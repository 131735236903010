import { createSlice } from "@reduxjs/toolkit";
import { getListThunk, addChat,getSharedChatList } from "./chatsThunk";


const initialState = {
  source: "default",
  chatId: null,
  // list: [],
  chats: {
    pages: null,
    page: null,
    count: null,
    limit: null,
    items: [],
  },
  animation: {

  },
  // sharedChats: [],
  sharedChats: {
    pages: null,
    page: null,
    count: null,
    limit: null,
    items: [],
  },
  loading: false,
  loadingGetMore: false,
  errors: [],
  status: ''
};

export const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setSource(state,action) {
      let source = action.payload?.source || "default";
      state.source = source;
    },
    setChatList(state, action) {
      state.list = action.payload.list;
      state.status = '';
    },
    setStatus(state,action) {
      state.status = action.payload.status;
    },
    setCurrentChat(state, action) {
      let list = state.source == "default" ? state.chats.items : state.sharedChats.items;
      let id = action.payload;
      let exist = list.find(item => item.id == id);
      state.status = '';

      if (exist) {
        state.chatId = id ? parseInt(id) : null 
      } else {
        state.chatId = null;
        // history.push('/playground');
      }
      
      // && exist
      // if(!exist) {
      //   history.push('/playground');
      // }
    },
    deleteChat(state, action) {
      let chatId = action.payload;


      if (state.source == "default") {
        state.chats.items = state.list.filter(chat => chat.id !== chatId)
      } else {
        state.sharedChats = state.sharedChats.filter(chat => chat.id !== chatId)
      }

      state.status = '';
      state.chatId = null;
    },
    pushChat(state, action) {
      state.chats.items.unshift(action.payload);

      let id = action.payload.id;
      
      state.chatId = id ? parseInt(id) : null;
      state.status = 'new';
    },
    cleanChats(state, action) {
      if (action.payload.shared) {
        state.sharedChats.items = [];
      }

      if (action.payload.chats) {
        state.chats.items = [];
        state.sharedChats.items = [];
      }
    },
    renameChat(state, action) {
      const id = action.payload.chatId;
      const name = action.payload.name;
      
      if (state.source == "default") {
        state.chats.items = state.chats.items.map(chat => {
          if (chat.id === id) {
            return { ...chat, name };
          }

          return chat;
        });
      } else {
        state.sharedChats.items = state.sharedChats.items.map(chat => {
          if (chat.id === id) {
            return { ...chat, name };
          }

          return chat;
        });
      }
    },
    cleanSelectedChat(state, action) {
      state.chatId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListThunk.pending, (state, { meta }) => {
      if (meta?.arg.limit > 10) {
        state.loadingGetMore = true;
      } else {
        state.loading = true;
      }
      // state.status = '';
    });
    builder.addCase(getListThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.loadingGetMore = false;
      state.chats = payload.chats;
      state.sharedChats = payload.shared;
      state.errors = [];
      if (payload.limit <= 10) {
        state.source = "default";
      }
      // state.status = '';
    });
    builder.addCase(addChat.pending, (state, { _ }) => {
      state.loading = true;
      state.status = '';
    });
    builder.addCase(addChat.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.chats.items.push(payload.chat);
      state.errors = [];
      state.status = '';
      state.source = "default";
    });
    builder.addCase(getSharedChatList.pending, (state, { _ }) => {
      state.loading = true;
      state.status = '';
      state.sharedChats = [];
    });

    builder.addCase(getSharedChatList.rejected, (state, { _ }) => {
      state.loading = false;
      state.status = '';
      state.source = "default";
    });
    builder.addCase(getSharedChatList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.sharedChats = payload.chats;
      state.source = "shared";

      // state.status = '';
    });
  },
});

export const {
  pushChat,
  setChatList,
  setCurrentChat,
  deleteChat,
  setStatus,
  cleanChats,
  setSource,
  renameChat,
  cleanSelectedChat,
} = chatSlice.actions;

export const getList = getListThunk;
export const getSharedList = getSharedChatList;

export default chatSlice.reducer;
