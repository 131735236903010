import { createSlice } from "@reduxjs/toolkit";
import {
  validateUser,
  refreshUserToken,
  login,
  signUp,
  thirdPartyLogin,
  updateUserState,
  refreshUserState,
} from "./authThunk";
import history from "_helpers/history";
import { useNavigate } from "react-router-dom";
import { getToken, removeToken, setToken } from "_helpers/tokenManagement";

const initialState = {
  token: "",
  isAuthenticated: false,
  user: null,
  loading: false,
  errors: [],
  signup: {
    loading: false,
    isUserSaved: false,
    errors: [],
  },
  authCount: 0
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {

    noToken(state) {
      state.token = null;
    },
    manualLock(state,action) {
      state.user.tariff_plan.manualUpgradeLock  = action.payload || false;
    },

    updatePlan(state,action) {
      state.user.tariff_plan = action.payload?.plan;
    },
    logout(state, action) {
      removeToken("token");
      removeToken("refreshToken");
      removeToken("cachedModel");
      removeToken("guestId");
      removeToken("activePage");
      removeToken("guestId");
      removeToken("userData");
      state.loading = false;
      state.user = {};
      state.token = null;
      state.isAuthenticated = false;
      state.errors = [];
      // let redirect = action.payload ? action.payload.redirect : false;
      // if(redirect) {
      //   history.push("/portal/login");
      //   if(redirect.hard) {
      //       history.go("/portal/login");
      //   }
      // }
    },
    removeJsonData(state, action) {
      const { dataKey } = action.payload;
      const updatedJsonData = { ...state.user.jsonData };
      if (Object.keys(updatedJsonData).length < 2) {
        state.user = { ...state.user, jsonData: null }
      } else {
        if (updatedJsonData.hasOwnProperty(dataKey)) {
          delete updatedJsonData[dataKey];
        }
        state.user = { ...state.user, jsonData: updatedJsonData }
      }
    },
    setJsonData(state, action) {
      const { jsonData } = action.payload;
      state.user = { ...state.user, jsonData }
    },
    refresh(state) {
      state.loading = false;
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, { payload }) => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { token, user } = payload;
      state.token = token;
      state.user = user;

      state.loading = false;
      state.isAuthenticated = true;
      let redirectUrl = payload.redirectUrl
        ? payload.redirectUrl
        : process.env.REACT_APP_PLATFORM_DOMAIN;

      // if (payload.redirectTop) {
      //   window?.parent?.postMessage(
      //     { isAuthentificated: true, redirectUrl: redirectUrl },
      //     "https://younet.ai"
      //   );
      // } else {
      //   history.push(redirectUrl);
      //   history.go(redirectUrl);
        // removeToken('loginRedirectUrlSession');
      // }
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.loading = false;
      state.errors.push(payload.message);
      removeToken("cachedModel");
    });

    builder.addCase(thirdPartyLogin.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(thirdPartyLogin.fulfilled, (state, { payload }) => {
      const { token, user } = payload;
      state.token = token;
      state.user = user;
      state.loading = false;
      state.isAuthenticated = true;
      // let redirectUrl = payload.redirectUrl
      // ? payload.redirectUrl
      // : process.env.REACT_APP_PLATFORM_DOMAIN;

      // history.push(redirectUrl);
      // history.go(redirectUrl);
      // removeToken('loginRedirectUrlSession');
    });
    builder.addCase(thirdPartyLogin.rejected, (state, { payload }) => {
      state.loading = false;
      state.errors.push(payload.message);
    });

    builder.addCase(updateUserState.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(updateUserState.rejected, (state, { payload }) => {
      state.loading = false;
      state.errors.push(payload.message);
    });
    builder.addCase(updateUserState.fulfilled, (state, { payload }) => {
      const { updatedUserValues } = payload;
      let updatedUser = state.user;

      if (updatedUserValues) {
        for (let key in updatedUserValues) {
          state.user[key] = updatedUserValues[key];
        }
      }

      state.loading = false;
      state.user = updatedUser;
    });

    builder.addCase(validateUser.pending, (state, { payload }) => {
      state.loading = true;
    });

    builder.addCase(validateUser.fulfilled, (state, { payload }) => {
      const { token, user, authCount } = payload;
      state.token = token;
      state.user = user;
      state.authCount = authCount
      state.loading = false;
      state.isAuthenticated = true;
    });

    builder.addCase(validateUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.user = {};
      state.token = null;
      state.authCount = 0;
      state.isAuthenticated = false;
      state.errors = ["Session Timeout. Please, re-login again.. "];
    });

    builder.addCase(refreshUserToken.pending, (state, { payload }) => {
      state.loading = true;
    });

    builder.addCase(refreshUserToken.fulfilled, (state, { payload }) => {
      const { token, user } = payload;
      state.token = token;
      state.user = user;
      state.loading = false;
      state.isAuthenticated = true;
    });

    builder.addCase(refreshUserToken.rejected, (state, { payload }) => {
      state.loading = false;
      state.user = {};
      state.token = null;
      state.authCount = 0;
      state.isAuthenticated = false;
      state.errors = ["Session Timeout. Please, re-login again.. "];
    });

    builder.addCase(signUp.pending, (state, { payload }) => {
      state.signup.loading = true;
    });
    builder.addCase(signUp.fulfilled, (state, { payload }) => {
      state.signup.savedEmail = payload.email;
      state.signup.userId = payload.id;
      state.signup.isUserSaved = true;
      state.signup.loading = false;
    });

    builder.addCase(signUp.rejected, (state, { payload }) => {
      const { message } = payload;
      state.signup.loading = false;
      state.signup.errors.push(message);
    });

    builder.addCase(refreshUserState.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(refreshUserState.fulfilled, (state, { payload }) => {
      const { token, user } = payload;
      state.user = user;
      state.loading = false;
    });
    builder.addCase(refreshUserState.rejected, (state, { payload }) => {
      state.loading = false;
      state.errors.push(payload.message);
    });
  },
});

export const { logout, setJsonData, removeJsonData , updatePlan,manualLock, noToken} = authSlice.actions;
export const signup = signUp;
export const updateUser = updateUserState;
export const refreshUser = refreshUserState;

export default authSlice.reducer;
