import LoadingButton from "@mui/lab/LoadingButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {memo, useEffect} from "react";
import {useSelector} from "react-redux";
import {sendUIEvent} from "events/analytics/google";

const SubmitBtn = ({
                       disabled, onSubmit = () => {
    }
                   }) => {

    const loading = useSelector(state => state.links?.parse?.loading);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if(loading) {
                e.preventDefault();
                e.returnValue = '';
                sendUIEvent({name: `website_tool_intent_to_leave_parsing_links`});
            }
        };


        if(loading) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [loading]);

    return (
        <LoadingButton  loadingPosition="end" disabled={loading || disabled} loading={loading} variant="contained" onClick={onSubmit} endIcon={<SearchRoundedIcon/>}>
            Parse
        </LoadingButton>
    )
}

export default memo(SubmitBtn);