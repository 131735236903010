import {interactWithUser, setMaintenance} from "redux/status";
import store from "redux/store";
import {checkIntention} from "./statusManagement";
import {removeToken} from "./tokenManagement";
import {logout} from "redux/auth";
import {refreshUserToken} from "redux/auth/authThunk";

export const handleResponse = (res) => {

    try {
        if (!res.data) {
            return;
        }

        let code = res?.data.code || 200;
        let serverCode = res.status || res.code || 200;
        let intention = res?.data?.intention || "";
        let message = Array.isArray(res?.data?.message)
            ? res?.data?.message[0] || null
            : res?.data?.message || res?.data?.message || null;


        if (serverCode === 418) {
            store.dispatch(
                setMaintenance({active: true, message: message})
            );
            return null
        }

        let statusInstance =
            intention || message
                ? checkIntention(serverCode, code, intention, message)
                : false;


        if (statusInstance) {
            store.dispatch(
                interactWithUser({
                    message: statusInstance.message,
                    type: statusInstance.status,
                    code: code,
                })
            );
        }


        if (serverCode == 401) {

            const urlPattern = /^\/s\/[^/]+$/;
            const url = window.location.pathname

            let isSharePage = urlPattern.test(url);
            if (isSharePage) {
              store.dispatch(logout({redirect: !isSharePage}));
            }
            if (!isSharePage && !localStorage.getItem('refreshToken')) {
              store.dispatch(refreshUserToken());
            }

            // window.location.href = "/login"
            // store.dispatch(interactWithUser({ template: "JWT_TOKEN_EXPIRED" }));
            return null;
        }
    } catch (e) {
        console.error(e);
    }
};


export const createPixel = (url = '', onLoad = () => {
}, onError = () => {
}, params = {}) => {
    return new Promise((resolve, reject) => {
        let img = document.createElement('img');

        img.onload = () => {
            onLoad();
            resolve('Image loaded successfully');
        };


        img.onerror = (e) => {

            console.error(e)
            if (params.allowError) {
                onLoad();
                resolve('Pixel handled');
            } else {
                reject(new Error('Failed to load image'));
            }

            onError();
        };

        img.style.display = 'none';
        img.src = url;
        document.getElementById('body').appendChild(img);
    });
};

export const createScript = (url = '', onLoad = () => {}, onError = () => {}, params = {}) => {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');

        script.type = 'text/javascript';

        script.onload = () => {
            onLoad();
            resolve('Script loaded successfully');
        };

        script.onerror = (e) => {
            console.error(e);
            if (params.allowError) {
                onLoad();
                resolve('Script handled');
            } else {
                reject(new Error('Failed to load script'));
            }
            onError();
        };

        script.src = url;
        document.head.appendChild(script);
    });
};


// export const dispatchCallback = (response) => {

// }