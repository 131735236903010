import { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './typewriter.module.css';

const Typewriter = ({ text, animationSpeed, animationDelay = 0, cursor = true, cursorDelay = 0, onTypingEnd = () => {} }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      const delay = setTimeout(() => {
        isInitialMount.current = false;
        const type = async () => {
          if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
              setCurrentText((prevText) => prevText + text[currentIndex]);
              setCurrentIndex((prevIndex) => prevIndex + 1);
            }, animationSpeed);

            return () => clearTimeout(timeout);
          }
        };
        type();
      }, animationDelay);
      
      setTimeout(() => {
        setShowCursor(true);
      }, cursorDelay);

      return () => clearTimeout(delay);
    } else {
      const type = async () => {
        if (currentIndex < text.length) {
          const timeout = setTimeout(() => {
            setCurrentText((prevText) => prevText + text[currentIndex]);
            setCurrentIndex((prevIndex) => prevIndex + 1);
          }, animationSpeed);

          return () => clearTimeout(timeout);
        } else {
          onTypingEnd();
        }

      };
      setTimeout(() => {
        setShowCursor(false);
      }, animationSpeed * text.length);
      type();
    }
  }, [currentIndex, animationSpeed, text]);

  return (
    <span className={styles.cursorWrapper}>
      {currentText}
      {showCursor && <span className={classnames(styles.cursor, !currentText.length && styles.cursorAnimation)}>|</span>}
    </span>
  );
};

export default Typewriter;
