import { useState, useEffect } from 'react';
import {
  Button,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  MenuItem,
  TextField,
  InputAdornment
} from '@mui/material';
import { setMonetizationInfo, updatePaymentInfo } from 'redux/monetization';
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const PaymentForm = ({ list, setIsClosed, editingPaymentId }) => {
  const paymentForm = {
    wire_transfer: {
      beneficiaryName: {
        value: '',
        label: 'Beneficiary name',
        hint: 'The legal name of the account holder.'
      },
      beneficiaryAddress: {
        value: '',
        label: 'Beneficiary address',
        hint: 'Full legal address of the account holder, including city, state, and country.',
      },
      beneficiaryBankName: {
        value: '',
        label: 'Beneficiary bank name',
        hint: 'The name of the bank holding the account.',
      },
      beneficiaryBankAddress: {
        value: '',
        label: 'Beneficiary bank address',
        hint: 'Complete address of the bank, including city, state, and country.',
      },
      accountNumber: {
        value: '',
        label: 'Account number',
        hint: 'The unique number identifying your bank account.',
      },
      currency: {
        value: '',
        label: 'Currency',
        hint: 'The currency in which you wish to receive funds.',
      },
      BICOrSWIFT: {
        value: '',
        label: 'BIC Or SWIFT',
        hint: 'A code for global identification of your bank, necessary for international transfers.',
      },
      IBAN: {
        value: '',
        label: 'IBAN',
        hint: 'Used internationally to identify individual bank accounts across national borders.',
      },
      companyAccountInfo: {
        value: '',
        label: 'Company account info',
        hint: 'Provide this if the account is registered under a business entity.',
      },
      companyInfo: {
        value: '',
        label: 'Company info',
        hint: 'Legal name and complete address of the company, including country.',
      },
      contactInfo: {
        value: '',
        label: 'Contact info',
        hint: 'Your contact details, including phone and email, for payment communication.',
      },
    },
    paypal: {
      currency: {
        value: '',
        label: 'Currency',
        hint: 'Select the currency for receiving payments.',
      },
      recipientEmail: {
        value: '',
        label: 'Recipient email',
        hint: 'The email address linked to your PayPal account.',
      }
    }
  };
  const [paymentMethod, setPaymentMethod] = useState('paypal');
  const [paymentFormInfo, setPaymentFormInfo] = useState(paymentForm);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.monetization.payment, shallowEqual);
  const globalLoading  = useSelector((state) => state.monetization.globalLoading);

  useEffect(() => {
    if (Boolean(editingPaymentId)) {
      const filteredList = list.filter(item => item.id === editingPaymentId)[0];
      const paymentData = filteredList?.payment_data;
      if (filteredList.type === 'wire_transfer') {
        setPaymentMethod('wire_transfer');
      }
      if (paymentData) {
        const updatedPaymentFormInfo = { ...paymentFormInfo };

        Object.keys(paymentData).forEach(item => {
          updatedPaymentFormInfo[filteredList.type][item].value = paymentData[item];
        });
  
        setPaymentFormInfo(updatedPaymentFormInfo);
      }
    }
  }, [editingPaymentId]);

  const onChangeHandler = (event, fieldName) => {
    const value = event.target.value;

    setPaymentFormInfo({
      ...paymentFormInfo,
      [paymentMethod]: {
        ...paymentFormInfo[paymentMethod],
        [fieldName]: {
          ...paymentFormInfo[paymentMethod][fieldName],
          value,
        }
      }
    });
  }

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  }

  const onClickHandler = () => {
    const translatedData = {};
    const data = paymentFormInfo[paymentMethod];

    for (const key in data) {
      translatedData[key] = data[key].value;
    }

    const payload = {
      type: paymentMethod,
      paymentData: translatedData
    }
    if (Boolean(editingPaymentId)) {
      dispatch(updatePaymentInfo({ id: editingPaymentId, data: payload }));
    } else {
      dispatch(setMonetizationInfo({ ...payload, isDefault: 1 }));
    }
  }

  return (
    <>
      <DialogContent dividers>
        <FormControl sx={{ width: '100%', marginBottom: '16px' }}>
          <Select
            value={paymentMethod}
            onChange={handleChangePaymentMethod}
          >
            <MenuItem value={'wire_transfer'}>Wire Transfer</MenuItem>
            <MenuItem value={'paypal'}>PayPal</MenuItem>
          </Select>
        </FormControl>
        {paymentMethod === 'wire_transfer' ?
          Object.keys(paymentForm.wire_transfer).map((item, i) => {
            return (
              <FormControl sx={{ display: 'block', width: '100%', marginBottom: '16px' }} key={`wire-${item}-${i}`}>
                <TextField
                  label={paymentFormInfo.wire_transfer[item].label}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name={item}
                  value={paymentFormInfo.wire_transfer[item].value}
                  onChange={event => onChangeHandler(event, item)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <GeneralTooltip
                          placement='bottom'
                          title={paymentForm.wire_transfer[item].hint}
                        >
                          <HelpOutlineIcon fontSize='small' sx={{color:'#B5B5B5'}}/>
                        </GeneralTooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            )
          }
        ) : Object.keys(paymentForm.paypal).map((item, i) => {
          return (
            <FormControl sx={{ display: 'block', width: '100%', marginBottom: '16px' }} key={`paypal-${item}-${i}`}>
              <TextField
                label={paymentFormInfo.paypal[item].label}
                type="text"
                fullWidth
                variant="outlined"
                name={item}
                value={paymentFormInfo.paypal[item].value}
                onChange={event => onChangeHandler(event, item)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <GeneralTooltip
                        placement='bottom'
                        title={paymentForm.paypal[item].hint}
                      >
                        <HelpOutlineIcon fontSize='small' sx={{color:'#B5B5B5'}}/>
                      </GeneralTooltip>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClickHandler}
          disabled={loading || globalLoading}
        >
          Save
        </Button>
        <Button
          autoFocus
          onClick={setIsClosed}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default PaymentForm;
