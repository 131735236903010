function NotFound() {
 return (
  <>
  NotFound
  </>

 );
}

export default NotFound;
