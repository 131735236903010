import {Box, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import FlexWrapper from "../../../wrappers/FlexWrapper";
import PhotoIcon from "@mui/icons-material/Photo";


const ERROR_LABEL = 'The image has expired or been deleted';
const MAX_IMAGE_SIZE = '285px';

const FailedImage = ({alt = ERROR_LABEL}) => {
    return (
        <FlexWrapper styles={{
            flexFlow: 'column',
            maxWidth: '285px',
            width: '100%',
            height: '285px',
            backgroundColor: 'rgba(0, 0, 0, 0.11)',
            textAlign: 'center',
            padding: '12px',
            borderRadius: '2px'
        }}
        >
            <PhotoIcon fontSize="large" color="icons" sx={{
                opacity: '0.5',
                marginBottom: '8px'
            }}/>
            <Typography sx={{opacity: '0.5'}} variant="describe_text"> {ERROR_LABEL}</Typography>

        </FlexWrapper>
    )
}

export default FailedImage;
