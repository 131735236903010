import { useState, useEffect, useRef } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { Step, Stepper } from 'components/Stepper';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import Logo from './components/Logo';
import Initial from './steps/Initial';
import Marketplace from './steps/Marketplace';
import SelectFlow from './steps/SelectFlow';
import Quiz from './steps/Quiz';
import Models from './steps/Models';
import { encodeData } from 'utils/formatContent';
import Brain from './steps/Brain';
import CreateModel from '../Model/components/CreateModel';
import { setEveryToolsStatus } from "redux/tools";
import {cleanEmails} from "redux/email";
import useGenerateModelInstructions from 'hooks/useGenerateModelInstructions';
import { customModelData, presetData } from '../Model/components/CreateModel/presetData';
import {sendUIEvent} from "../../../events/analytics/google";
import styles from './onboarding.module.css';

const data = {
  monetization: [
    {
      name: 'target',
      title: 'Who is your target market?',
      options: ['Anonymous consumers', 'Businesses', 'Followers'],
      other: 'Example: My target market includes hobbyist photographers who are looking to improve their skills. The AI model would provide tips, critiques, and tutorials based on their uploaded images.',
    }, {
      name: 'function',
      title: 'What is the primary function you want me to accomplish?',
      options: ['Specific industry expertise', 'Niche tool', 'Educational (tutorials, courses)'],
      other: 'Example: I am developing a niche AI tool tailored for aspiring YouTubers who want to create engaging content based on their specific interests, modeled after the successful style of MrBeast. This AI would utilize the structural elements and storytelling techniques characteristic of MrBeast’s videos, adapting them to the user’s chosen topics.',
    }, {
      name: 'interact',
      title: 'How should I interact with users to function best?',
      options: ['No specific user input is required', 'Brainstorm input flow for me', 'Ask follow-up questions'],
      other: 'Example: Before initiating any detailed assistance, the AI should first ask users a series of onboarding questions to understand their preferences, budget, and specific needs. For example, if a user is looking for tech gadgets, the AI might ask about their preferred brands and tech specifications of interest.',
    }
  ], assistant: [
    {
      name: 'role',
      title: 'What is your current role?',
      options: ['Student', 'Working professional', 'Job seeker'],
      other: 'Example: I currently work as a Senior Project Manager, leading a dynamic team in planning, executing, and closing projects within tight deadlines.',
    }, {
      name: 'industry',
      title: 'What kind of work you do?',
      options: ['Education & research', 'Arts & entertainment', 'Technology & software'],
      other: 'Example: I have been actively engaged in projects related to software development, artificial intelligence, and emerging technologies. My passion lies in exploring new advancements, staying abreast of industry trends, and leveraging technology to solve complex challenges.',
    }, {
      name: 'text',
      title: 'What type of text content do you work with most?',
      options: ['Social media and blog content', 'Emails and correspondence', 'Reports and documents'],
      other: 'Example: I primarily work with diverse sets of text data in my role. This includes but is not limited to analyzing customer feedback, processing and interpreting natural language data, and extracting valuable insights from large textual datasets.',
    }, {
      name: 'routine',
      title: 'What would you like your Al to do for you?',
      options: ['Summarizing long texts or reports', 'Translating text between languages', 'Not decided yet'],
      other: 'Example: There are certain routine tasks that I believe could be more efficiently handled with the assistance of AI or automation. For instance, tasks such as data entry and basic data analysis, which can be time-consuming, could benefit from automation to enhance accuracy and speed.',
    },
  ], 'chat-bot': [
    {
      name: 'type',
      title: 'What type of website do you operate?',
      options: ['E-commerce', 'Educational', 'Entertainment'],
      other: 'Example: I operate a boutique online store that specializes in handcrafted home decor items. Our focus is on personalized customer service, and we aim to use AI to enhance this personalized shopping experience by offering tailored product recommendations.',
    }, {
      name: 'purpose',
      title: 'What main purpose should I serve on your website?',
      options: ['Customer support', 'Product or service guidance', 'Content search assistance'],
      other: 'Example: The AI should act as a virtual event coordinator on our website, helping users navigate through event schedules, register for sessions, and connect with speakers and other attendees virtually.',
    }, {
      name: 'topics',
      title: 'Are there any topics or types of knowledge that I should avoid?',
      customOther: 'Yes',
      options: ['No'],
      other: 'Example: I would like the AI to avoid discussing specific financial advice or investment strategies without first verifying that the user is authorized to receive such information, to comply with financial regulations.',
    }, {
      name: 'interact',
      title: 'How should I interact with users to function best?',
      options: ['No specific user input is required', 'Brainstorm input flow for me', 'Ask follow-up questions'],
      other: 'Example: Before initiating any detailed assistance, the AI should first ask users a series of onboarding questions to understand their preferences, budget, and specific needs. For example, if a user is looking for tech gadgets, the AI might ask about their preferred brands and tech specifications of interest.',
    },
  ], 'customer-support': [
    {
      name: 'type',
      title: 'What product do you need support for?',
      options: [],
      isOptional: false,
      customOther: '',
      expanded: true,
      other: 'Example: I need a support for the XYZ Smart Thermostat. It’s a device that allows users to control their home heating and cooling systems remotely via a mobile app.',
    }, {
      name: 'questions',
      title: 'What questions should be asked before support?',
      options: ['I am not sure'],
      customOther: '',
      isOptional: true,
      expanded: true,
      other: 'Example: The support should ask if the issue is with the device or app, what the specific problem is, and what solutions have been tried.',
    }, {
      name: 'contact',
      title: 'Provide business contact details for further assistance (optional).',
      options: ['I will add later'],
      isOptional: true,
      expanded: true,
      other: 'Example: For further assistance or to request a refund, customers can call us at (123) 456-7890 or email support@xyzcompany.com.',
    },
  ],
}

const Onboarding = ({ initialStep }) => {
  // TODO: mode state to context
  const [answers, setAnswers] = useState([]);
  const [textInputText, setTextInputText] = useState('');
  const [finishedSteps, setFinishedSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepName, setCurrentStepName] = useState('initial');
  const [selectedModel, setSelectedModel] = useState(null);
  const [isCustomModel, setIsCustomModel] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState('assistant'); // assistant, chat-bot, monetization
  const [finishedStepNames, setFinishedStepNames] = useState([]);
  const [animatedSteps, setAnimatedSteps] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const [stage, setStage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [renderedModels, setRenderedModels] = useState([]);
  const [renderedGeneratedModels, setRenderedGeneratedModels] = useState([]);
  const [isFormShowed, setIsFormShowed] = useState(false);
  const [isInitialStepShowed, setIsInitialStepShowed] = useState(false);
  const { handleGenerateModelInstructions, modelInstructions, isGenerating } = useGenerateModelInstructions();
  const [models, setModels] = useState(modelInstructions);
  const stageTimeoutRef = useRef(null);
  const logoRef = useRef(null);
  const steps = {
    assistant: ['initial', 'selectFlow', 'role', 'industry', 'text', 'routine', 'modelSelect', 'modelForm', 'modelBrain'],
    monetization: ['initial', 'selectFlow', 'target', 'function', 'interact', 'modelSelect', 'modelForm', 'modelBrain'],
    'chat-bot': ['initial', 'selectFlow', 'type', 'purpose', 'topics', 'interact', 'modelSelect', 'modelForm', 'modelBrain'],
    'customer-support': ['initial', 'selectFlow', 'type', 'questions', 'contact', 'modelSelect', 'modelForm', 'modelBrain'],
  };
  const fullHeightSteps = ['modelForm'];
  const model = useSelector((state) => state.models?.current, shallowEqual);
  const isBrainOnboardingEnabled = localStorage.getItem('brainOnboarding');
  const [queryParameters] = useSearchParams();
  const continueOnboarding = queryParameters.get('continueOnboarding');
  const dispatch = useDispatch();
  const isNewUser = !Boolean(model?.id);

  const setStageToLoading = () => {
    setStage(1);
    stageTimeoutRef.current = setTimeout(() => {
      setStage(3);
    }, 400);
    stageTimeoutRef.current = setTimeout(() => {
      setStage(1);
    }, stage > 1 ? 7200 : 5200);
  };

  useEffect(() => {
    // localStorage.removeItem('onboardingBrainStatus');
    dispatch(cleanEmails());
    dispatch(setEveryToolsStatus({ status: {text: null, qa: null, files: null, emails: null, links: null}}));
  }, []);

  useEffect(() => {
    if (isGenerating) {
      setStage(3);
      setIsLoading(true);
    }
    if (!isGenerating) {
      if (modelInstructions?.length) {
        setTimeout(() => {
          setStage(1);
          setIsLoading(false);
        }, 3000 * modelInstructions.length + 1000);
      } else {
        setIsLoading(false);
        setStage(1);
        setModels(modelInstructions);
      }
    }
  }, [isGenerating, modelInstructions]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if(steps[selectedFlow][currentStep] !== 'modelBrain') {
        e.preventDefault();
        e.returnValue = '';
        sendUIEvent({name: `onboarding_intent_leave_${selectedFlow}_${steps[selectedFlow][currentStep]}`});
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentStep]);
  
  useEffect(() => {
    if (['marketplace', 'selectFlow'].includes(currentStepName)) {
      logoRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };
    
    if (finishedStepNames.includes(currentStepName)) return;
    if (isNewUser) {
      setStageToLoading();
    }
    setTextInputText('');

    return () => {
      clearTimeout(stageTimeoutRef.current);
    };
  }, [currentStepName]);

  useEffect(() => {
    setStage(1);
  }, [animatedSteps]);
  
  useEffect(() => {
    clearTimeout(stageTimeoutRef.current);
    
    if (isFocused) {
      setStage(2);
    } else {
      setStage(1);
    }
  }, [isFocused]);

  return (
    <Box sx={{ height: 'inherit', overflowY: {xs: 'none', md: 'auto'}, display: {xs: 'block', md: 'flex'}, position: 'relative', padding: '0 10px' }}>
      <Grid container mt={6} sx={{ minHeight: currentStepName === 'marketplace' ? '100%' : 'none', height: currentStepName === 'marketplace' ? 'none' : fullHeightSteps.includes(currentStepName) ? '100%' : 'auto', margin: 'auto' }}>
        {currentStep !== data[selectedFlow].length + 3  && (
          <Grid item sx={{ width: '100%', textAlign: 'center' }} mb={10} ref={logoRef}>
            <Box sx={{ display: 'inline-block', marginTop: '50px' }}>
              <Logo stage={stage} isLoading={isGenerating} textInputText={textInputText} />
            </Box>
          </Grid>
        )}
        <Stepper initialStep={continueOnboarding ? 'modelBrain' : initialStep} updateAnyway onChangeStep={(step) => {
          const stepIndex = steps[selectedFlow].indexOf(step);

          setCurrentStep(stepIndex);
          setCurrentStepName(step);
          if (finishedSteps < stepIndex) {
            setFinishedSteps(stepIndex);
          }
          
        }}>
          <Step stepKey='initial'>
            {(stepBag) => (
              <Initial
                stepBag={stepBag}
                isNewUser={isNewUser}
                isInitialStepShowed={isInitialStepShowed}
                setIsInitialStepShowed={val => setIsInitialStepShowed(val)}
                finishedSteps={finishedSteps}
                setFlow={val => {
                  setSelectedFlow(val);
                }}
                finishedStepNames={finishedStepNames}
                setFinishedStepNames={setFinishedStepNames}
              />
            )}
          </Step>
          <Step stepKey='marketplace'>
            {(stepBag) => (
              <>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <Marketplace
                    stepBag={stepBag}
                    isNewUser={isNewUser}
                    animatedSteps={animatedSteps}
                    finishedStepNames={finishedStepNames}
                  />
                </Box>
                <Button
                  onClick={() => {
                    window.gtag('event', 'Onboarding_click_back_marketplace', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'click back',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    setFinishedStepNames([ ...finishedStepNames, 'marketplace' ]);
                    stepBag.setStep('initial');
                  }}
                  startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                  sx={{
                    position: 'sticky',
                    bottom: '10px',
                    left: '10px',
                    color: '#7e7e7e',
                    background: '#fff',
                    minWidth: {xs: '32px', md: 'auto'}, 
                    height: {xs: '40px', md: 'auto'}, 
                  }}
                >
                  <Box sx={{ display: {xs: 'none', md: 'block'} }}>
                    Back
                  </Box>
                </Button>
              </>
            )}
          </Step>
          <Step stepKey='selectFlow'>
            {(stepBag) => (
              <>
                <SelectFlow
                  stepBag={stepBag}
                  isNewUser={isNewUser}
                  isFormShowed={isFormShowed}
                  setIsFormShowed={val => setIsFormShowed(val)}
                  finishedSteps={finishedSteps}
                  setFlow={val => {
                    setSelectedFlow(val);
                    setFinishedStepNames([ ...finishedStepNames, 'selectFlow' ]);
                  }}
                  setIsCustomModel={val => {
                    setIsCustomModel(val);
                    if (val) {
                      setSelectedModel(customModelData);
                    } else {
                      setSelectedModel(null);
                    }
                  }}
                  finishedStepNames={finishedStepNames}
                />
                <Button
                  className={styles.backBtn}
                  onClick={() => {
                    window.gtag('event', 'Onboarding_click_back_selectFlow', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'click back',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    setFinishedStepNames([ ...finishedStepNames, 'selectFlow' ]);
                    stepBag.setStep('initial');
                  }}
                  startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                  sx={{
                    // position: 'sticky',
                    bottom: '10px',
                    left: '10px',
                    color: '#7e7e7e',
                    background: '#fff',
                    minWidth: {xs: '32px', md: 'auto'}, 
                    height: {xs: '40px', md: 'auto'},
                  }}
                >
                  <Box sx={{ display: {xs: 'none', md: 'block'} }}>
                    Back
                  </Box>
                </Button>
              </>
            )}
          </Step>
          {data[selectedFlow].map((item, i) => (
            <Step key={`step-${i}`} stepKey={item.name}>
              {(stepBag) => (
                <Quiz
                  item={item}
                  isNewUser={isNewUser}
                  index={i}
                  answers={answers}
                  setAnswers={setAnswers}
                  isFocused={isFocused}
                  setIsFocused={val => setIsFocused(val)}
                  stepBag={stepBag}
                  setTextInputText={val => setTextInputText(val)}
                  textInputText={textInputText}
                  finishedSteps={finishedSteps}
                  setAnimatedSteps={val => setAnimatedSteps(val)}
                  animatedSteps={animatedSteps}
                  steps={steps[selectedFlow]}
                  quizLength={data[selectedFlow].length + 2}
                  getModels={val => {
                    handleGenerateModelInstructions(val, encodeData(selectedFlow));
                  }}
                  finishedStepNames={finishedStepNames}
                  setFinishedStepNames={setFinishedStepNames}
                />
              )}
            </Step>
          ))}
          <Step stepKey='modelSelect'>
            {(stepBag) => (
              <>
                <Models
                  stepBag={stepBag}
                  isNewUser={isNewUser}
                  answers={answers}
                  finishedSteps={finishedSteps}
                  modelInstructions={modelInstructions}
                  isLoading={isLoading}
                  setIsLoading={val => setIsLoading(val)}
                  setSelectedModel={model => {
                    if (Number(stepBag.step) >= finishedSteps) {
                      setFinishedSteps(finishedSteps + 1);
                    }
                    setSelectedModel(model);
                  }}
                  setRenderedModels={val => setRenderedModels(val)}
                  renderedModels={renderedModels}
                  isGenerating={isGenerating}
                  setRenderedGeneratedModels={val => setRenderedGeneratedModels(val)}
                  renderedGeneratedModels={renderedGeneratedModels}
                  setStage={val => setStage(val)}
                  setModels={val => setModels(val)}
                  models={models}
                  getModels={() => {
                    handleGenerateModelInstructions(answers, encodeData(selectedFlow));
                  }}
                  selectedFlow={selectedFlow}
                />
                <Button
                  onClick={() => {
                    window.gtag('event', 'Onboarding_click_back_selectModel', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'click back',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    stepBag.setStep(data[selectedFlow][data[selectedFlow].options.length - 1]?.name);
                    setFinishedSteps(data[selectedFlow].options.length + 1);
                    setAnimatedSteps({...animatedSteps, routine: true});
                    setRenderedModels([]);
                    setRenderedGeneratedModels([]);
                    setModels(null);
                  }}
                  disabled={isLoading}
                  startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                  sx={{
                    position: 'sticky',
                    bottom: '10px',
                    left: '10px',
                    color: '#7e7e7e',
                    background: '#fff',
                    minWidth: {xs: '32px', md: 'auto'}, 
                    height: {xs: '40px', md: 'auto'},
                  }}
                >
                  <Box sx={{display: {xs: 'none', md: 'block'} }}>
                    Back
                  </Box>
                </Button>
              </>
            )}
          </Step>
          <Step stepKey='modelForm'>
            {(stepBag) => (
              <>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <CreateModel preselectedModel={selectedModel} generatedModels={modelInstructions} stepBag={stepBag} />
                  <Button
                    onClick={() => {
                      window.gtag('event', 'Onboarding_click_back_createModel', {
                        'event_category': 'Onboarding',
                        'event_label': 'Onboarding',
                        'value': 'click back',
                        'step': stepBag.step,
                        'width': window.innerWidth,
                      });
                      setSelectedModel(null);
                      if (isCustomModel) {
                        stepBag.setStep('selectFlow');
                        setFinishedSteps(0);
                        setAnimatedSteps({ ...animatedSteps, initial: true })
                      } else {
                        stepBag.setStep('modelSelect');
                      }
                    }}
                    startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
                    sx={{
                      position: 'sticky',
                      bottom: '10px',
                      left: '10px',
                      color: '#7e7e7e',
                      background: '#fff',
                      minWidth: {xs: '32px', md: 'auto'}, 
                      height: {xs: '40px', md: 'auto'},
                    }}
                  >
                    <Box sx={{ display: {xs: 'none', md: 'block'} }}>
                      Back
                    </Box>
                  </Button>
                </Box>
              </>
            )}
          </Step>
          <Step stepKey='modelBrain'>
            {(stepBag) => (
              <>
                <Box sx={{ width: '100%' }}>
                  <Brain modelId={model.id} intention={selectedFlow} isNewUser={isNewUser} />
                </Box>
              </>
            )}
          </Step>
        </Stepper>
      </Grid>
    </Box>
  );
};

export default Onboarding;
