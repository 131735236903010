import {getOutlookFolderList} from "redux/email/emailThunk";
import {reloadSearchFilter} from "redux/email";

const gmailSearchShorts = [
    {id: "in:sent", name: "Sent"},
    {id: "in:inbox", name: "Inbox"},
    {id: "in:starred", name: "Starred"},
    {id: "is:important", name: "Important"},
];


const accountTypeActions = {
    outlook: (dispatch, accountId) => {
        dispatch(getOutlookFolderList({accountId}));
    },
    gmail: (dispatch) => {
        dispatch(reloadSearchFilter({folders: gmailSearchShorts}));
    }

};

export default accountTypeActions;