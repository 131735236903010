import {useMemo} from 'react';
import {useSelector} from "react-redux";

const fullCodeComment = '<!-- Add this block to the <head> or within the <body> at the desired location. -->';

const useScriptCode = ({previewData, }) => {

    const modelHash = useSelector(state => state.models.current.public_hash);

    const {hash, type, isBtnNeeded} = useMemo(() => {
        const hash = modelHash || '';
        const type = previewData.type || 'bubble';
        const isBtnNeeded = type !== 'bubble';

        return {hash, type, isBtnNeeded};
    }, [modelHash, previewData.type]);


    const scriptCode = useMemo(() => {
        let paramUrl = `https://widget.younet.ai/index.js?modelHash=${hash}&type=${type}`;
        const btnImg = previewData.btnImg ? encodeURIComponent(previewData.btnImg) : '';

        if (isBtnNeeded && btnImg !== '') {
            paramUrl += `&imgUrl=${btnImg}`;
        }

        if (previewData.width) {
            paramUrl += `&width=${previewData.width}`;
        }

        if (previewData.height) {
            paramUrl += `&height=${previewData.height}`;
        }

        if (previewData.right) {
            paramUrl += `&right=${previewData.right}`;
        }

        if (previewData.bottom) {
            paramUrl += `&bottom=${previewData.bottom}`;
        }

        if (previewData.language) {
            paramUrl += `&language=${previewData.language}`;
        }

        return `${fullCodeComment}\n<script \n\ttype="module" \n\tsrc="${paramUrl}">\n</script>`;
    }, [hash, type, isBtnNeeded, previewData.width, previewData.height, previewData.right, previewData.bottom, previewData.language, previewData.btnImg]);

    return scriptCode;
};

export default useScriptCode;

