import {useCallback, memo, useEffect, useState} from "react";
import {Collapse, Fade, Paper} from "@mui/material";
import {shallowEqual, useSelector} from "react-redux";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import ToolsList from "./components/ToolsList";
import api from "_services/api";

const MAX_FILES_ALLOWED = 1;

const CustomTools = ({modelId, isOpen, closeDialog}) => {

    const {smallScreen} = useIsSmallScreen();
    const [isLoading, setIsLoading] = useState(true);
    const [tools, setTools] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                let customFunctions = [];
                let defaultFunctions = [];

                const response = await api.get(`/custom-functions/list/${modelId}`);

                if (response.code === 200 && Array.isArray(response.functions)) {
                  const updatedCustomFunctions = response.functions.reduce((acc, current) => {
                    if (!acc.some(item => item.id === current.id)) {
                        acc.push(current);
                    }
                    return acc;
                  }, []);
                  customFunctions = updatedCustomFunctions;
                }

                const responseDefault = await api.get(`/custom-functions/list/default`);

                if (responseDefault.code === 200 && Array.isArray(responseDefault.functions)) {
                  const customIds = customFunctions.map(item => item.id);
                  const updatedDefaultFunctions = responseDefault.functions.map(item => ({...item, isActive: 0}));
                  defaultFunctions = updatedDefaultFunctions.filter(item => !customIds.includes(item.id))
                }

                setTools([...defaultFunctions, ...customFunctions]);

            } catch (e) {
                setTools([])
            } finally {
                setIsLoading(false);
            }

        }

        if (isOpen) {
            fetchData()
        }


    }, [isOpen]);

    // if(tools === 'loading') {
    //     return <></>
    // }


    if (smallScreen) {
        return (
            <Collapse in={isOpen} unmountOnExit sx={{padding: '0 5%', boxShadow: 'rgba(0,0,0, .1) 0px -7px 12px -4px'}}>
                <ToolsList modelId={modelId} smallScreen={smallScreen} tools={tools} isLoading={isLoading}/>
                {/*<SearchList {...{ files, fileSources, handleSelectItem, modelId, prices, smallScreen, isFilesAllowed, closeDialog, isLoading }} />*/}
            </Collapse>
        )
    }

    return (
        <Fade in={isOpen} unmountOnExit style={{boxShadow: 'none', position: 'absolute', bottom: 0, right: 0, left: 0}}>
            <Paper sx={{zIndex: 3}}>
                <ToolsList modelId={modelId} smallScreen={smallScreen} tools={tools} isLoading={isLoading}/>
                {/*<SearchList {...{ files, fileSources, handleSelectItem, modelId, prices, smallScreen, isFilesAllowed, closeDialog, isLoading }} />*/}
            </Paper>
        </Fade>
    );
};

export default memo(CustomTools, (prevProps, nextProps) => {
    return prevProps.modelId === nextProps.modelId && prevProps.isOpen === nextProps.isOpen;
});
