import { Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import api from "_services/api";
import InteractWithUser from "../InteractWithUser";

const ResetPasswordMemo = ({ email = "" }) => {
  let { user } = useSelector((state) => {
    return state.auth;
  });

  const [status, setStatus] = useState({
    isOpen: false,
    msg: "",
    type: "error",
  });

  return (
    <Paper
      // className={styles["paper-container"]}
      variant="outlined"
      sx={{
        my: { xs: 3, md: 6 },
        p: { xs: 2, md: 3 },
        maxWidth: "600px",
        margin: "150px auto",
      }}
    >
      <Typography component="h1" variant="page_title" align="center">
        Check your email inbox!
      </Typography>

      <>
        <Typography variant="subtitle1" gutterBottom>
          An email has been sent to <b><i>{email}</i></b> for resetting your password. It could take up to 2 minutes. If you can't find it, please check your spam or junk folders.
        </Typography>

    </>
      {status.isOpen && (
        <InteractWithUser
          msg={status.msg}
          display={status.isOpen}
          type={status.type}
        />
      )}
    </Paper>
  );
};

export default ResetPasswordMemo;
