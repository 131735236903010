import Grid from "@mui/material/Grid";
import {memo, useMemo} from "react";
import {ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import TrainStatus from "components/showcase/TrainStatus";
import getLinkType from "../helpers/getLinkType";
import SimpleCheckbox from "components/inputs/checkboxes/SimpleCheckbox";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {useDispatch} from "react-redux";
import FlexWrapper from "components/wrappers/FlexWrapper";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {sendUIEvent} from "events/analytics/google";

const Link = ({
                  sort = '', item, handleRedirect = path => {
    }, setItemToggle = () => {
    }, isTrained = false,
                  isSelected = false,
                  isRedirectAllowed = true
              }) => {


    const domain = item?.relate_data?.domain || item?.domain || item.data || item.uri || 'no information';
    const isBrainCell = item.data_type === 'link_resource_type';

    const statusType = useMemo(() => {
        return getLinkType(sort, item, isTrained);
    }, [sort, item, isTrained]);


    const checkboxAllowed = sort !== 'trained';


    const handleItemCheckboxClick = (e) => {

        // e.preventDefault();
        e.stopPropagation();
        const isSelected = e.target.checked;
        setItemToggle({itemId: item.id, isSelected: isSelected});

    }

    const handleRowSelect = (e) => {

        // e.preventDefault();


        // e.stopPropagation();

        let path = ''

        if (isBrainCell) {
            // const splitData = item?.relate_data_id.split('_')
            // const site_id = splitData[0];
            // const contentId = splitData[1];

            path = `/training/website/trained/${item.id}`;
            // path = `/training/website/${site_id}/${contentId}`;
        } else if (sort !== 'archive') {
            path = `/training/website/${item.site_id}/${item.id}`;
        } else {
            path = `/training/website/${item.id}`;
        }


        handleRedirect(path);
        sendUIEvent({name: `website_tool_view_item`});

    }

    return (
        <ListItem xs={12}
                  secondaryAction={
                      <FlexWrapper>
                          <TrainStatus status={statusType}/>
                          {isRedirectAllowed && <IconButton
                              onClick={handleRowSelect}
                              size="small"
                              sx={{marginLeft: '20px !important'}}
                          >
                              <VisibilityIcon/>
                          </IconButton>
                          }
                      </FlexWrapper>
                  }
                  disablePadding
                  divider
                  dense
        >
            <ListItemButton dense
                // onClick={handleRowSelect}
            >
                {checkboxAllowed &&
                    <ListItemIcon sx={{minWidth: '24px', maxWidth: '24px'}}>
                        <SimpleCheckbox checked={isSelected} onClick={handleItemCheckboxClick} edge="start"
                                        size="small"/>
                    </ListItemIcon>
                }
                {/*    <ListItemIcon>*/}
                {/*        /!*<AccountTreeIcon fontSize="small" edge="start"/>*!/*/}
                {/*    </ListItemIcon>*/}

                {/*}*/}

                <ListItemText
                    primary={item.title}
                    secondary={
                        <Typography
                            // sx={{display: 'inline'}}
                            variant="truncate_text_150w"
                            // color="text.primary"
                            maxWidth="80%"
                        >
                            {domain}
                        </Typography>

                    }
                />
            </ListItemButton>

            {/*{item.id}*/}


            {/*<ListItemAvatar>*/}
            {/*    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />*/}
            {/*</ListItemAvatar>*/}


        </ListItem>
    );
}

export default memo(Link);