import { useState } from 'react';
import api from '_services/api';

const useGetMarketplaceProductCard = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const [marketplaceData, setMarketplaceData] = useState(null);
  const [data, setData] = useState(null);
  const [showMoreEnabled, setShowMoreEnabled] = useState(null);

  const handleGetMarketplaceProductCard = (modelHash, redirectCallback = false, successCallback = false) => {
    setDataLoading(true);
    api
      .get(`/marketplace/card/${modelHash}`)
      .then((res) => {
        setDataLoading(false);
        
        if (res.code >= 400) {
          setDataLoading(false);
          return;
        }

        if (res.data?.enable_monetization === 0 && redirectCallback) {
          redirectCallback();
        }
        setData(res.data);
        if (res.data.description.length > 200) {
          // setIsShortDescription(true);
          setShowMoreEnabled(true);
        }

        if (successCallback) {
          successCallback(res.data);
        }

        setMarketplaceData(res.data);
      })
      .catch(() => {
        setDataLoading(false);
      });
  };

  return { handleGetMarketplaceProductCard, dataLoading, data, marketplaceData, showMoreEnabled };
};

export default useGetMarketplaceProductCard;
