import { useState } from 'react';
import api from "_services/api";

const useGetModelInstructions = () => {
  const [data, setData] = useState(null);
  const [isGettingModelInstructions, setIsGettingModelInstructions] = useState(false);

  const handleGetModelInstructions = () => {
    setIsGettingModelInstructions(true);
    setData(null);
    api
      .get(`/model/instruction/list`)
      .then((res) => {
        setIsGettingModelInstructions(false);
        if (res?.list) {
          setData(res?.list);
        } else {
          setData([]);
        }
      })
      .catch(() => {
        setIsGettingModelInstructions(false);
        setData(null);
      });
  };

  return { handleGetModelInstructions, isGettingModelInstructions, data };
};

export default useGetModelInstructions;