import { useDispatch } from "react-redux";
import { interactWithUser } from "redux/status";

const useCopyToDevice = (data = "") => {
 const dispatch = useDispatch();

 const CopyToClipboard = async (data) => {
  if ("clipboard" in navigator) {
   await navigator.clipboard.writeText(data);
  } else {
   document.execCommand("copy", true, data);
  }

  dispatch(
   interactWithUser({ type: "info", message: "Coppied!" })
  );
 }

 return { copy: CopyToClipboard }
};

export default useCopyToDevice;