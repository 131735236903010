import React from 'react';

function FileIcons({icon}) {
    const brainAdd = (
        <svg width="23.413" height="20" viewBox="0 0 23.413 20">
        <g transform="translate(-13791.866 6465.5)">
            <path d="M8.125,0A2.493,2.493,0,0,0,5.711,1.883c-.031,0-.055-.008-.086-.008a2.5,2.5,0,0,0-2.5,2.5,2.3,2.3,0,0,0,.066.547A3.1,3.1,0,0,0,1.574,9.176a3.113,3.113,0,0,0,.355,5.586,2.862,2.862,0,0,0-.055.551,2.811,2.811,0,0,0,2.812,2.812,2.649,2.649,0,0,0,.469-.047,2.806,2.806,0,0,0,5.469-.891V2.5A2.5,2.5,0,0,0,8.125,0ZM22.5,11.875a3.109,3.109,0,0,0-1.574-2.7,3.074,3.074,0,0,0,.324-1.363,3.125,3.125,0,0,0-1.941-2.891,2.477,2.477,0,0,0-2.434-3.047c-.031,0-.059.008-.086.008a2.5,2.5,0,0,0-4.914.617V17.187a2.806,2.806,0,0,0,5.469.891,2.649,2.649,0,0,0,.469.047,2.811,2.811,0,0,0,2.812-2.812,2.863,2.863,0,0,0-.055-.551A3.125,3.125,0,0,0,22.5,11.875Z" transform="translate(13791.866 -6465.5)" fill="#666"/>
            <g transform="translate(13804.827 -6456.772)" fill="#666" stroke="#fff" strokeWidth="0.5">
            <ellipse cx="4.976" cy="4.976" rx="4.976" ry="4.976" stroke="none"/>
            <ellipse cx="4.976" cy="4.976" rx="5.226" ry="5.226" fill="none"/>
            </g>
            <g transform="translate(13806.297 -6455.303)">
            <path d="M6.115.295H-.1A.4.4,0,0,1-.5-.1.4.4,0,0,1-.1-.5H6.115a.4.4,0,0,1,.4.4A.4.4,0,0,1,6.115.295Z" transform="translate(0.5 3.609)" fill="#fff"/>
            <path d="M-.1,6.513a.4.4,0,0,1-.4-.4V-.1A.4.4,0,0,1-.1-.5a.4.4,0,0,1,.4.4V6.115A.4.4,0,0,1-.1,6.513Z" transform="translate(3.609 0.5)" fill="#fff"/>
            </g>
        </g>
        </svg>

    )
    const brianSuccess = (
        <svg width="23.413" height="20" viewBox="0 0 23.413 20">
        <g transform="translate(-13436.366 6577.664)">
            <path d="M8.125,0A2.493,2.493,0,0,0,5.711,1.883c-.031,0-.055-.008-.086-.008a2.5,2.5,0,0,0-2.5,2.5,2.3,2.3,0,0,0,.066.547A3.1,3.1,0,0,0,1.574,9.176a3.113,3.113,0,0,0,.355,5.586,2.862,2.862,0,0,0-.055.551,2.811,2.811,0,0,0,2.812,2.812,2.649,2.649,0,0,0,.469-.047,2.806,2.806,0,0,0,5.469-.891V2.5A2.5,2.5,0,0,0,8.125,0ZM22.5,11.875a3.109,3.109,0,0,0-1.574-2.7,3.074,3.074,0,0,0,.324-1.363,3.125,3.125,0,0,0-1.941-2.891,2.477,2.477,0,0,0-2.434-3.047c-.031,0-.059.008-.086.008a2.5,2.5,0,0,0-4.914.617V17.187a2.806,2.806,0,0,0,5.469.891,2.649,2.649,0,0,0,.469.047,2.811,2.811,0,0,0,2.812-2.812,2.863,2.863,0,0,0-.055-.551A3.125,3.125,0,0,0,22.5,11.875Z" transform="translate(13436.366 -6577.664)" fill="#666"/>
            <g transform="translate(13449.327 -6568.937)" fill="#38953f" stroke="#fff" strokeWidth="0.5">
            <ellipse cx="4.976" cy="4.976" rx="4.976" ry="4.976" stroke="none"/>
            <ellipse cx="4.976" cy="4.976" rx="5.226" ry="5.226" fill="none"/>
            </g>
            <path d="M12045.408-4750.695l-2.154-2.4a.31.31,0,0,1,.023-.438.309.309,0,0,1,.438.023l1.635,1.822,2.635-3.731a.312.312,0,0,1,.434-.075.313.313,0,0,1,.074.433Z" transform="translate(1408.442 -1810.837)" fill="#fff"/>
        </g>
        </svg>
    )
    switch (icon) {
        case "brainAdd":
          return brainAdd;
        case "brianSuccess":
          return brianSuccess;
        default:
          return null;
      }
}

export default FileIcons;