import { useState, memo } from "react";
import { Grid, Box } from "@mui/material";
import { Typography } from "@mui/material";
import styles from "../share-model.module.css";
import Avatar from "@mui/material/Avatar";
import { useDispatch } from "react-redux";
import PersonIcon from '@mui/icons-material/Person';

import MarkdownComponent from "components/MarkdownComponent";
import { getInitials } from "utils/prepareNames";

const Message = ({ msg_id, msg, userInfo = {}, modelName = "" ,children = null}) => {
  const dispatch = useDispatch();
  const [isEditing, setEditing] = useState(false);
  const [isEditLoading, setEditLoading] = useState(false);
  const [editValue, setEditValue] = useState(msg.message);


  const actionElements = children != null;


  return (
    <Grid
      container
      sx={{ padding: { md: "20px 50px 20px 60px", xs: "20px 0" } }}
      msg_id={msg_id}
      className={
        styles["msg-box"] +
        " " +
        (msg.type === "prompt" ? styles["user"] : styles["ai"])
      }
    >
      
      <Grid item xs={1} sx={{ display: "flex", justifyContent: "end" }}>
        {msg.type === "prompt" ? (
          <Avatar
            className={styles["avatar"]}
            src={userInfo.avatar ?? ""}
            sx={{ fontSize: 15 }}
          >
            {userInfo.initial === ''? <PersonIcon/>:userInfo.initial}
          </Avatar>
        ) : (
          <Avatar
            className={styles["avatar"]}
            sx={{ backgroundColor: "rebeccapurple" }}
          >
            {modelName}
          </Avatar>
        )}
      </Grid>

      <Grid
        item
        xs={10}
        md={actionElements ? 9.5 : 11}
        px={{ xs: 2, md: 6 }}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography className={styles["inner-msg"]} variant="messages">
          <MarkdownComponent source={msg.message} msgId={msg.id} chatId={msg.chat_id} />
        </Typography>
      </Grid>

      {actionElements &&
        <Grid item md={1.5}>
        {children}
        </Grid> }
    
      
    </Grid>
  );
};


export default Message;

// export default memo(Message, (prevProps, nextProps) => {
//   return prevProps.msg === nextProps.msg;
// });
