import { useState, useEffect } from 'react';
import classnames from 'classnames';
import styles from './logo.module.css';

const Logo = ({ stage, isLoading, textInputText }) => {
  const [tick, setTick] = useState(false);
  useEffect(() => {
    if (textInputText.length) {
      setTick(true);
      setTimeout(() => {
        setTick(false);
      }, 100);
    }
  }, [textInputText]);

  return (
    <div
      className={
        classnames(
          styles.wrapper,
          stage === 1 && styles.firstStage,
          stage === 2 && !isLoading && styles.secondStage,
          (stage === 3 || isLoading) && styles.thirdStage,
        )
      }
    >
      <div className={classnames(styles.line, styles.line1)} />
      <div className={classnames(styles.line, styles.line2)} />
      <div className={classnames(styles.line, styles.line3)} />
      <div className={classnames(styles.line, styles.line4)} />
      <div className={classnames(styles.line, styles.line5)} />
      <div className={classnames(styles.smallDot, styles.dot1)} />
      <div className={classnames(styles.smallDot, styles.dot2)} />
      <div className={classnames(styles.smallDot, styles.dot3)} />
      <div className={classnames(styles.smallDot, styles.dot4)} />
      <div className={classnames(styles.smallDot, styles.dot5)} />
      <div className={classnames(styles.smallDot, styles.dot6)} />
      <div className={classnames(styles.smallDot, styles.dot7)} />
      <div className={classnames(styles.smallDot, styles.dot8)} />
      <div className={classnames(styles.smallDot, styles.dot9)} />
      <div className={classnames(styles.smallDot, styles.dot10)} />
      <div className={classnames(styles.smallDot, styles.dot11)} />
      <div className={classnames(styles.smallDot, styles.dot11)} />
      <div className={classnames(styles.smallDot, styles.dot12)} />
      <div className={classnames(styles.smallDot, styles.dot13)} />
      <div className={classnames(styles.smallDot, styles.dot14)} />
      <div className={classnames(styles.blue, isLoading && styles.loading)} />
      <div className={classnames(styles.purple, isLoading && styles.loading)} />
      <div className={classnames(
        styles.bigDot,
        isLoading && styles.loading,
        tick && styles.tick,
        // bouncing && !tick && !isLoading && styles.bouncing,
      )} />
    </div>
  )
}

export default Logo;
