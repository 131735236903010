import {memo, useMemo} from "react";
import {useSelector} from "react-redux";
import {Box, Fade} from "@mui/material";
import {DynamicLogo} from "../../../loadings/BrandLoading";

const MiddleBrain = memo(({shouldAppear}) => {


    // const aiMessage = useSelector(state => state.messages.session.ai);
    const loadingStage = useSelector(state => state.messages.session.loading.stage);
    const isMsgSending = useSelector(state => state.messages.session.loading.isMsgSending);
    const tickNum = useSelector(state => state.messages.session.loading.tick);
    // const loadingStage = 2;
    // const isMsgSending = false




    if(!shouldAppear) {
        return
    }


    return (
        // <Fade in={shouldAppear} timeout={800}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    width: "60px",
                    height: "25px",
                    zIndex: 2,
                    top: "-15px",
                }}
            >
                {shouldAppear && <DynamicLogo tickNum={tickNum} stage={loadingStage} isLoading={isMsgSending} size={'small'}/>}
                {/*<FullAnimationLoading stage={stage} isLoading={isLoading} textInputText={txt}/>*/}
            </Box>
        // </Fade>
    )
});


export default MiddleBrain;