import Button from "@mui/material/Button";
import {getToken, setToken} from "_helpers/tokenManagement";
import {REDIRECT_AUTH_DATA_PREFIX} from "../../../../constants";
import {memo} from "react";
import styled from "@emotion/styled";
import {GoogleBtnStyle, GoogleIcon} from "../../../auth/GoogleAuthBtn/GoogleBtnStyle";
import {FacebookBtnStyle} from "../../../auth/FacebookAuthBtn/FacebookBtnStyle";
import FacebookIcon from "@mui/icons-material/Facebook";

const DefaultAuthBtn = styled.a`
    display: flex;
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 8px 10px !important;
    border: none;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px -1px 0px, rgba(0, 0, 0, 0.25) 0px 1px 3px;
    color: #757575;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    ${({fullWidth}) => fullWidth ? `
    justify-content: center;
    width: 100%;
  ` : `
    max-width: 240px;
  `}
    ${({width}) => width ? `
    width: ${width}px;
  ` : ''}
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.08) 0px -1px 0px, rgba(0, 0, 0, 0.25) 0px 2px 5px;
    }

    &:active {
        background-color: #eeeeee;
    }

    &:focus {
        outline: none;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
        0 0 0 3px #c8dafc;
    }

    ${({disabled}) => disabled ? `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  ` : ''}
`;

const AuthRedirect = ({provider, msgId, chatId, href, state, isStream = false}) => {


    const handleRedirect = (e) => {

        const currentModel = getToken('cachedModel','json')

        let currentTimeStamp = new Date().getTime();
        let fiveMinutes = 5 * 60 * 1000;

        const redirectUrl = `playground/chat/${chatId}?regenerateMsg=${msgId}`;

        let data = {
            status: 'pending',
            redirectUrl: redirectUrl,
            msgId,
            chatId,
            timeStamp: currentTimeStamp,
            expData: currentTimeStamp + fiveMinutes,
            modelId: currentModel.id
        };

        const dataStr = JSON.stringify(data);
        setToken(`${REDIRECT_AUTH_DATA_PREFIX}${state}`, dataStr);

        const normalizedHref = href.replace(/[&?]action_required_oauth_younet=true/,'');
        window.location.href = normalizedHref;

    }

    if(provider === 'google') {
        return <GoogleBtnStyle disabled={isStream} width="190" sx={{textTransform: 'none'}} variant="outlined" onClick={handleRedirect}><GoogleIcon/>Sign in with Google</GoogleBtnStyle>;
    } else if (provider === 'facebook')  {
        return  <FacebookBtnStyle disabled={isStream} width="210" sx={{textTransform: 'none'}} variant="outlined" onClick={handleRedirect}><FacebookIcon/>Sign in with Facebook</FacebookBtnStyle>
    } else {
        // return  <DefaultAuthBtn disabled={isStream} width="150" sx={{textTransform: 'none'}} variant="outlined" onClick={handleRedirect}>Click to Authorize</DefaultAuthBtn>
        return  <Button disabled={isStream} width="150" sx={{textTransform: 'none'}} variant="outlined" onClick={handleRedirect}>Click to Authorize</Button>
    }


}

export default memo(AuthRedirect);