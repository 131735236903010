import { useState, useEffect, useRef } from "react";
import {
  Paper,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
  TableContainer,
} from "@mui/material";
import Row from './components/Row';
import { useDispatch, useSelector } from "react-redux";
import { getList } from "redux/invitations";
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Invitations = () => {
  const dispatch = useDispatch();
  const { currentModel, loading, list } = useSelector((state) => ({
    currentModel: state.models.current,
    loading: state.invitations.loading,
    list: state.invitations.list,
  }));

  useEffect(() => {
    dispatch(getList(currentModel.id));
  }, []);

  return (
    <Grid
      container
      spacing={6}
    >
      <Grid item xs={12} md={12} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <Typography px={2} sx={{fontSize:24}}>Email Invitations</Typography>
        <GeneralTooltip
          placement='bottom'
          title="Curated question and answer pairs used to train AI models, enabling accurate responses, personalized interactions, and improved user experiences. All questions and responses that are being selected to be trained are stored here."
        >
          <HelpOutlineIcon sx={{color:'#B5B5B5'}}/>
        </GeneralTooltip>
      </Grid>

      <Grid item xs={12} md={12} mx={4}>
        <TableContainer
          component={Paper}
          px={2}
        >
          <Table aria-label="collapsible table" fixedheader="true" fixedfooter="true" px={2}>
            <TableBody px={2}>
              {loading && (
                <>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {Boolean(list.length) && !loading && (
                list.map((row => (
                  <Row key={row.id} row={row} />
                )))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Invitations;

