import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "_services/api";
import formatTime from "utils/formatTime";
import { decodeData } from "utils/formatContent";
import { useDispatch } from "react-redux";
import { interactWithUser } from "redux/status";
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import MarkdownComponent from "components/MarkdownComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import BrainCell from "components/BrainCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ViewEmailContent = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isExpanded, setExpand] = useState(false);

  var savedData = "";

  const handleEditorChange = (data) => {
    savedData = data;
  };

  const handleEditClick = () => {
    setEditMode(true);
    savedData = "";
  };

  const handleExpansion = () => {
    setExpand(!isExpanded);
  };

  const handleEditClose = () => {
    setEditMode(false);
    savedData = "";
  };
  const handleSave = async () => {

    let params = {
      text: savedData,
      title: data.title,
      dataId: data.id,
      modelId: data.model_id,
    };

    setLoading(true);
    await api
      .put("/learning/text", params)
      .then((r) => {
        setLoading(false);

        // if (r.code >= 400) {
        //   dispatch(
        //     interactWithUser({
        //       message: "Could not update this brain knowledge",
        //       type: "error",
        //     })
        //   );
        // } else {
        //   dispatch(
        //     interactWithUser({
        //       message: "Text Knowledge was updated successfully",
        //       type: "success",
        //     })
        //   );
        // }

        setEditMode(false);
        setLoading(false);
      })
      .catch((error) => {
        // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
        setLoading(false);
        setEditMode(false);
      });
  };

  const handleDeleteRows = (id) => {
    // let splittedId = id.split("_");
    // let realId = splittedId[1] || "";
    const deleteData = async () => {
      //  let params = {
      //   messageIds: [realId],
      //   modelId: data.modelId
      // };

      // dispatch(
      //   interactWithUser({
      //     message: "Waiting for Mykola to make delete api -_-",
      //     type: "info",
      //   })
      // );
    };

    deleteData();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await api
        .get(`brain/get/${id}`)
        .then((r) => {
          if (r.code == 200) {
            setData(r.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
        });
    };

    fetchData();
  }, []);

  const handleChunkDelete = (vector_id) => {
    let chunksArr = data.chunks.filter((item) => item.vector_id !== vector_id);
    setData((prevState) => ({
      ...prevState,
      chunks: chunksArr,
    }));
  };

  const calculatedDate = data.created_at
    ? formatTime({ date: data.created_at.date })
    : "";
  const textData = data.data ? decodeData(data.data) : "";
  const titleData = data.title ? decodeData(data.title) : "";

  return (
    <Grid
      container
      sx={{ height: "inherit", overflowY: "auto", padding: "0 20px" }}
    >
      <Grid item md={11}>
        <Typography variant="view_data_title">Brain Cells</Typography>
      </Grid>
      <Grid
        item
        md={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="view_data_time">
          {loading ? (
            <Skeleton
              sx={{ maxWidth: "120px" }}
              variant="text"
              animation="wave"
            />
          ) : (
            calculatedDate
          )}
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={12} sx={{ p: 4 }}>
        {loading ? (
          <>
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
          </>
        ) : (
          data.chunks &&
          data.chunks.map((item, index) => (
            <BrainCell
              key={index}
              item={item}
              onDelete={(id) => {
                handleChunkDelete(id);
              }}
            />
          ))
        )}
      </Grid>

      <Grid item md={12} sx={{ textAlign: "right" }}>
        {isEditMode ? (
          <>
            {/* <IconButton onClick={handleEditClose}>
            <CloseIcon />
          </IconButton> */}
            {/* <IconButton onClick={() => handleSave()}>
              <SaveIcon />
            </IconButton> */}
          </>
        ) : (
          <>
            {/* <IconButton onClick={() => handleDeleteRows(data.id)}>
              <DeleteIcon color="icons" />
            </IconButton> */}
          </>
        )}
      </Grid>
      <Grid item md={11}>
        <Typography variant="view_data_content">Raw Data:</Typography>
      </Grid>
      <Grid item md={1} sx={{ textAlign: "right" }}>
        <IconButton onClick={() => handleExpansion()}>
          {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Grid>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Grid item md={12}>
          <Typography variant="view_data_title">
            {loading ? <Skeleton variant="text" animation="wave" /> : titleData}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>

        <Grid item md={12} sx={{ p: 4 }}>
          {loading ? (
            <>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </>
          ) : isEditMode ? (
            <CustomMdEditor
              value={textData}
              onChange={handleEditorChange}
              customHeight="100%"
            />
          ) : (
            <MarkdownComponent source={textData} />
          )}
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default ViewEmailContent;
