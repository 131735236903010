import {createSlice} from "@reduxjs/toolkit";
import {
    getTrainedList,
    getFullDataById,
    getAllLinks,
    parseWebsite,
    getNestedList,
    getTrainedDataById,
    updateLinkContent, approveOrDisapproveById, getPendingList, deleteBrainItem
} from "./linksThunk";
import {splitArrayIntoChunks} from "utils/arrayManipulation";
import {decodeData} from "utils/formatContent";

const DEFAULT_PAGE_ID = 1;
const DEFAULT_FILTER = 'trained';


const initialPaginationState = {
    pagination: {

        amount: 0,
        page: {
            id: 1,
            prev: 0,
            next: 0
        },
        totalAmount: -1
        // tokens: {
        //     1: null
        // }
    }
}

const initialState = {
    modelId: 0,
    parse: {
        task: [],
        errors: [],
        pages: [],
        loading: false,

    },
    content: {
        loading: false,
        syncCounter: 0,
        // do cache sys later for items
        fullDataItems: {
            loading: false,


        },
        list: {
            archive: {
                ...initialPaginationState,
            },
            trained: {
                ...initialPaginationState,
            },
            new: {
                ...initialPaginationState,
            },
            pending: {
                pendingNumber: 0,
                ...initialPaginationState,
            }
        },
        sort: {
            maps: {
                trainedIds: {},
                selectedIds: {},
                isAllSelected: false
            },
            loading: false,
            current: {id: DEFAULT_FILTER, prev: DEFAULT_FILTER},
            filter: [
                'new',
                'trained',
                'pending',
                'archive'],
        },
        syncCount: 0,
        maxRows: 10,
        deeperViewAllowed: true

    },
    loading: false
};

export const linksSlice = createSlice({
    name: "links",
    initialState,
    reducers: {
        deeperViewAllowed(state, action) {
            state.content.deeperViewAllowed =  action.payload;
        },

        refreshList(state) {
            const currentSortId = state.content.sort.current.id;
            state.content.list[currentSortId] = {...initialPaginationState}
            state.content.syncCount = state.content.syncCount + 1;
        },
        cleanAllLinks(state, action) {
            state.content = initialState.content;
            state.parse = initialState.parse;
        },
        deleteItemChunk(state, action) {

            const {itemId, chunkId} = action.payload;

            const allChunks = state.content.fullDataItems[itemId].chunks;
            let updatedArr = allChunks.filter(item => item.vector_id !== chunkId);

            state.content.fullDataItems[itemId].chunks = updatedArr;

        },
        changeFilter(state, action) {
            const value = action.payload;

            if (value === 'archive' || value === 'new' || value === 'trained' || value === 'pending') {
                state.content.sort.current.prev = value;
            }

            if (value) {
                state.content.sort.current.id = value;
                // state.content.sort.current.prev = value;
            } else {
                const prevSaved = state.content.sort.current.prev;
                state.content.sort.current.id = prevSaved;
            }
            // state.content.sort.current.id = value ?? DEFAULT_FILTER;
        },
        setItemToggle(state, action) {
            const {itemId, isSelected} = action.payload;

            // const selectedIds = Object.fromEntries(Object.entries(allEmails).map(([key, value]) => [value.id, true]));

            // state.content.selected = selectedIds;
            // const {dataId} = action.payload;
            const isExist = !!state.content.sort.maps.selectedIds[itemId];
            //
            //


            if (isExist && !isSelected) {
                delete state.content.sort.maps.selectedIds[itemId];
                return;
            }

            const updatedSelected = {
                ...state.content.sort.maps.selectedIds,
                [itemId]: true
            };


            state.content.sort.maps.selectedIds = updatedSelected

        },
        unselectAll(state, action) {
            state.content.sort.maps.selectedIds = {};
            state.content.sort.maps.isAllSelected = false;
        },
        selectAll(state, action) {
            const sortId = state.content.sort.current.id;
            const currentPageId = state.content.list[sortId].pagination.page.id;
            const items = state.content.list[sortId][currentPageId];
            const selectedIds = Object.fromEntries(items.map(({id}) => [id, true]));
            state.content.sort.maps.selectedIds = selectedIds;
            state.content.sort.maps.isAllSelected = true;


        },
        tableNavigation(state, action) {
            const {pageId, currentSortId} = action.payload;
            state.content.list[currentSortId].pagination.page.id = pageId;
        },


    },
    extraReducers: (builder) => {


        builder.addCase(deleteBrainItem.pending, (state, {_}) => {
            state.content.fullDataItems.loading = true;
            state.content.loading = true;
        });
        builder.addCase(deleteBrainItem.rejected, (state, {_}) => {
            state.content.fullDataItems.loading = false;
            state.content.loading = false;
        });
        builder.addCase(deleteBrainItem.fulfilled, (state, {payload}) => {
            const {brainId} = payload;

            const sortId = state.content.sort.current.id;
            const currentPageId = state.content.list[sortId].pagination.page.id;
            const list = state.content.list.trained[currentPageId];
            let updatedArr = list.filter(item => item.id !== brainId);

            state.content.list.trained[currentPageId] = updatedArr;

            state.content.fullDataItems.loading = false;
            state.content.loading = false;
        });

        builder.addCase(approveOrDisapproveById.pending, (state, {_}) => {
            state.content.fullDataItems.loading = true;
            state.content.loading = true;
        });
        builder.addCase(approveOrDisapproveById.rejected, (state, {_}) => {
            state.content.fullDataItems.loading = false;
            state.content.loading = false;
        });
        builder.addCase(approveOrDisapproveById.fulfilled, (state, {payload}) => {
            const {isApproved, data} = payload;
            // refreshing table
            state.content.list.trained = {...initialPaginationState}
            state.content.syncCount = state.content.syncCount + 1;
            let trainedItemsIds = state.content.sort.maps.trainedIds;
            if(isApproved) {


                data.map((item) => {
                    trainedItemsIds[item] = true
                });

                state.content.sort.maps.trainedIds = trainedItemsIds;

            } else {

                data.forEach(key => {
                    if (trainedItemsIds.hasOwnProperty(key)) {
                        delete trainedItemsIds[key];
                    }
                });

                state.content.sort.maps.trainedIds = trainedItemsIds;
            }
            // const itemValues = {
            //     title: decodeData(data.title),
            //     content: decodeData(data.content),
            // }
            //
            // state.content.fullDataItems[data.id] =
            //     {...state.content.fullDataItems[data.id], ...itemValues};
            //
            state.content.fullDataItems.loading = false;
            state.content.loading = false;
        });

        builder.addCase(updateLinkContent.pending, (state, {_}) => {
            state.content.fullDataItems.loading = true;
        });
        builder.addCase(updateLinkContent.rejected, (state, {_}) => {
            state.content.fullDataItems.loading = false;
        });
        builder.addCase(updateLinkContent.fulfilled, (state, {payload}) => {
            const {data} = payload;

            const itemValues = {
                title: decodeData(data.title),
                content: decodeData(data.content),
            }

            state.content.fullDataItems[data.id] =
                {...state.content.fullDataItems[data.id], ...itemValues};

            state.content.fullDataItems.loading = false;
        });

        builder.addCase(getTrainedDataById.pending, (state, {_}) => {
            state.content.fullDataItems.loading = true;
        });
        builder.addCase(getTrainedDataById.rejected, (state, {_}) => {
            state.content.fullDataItems.loading = false;
        });
        builder.addCase(getTrainedDataById.fulfilled, (state, {payload}) => {
            const {data} = payload;

            const id = data.relate_data_id.split('_')[1];

            const sortedChunks = data.chunks?.sort((a, b) => a.position - b.position) || [];

            const item = {
                id: id,
                brainId: data.id,
                title: decodeData(data.title),
                content: decodeData(data.data),
                domain: data?.relate_data?.domain || '',
                task: data.task,
                chunks: sortedChunks
            }

            state.content.fullDataItems[data.id] = item;
            state.content.fullDataItems.loading = false;

        });

        builder.addCase(getFullDataById.pending, (state, {_}) => {
            state.content.fullDataItems.loading = true;
        });
        builder.addCase(getFullDataById.rejected, (state, {_}) => {
            state.content.fullDataItems.loading = false;
        });
        builder.addCase(getFullDataById.fulfilled, (state, {payload}) => {
            const {data} = payload;

            const item = {
                id: data.id,
                title: data.title,
                content: data.content,
                domain: data?.uri || '',
                task: data.task || false,
                siteId: data.site_id,
            }
            state.content.fullDataItems[data.id] = item;
            state.content.fullDataItems.loading = false;
        });

        builder.addCase(parseWebsite.pending, (state, {_}) => {
            state.parse.loading = true;
            state.content.loading = true;
        });

        builder.addCase(parseWebsite.rejected, (state, {_}) => {
            state.parse.loading = false;
            state.content.loading = false;
        });

        builder.addCase(parseWebsite.fulfilled, (state, {payload}) => {
            const {pages, task, errors} = payload;

            if (task.length !== 0) {
                state.content.list.pending = {...initialPaginationState}
                state.content.syncCount = state.content.syncCount + 1;
            } else {
                state.parse.pages = pages;
                // state.content
                // state.parse.task = task;
                state.parse.errors = errors;
                state.content.list.new.pagination.amount = pages.length;
                state.content.list.new.pagination.totalAmount = pages.length;
                state.content.list.new[DEFAULT_PAGE_ID] = pages;
            }

            state.parse.loading = false;
            state.content.loading = false;


        });


        builder.addCase(getPendingList.pending, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getPendingList.rejected, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getPendingList.fulfilled, (state, {payload}) => {
            const {tasks} = payload;
            const currentPageId = state.content.list.pending.pagination.page.id;
            const maxRows = state.content.maxRows;


            const pendingNumber = tasks.filter(item => item.status === 'site');


            const splitItems = splitArrayIntoChunks(tasks, maxRows);

            const result = {};

            splitItems.forEach((chunk, index) => {
                result[index + 1] = chunk;
            });

            state.content.list.pending = {
                ...state.content.list.pending,
                ...result
            }

            state.content.list.pending.pendingNumber = pendingNumber.length;
            state.content.list.pending.pagination.totalAmount = tasks.length;
            state.content.list.pending.pagination.amount = tasks.length;
            state.content.loading = false;

        });
        builder.addCase(getAllLinks.pending, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getAllLinks.rejected, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getAllLinks.fulfilled, (state, {payload}) => {
            const {all, errors, totalAmount} = payload;
            const currentPageId = state.content.list.archive.pagination.page.id;

            // state.content.list.all[currentPageId] = {
            //     ...state.content.list.all,
            //     [currentPageId]: all
            // };
            state.content.list.archive[currentPageId] = all;
            state.content.list.archive.pagination.totalAmount = totalAmount;
            state.content.list.archive.pagination.amount = all.length;
            state.content.loading = false;

        });

        builder.addCase(getNestedList.pending, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getNestedList.rejected, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getNestedList.fulfilled, (state, {payload}) => {
            const {all, totalAmount} = payload;

            const currentSort = state.content.sort.current.id;

            const nestedContent = state.content.list[currentSort];
            if (nestedContent) {
                const currentPageId = state.content.list[currentSort].pagination.page.id;

                state.content.list[currentSort] = {
                    ...state.content.list[currentSort],
                    [currentPageId]: {
                        ...state.content.list[currentSort][currentPageId],
                        ...all
                    }
                };
            } else {
                // state.content.list[currentSort] = {...initialPaginationState};
                state.content.list[currentSort] = {
                    pagination: {

                        amount: 0,
                        page: {
                            id: 1,
                            prev: 0,
                            next: 0
                        },
                        totalAmount: -1
                        // tokens: {
                        //     1: null
                        // }
                    }
                }
                state.content.list[currentSort][DEFAULT_PAGE_ID] = all
            }


            // const updatedList = {
            //     ...state.content.list[currentSort],
            //     [currentPageId]: all
            // };

            // return {
            //     ...state,
            //     content: {
            //         ...state.content,
            //         selected: updatedSelected
            //     }
            // };


            // state.content.list[currentSort] = {
            //     ...state.content.list[currentSort],
            //     state.content.list[currentSort]
            // }


            state.content.list[currentSort].pagination.totalAmount = totalAmount;
            state.content.list[currentSort].pagination.amount = all.length;
            state.content.loading = false;
        });


        // getTrainedList - getting list of trained items
        builder.addCase(getTrainedList.pending, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getTrainedList.rejected, (state, {_}) => {
            state.content.loading = false;
        });
        builder.addCase(getTrainedList.fulfilled, (state, {payload}) => {
            const {trained, totalAmount} = payload;
            const currentPageId = state.content.list.trained.pagination.page.id;


            let trainedItemsIds = {};
            trained.map((item) => {
                const supportData = item.relate_data_id?.split("_") || {};
                trainedItemsIds[supportData[1]] = supportData[0]
            });

            state.content.sort.maps.trainedIds = trainedItemsIds;

            const maxRows = state.content.maxRows;

            const splitItems = splitArrayIntoChunks(trained, maxRows);

            const result = {};

            splitItems.forEach((chunk, index) => {
                result[index + 1] = chunk;
            });

            state.content.list.trained = {
                ...state.content.list.trained,
                ...result
            }


            // state.content.list.trained[currentPageId] = trained;

            // state.content.list.trained[currentPageId] = {
            //     ...state.content.list.trained,
            //     [currentPageId]: trained
            // };

            state.content.list.trained.pagination.totalAmount = totalAmount || -1;

            state.content.list.trained.pagination.amount = trained.length;
            state.content.loading = false;

        });

    },
});

export const {
    changeFilter,
    tableNavigation,
    deleteItemChunk,
    setItemToggle,
    selectAll,
    unselectAll,
    cleanAllLinks,
    refreshList,
    deeperViewAllowed
} = linksSlice.actions;

export default linksSlice.reducer;
