import {Box, ListItemButton, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {useState} from "react";
import IosShareIcon from "@mui/icons-material/IosShare";
import {useNavigate} from "react-router-dom";

const ShareModelListBtn = ({setShareModelConfig}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const open = Boolean(anchorEl);

    const handleRedirect = (e, id) => {
        navigate(`/share/${id}`);
        // setShareModelConfig((prev) => ({
        //     ...prev,
        //     isExpanded: true,
        //     shareType: id
        // }));
    };

    const handleClose = () => {
        setAnchorEl(null);
    }


    return (
        <>
            <ListItemButton
                sx={{pl: 6}}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
            >
                <ListItemIcon sx={{minWidth: "40px"}}>
                    <IosShareIcon color="icons" width="20" height="20" sx={{position: 'relative', left: '-2px'}}/>
                </ListItemIcon>
            </ListItemButton>
            {
                open && (

                    <Menu
                        id="brain-mobile-menu"
                        width="100%"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 3,
                        }}
                    >
                        <Box width={200}>
                            <MenuItem
                                divider
                                sx={{
                                    fontSize: 14,
                                    padding: "10px 20px"
                                }}
                                onClick={(e) => handleRedirect(e, 'public')}
                            >Public</MenuItem>
                            <MenuItem
                                divider
                                sx={{
                                    fontSize: 14,
                                    padding: "10px 20px",
                                }}
                                onClick={(e) => handleRedirect(e, 'restricted')}
                            >Restricted</MenuItem>
                            <MenuItem
                                divider
                                sx={{
                                    fontSize: 14,
                                    padding: "10px 20px",
                                    justifyContent: "space-between",
                                }}
                                onClick={(e) => handleRedirect(e, 'chat-bot')}
                            >Chatbot</MenuItem>
                            <MenuItem
                                divider
                                sx={{
                                    fontSize: 14,
                                    padding: "10px 20px",
                                    justifyContent: "space-between",
                                }}
                                onClick={(e) => handleRedirect(e, 'monetization')}
                            >For a fee</MenuItem>
                            <MenuItem
                                divider
                                sx={{
                                    fontSize: 14,
                                    padding: "10px 20px",
                                    justifyContent: "space-between",
                                }}
                                onClick={(e) => handleRedirect(e, 'speak-to-product')}
                            >Speak to Product</MenuItem>
                        </Box>
                    </Menu>
                )}
        </>
    )


}


export default ShareModelListBtn;