import { Grid, Skeleton, Typography } from "@mui/material";

const MessageChunkSkeleton = ({ loading = false }) => {


    if (!loading) {
        return;
    }

    return (
        <Grid container>
            <Grid item md={12}>
                <Typography

                    variant="messages"
                >
                    <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ width: "100%" }}
                    />
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Typography
                    variant="messages"
                >
                    <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ width: "100%" }}
                    />
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Typography
                    variant="messages"
                >
                    <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ width: "100%" }}
                    />
                </Typography>
            </Grid>
        </Grid>
    )
}


export default MessageChunkSkeleton;