import { useSelector, shallowEqual } from "react-redux";
import { Collapse, Drawer, Box, Button, Typography } from "@mui/material";
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import {sendUIEvent} from "../../../../../events/analytics/google";

const Wrapper = ({ children, modelsMenuOpen, onCloseHandler, loadMoreHandler, loadMoreDisabled, leftBarMenu = false, widesidebar, createModelEnabled = true }) => {
  const { smallScreen } = useIsSmallScreen();
  const sharedCards = useSelector((state) => state.marketplace.sharedCards, shallowEqual);
  const marketplaceLoading = useSelector((state) => state.marketplace.loading, shallowEqual);

  if (smallScreen && !leftBarMenu) {
    return (
      <Drawer
        anchor="top"
        open={modelsMenuOpen}
        onClose={onCloseHandler}
        sx={{ top: '30px' }}
        PaperProps={{
          sx: {
             maxHeight: '70%'
          }
       }}
      >
        {children}
        {!marketplaceLoading && !Boolean(sharedCards?.pinnedItems.length) && !Boolean(sharedCards?.allItems.length) && widesidebar && (
          <Typography sx={{ fontSize: '0.875rem', padding: '10px 24px', textAlign: 'center' }}>
            Nothing found, create yours
          </Typography>
        )}
        {widesidebar && (
          <Box sx={{ padding: {xs: '5px 0px', md: '0 15px'} }}>            
            {/* <Button
              onClick={loadMoreHandler} 
              sx={{
                fontSize: { sm: '0.75rem', md: '0.8125rem' },
                color: '#7e7e7e',
              }}
              disabled={loadMoreDisabled}
            >Load more...</Button> */}
            <Button
              href="/portal/models/create"
              sx={{
                fontSize: { sm: '0.75rem', md: '0.8125rem' }
              }}
              onClick={() => {sendUIEvent({name: `create_your_ai_nav_click`})}}
            >+ Create your AI</Button>
          </Box>
        )}
      </Drawer>
    )
  }
  
  return (
    <Collapse
      in={modelsMenuOpen}
      timeout="auto"
      unmountOnExit
    >
      {children}
      {widesidebar && (
        <>
          {!marketplaceLoading && !Boolean(sharedCards?.pinnedItems.length) && !Boolean(sharedCards?.allItems.length) && (
            <Typography sx={{ fontSize: '0.875rem', padding: '10px 24px', textAlign: 'center' }}>
              Nothing found, create yours
            </Typography>
          )}
          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: {xs: '5px 0', md: 0} }}> */}
          <Box sx={{ padding: {xs: '5px 15px', md: '0 15px'} }}>
            {/* <Button
              onClick={loadMoreHandler} 
              sx={{
                fontSize: '0.75rem',
                color: '#7e7e7e',
              }}
              disabled={loadMoreDisabled}
            >Load more...</Button> */}
            {createModelEnabled && (
              <Button
                href="/portal/models/create"
                sx={{
                  fontSize: '0.75rem',
                  float: 'right',
                }}
                onClick={() => {sendUIEvent({name: `create_your_ai_nav_click`})}}
              >+ Create your AI</Button>
            )}
          </Box>
        </>
      )}
    </Collapse>
  )
}

export default Wrapper;
