import {useEffect, useState, useRef} from 'react';
import {Grid, Divider, Button, Card, CardContent, Collapse} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import plans from './plansData';
import './priceTable.css'
import {sendUIEvent} from "../../../../../../events/analytics/google";

const ComparePlans = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [comparePlanOpen, setComparePlanOpen] = useState(false);
    const comparePlanRef = useRef(null);

    const toggleComparePlans = () => {
        setComparePlanOpen(!comparePlanOpen);
        const label = comparePlanOpen ? 'close' : 'open'
        sendUIEvent({name: `price_table_compare_plans_${label}`});
    }

    useEffect(() => {
        if (comparePlanOpen && comparePlanRef.current) {
            comparePlanRef.current.scrollIntoView({behavior: "smooth"});
        }
    }, [comparePlanOpen]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                    <Button onClick={toggleComparePlans} ref={comparePlanRef}>
                        Compare Plans {comparePlanOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {comparePlanOpen &&
                        <Collapse in={comparePlanOpen} width='100%'>
                            {isMobile ?
                                <Grid container>
                                    {plans.map((plan, i) =>
                                        <Grid key={i} my={2} sx={{width: '100%'}}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <h3>{plan.name}</h3>
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <h5>Credits</h5></Grid>
                                                        <Grid item xs={6} display='flex' alignItems='center'><p
                                                            className='price-table-credits'>{plan.credits}</p></Grid>
                                                    </Grid>
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <h5>Models</h5></Grid>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <p>{plan.models}</p></Grid>
                                                    </Grid>
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'><h5>Daily
                                                            Use</h5></Grid>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <p>{plan.dailyUse}</p></Grid>
                                                    </Grid>
                                                    <Divider sx={{
                                                        margin: '20px 0 0 10px',
                                                        color: 'black',
                                                        fontWeight: '500',
                                                        fontSize: '16px'
                                                    }}>{plan.training.title}</Divider>
                                                    {/* {
                        plan.training && plan.training.list.map((item,i)=>
                            <Grid key={i} container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                <Grid item xs={6} display='flex' alignItems='center'><h5>{item.name}</h5></Grid>
                                <Grid item xs={6} display='flex' alignItems='center'>{item.value ? <CheckIcon color='success'/>:<CloseIcon color='error'/>} </Grid>
                            </Grid>
                        )
                    } */}
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <h5>{plan.training?.list[0].name}</h5></Grid>
                                                        <Grid item xs={6} display='flex'
                                                              alignItems='center'>{plan.training?.list[0].value ?
                                                            <CheckIcon color='success'/> :
                                                            <CloseIcon color='error'/>} </Grid>
                                                    </Grid>
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <h5>{plan.training?.list[1].name}</h5></Grid>
                                                        <Grid item xs={6} display='flex'
                                                              alignItems='center'>{plan.training?.list[1].value ?
                                                            <CheckIcon color='success'/> :
                                                            <CloseIcon color='error'/>} </Grid>
                                                    </Grid>
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <h5>{plan.training?.list[2].name}</h5></Grid>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <p>{plan.training?.list[2].value}</p></Grid>
                                                    </Grid>
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <h5>{plan.training?.list[3].name}</h5></Grid>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <p>{plan.training?.list[3].value}</p></Grid>
                                                    </Grid>
                                                    <Grid container px={1} pt={1.5} display='flex' flexDirection='row'
                                                          width='100%'>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <h5>{plan.training?.list[4].name}</h5></Grid>
                                                        <Grid item xs={6} display='flex' alignItems='center'>
                                                            <p>{plan.training?.list[4].value}</p></Grid>
                                                    </Grid>
                                                    <Divider sx={{
                                                        margin: '20px 0 0 10px',
                                                        color: 'black',
                                                        fontWeight: '500',
                                                        fontSize: '16px'
                                                    }}>{plan.security.title}</Divider>
                                                    {
                                                        plan.security && plan.security.list.map((item, i) =>
                                                            <Grid key={i} container px={1} pt={1.5} display='flex'
                                                                  flexDirection='row' width='100%'>
                                                                <Grid item xs={6} display='flex' alignItems='center'>
                                                                    <h5>{item.name}</h5></Grid>
                                                                <Grid item xs={6} display='flex'
                                                                      alignItems='center'>{item.value ?
                                                                    <CheckIcon color='success'/> :
                                                                    <CloseIcon color='error'/>} </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                    <Divider sx={{
                                                        margin: '20px 0 0 10px',
                                                        color: 'black',
                                                        fontWeight: '500',
                                                        fontSize: '16px'
                                                    }}>{plan.communication.title}</Divider>
                                                    {
                                                        plan.communication && plan.communication.list.map((item, i) =>
                                                            <Grid key={i} container px={1} pt={1.5} display='flex'
                                                                  flexDirection='row' width='100%'>
                                                                <Grid item xs={6} display='flex' alignItems='center'>
                                                                    <h5>{item.name}</h5></Grid>
                                                                <Grid item xs={6} display='flex'
                                                                      alignItems='center'>{item.value ?
                                                                    <CheckIcon color='success'/> :
                                                                    <CloseIcon color='error'/>} </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                    <Divider sx={{
                                                        margin: '20px 0 0 10px',
                                                        color: 'black',
                                                        fontWeight: '500',
                                                        fontSize: '16px'
                                                    }}>{plan.support.title}</Divider>
                                                    {
                                                        plan.support && plan.support.list.map((item, i) =>
                                                            <Grid key={i} container px={1} pt={1.5} display='flex'
                                                                  flexDirection='row' width='100%'>
                                                                <Grid item xs={6} display='flex' alignItems='center'>
                                                                    <h5>{item.name}</h5></Grid>
                                                                <Grid item xs={6} display='flex'
                                                                      alignItems='center'>{item.value ?
                                                                    <CheckIcon color='success'/> :
                                                                    <CloseIcon color='error'/>} </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Grid>)
                                    }
                                </Grid>
                                :
                                <Grid>
                                    <Grid container py={3}
                                          sx={{borderBottom: '1px solid var(--ynt-mildgrey)', flexWrap: 'nowrap'}}>
                                        <Grid item xs={3} md={3}
                                              className='center-align price-table-header price-table-cell'>
                                            <h4>Plans</h4>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item key={i} xs={1} md={1.5}
                                                      sx={{display: 'flex', flexDirection: 'column'}}
                                                      className='center-align price-table-header price-table-cell'>
                                                    <h4>{plan.name}</h4>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    <Grid container sx={{flexWrap: 'nowrap'}}>
                                        <Grid item py={2} xs={3} md={3}
                                              className='center-align price-table-header price-table-cell'>
                                            <h5>Credits</h5>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item py={2} key={i} xs={1} md={1.5}
                                                      className='center-align price-table-cell'>
                                                    <p className='price-table-credits'>{plan.credits}</p>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    <Grid container sx={{flexWrap: 'nowrap'}}>
                                        <Grid item py={2} xs={3} md={3}
                                              className='center-align price-table-header price-table-cell'>
                                            <h5>Models</h5>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item py={2} key={i} xs={1} md={1.5}
                                                      className='center-align price-table-cell'>
                                                    {plan.models}
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    <Grid container sx={{flexWrap: 'nowrap'}}>
                                        <Grid item py={2} xs={3} md={3}
                                              className='center-align price-table-header price-table-cell'>
                                            <h5>Daily Use</h5>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item py={2} key={i} xs={1} md={1.5}
                                                      className='center-align price-table-cell'>
                                                    {plan.dailyUse}
                                                </Grid>
                                            )
                                        }
                                    </Grid>

                                    <Divider sx={{
                                        margin: '20px 0',
                                        color: 'black',
                                        fontWeight: '500',
                                        fontSize: '18px'
                                    }}>Training</Divider>
                                    <Grid container>
                                        <Grid item py={2} xs={3} md={3}>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Text</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Q&A</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'><h5>Gmail
                                                Conversations</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Outlook Conversations</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Files</h5></Grid>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item py={2} key={i} xs={1} md={1.5}>
                                                    {/* {
                            plan.training.list.map((item,j)=>
                                <Grid className='center-align price-table-cell'>
                                    <Grid className='price-mark' key={j}>
                                    {item.value ? <CheckIcon color='success'/>:<CloseIcon color='error'/>}
                                    </Grid>
                                </Grid>
                            )
                        } */}
                                                    <Grid className='center-align price-table-cell'>
                                                        <Grid className='price-mark'>
                                                            {plan.training?.list[0].value ?
                                                                <CheckIcon color='success'/> :
                                                                <CloseIcon color='error'/>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className='center-align price-table-cell'>
                                                        <Grid className='price-mark'>
                                                            {plan.training?.list[1].value ?
                                                                <CheckIcon color='success'/> :
                                                                <CloseIcon color='error'/>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className='center-align price-table-cell'>
                                                        <Grid item py={4.5} key={i} xs={1} md={1.5}
                                                              className='center-align price-table-cell'>
                                                            <p>{plan.training?.list[2].value}</p>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className='center-align price-table-cell'>
                                                        <Grid item py={4.5} key={i} xs={1} md={1.5}
                                                              className='center-align price-table-cell'>
                                                            <p>{plan.training?.list[3].value}</p>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className='center-align price-table-cell'>
                                                        <Grid item py={4.5} key={i} xs={1} md={1.5}
                                                              className='center-align price-table-cell'>
                                                            <p>{plan.training?.list[4].value}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>

                                    <Divider sx={{
                                        margin: '20px 0',
                                        color: 'black',
                                        fontWeight: '500',
                                        fontSize: '18px'
                                    }}>Security</Divider>
                                    <Grid container>
                                        <Grid item py={2} xs={3} md={3}>
                                            <Grid className='center-align price-table-header price-table-cell'><h5>Data
                                                Privacy</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Security</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Reliability</h5></Grid>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item py={2} key={i} xs={1} md={1.5}>
                                                    {
                                                        plan.security.list.map((item, j) =>
                                                            <Grid className='center-align price-table-cell'>
                                                                <Grid className='price-mark' key={j}>
                                                                    {item.value ? <CheckIcon color='success'/> :
                                                                        <CloseIcon color='error'/>}
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            )
                                        }
                                    </Grid>

                                    <Divider sx={{
                                        margin: '20px 0',
                                        color: 'black',
                                        fontWeight: '500',
                                        fontSize: '18px'
                                    }}>Communication</Divider>
                                    <Grid container>
                                        <Grid item py={2} xs={3} md={3}>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Playground</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Browser Extension</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'><h5>AI
                                                Model Sharing</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'><h5>50+
                                                Languages</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'><h5>Speak
                                                to File</h5></Grid>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item py={2} key={i} xs={1} md={1.5}>
                                                    {
                                                        plan.communication.list.map((item, j) =>
                                                            <Grid className='center-align price-table-cell'>
                                                                <Grid className='price-mark' key={j}>
                                                                    {item.value ? <CheckIcon color='success'/> :
                                                                        <CloseIcon color='error'/>}
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            )
                                        }
                                    </Grid>

                                    <Divider sx={{
                                        margin: '20px 0',
                                        color: 'black',
                                        fontWeight: '500',
                                        fontSize: '18px'
                                    }}>Support</Divider>
                                    <Grid container>
                                        <Grid item py={2} xs={3} md={3}>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Technical Support</h5></Grid>
                                            <Grid className='center-align price-table-header price-table-cell'>
                                                <h5>Dedicated Manager</h5></Grid>
                                        </Grid>
                                        {
                                            plans.map((plan, i) =>
                                                <Grid item py={2} key={i} xs={1} md={1.5}>
                                                    {
                                                        plan.support.list.map((item, j) =>
                                                            <Grid className='center-align price-table-cell'>
                                                                <Grid className='price-mark' key={j}>
                                                                    {item.value ? <CheckIcon color='success'/> :
                                                                        <CloseIcon color='error'/>}
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            )
                                        }
                                    </Grid>

                                </Grid>
                            }
                        </Collapse>
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default ComparePlans;