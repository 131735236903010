import AccountManageBtn from "./AccountManageBtn";
import Grid from "@mui/material/Grid";
import EmailSortSearch from "./EmailSortSearch";

const EmailMenuBar = () => {
    return (
        <Grid container>

            <Grid item xs={12} md={6} p={{xs: 2}} sx={{display: "flex", alignItems: "center"}}>
                <EmailSortSearch/>
            </Grid>
            <Grid item xs={12} md={6} py={{xs: 2}}
                  sx={{display: 'flex', alignItems: 'center', justifyContent: {xs: 'center', md: 'end'}}}>
                <AccountManageBtn/>
            </Grid>
        </Grid>
    )
}
export default EmailMenuBar;

