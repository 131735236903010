import {Box, MenuItem, Select, Typography} from "@mui/material";
import React, {memo, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {setNewModelFlow} from "redux/models";
import {getList} from "../../../../redux/invitations";

const ShareModelSelector = ({selectedModel, setSelectedModel, smallScreen}) => {


    const dispatch = useDispatch();

    const {user, models} = useSelector((state) => ({
        user: state.auth.user,
        models: state.models.list,
    }));

    const ownedModels = useMemo(() => {
        return models.filter((mod) => mod.user_id === user.id);
    }, [models, user.id]);

    const handleModelChange = (event) => {
        const id = event.target.value;
        const targetModel = models.find((model) => model.id === id);

        setSelectedModel(targetModel);
        dispatch(getList(targetModel.id));
    };

    return (
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: {xs: 'center', md: 'flex-start'}}}>
        <Typography px={2} sx={{fontSize: 24, paddingLeft: 0}}>Share Your</Typography>
        {!smallScreen && (
          <Select
            value={selectedModel?.id}
            onChange={handleModelChange}
            sx={{
                padding: {xs: '0', md: '0 5px'},
                boxShadow: "none",
                fontSize: 'inherit',
                background: '#e9f0fe',
                fontWeight: '500',
                // position: { xs: 'absolute', md: 'relative' },
                // left: {xs: '50%', md: 0},
                // bottom: 0,
                mr: 2,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: {xs: '100%', md: '380px'},
                whiteSpace: 'nowrap',
                // transform: { xs: 'translateX(-50%)', md: 'none' },
                ".MuiOutlinedInput-notchedOutline": {border: 0, padding: 0},
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },
                ".MuiSelect-select": {
                    padding: '0 0 0 14px',
                }
              }}
            >
            {ownedModels.map((mod) => (
                <MenuItem key={mod.id} value={mod.id}>
                    {mod.name}
                </MenuItem>
            ))}
          </Select>
        )}
        <Typography px={2} sx={{fontSize: 24, padding: 0}}>Model</Typography>
      </Box>        
    )
}

export default memo(ShareModelSelector);