import {useMemo} from "react";

const htmlContent = `
    <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Styled Webpage</title>
            <!--YOUNET-CHAT-CODE-->
            <style>
                body {
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    margin: 0;
                    padding: 0;
                    background-color: #f4f7f9;
                    color: #333;
                    line-height: 1.6;
                }
                header {
                    background-color: #004e92;
                    color: white;
                    padding: 10px 0;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    position: sticky;
                    top: 0;
                    z-index: 1000;
                }
                header h1 {
                    margin: 0;
                    font-size: 2.5em;
                    text-align: center;
                }
                nav {
                    background-color: #003c7a;
                    color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 20px;
                    position: relative;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                }
                .nav-menu {
                    display: flex;
                    gap: 20px;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
                .nav-menu a {
                    color: white;
                    text-decoration: none;
                    padding: 10px 15px;
                    border-bottom: 3px solid transparent;
                    transition: border-bottom 0.3s ease, color 0.3s ease;
                }
                .nav-menu a:hover {
                    border-bottom: 3px solid #00aaff;
                    color: #d0e6f9;
                }
                .hamburger {
                    display: none;
                    cursor: pointer;
                    flex-direction: column;
                    gap: 4px;
                }
                .hamburger div {
                    width: 25px;
                    height: 2px;
                    background-color: white;
                    transition: all 0.3s ease;
                }
                @media (max-width: 568px) {
                    .nav-menu {
                        display: none;
                        flex-direction: column;
                        width: 100%;
                        background-color: #003c7a;
                        position: absolute;
                        top: 60px;
                        left: 0;
                        padding: 10px 0;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    }
                    .nav-menu.active {
                        display: flex;
                    }
                    .hamburger {
                        display: flex;
                    }
                    .hamburger.active div:nth-child(1) {
                        transform: rotate(45deg) translate(5px, 5px);
                    }
                    .hamburger.active div:nth-child(2) {
                        opacity: 0;
                    }
                    .hamburger.active div:nth-child(3) {
                        transform: rotate(-45deg) translate(7px, -7px);
                    }
                }
                main {
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    gap: 20px;
                    padding: 20px;
                    max-width: 1200px;
                    margin: 20px auto;
                }
                article {
                    background-color: white;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                }
                article h2 {
                    margin-top: 0;
                    font-size: 1.75em;
                    color: #004e92;
                }
                article img {
                    max-width: 100%;
                    height: auto;
                    border-radius: 8px;
                    margin-bottom: 15px;
                }
                article p {
                    margin-bottom: 15px;
                }
                article ul {
                    list-style-type: disc;
                    margin-left: 20px;
                    margin-bottom: 15px;
                }
                article ul li {
                    margin-bottom: 10px;
                }
                .quote {
                    background-color: #e3f1fa;
                    border-left: 4px solid #004e92;
                    padding: 15px;
                    margin: 20px 0;
                    font-style: italic;
                    color: #555;
                }
                aside {
                    background-color: #eaf1f8;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                }
                aside h3 {
                    margin-top: 0;
                    font-size: 1.5em;
                    color: #003c7a;
                }
                aside ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
                aside ul li {
                    margin-bottom: 10px;
                }
                aside ul li a {
                    text-decoration: none;
                    color: #004e92;
                    font-weight: bold;
                    transition: color 0.3s ease;
                }
                aside ul li a:hover {
                    color: #00aaff;
                }
                .gallery {
                    display: flex;
                    flex-flow: column;
                    gap: 25px;
                    margin-top: 20px;
                }
                .gallery img {
                    flex: 1;
                    border-radius: 8px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease;
                }
                .gallery img:hover {
                    transform: scale(1.05);
                }
                footer {
                    background-color: #003c7a;
                    color: white;
                    text-align: center;
                    padding: 20px 0;
                    margin-top: 40px;
                }
                footer p {
                    margin: 0;
                }
                
                @media (max-width: 600px) {
                    main {
                        grid-template-columns: 1fr !important;
                    }
                
                }
            </style>
        </head>
        <body>
            <nav>
                <div class="hamburger" id="hamburger">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <ul class="nav-menu" id="nav-menu">
                    <li><a>Home</a></li>
                    <li><a>About</a></li>
                    <li><a>Services</a></li>
                    <li><a>Contact</a></li>
                </ul>
            </nav>
            <main>
                <article>
                    <h2>Lorem Ipsum Dolor Sit Amet</h2>
                    <img src="https://via.placeholder.com/800x400" alt="Lorem Ipsum Placeholder">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vehicula, purus non commodo fermentum, nisl urna tempus purus, ut ultricies turpis lacus vel orci. Duis venenatis metus vel risus sollicitudin, sed commodo ligula aliquet.</p>
                    <p>Curabitur dignissim, sapien non scelerisque ultrices, eros turpis sodales risus, a tempor massa nisi ac erat. Cras consequat velit vitae velit convallis, at vehicula purus dignissim. Integer vulputate tincidunt orci, vel lacinia lorem.</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Consectetur adipiscing elit</li>
                        <li>Praesent vehicula, purus non</li>
                        <li>Nullam auctor, risus a varius</li>
                    </ul>
                    <div class="quote">
                        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." - Lorem Ipsum
                    </div>
                    <p>Mauris fermentum tortor eu risus auctor, vel convallis dui dignissim. Suspendisse potenti. Nullam auctor, risus a varius suscipit, purus velit cursus justo, vel cursus felis erat a nulla. Suspendisse viverra libero non augue suscipit, a hendrerit justo fermentum.</p>
                </article>
                <aside>
                    <h3>Lorem Ipsum Section</h3>
                    <ul>
                        <li><a>Aliquam erat volutpat</a></li>
                        <li><a>Phasellus imperdiet</a></li>
                        <li><a>Vestibulum non ante</a></li>
                        <li><a>Morbi tristique senectus</a></li>
                        <li><a>Quisque cursus magna</a></li>
                    </ul>
                    <!--YOUNET-CHAT-BUTTON-->
                    <div class="gallery">
                        <img src="https://via.placeholder.com/150x100" alt="Gallery Image 1">
                        <img src="https://via.placeholder.com/150x100" alt="Gallery Image 2">
                        <img src="https://via.placeholder.com/150x100" alt="Gallery Image 3">
                    </div>
                </aside>
            </main>
            <footer>
                <p>&copy; 2024 Lorem Ipsum</p>
            </footer>
            
            <script>
                document.getElementById('hamburger').addEventListener('click', function() {
                    document.getElementById('nav-menu').classList.toggle('active');
                    this.classList.toggle('active');
                });
            </script>
        </body>
        </html> 
  `;

const WebsiteWireframe = ({code = '', previewData = {}, wrapperHeight}) => {
    const replacedContent = useMemo(() => {
        let html = htmlContent;
        const btnText = previewData.label || 'Hello there';
        const btnCode = previewData.type && previewData.type !== 'bubble' ? `<younet-chat-button>${btnText}</younet-chat-button>` : '';

        html = html.replace('<!--YOUNET-CHAT-BUTTON-->', btnCode);
        html = html.replace('<!--YOUNET-CHAT-CODE-->', code);

        return html;

    }, [code, previewData.label, previewData.type]);

    return (
        <iframe
            srcDoc={replacedContent}
            title="Example HTML"
            // style={{width: '100%', height: '100%', border: 'none', minHeight: 'calc(100vh - 347px)', overflow: 'hidden', maxHeight: 'calc(100vh - 347px)'}}
            style={{width: '100%', height: '100%', border: 'none', minHeight: wrapperHeight ? `${wrapperHeight}px` : '700px', maxHeight: wrapperHeight ? `${wrapperHeight}px` : '700px', overflow: 'hidden'}}
        />
    );
};

export default WebsiteWireframe;