import React, { useState } from 'react';
import MDEditor, { ICommand, commands } from "@uiw/react-md-editor";
import MarkdownComponent from "components/MarkdownComponent";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./custom-md-editor.css";
import { useEffect } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import api from "_services/api";


// TODO: add on error callback here 
const CustomMdEditor = ({value, onChange, placeholder, customHeight = null, onChangeRequest = null, resetSignal, setResetSignal, isToolbarVisible = false}) => {
    const [toolbarVisible, setToolbarVisible] = useState(isToolbarVisible);

    // Value state
    const [valueState, setValueState] = useState(value && typeof value == 'string'  ? value : '');

    const internalOnChange = (value) => {
      if (onChangeRequest === null) {
        setValueState(value);
        onChange(value);
      }else{
        setValueState(value);
      }
    }
    
    useEffect(()=> {
      if (onChangeRequest == 1) return;
      onChange(valueState);
    },[onChangeRequest]);

    useEffect(() => {
      if (resetSignal === true) {
          setValueState('');
          setResetSignal(false);
      }
  }, [resetSignal]);
  
    const closeToolbar: ICommand = {
      name: "closeToolbar",
      keyCommand: "closeToolbar",
      buttonProps: { 'aria-label': "Close Toolbar", className: "custom-md-editor-button-up" },
      icon: <KeyboardArrowUpIcon fontSize='inherit' />,
      execute: () => {
        setToolbarVisible(false);
      }
    };

    const customImageUploadCommand: ICommand = {
      name: "uploadImage",
      keyCommand: "uploadImage",
      buttonProps: { 'aria-label': "Upload Image" },
      icon: <ImageIcon fontSize='inherit' />,
      execute: (state, apiEditor, dispatch) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async function() {
          const file = input.files[0];
          if (!file) return;
          const formData = new FormData();
          formData.append('upload', file);
          formData.append('dataType', file.type);

          await api
            .post('/file/upload', formData, { 
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              // Get the file id
              const fileId = response.file.id;
              // Prepare the image url
              const imageUrl = `${fileId}`;

              // const blobUrl = URL.createObjectURL(file);

              
              // Insert markdown for image
              apiEditor.replaceSelection(`![image](${imageUrl})`);
              // apiEditor.replaceSelection(`<img src="${blobUrl}" alt="Image" class="preview-image" />`);
              // setValueState(prev=> prev + `<img src="${blobUrl}" alt="Image" class="preview-image" />`)
              // apiEditor.replaceSelection(`![Image desciption](${blobUrl})`)
              

            })
            .catch((error) => {
              console.log(error);
            });
        }
        input.click();
      },
    };
    
  
  const otherCommands = [
        commands.title,
        commands.bold,
        commands.italic,
        commands.strikethrough,
        commands.hr,
        commands.link,
        commands.quote,
        // customImageUploadCommand,
        commands.code,
        commands.divider,
        commands.unorderedListCommand,
        commands.orderedListCommand,
        commands.checkedListCommand,
        commands.divider,
        commands.codeEdit,
        commands.codeLive,
        commands.codePreview,
        commands.fullscreen
    ];
    const extraCommands = [
      closeToolbar
    ];

    const defaultHeight = customHeight !== null ? customHeight : '150px';    
    // If value is empty, set height to 150px otherwise auto
    const height = value && customHeight == null ? 'auto' : defaultHeight;

    // Assign to the editor css variable --md-editor-custom-height
    document.documentElement.style.setProperty('--md-editor-custom-height', height);

    return (
      <div data-color-mode="light" className="md-container">
        {!toolbarVisible && (
          <button 
            className='custom-md-editor-button'
            onClick={() => setToolbarVisible(true)}>
            <KeyboardArrowDownIcon fontSize='3px' />
          </button>
        )}
        <MDEditor
          value={valueState}
          onChange={internalOnChange}
          textareaProps={{
            placeholder: placeholder,
          }}
          preview="edit"
          className='auto-size'
          onHeightChange={(height, preview) => {
            // Remove .auto-size class from all found editors
            const editors = document.querySelectorAll('.auto-size');
            editors.forEach(editor => {
              editor.classList.remove('auto-size');
            }
            );
          }}
          width="100%"
          sx={{
            "min-height": defaultHeight,
          }}
          visibleDragbar={true}
          commands={toolbarVisible ? otherCommands : []}
          extraCommands={extraCommands}
          hideToolbar={!toolbarVisible}
          components={{
            preview: (source, state, dispatch) => {

              return <MarkdownComponent source={source} />
            }
          }}
          enableScroll={false}
        />
      </div>
    );
  };

export default CustomMdEditor;
