import { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from '@mui/material';
import { getList } from "redux/invitations";
import { useDispatch, useSelector } from "react-redux";
import ShareModelConfig from 'pages/protected/ShareModelConfig';
// import { setNewModelFlow } from 'redux/models';

const ShareModelPopup = ({ data, onClose, preselectedFlow = 'anyone' }) => {
  const { model } = data;
  const [selectedModel, setSelectedModel] = useState(model);
  const dispatch = useDispatch();
  const handleClose = () => {
    // dispatch(setNewModelFlow({flow: null}));
    // localStorage.removeItem('newModelFlow');
    onClose();
  };
  const { user, models } = useSelector((state) => ({
    user: state.auth.user,
    models: state.models.list,
  }));
  const ownedModels = useMemo(() => {
    return models.filter((mod) => mod.user_id === user.id);
  }, [models]);

  const handleModelChange = (event) => {
    const id = event.target.value;
    const targetModel = models.find((model) => model.id === id);

    setSelectedModel(targetModel);
    dispatch(getList(targetModel.id));
  };

  return (
    <Dialog
      open={data.isExpanded}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle
        sx={{ 
          position: 'relative', 
          m: 0, 
          p: 4, 
          pb: { xs: 8, md: 4 }, 
          width: '100%', 
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'flex-start', 
          alignItems: 'center',
          textAlign: { xs: 'center', md: 'left' }
        }}
      >
        Share Your&nbsp;
        <Select
          value={selectedModel?.id}
          onChange={handleModelChange}
          sx={{ 
            boxShadow: "none",
            fontSize: 'inherit',
            background: '#e9f0fe',
            fontWeight: '500',
            // position: { xs: 'absolute', md: 'relative' },
            // left: {xs: '50%', md: 0},
            // bottom: 0,
            mr: 2,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: { xs: '100%', md: '380px' },
            whiteSpace: 'nowrap',
            // transform: { xs: 'translateX(-50%)', md: 'none' },
            ".MuiOutlinedInput-notchedOutline": { border: 0, padding: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            ".MuiSelect-select" : {
              padding: '0 0 0 14px',
            }
          }}
        >
          {ownedModels.map((mod) => (
            <MenuItem key={mod.id} value={mod.id}>
              {mod.name}
            </MenuItem>
          ))}
        </Select>
        Model
      </DialogTitle>
      <ShareModelConfig
        handleClose={handleClose}
        selectedModel={selectedModel}
        setSelectedModel={updatedModel => {
          setSelectedModel(updatedModel);
          dispatch(getList(updatedModel.id));
        }}
        preselectedFlow={preselectedFlow}
      />
    </Dialog>
  );
};


export default ShareModelPopup;