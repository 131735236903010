import {Typography, Box, Grid, Button} from "@mui/material";
import {Link} from "react-router-dom";
import styles from "../playground.module.css";
import {PlanChecker} from "components/security/AccessChecker";
import {AutoAwesome} from "@mui/icons-material";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {sendUIEvent} from "../../../../../events/analytics/google";

const PersonalizationHints = ({modelName = ''}) => {

    const sendEvent = () => {
        sendUIEvent({name: `use_personalization_tools_from_chat_redirect`})
    }

    return (
        <Grid container className={styles["personalized-hints-container"]}>
            {/*<Grid item md={4}>*/}
            {/*    <WarningRoundedIcon sx={{color: '#ffca00', fontSize: 35 }}/>*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={12} md={12} sx={{display: 'flex',flexFlow: 'row', alignItems: 'center', justifyContent: {sm: 'space-between', xs: 'center',md:'space-between'}}} className={styles["personalized-hints-tools"]}>
                <WarningRoundedIcon sx={{color: '#ffca00', fontSize: 35 }}/>
                <Typography sx={{fontSize: '0.813rem'}}
                            color="text.secondary"
                            gutterBottom>
                    Personalize your {modelName} AI model more with:
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className={styles["personalized-hints-tools"]} onClick={sendEvent}>
                <Link to="/training/text"><Typography variant="plain_links">Text</Typography></Link>
                <Link to="/training/q&a"><Typography variant="plain_links">Q&A</Typography></Link>
                <Link to="/training/email"><Typography variant="plain_links">Email</Typography></Link>
                <Link to="/training/files"><Typography variant="plain_links">Files</Typography></Link>
                <Link to="/training/website"><Typography variant="plain_links">Website</Typography></Link>
            </Grid>
        </Grid>
    );
};

export default PersonalizationHints;
