import {Grid, Typography} from "@mui/material";
import WebsiteDomain from "../WebsiteDomain";
import StyleCustomizations from "../StyleCustomizations";
import EmbededCode from "../EmbeddedCode";
import {memo} from "react";
import ActionBtns from "pages/protected/ShareModel/components/ActionBtns";

const Settings = ({isPublic = false, handleChanges, previewData = {}, code = '', smallScreen, isInView}) => {
    return (
      <>
        <Grid item container py={4} spacing={4}
              sx={{flexFlow: 'column nowrap !important'}}>
            {!smallScreen && (
              <Grid item xs={12} flex="0 !important" sx={{paddingTop: '0 !important'}}>
                  <Typography sx={{fontSize: '24px'}}>
                      {isPublic ? 'Edit Website Chatbot' : 'Create Website Chatbot'}
                  </Typography>
              </Grid>
            )}
            <Grid item xs={12} flex="0 !important" sx={{paddingTop: '8px !important'}}>
                <WebsiteDomain/>
            </Grid>
            <Grid item xs={12} flex="0 !important">
                <StyleCustomizations handleChanges={handleChanges}/>
            </Grid>
            {
                isPublic &&
                <Grid item xs={12} flex="0 !important">
                    <EmbededCode code={code} previewData={previewData}/>
                </Grid>
            }
        </Grid>
      </>
    )
}

export default memo(Settings);
