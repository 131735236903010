import React from 'react';
import ReactMarkdown from 'react-markdown';
import {Link} from "@mui/material";
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkSlug from 'remark-slug';
import remarkAutolinkHeadings from 'remark-autolink-headings';
import remarkEmoji from 'remark-emoji';
import './index.css';
import remarkMath from 'remark-math'
// import 'katex/dist/katex.min.css'
import {AdaptiveLanguageMarkdown} from "./components/AdaptiveLanguageMarkdown";
import directive from "remark-directive";
import {visit} from "unist-util-visit"
import {h} from "hastscript"
import InlineLoad from "./components/InlineLoad";
import ImageComponent from "./components/ImageComponent";
import AuthRedirect from "./components/AuthRedirect";

function htmlDirectives() {
    return transform

    function transform(tree) {
        visit(tree, ['textDirective', 'leafDirective', 'containerDirective'],
            ondirective
        )
    }

    function ondirective(node) {
        if (
            node.type === 'containerDirective' ||
            node.type === 'leafDirective' ||
            node.type === 'textDirective'
        ) {
            const data = node.data || (node.data = {})
            const hast = h(node.name, node.attributes || {})

            data.hName = node.name || hast.tagName
            data.hProperties = hast.properties
        }

    }
}

const processImages = (content) => {
    return content.replace(/(\!\[.*\]\([^)]*$)/g, ':InlineLoad[image]');
}

const processAuthLinks = (content) => {
    return content.replace(/(\!\[.*\]\([^)]*$)/g, ':InlineLoad[image]');
}

const preprocessLaTeX = (content) => {

    const blockProcessedContent = content.replace(
        /\\\[(.*?)\\\]/gs,
        (_, equation) => `$$\n${equation}\n$$`,
    );

    const inlineProcessedContent = blockProcessedContent.replace(
        /\\\((.*?)\\\)/gs,
        (_, equation) => `$${equation}$`,
    );
    return inlineProcessedContent;
};


const MarkdownComponent = ({source, stream = false, msgId = undefined, chatId = undefined}) => {
    let formattedSource = source.replace(/ +/g, ' ');


    // const

    // formattedSource = preprocessLaTeX(formattedSource);
    if (stream) {
        formattedSource = processImages(formattedSource)
    }

    return (
        <ReactMarkdown
            // rehypePlugins={[rehypeKatex]}
            // katexOptions={{ throwOnError: false }}
            components={{
                InlineLoad: (value) => <InlineLoad/>,

                // math: (value) => <BlockMath renderError={(e)=> {console.log(e)}}>{value}</BlockMath>,
                // inlineMath: (value) => <InlineMath renderError={(e)=> {console.log(e)}}>{value}</InlineMath>,

                code({node, inline, className, children, ...props}) {

                    if (typeof children[0] !== 'undefined' && children[0].type === 'text' && children[0].value === '') {
                        return ''
                    }

                    const match = /language-(\w+)/.exec(className || '') || [];
                    return <AdaptiveLanguageMarkdown className={className} {...props} inline={inline}
                                                     language={match[1]}>{children}</AdaptiveLanguageMarkdown>

                },
                a({node, children, href, ...props}) {

                    if (href === '') {
                        return null;
                    }


                    const defaultRegex = /[&?]action_required_oauth_younet=true/;
                    const googleRegex = /^https:\/\/accounts\.google\.com\/o\/oauth2\/v2\/auth(\?.*)?$/;
                    const facebookRegex = /^https:\/\/www\.facebook\.com\/[^\/]+\/dialog\/oauth(\?.*)?$/;

                    let provider = '';
                    if (googleRegex.test(href)) {
                        provider = 'google';
                    } else if (facebookRegex.test(href)) {
                        provider = 'facebook';
                    } else if (defaultRegex.test(href)) {
                        provider = 'default';
                    }

                    if (provider) {
                        const urlObj = new URL(href);
                        const params = new URLSearchParams(urlObj.search);
                        const state = params.get('state');

                        return <AuthRedirect provider={provider} msgId={msgId} chatId={chatId} href={href}
                                             state={state} isStream={stream}/>;
                    }

                    return (
                        <Link href={href} target="_blank" rel="noopener noreferrer" {...props}>{children}</Link>
                    );
                }
                ,
                li({node, children, ordered, ...props}) {
                    return (
                        <li {...props}>{children}</li>
                    );
                }
                ,
                img: ImageComponent,

            }
            }

            remarkPlugins={[
                directive,
                htmlDirectives,
                remarkMath, {inlineMathDouble: true, displayMathDouble: true},
                remarkGfm,
                remarkBreaks,
                remarkSlug,
                remarkAutolinkHeadings,
                remarkEmoji,
            ]}
        >

            {formattedSource}
        </ReactMarkdown>
    );
};

export default MarkdownComponent;
