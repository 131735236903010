import React, {useRef, useEffect, useState, memo, useMemo, useCallback} from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "../share-model.module.css";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import {useSelector, shallowEqual} from "react-redux";
import Message from "./Message";
import MarkdownComponent from "components/MarkdownComponent";
import MessageSender from "./MessageSender";
import PersonIcon from "@mui/icons-material/Person";
import NewChatHints from "pages/protected/tools/Playground/components/NewChatHints";
import MiddleBrain from "./MiddleBrain";
import ToolCalls from "../../../components/ChatConversation/components/ToolCalls";
import useIsSmallScreen from "hooks/useIsSmallScreen";

function Chat({
                  userInfo = {},
                  modelData,
                  modelName = "",
                  chatLoading = false,
                  info = {},
                  type = 'rabbit', // 'rabbit' or 'shared'
                  viewportHeight = 0,
                  topContainerHeight = 0,
                  inputSizes = {lg: 8, md: 8, sm: 12, xs: 12},
                  topOffset = {md: 177, xs: 200},
                  hideSideBar,
                  preview,
              }) {
    const lastElement = useRef(null);
    const containerRef = useRef(null);
    const [sendSignal, setSendSignal] = useState({count: 0, msg: ""});
    const { smallScreen } = useIsSmallScreen();

    const {messagesState} = useSelector(
        (state) => ({
            messagesState: state.sharedMessages,
        }),
        shallowEqual
    );
    const responseStream = messagesState.session[type];
    const messages = messagesState[`${type}Messages`];

    const setSendSignalCallback = useCallback((e, msg) => {
            setSendSignal((prev) => ({
                count: prev.count + 1,
                msg: msg,
            }));
        },
        [setSendSignal]
    );

    useEffect(() => {
        if (lastElement.current) {
            lastElement.current.scrollIntoView({behavior: "smooth", block: "end"});
        }
    }, [responseStream, messages]);

    if (chatLoading) {
        return (
            <Grid container className={styles["container"]}>
                <Grid
                    container
                    sx={{
                        padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                    }}
                    msg_id={0}
                    className={styles["msg-box"] + " " + styles["user"]}
                >
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                        <Avatar className={styles["avatar"]} src={userInfo.avatar ?? ""}>
                            {userInfo.initial === "" ? <PersonIcon/> : userInfo.initial}
                        </Avatar>
                    </Grid>
                    <Grid item xs={10} md={11}>
                        <Typography
                            px={{xs: 3, md: 5}}
                            className={styles["inner-msg"]}
                            variant="messages"
                        >
                            <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{width: "100%"}}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                    }}
                    msg_id={1}
                    className={styles["msg-box"] + " " + styles["ai"]}
                >
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                        <Avatar className={styles["avatar"]}>{modelName}</Avatar>
                    </Grid>
                    <Grid item xs={10} md={11}>
                        <Typography
                            px={{xs: 3, md: 5}}
                            className={styles["inner-msg"]}
                            variant="messages"
                        >
                            <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{width: "100%"}}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                    }}
                    msg_id={2}
                    className={styles["msg-box"] + " " + styles["user"]}
                >
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                        <Avatar className={styles["avatar"]} src={userInfo.avatar ?? ""}>
                            {userInfo.initial === "" ? <PersonIcon/> : userInfo.initial}
                        </Avatar>
                    </Grid>
                    <Grid item xs={10} md={11}>
                        <Typography
                            px={{xs: 3, md: 5}}
                            className={styles["inner-msg"]}
                            variant="messages"
                        >
                            <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{width: "100%"}}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                    }}
                    msg_id={3}
                    className={styles["msg-box"] + " " + styles["ai"]}
                >
                    <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                        <Avatar className={styles["avatar"]}>{modelName}</Avatar>
                    </Grid>
                    <Grid item xs={10} md={11}>
                        <Typography
                            px={{xs: 3, md: 5}}
                            className={styles["inner-msg"]}
                            variant="messages"
                        >
                            <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{width: "100%"}}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexFlow: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Grid
                ref={containerRef}
                container
                className={styles["container"]}
                // minHeight={{
                //     xs: `calc(${viewportHeight}px - ${topOffset.xs}px - ${topContainerHeight}px)`,
                //     md: `calc(${viewportHeight}px - ${topOffset.md}px - ${topContainerHeight}px)`,
                // }}
                // maxHeight={`calc(${viewportHeight}px - ${topOffset.xs}px)`}
                maxHeight={smallScreen && preview ? `calc(${viewportHeight}px - 46px)` : `calc(${viewportHeight}px - ${topOffset.xs}px)`}
            >
                {messages.length === 0 && (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            height: '100%',
                            padding: "0 10px",
                            position: 'relative',
                        }}
                    >
                        <Grid
                            item
                            sx={{height: preview && smallScreen ?  'calc(100vh - 246px)' : '100%'}}
                            lg={inputSizes.lg}
                            md={inputSizes.md}
                            sm={inputSizes.sm}
                            xs={inputSizes.xs}
                        >


                            <NewChatHints
                                userName={userInfo.fullname}
                                onTry={setSendSignalCallback}
                                envState={'sharedMessages'}
                                sharedModelId={info.modelId}
                                modelData={modelData}
                                preview={preview}
                            />


                        </Grid>
                    </Box>
                )}

                {messages.map((msg, index) => (
                    <Message
                        key={index}
                        msg={msg}
                        userInfo={userInfo}
                        modelName={modelName}
                    />
                ))}
                {responseStream !== false &&
                    (responseStream === "" ? (
                        <Grid
                            container
                            sx={{
                                padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                                justifyContent: "center",
                            }}
                            msg_id={0}
                            className={styles["msg-box"] + " " + styles["ai"]}
                        >
                            <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                                <Avatar
                                    className={styles["avatar"]}
                                    sx={{backgroundColor: "rebeccapurple"}}
                                >
                                    {modelName}
                                </Avatar>
                            </Grid>
                            <Grid item xs={10} md={11} sx={{display: "flex"}}>
                                <Typography
                                    px={{xs: 3, md: 5}}
                                    className={styles["inner-msg"]}
                                    variant="messages"
                                >
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        sx={{width: "100%"}}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            sx={{
                                padding: {md: "20px 50px 20px 60px", xs: "20px 0"},
                                justifyContent: "center",
                            }}
                            msg_id={0}
                            className={styles["ai"]}
                        >
                            <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>
                                <Avatar
                                    className={styles["avatar"]}
                                    sx={{backgroundColor: "rebeccapurple"}}
                                >
                                    {modelName}
                                </Avatar>
                            </Grid>
                            <Grid
                                item
                                xs={10}
                                md={11}
                                px={{xs: 2, md: 6}}
                                sx={{display: "flex"}}
                            >
                                <Typography className={styles["inner-msg"]} variant="messages">
                                    <MarkdownComponent stream={true} source={responseStream}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}

                <Grid item xs={11} sm={11} md={12} width="100%" sx={{margin: 'auto', flex: '1 !important'}}>
                    <ToolCalls type="shared"/>
                </Grid>

                {!responseStream !== false && responseStream !== "" && messages.length > 0 && type === 'rabbit' && (
                    <Box px={8} my={4} fontSize={12} textAlign="center" width="100%">
                        <Box pt={6} borderTop="1px solid rgba(0,0,0, .2)" color="rgba(0, 0, 0, 0.5)">
                            Exiting AI Copilot Rabbit chat will result in the loss of your ongoing conversation.
                        </Box>
                    </Box>
                )}
                <div ref={lastElement} style={{height: '1px', width: '100%'}}/>
            </Grid>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px 10px",
                    position: preview ? 'sticky' : 'relative',
                }}
            >
                <Grid
                    item
                    lg={inputSizes.lg}
                    md={inputSizes.md}
                    sm={inputSizes.sm}
                    xs={inputSizes.xs}
                >
                    <MiddleBrain type={type} shouldAppear={Boolean(messages.length)}/>
                    <MessageSender
                        hideSideBar={hideSideBar}
                        info={info}
                        messages={messagesState.session[type]}
                        type={type}
                        sendSignal={sendSignal}
                    />
                </Grid>
            </Box>
        </Box>
    );
}

export default memo(Chat);
