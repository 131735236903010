import {memo, useMemo, useState} from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {BtnSettings} from "./BtnSettings";
import {LanguageSettings} from "./LanguageSettings";
import {PositionSettings} from "./PositionSettings";
import {SizeSettings} from "./SizeSettings";
import {Tooltip} from "@mui/material";


const Index = ({handleChanges}) => {

    const [alignment, setAlignment] = useState('bubble');

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        handleChanges({type: newAlignment});
    };

    const isBtnNeeded = alignment !== 'bubble';
    const isBubbleNeeded = alignment !== 'button';

    return (
        <Grid container py={0} spacing={4}>
            <Grid item xs={12}>
                <Typography variant="describe_text" sx={{fontWeight: 500}}>Styles:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <ToggleButtonGroup
                    fullWidth
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <Tooltip value="bubble"
                             title="The bubble icon is the default option, positioned in the lower-right corner of your website."><ToggleButton
                        value="bubble">Bubble</ToggleButton></Tooltip>
                    <Tooltip value="button"
                             title="The additional button offers another call-to-action option for users."><ToggleButton
                        value="button">Button</ToggleButton></Tooltip>
                    <Tooltip value="both"
                             title="This setting combines the features of both the bubble icon and the additional button."><ToggleButton
                        value="both">Both</ToggleButton></Tooltip>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <LanguageSettings handleChanges={handleChanges}/>
            </Grid>
            {isBtnNeeded &&
                <Grid item xs={12} md={12}>
                    <BtnSettings handleChanges={handleChanges}/>
                </Grid>
            }
            {isBubbleNeeded &&
                <>
                    <Grid item xs={12}>
                        <SizeSettings handleChanges={handleChanges}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PositionSettings handleChanges={handleChanges}/>
                    </Grid>
                </>
            }

        </Grid>
    )
}


export default memo(Index);