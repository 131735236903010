import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ShadowSpinner from "components/loadings/ShadowSpinner";
import { useSelector } from "react-redux";
import Copyright from "components/Copyrights";
import LoginForm from './components/LoginForm';

const theme = createTheme();

const Login = ({ authType = "default" }) => {
  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <LoginForm authType={authType} />
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <ShadowSpinner open={loading}/>
    </ThemeProvider>
  );
};

export default Login;
