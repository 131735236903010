import moment from "moment";
import formatFileSizes from "./formatFileSizes";

const emailValidator = (email, options = {}) => {
    if (!email) {
        return "Email is required";
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
        return "Incorrect email format";
    }
    return false;
};

// export const passwordValidator = (password) => {
//   if (!password) {
//     return "Password is required";
//   } else if (password.length < 8) {
//     return "Password must have a minimum 8 characters";
//   }
//   return "";
// };

// export const confirmPasswordValidator = (confirmPassword, form) => {
//   if (!confirmPassword) {
//     return "Confirm password is required";
//   } else if (confirmPassword.length < 8) {
//     return "Confirm password must have a minimum 8 characters";
//   } else if (confirmPassword !== form.password) {
//     return "Passwords do not match";
//   }
//   return "";
// };

const isEmpty = (data) => {
    let validateMsg = "The field is empty";

    if (typeof data == "string") {
        return data.trim() == "" ? validateMsg : false;
    }

    if (typeof data == "object") {
        return Object.keys(data).length == 0 ? validateMsg : false;
    }
};

const phoneValidator = (phone, options = {}) => {
    let validateMsg = "Phone number format is not correct..";

    let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return phoneReg.test(phone) ? false : validateMsg;
};

const websiteValidator = (website, options = {}) => {
    // const domainPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/;
    const domainPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/)?$/;


    let validateMsg = "Please ensure the domain is valid and correctly formatted";

    return domainPattern.test(website) ? false : validateMsg;
}

const urlValidator = (url, options = {}) => {
    const httpPattern = /^(https?:\/\/)/;
    const fullUrlPatter = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(\/[^\s]*)?$/;

    if (!httpPattern.test(url)) {
        return 'Url must contain http:// or https:// in the link';
    }

    if (!fullUrlPatter.test(url)) {
        return 'Please, make sure to finish domain link (Ex: https://younet.ai)';
    }

    return false;
}

// default 1mb max
const fileValidator = (
    file,
    options = {allowedTypes: [], maxSizeInBytes: 1048576}
) => {
    let validateMsg = "File is corrupted.";


    // smth wrong with it TODO:
    // if (!options.allowedTypes.includes(file.type)) {
    //   return "Invalid file type. Please choose a different file.";
    // }

    if (file.size > options.maxSizeInBytes) {
        return `File size exceeds the maximum allowed limit of ${formatFileSizes(options.maxSizeInBytes)}`;
    }

    if (options.maxAllowedFiles <= options.totalFiles) {
        return `You have reached the maximum of 1 file upload for the trial subscription.`;
    }


    return false;
};

// const ageValidator = (date,options = {}) => {
//   if(options.minAllowedAge) {
//     const today = moment();
//     const birthDate = moment(date);
//     const age = today.diff(birthDate, 'years');

//     if(age < options.minAllowedAge) {
//       return "The user must be over 18 years old.";
//     }

//   }


//   return false;
// };

const textValidator = (text, options = {}) => {

    let words = text.split(' ');
    if (options.maxWordsAllowed < words.length) {
        return `The input must have less than ${options.maxWordsAllowed} characters (${words.length}/${options.maxWordsAllowed})`;
    }

    return false;
};

const typeSelector = (type) => {

    switch (type) {
        case "text":
            return textValidator;
        case "email":
            return emailValidator;
        case "phone":
            return phoneValidator;
        case "website":
            return websiteValidator;
        case "file":
            return fileValidator;
        case "url":
            return urlValidator;
        // case "birthday":
        //   return ageValidator;

        default:
            return textValidator;
    }
};

export const isInputValid = (
    data,
    options = {type: "text", isRequired: false, additionals: false}
) => {

    let isNotFile = options.type != 'file';

    let emptyError = isNotFile ? isEmpty(data) : data.size === 0 ? 'Upload the file.' : '';

    if (emptyError) {
        return options.isRequired ? {error: emptyError} : {success: true};
    }

    let action = typeSelector(options.type);

    let validatorState = options.additionals ? action(data, options.additionals) : action(data);

    return validatorState === false
        ? {success: true}
        : {error: validatorState};
};

export const checkFormForErrors = (form, dataState) => {

    let updates = {};
    let hasErrors = false;

    // for anything inside cache should be combined and validated with the same functionality


    for (const field of form.entries()) {

        let keyData = dataState[field[0]];
        let params = {
            type: field[0],
            isRequired: keyData.isRequired,
        }

        if (keyData.additionals) {
            params['additionals'] = keyData.additionals;
        }


        let state = isInputValid(field[1], params);

        updates[field[0]] = {
            isRequired: keyData.isRequired,
            errMsg: state.success ? "" : state.error || "Something wrong..",
        };

        if (keyData.additionals) {
            updates[field[0]]['additionals'] = keyData.additionals;
        }

        if (state.error && !hasErrors) {
            hasErrors = true;
        }
    }

    return {hasErrors: hasErrors, updates: updates};
};
