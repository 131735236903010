import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api/index.js';
import { produce } from 'immer';
import controlledAPI from "_services/controlledApi";

export const getAllCardsThunk = createAsyncThunk('marketplace/info', async (payload, thunkAPI) => {
  try {
    const cardsResponse = await api.get('/marketplace/card/my');

    if (cardsResponse.code >= 400) {
      throw new Error("Could not get server info");
    }
    const cardsList = cardsResponse.items;

    return { cardsList };
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const getSharedCardsThunk = createAsyncThunk('marketplace/sharedCards', async (payload, thunkAPI) => {
  try {
    const { query, loadMore, pinnedPages, pinnedPage, pinnedCount, pinnedLimit, allPages, allPage, allCount, allLimit, pinnedItems, allItems, isPublic = false, isOnboarding = false, initialSearch = false } = payload;
    const isPinned = !Boolean(query?.length);

    let data = {
      pinnedPages,
      pinnedPage,
      pinnedCount,
      pinnedLimit,
      allPages,
      allPage,
      allCount,
      allLimit,
      pinnedItems,
      allItems,
    }

    const params = { page: 1, limit: 10 };

    if (isPublic) {
      params.isPublic = 1;
    }

    if (loadMore) {
      if (isPinned) {
        if (pinnedLimit < pinnedCount) {
          const response = await api.get('/marketplace/card/list', { params: { ...params, isPinned: 1, limit: pinnedLimit + 10 } });
  
          if (response.code >= 400) {
            throw new Error("Could not get server info");
          }
          
          data = { ...data, pinnedPages: response.pages, pinnedPage: response.page, pinnedCount: response.count, pinnedItems: response.items, pinnedLimit: response.limit }
        }
        if (pinnedLimit >= pinnedCount || 0 < pinnedCount - pinnedLimit < 10) {
          const response = await api.get('/marketplace/card/list', { params: { ...params, isPinned: 0, limit: Boolean(allLimit) ? allLimit + 10 : 10 } });
  
          if (response.code >= 400) {
            throw new Error("Could not get server info");
          }
          
          data = { ...data, allPages: response.pages, allPage: response.page, allCount: response.count, allItems: response.items, allLimit: response.limit }
        }
      } else {
        const updatedLimit = allLimit + 10;
        const response = await api.get('/marketplace/card/list', { params: { ...params, isPinned: 0, query, limit: updatedLimit } });
  
        if (response.code >= 400) {
          throw new Error("Could not get server info");
        }
        data.pinnedPages = null;
        data.pinnedPage = null;
        data.pinnedCount = null;
        data.pinnedItems = [];
        data.pinnedLimit = null;
        data.allPages = response.pages;
        data.allPage = response.page;
        data.allCount = response.count;
        data.allItems = response.items;
        data.allLimit = response.limit;
      }
    } else {
      if (isPinned) {
        const response = await api.get('/marketplace/card/list', { params: { ...params, isPinned: 1 } });
        
        if (response.code >= 400) {
          throw new Error("Could not get server info");
        }
  
        data.pinnedPages = response.pages;
        data.pinnedPage = response.page;
        data.pinnedCount = response.count;
        data.pinnedItems = response.items;
        data.pinnedLimit = response.limit;
        data.allPages = null
        data.allPage = null;
        data.allCount = null;
        data.allItems = [];
        data.allLimit = null;
      }
      if (!isPinned) {
        const response = await api.get('/marketplace/card/list', { params: { ...params, isPinned: 0, query } });
  
        if (response.code >= 400) {
          throw new Error("Could not get server info");
        }
        data.pinnedPages = null;
        data.pinnedPage = null;
        data.pinnedCount = null;
        data.pinnedItems = [];
        data.pinnedLimit = null;
        data.allPages = response.pages;
        data.allPage = response.page;
        data.allCount = response.count;
        data.allItems = response.items;
        data.allLimit = response.limit;
      }
    }
    
    return { data, isOnboarding, initialSearch };
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const addNewCardThunk = createAsyncThunk('marketplace/addNewCard', async (payload, thunkAPI) => {
  try {
    const payloadData = {
      modelId: payload.modelId,
      title: payload.title,
      description: payload.description,
      miniDescription: payload.miniDescription,
      urlHash: payload.urlHash,
    };
    let resData = null;
    const res = await controlledAPI({ ignoreUser: true }).get(`/marketplace/card/${payload.urlHash}`);
     // Hash can be used
     if ((res?.code >= 400 || res?.status >= 400) && res.message[0] === 'marketplace_cards.not_exist') {
      const cardRes = await api.post('/marketplace/card', payloadData);
      resData = cardRes?.data;

      if (cardRes?.code >= 400 || cardRes?.status >= 400) {
        throw new Error("Could not get server info");
      }
      resData.card = cardRes.data;
    } else {
      const cardRes = await api.post('/marketplace/card', { ...payloadData, urlHash: `${payload.urlHash}-${payload.modelId}` });
      const resData = cardRes?.data;

      if (cardRes.code >= 400) {
        throw new Error("Could not get server info");
      }
      resData.card = cardRes.data;
    }

      return { card: resData };
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const addFilesThunk = createAsyncThunk('marketplace/addFiles', async (payload, thunkAPI) => {
  try {
    const { upload, cardId } = payload;
    let files = [];
    
    if (Array.isArray(upload) && upload.length > 0) {
      for (const file of upload) {
        const uploadParams = new FormData();
        uploadParams.append("marketplaceCardId", cardId);
        uploadParams.append("isMain", 1);
        uploadParams.append("upload", file);
  
        const fileRes = await controlledAPI({ ignoreUser: true }).post(`/marketplace/card/gallery`, uploadParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        });
  
        if (fileRes.code >= 400) {
          throw new Error("Could not get server info");
        }
  
        files = [...files, fileRes.data];
      }
    } 

    return { files, cardId };

  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const updateCardThunk = createAsyncThunk('marketplace/updateCard', async (payload, thunkAPI) => {
  const { id, params } = payload;

  try {
    const res = await api.put(`/marketplace/card/${id}`, {
      title: params.title,
      description: params.description,
      miniDescription: params.miniDescription,
    });
    const resData = { id, params };

    if (res.code >= 400) {
      throw new Error("Could not get server info");
    }
    resData.card = res.data;

    return { card: resData };
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const updateCardWithHashThunk = createAsyncThunk('marketplace/updateCardWithHash', async (payload, thunkAPI) => {
  const { id, params } = payload;
  let resData = { id, params };

  try {
    const res = await controlledAPI({ ignoreUser: true }).get(`/marketplace/card/${params.urlHash}`);
    if ((res?.code >= 400 || res?.status >= 400) && res.message[0] === 'marketplace_cards.not_exist') {
      const cardRes = await api.put(`/marketplace/card/${id}`, {
        title: params.title,
        description: params.description,
        miniDescription: params.miniDescription,
        urlHash: params.urlHash,
      });
      resData = { id, params };

      if (cardRes.code >= 400) {
        throw new Error("Could not get server info");
      }
      resData.card = cardRes.data;
    } else if (res?.code === 200 || res?.status === 200) {
      
      throw new Error('This hash name is already taken.');
    }

    return { card: resData };

  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const deleteCardThunk = createAsyncThunk('marketplace/deleteCard', async (payload, thunkAPI) => {
  const { id } = payload;
  try {
    const res = await api.delete(`/marketplace/card/${id}`);


    if (res.code >= 400) {
      throw new Error("Could not get server info");
    }

    return { id, settings: res.model_settings };

  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const deleteFileThunk = createAsyncThunk('marketplace/deleteCard', async (payload, thunkAPI) => {
  const { id, cardId } = payload;
  try {
    const res = await api.delete(`/marketplace/card/gallery/${id}`);


    if (res.code >= 400) {
      throw new Error("Could not get server info");
    }

    return { id, cardId };

  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});
