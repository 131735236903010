import { createPixel } from "_helpers/apiHandlers";
import { getToken, setToken } from "_helpers/tokenManagement";




export const shouldSendFBPixel = () => {

    const pixelSent = getToken("FBPixelSent") || false;
    return !pixelSent;
};

export const sendPixelFB = async (params = { event: 'CompleteRegistration', content_name: null, content_category: null, content_type: null, custom: null, once: false }) => {

    if(process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
        return;
    }
    const { event, content_name, content_category, content_type, custom, once } = params;
    let url = `https://www.facebook.com/tr?id=569201103273541&ev=${event}`;

    if (content_name) {
        url += `&cd[content_name]=${encodeURIComponent(content_name)}`;
    }

    if (content_category) {
        url += `&cd[content_category]=${encodeURIComponent(content_category)}`;
    }

    if (content_type) {
        url += `&cd[content_type]=${encodeURIComponent(content_type)}`;
    }

    if (custom) {
        url += custom;
    }


    const onLoad = () => {
        if (once) {
            setToken("FBPixelSent", "true");
        }
    };

    await createPixel(url, onLoad, undefined, {allowError: true});
};