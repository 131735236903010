import styles from "./training.module.css";
import {
    Box,
    Container,
    Paper,
    Typography,
    TextField,
    Button, Tooltip, Badge, ListItemText,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {useNavigate, Outlet, useLocation} from "react-router-dom";
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import brainToolsMap from "mappings/languages/en/brainToolsLabels.js";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import styled from "@emotion/styled";
import useIsSmallScreen from "hooks/useIsSmallScreen";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    '& .MuiToggleButton-root': {
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: '#fefefe',
            margin: '2px',
            borderRadius: '10px'
        },
        color: '#686d77',
        fontWeight: 600,
        textTransform: 'Capitalize',
        border: 'unset',
        fontSize: '12px',
        padding: '5px 10px',
        fontFamily: 'Inter'
    },
    padding: '2px',
    borderRadius: '10px',
    backgroundColor: '#f4f4f5'

}));


const labels = brainToolsMap.labels
const navigationMenuItems = labels.navigationMenuItems;

const ToolsHeader = () => {

    const nav = useNavigate();
    const location = useLocation();
    const {smallScreen} = useIsSmallScreen();

    const [chip, setChip] = useState('');

    const changeChip = (e, chip) => {
        e.preventDefault();
        if (!chip) {
            return;
        }

        nav('./' + chip);
        setChip(chip);
    };

    useEffect(() => {
        // const chipPath = location.pathname?.split('/').pop();
        const chipPath = location.pathname?.split('/')[2] || '';
        if (chipPath !== chip) {
            setChip(chipPath);
        }

    }, [location.pathname])

    const headerInfo = labels[chip] || {};

    return (
        <Grid container sx={{flexFlow: 'column nowrap'}}>
            <Grid item xs="auto">
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography px={2} sx={{fontSize: 24, paddingLeft: 0}}>{headerInfo.title}</Typography>
                    <GeneralTooltip placement='bottom' title={headerInfo.description}>
                        <HelpOutlineIcon sx={{color: '#B5B5B5'}}/>
                    </GeneralTooltip>
                </Box>

            </Grid>
            <Grid item xs="auto" sx={{margin: "20px 0"}}>
                <StyledToggleButtonGroup
                    fullWidth={smallScreen}
                    // color="primary"
                    value={chip}
                    exclusive
                    onChange={changeChip}
                    aria-label="Platform"
                >
                    {/*<ToggleButton value="brain">Brain</ToggleButton>*/}
                    {/*<ToggleButton value="text">Text</ToggleButton>*/}
                    {/*<ToggleButton value="q&a">Q&A</ToggleButton>*/}
                    {/*<ToggleButton value="email">Email</ToggleButton>*/}
                    {/*<ToggleButton value="files">Files</ToggleButton>*/}
                    {!Boolean(localStorage.getItem('onboardingBrainStatus')) && (
                        navigationMenuItems.map((item) => (
                            <ToggleButton key={item.value} value={item.value}>
                                {
                                    // for beta (remove later)
                                    item.badge ?
                                        <Badge
                                            badgeContent={item.badge}
                                            size="small"
                                            color="secondary"
                                            sx={{
                                                '& .MuiBadge-badge': {
                                                    fontSize: '0.6rem',
                                                    padding: '0 6px',
                                                    height: '18px',
                                                    minWidth: '15px',
                                                    top: '-5px',
                                                    textTransform: 'lowercase'
                                                },
                                            }}
                                        >
                                            {item.label}
                                        </Badge>
                                        :
                                        item.label
                                }
                            </ToggleButton>
                        ))
                    )}

                </StyledToggleButtonGroup>
            </Grid>

            {/*<Grid item xs="auto" sx={{marginBottom: "20px"}}>*/}
            {/*    <Stack direction="row" spacing={2} sx={{mt: 4}}>*/}
            {/*        <Chip*/}
            {/*            color="primary"*/}
            {/*            label="Brain"*/}
            {/*            variant={chip === "brain" ? "standard" : "outlined"}*/}
            {/*            onClick={() => changeChip("brain")}*/}
            {/*        />*/}
            {/*        <Chip*/}
            {/*            color="primary"*/}
            {/*            label="Text"*/}
            {/*            variant={chip === "text" ? "standard" : "outlined"}*/}
            {/*            onClick={() => changeChip("text")}*/}
            {/*        />*/}
            {/*        <Chip*/}
            {/*            color="primary"*/}
            {/*            label="Q/A"*/}
            {/*            variant={chip === "q&a" ? "standard" : "outlined"}*/}
            {/*            onClick={() => changeChip("q&a")}*/}
            {/*        />*/}
            {/*        <Chip*/}
            {/*            color="primary"*/}
            {/*            label="Email"*/}
            {/*            variant={chip === "email" ? "standard" : "outlined"}*/}
            {/*            onClick={() => changeChip("email")}*/}
            {/*        />*/}
            {/*        <Chip*/}
            {/*            color="primary"*/}
            {/*            label="Files"*/}
            {/*            variant={chip === "files" ? "standard" : "outlined"}*/}
            {/*            onClick={() => changeChip("files")}*/}
            {/*        />*/}
            {/*    </Stack>*/}
            {/*</Grid>*/}
        </Grid>
    )
}

function Training() {

    const model = useSelector((state) => state.models.current, shallowEqual);

    return (
        <Grid container sx={{px: 3, py: 6, height: 'inherit', overflowY: "auto", flexFlow: 'column nowrap'}}
            // className={styles["container"]}
            //   spacing={2}
            // minHeight="100%"
        >

            <Grid item xs="auto">
                <ToolsHeader/>
            </Grid>
            <Grid item xs={12}>
                <Outlet context={{modelId: model.id}}/>
            </Grid>

        </Grid>
    );
}

export default Training;
