import { Typography, Grid, IconButton, Badge, Menu, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import DoneIcon from '@mui/icons-material/Done';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import { useState, useMemo } from "react";
import formatTime from "utils/formatTime";
import MoreIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteModel, deleteInvitation, changeInvitationStatus, acceptInvitation } from "redux/models";
import { useNavigate } from "react-router-dom";
import api from "_services/api";
import { interactWithUser } from "redux/status";
import { unfollow } from "redux/models";
import AccessChecker from "components/security/AccessChecker";
import "../../index.css";
import {changeModelAction} from "_actions/modelActions";

const ParentUsedComponent = ({ children, modelId, currentModelId }) => {
  return modelId == currentModelId ? (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      badgeContent="selected"
      color="success"
      variant="dot"
    >
      {children}
    </Badge>
  ) : (
    <>{children}</>
  );
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ModelItem = ({ model }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { models, user } = useSelector((state) => ({
    models: state.models,
    user: state.auth?.user,
  }));
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const isOwnersModel = model.user_id === user.id;

  const currentModelId = useMemo(() => {
    return models.current.id || 0;
  }, [models]);

  const handleModelSelect = (modelId) => {
      changeModelAction(dispatch, modelId);

  };

  const handleDeleteModel = async (id) => {
    await api
      .delete(`/model/${id}`)
      .then((r) => {
        // dispatch(
        //   interactWithUser({
        //     message: "Model was succesfully deleted",
        //     type: "info",
        //   })
        // );
        dispatch(deleteModel({ id }));
      })
      .catch((error) => {});
  };

  const handleAcceptInvitation = async () => {
    await api
      .post('model/invite/action', { action: 'accept', hash: model.unique_hash })
      .then((r) => {
        if (r.code >= 400) {
          dispatch(
            interactWithUser({
              message: `We were not able to process the invitation`,
              type: 'error',
            })
          );

          return;
        }
        dispatch(
          interactWithUser({
            message: `Invitation was successfully accepted`,
          })
        );
        dispatch(acceptInvitation({ id: model.id, hash: model.unique_hash }));
      })
      .catch((error) => {
        dispatch(
          interactWithUser({
            message: `Invitation was successfully accepted`,
          })
        );
      });
  };

  const handleUnfollowInvitation = async () => {
    dispatch(unfollow({ modelId: model.id })).then((res) => {
      if (res.payload) {
        dispatch(
          interactWithUser({
            message: 'The invitation has been rejected',
          })
        );
      }
    }).catch(() => {
      dispatch(
        interactWithUser({
          message: 'We were not able to process the invitation',
          type: 'error',
        })
      );
    });
    // await api
    //   .post('model/share/unfollow', { modelId: model.id })
    //   .then((r) => {
    //     if (r.code >= 400) {
    //       dispatch(
    //         interactWithUser({
    //           message: 'We were not able to process the invitation',
    //           type: 'error',
    //         })
    //       );
          
    //       return;
    //     }
    //     dispatch(
    //       interactWithUser({
    //         message: 'The invitation has been rejected',
    //       })
    //     );
    //     dispatch(deleteModel({ id: model.id }));
    //   })
    //   .catch((error) => {});
  };

  const handleRejectInvitation = async () => {
    await api
      .post('model/invite/action', { action: 'reject', hash: model.unique_hash })
      .then((r) => {
        if (r.code >= 400) {
          dispatch(
            interactWithUser({
              message: 'We were not able to process the invitation',
              type: 'error',
            })
          );
          
          return;
        }
        dispatch(
          interactWithUser({
            message: 'The invitation has been rejected',
          })
        );
        dispatch(deleteInvitation({ id: model.id }));
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleEditModel = (id) => {
    navigate(`/models/${id}`);
  };

  const { date } = model.created_at;
  const calculatedDate = formatTime({ date });

  return (
    <ParentUsedComponent modelId={model.id} currentModelId={currentModelId}>
      <Card className="custom-card">
        <Grid className="custom-card-content">
          <Typography
            variant="cards_title"
            component="div"
            sx={{
              display: "-webkit-box",
              webkitLineClamp: 2,
              webkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {model.name}
          </Typography>

          <Typography variant="p" component="p" sx={{ fontSize: 12 }}>
            Date Created:
          </Typography>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            {calculatedDate}
          </Typography>
        </Grid>
        <CardActions sx={{ height: "64px" }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: "5px", width: "70%" }}
            onClick={() => handleModelSelect(model.id)}
            disabled={model.id === currentModelId || Boolean(model.unique_hash)}
          >
            select
          </Button>

          {/* <AccessChecker
            currentPermissions={model.access}
            requiredPermissions={["write"]}
          > */}
          {/*{model.id !== 78 && (*/}
            <IconButton
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
              onClick={handleMenuOpen}
            >
              <MoreIcon color="icons" />
            </IconButton>
          {/*)}*/}
          {/* </AccessChecker> */}
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <Tooltip title='Share button is not activated in Beta version'>
              <span>
              <MenuItem disabled disableRipple>
              <IosShareIcon/>
                Share
              </MenuItem>
              </span>
            </Tooltip> */}
            {model.unique_hash ? (
              <Box>
                <MenuItem onClick={handleAcceptInvitation} disableRipple>
                  <DoneIcon />
                  Accept Invitation
                </MenuItem>
                <MenuItem onClick={handleRejectInvitation} disableRipple>
                  <RemoveCircleOutlineIcon />
                  Reject
                </MenuItem>
              </Box>
            ) : (
              <Box>
                {isOwnersModel ? (
                  <AccessChecker
                    currentPermissions={model.access}
                    requiredPermissions={["write"]}
                  >
                    <MenuItem onClick={() => handleEditModel(model.id)} disableRipple>
                      <EditIcon />
                      Edit
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      onClick={() => handleDeleteModel(model.id)}
                      disableRipple
                    >
                      <DeleteIcon />
                      Delete
                    </MenuItem>
                  </AccessChecker>
                ) : (
                  <MenuItem onClick={handleUnfollowInvitation} disableRipple>
                    <RemoveCircleOutlineIcon />
                    Unfollow
                  </MenuItem>
                )}
              </Box>
            )}
          </StyledMenu>
        </CardActions>
      </Card>
    </ParentUsedComponent>
  );
};

export default ModelItem;
