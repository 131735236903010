import { useState, useEffect } from "react";
import { ListItemSecondaryAction, Typography } from "@mui/material";
import AttachmentLinks from "components/showcase/AttachmentsLinks";
import controlledAPI from "_services/controlledApi";
import SmoothContent from "components/loadings/SmoothContent";
import { useDispatch } from "react-redux";
import { changeFilePrices } from "redux/tools";

const FilePrice = ({ fileId = 0, modelId = 0, cachedPrice}) => {

    const [status, setStatus] = useState({loading: false, price: cachedPrice});
    const dispatch = useDispatch();
  
    useEffect(()=> {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const fetchPrice = async () => {
  
        setStatus((prevStatus) => ({ ...prevStatus, loading: true }));
  
        let params = {
          modelId: modelId,
          fileId: fileId
        }
        await controlledAPI({ ignoreUser: true })
        .post("file/speak/price", params, { signal: signal })
        .then((r) => {
          let price = r.code == 200 ? r.price : 1;
          dispatch(changeFilePrices({fileId: fileId, price: price }))
          setStatus({ loading: false, price: price });
        })
        .catch((err) => {
          setStatus({ loading: false, price: 1 });
        });
      }
  

      if(!cachedPrice) {
        fetchPrice()
      }

  
      return () => {
        abortController.abort();
      }
  
    },[]);
  
    const label = `${status.price} credit${status.price == 1 ? '' : 's'}`;
    return <ListItemSecondaryAction><SmoothContent height={15} loading={status.loading}><Typography fontSize={12} variant="describe_text">{label}</Typography></SmoothContent></ListItemSecondaryAction>
  }




  export default FilePrice;