import { Button } from "@mui/base";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import api from "_services/api";
import FacebookIcon from '@mui/icons-material/Facebook';
import { setToken } from "_helpers/tokenManagement";

const FacebookAuthBtn = ({
  redirectUrl,
  redirectUser = "",
  text = "",
  fullWidth = false,
  facebookOptions = {},
}) => {
  const [url, setUrl] = useState();

  const Btn = styled.a`
    background-color: #3b5998;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    padding: 8px 10px !important;

    & > svg {
      margin-right: 5px;

    }
    ${props => props.disabled ? "opacity: 0.5; cursor: not-allowed; pointer-events: none;" :""}
  `;

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        redirectUrl: redirectUrl,
      };

      try {

        let response = await api.post('facebook/oauth2/url', params);
        if (response.code >= 400) {
          throw Error("Something went wrong..");
        }

        let authUrl = response.authUrl || "";
        setUrl(authUrl);
      } catch (error) {
        console.error(error); // Handle any errors
      }
    };

    fetchData();
  }, []);


  const handleRedirect = (e) => {
    e.preventDefault();
    redirectUser && setToken("loginRedirectUrlSession",redirectUser)
    window.location.href = url;
  }


  return (
    <Btn
      variant="contained"
      color="primary"
      onClick={handleRedirect}
      disabled={!Boolean(url)}
    >
      <FacebookIcon /> {text}
    </Btn>
  );
};

export default FacebookAuthBtn;
