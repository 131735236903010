import {checkLimitations, checkPermissions} from "_helpers/checkPermissions";
import {shallowEqual, useSelector} from "react-redux";
import {useCallback} from "react";

const usePermissions = () => {

  const planLimitations = useSelector(
    (state) => state.permissions.planLimitations,
    shallowEqual
  );

  const userPermissions = useSelector(
      (state) => state.permissions.userPermissions,
      shallowEqual
  );

  const userActionAllowed = useCallback((action) => {
    return (typeof action === 'undefined' ||
        userPermissions.includes('*') ||
        checkPermissions(userPermissions, action));
  }, [userPermissions]);

  const hasAccess = useCallback(({ limitationAction }) => {
    const isAnyLimitations = Object.keys(planLimitations).length !== 0;
    return !isAnyLimitations || checkLimitations(planLimitations, limitationAction);
  }, [planLimitations]);

  return { hasAccess, userActionAllowed };
};

export default usePermissions;
