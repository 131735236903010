import { useStripe } from "@stripe/react-stripe-js";
import { createPixel } from "_helpers/apiHandlers";
import api from "_services/api";
import { getList, changeModel } from 'redux/models';
import controlledAPI from "_services/controlledApi";
import {removeJsonData, updatePlan} from "redux/auth";
import ShadowSpinner from "components/loadings/ShadowSpinner";
import { sendPixelGenai } from "events/analytics/genai";
import { sendPixelFB } from "events/analytics/facebook";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPermissionsAndLimitations } from "redux/permissions";
import { interactWithUser } from "redux/status";

const CheckoutSession = ({ plan = {}, paymentMethod = {}, monetizationModel = null, handleError }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const stripe = useStripe();

    const planId = plan?.id;
    const [loading, setLoading] = useState(true);

    const handleMessage = (message, type = "error", showStatus = true) => {
        setLoading(false);

        if (type === 'success' && Boolean(plan)) {
          dispatch(updatePlan({plan: plan}));
        } else if (type === 'error') {
          handleError();
        }

        if(showStatus) {
          dispatch(
            interactWithUser({
                message: message ?? "Something went wrong..",
                type: type,
            })
        );
        }



        navigate("/billing/balance");
        return;
    }

    useEffect(() => {
        const startCheckout = async () => {
          const currentPlanId = auth.user.tariff_plan.id;
          if (Boolean(plan?.id) && Number(currentPlanId) !== Number(plan?.id)) {
            const sendEventFB = async () => {
              await sendPixelFB({ event: 'Purchase', content_name: plan.name });
            };

            if (auth.user?.jsonData?.genai) {
              await sendPixelGenai({ afprice: plan.price });
            }


            let { code, stripe_subscription } = await api.get(`billing/tariff-plans/choose/${planId}`);

            if (code != 200) {
                return handleMessage("Something went wrong..");
            }


            const payment_intent = stripe_subscription?.latest_invoice?.payment_intent;
            const clientSecret = payment_intent?.client_secret || false;

            if (stripe_subscription.status == "active") {


            } else if (stripe_subscription.status == "incomplete" && payment_intent.status == "requires_action") {

                if (clientSecret) {
                    const confirmPayment = await stripe?.confirmCardPayment(
                        clientSecret
                    );

                    if (confirmPayment?.error || confirmPayment?.last_payment_error?.code) {
                        return handleMessage(confirmPayment.error.message || confirmPayment?.last_payment_error?.message);
                    }

                    const subId = stripe_subscription.id;

                    let finalizeTariff = await api.get(`billing/tariff-plans/finalize/${planId}/${subId}`);


                    if (finalizeTariff.code == 200) {
                        let url = `https://www.shareasale.com/sale.cfm?tracking=${plan.stripe_id}&amount=${plan.price}&merchantID=149700&transtype=sale`;
                        await createPixel(url);
                        await sendEventFB();

                        return handleMessage("Updated subscription plan", "success");
                    } else {
                        return handleMessage("Could not complete the verification.");
                    }

                    // if(confirmPayment.status == "succeeded") {
                    //     navigate("/billing/payment/complete");
                    // }
                }
            } else {
                return handleMessage("Could not complete the payment. Please, try again..");
                // console.warn("smth wrong")
            }

            let url = `https://www.shareasale.com/sale.cfm?tracking=${plan.stripe_id}&amount=${plan.price}&merchantID=149700&transtype=sale`;
            await createPixel(url);
            await sendEventFB();

            dispatch(setPermissionsAndLimitations({ limitations: plan.limitation }));
            
          }

          if (Boolean(monetizationModel)) {
            const { model_id } = monetizationModel;
            // TODO: move to redux
            const { code, stripe_subscription } = await api.get(`user-model/subscribe/${model_id}`);
            const updatedJsonData = auth.user.jsonData ? { ...auth.user.jsonData } : null;
            if (Boolean(updatedJsonData) && updatedJsonData.hasOwnProperty('monetizationSubscription')) {
              delete updatedJsonData.monetizationSubscription;
            }
            const user = await controlledAPI({ ignoreUser: true }).put(`users/${auth.user.id}`, { jsonData: updatedJsonData });
            
            if (code != 200 || user.code >= 400) {
              console.log('error monetization');
              //handleError();
              return handleMessage("Something went wrong..", 'error', false);
            }
            dispatch(getList()).then(() => {
              dispatch(changeModel(model_id));
            });
            // TODO: move to redux - after fetch
            dispatch(removeJsonData({ dataKey: 'monetizationSubscription' }));
    
            const payment_intent = stripe_subscription?.latest_invoice?.payment_intent;
            const clientSecret = payment_intent?.client_secret || false;
    
            if (stripe_subscription.status == "active") {
    
            } else if (stripe_subscription.status == "incomplete" && payment_intent.status == "requires_action") {
              if (clientSecret) {
                const confirmPayment = await stripe?.confirmCardPayment(clientSecret);
    
                if (confirmPayment?.error || confirmPayment?.last_payment_error?.code) {
                  return handleMessage(confirmPayment.error.message || confirmPayment?.last_payment_error?.message);
                }
              }
            } else {
              return handleMessage("Could not complete the payment. Please, try again..");
              // console.warn("smth wrong")
            }
          }
          return handleMessage("Updated subscription plan", "success");
        }

        startCheckout();
    }, [])

    return <ShadowSpinner open={loading} />;
}

export default CheckoutSession;
