import {memo} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";

export const SizeSettings = memo(({
                                      handleChanges = () => {
                                      }
                                  }) => {

    const onWidthChange = (e) => {
        const value = e.target.value;
        handleChanges({width: value});
    }

    const onHeightChange = (e) => {
        const value = e.target.value;
        handleChanges({height: value});
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <TextField
                    variant="filled"
                    placeholder={'Change your bubble width'}
                    sx={{opacity: 0.6}}
                    defaultValue="60"
                    fullWidth
                    label="Bubble Width"
                    onChange={onWidthChange}
                    InputProps={{
                        style: {paddingRight: "5px"},
                        type: 'number'
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    variant="filled"
                    placeholder={'Change your bubble height'}
                    sx={{opacity: 0.6}}
                    defaultValue="60"
                    fullWidth
                    label="Bubble Height"
                    onChange={onHeightChange}
                    InputProps={{
                        style: {paddingRight: "5px"},
                        type: 'number'
                    }}
                />
            </Grid>
        </Grid>
    )
})