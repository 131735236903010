import { useEffect, useState, useRef } from "react";
import { Box, Button, Divider, Modal } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Slider from "react-slick";
import classnames from 'classnames';
import { useTheme } from '@mui/material/styles';
import "slick-carousel/slick/slick.css";
import styles from '../shareModelMarketplace.module.css';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <Button onClick={onClick} className={classnames(styles.carouselCta, styles.carouselCtaNext)}>
      <ArrowForwardIosIcon />
    </Button>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <Button onClick={onClick} className={classnames(styles.carouselCta, styles.carouselCtaPrev)}>
      <ArrowBackIosNewIcon />
    </Button>
  );
}

const GalleryContent = ({ gallery_items }) => {
  const [sliderWidth, setSliderWidth] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      setSliderWidth(sliderRef.current.offsetWidth);
    }
    
    const handleResize = () => {
      if (sliderRef.current) {
        setSliderWidth(sliderRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => setOpen(false);

  return (
    <>
      {gallery_items.length === 1 ? (
        <Box sx={{ position: 'relative', paddingBottom: '10px' }}>
          <Box
            onClick={() => {
              setOpen(true);
              setSelectedImage(0);
            }}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: isMobile ? '0 5px' : '0 20px',
              boxShadow: '0 3px 10px #444 inner',
              marginBottom: { xs: '10px', md: '20px'},
              background: 'none !important',
              cursor: 'pointer',
            }}>
              <Box 
                sx={{ 
                  border: '1px solid rgba(0,0,0, .3)',
                  borderRadius: '10px',
                  width: '100%',
                  height: {xs: '220px', md: '240px', lg: '300px' },
                  background: `center / cover no-repeat url(${gallery_items[0].file_url})`,
                  boxShadow: '1px 3px 6px rgba(0,0,0, .3)'
                }} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ position: 'relative', paddingBottom: {xs: '30px', md: '85px'} }} ref={sliderRef}>
          <Slider {...{
            dots: true,
            infinite: true,
            arrows: !isMobile,
            speed: 500,
            autoplay: open ? false : true,
            slidesToShow: isMobile ? 1 : 2,
            slidesToScroll: 1,
            afterChange: current => setActiveIndex(current),
            appendDots: dots => (
              <Box sx={{ position: 'absolute', left: 0, right: 0, marginTop: { xs: '0', md: '15px' } }}>
                <ul style={{ marginTop: 0, display: 'flex', listStyleType: 'none', gap: '10px', padding: 0, justifyContent: 'center' }}>{dots}</ul>
              </Box>
            ),
            customPaging: i => {
              return (
                <Button
                  key={`indicator-${i}`}
                  className={classnames(styles.indicator, activeIndex === i && styles.indicatorActive)}
                  style={{ background: `center/cover no-repeat url(${gallery_items[i]?.file_url})` }}
                />
              );
            },
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
          }}>
            {gallery_items?.map((item, i) => (
              <Box
                key={`slide-${i}`}
                onClick={() => {
                  setOpen(true);
                  setSelectedImage(i);
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: isMobile ? '0 5px' : '0 20px',
                  boxShadow: '0 3px 10px #444 inner',
                  marginBottom: { xs: '10px', md: '20px'},
                  background: 'none !important',
                  cursor: 'pointer',
                }}>
                  <Box
                    sx={{ 
                      border: '1px solid rgba(0,0,0, .3)',
                      borderRadius: '10px',
                      // height: {xs: '220px', sm: '240px', md: '250px' },
                      height: { xs: sliderWidth > 0 ? `${sliderWidth / 2}px` : '210px', md: sliderWidth > 0 ? `${sliderWidth / 4.2}px` : '250px' },
                      background: `center / cover no-repeat url(${item.file_url})`,
                      boxShadow: '1px 3px 6px rgba(0,0,0, .3)'
                    }}/>
              </Box>
            ))}
          </Slider>
        </Box>
      ) }
      <Divider sx={{ margin: {xs:'20px 0', md: '37px 0'} }} />
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', width: '100%', height: '100%' }}
      >
        <Box sx={{ padding: { xs: '10px', md: '20px' }, margin: 'auto', maxWidth: '800px', outline: 'none' }}>
          <img src={gallery_items[selectedImage]?.file_url} width="100%" />
        </Box>
      </Modal>
    </>
  )
}

export default GalleryContent;
