import { Box, Button, Divider, Grid, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getBillingInfo } from "redux/billing";
import { getSubscriptions } from "redux/models";
import classnames from 'classnames';
import styles from "./billing.module.css";
import ProgressBar from "components/showcase/ProgressBar";
import { useNavigate } from "react-router-dom";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import History from "./components/History";
import moment from "moment";
import SmoothContent from "components/loadings/SmoothContent";
import {sendUIEvent} from "../../../../events/analytics/google";

const Balance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const billing = useSelector((state) => state.billing, shallowEqual);
  const marketplace = useSelector((state) => state.marketplace, shallowEqual);
  const models = useSelector((state) => state.models, shallowEqual);
  const isLoading = billing.loadingBilling;
  const maximumCredits = billing.planInfo?.maximumAllowedCredits || 0;
  const availableCredits = billing.planInfo?.availableCredits || 0;
  const currentPlan = billing.planInfo?.currentPlan || {};
  const billingInfo = billing.billingInfo || {};
  const lastFourDigits = billingInfo.lastFourDigits || null;
  const startDate = moment(currentPlan?.startDate?.date).local().format('YYYY-MM-DD');
  const endDate = moment(currentPlan?.endDate?.date).local().format('YYYY-MM-DD');
  const { subscriptions } = models;
  const marketplaceLoading = marketplace.globalLoading;

  useEffect(() => {
    dispatch(getBillingInfo());
    dispatch(getSubscriptions());
  }, []);

  const handleRedirect = (path = "/") => {
    const defaultPath = "/billing";
    navigate(defaultPath + path);
  }

  return (
    <Grid container className={styles["main-container"]} spacing={12}>
      <Grid item xs={12} md={12}>
        <Typography sx={{ fontSize: 24 }}>Billing & Balance</Typography>
      </Grid>
      <Grid container item xs={12} md={4} spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="page_sub_title_bold">Credits Available</Typography>
          <SmoothContent loading={isLoading}>
            <Typography variant="page_content" sx={{fontSize: "11px"}}>{ `${availableCredits}/${maximumCredits} credits`}</Typography>
          </SmoothContent>
        </Grid>
        <Grid item xs={12}>
          <ProgressBar maxValue={maximumCredits} currentValue={availableCredits} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="page_sub_title_bold">Current plan</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={styles["plan-card"]}>
            <Grid item xs={8}>
              <SmoothContent loading={isLoading}>
                <Typography className={styles["plan-name"]} variant="page_sub_title_bold">{currentPlan.name || "Trial"}</Typography>
              </SmoothContent>
            </Grid>
            <Grid item xs="auto">
            <SmoothContent loading={isLoading}>
              <Typography variant="page_content">{`$${currentPlan.chargePerMonth}/${currentPlan.frequency}`}</Typography>
              </SmoothContent>
            </Grid>
            <Grid item xs={12} paddingBottom="10px">
              <SmoothContent loading={isLoading}>
                <Typography variant="page_content" fontSize="10px">{currentPlan.description}</Typography>
              </SmoothContent>
            </Grid>
            <Grid item xs={12}>
              <SmoothContent loading={isLoading}>
                <Typography variant="describe_text" fontSize="10px">{`Started: ${startDate}`}</Typography>
              </SmoothContent>
            </Grid>
            <Grid item xs={12}>
              <SmoothContent loading={isLoading}>
                <Typography variant="describe_text" fontSize="10px">{`Next renewal: ${endDate}`}</Typography>
              </SmoothContent>
            </Grid>
          </Grid>
        </Grid>
        {subscriptions?.filter(item => item.status === 'active')?.map((item, i) => {
          const startDate = moment(item?.created_at?.date).local().format('YYYY-MM-DD');
          const endDate = moment(item?.created_at?.date).local().add(1, 'months').format('YYYY-MM-DD');

          return (
            <Grid key={`${item.id}-${i}`} item xs={12}>
              <Grid container className={styles["plan-card"]}>
                <Grid item xs={8}>
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography className={styles["plan-name"]} variant="page_sub_title_bold">{item.model?.name}</Typography>
                  </SmoothContent>
                </Grid>
                <Grid item xs="auto">
                <SmoothContent loading={marketplaceLoading}>
                  <Typography variant="page_content">
                    {`$${item.model?.settings?.monetization_price}/${item.model?.settings?.monetization_charge_frequency}`}
                  </Typography>
                  </SmoothContent>
                </Grid>
                {/* <Grid item xs={12} paddingBottom="10px">
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography variant="page_content" fontSize="10px">{item.description}</Typography>
                  </SmoothContent>
                </Grid> */}
                <Grid item xs={12}>
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography variant="describe_text" fontSize="10px">{`Started: ${startDate}`}</Typography>
                  </SmoothContent>
                </Grid>
                <Grid item xs={12}>
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography variant="describe_text" fontSize="10px">{`Next renewal: ${endDate}`}</Typography>
                  </SmoothContent>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
        {subscriptions?.filter(item => item.status === 'canceled')?.map((item, i) => {
          const startDate = moment(item?.created_at?.date).local().format('YYYY-MM-DD');
          const endDate = moment(item?.end_at?.date).local().add(1, 'months').format('YYYY-MM-DD');

          return (
            <Grid key={`${item.id}-${i}`} item xs={12}>
              <Grid container className={classnames(styles["plan-card"], styles.calceled)}>
                <Grid item xs={8}>
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography className={styles["plan-name"]} variant="page_sub_title_bold">{item.model?.name}</Typography><br />
                  </SmoothContent>
                </Grid>
                <Grid item xs="auto">
                <SmoothContent loading={marketplaceLoading}>
                  <Typography variant="page_content">
                    {`$${item.model?.settings?.monetization_price}/${item.model?.settings?.monetization_charge_frequency}`}
                  </Typography>
                  </SmoothContent>
                </Grid>
                {/* <Grid item xs={12} paddingBottom="10px">
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography variant="page_content" fontSize="10px">{item.description}</Typography>
                  </SmoothContent>
                </Grid> */}
                <Grid item xs={12}>
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography variant="describe_text" fontSize="10px">{`Started: ${startDate}`}</Typography>
                  </SmoothContent>
                </Grid>
                <Grid item xs={12}>
                  <SmoothContent loading={marketplaceLoading}>
                    <Typography variant="describe_text" fontSize="10px">{`Expires: ${endDate}`}</Typography>
                  </SmoothContent>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Button onClick={() => { handleRedirect('/subscribe'); sendUIEvent({name: 'billing_page_subscribe_btn_click'})}} className={styles["upgrade-btn"]} color="secondary" fullWidth size="small" variant="outlined">Subscription management</Button>
        </Grid>
      </Grid>
      <Grid item xs="auto" >
        <Divider orientation="vertical" />
      </Grid>
      <Grid container item xs={12} md={7} paddingTop="15px" spacing={4} sx={{ alignContent: 'start' }}>
        <Grid item xs={12} md={12}>
          <Typography variant="page_sub_title_bold">Billing and Payment</Typography>
        </Grid>
        <Grid item container xs={12} md={12} justifyContent="space-between">
          <Grid item xs={12} md={8} >
            <Box className={styles.creditCard}>
              <CreditCardIcon color="icons" /> <SmoothContent loading={isLoading}><Typography size="small" variant="page_content"> {billingInfo.lastFourDigits ||  "No card Attached"}</Typography></SmoothContent>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{textAlign: 'right'}}>
            <Button onClick={() => { handleRedirect('/payment/all'); sendUIEvent({name: 'billing_page_manage_cards_btn_click'})}} className={styles["upgrade-btn"]} color="secondary" size="small" variant="outlined">Manage Cards</Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} >
          <Typography variant="page_sub_title_bold">Billing History</Typography>
          {/* <History billingHistory={[...billingInfo.billingHistory, ...billingInfo.marketplaceInvoices]} subscriptions={subscriptions} isLoading={isLoading} /> */}
          <History billingHistory={billingInfo.billingHistory} subscriptions={subscriptions} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Balance;
