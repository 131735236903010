import React from "react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CreditCardIcon from "@mui/icons-material/CreditCard";


MenuIcons.defaultProps = {
  width: "40",
  height: "40",
  fill: "#7b8191",
};

function MenuIcons({ icon, fill, width, height }) {
  const folder = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 34.286"
    >
      <g transform="translate(-2.25 -4.5)">
        <path
          d="M42.25,10.5c0-1.452-.839-2.511-2.036-2.511H17.152a.6.6,0,0,1-.545-.262L14.6,5.275l-.018-.022A1.783,1.783,0,0,0,13.036,4.5H4.455A2.364,2.364,0,0,0,2.25,7.011v8.046c0,.175.152.164.268.076a1.4,1.4,0,0,1,.625-.153H41.357a1.4,1.4,0,0,1,.625.153c.116.087.268.1.268-.076Z"
          transform="translate(0 0)"
          fill={fill}
        />
        <path
          d="M2.25,31.446a2.685,2.685,0,0,0,2.821,2.522H39.393a2.726,2.726,0,0,0,2.857-2.554V14.5a1.363,1.363,0,0,0-1.429-1.277H3.679A1.363,1.363,0,0,0,2.25,14.5Z"
          transform="translate(0 4.818)"
          fill={fill}
        />
      </g>
    </svg>
  );
  const playground = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 47.5 38"
    >
      <path
        d="M15.438,26.125a1.7,1.7,0,0,0-.524.081,9.791,9.791,0,0,1-3.039.513,9.8,9.8,0,0,1-3.039-.513,1.693,1.693,0,0,0-.523-.081A8.313,8.313,0,0,0,0,34.484,3.553,3.553,0,0,0,3.563,38H20.188a3.553,3.553,0,0,0,3.563-3.516A8.313,8.313,0,0,0,15.438,26.125ZM11.875,23.75A7.125,7.125,0,1,0,4.75,16.625,7.125,7.125,0,0,0,11.875,23.75ZM43.938,0h-28.5a3.627,3.627,0,0,0-3.562,3.681V7.125a9.392,9.392,0,0,1,4.75,1.321V4.75H42.75V26.125H38v-4.75H28.5v4.75H22.842a9.459,9.459,0,0,1,2.946,4.75h18.15A3.627,3.627,0,0,0,47.5,27.194V3.681A3.627,3.627,0,0,0,43.938,0Z"
        fill={fill}
      />
    </svg>
  );
  const brain = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42.75 38"
    >
      <path
        d="M15.438,0a4.736,4.736,0,0,0-4.587,3.577c-.059,0-.1-.015-.163-.015a4.754,4.754,0,0,0-4.75,4.75,4.377,4.377,0,0,0,.126,1.039,5.886,5.886,0,0,0-3.073,8.082,5.915,5.915,0,0,0,.675,10.613,5.439,5.439,0,0,0-.1,1.046,5.34,5.34,0,0,0,5.344,5.344,5.032,5.032,0,0,0,.891-.089,5.331,5.331,0,0,0,10.391-1.692V4.75A4.754,4.754,0,0,0,15.438,0ZM42.75,22.563a5.907,5.907,0,0,0-2.991-5.129,5.841,5.841,0,0,0,.616-2.59,5.937,5.937,0,0,0-3.689-5.492,4.706,4.706,0,0,0-4.624-5.789c-.059,0-.111.015-.163.015A4.741,4.741,0,0,0,22.563,4.75V32.656a5.331,5.331,0,0,0,10.391,1.692,5.032,5.032,0,0,0,.891.089,5.34,5.34,0,0,0,5.344-5.344,5.439,5.439,0,0,0-.1-1.046A5.938,5.938,0,0,0,42.75,22.563Z"
        fill={fill}
      />
    </svg>
  );
  const setting = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
    >
      <path
        id="Icon_material-settings-applications"
        data-name="Icon material-settings-applications"
        d="M23.5,19.278A4.222,4.222,0,1,0,27.722,23.5,4.235,4.235,0,0,0,23.5,19.278ZM38.278,4.5H8.722A4.221,4.221,0,0,0,4.5,8.722V38.278A4.221,4.221,0,0,0,8.722,42.5H38.278A4.221,4.221,0,0,0,42.5,38.278V8.722A4.221,4.221,0,0,0,38.278,4.5Zm-3.694,19a10.685,10.685,0,0,1-.106,1.436L37.6,27.384a.746.746,0,0,1,.169.95l-2.956,5.109a.747.747,0,0,1-.908.317l-3.673-1.478a11.322,11.322,0,0,1-2.491,1.457l-.549,3.906a.766.766,0,0,1-.739.633H20.544a.768.768,0,0,1-.739-.612l-.549-3.906A10.856,10.856,0,0,1,16.766,32.3l-3.673,1.478a.747.747,0,0,1-.908-.317L9.229,28.356a.746.746,0,0,1,.169-.95l3.124-2.449a11.268,11.268,0,0,1-.106-1.457,10.684,10.684,0,0,1,.106-1.436L9.4,19.616a.746.746,0,0,1-.169-.95l2.956-5.109a.747.747,0,0,1,.908-.317l3.673,1.478a11.322,11.322,0,0,1,2.491-1.457l.549-3.906a.766.766,0,0,1,.739-.633h5.911a.768.768,0,0,1,.739.612l.549,3.906A10.856,10.856,0,0,1,30.234,14.7l3.673-1.478a.747.747,0,0,1,.908.317l2.956,5.109a.746.746,0,0,1-.169.95l-3.124,2.449a11.268,11.268,0,0,1,.106,1.457Z"
        transform="translate(-4.5 -4.5)"
        fill={fill}
      />
    </svg>
  );

  const rabbit = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      imageRendering="optimizeQuality"
      clipRule="evenodd"
      fillRule="evenodd"
      shapeRendering="geometricPrecision"
    >
      <path
        d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.69-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.31 28.66 185.31 0 256 0zm-21.91 302.69v-2.07c.16-13.72 1.51-24.59 4.15-32.67 2.59-8.08 6.32-14.65 11.18-19.63 4.87-5.02 10.72-9.58 17.56-13.72 4.4-2.8 8.39-5.9 11.91-9.37 3.52-3.42 6.32-7.41 8.38-11.91 2.07-4.46 3.11-9.42 3.11-14.91 0-6.53-1.55-12.18-4.66-16.99-3.05-4.77-7.19-8.44-12.27-11.08-5.13-2.59-10.82-3.89-17.09-3.89-5.65 0-11.03 1.15-16.21 3.53-5.12 2.33-9.42 6-12.79 10.97-3.36 4.98-5.33 11.35-5.85 19.11h-33.56c.53-13.21 3.89-24.39 10.05-33.55 6.21-9.16 14.4-16.11 24.55-20.82 10.2-4.71 21.49-7.04 33.81-7.04 13.57 0 25.38 2.48 35.52 7.56 10.15 5.02 18.08 12.06 23.72 21.08 5.59 9 8.44 19.47 8.44 31.48 0 8.23-1.29 15.64-3.88 22.21-2.59 6.58-6.22 12.48-10.98 17.61-4.77 5.18-10.41 9.73-17.03 13.67-6.27 3.94-11.35 7.97-15.18 12.17-3.88 4.19-6.68 9.17-8.44 14.86-1.76 5.74-2.75 12.84-2.9 21.33v2.07h-31.54zm16.68 70.67c-6.06 0-11.24-2.18-15.59-6.48-4.34-4.29-6.47-9.53-6.47-15.63 0-6.01 2.12-11.19 6.47-15.49 4.35-4.3 9.53-6.47 15.59-6.47 5.95 0 11.12 2.19 15.48 6.47 4.39 4.31 6.58 9.48 6.58 15.49 0 4.04-1.05 7.76-3.06 11.08-2.02 3.35-4.66 6.07-7.97 8.03-3.31 1.96-6.99 3-11.03 3z"
        fill={fill}
      />
    </svg>
  );

  const models = <AccountTreeIcon />;

  const billing = <CreditCardIcon/>;
  const apps = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 41 41"
    >
      <g transform="translate(-3 -3)">
        <path
          d="M8.722,4.5H38.278A4.222,4.222,0,0,1,42.5,8.722V38.278A4.222,4.222,0,0,1,38.278,42.5H8.722A4.222,4.222,0,0,1,4.5,38.278V8.722A4.222,4.222,0,0,1,8.722,4.5Z"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M18,12V24"
          transform="translate(5.5 5.5)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M12,18H24"
          transform="translate(5.5 5.5)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
  switch (icon) {
    case "folder":
      return folder;
    case "playground":
      return playground;
    case "brain":
      return brain;
    case "setting":
      return setting;
    case "models":
      return models;
    case "apps":
      return apps;
    case "rabbit":
      return rabbit;
    case "billing":
      return billing;
    default:
      return null;
  }
}

export default MenuIcons;
