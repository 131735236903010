import { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Skeleton,
  TableContainer,
} from "@mui/material";
import api from "_services/api";
import controlledAPI from "_services/controlledApi";
import { useDispatch } from "react-redux";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { interactWithUser } from "redux/status";
import { deleteInvitation } from "redux/invitations";
import CancelIcon from '@mui/icons-material/Cancel';

const Row = ({ row }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteChat = () => {
    setDeleteLoading(true);
    api
      .delete(`model/invite/delete/${row.id}`)
      .then((res) => {
        dispatch(deleteInvitation({id: row.id}));
        setDeleteLoading(false);
        dispatch(
          interactWithUser({
            message: "All Good",
            type: "info",
          })
        );
      })
      .catch((error) => {
        setDeleteLoading(false);
      });
  };

  const resendInvitation = async () => {
    const response = await controlledAPI({ ignoreUser: true }).post('model/invite/sent', {
      modelId: row.model,
      recipientEmails: [ row.recipient_email ],
    });

    if (response.code >= 400) {
      const message = response?.message || "Something went wrong";

      dispatch(
        interactWithUser({
          message: message ?? "Something went wrong..",
          type: 'error',
        })
      );
      return;
    }

    dispatch(
      interactWithUser({
        message: "All Good",
        type: "info",
      })
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography
            sx={{ paddingLeft: "10px" }}
            variant="div"
          >
            {row.recipient_email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={{ paddingLeft: "10px" }}
            variant="div"
          >
            {row.status}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'right' }}>
            <IconButton onClick={resendInvitation}>
              <ForwardToInboxIcon  />
            </IconButton>
            <IconButton size="sm" variant="contained" color="error" onClick={handleDeleteChat}>
              <CancelIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
