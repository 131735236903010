import {createSlice} from "@reduxjs/toolkit";
import {removeToken} from "_helpers/tokenManagement";
import {GET_EMAIL_CACHED_DATA_NAME} from "mappings/constants";
import {getGmailEmailList, getOutlookEmailsList, getOutlookFolderList} from "./emailThunk";



const defaultOutlookFilterIndex = 7;
const defaultFolderName = 'sent';

const findSentFolderIndex = (array) => {
    return array.findIndex(item => item.name.toLowerCase().includes("sent"));
}


const initialState = {
    modelId: 0,
    account: {
        current: {},
        list: []
    },
    content: {
        loading: false,
        syncCounter: 0,
        emails: {},
        selected: {
            // all: false
        },
        sort: {
            loading: false,
            current: {},
            filter: [],
        },
        pagination: {
            amount: 0,
            page: {
                id: 1,
                prev: 0,
                next: 0
            },
            tokens: {
                1: null
            }

        },
        maxRows: 20

    },
    loading: false
};

export const emailSlice = createSlice({
    name: "email",
    initialState,
    reducers: {
        reloadContent(state) {
            state.content.syncCounter = state.content.syncCounter + 1;
        },
        toggleAllRows(state, action) {

            const {isChecked} = action.payload;

            if (!isChecked) {
                state.content.selected = {};
                return;
            }

            let currentPageId = state.content.pagination.page.id;
            let currentSortId = state.content.sort.current.id;

            const allEmails = state.content.emails[currentSortId][currentPageId]

            const selectedIds = Object.fromEntries(Object.entries(allEmails).map(([key, value]) => [value.id, true]));
            //
            state.content.selected = selectedIds;
        },
        toggleRow(state, action) {
            const {dataId} = action.payload;
            const isSelected = !!state.content.selected[dataId];


            if (isSelected) {
                delete state.content.selected[dataId];
                return;
            }
            const updatedSelected = {
                ...state.content.selected,
                [dataId]: true
            };

            return {
                ...state,
                content: {
                    ...state.content,
                    selected: updatedSelected
                }
            };
        },
        cleanEmails(state) {
            state.content.selected = {};
            state.content.pagination.amount = 0;
            state.content.emails = {};
            state.content.pagination = initialState.content.pagination;
        },
        tableNavigation(state, action) {
            const {pageId} = action.payload;
            state.content.pagination.page.id = pageId;
        },
        setSearchFilter(state, action) {
            const {current} = action.payload;
            state.content.sort.current = current;
        },
        reloadSearchFilter(state, action) {
            let {folders} = action.payload;
            const defaultFolderIndex = findSentFolderIndex(folders);

            // TODO: quick fix for now
            let updatedFolders = JSON.parse(JSON.stringify(folders));
            if (defaultFolderIndex !== -1) {
                updatedFolders[defaultFolderIndex].sentFolder = true;
            }
            state.content.sort.filter = updatedFolders;
            state.content.sort.current = updatedFolders[defaultFolderIndex] ?? updatedFolders[0];
        },
        accountLogout(state) {
            const STORAGE_ID = GET_EMAIL_CACHED_DATA_NAME(state.modelId);
            removeToken(STORAGE_ID);
            state.account = initialState.account;
            state.content = initialState.content;
            state.modelId = 0;
        },
        setAccount(state, action) {
            const {modelId, account} = action.payload;
            state.modelId = modelId;
            state.account.current = account;
            state.content.type = account.type;

            // const amount = state.content.pagination.amount;
            //
            // if(amount === 0) {
            //     state.content.loading = true;
            // }

        },
        cleanAccount(state) {
            state.modelId = initialState.modelId;
            state.account = initialState.account;
            state.content = initialState.content;
            state.loading = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOutlookEmailsList.pending, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getOutlookEmailsList.fulfilled, (state, {payload}) => {
            let currentPageId = state.content.pagination?.page?.id || 1;
            let currentSortId = state.content.sort?.current?.id || '';
            const {emails} = payload;
            state.content.loading = false;
            state.content.emails[currentSortId] = {
                ...state.content.emails[currentSortId],
                [currentPageId]: emails
            };
            state.content.pagination.amount = emails.length;
        });

        builder.addCase(getGmailEmailList.pending, (state, {_}) => {
            state.content.loading = true;
        });
        builder.addCase(getGmailEmailList.fulfilled, (state, {payload}) => {
            let currentPageId = state.content.pagination.page.id;
            let currentSortId = state.content.sort.current.id;

            const {emails, nextPageToken} = payload;
            state.content.loading = false;

            state.content.emails[currentSortId] = {
                ...state.content.emails[currentSortId],
                [currentPageId]: emails
            };

            state.content.pagination.amount = emails.length;

            let nextPageId = currentPageId + 1;
            state.content.pagination.tokens[nextPageId] = nextPageToken;
        });

        builder.addCase(getOutlookFolderList.pending, (state, {_}) => {
            state.loading = true;
        });
        builder.addCase(getOutlookFolderList.fulfilled, (state, {payload}) => {
            let {items} = payload;
            state.loading = false;

            let updatedFolders = JSON.parse(JSON.stringify(items));
            const defaultFolderIndex = findSentFolderIndex(updatedFolders);
            if (defaultFolderIndex !== -1) {
                updatedFolders[defaultFolderIndex].sentFolder = true;
            }
            state.content.sort.filter = updatedFolders;
            state.content.sort.current = updatedFolders[defaultFolderIndex] ?? updatedFolders[0];
        });
    },
});

export const {
    setAccount,
    cleanAccount,
    accountLogout,
    setSearchFilter,
    reloadSearchFilter,
    tableNavigation,
    cleanEmails,
    toggleRow,
    toggleAllRows,
    reloadContent
} =
    emailSlice.actions;

export const getGmailEmails = getGmailEmailList;
export const getOutlookEmails = getOutlookEmailsList;

export default emailSlice.reducer;
