import { Box, IconButton } from '@mui/material';
import styles from "../invoices-table.module.css";
import classnames from 'classnames';
import formatTime from 'utils/formatTime';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';

const InvoiceSmallItem = ({ item }) => {
  const { id, status, amount, invoice_date } = item;

  const orderId = id;
  const formattedDate = formatTime({ date: invoice_date.date });
  const handleDelete = (id) => {

  }

  return (
    <Box key={`invoices-small-${orderId}`} className={classnames(styles.wrapper, styles.mobileWrapper)}>
      <Box className={classnames(styles.verticalLine, styles.bold)}>
        <div>Date</div>
        <div>Invoice ID</div>
        <div>Status</div>
        <div>Amount</div>
      </Box>
      <Box className={styles.verticalLine}>
        <div>{formattedDate}</div>
        <div>{id}</div>
        <div>{status}</div>
        <div>{amount}</div>
      </Box>
      {/* <Box className={styles.actionsWrapper}>
        <div className={styles.actions}>
          <IconButton variant="text"
            //  href={hosted_invoice_url} 
            target="_blank">
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton variant="text"
          // href={invoice_pdf}
          >

            <DownloadIcon />
          </IconButton>
        </div>
      </Box> */}
    </Box>
  );
};

export default InvoiceSmallItem;
