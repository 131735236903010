import {useState, useCallback, memo } from "react";
import { Select, MenuList, OutlinedInput, Input, FormControl, MenuItem, Typography, Box, Button, Menu }from '@mui/material';
import useModelSettingsUpdate from 'hooks/useModelSettingsUpdate';
import useIsSmallScreen from "hooks/useIsSmallScreen";
import { useSelector } from "react-redux";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { STANDART_GPT_MODEL, ENHANCED_GPT_MODEL } from 'constants';

const Version = ({ model, modelSetting, modelChangeHandler }) => {
  const { handleModelSettingsUpdate, isSettingsUpdateLoading } = useModelSettingsUpdate();
  const [anchorEl, setAnchorEl] = useState(null);
  // const handleModelChange = (event) => {
  //   const { name, value } = event.target;
  //   console.log(name, value)
  //   modelChangeHandler(name, value);
  //   handleModelSettingsUpdate(model, value, modelSetting.contextWindowSizePercent);
  // }
  const handleModelChange = (value) => {
    modelChangeHandler("gpt_model", value);
    handleModelSettingsUpdate(model, value, modelSetting.contextWindowSizePercent);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box p={4} sx={{ position: 'relative' }}>
      <Typography variant="div">Version of processing AI</Typography>
      <FormControl sx={{ width: '100%', marginTop: '10px' }}>
        <OutlinedInput
          fullWidth
          id="gpt_model-input"
          type="text"
          readOnly
          onClick={handleClick}
          variant="outlined"
          inputProps={{
            sx: { cursor: 'pointer' }
          }}
          value={
            modelSetting.gpt_model === STANDART_GPT_MODEL
              ? "Standard (2 credits / request)"
              : "Enhanced (10 credits / request)"
          }
          endAdornment={<ArrowDropDownIcon sx={{ color: "#5f6368", transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'none' }} />}
        />
        <Menu
          id="gpt_model-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: { width: anchorEl && anchorEl.offsetWidth }
         }}
        >
          <MenuItem
            onClick={() => handleModelChange(STANDART_GPT_MODEL)}
            sx={{ background: modelSetting.gpt_model === STANDART_GPT_MODEL ? 'rgba(57,118,239, .08)' : 'none' }}
          >
            Standard (2 credits / request)
          </MenuItem>
          <MenuItem
            onClick={() => handleModelChange(ENHANCED_GPT_MODEL)}
            sx={{ background: modelSetting.gpt_model === ENHANCED_GPT_MODEL ? 'rgba(57,118,239, .08)' : 'none' }}
          >
            Enhanced (10 credits / request)
          </MenuItem>
        </Menu>
        {/* <Box>
        <Select
          labelId="gpt_model-label"
          id="gpt_model"
          name="gpt_model"
          value={modelSetting.gpt_model ?? ''}
          onChange={handleModelChange}
        >
          <MenuItem value={STANDART_GPT_MODEL}>Standard <Typography marginLeft={1} variant="describe_text"> (2 credits / request)</Typography></MenuItem>
          <MenuItem value={ENHANCED_GPT_MODEL}>Enhanced <Typography  marginLeft={1} variant="describe_text"> (10 credits / request)</Typography></MenuItem>
        </Select>
        </Box> */}
      </FormControl>
    </Box>
  );
};

export default Version;
