import React from 'react'
import StatusMessageState from './components/StatusMessageState'
import UserTracking from './components/UserTracking'
import ParamsChecker from "./components/ParamsChecker";
// import Subscription from './components/Subscription'

const Events = () => {
    return (
        <>
            <StatusMessageState/>
            <UserTracking/>
            <ParamsChecker/>
            {/* <Subscription /> */}
        </>
    )
}

export default Events
