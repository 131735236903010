import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "../../monetization.module.css";
import { useNavigate } from "react-router-dom";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SmoothContent from "components/loadings/SmoothContent";
import InvoicesTable from "./components/InvoicesTable";
import { useSelector } from "react-redux";
import ManagePayment from "./components/ManagePayment";

const InvoicesManager = () => {

    const navigate = useNavigate();

    const handleRedirect = (path = "/") => {
        const defaultPath = "/billing";
        navigate(defaultPath + path);
    }

    return (
        <Grid container item xs={12} md={12} paddingTop="15px" spacing={4}>
            <Grid item xs={12} md={12}>
                <Typography variant="page_sub_title_bold">Payment Details</Typography>
            </Grid>
            <Grid item container xs={12} md={12} justifyContent="space-between">
                <Grid item xs={12} md={8} >
                    <Box className={styles.creditCard}>
                        {/* <CreditCardIcon color="icons" /> <SmoothContent loading={isLoading}><Typography size="small" variant="page_content"> {billingInfo.lastFourDigits || "No card Attached"}</Typography></SmoothContent> */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
                    <ManagePayment />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} >
                <Typography variant="page_sub_title_bold">Invoice History</Typography>
            </Grid>
            <Grid item xs={12} md={12} >

                <InvoicesTable />
            </Grid>
        </Grid>
    )
}

export default InvoicesManager
