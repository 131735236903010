import { memo } from 'react';
import { Box } from '@mui/material';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import styles from './subs-table.module.css';
import SubsHeader from './components/SubsHeader';
import { SubsItems, LoadingItems, NoSubs, LoadingSmallItems, SubsSmallItems  } from './components/SubsViews';

const SubsTable = () => {
  const { smallScreen } = useIsSmallScreen();
  const { list, loading } = useSelector((state) => state.monetization.subs);

  if (!list.length && !loading) {
    return <NoSubs />;
  }

  if(smallScreen) {
    return loading ? <LoadingSmallItems /> : <SubsSmallItems list={list} />
  }

  return (
    <Box className={classnames(styles.wrapper, smallScreen && styles.mobileWrapper)}>
      <SubsHeader />
      {loading ? <LoadingItems /> : <SubsItems list={list} />}
    </Box>
  );
};

export default memo(SubsTable);
