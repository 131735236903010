import React, {useState} from "react";
import styled from "@emotion/styled";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    '& .MuiToggleButton-root': {
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            // margin: '2px',
            // borderRadius: '10px'
        },
        '&.Mui-selected:hover': {
            backgroundColor: '#6090f0',
        },
        color: theme.palette.primary.main,
        // backgroundColor: '#fefefe',
        // fontWeight: 600,
        textTransform: 'Capitalize',
        // border: 'unset',
        fontSize: '12px',
        borderColor: '#82a8f5',
        // padding: '5px 10px',
        // fontFamily: 'Inter'
    },
    height: '32px',
    opacity: 0.8,

    backgroundColor: '#f6f8f8',

    // padding: '2px',
    borderRadius: '10px',
    // backgroundColor: '#f4f4f5'

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SmallScreenToggleBtn = ({previewComp, settingsComp, position, isInView = true, hidePreview}) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <>
          {isInView && !hidePreview && (
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                sx={{ position: 'fixed', bottom: `${position * 50}px`, width: '90%', left: '5%', background: '#fff' }}
              >
                Preview
              </Button>
          )}
            <Grid item xs={12} sm={12}>
              {settingsComp}
            </Grid>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10 }}
              >
                <CloseIcon />
              </IconButton>
              <Box sx={{ paddingTop: '10px', textAlign: 'left' }}>
                {previewComp}
              </Box>
            </Dialog>
        </>
    )
}

export default SmallScreenToggleBtn
