import { Box, IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import styles from '../invoices-table.module.css';
import StripeStatus from 'components/showcase/StripeStatus';
import SmoothContent from 'components/loadings/SmoothContent';



const InvoiceLoading = () => {

    return (
        <Box className={styles.line}>

            <div className={styles.loadingCenter}>
                <SmoothContent width={100}/>
            </div>

            <div className={styles.loadingCenter}>
                <SmoothContent width={100}/></div>

            <div className={styles.loadingCenter}>
                <SmoothContent width={58} height={26} />
            </div>
            <div className={styles.loadingCenter}>
                <SmoothContent width={40} />
            </div>

            <div className={styles.actions}>
                <div className={styles.loadingIcons}>
                    <SmoothContent width={22} height={22} variant="circular" />
                </div>
                <div className={styles.loadingIcons}>
                    <SmoothContent width={22} height={22} variant="circular" />
                </div>
            </div>
        </Box>
    );
};


export default InvoiceLoading;