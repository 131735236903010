import Link
    from "../../../pages/protected/tools/Training/components/WebsiteTraining/components/ViewWebsite/components/LinksTable/components/LinksList/components/Link";
import TaskItem from "./components/TaskItem";
import {memo, useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import {refreshList} from "../../../redux/links";


const REFRESH_LIST_INTERVAL_MS = 30000;
const PendingList = ({ list = []}) => {

    const dispatch = useDispatch();

    const pendingNum = useMemo(() => {
        return list.filter(item => item.status === 'pending').length;
    },[list])

    const updateTaskList = async () => {
        dispatch(refreshList());
    };

    useEffect(() => {

        let intervalId = null;
        if(pendingNum !== 0) {
            intervalId = setInterval(updateTaskList, REFRESH_LIST_INTERVAL_MS);
        }

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [pendingNum]);

    return list.map((item) => <TaskItem key={item.id} item={item} />)
}

export default memo(PendingList);