import UpgradePlanForm from "pages/protected/billing/UpgradePlan";
import {Box, styled} from "@mui/material";
import React from 'react';
import {Modal, Backdrop, Fade, Button} from '@mui/material';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";


const UpgradePlanWrapper = ({children}) => {


    return (
        <>
            {/*<UpgradePlanForm/>*/}
            <UpgradeOverlay>
                <UpgradePlanForm noHeader={true}/>
            </UpgradeOverlay>
            {children}
        </>


    )
}


const overlayStyle = styled(Box)(({theme}) => ({
    modal: {
        display: 'block',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const UpgradeOverlay = ({children}) => {

    return (

        <Modal
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1300
            }}
            open={true}
            closeAfterTransition

            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <Fade in={true}>
                <Grid container id="testing-321" sx={{
                    backgroundColor: '#fff',
                    maxWidth: {lg: '80%', md: '80%', sm: '90%', xs: '95%'},
                    maxHeight: '90%',
                    minHeight: '90%',
                    borderRadius: '15px',
                    overflowY: 'scroll',
                    alignContent: 'flex-start'
                    // boxShadow: theme.shadows[5],
                    // padding: theme.spacing(2, 4, 3),
                }}
                >
                    <Grid item xs={12} sm={12} md={12}><Typography sx={{fontSize: '1.5rem', paddingTop: '50px'}} component="p" textAlign="center" variant="page_sub_title_bold">You have
                        reached your monthly
                        credit limit</Typography></Grid>
                    <Grid item xs={12} sm={12} md={12} mb={4}>
                      <Typography sx={{paddingTop: '15px'}} component="p" textAlign="center" variant="describe_text">
                        Upgrade your plan to get more credits
                      </Typography></Grid>
                    <Grid item xs={12} sm={12} md={12}>{children}</Grid>
                </Grid>
            </Fade>
        </Modal>

    );
};


export default UpgradePlanWrapper;
