import { Box, IconButton, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import classnames from 'classnames';
import formatTime from 'utils/formatTime';
import { unsubscribe } from 'redux/monetization';
import styles from "../subs-table.module.css";

const SubItemSmall = ({ item }) => {
  const dispatch = useDispatch();
  const { unsubscribeLoading } = useSelector((state) => state.monetization.subs);
  const orderId = item.id;
  let rawDate = item.start_at?.date || {}; 
  const dateJoined = formatTime({ date: rawDate });
  const fullName = item.user?.name || "Testing";

  return (
    <Box key={`subs-small-${orderId}`} className={classnames(styles.wrapper, styles.mobileWrapper)}>
      <Box className={classnames(styles.verticalLine, styles.bold)}>
        <div>Joined Date</div>
        <div>Customer Name</div>
        <div>Model Name</div>
      </Box>
      <Box className={styles.verticalLine} sx={{ flexGrow: 1 }}>
        <div>{dateJoined}</div>
        <div>{fullName}</div>
        <div>{item.model.name}</div>
      </Box>
      <Box className={styles.actionsWrapper}>
        <div className={styles.actions}>
          {unsubscribeLoading ? <CircularProgress size={20} /> : (
            <IconButton onClick={()=> {
              dispatch(unsubscribe({id: orderId}));
            }}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default SubItemSmall;
