import {Box, Avatar, Grid, Button} from "@mui/material";
import styles from "../TopBar/topbar.module.css";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListIcon from '@mui/icons-material/List';
import {IconButton} from '@mui/material';
import LogoSvg from "components/logo/LogoSvg";
import SharedModels from 'components/menus/SharedModels';
import UserMenuButton from "pages/ShareModel/components/UserMenuButton";
import AccountMenu from "../TopBar/components/AccountMenu";
import classnames from "classnames";
import {useSelector} from "react-redux";

function TopBarSharing({ widesidebar, mobilebar, mobilebarChange, widesidebarChange, smallScreen, userInfo, onSelect }) {
    const auth = useSelector((state) => state.auth);
    const widesidebarHandler = () => {
      if (widesidebar) {
        localStorage.setItem('smallMenu', 1);
      } else {
        localStorage.removeItem('smallMenu');
      }
      widesidebarChange(!widesidebar);
    }

    const mobilebarHandler = () => {
        mobilebarChange(!mobilebar);
    }

    return (
      <Box className={styles["topbar"]}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {
        smallScreen ?
            <>
              <Box sx={{
                  width: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
              }}>
                <ListIcon color={'icons'} sx={{marginLeft: '6px', width: 28, height: 28}}  onClick={mobilebarHandler} />
              </Box>
              <Box sx={{
                width: 'calc(100% - 60px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}>
                  {/*<LogoSvgIcon/>*/}
              </Box>
            </> : <>
              <IconButton aria-label="sideMenu" onClick={widesidebarHandler}>
                {widesidebar ? <MenuOpenIcon color={'icons'}/> : <ListIcon color={'icons'}/>}
              </IconButton>
              <Box sx={{display: 'flex', alignItems: 'center'}}><LogoSvg hasLogo={false}/></Box>
            </>
          }
        </Box>
        {smallScreen && (
          <>
            <Box>
              <SharedModels widesidebar={widesidebar} onSelect={onSelect} defaultOppened={false} hangeUrl={true} changeUrl={true} />
            </Box>
            <Box className={styles["flex-space-around"]}>
              {/* <AccountMenu/> */}
              {auth.user ? (
                <Box display='flex' alignItems='center'>
                  <Avatar  sx={{width:'30px',height:'30px',border:'1px solid #00000011'}} src={userInfo.avatar ?? ''}>{userInfo.initial}</Avatar>
                  <UserMenuButton userInfo={userInfo}/>
                </Box>
              ) : (
                <Button href="/portal/login" variant="contained" size="small" sx={{  }}>Sign&nbsp;in</Button>
              )}
            </Box>
          </>
        )}
      </Box>
    );
}

export default TopBarSharing;
