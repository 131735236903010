export const getToken = (name, type = '') => {
 try {

  let data = localStorage.getItem(name);

  if(type == 'json') {
   return JSON.parse(data);
  }

  return data || '';
 } catch {
  return '';
 }
}
export const removeToken = (name) => {
 try {
  localStorage.removeItem(name);
 } catch {
 }
}
export const setToken = (name,val) => {
 try {
  if (typeof val == 'object') {
   val = JSON.stringify(val);
  }
  localStorage.setItem(name, val);
 } catch {
 }
}
