import React from 'react';

const map = new Map();

function setRef(key) {
  if (!key) return console.warn("useDynamicRefs: Cannot set ref without key");
  const ref = React.createRef();
  map.set(key, ref);
  return ref;
}

function getRef(key) {
  if (!key) return console.warn("useDynamicRefs: Cannot get ref without key");
  return map.get(key);
}

function useDynamicRefs() {
  return [getRef, setRef];
}

export default useDynamicRefs;
// This code should work in a JavaScript environment, but please note that it includes TypeScript type annotations like React.RefObject<T>. If you're not using TypeScript, you can remove those type annotations.
