import { Grid, Typography } from "@mui/material";
import PaymentOptions from "../PaymentOptions";

const AllPayments = () => {

    return (

        <Grid container spacing={6}>
            <Grid item xs={12} md={12}>
                <Typography
                    sx={{ fontSize: 24 }}>All Payments</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
                <PaymentOptions action="all"/>
            </Grid>
        </Grid>

    )

}


export default AllPayments;