import {
  Grid,
  TextField,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StopIcon from '@mui/icons-material/Stop';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import VisibilityIcon from '@mui/icons-material/Visibility';

const PublicLink = ({ publicLink, copyPublicLink, hideFromPublic, hideStopCta = false, onChangeHandler, readOnly = true }) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <TextField
        variant="filled"
        // sx={{ opacity: 0.6 }}
        fullWidth
        label="Public url"
        onChange={onChangeHandler}
        value={publicLink}
        InputProps={{
          readOnly,
          style: { paddingRight: "5px" },
          endAdornment: (
            <>
            <Tooltip title="Copy link">
              <Checkbox
                size={"small"}
                onClick={copyPublicLink}
                checkedIcon={<DoneAllIcon />}
                icon={<ContentCopyIcon />}
              />
            </Tooltip>
            <Tooltip title="Preview">
              <IconButton
                size={"small"}
                onClick={(e) => {
                  e.preventDefault();
                  // redirect to the share model
                  window.location.href = publicLink;
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            {!hideStopCta && (
              <Tooltip title="Stop sharing">
                <IconButton
                  size={"small"}
                  onClick={hideFromPublic}
                >
                  <StopIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
          ),
        }}
      />
    </Grid>
  )
}

export default PublicLink;
