import { Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import api from "_services/api";
import InteractWithUser from "../InteractWithUser";

const VerificationLetter = ({ email = "" }) => {
  let { user } = useSelector((state) => {
    return state.auth;
  });

  const [status, setStatus] = useState({
    isOpen: false,
    msg: "",
    type: "error",
  });

  const handleResend = async () => {

    await api
      .get("users/resend/verification")
      .then((r) => {
        if (r.code >= 400) {
          setStatus({
            isOpen: true,
            msg: "Could not re-send verification letter. Please, contact out technical team.",
            type: "error",
          });
          return;
        }

        setStatus({
          isOpen: true,
          msg: "Verification letter has been sent. ",
          type: "success",
        });
      })
      .catch(() => {
        setStatus({
          isOpen: true,
          msg: "Could not re-send verification letter. Please, contact out technical team.",
          type: "error",
        });
      });
  };

  return (
    <Paper
      // className={styles["paper-container"]}
      // variant="outlined"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: '6px'
      }}
    >
      <Typography component="h1" variant="page_title" align="center">
        Thank you for registering!
      </Typography>

      <Typography component="p" variant="page_content" fontSize={15} textAlign={"center"} fontWeight={500} gutterBottom>
        A verification link has been sent to your email address "<i style={{"color": "#3976EF"}}>{email || user.email}</i>".<br/>
        Please click on the link to verify your account.<br/><br/>
        If you do not see the email in your inbox, be sure to check your spam or junk folders.<br/><br/>
        You're one step closer to empowering yourself with personalized Al.
        {/* {user ? (
            <Typography
              onClick={handleResend}
              color="primary"
              component="a"
              variant="link"
            >
              click here to re-send it
            </Typography>
          ) : (
            " junk folders"
          )} */}

      </Typography>

      {status.isOpen && (
        <InteractWithUser
          msg={status.msg}
          display={status.isOpen}
          type={status.type}
        />
      )}
    </Paper>
  );
};

export default VerificationLetter;
