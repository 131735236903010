import {Divider, Grid, Box} from "@mui/material";
import React, {memo, useCallback, useRef, useState, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import Preview from "./components/Preview";
import Settings from "./components/Settings";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import SmallScreenToggleBtn from "../../SmallScreenToggleBtn";
import useScriptCode from "./helpers/useScriptCode";
import ActionBtns from "../../ActionBtns";

const ChatBot = () => {
    const context = useOutletContext();
    const [previewData, setPreviewData] = useState({});
    const code = useScriptCode({previewData});
    const viewRef = useRef(null,)
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setIsInView(entry.isIntersecting);
        }, {
            root: null,
            threshold: 1.0
        });

        if (viewRef.current) {
            observer.observe(viewRef.current);
        }

        return () => {
            if (viewRef.current) {
                observer.unobserve(viewRef.current);
            }
        };
    }, []);

    const handleChanges = useCallback((data) => {
        setPreviewData(prev => ({...prev, ...data}));
    }, [setPreviewData]);



    if (context.smallScreen) {

        return (
          <>
            <Grid container py={4} spacing={4} sx={{ paddingBottom: '100px' }}>
                <Grid item xs={12} sm={12} textAlign="center">
                    <SmallScreenToggleBtn
                      position={2}
                      isInView={isInView}
                      hidePreview={!context.isPublic}
                      settingsComp={<Settings
                        code={code}
                        isInView={isInView}
                        previewData={previewData}
                        handleChanges={handleChanges}
                        isPublic={context.isPublic}
                        smallScreen={context.smallScreen}
                        innerRef={viewRef}/>}
                      previewComp={<Preview code={code} previewData={previewData} isPublic={context.isPublic} />}
                    />       
                    {isInView && (
                      <ActionBtns
                        isPublic={context.isPublic}
                        hideFromPublic={context.hideFromPublic}
                        savePublic={context.savePublic}
                        loading={context.loading}
                        websiteStatus={context.websiteStatus}
                        shareType={context.shareType}
                        handleSendInvitation={context.handleSendInvitation}
                      />
                    )}
                    <Box ref={viewRef} />           
                </Grid>
            </Grid>
          </>
        )
    }

    return (
        <Grid container py={4} spacing={4}>
            <Grid item xs={12} sm={12} md={context.isPublic ? 3.8 : 12} lg={context.isPublic ? 4.9 : 12}>
                <Settings code={code} isInView={true} previewData={previewData} handleChanges={handleChanges} isPublic={context.isPublic}/>
                <ActionBtns
                  isPublic={context.isPublic}
                  hideFromPublic={context.hideFromPublic}
                  savePublic={context.savePublic}
                  loading={context.loading}
                  websiteStatus={context.websiteStatus}
                  shareType={context.shareType}
                  handleSendInvitation={context.handleSendInvitation}
                />
            </Grid>
            {context.isPublic && (
              <Grid item auto>
                <Divider orientation="vertical"/>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={7.8} lg={6.9}>
                <Preview code={code} previewData={previewData} isPublic={context.isPublic}/>
            </Grid>
        </Grid>
    )
}

export default memo(ChatBot);