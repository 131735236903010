import {memo} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";

export const PositionSettings = memo(({
                                   handleChanges = () => {
                                   }
                               }) => {

    const onRightChange = (e) => {
        const value = e.target.value;
        handleChanges({right: value});
    }

    const onBottomChange = (e) => {
        const value = e.target.value;
        handleChanges({bottom: value});
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <TextField
                    variant="filled"
                    placeholder={'Change your bubble right position'}
                    sx={{opacity: 0.6}}
                    defaultValue="24"
                    fullWidth
                    label="Bubble Right Position"
                    onChange={onRightChange}
                    InputProps={{
                        style: {paddingRight: "5px"},
                        type: 'number'
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    variant="filled"
                    placeholder={'Change your bubble bottom position'}
                    sx={{opacity: 0.6}}
                    defaultValue="20"
                    fullWidth
                    label="Bubble Bottom Position"
                    onChange={onBottomChange}
                    InputProps={{
                        style: {paddingRight: "5px"},
                        type: 'number'
                    }}
                />
            </Grid>
        </Grid>
    )
})

