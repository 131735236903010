import { useState, useEffect, useMemo } from "react";
import { Typography, Box, Button, Grid, Accordion, AccordionSummary, AccordionDetails, Skeleton } from "@mui/material";
import Carousel from 'react-material-ui-carousel'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import classnames from 'classnames';
import { presetData, customModelData } from "../../Model/components/CreateModel/presetData";
import { styled } from '@mui/material/styles';
import Typewriter from '../components/Typewriter';
import useGetModelInstructions from 'hooks/useGetModelInstructions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import styles from "../onboarding.module.css";
import {sendUIEvent} from "../../../../events/analytics/google";

const accordionStyles = `
  &.MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
  .MuiAccordionSummary-content:before {
    display: none;
  }
  .MuiAccordionSummary-root {
    min-height: auto !important;
    padding: 0;
  }
  .MuiAccordionDetails-root {
    padding: 5px 0 0;
  }
`;
const CustomAccordion = styled(Accordion)`${accordionStyles}`;

const CustomShortAccordion = styled(Accordion)`
  ${accordionStyles}
  .MuiAccordionSummary-content {
    margin: 0 !important;
    flex-grow: 0;
  }
  .MuiButtonBase-root {
    flex-direction: column;
  }
`;

const Models = ({
    modelInstructions,
    isLoading,
    answers,
    setIsLoading,
    stepBag,
    isGenerating,
    finishedSteps,
    setSelectedModel,
    setRenderedModels,
    setRenderedGeneratedModels, 
    renderedGeneratedModels,
    setStage,
    models,
    setModels,
    getModels,
    selectedFlow,
   }) => {
  const customSort = (data) => {
    data.sort((a, b) => {
      const nameA = a.name?.toLowerCase();
      const nameB = b.name?.toLowerCase();
  
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  
    return [...data, customModelData];
  };
  const titleSelectTexts = {
    assistant: 'AI Assistant', 'chat-bot': 'AI Website Chatbot', monetization: 'Monetize AI', 'customer-support': 'Speak to Product AI'
  }

  const sortedPresetData = useMemo(() => customSort(presetData), []);
  const generateAccordionState = (models) => {
    const sentences = models.reduce((result, {}, i) => {
      result[i] = false;

      return result;
    }, {});

    return sentences;
  }
  const [isGeneratedExpanded, setIsGeneratedExpanded] = useState({});
  const [isExpanded, setIsExpanded] = useState(generateAccordionState(sortedPresetData));
  const [showOtherModels, setShowOtherModels] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [animationSpeed, setAnimationSpeed] = useState(10000);
  const [showSlider, setShowSlider] = useState(false);
  const isStepFinished = Number(stepBag.step) < finishedSteps;
  const [showGeneratedModels, setShowGeneratedModels] = useState(isStepFinished);
  const statuses = [
    "Analyzing Q&A inputs...",
    "Decoding questions...",
    "Cataloging inquiries...",
    "Generating ideas...",
    "Reviewing top ideas...",
    "Selecting key ideas...",
    "Refining goals...",
    "Aligning solutions...",
    "Enhancing clarity...",
    "Finalizing insights..."
  ];
  const phrases = [
    {
      title: 'Personalize your AI',
      text: 'Train the model with data that is unique to you to receive more personalized responses.'
    },
    {
      title: 'Leverage Q&A',
      text: 'Use question and answer (Q&A) pairs to train your AI for greater accuracy and efficiency.'
    },
    {
      title: 'Explore playground',
      text: 'The Playground is where you interact with your AI, provide feedback, and refine how it learns in real-time.'
    },
    {
      title: 'Speak to file',
      text: 'Use “/files” to initiate the \'Speak to File\' feature which restricts responses to data from a specific file.'
    },
    {
      title: 'Set model rules',
      text: 'Set rules and limitations to guide your model\'s behavior and output as well ensuring it operates within a controlled and intended framework.'
    },
    {
      title: 'Data visibility',
      text: 'Adjust the Data Visibility setting (0 to 100) to fine-tune the AI\'s responses from brief (0) to  highly detailed (100). The default setting is 80.'
    },
    {
      title: 'Version selection',
      text: 'Adjust the AI version setting (Standard or Enhanced) to optimize performance, compatibility, efficiency, and feature access for improved responses.'
    }
  ];

  useEffect(() => {
    if (showGeneratedModels) {
      window.gtag('event', 'Onboarding_click_show_other_models', {
        'event_category': 'Onboarding',
        'event_label': 'Onboarding',
        'value': 'click show other models',
        'step': stepBag.step,
        'width': window.innerWidth,
      });
    }
  }, [showGeneratedModels]);
  useEffect(() => {
    if (modelInstructions) {
      setModels(modelInstructions);
    }
  }, [modelInstructions, setModels]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStatus(prevIndex => {
        if (prevIndex < statuses.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    }, animationSpeed);

    return () => {
      clearInterval(intervalId);
    };
  }, [animationSpeed]);

  const handleSlideChange = (index) => {
    setCurrentSlideIndex(index);
  };

  const handleAccordionClick = (index, isGeneratedModel = false) => {
    if (isGeneratedModel) {
      setIsGeneratedExpanded((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    } else {
      setIsExpanded((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };

  const renderModels = async (data, generatedModels = false) => {
    for (let i = 0; i < data.length; i++) {
      if (renderedGeneratedModels) {
        setRenderedGeneratedModels((prevRenderedModels) => [
          ...prevRenderedModels,
          data[i],
        ]);
      } 
      // else {
      //   setRenderedModels((prevRenderedModels) => [
      //     ...prevRenderedModels,
      //     data[i],
      //   ]);
      // }

      handleAccordionClick(i, generatedModels);

      setTimeout(() => {
        handleAccordionClick(i, generatedModels);
      }, 2500);

      if (i < data.length - 1 && !isStepFinished) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    }
  };

  useEffect(() => {
    if (!models) {
      setAnimationSpeed(10000);
      setTimeout(() => {
        setShowSlider(true);
      }, 10000);
    } else {
      setCurrentStatus(9)
    }
  }, [models]);

  useEffect(() => {
    if (isStepFinished) return;

    if (models) {
      renderModels(models, true);
      setTimeout(() => {
        setShowGeneratedModels(true);
      }, 1000);
    }
  }, [models, isStepFinished]);

  // useEffect(() => {
  //   if (models?.length) return;

  //   if (isGettingModelInstructions) {
  //     setStage(3);
  //     setIsLoading(true);
  //   } else {
  //     setStage(1);
  //     setIsLoading(false);
  //     setModels(data);
  //   }
  //   if (!isGettingModelInstructions && data?.length) {
  //     setTimeout(() => {
  //       setStage(1);
  //       setIsLoading(false);
  //     }, 3000 * data.length + 1000);
  //   }
    
  // }, [isGettingModelInstructions, data]);

  const selectedFlowText = selectedFlow === 'monetization' ? 'Monetization AI Model' : titleSelectTexts[selectedFlow];

  return (
    <>
      <Grid item sx={{ width: '100%', minHeight: '100vh' }}>
        <Typography
          variant="h2"
          sx={{
            marginBottom: '40px',
            textAlign: 'center',
            width: '100%',
            fontSize: {xs: '22px', md: '32px'},
            fontWeight: 300,
            marginTop: '16px',
            padding: '0 10px',
          }}
        >
          {!isLoading && showGeneratedModels && 'Select a model'}
          {!isLoading && !showGeneratedModels && `Oops! Encountered an issue while building your ${selectedFlowText}.`}
          {isLoading && !isStepFinished && <Typewriter text={`Hang tight! I'm building your personalized ${selectedFlowText}...`} animationSpeed={10} animationDelay={1500} />}
          {isLoading && isStepFinished && `Hang tight! I'm building your personalized ${selectedFlowText}...`}
        </Typography>
        <Box className={classnames(!isStepFinished && styles.button)} sx={{ maxWidth: '340px', width: '100%', margin: 'auto', position: 'relative', paddingBottom: '45px', animationDelay: '2500ms' }}>
          {!showGeneratedModels && (
            <>
              <Typography
                variant="div"
                sx={{
                  marginBottom: '40px',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '12px',
                  fontWeight: 300,
                  marginBottom: '16px',
                }}
              >
                {statuses[currentStatus]}
              </Typography>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
              <Typography
                variant="div"
                sx={{
                  display: 'block',
                  textAlign: 'right',
                  fontSize: '11px',
                  fontWeight: 300,
                }}
              >
                Please, do not close this window
              </Typography>
            </>
          )}
          {showGeneratedModels && Boolean(!renderedGeneratedModels?.length) && (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Button
                onClick={() => {
                  window.gtag('event', 'Onboarding_click_try_again_(refetch)', {
                    'event_category': 'Onboarding',
                    'event_label': 'Onboarding',
                    'value': 'click try again (refetch)',
                    'step': stepBag.step,
                    'width': window.innerWidth,
                  });
                  setShowGeneratedModels(false);
                  getModels();
                }}
                variant="contained"
                size="large"
                sx={{ marginBottom: '10px' }}
                disabled={isLoading}
              >Try again</Button>
            </Box>
          )}
          {showGeneratedModels && Boolean(renderedGeneratedModels?.length) && renderedGeneratedModels.map((model, i) => (
            <CustomAccordion
              key={`model-new-${i}`}
              expanded={isGeneratedExpanded[i]}
              sx={{
                marginBottom: '10px',
                border: '1px solid rgba(216,211,250, .1)',
                borderRadius: '12px !important',
                padding: '4px 10px 4px 14px',
                width: '100%',
                marginBottom: '10px',
                justifyContent: 'left',
                color: '#000',
                fontSize: '14px',
                textTransform: 'none',
                boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
                minHeight: '34px'
              }}
              className={classnames(!isStepFinished && styles.button)}
            >
              <AccordionSummary
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon />}
                onClick={() => handleAccordionClick(i, true)}
              >
                {isStepFinished ? model.name : <Typewriter text={model.name} animationSpeed={30} />}
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="div"
                  sx={{
                    marginBottom: '5px',
                    width: '100%',
                    fontSize: '14px',
                    color: 'rgba(0,0,0, .6)'
                  }}
                >
                  {isStepFinished ? model.description : <Typewriter animationDelay={1000} text={model.description} animationSpeed={10} />}
                </Typography>
                {!isLoading && (
                  <Box sx={{ textAlign: 'right', margin: '20px 0 10px' }}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        window.gtag('event', 'Onboarding_select_a_generated_model', {
                          'event_category': 'Onboarding',
                          'event_label': 'Onboarding',
                          'value': 'select a generated model',
                          'step': stepBag.step,
                          'width': window.innerWidth,
                        });
                        setSelectedModel(model);
                        stepBag.setNextStep();
                      }}>Next</Button>
                  </Box>
                )}
              </AccordionDetails>
            </CustomAccordion>
          ))}
          {showGeneratedModels && (
            <CustomShortAccordion
              sx={{
                marginBottom: '10px',
                border: 'none',
                borderRadius: '12px !important',
                padding: '4px',
                marginBottom: '10px',
                marginTop: '0 !important',
                justifyContent: 'left',
                color: '#3976ef',
                fontSize: '14px',
                textTransform: 'none',
                boxShadow: 'none',
                animationDelay: `${renderedGeneratedModels.length * 3000}ms`,
                minHeight: '34px',
                width: 'auto',
              }}
              className={classnames(!isStepFinished && styles.button)}
            >
              <AccordionSummary
                aria-controls="panel-content"
                expandIcon={<ExpandMoreIcon sx={{ color: '#3976ef' }} color="#3976ef" />}
                onClick={() => setShowOtherModels(!showOtherModels)}
              >
                {isStepFinished ? 'Other models' : <Typewriter text='Other models' animationSpeed={30} />}
              </AccordionSummary>
            </CustomShortAccordion>
          )}
        </Box>
        {showOtherModels && (
          <Box sx={{ maxWidth: '340px', width: '100%', margin: 'auto', position: 'relative', paddingBottom: '38px' }}>
            {sortedPresetData.map((model, i) => {
              const name = Number(model.id) === 17 ? `+ ${model.name}` : model.name;
              return (
                <CustomAccordion
                  key={`model-preset-${model.id}`}
                  sx={{
                    marginBottom: '10px',
                    border: '1px solid rgba(216,211,250, .1)',
                    borderRadius: '12px !important',
                    padding: '4px 10px 4px 14px',
                    width: '100%',
                    marginBottom: '10px',
                    justifyContent: 'left',
                    color: Number(model.id) === 17 ? '#027bff' : '#000',
                    fontSize: '14px',
                    textTransform: 'none',
                    boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
                    animationDelay: `${(i) * 300}ms`,
                    minHeight: '34px'
                  }}
                  className={styles.button}
                >
                  <AccordionSummary
                    aria-controls="panel-content"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typewriter text={name} animationSpeed={10} animationDelay={i * 300} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="div"
                      sx={{
                        marginBottom: '5px',
                        width: '100%',
                        fontSize: '14px',
                        color: 'rgba(0,0,0, .6)'
                      }}
                    >
                      {isStepFinished ? model.description : <Typewriter animationDelay={i * 300} text={model.description} animationSpeed={20} />}
                    </Typography>
                    {!isLoading && (
                      <Box sx={{ textAlign: 'right', margin: '20px 0 10px' }}>
                        <Button
                          fullWidth
                          variant="contained"
                          
                          onClick={() => {
                            window.gtag('event', 'Onboarding_select_a_standart_model', {
                              'event_category': 'Onboarding',
                              'event_label': 'Onboarding',
                              'value': 'select a standart model',
                              'step': stepBag.step,
                              'width': window.innerWidth,
                            });
                            setSelectedModel(model);
                            stepBag.setNextStep();
                          }}>Next</Button>
                      </Box>
                    )}
                  </AccordionDetails>
                </CustomAccordion>
              )
            })}
          </Box>
        )}
        {Boolean(!renderedGeneratedModels?.length) && showSlider && (
          <Box sx={{ width: '100%', position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0, left: 0, borderTop: '1px solid rgba(216,211,250, .5)'  }} />
            <Typography
              variant="div"
              sx={{
                position: 'absolute',
                background: '#fff',
                fontSize: '14px',
                top: '-19px',
                left: '50%',
                fontWeight: 600,
                padding: '0 10px',
                color: '#757575',
                transform: 'translateX(-50%)'
              }}
            >
              Hints&nbsp; <HelpOutlineOutlinedIcon sx={{ position: 'relative', top: '8px' }} /> &nbsp;Tips
            </Typography>
            <Box sx={{ maxWidth: '640px', width: '100%', margin: 'auto', position: 'relative', padding: '38px 20px' }} className={styles.button}>
              <Carousel
                PrevIcon={<ArrowBackIosIcon sx={{ color: '#757575', position: 'relative', left: '-3px' }} onClick={() => {
                  window.gtag('event', 'Onboarding_slider_click_prev', {
                    'event_category': 'Onboarding',
                    'event_label': 'Onboarding',
                    'value': 'slider click prev',
                    'step': stepBag.step,
                    'width': window.innerWidth,
                  });
                }} />}
                NextIcon={<ArrowForwardIosIcon sx={{ color: '#757575', position: 'relative', right: '-10px' }}  onClick={() => {
                  window.gtag('event', 'Onboarding_slider_click_next', {
                    'event_category': 'Onboarding',
                    'event_label': 'Onboarding',
                    'value': 'slider click next',
                    'step': stepBag.step,
                    'width': window.innerWidth,
                  });
                }} />}
                navButtonsAlwaysVisible={true}
                onChange={handleSlideChange}
                indicators={false}
                animation='slide'
                interval={6000}
                sx={{
                  button: {
                    backgroundColor: '#fff !important',
                    borderRadius: 0,
                    color: '#333',
                    width: '10px',
                    margin: 0,
                    width: '30px',
                    '&:hover': {
                      background: 'none',
                    }
                  },
                }}
              >
                {phrases.map((item, i) => (
                  <Box key={`carousel-slide-${i}`} sx={{ display: 'flex', height: '140px', fontSize: '16px', padding: '0 30px', textAlign: 'center' }}>
                    <Box style={{ margin: 'auto' }}>
                      {currentSlideIndex === i && (
                        <>
                          <div style={{ fontWeight: 600 }}>{item.title}</div>
                          {item.text}
                        </>
                      )}
                    </Box>
                  </Box>
                ))}
              </Carousel>
            </Box>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Models;
