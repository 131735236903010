import {Box, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import FileIcons from "components/icons/fileIcons";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {approveOrDisapproveById} from "redux/links/linksThunk";
import {updateCells} from "redux/tools";

const ApproveLinksToggleBtn = ({siteId = 0,pageId = 0}) => {
    // const isLoading = false;

    // const [isApproved, setApproved] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const trainedId = useSelector(state=> state.links.content.sort.maps.trainedIds[pageId]);
    const isApproved = Boolean(trainedId);
    const dispatch = useDispatch();

    const handleChange = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);


        //
        // if approved before than do the opposite

        await dispatch(approveOrDisapproveById({isApproved: !isApproved, pageIds: [pageId], siteId: siteId, pageOnly: true }));

        dispatch(updateCells({increase: !isApproved}));
        setLoading(false);
        // setApproved(prev => !prev);
    };

    return (
        <Tooltip title="Use this button to train your model" placement="top">
            <Box>
                {isLoading ? (<CircularProgress size={20}/>) : (<Checkbox
                    size="small"
                    sx={{padding: 0}}
                    checked={isApproved}
                    color="success"
                    onClick={handleChange}
                    checkedIcon={// <Avatar
                        //   sx={{ width: sizes, height: sizes }}
                        //   src={filledLogoSrc}
                        //   alt="logo"
                        // />
                        // <ThumbUp />
                        <Box sx={{width: {xs: 20}, height: {xs: 20}, display: 'flex', alignItems: 'center'}}>
                            <FileIcons icon='brianSuccess'/>
                        </Box>}
                    icon={// <Avatar
                        //   sx={{ width: sizes, height: sizes }}
                        //   src={transparentLogoSrc}
                        //   alt="logo"
                        // />
                        <Box
                            sx={{width: {xs: 20}, height: {xs: 20}, display: 'flex', alignItems: 'center'}}
                            display='flex'>
                            <FileIcons icon='brainAdd'/>
                        </Box>}
                />)}
            </Box>
        </Tooltip>
    )
}

export default ApproveLinksToggleBtn;