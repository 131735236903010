const getLinksRequestParamsAction = {
    trained: (content) => ({
        pageId: content.list[content.sort.current.id].pagination.page.id,
        // folderId: content.sort.current.id,
        limits: content.maxRows
    }),
    archive: (content) => ({
        page: content.list[content.sort.current.id].pagination.page.id,
        limit: content.maxRows
        // pageToken: content.pagination.tokens[content.pagination.page.id],
        // maxResults: content.maxRows
    }),
    new: (content) => ({
        // page: content.pagination.page.id,
        // limit:  content.pagination.maxRows
        // pageToken: content.pagination.tokens[content.pagination.page.id],
        // maxResults: content.maxRows
    }),
    pending: (content) => ({
        // page: content.pagination.page.id,
        // limit:  content.pagination.maxRows
        // pageToken: content.pagination.tokens[content.pagination.page.id],
        // maxResults: content.maxRows
    }),
    nested: (content) => ({
        //
        id: content.sort.current.id.split('_')[1],
        page: content.list[content.sort.current.id]?.pagination.page.id || 1,
        limit: content.maxRows
    })
};


export default getLinksRequestParamsAction;