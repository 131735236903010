import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "_services/api";
import formatTime from "utils/formatTime";
import { decodeData, encodeData } from "utils/formatContent";
import { useDispatch } from "react-redux";
import { interactWithUser } from "redux/status";
import { Collapse, Divider, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import MarkdownComponent from "components/MarkdownComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import BrainCell from "components/BrainCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ViewQAContent = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const handleDeleteRows = (id) => {
    const deleteData = async () => {
      setLoading(true);
      await api
        .delete(`/learning/faq/${id}`)
        .then((r) => {
          if (r.code == 200) {
            setLoading(false);
            // dispatch(
            //   interactWithUser({
            //     message: "Q&A Knowledge was deleted successfully",
            //     type: "success",
            //   })
            // );
            navigate("/training/brain");

            return;
          }
        })
        .catch((error) => {
          setLoading(false);
          // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
        });
    };

    deleteData();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await api
        .get(`brain/get/${id}`)
        .then((r) => {
          if (r.code == 200) {
            setData(r.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
        });
      // setData(result.data);
    };

    fetchData();
  }, []);

  const handleChunkDelete = (vector_id) => {
    
    let chunksArr = data.chunks.filter(item => item.vector_id !== vector_id);
    setData((prevState) => ({
      ...prevState,
      chunks: chunksArr
    }));
  }
  
  const QAEditors = ({ updateData }) => {
    const [questionInputEl, setQuestionInputEl] = useState("");
    const [answerInputEl, setAnswerInputEl] = useState("");
    const [isExpanded, setExpand] = useState(false);
    const [isEditMode, setEditMode] = useState(false);

    const handleExpansion = () => {
      setExpand(!isExpanded);
    };

    const handleSave = async () => {
      // console.log(savedData)

      let params = {
        answer: encodeData(answerInputEl),
        question: encodeData(questionInputEl),
        dataId: data.id,
        modelId: data.model_id,
      };

      setLoading(true);
      await api
        .put("/learning/faq", params)
        .then((r) => {
          setLoading(false);

          if (r.code >= 400) {
          } else {
            updateData({ data: params.answer, title: params.question });
          }

          setEditMode(false);
          setLoading(false);
        })
        .catch((error) => {
          // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
          setLoading(false);
          setEditMode(false);
        });
    };

    const handleEditClose = () => {
      setEditMode(false);
      setQuestionInputEl("");
      setAnswerInputEl("");
    };

    const handleEditClick = () => {
      setEditMode(true);
      // savedData = '';
    };

    return (
      <>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={11}>
          <Typography variant="view_data_content">Raw Data:</Typography>
        </Grid>
        <Grid item md={1} sx={{ textAlign: "right" }}>
          <IconButton onClick={() => handleExpansion()}>
            {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Grid>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Grid item md={11}>
          <Typography variant="view_data_title">
            {loading ? (
              <Skeleton variant="text" animation="wave" />
            ) : isEditMode ? (
              <CustomMdEditor
                value={titleData}
                // onChange={handleEditorChange}
                onChange={setQuestionInputEl}
                customHeight="100%"
              />
            ) : (
              <MarkdownComponent source={titleData} />
            )}
          </Typography>
        </Grid>
        <Grid item md={1} sx={{ textAlign: "right" }}>
          {isEditMode ? (
            <>
              <IconButton onClick={handleEditClose}>
                <CloseIcon color="icons" />
              </IconButton>
              <IconButton onClick={() => handleSave()}>
                <SaveIcon color="icons" />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={() => handleDeleteRows(data.id)}>
                <DeleteIcon color="icons" />
              </IconButton>
              <IconButton onClick={() => handleEditClick()}>
                <EditIcon color="icons" />
              </IconButton>
            </>
          )}
        </Grid>
        <Grid item md={12} sx={{ p: 4 }}>
          {loading ? (
            <>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </>
          ) : isEditMode ? (
            <CustomMdEditor
              value={textData}
              // onChange={handleEditorChange}
              onChange={setAnswerInputEl}
              customHeight="100%"
            />
          ) : (
            <MarkdownComponent source={textData} />
          )}
        </Grid>
        </Collapse>
      </>
    );
  };

  const handleEditorChange = (data) => {
    // savedData = data;
    // console.log(data);
  };


  const calculatedDate = data.created_at
    ? formatTime({ date: data.created_at.date })
    : "";
  const textData = data.data ? decodeData(data.data) : "";
  const titleData = data.title ? decodeData(data.title) : "";

  return (
    <>
      <Grid
        container
        sx={{ height: "inherit", overflowY: "auto", padding: "0 20px" }}
      >
        <Grid item md={11}>
          <Typography variant="view_data_title">Brain Cells</Typography>
        </Grid>
        <Grid
          item
          md={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="view_data_time">
            {loading ? (
              <Skeleton
                sx={{ maxWidth: "120px" }}
                variant="text"
                animation="wave"
              />
            ) : (
              calculatedDate
            )}
          </Typography>
        </Grid>

        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12} sx={{ p: 4 }}>
          {loading ? (
            <>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </>
          ) : (
            data.chunks &&
            data.chunks.map((item, index) => (
              <BrainCell
                key={`${index}-${item.vector_id}`}
                item={item}
                onDelete={(id) => {
                  if (data.chunks?.length > 1) {
                    handleChunkDelete(id);
                  } else {
                    handleDeleteRows(data.id);
                  }
                }}
              />
            ))
          )}
        </Grid>
        <QAEditors
          updateData={(data) => {
            setData((prev) => ({ ...prev, ...data }));
          }}
        />
        {/* <Grid item md={12}>
          <Typography variant="view_data_content">Brain Cells:</Typography>
        </Grid>
        {data.chunks &&
          data.chunks.map((item, index) => (
            <Grid key={index} item md={12}>
              <Divider />
              <Typography variant="span">{decodeData(item.data)}</Typography>
            </Grid>
          ))} */}
      </Grid>
    </>
  );
};

export default ViewQAContent;
