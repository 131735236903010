import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const Copyright = () => {
  return (
    <>
    <Typography variant="body2" color="text.secondary" align="center">
      By signing up, you agree to the&nbsp;
      <a
        style={{ color: "#3976ef" }}
        href="https://younet.ai/terms-and-conditions#terms-and-conditions"
      >Terms of use</a>
      &nbsp;and&nbsp;
      <a
        style={{ color: "#3976ef" }}
        href="https://younet.ai/terms-and-conditions#privacy-policy"
      >Privacy policy.</a>
      <br/>
      </Typography>
      <Typography pt={2} variant="body2" color="text.secondary" align="center">
        {"© " + new Date().getFullYear()}    <a
        href="https:/younet.ai"
        >Younet</a>. All rights Reserved.
      </Typography>
      </>

  );
};

export default Copyright;
