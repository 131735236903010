export const defaultTypography = {
    fontSize: 14,
    fontFamily: [
        'Inter',
    ].join(','),

    header: {
        fontSize: '1.5rem',
        fontWeight: '500',
        letterSpacing: '0.7px',
        paddingBottom: '20px'
    },
    h1: {
        fontSize: '1.5rem',
        //  fontFamily: 'Inter',
        fontWeight: 'bold',
        letterSpacing: '0.7px'
    },
    h2: {
        fontSize: '1rem',
        // fontFamily: 'Inter',
        fontWeight: 'bold',
        letterSpacing: '0.7px'
    },
    h3: {
        fontSize: '0.8rem',
        // fontFamily: 'Inter',
        letterSpacing: '0.7px'
    },
    p: {
        fontSize: 14,
    },
    sources_title: {
        fontSize: '0.875rem',
        fontWeight: '500',
        paddingRight: '5px',
        lineHeight: '1.4',
        // color:'#7b8191'
    },
    truncate_text_150w: {
        display: 'inline-block',
        fontSize: '0.7rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        height: '15px'
    },
    infoSources_text_truncated: {
      position: 'relative',
      display: 'inline-block',
      fontSize: '0.7rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '150px',
    },
    infoSources_text: {
      position: 'relative',
      display: 'inline-block',
      fontSize: '0.7rem',
      overflow: 'hidden',
      // whiteSpace: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // maxWidth: '150px',
      // height: '15px'
    },
    messages: {
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif',
        letterSpacing: '0.7px',
        display: 'block'
    },
    tool_header: {
        width: '100%',
        fontSize: '1.5rem !important',
        letterSpacing: '0.7px',
        textTransform: 'uppercase',
        textAlign: 'center',
        display: 'block'

    },
    link: {
        fontSize: 'inherit',
        // fontFamily: 'Inter',
        textDecoration: 'underline',
        cursor: 'pointer',
        letterSpacing: '0.7px'
    },
    span: {
        fontSize: '0.7rem',
        // fontFamily: 'Inter',
        letterSpacing: '0.7px'
    },
    one_line_ellipsis: {
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    describe_text: {
        fontSize: '0.875rem',
        fontWeight: '400',
        color: '#7b8191'
    },
    count_down_text: {
        fontSize: '0.675rem',
        fontWeight: '500',
        color: '#7b8191',
        position: 'relative',
        bottom: '-2px'
    },
    list_header: {
        fontSize: '0.7rem',
        fontWeight: '500',
        color: '#7b8191'
    },
    page_title: {
        fontSize: '1.1rem',
        fontWeight: '500',
        color: 'rgb(52, 49, 76)',
        margin: '10px 0',
        letterSpacing: '1px'
    },
    page_sub_title: {
        fontSize: '1rem',
        fontWeight: '500',
        color: 'rgb(52, 49, 76)'
        // margin: '10px 0',
        // letterSpacing: '0.4px'
    },
    page_sub_title_bold: {
        fontSize: '1rem',
        fontWeight: '600',
        color: 'rgb(52, 49, 76)',
        letterSpacing: '0.4px'
    },
    page_content: {
        fontSize: '0.75rem',
        fontWeight: '400',
        color: 'rgb(52, 49, 76)'
        // margin: '10px 0',
        // letterSpacing: '0.4px'
    },
    view_data_title: {
        fontSize: '1.2rem',
        fontWeight: '500',
        color: 'rgb(52, 49, 76)',
        margin: '10px 0',
        minWidth: '150px',
        maxWidth: '160px',
        letterSpacing: '1px'
    },
    view_data_content: {
        fontSize: '0.8rem',

        width: '100%',
        // color:'rgb(52, 49, 76)',
        margin: '10px 0',
        letterSpacing: '1px'
    },
    view_data_content_preview: {
        fontSize: '0.8rem',

        width: '100%',
        // color:'rgb(52, 49, 76)',
        margin: '10px 0',
        letterSpacing: '1px'
    },
    view_data_time: {
        fontSize: '0.8rem',
        minWidth: '100px',
        maxWidth: '150px',
        // color:'rgb(52, 49, 76)',
        textAlign: 'right',
        letterSpacing: '1px'
    },
    cards_title: {
        fontFamily: 'Inter',
        fontSize: '0.875rem',
        fontWeight: '500',
        opacity: 0.8,
        color: '#252525',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        lineClamp: '2',
        WebkitLineClamp: '2',

        // '-webkit-line-clamp': '2',
        // 'line-clamp': '2',
        // '-webkit-box-orient': 'vertical'
    },
    cards_title_mobile: {
        fontFamily: 'Inter',
        fontSize: '0.875rem',
        fontWeight: '500',
        opacity: 0.8,
        color: '#252525',
    },
    gmailFrom: {
        fontSize: '0.8rem',
        letterSpacing: '0.7px',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
    },
    gmailSubject: {
        fontSize: '0.8rem',
        letterSpacing: '1px',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
    },
    gmailContent: {
        fontSize: '0.8rem',
        letterSpacing: '1px',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        maxWidth: '500px',
        color: '#5f6368'
    },
    inline: {
        display: 'flex'
    },
    emailTemplateSubject: {
        fontSize: '1.375rem',
        fontWeight: 500,
        letterSpacing: '0.7px'
    },
    emailTemplateName: {
        fontSize: '0.875rem',
        fontWeight: 500,
        letterSpacing: '0.7px'
    },
    emailTemplateEmail: {
        fontSize: '0.75rem',
        color: '#5f6368',
        // fontFamily: 'Inter',
        letterSpacing: '0.7px'
    },
    emailTemplateTime: {
        fontSize: '0.75rem',
        color: '#5f6368',
        // fontFamily: 'Inter',
        letterSpacing: '0.7px'
    },
    emailTemplateContent: {
        fontSize: '0.813rem',
        // fontFamily: 'Inter',
        letterSpacing: '0.7px'
    },
    footerLink: {
        color: '#00000099 !important',
        fontSize: 12,
        textDecoration: 'none !important',
    },
    plain_links: {
        position: 'relative',
        color: '#3976EF',
        fontSize: '0.813rem',
        padding: '0 2px'
    },
    center_text: {
        textAlign: 'center'
    },
    new_status: {
        fontSize: '0.813rem',
        color: '#3976EF',
        letterSpacing: '0.7px'
    },
    error_status: {
        fontSize: '0.813rem',
        color: 'red',
        letterSpacing: '0.7px'
    },
    approved_status: {
        fontSize: '0.813rem',
        color: 'green',
        letterSpacing: '0.7px'
    },
    success_status: {
        fontSize: '0.813rem',
        color: 'green',
        letterSpacing: '0.7px'
    },
    trained_status: {
        fontSize: '0.813rem',
        color: 'green',
        letterSpacing: '0.7px'
    },
    pending_status: {
        fontSize: '0.813rem',
        color: '#F7CB73',
        letterSpacing: '0.7px'
    },
    processing_status: {
        fontSize: '0.813rem',
        color: '#F7CB73',
        letterSpacing: '0.7px'
    },
    welcome_header: {
        fontSize: '1.8rem',
        fontWeight: '300',
        letterSpacing: '1.2px',
        paddingTop: '60px'
    },
    marketplace_title: {
      fontSize: '2rem',
      fontWeight: '600',
      '@media (max-width: 960px)': {
        fontSize: '1.875rem',
      },
      '@media (max-width: 600px)': {
        fontSize: '1.5rem',
      },
    },
    marketplace_subtitle: {
      fontSize: '1rem',
      fontWeight: '400',
      '@media (max-width: 600px)': {
        fontSize: '0.87rem',
      },
    },
    marketplace_content_title: {
      fontSize: '1.75rem',
      fontWeight: '600',
      '@media (max-width: 960px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 600px)': {
        fontSize: '1.375em',
      },
    },
    // Omboarding
    onboarding_title: {
      fontSize: '0.875rem',
      fontWeight: '600',
      '@media (max-width: 960px)': {
        fontSize: '0.875rem',
      },
      '@media (max-width: 600px)': {
        fontSize: '0.875rem',
      },
    },
    onboarding_content: {
      display: 'block',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '1.4',
      '@media (max-width: 960px)': {
        // fontSize: '1.5rem',
      },
      '@media (max-width: 600px)': {
        // fontSize: '1.375rem',
      },
    },
}