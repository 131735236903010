import { useState, useEffect } from 'react';
import { Paper, Collapse, Fade, Box } from '@mui/material';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useSelector } from 'react-redux';
import { interactWithUser } from "redux/status";
import { useDispatch } from "react-redux";
import api from "_services/api";
import Version from './components/Version';

const Settings = ({ isOpen, closeDialog }) => {
  const dispatch = useDispatch();
  const model = useSelector(state => state.models.current);
  const [modelSetting, setModelSetting] = useState({
    gpt_model: model.settings && model.settings.gpt_model ? model.settings.gpt_model: null,
    contextWindowSizePercent: model.settings && model.settings.contextWindowSizePercent ?  model.settings.contextWindowSizePercent : null,
  });
  const { smallScreen } = useIsSmallScreen();
  const desktopStyling = {
    zIndex: 3,
    borderRadius: '4px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, ' +
      'rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, ' +
      'rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
  };

  useEffect(() => {
    if ( modelSetting.gpt_model === null || modelSetting.contextWindowSizePercent === null) {
      api.get('/user/settings/current')
        .then(response => {
          if (response.code === 200) {
            setModelSetting({
              gpt_model: response.setting.gpt_model,
              contextWindowSizePercent: response.setting.contextWindowSizePercent,
            });
          }
        })
        .catch(error => {
          dispatch(
            interactWithUser({
              message: `Error fetching model settings: ${error}`,
              type: "error",
            })
          );
        });
    }
  }, []);

  const modelChangeHandler = (name, value) => {
    closeDialog();
    setModelSetting((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  if (smallScreen) {
    return (
      <Collapse in={isOpen} unmountOnExit sx={{ padding: '0 5%', boxShadow: 'rgba(0,0,0, .1) 0px -7px 12px -4px' }}>
        <Version model={model} modelSetting={modelSetting} modelChangeHandler={modelChangeHandler} />
        {/* <Visibility model={model} modelSetting={modelSetting} modelChangeHandler={modelChangeHandler} /> */}
      </Collapse>
    )
  }

  return (
    <Fade in={isOpen} unmountOnExit style={{ boxShadow: 'none', position: 'absolute', bottom: 0, right: 0, left: 0 }}>
      <Box sx={{zIndex: 3}}>
        <Paper sx={desktopStyling}>
          <Version model={model} modelSetting={modelSetting} modelChangeHandler={modelChangeHandler} />
          {/* <Divider sx={{ margin: '15px 0' }} /> */}
          {/* <Visibility model={model} modelSetting={modelSetting} modelChangeHandler={modelChangeHandler} /> */}
        </Paper>
      </Box>
    </Fade>
  );
};

export default Settings;
