import {useState, useEffect} from 'react';

let sourcesTimer = null;
const useWriteAnimation = (sources = [], setAction, interval = 1000, startIndex = 0) => {
    const [animationText, setAnimationText] = useState('');


    const contentWriteAnimation = () => {
        if (!Array.isArray(sources) || sources.length === 0 || !setAction || typeof setAction !== 'function' || interval <= 0) {
            console.error('Invalid arguments provided to contentSearchAnimation function.');
            return;
        }

        let typeIndex = startIndex;
        const maxTypeLength = sources.length;


        const updateUI = () => {
            if (typeIndex >= maxTypeLength) {
                clearInterval(sourcesTimer);
                return;
            }

            const uiMsg = sources[typeIndex++] + '...';
            setAction(uiMsg);
        };

        updateUI();

        sourcesTimer = setInterval(updateUI, interval);

        // setTimeout(() => {
        //     clearInterval(sourcesTimer);
        // }, duration);
    }

    const cleanContentWriteAnimation = () => {
        clearInterval(sourcesTimer)
    }

    return {contentWriteAnimation, cleanContentWriteAnimation};
};




export default useWriteAnimation;
