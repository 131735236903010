import React, {useState} from 'react';
import {Select, MenuItem, Typography, Chip, Box} from '@mui/material';
import {useNavigate} from "react-router-dom";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PublicIcon from "@mui/icons-material/Public";

const icon = (shareType) => {
    if (shareType === 'monetization') {
        return <AttachMoneyIcon/>;
    } else if (shareType === 'restricted') {
        return <HttpsOutlinedIcon/>;
    } else if (shareType === 'chat-bot') {
        return <SmartToyIcon/>
    } else if (shareType === 'customer-support') {
        return <QrCodeIcon/>
    } else {
        return <PublicIcon/>;
    }
}

const description = {
    restricted: 'Only invited people will have the ability to communicate with the model',
    public: 'Anyone on the Internet will have the ability to communicate with the model',
    monetization: 'Only people with a subscription will have the ability to communicate with the model',
    'chat-bot': 'People on your website will be able to use this chat',
    'speak-to-product': 'People with the customer link or QR code will be able to use this chat',
};

const ShareTypeSelector = ({shareType, smallScreen = false}) => {

    const navigate = useNavigate();

    const handleChange = (event) => {
        // setShareType(event.target.value);
        navigate(`/share/${event.target.value}`);
    };

    const containerStyle = smallScreen ? {display: 'flex', flexFlow: 'column'}  : {}


    return (
        <Box sx={{display: 'flex', gap: '10px', flexFlow: smallScreen ? 'column' : 'row'}}>
            {!smallScreen && (
              <Chip
                sx={{
                    padding: '5px',
                    ".MuiChip-avatar": {margin: 0, padding: '1px'},
                    ".MuiChip-label": {padding: 0}
                }}
                avatar={icon(shareType)}
              />
            )}
            <Box sx={containerStyle}>
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', marginBottom: { xs: '5px', md: 0 } }}>
                {smallScreen && (
                  <Chip
                    sx={{
                        padding: '5px',
                        ".MuiChip-avatar": {margin: 0, padding: '1px'},
                        ".MuiChip-label": {padding: 0}
                    }}
                    avatar={icon(shareType)}
                  />
                )}
                <Select
                    value={shareType}
                    onChange={handleChange}
                    sx={{
                        boxShadow: "none",
                        fontSize: '14px',
                        fontWeight: 500,
                        position: 'relative',
                        right: smallScreen ? '-10px' : 'unset',
                        padding: 0,
                        ".MuiOutlinedInput-notchedOutline": {border: 0},
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {border: 0},
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {border: 0},
                        ".MuiSelect-select": {padding: 0}
                    }}
                >
                    <MenuItem value="public">Anyone with the link</MenuItem>
                    <MenuItem value="restricted">Restricted by email</MenuItem>
                    <MenuItem value="monetization">Monetization</MenuItem>
                    <MenuItem value="chat-bot">Website Chatbot</MenuItem>
                    <MenuItem value="speak-to-product">Speak to Product</MenuItem>
                </Select>
              </Box>
              <Typography sx={{fontSize: '12px', color: '#444746'}}>
                  {description[shareType]}
              </Typography>
            </Box>
        </Box>
    );
};

export default ShareTypeSelector;
