import Grid from "@mui/material/Grid";
import {memo, useCallback, useEffect, useMemo} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getTrainedList} from "redux/links/linksThunk";
import Link from "./components/Link";
import LoadingLinksList from "./components/LoadingLinksList";
import List from "@mui/material/List";
import getLinksListByFilterAction from "../../actions/getLinksListByFilterAction";
import styles from '../../links-table.module.css';
import getLinksRequestParamsAction from "../../actions/getLinksRequestParamsAction";
import {useNavigate, useParams} from "react-router-dom";
import PendingList from "components/lists/PendingList";
import {setItemToggle} from "redux/links";
import classnames from "classnames";
import NoItemsInTheList from "components/showcase/Empty/NoItemsInTheList";


const MemorizedList = memo(({sort = '', list = []}) => {


    const dispatch = useDispatch();
    const trainedItemsIds = useSelector(state => state.links.content.sort.maps.trainedIds, shallowEqual);
    const selectedIds = useSelector(state => state.links.content.sort.maps.selectedIds, shallowEqual);
    const deeperViewAllowed = useSelector(state => state.links.content.deeperViewAllowed);

    const navigate = useNavigate();
    const handleRedirect = useCallback((path) => {
        navigate(path);
    }, [navigate])

    const setItem = useCallback((data) => {
        dispatch(setItemToggle(data));
    }, [dispatch])

    if (sort === 'pending') {
        return <PendingList list={list}/>
    }

    return list.map((item) => {
        const isTrained = Boolean(trainedItemsIds[item.id]);
        const isSelected = Boolean(selectedIds[item.id]);
        return <Link key={item.id} item={item} sort={sort} handleRedirect={handleRedirect} setItemToggle={setItem}
                     isTrained={isTrained} isSelected={isSelected} isRedirectAllowed={deeperViewAllowed}/>
    })
})

const LinksList = () => {
    // console.log('list')

    const dispatch = useDispatch();
    const content = useSelector(state => state.links.content, shallowEqual);
    const modelId = useSelector(state => state.models?.current?.id);

    const currentSort = content.sort?.current?.id;
    const linkCategory = content.list[currentSort];
    const currentPageId = linkCategory?.pagination?.page?.id || 1;
    const cachedLinks = linkCategory ? linkCategory[currentPageId] : undefined;


    useEffect(() => {

        if (typeof cachedLinks !== 'undefined' && typeof currentSort !== 'undefined') {
            return;
        }

        const flexibleSort = currentSort.startsWith('nested') ? 'nested' : currentSort;

        // default params + specific for each type of filter
        const params = {
            modelId,
            ...getLinksRequestParamsAction[flexibleSort](content)
        };


        dispatch(getLinksListByFilterAction[flexibleSort](params));
    }, [currentSort, currentPageId, content.syncCount]);

    if (content.loading) {
        return <LoadingLinksList/>;
    }


    if (!cachedLinks || cachedLinks?.length === 0) {
        return <NoItemsInTheList/>;
    }

    return <List dense disablePadding
                 className={classnames(styles['last-item-no-border'])}
    ><MemorizedList sort={currentSort}
                    list={cachedLinks}/></List>;
}

export default memo(LinksList);