import {
 Grid,Box,
 Paper,
 Typography,
 TextField,
} from "@mui/material";
import { useState } from "react";
import dropdowns from "mappings/signInDropDowns.json";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { checkFormForErrors } from "utils/validateInputs";
import api from "_services/api";
import ResetPasswordMemo from "components/userInteractions/Memos/ResetPassword";
import SendIcon from "@mui/icons-material/Send";
import LogoSvg from "components/logo/LogoSvg";
import styles from './resetpassword.module.css'
import TopAppBar from "components/TopAppBar";

const ResetPassword = () => {
 const [email, setEmail] = useState("");
 const [isLoading, setLoading] = useState(false);

 const [formState, setFormState] = useState({
   email: {
     errMsg: "",
     isRequired: true,
   }
 });
 const handleFormSubmit = (e) => {
   e.preventDefault();

   const form = new FormData(e.currentTarget);
   let status = checkFormForErrors(form, formState);


   setFormState((prevState) => ({
     ...prevState,
     ...status.updates,
   }));

   if (status.hasErrors) {
    return;
   } else {

    let email = form.get("email");
    verifyEmail(email);
   }
 };

 const verifyEmail = async (email) => {
  setLoading(true);
  await api
        .get(`users/forget/password/${email}`)
        .then((r) => {
          if (r.code == 200) {
            setEmail(email);
          }
          setLoading(false);
          
        })
        .catch(() => {
         setLoading(false);
        });
 }



 if(email) {
  return <ResetPasswordMemo email={email}/>
 }

 return (
  <>
  <TopAppBar/>
  <Paper
     // className={styles["paper-container"]}
     variant="outlined"
     sx={{
       my: { xs: 3, md: 6 },
       p: { xs: 2, md: 3 },
       maxWidth: "600px",
       margin: "150px auto",
     }}
   >
     <Grid
       component="form"
       noValidate
       onSubmit={handleFormSubmit}
       container
       spacing={6}
     >
       <Grid item xs={12} md={12}>
         <Typography component="h1" variant="page_title" align="left">
           Forgot your password?
         </Typography>
       </Grid>
       <Grid item xs={12} md={12} sx={{paddingTop: "0 !important"}}>
         <Typography component="span" variant="page_sub_title" align="left" >
           Please enter your email you use to sign in to the portal:
         </Typography>
       </Grid>

       <Grid item xs={12} md={12}>
         <TextField
           id="email"
           name="email"
           label="Your Email"
           fullWidth
           autoComplete="email"
           variant="outlined"
           error={formState.email.errMsg != ""}
           helperText={formState.email.errMsg ? formState.email.errMsg : ""}
         />

       </Grid>
       <Grid item display="flex" justifyContent="flex-end" xs={12}>

         <LoadingButton
           type="submit"
           variant="contained"
           sx={{ mt: 3, ml: 1, borderRadius: "5px" }}
           loading={isLoading}
           loadingPosition="end"
           endIcon={<SendIcon />}>
            
           Continue
         </LoadingButton>
       </Grid>
     </Grid>
   </Paper>
  </>
 );
};

export default ResetPassword;
