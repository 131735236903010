import { Box, Button, Divider, Grid, Typography, Chip } from '@mui/material';
import { AddressElement, CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import styles from "pages/protected/billing/Payment/payment.module.css";
import PoweredByStripe from './logos/PoweredByStripe';
import Guarantee from './logos/Guarantee';
import { interactWithUser } from 'redux/status';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '_services/api';
import controlledAPI from '_services/controlledApi';
import ShadowSpinner from 'components/loadings/ShadowSpinner';

const CheckoutPaymentForm = ({ setPaymentMethod = false, formRef = null, setSuccess = false, setTempAddress = ()=>{} }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const handleMessage = (message, type = "error") => {
    dispatch(
      interactWithUser({
        message: message ?? "Something went wrong..",
        type: type,
      })
    );
    setCheckoutLoading(false);

    return;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setCheckoutLoading(true);

    const DEF_ERROR_MSG = "Something went wrong..";
    const SUCCESS_MSG = "Successfully created a new payment method";
    const BILLING_ROUTE = '/billing/payment/all';
    const CREATE_PAYMENT_STRIPE_ROUTE = "stripe/payment_method";
    const SET_DEFAULT_PAYMENT = "stripe/payment_method/set-default";

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        return handleMessage(submitError.message);
      }

      const status = await stripe?.confirmSetup({
        elements,
        redirect: "if_required"
      });

      if (status?.setupIntent?.payment_method) {
        let params = {
          methodId: status?.setupIntent?.payment_method
        }
        let response = await controlledAPI({ ignoreUser: true }).post(CREATE_PAYMENT_STRIPE_ROUTE, params);

        if (response.code != 201) {
          return handleMessage(DEF_ERROR_MSG);
        }
        const { code } = await controlledAPI({ ignoreUser: true }).get(`${SET_DEFAULT_PAYMENT}/${response.id}`);
        if (code != 200) {
          return handleMessage(DEF_ERROR_MSG);
        }

        setCheckoutLoading(false);
        setPaymentMethod(response);
        setSuccess("success");

        return;
      } else {
        return handleMessage(status?.error?.message || DEF_ERROR_MSG);
      }
    } catch (e) {
      console.warn(e);
      return handleMessage(DEF_ERROR_MSG);
    }
  }

  const appearance = { /* appearance */ };
  const options = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    }
  };

  return (
    <Grid container
      component="form"
      ref={formRef}
      padding={2}
      // noValidate
      onSubmit={handleSubmit}
      spacing={2}
    >
      {checkoutLoading && <ShadowSpinner open={checkoutLoading} />}
      <Grid item xs={12} md={12}>
        <Typography variant="page_sub_title_bold">Payment Info</Typography>
        <Box className={styles["indentions"]}>
          <PaymentElement options={options} />
          {/* <CardElement /> */}
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="page_sub_title_bold">Billing Address</Typography>
        <Box className={styles["indentions"]}>
          <AddressElement options={{ mode: 'billing' }} onChange={(event) => {
            let country = event?.value?.address?.country || false;

            if (country && setTempAddress) { 
              setTempAddress(country)
            }
          }} />
          <Box ml={1} mt={6} sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
            <Guarantee />&nbsp;&nbsp;&nbsp;
            <Typography variant="describe_text" sx={{ fontWeight: 600, color: '#2a2f32' }}>Guaranteed safe checkout</Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;<Chip sx={{ padding: '5px 0 0', height: '20px', lineHeight: '20px', borderRadius: '4px', background: '#6a7177' }} label={<PoweredByStripe />}></Chip>
          </Box>
        </Box>
      </Grid>

      {/* {!isInsideForm && */}
      {/* // <Grid item xs={12} md={12}>
          //     <Box className={styles["submit-btn-container"]}>
          //         <Button variant="contained" type="submit">Save</Button>
          //     </Box>

          // </Grid> */}
      {/* } */}

    </Grid>
  );
};

export default CheckoutPaymentForm;
