import React, {useState, useEffect} from "react";
import {
    Box,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    Skeleton,
    Button,
    MenuItem,
    Menu,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, Avatar,
} from "@mui/material";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { getInitials } from "utils/prepareNames";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import MenuIcons from "components/icons/menuIcons";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "_services/api";
import {interactWithUser} from "redux/status";
import SharedModels from 'components/menus/SharedModels';
import {deleteModel, setSelectedModelType, removeHints, changeModel} from "redux/models";
import useViewportHeight from "hooks/useViewportHeight";
import ChatMenu from "components/menus/helperMenu/ChatMenu";
import AccessChecker, {PlanChecker} from "components/security/AccessChecker";
import styles from '../../parent-menu.module.css';
import {setCurrentChat, setSource} from "redux/chats";
import {AutoAwesome} from "@mui/icons-material";
import modelActions from "_actions/modelActions";
import ShareModelListBtn from "./ShareModelListBtn";
import brainToolsLabels from "mappings/languages/en/brainToolsLabels";

const navigationMenuItems = brainToolsLabels.labels.navigationMenuItems;

const SimpleListMenu = ({userId, rabbitId, smallScreen, setShareModelConfig}) => {
    const [modelsMenuOpen, setModelsMenuOpen] = useState(false);
    const [brainMobileMenuOpen, setBrainMobileMenuOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedModelId, setSelectedModelId] = useState(null);
//   const [shareModelConfig, setShareModelConfig] = useState({
//     isExpanded: false,
//     model: null,
//   });
    //const [moreMobileMenuOpen, setMoreMobileMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);
    const modelsOpen = Boolean(anchorEl);

    const location = useLocation();
    const viewportHeight = useViewportHeight();


    const dispatch = useDispatch();
    const {models, planLimitations, isSharedChatSelected} = useSelector((state) => ({
        models: state.models,
        planLimitations: state.permissions.planLimitations,
        isSharedChatSelected: state.chats.source !== "default",
    }), shallowEqual);

    const navigate = useNavigate();
    const modelActionsRedux = modelActions(dispatch)

    const handleModelChange = (e, modelId) => {
        e.preventDefault();
        modelActionsRedux.changeModel(modelId);
        let navigateLink ="/playground";
        navigate(navigateLink);
    };
    const handleModelsClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleModelsClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemEnter = (index) => {
        setHoveredItemIndex(index);
    };
    const handleManageModelClick = (e) => {
        e.stopPropagation();
        navigate("/models");
        handleModelsClose();
        setModelsMenuOpen(false);
    };
    const handleModelEdit = (e, id) => {
        // e.stopPropagation();
        navigate(`/models/${id}`);
        handleModelsClose();
        setModelsMenuOpen(false);
    };
    const handleModelDelete = (e, id) => {
        handleDeleteModel(id);
        handleModelsClose();
        setModelsMenuOpen(false);
        setIsDialogOpen(false);
    };
    const handleDeleteModel = async (id) => {
        await api
            .delete(`/model/${id}`)
            .then((r) => {
                dispatch(
                    interactWithUser({
                        message: "Model was deleted",
                        type: "info",
                    })
                );
                dispatch(deleteModel({id: id}));
            })
            .catch((error) => {
            });
    };

    const fetchSharedData = async (modelHash) => {
      let params = {
        modelHash,
      };
      dispatch(setSelectedModelType({ modelType: 'shared', data: null, loading: true }));
    
      try {
        const response = await api.post('/model/share/data', params);
        
        if (response.code >= 400) {
          // setDataLoading(false);
          // setChatNotExist(true);
          return;
        }
  
        const messages = response.messages || [];
        const model = response.chat?.model || {};
        const ownerInfo = response.owner || {};
        const ownerInnitial = ownerInfo.fullName || '';
  
        if (ownerInnitial !== '') {
          ownerInfo.initial = getInitials(ownerInnitial);
        }
  
        // dispatch(addMessages({ type: 'shared', messages }));
        // setModelInitialsData({ avatar: model.avatar, name: model.name });
        const data = {
          model: model,
          messages: messages,
          info: {
            // chat_id: null,
            chat_id: response.chat.id,
            guest_id: response.chat.guest_id,
            modelName: model.name,
            modelWebsite: model.modelWebsite,
            modelId: model.id,
          },
          ownerInfo: ownerInfo,
        };
        dispatch(setSelectedModelType({ modelType: 'shared', data, loading: false }));
      } catch (error) {
        // setDataLoading(false);
      }
    }

    const openShareConfig = (e, model) => {
        e.preventDefault();
        setShareModelConfig({isExpanded: true, model: model});
    };

    if (models.loading || (!Boolean(models.selectedModel.data) && models.list.length === 0)) {
        return <Skeleton variant="h2"/>;
    }
    const ListSimpleModelMenu = ({mod, index}) => {
        return (
            <Grid key={mod.id} container>
                <MenuItem
                    onMouseEnter={() => handleMenuItemEnter(index)}
                    onMouseLeave={() => {
                        setHoveredItemIndex(-1);
                    }}
                    data-value={mod.id}
                    divider
                    sx={{fontSize: 14, padding: "10px 20px"}}
                    onClose={handleModelsClose}
                    onClick={(e) => {
                      dispatch(setSelectedModelType({ modelType: 'default', data: null, loading: false }));
                        handleModelChange(e, mod.id);
                    }}
                >
                    <Grid container width={260} py={1}>
                        <Grid
                            item
                            xs={8}
                            pr={3}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "30px",
                            }}
                        >
              <span
                  style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                  }}
              >
                {mod.name}
              </span>
                        </Grid>
                        <AccessChecker
                            currentPermissions={mod.access}
                            requiredPermissions={["write"]}
                        >
                            {smallScreen ? (
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                    }}
                                >
                                    <IconButton
                                        sx={{width: "30px", height: "30px"}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleModelEdit(e, mod.id);
                                        }


                                        }
                                    >
                                        <SettingsIcon fontSize="small"/>
                                    </IconButton>
                                    <IconButton
                                        sx={{width: "30px", height: "30px"}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedModelId(mod.id);
                                            setIsDialogOpen(true);
                                        }}
                                    >
                                        <DeleteIcon fontSize="small"/>
                                    </IconButton>
                                </Grid>
                            ) : (
                                hoveredItemIndex === index && (
                                    <Grid
                                        item
                                        xs={4}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <IconButton
                                            sx={{width: "30px", height: "30px"}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleModelEdit(e, mod.id)
                                            }
                                            }
                                        >
                                            <SettingsIcon fontSize="small"/>
                                        </IconButton>
                                        <IconButton
                                            sx={{width: "30px", height: "30px"}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedModelId(mod.id);
                                                setIsDialogOpen(true);
                                            }}
                                        >
                                            <DeleteIcon fontSize="small"/>
                                        </IconButton>
                                    </Grid>
                                )
                            )}
                        </AccessChecker>
                        {userId !== mod.user_id &&
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                }}
                            >
                                <Typography sx={{fontSize: 12, color: '#00000066'}}>Linked</Typography>
                            </Grid>}
                    </Grid>
                </MenuItem>
            </Grid>
        )
    }

    return (
        <>
            <List className={styles.list}>
                <ListItemButton
                    sx={{pl: 6, pb: 3, borderBottom: "1px solid #ededf5"}}
                    onClick={(e) => {
                        handleModelsClick(e);
                        setModelsMenuOpen(!modelsMenuOpen);
                    }}
                >
                    <ListItemIcon sx={{padding: "4.8px 0"}}>
                        <ListItemIcon sx={{minWidth: "40px"}}>
                            <Avatar src={models?.current?.avatar} sx={{
                                width: {xs: '22px', md: '22px'},
                                height: {xs: '22px', md: '22px'},
                                border: '1px solid #00000011',
                                fontSize: "14px"
                            }}>{getInitials(models?.current?.name)}</Avatar>
                        </ListItemIcon>
                        {modelsMenuOpen ? (
                            <Menu
                                id="model-menu"
                                width="100%"
                                anchorEl={anchorEl}
                                open={modelsOpen}
                                onClose={handleModelsClose}
                                PaperProps={{
                                    elevation: 3,
                                }}
                            >
                                {models.list.length > 0 && (
                                    <Box px={5} py={2}>
                                        Select Model:
                                    </Box>
                                )}
                                {models.list.map((mod, index) => (
                                    <ListSimpleModelMenu key={mod.id} mod={mod} index={index}/>
                                ))}
                                <Grid container width={200}>
                                    <MenuItem
                                        sx={{fontSize: 14, padding: "10px 20px"}}
                                        onClose={handleModelsClose}
                                        onClick={() => {
                                            dispatch(removeHints());
                                            navigate("/models/create");
                                        }}
                                    >
                                        <Grid container width={260} py={1}>
                                            <Grid
                                                item
                                                xs={1}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "30px",
                                                }}
                                            >
                                                <NoteAddIcon sx={{color: "#5f6368"}}/>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={7}
                                                px={3}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "30px",
                                                }}
                                            >
                                                Create Model
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "30px",
                                                }}
                                            >
                                                <Button
                                                    sx={{fontSize: 14, textTransform: "none"}}
                                                    fullWidth
                                                    onClick={handleManageModelClick}
                                                >
                                                    Manage
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                </Grid>
                            </Menu>
                        ) : (
                            ""
                        )}
                    </ListItemIcon>
                </ListItemButton>
                {/* <SharedModels widesidebar={false} onSelect={(model) => {
                  if (!Boolean(model?.settings?.monetization_price) && Boolean(model?.public_hash)) {
                    fetchSharedData(model?.public_hash);
                  }

                  if (Boolean(model?.settings?.monetization_price) && Boolean(model?.marketplace_card?.url_hash)) {
                    console.log(model)
                    dispatch(setSelectedModelType({ modelType: 'monetization', data: model, loading: false }));
                  }
                }} leftBarMenu defaultOppened={false} /> */}
                {/* model part*/}
                {/* <ListItemButton
                    sx={{pl: 6}}
                    selected={location.pathname === "/playground"}
                    onClick={() => {
                        navigate("/playground");
                    }}
                >
                    <ListItemIcon sx={{padding: "4.5px 0"}}>
                        <MenuIcons icon="playground" width="20" height="20"/>
                    </ListItemIcon>
                </ListItemButton> */}
                {models.selectedModel.type !== 'monetization' && models.selectedModel.type !== 'shared' && (
                  <>
                    <AccessChecker
                      currentPermissions={models.current.access}
                      requiredPermissions={["write"]}
                      source="training"
                    >
                        <ListItemButton
                            sx={{pl: 6}}
                            selected={location.pathname.split("/")[1] === "training"}
                            onClick={(e) => {
                                handleModelsClick(e);
                                setBrainMobileMenuOpen(!brainMobileMenuOpen);
                            }}
                        >
                            <ListItemIcon sx={{alignItems: "center", padding: "4.5px 0"}}>
                                <MenuIcons icon="brain" width="20" height="20"/>
                            </ListItemIcon>
                            {brainMobileMenuOpen && (

                                <Menu
                                    id="brain-mobile-menu"
                                    width="100%"
                                    anchorEl={anchorEl}
                                    open={brainMobileMenuOpen}
                                    onClose={handleModelsClose}
                                    PaperProps={{
                                        elevation: 3,
                                    }}
                                >
                                    <Grid width={200}>
                                        {navigationMenuItems.map((item) => (
                                            <MenuItem
                                                divider
                                                sx={{
                                                    fontSize: 14,
                                                    padding: "10px 20px",
                                                    justifyContent: "space-between",
                                                }}
                                                onClose={handleModelsClose}
                                                onClick={() => {
                                                    navigate(item.path);
                                                }}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}

                                    </Grid>
                                </Menu>
                            )}

                        </ListItemButton>
                    </AccessChecker>
                    {/* <ListItemButton sx={{pl:6}} selected={location.pathname === '/models'} onClick={() => {handlePageChange('models');navigate('/models')}}>
          <ListItemIcon sx={{alignItems:'center',padding:'4.5px 0'}}>
            <MenuIcons icon='models' width='20' height='20'/>
          </ListItemIcon>
        </ListItemButton> */}
                    <AccessChecker
                        currentPermissions={models.current.access}
                        requiredPermissions={["write"]}
                        source="all"
                    >
                        <ShareModelListBtn setShareModelConfig={setShareModelConfig}/>
                        {/* <ListItemButton
                            sx={{pl: 6}}
                            selected={location.pathname === "/model/settings"}
                            onClick={() => {
                                navigate("/model/settings");
                            }}
                        >
                            <ListItemIcon
                                sx={{alignItems: "center", padding: "4.5px 0"}}
                            >
                                <MenuIcons icon="setting" width="20" height="20"/>
                            </ListItemIcon>
                        </ListItemButton> */}
                    </AccessChecker>
                  </>
                )}
                {models.selectedModel.type !== 'shared' && models.selectedModel.type !== 'monetization' && (
                  <ListItemButton sx={{ paddingLeft: '21px', paddingTop: '15px', textAlign: 'center' }}>
                    <Button
                      sx={{ minWidth: '26px', padding: 0 }}
                      onClick={() => {
                        navigate("/playground/chat/new");

                        if (isSharedChatSelected) {
                          dispatch(setSource({source: 'default'}));
                        }
                          dispatch(setCurrentChat(null));
                          // onInnerClick();
                        // mobileChatOpenHandler();
                      }}
                      variant="outlined"
                    >
                      +
                    </Button>
                  </ListItemButton>
                )}
                {/* <ListItemButton
            sx={{ pl: 6 }}
            selected={location.pathname === "/rabbit-ai"}
            onClick={() => {
              handlePageChange("rabbit-ai");
              navigate("/rabbit-ai");
            }}
          >
            <ListItemIcon sx={{ alignItems: "center", padding: "4.5px 0" }}>
              <MenuIcons icon="rabbit" width="20" height="20" />
            </ListItemIcon>
          </ListItemButton> */}

                {/* <ListItemButton sx={{pl:6}} selected={location.pathname.split('/')[1] === 'apps'} onClick={(e)=>{handleModelsClick(e);setMoreMobileMenuOpen(!moreMobileMenuOpen);}}>
      <ListItemIcon sx={{padding:'4.5px 0'}}>
      <MenuIcons icon='apps' width='20' height='20'/>
      </ListItemIcon>
      {
        moreMobileMenuOpen?
        <Menu
          id="more-mobile-menu"
          width='100%'
          anchorEl={anchorEl}
          open={moreMobileMenuOpen}
          onClose={handleModelsClose}
          PaperProps={{
            elevation: 3,
          }}
          >
            <Grid width={200}>
            {apps.map((app,i) => (
            <MenuItem key={i} px={4} divider={i<apps.length-1} sx={{fontSize:14}} 
            onClose={handleModelsClose} onClick={()=>{navigate("/apps/"+app.exp_text)}}>{app.exp_text}</MenuItem>
        //map links of app to menu by btn
          ))}
            </Grid>
        </Menu>
        :''
      }
    </ListItemButton> */}
            </List>
            {/* TODO: move this dialog to 1 separate component */}
            {/* Comfirmation dialog */}
            <Dialog
                open={isDialogOpen}
                sx={{
                    '.MuiPaper-root': {
                        padding: 2,
                    },
                }}
                onClose={() => {
                    setIsDialogOpen(false);
                    setSelectedModelId(null);
                }}
            >
                <DialogContent sx={{display: 'flex', alignItems: 'top', gap: '10px'}}>
                    <ReportProblemRoundedIcon sx={{width: 50, height: 50, color: '#ff3d00'}}/>
                    <DialogContentText>
                        Are you sure you want to delete this model's data? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.ctaActions}>
                    <Button
                        variant="outlined"
                        className={styles.cta}
                        onClick={() => {
                            setIsDialogOpen(false);
                            setSelectedModelId(null);
                        }}
                    >Cancel</Button>
                    <Button
                        variant="contained"
                        color="error"
                        className={styles.cta}
                        onClick={(e) => {
                            handleModelDelete(e, selectedModelId);
                            setSelectedModelId(null);
                        }}
                    >
                        Confirm Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SimpleListMenu;