import { createSlice } from "@reduxjs/toolkit";
import { getListThunk } from "./invitationsThunk";

const initialState = {
  list: [],
  loading: false,
  errors: [],
};

export const invitationsSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    setInvitationsList(state, action) {
      state.list = action.payload.list;
      state.status = '';
    },
    addInvitations(state, action) {
      state.list = state.list.concat(action.payload.list);
      state.status = '';
    },
    deleteInvitation(state, action) {
      let { id } = action.payload;
      state.list = state.list.filter(item => item.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListThunk.pending, (state, { _ }) => {
      state.loading = true;
    });
    builder.addCase(getListThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.list = payload.invitations;
      state.errors = [];
    });
  },
});

export const { setInvitationsList, deleteInvitation, addInvitations } =
invitationsSlice.actions;

export const getList = getListThunk;

export default invitationsSlice.reducer;
