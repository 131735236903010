import { useState, useEffect } from "react";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import Grid from "@mui/material/Grid";
import { checkFormForErrors } from "utils/validateInputs";
import { FormHelperText } from "@mui/material";
import { useDispatch } from "react-redux";
import { interactWithUser } from "redux/status";

const QAEditors = ({ fetchSignal, fetchCallback, customHeight = "150px" }) => {

  const dispatch = useDispatch();
  const [questionInputEl, setQuestionInputEl] = useState("");
  const [answerInputEl, setAnswerInputEl] = useState("");
  const [resetSignal, setResetSignal] = useState(false);
  const DEFAULT_MAX_WORDS = 1500;
  const [qaState, setQaState] = useState({
    question: {
      errMsg: "",
      isRequired: true,
      additionals: {
        maxWordsAllowed: DEFAULT_MAX_WORDS,
      },
    },
    answer: {
      errMsg: "",
      isRequired: true,
      additionals: {
        maxWordsAllowed: DEFAULT_MAX_WORDS,
      },
    },
  });

  const prepareBeforeSave = () => {
    let form = new FormData();

    form.append("question", questionInputEl);
    form.append("answer", answerInputEl);
    let status = checkFormForErrors(form, qaState);

    setQaState((prevState) => ({
      ...prevState,
      ...status.updates,
    }));

    // quick token validation
    let questWords = questionInputEl.split(' ');
    let answerWords = answerInputEl.split(' ');
    let totalWords = questWords.length + answerWords.length;
    if (totalWords > DEFAULT_MAX_WORDS) {
      dispatch(
        interactWithUser({
          message: `The total amount of words (${totalWords}) is more than maximum ${DEFAULT_MAX_WORDS}`,
          type: "info",
        })
      );

      return;
    }

    if (status.hasErrors) {
      return;
    }


    return true
  };

  useEffect(() => {
    if (fetchSignal == 1) return;
    let status = prepareBeforeSave();

    if(status) {
        fetchCallback(questionInputEl, answerInputEl);
        setQuestionInputEl("");
        setAnswerInputEl("");
        setResetSignal(true);
    }

  }, [fetchSignal]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <CustomMdEditor
          value={questionInputEl}
          onChange={setQuestionInputEl}
          placeholder="Write a question.."
          customHeight={customHeight}
          resetSignal={resetSignal}
          setResetSignal={setResetSignal}
          disa
        />
        <FormHelperText sx={{ color: "#d32f2f" }}>
          {qaState.question.errMsg ? qaState.question.errMsg : ""}
        </FormHelperText>
      </Grid>
      <Grid item xs={12} md={6} justifyContent="center">
        <CustomMdEditor
          value={answerInputEl}
          onChange={setAnswerInputEl}
          placeholder="Write an answer for the question.."
          customHeight={customHeight}
          resetSignal={resetSignal}
          setResetSignal={setResetSignal}
        />
        <FormHelperText sx={{ color: "#d32f2f" }}>
          {qaState.answer.errMsg ? qaState.answer.errMsg : ""}
        </FormHelperText>
      </Grid>
    </>
  );
};

export default QAEditors;
