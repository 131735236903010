import TablePagination from "@mui/material/TablePagination";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {tableNavigation} from "redux/links";
import {memo} from "react";
import styles from "../../links-table.module.css";


const LinksPagination = () => {

    const dispatch = useDispatch();
    const content = useSelector(state => state.links.content, shallowEqual);


    const currentSortId = content.sort.current.id;
    const pagination = content.list[currentSortId]?.pagination;

    if (content.loading || !pagination) {
        return <></>;
    }

    const maxRows = content.maxRows || 10;
    const linksAmount = pagination.amount;
    const totalAmount = pagination.totalAmount || -1;


    // debugger
    if (pagination.page.id === 1 && linksAmount < maxRows) {
        return;
    }


    const handleChangePage = (e, resId) => {
        const pageId = resId + 1;
        dispatch(tableNavigation({pageId, currentSortId}))
    }

    const numRowsLabel = ({from, to, count}) => {
        return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
    };

    const pageId = pagination.page.id - 1;
    return (
        <TablePagination
            className={styles['pagination-border']}
            labelDisplayedRows={numRowsLabel}
            rowsPerPageOptions={[]}
            component="div"
            count={totalAmount}
            rowsPerPage={maxRows}
            page={pageId}
            onPageChange={handleChangePage}
        />
    )
}
export default memo(LinksPagination);
