import { Grid, Paper, Typography, TextField } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { checkFormForErrors } from "utils/validateInputs";
import api from "_services/api";
import { useNavigate, useParams } from "react-router-dom";
import { setToken } from "_helpers/tokenManagement";
import SendIcon from "@mui/icons-material/Send";

const ResetPassword = () => {
  const params = useParams();
  const hash = params.hash || "";

  const navigate = useNavigate();


  const [isLoading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    password: {
      errMsg: "",
      isRequired: true,
    },
    confirmPassword: {
      errMsg: "",
      isRequired: true,
    },
  });
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    let status = checkFormForErrors(form, formState);

    let password = form.get("password");
    let confirmPassword = form.get("confirmPassword");

    if (confirmPassword != password) {
      status.updates.confirmPassword = {
        isRequired: true,
        errMsg: "Passwords must be identical..",
      };
      status.hasErrors = true;
    }

    setFormState((prevState) => ({
      ...prevState,
      ...status.updates,
    }));

    if (status.hasErrors) {
      return;
    } else {
      saveNewPassword(password, hash);
    }
  };

  const saveNewPassword = async (password, hash) => {
   setLoading(true);


    let params = {
      password: password,
      token: hash,
    };
    
    await api
      .post("users/update/password", params)
      .then((r) => {
       setLoading(false);
        if (r.code == 200) {
          let token = r.token;
          setToken(token);
          navigate("/playground");
        }
        
      })
      .catch(() => {
       setLoading(false);
      });
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        my: { xs: 3, md: 6 },
        p: { xs: 2, md: 3 },
        maxWidth: "600px",
        margin: "150px auto",
      }}
    >
      <Grid
        component="form"
        noValidate
        onSubmit={handleFormSubmit}
        container
        spacing={6}
      >
        <Grid item xs={12} md={12}>
          <Typography component="h1" variant="page_title" align="left">
            Create New Password
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ paddingTop: "0 !important" }}>
          <Typography component="span" variant="page_sub_title" align="left">
            Please enter your new password 
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            id="password"
            name="password"
            label="New Password"
            type="password"
            fullWidth
            autoComplete="password"
            variant="outlined"
            error={formState.password.errMsg != ""}
            helperText={
              formState.password.errMsg ? formState.password.errMsg : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            fullWidth
            autoComplete="password"
            variant="outlined"
            error={formState.confirmPassword.errMsg != ""}
            helperText={
              formState.confirmPassword.errMsg
                ? formState.confirmPassword.errMsg
                : ""
            }
          />
        </Grid>
        <Grid item display="flex" justifyContent="flex-end" xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ mt: 3, ml: 1, borderRadius: "5px" }}
            loading={isLoading}
            loadingPosition="end"
            endIcon={<SendIcon />}>

            Continue
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResetPassword;
