import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { AddressElement, CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import styles from "../../payment.module.css";
import { interactWithUser } from 'redux/status';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '_services/api';
import controlledAPI from '_services/controlledApi';
import StripePaymentSkeleton from 'components/loadings/templates/StripePaymentSkeleton';
import ShadowSpinner from 'components/loadings/ShadowSpinner';

const AddPaymentForm = ({ setPaymentMethod = false }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const handleMessage = (message, type = "error") => {
    dispatch(
      interactWithUser({
        message: message ?? "Something went wrong..",
        type: type,
      })
    );
    setCheckoutLoading(false);
    
    return;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setCheckoutLoading(true);
    const DEF_ERROR_MSG = "Something went wrong..";
    const SUCCESS_MSG = "Successfully created a new payment method";
    const CREATE_PAYMENT_STRIPE_ROUTE = "stripe/payment_method";
    const SET_DEFAULT_PAYMENT = "stripe/payment_method/set-default";

    try {
      const { error: submitError } = await elements.submit();

      if (submitError) {
        return handleMessage(submitError.message);
      }

      const status = await stripe?.confirmSetup({
        elements,
        redirect: 'if_required',
      });


      if (status?.setupIntent?.payment_method) {
          let params = {
            methodId: status.setupIntent.payment_method
          }

          let response = await controlledAPI({ ignoreUser: true }).post(CREATE_PAYMENT_STRIPE_ROUTE, params);

          if (response.code != 201) {
            return handleMessage(DEF_ERROR_MSG);
          }
          const { code } = await controlledAPI({ ignoreUser: true }).get(`${SET_DEFAULT_PAYMENT}/${response.id}`);
          if (code != 200) {
            return handleMessage(DEF_ERROR_MSG);
          }
          setCheckoutLoading(false);
          setPaymentMethod(response);

          return;
        } else {
          return handleMessage(status?.error?.message || DEF_ERROR_MSG);
        }




    } catch (e) {
        console.warn(e);
        return handleMessage(DEF_ERROR_MSG);
    }
  }


  const appearance = { /* appearance */ };
  const options = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    }
  };



  return (
    <Grid container
      component="form"
      onSubmit={handleSubmit}
      spacing={12}
      padding={2}
    >
      {checkoutLoading && <ShadowSpinner open={checkoutLoading}/>}
      {formLoading &&
        <Grid item xs={12} md={12}>
          <StripePaymentSkeleton />
        </Grid>
      }
      <Grid item xs={12} md={12} sx={{ display: formLoading ? "none" : "block" }}>
        <Typography variant="page_sub_title_bold">Payment Info</Typography>
        <Box className={styles["indentions"]}>
          <PaymentElement options={options} onReady={(event) => { setFormLoading(false) }} />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} sx={{ display: formLoading ? "none" : "block" }}>
        <Typography variant="page_sub_title_bold">Billing Address</Typography>
        <Box className={styles["indentions"]}>
          <AddressElement options={{ mode: 'billing' }} />
        </Box>
        <Box className={styles["submit-btn-container"]}>
          <Button variant="contained" type="submit">Save</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddPaymentForm;