import { useState } from "react";
import { Tooltip, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import api from "_services/api";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import FileIcons from "components/icons/fileIcons";
import { interactWithUser } from "redux/status";

import CheckIcon from '@mui/icons-material/Check';

const ApproveMessage = ({ checked, msgId, chatId, onStatusChange }) => {
  const [isApproved, setApprove] = useState(checked);
  const [isLoading, setLoading] = useState(false);
  
  const dispatch = useDispatch();

  const handleChange = () => {
    setLoading(true);

    let action = !isApproved ? "approve" : "disapprove";

    api.get(`/message/${action}/${chatId}/${msgId}`)
      .then((r) => {
        let messages = r.messages || [];

        if (r.code < 400) {
          onStatusChange(!isApproved)
          setApprove(!isApproved)
          dispatch(
            interactWithUser({
              message: `The response ${!isApproved ? 'added to' : 'removed from'} the Brain knowledge`,
              type: "success",
            })
          );
        }

        setLoading(false);
      })
      .catch((error) => {
        // dispatch(
        //   interactWithUser({
        //     message: "Could not use this message for training at this moment.",
        //     type: "error",
        //   })
        // );
        setLoading(false);
      });
  };

  // const last

  return (
    <Tooltip title="Remove this response from AI knowledge" placement="top">
      <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
        {isLoading ?
          (
            <div style={{ margin: '5px 0 0 0', padding: '6px' }}>
              <CircularProgress size={20} />
            </div>
          ) : (
            <Checkbox
              size={"small"}
              checked={isApproved}
              color="success"
              onClick={handleChange}
              checkedIcon={
                <Box sx={{ width: { xs: 20 }, height: { xs: 20 }, display: 'flex', alignItems: 'center' }}>
                  <CheckIcon fontSize="small"/>
                </Box>
              }
              icon={
                <Box
                  sx={{ width: { xs: 20 }, height: { xs: 20 }, display: 'flex', alignItems: 'center' }}
                  display='flex'>
                  <FileIcons icon='brainAdd' />
                </Box>
              }
            />
          )}
      </Box>
    </Tooltip>
  );
};

export default ApproveMessage;
