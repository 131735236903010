import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Skeleton
} from '@mui/material';
import PaymentForm from './components/PaymentForm';
import PaymentsList from './components/PaymentsList';
import { setMonetizationInfo } from 'redux/monetization';
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styles from 'pages/protected/monetization/monetization.module.css';

const ManagePayment = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFormOppened, setIsFormOppened] = useState(false);
  const [editingPaymentId, setEditingPaymentId] = useState(null);
  const dispatch = useDispatch();
  const { list } = useSelector(state => state.monetization.payment);
  const { globalLoading }  = useSelector((state) => state.monetization);

  useEffect(() => {
    setIsFormOppened(!Boolean(list?.length));
  }, [list]);

  const onCloseHandler = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsFormOppened(false);
      setEditingPaymentId(null);
    }, 100);
  }

  return (
    <>
      <GeneralTooltip
        placement="bottom"
        title="Set up or update your payment details to receive earnings from Younet. Ensure your information is current for seamless transactions."
      >
        <Button onClick={() => setIsOpen(true)} className={styles["upgrade-btn"]} color="secondary" size="small" variant="outlined">Manage Payment Details</Button>
      </GeneralTooltip>
      <Dialog open={isOpen} onClose={onCloseHandler} fullWidth xs={{ maxWidth: '600px', width: '100%' }}>
        <DialogTitle sx={{ m: 0, p: 4 }}>Manage payment details</DialogTitle>
        {globalLoading ? (
          <DialogContent dividers>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
          </DialogContent>
        ) : ( 
          isFormOppened ?
            <PaymentForm list={list} setIsClosed={onCloseHandler} editingPaymentId={editingPaymentId} />
            : <PaymentsList
                list={list}
                setIsClosed={onCloseHandler}
                openFormHandler={() => {
                  setIsFormOppened(true);
                  setEditingPaymentId(null);
                }}
                onEditHandler={id => {
                  setEditingPaymentId(id);
                  setIsFormOppened(true);
                }}
                
              />
        )}
      </Dialog>
    </>
  )
}

export default ManagePayment
