import { Box, Tooltip, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Link } from "react-router-dom";

export const LockedTemplate = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#eeeeeed9"
            }}
        >
            <Tooltip title="You don't have enough permission for this menu" placement="top">
                <LockIcon color="icons" />
            </Tooltip>

        </Box>
    );
};



export const UpgradeTemplate = ({ text = null }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#eeeeeee3",
                borderRadius: "2px",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 2,
            }}
        >
            <Tooltip title="You need to upgrade your plan to use this feature" placement="top">
              <>
                <AutoAwesomeIcon sx={{ fill: "#6373ee" }} />
                <Typography variant="describe_text" pl={2}><Link to="/billing/subscribe" > <Typography variant="plain_links" sx={{ fontSize: "14px", fontWeight: 300 }}>Upgrade</Typography></Link> your plan to unlock this feature.</Typography>
              </>
            </Tooltip>
        </Box>
    );
};

export const UpgradeFilesTemplate = ({ text = null }) => {
  return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#eeeeeee3",
          borderRadius: "2px",
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 2,
          padding: '0 10px',
        }}
      >
          <Tooltip title="You need to upgrade your plan to use this feature" placement="top">
            <>
              <AutoAwesomeIcon sx={{ fill: "#6373ee" }} />
              <Typography variant="describe_text" pl={2}>Please <Link to="/billing/subscribe" > <Typography variant="plain_links" sx={{ fontSize: "14px", fontWeight: 300 }}>upgrade</Typography></Link> to use "Speak to File".</Typography>
            </>
          </Tooltip>
      </Box>
  );
};
