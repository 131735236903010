const EXT_ID_EMPTY_MSG = "Extension ID not provided.";
const BAD_ENV_MSG = "sendMessage function is not available for this environment";
const ACTION_FAILED_MSG = "Failed to send extension action: ";
const NO_EXTENSION_MSG = "No extension user detected";
const PUBLIC_EXTENSION_ID = process.env.REACT_APP_PUBLIC_EXTENSION_ID || null;

const requestCallback = (response, runtime) => {
    if (runtime.lastError) {
        console.warn(runtime.lastError);
    } else {
        console.log(response);
    }
}

const checkIfExtensionExist = () => {
    const management = window.chrome?.management || window.browser?.management;

    if (!management) {
        return false
    }

    management.get(PUBLIC_EXTENSION_ID, function (info) {
        if (info) {
            console.log(info)
            return true;
        } else {
            return false;
        }
    });
}

export const sendExtensionAction = ({ action = 'logout', data = undefined }) => {

    if (!PUBLIC_EXTENSION_ID) {
        console.warn(EXT_ID_EMPTY_MSG);
        return;
    }

    // const isExtensionExist = checkIfExtensionExist();

    // if (!isExtensionExist) {
    //     console.info(NO_EXTENSION_MSG);
    //     return
    // }

    const runtime = window.chrome?.runtime || window.browser?.runtime;

    if (!runtime?.sendMessage || typeof runtime.sendMessage !== 'function') {
        console.warn(BAD_ENV_MSG);
        return;
    }

    try {

        const params = {
            action,
            data: data,
        };

        runtime.sendMessage(PUBLIC_EXTENSION_ID, params, (response) => { requestCallback(response, runtime) });

    } catch (error) {
        console.warn(ACTION_FAILED_MSG, error);
    }
};
