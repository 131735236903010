import React from "react";
import { Box } from "@mui/material";
import {LoadingButton} from "@mui/lab";
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';

const ActionBtns = ({
    isPublic,
    hideFromPublic,
    savePublic,
    loading,
    websiteStatus,
    shareType,
    handleSendInvitation,
}) => {
    return (
      <Box sx={{display: 'flex', padding: '0', justifyContent: 'space-between', width: '100%'}}>

          {shareType === 'restricted' && (
              // <Box sx={{ display: 'flex', gap: '10px' }}>
              <LoadingButton
                loading={loading}
                loadingIndicator="Loading…"
                variant="contained"
                onClick={handleSendInvitation}
                size="small"
                startIcon={<SendIcon/>}
                sx={{
                  position: { xs: 'fixed', md: 'relative' },
                  bottom: { xs: '50px', md: 'inherit' },
                  width: { xs: '90%', md: 'auto' },
                  left: { xs: '5%', md: 'inherit' },
                  padding: { xs: '6px 10px', md: '4px 10px' }
                }}
              >
                Send Invitation
              </LoadingButton>
              // </Box>
          )}
          {(!isPublic && (shareType === 'public' || (shareType === 'chat-bot' && websiteStatus.success))) && (
              <LoadingButton
                  loading={loading}
                  loadingIndicator="Loading…"
                  variant="contained"
                  onClick={() => savePublic(1)}
                  size="small"
                  sx={{
                    position: { xs: 'fixed', md: 'relative' },
                    bottom: { xs: '50px', md: 'inherit' },
                    width: { xs: '90%', md: 'auto' },
                    left: { xs: '5%', md: '0' },
                    padding: { xs: '6px 10px', md: '4px 10px' }
                  }}
                  startIcon={<LinkIcon/>}>
                  Share
              </LoadingButton>
          )} 
          {(!isPublic && (shareType === 'speak-to-product')) && (
              <LoadingButton
                  loading={loading}
                  loadingIndicator="Loading…"
                  variant="contained"
                  onClick={() => savePublic(1)}
                  size="small"
                  sx={{
                    position: { xs: 'fixed', md: 'relative' },
                    bottom: { xs: '50px', md: 'inherit' },
                    width: { xs: '90%', md: 'auto' },
                    left: { xs: '5%', md: 'inherit' },
                    padding: { xs: '6px 10px', md: '4px 10px' }
                  }}
                  startIcon={<LinkIcon/>}
              >
                  Generate QR Code
              </LoadingButton>
          )}

          {isPublic && (shareType === 'speak-to-product' || shareType === 'chat-bot') && (
              <LoadingButton
                  loading={loading}
                  loadingIndicator="…"
                  variant="contained"
                  onClick={hideFromPublic}
                  size="small"
                  color="error"
                  sx={{
                    // backgroundColor: 'red',
                    position: { xs: 'fixed', md: 'relative' },
                    bottom: { xs: '50px', md: 'inherit' },
                    width: { xs: '90%', md: 'auto' },
                    left: { xs: '5%', md: 'inherit' },
                    padding: { xs: '6px 10px', md: '4px 10px' }
                  }}
              >
                  STOP SHARING
              </LoadingButton>
          )}

      </Box>
    );
};

export default ActionBtns;