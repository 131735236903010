import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {IconButton, Menu, MenuItem} from "@mui/material";
import FlexWrapper from "components/wrappers/FlexWrapper";
import Typography from "@mui/material/Typography";
import {memo, useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {changeFilter} from "redux/links";
import Button from "@mui/material/Button";
import {sendUIEvent} from "../../../../../../../../../../../../../../events/analytics/google";


const MenuPopup = memo(({sortLabel, filterItems = []}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterChange = (e, value) => {
        e.preventDefault();
        dispatch(changeFilter(value));
        sendUIEvent({name: `website_tool_filter_changed_${value}`});
    }


    return (
        <>
            {/*<IconButton onClick={handleClick}> <FilterAltIcon fontSize="small"/> </IconButton>*/}
            <Button
                aria-haspopup="true"
                onClick={handleClick}
                variant="text"
                sx={{marginLeft: '6px', textTransform: 'capitalize', color: "rgba(0, 0, 0, 0.54)"}}
                startIcon={<FilterAltIcon fontSize="small"/>}


            >
                <FlexWrapper>
                    <Typography fontSize="small">
                        <Typography component="span" fontSize="small"
                        >{sortLabel}</Typography> List</Typography>
                </FlexWrapper>
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/*<MenuItem onClick={(e)=> handleFilterChange(e,'trained')}>Trained</MenuItem>*/}
                {/*<MenuItem onClick={(e)=> handleFilterChange(e,'new')}>New</MenuItem>*/}
                {/*<MenuItem onClick={(e)=> handleFilterChange(e,'all')}>All</MenuItem>*/}

                {filterItems.map((item, index) => (
                    <MenuItem onClick={(e) => handleFilterChange(e, item)} sx={{textTransform: 'capitalize'}}
                              key={index}>
                        {item}
                    </MenuItem>
                ))}
            </Menu>

        </>
    )

})

const FilterMenu = () => {


    const filter = useSelector(state => state.links.content.sort.filter, shallowEqual);
    const sortLabel = useSelector(state => state.links.content.sort.current.id);

    return (
        <FlexWrapper>
            <MenuPopup sortLabel={sortLabel} filterItems={filter}/>
            {/*<FlexWrapper>*/}
            {/*    <Typography fontSize="small">*/}
            {/*        <Typography component="span" fontSize="small"*/}
            {/*                    sx={{textTransform: 'capitalize'}}>{sortLabel}</Typography> List</Typography>*/}
            {/*</FlexWrapper>*/}
        </FlexWrapper>
    )
}

export default memo(FilterMenu);
