import { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../managePayment.module.css";
import AddCardIcon from '@mui/icons-material/AddCard';
import { updatePaymentInfo, deletePaymentInfo } from 'redux/monetization';
import EditIcon from '@mui/icons-material/Edit';
import DeletePaymentDialog from "components/dialogs/DeletePaymentDialog";
import { useDispatch } from "react-redux";

const PaymentsList = ({ list, setIsClosed, openFormHandler, onEditHandler }) => {
  const [defaultCard, setDefaultCard] = useState(list ? list.filter(item => item.is_default)[0] : null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultCard(list?.filter(item => item.is_default)[0]);
  }, [list]);

  const onChangeHandler = (event) => {
    const id = event.target.value;
    const currentItem = list.filter(item => Number(item.id) === Number(id))[0];

    const payload = {
      id,
      data: {
        isDefault: 1,
        type: currentItem.type,
        payment_data: currentItem.payment_data,
      }
    }
    dispatch(updatePaymentInfo(payload));
  }

  const onDeleteHandler = (id) => {
    if (list.length > 1) {
      dispatch(deletePaymentInfo({ id }));
    } else {
      setIsOpen(true);
    }
  }

  return (
    <>
      <DialogContent dividers>
        <RadioGroup
          value={defaultCard?.id}
          onChange={onChangeHandler}
          sx={{ width: "100%" }}
        >
          {list?.map((item, i) =>
            <Grid container className={styles["payment-box-info"]} key={`checkbox-${item.id}-${i}`}>
              <Grid item md="auto">
                <FormControlLabel sx={{ paddingLeft: "5px" }} value={item.id} control={<Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 16
                    },
                  }} />}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="describe_text">{item.type === 'paypal' ? item.payment_data.recipientEmail : item.payment_data?.accountNumber}</Typography>
              </Grid>
              <Grid item md={4} textAlign="right">
                <Typography variant="describe_text">{item.payment_data?.currency}</Typography>
              </Grid>
              <Grid item md={1} textAlign="right">
                <IconButton onClick={() => onEditHandler(item.id)}>
                  <EditIcon color="icons" />
                </IconButton>
              </Grid>
              <Grid item md={1} textAlign="right">
                <IconButton onClick={() => onDeleteHandler(item.id)}>
                  <DeleteIcon color="icons" />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </RadioGroup>
        <Grid container className={styles["payment-box-info"]} onClick={openFormHandler}>
          <Grid item xs={10} md={6}>
            <Typography variant="describe_text">Add Payment Method</Typography>
          </Grid>
          <Grid item xs={2} md={1} textAlign="right">
            <IconButton >
              <AddCardIcon color="icons" />
            </IconButton>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={setIsClosed}
        >
          Cancel
        </Button>
      </DialogActions>
      <DeletePaymentDialog isOpen={isOpen} setOpen={setIsOpen} handleNewCard={openFormHandler} />
    </>
  )
}

export default PaymentsList;
