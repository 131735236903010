import {
    Grid,
    Divider,
} from "@mui/material";
import EmailMenuBar from "./components/EmailMenuBar";
import EmailTable from "./components/EmailTable";
import EmailTableToolBar from "./components/EmailTable/EmailToolBar";
import {useSelector} from "react-redux";
import {ThreeDotsLoading} from "components/loadings/BrandLoading";

const EmailView = () => {

    const requiredLoading = useSelector(state => state.email.loading);

    if(requiredLoading) {
        return  <ThreeDotsLoading topOffset={-170} isLoading={true}/>;
    }

    return (
        <Grid container rowSpacing={6}>
            <Grid item
                  md={12}
                  sm={12}
                // sx={{display: "flex", alignItems: "center"}}

            >
                <EmailMenuBar/>
            </Grid>
            <Grid item xs={12} md={12}>
                <EmailTableToolBar/>
            </Grid>
            <Grid item xs={12} md={12} paddingTop="0 !important">
                <Divider py={0}/>
            </Grid>
            <Grid item xs={12} md={12}  paddingTop="5px !important">
                <EmailTable/>
            </Grid>
        </Grid>
    );
};

export default EmailView;
