import {memo, useEffect, useRef, useState} from "react";
import {Box, Icon, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import styles from "./show-more.module.css"
import FlexWrapper from "../../wrappers/FlexWrapper";
import classnames from "classnames";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

const MAX_HEIGHT = 300;
const ShowMore = ({children}) => {

    const [show, setShowState] = useState('idle');


    const itemRef = useRef(null);

    const handleViewMore = () => {
        setShowState(true);
    }

    const handleViewLess = () => {
        setShowState(true);
    }

    const handleViewToggle = (e) => {
        e.preventDefault();
        setShowState(prev => !prev);
    }


    useEffect(() => {
        if (itemRef.current) {
            const itemHeight = itemRef.current.offsetHeight;

            if (itemHeight > MAX_HEIGHT) {
                setShowState(false);
            }

        }
    }, []);


    return (
        <Box ref={itemRef} sx={{maxHeight: show ? '100%' : MAX_HEIGHT}}
             className={show ? styles['container-expanded'] : styles['container']}>
            <Box className={styles['inner-box']} sx={{maxHeight: show ? '100%' : MAX_HEIGHT}}>
                {children}
            </Box>


            {show !== 'idle' && <FlexWrapper
                styles={{
                    width: '100%',
                    position: show ? 'relative' : 'absolute',
                    bottom: 0
                }}>
                <div className={styles['bottom-shadow']}></div>
                <IconButton     size="small"
                                variant="text"
                                onClick={handleViewToggle}
                                className={styles['toggle-button']}>
                    {show ? <KeyboardArrowUpRoundedIcon/> : <KeyboardArrowDownRoundedIcon/>}
                </IconButton>
                {/*<Button*/}
                {/*    size="small"*/}
                {/*    variant="text"*/}
                {/*    onClick={handleViewToggle}*/}
                {/*    className={styles['toggle-button']}*/}
                {/*>*/}
                {/*    {show ? 'Less' : 'More'}*/}
                {/*</Button>*/}
            </FlexWrapper>}

        </Box>
    )


}

export default memo(ShowMore);