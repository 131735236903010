import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {
    Grid,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Skeleton, Box,
} from "@mui/material";
import { encodeData, decodeData } from "utils/formatContent";
import {autocompleteClasses} from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import classnames from 'classnames';
import api from "_services/api";
import {checkFormForErrors} from "utils/validateInputs";
import {useDispatch, useSelector} from "react-redux";
import {addModel, setSession, updateModel, getHintsList} from "redux/models";
import {useParams} from "react-router-dom";
import {Autocomplete, Divider} from "@mui/material";
import {presetData, customModelData} from "./presetData";
import CreateModelForm from '../CreateModelForm';
import styles from './createModels.module.css';
import ModelSaveWindow from "../ModelSaveWindow";
import controlledAPI from "_services/controlledApi";
import modelLabels from "mappings/languages/en/modelLabels.json";
import { setIsNewModel } from 'redux/models';

const initialState = {
    user_id: 0,
    name: {
        errMsg: "",
        isRequired: true,
    },
    role: {
        errMsg: "",
        isRequired: true,
    },
    goals: {
        errMsg: "",
        isRequired: true,
    },
    mini_description: {
      errMsg: "",
      isRequired: true,
    },
    rules: {
        errMsg: "",
        isRequired: false,
    },
    company: {
        errMsg: "",
        isRequired: false,
    },
    website: {
        errMsg: "",
        isRequired: false,
    },
    website: {
      errMsg: "",
      isRequired: false,
    },
    file: {
      errMsg: "",
      isRequired: false,
    }
};

const CreateModel = ({preselectedModel = null, generatedModels = null, stepBag}) => {
    const {id: modelId} = useParams();
    const preselectedModelId = preselectedModel?.id;
    const [file, setFile] = useState(null);
    const [hintsData, setHintsData] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDataSending, setDataSending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modelState, setModelState] = useState(initialState);
    const [modelObject, setModelObject] = useState({});
    const [modelDataIsChanged, setModelDataIsChanged] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [preset, setPreset] = useState(null);
    const [selectedPreset, setSelectedPreset] = useState(null);
    const [isSelectorChosen, setIsSelectorChosen] = useState(false);
    const [selectingNewModel, setSelectingNewModel] = useState(false);
    const formRef = useRef(null);
    const models = useSelector((state) => state.models.list);
    const hints = useSelector(state => state.models?.hints?.list?.current);
    const transformModelDataObj = (model) => ({ ...model, mini_description: model.description });

    useEffect(() => {
      const updatedHints = {};
      hints.map((item) => {
        const key = `hint${Math.floor(Math.random() * 1000000)}`;
        updatedHints[key] = item;
      });

      setHintsData(updatedHints);
    }, [hints]);

    const handlePresetChange = (preset) => {
        setModelState((prevState) => ({
            ...prevState,
            ...Object.fromEntries(
                Object.entries(preset).map(([key, val]) => [
                    key,
                    {...prevState[key], value: val},
                ])
            ),
        }));
        setIsSelectorChosen(true);
    };

    const setStep = (step, model = undefined) => {
        dispatch(setSession({model: model, step: modelLabels.labels.saveModelLoadingSteps[step] + '...'}));
    }

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleInputChange = useCallback((event) => {
      const {name, value} = event.target;
      setModelDataIsChanged(true);
      if (name.startsWith('hint')) {
        setHintsData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setModelState((prevState) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            value,
          },
        }));
      }
    }, []);

    const handleSuccess = useCallback((resp) => {
        // setDataSending(false);
        if (resp && resp.code < 400) {
            if (modelId) {
                // Fill modelObject.data with matching keys from modelState
                modelObject.data = Object.fromEntries(Object.entries(modelObject.data).map(([key, val]) => [key, modelState[key].value || ""]));
                modelObject.name = modelState.name.value;
                dispatch(updateModel({model: modelObject}));
            } else {
              // If the first created model
              if (models.length === 0) {
                dispatch(setIsNewModel({isNewModel: true}));
              }
              // dispatch(setIsNewModel({isNewModel: true}));
              // dispatch(addModel({model: resp.model, choosen: true}));
            }

            setStep('build_brain', resp.model ?? modelObject);
        } else {
            navigate('/models');
        }
    }, [modelId, dispatch, navigate, modelState, modelObject, modelObject.name, models.length]);

    const handleError = useCallback((error) => {
        setDataSending(false);
    }, [setDataSending]);

    function removeEmptyKeys(obj) {
      return Object.entries(obj).reduce((newObj, [key, value]) => {
        if (value !== "") {
          newObj[key] = value;
        }
        return newObj;
      }, {});
    }

    function transformObject(input) {
      const output = {};
      for (const key in input) {
          output[key] = {
              value: input[key],
              isRequired: false,
              errMessage: ''
          };
      }
      return output;
  }

    const handleSave = useCallback((e) => {
        e.preventDefault();

        const form = new FormData(e.currentTarget);
        // const hintsKeys = Object.keys(hintsData).filter(key => key.startsWith('hint'));
        const hintsKeys = Object.keys(hintsData);
        const hintsValues = Object.values(hintsData);
        const filteredHintsValues = hintsValues?.filter(item => item.length);
        const formHintsData = Object.fromEntries(hintsKeys.map(field => [field, form.get(field) || ""]));
        const updatedModelState = { ...modelState, ...transformObject(hintsData) };        
        const status = checkFormForErrors(form, updatedModelState);

        if (status.hasErrors) {
            console.log('error', status)
            setModelState((prevState) => ({
                ...prevState,
                ...Object.fromEntries(
                    Object.entries(status.updates).map(([key, update]) => [
                        key,
                        {
                            ...prevState[key],
                            errMsg: update.errMsg,
                        },
                    ])
                ),
            }));
        } else {
            setDataSending(true);
            setStep('thinking');
            const data = {
                name: form.get('name'),
                data: Object.fromEntries(['role', 'goals', 'rules', 'company', 'website'].map(field => [field, form.get(field) || ""])),
            };
            const avatarParams = new FormData();
            avatarParams.append('avatar', file);

            setStep('model_saving');

            const apiUrl = modelId ? `/model/${modelId}` : "/model";
            if (modelId) {
                controlledAPI({ignoreUser: true}).put(apiUrl, { ...data, miniDescription: form.get('mini_description') })
                  .then(async (res) => {
                    if (Boolean(file)) {
                      const params = new FormData();
                      params.append('avatar', file);
                      await controlledAPI({ignoreUser: true}).post(`/model/avatar/${modelId}`, params, {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        },
                      });
                    }
                    if (filteredHintsValues?.length) {
                      const params = {
                        modelId,
                        questions: filteredHintsValues.map(item => encodeData(item)),
                      }
                      await controlledAPI({ignoreUser: true}).put(`/model/questions`, params);
                    }
                    
                    handleSuccess(res);
                  })
                  .catch(handleError);
            } else {
                controlledAPI({ignoreUser: true}).post(apiUrl, data)
                  .then(async (res) => {
                    const { id } = res.model;

                    if (Boolean(file)) {
                      const params = new FormData();
                      params.append('avatar', file);
                      await controlledAPI({ignoreUser: true}).post(`/model/avatar/${id}`, params, {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        },
                      });
                    }
                    if (filteredHintsValues?.length) {
                      const params = {
                        modelId,
                        questions: filteredHintsValues.map(item => encodeData(item)),
                      }
                      await controlledAPI({ignoreUser: true}).put(`/model/questions`, params);
                    }
                    await controlledAPI({ignoreUser: true}).put(`/model/${id}`, { miniDescription: form.get('mini_description')});
                    handleSuccess(res);
                  })
                  .catch(handleError);
            }

            // Scroll to the form after the selector is chosen and the form is visible
            if (isSelectorChosen && formRef.current) {
                formRef.current.scrollIntoView({behavior: "smooth"});
            }
        }

    }, [modelId, handleSuccess, handleError, modelState, isSelectorChosen, file, hintsData]);

    const customSort = (data) => {
        data.sort((a, b) => {
            const nameA = a.name?.toLowerCase();
            const nameB = b.name?.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });

        return [customModelData, ...data];
    }

    const sortedPresetData = useMemo(() => customSort(generatedModels ? [...generatedModels, ...presetData] : presetData), []);

    const clearModelData = () => {
        setIsSelectorChosen(false);
        setPreset(null);
        setSelectedPreset(null);
        setModelDataIsChanged(false);
        if (generatedModels) {
            setModelState(initialState);
        }
    }

    useEffect(() => {
        if (preselectedModelId) {
            setPreset(transformModelDataObj(preselectedModel));
            handlePresetChange(transformModelDataObj(preselectedModel));
        }
    }, []);

    useEffect(() => {
        if (modelId) {
          // Fetch the model data
          setIsLoading(true);
          api
            .get(`/model/${modelId}`)
            .then((response) => {
                setModelObject(response.model);
                const modelData = response.model;
                // Copy the name of the model to the data object
                modelData.data.name = modelData.name;
                // Set the state with the model data
                setModelState(prevState => ({
                    ...prevState,
                    ...Object.fromEntries(['name', 'role', 'goals', 'rules', 'company', 'website', 'mini_description'].map(field => {
                      
                      return [field, {
                        ...prevState[field],
                        value: modelData.data[field] || modelData[field]
                      }]
                    }))
                }));

                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching model data:", error);
                setIsLoading(false);
            });
            dispatch(getHintsList({modelId: modelId}));
        } else {

            return () => {
                setModelState(initialState);
            };
        }
    }, [modelId]);

    const onClearHandler = () => {
        const selectedId = modelState.id.value;
        setSelectingNewModel(false);
        if (!selectedId) return;

        // Creating a new array with empty custom name by default
        const updatedPresetData = sortedPresetData.map(item => {
            if (item.id === 17) {
                return {...item, name: ''};
            }

            return item;
        });
        const initialInfo = updatedPresetData.filter(item => item.id === selectedId)[0];
        if (
            initialInfo.name !== modelState.name?.value
            || initialInfo.role !== modelState.role?.value
            || initialInfo.goals !== modelState.goals?.value
            || initialInfo.rules !== modelState.rules?.value
        ) {
            setModelDataIsChanged(true);
        }

        if (modelDataIsChanged) {
            setIsDialogOpen(true);
        } else {
            clearModelData();
        }
    }

    const onSelectNewModel = (newValue) => {
        if (!newValue) return;

        const isCustom = newValue?.name?.toLowerCase() === 'custom';
        setPreset(transformModelDataObj(newValue));
        setModelDataIsChanged(false);

        if (isCustom) {
            handlePresetChange({...newValue, name: '', mini_description: ''});
        } else {
            handlePresetChange({ ...newValue, mini_description: newValue.mini_description || newValue.description });
        }
        setSelectingNewModel(false);
    }

    const onConfirmHandler = () => {
        if (selectedPreset) {
            onSelectNewModel(selectedPreset);
        }

        handleDialogClose();
        if (!selectingNewModel) {
            clearModelData();
        }
    }

    if (isLoading) {
        return (
            <Grid
                sx={{width: '100%'}}
                xs={12}
                md={12}
                padding={5}
            >
                {[...Array(4)].map(item => <Skeleton variant="text" animation="wave"/>)}
            </Grid>
        );
    };


    if (isDataSending) {
      return <ModelSaveWindow stepBag={stepBag} />
    }

    return (
        <>
            <Grid
                container
                rowSpacing={4}
                item
                xs={12}
                md={12}
                padding={5}
                sx={{margin: "auto"}}
                className={styles.presetsHelperArea}
            >
                <Grid item xs={12} md={12}>
                    <Typography variant="tool_header">{modelId ? "Edit" : "Create New"} Model</Typography>
                </Grid>
            </Grid>

            {!modelId && (
                <Grid
                    container
                    rowSpacing={4}
                    item
                    xs={12}
                    md={12}
                    padding={5}
                    sx={{margin: "auto"}}
                    className={styles.presetsHelperArea}
                >
                    <Grid item xs={12} md={12}>
                        <Typography variant="tool_subheader">
                          Select from the drop-down menu AI preset settings for your model:
                        </Typography>
                    </Grid>
                    <Divider sx={{margin: '20px 0'}}/>
                    <Grid item xs={12} md={12} className={styles.presetsHelperSelector}>
                        <Autocomplete
                            className={styles.presetsSelector}
                            value={preset}
                            onChange={(event, newValue) => {
                                if (!newValue) return;

                                if (modelDataIsChanged) {
                                    setSelectingNewModel(true);
                                    setSelectedPreset(transformModelDataObj(newValue));
                                    setIsDialogOpen(true);
                                } else {
                                    onSelectNewModel(newValue);
                                }
                            }}
                            popupIcon={<SearchIcon/>}
                            sx={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                    transform: 'none',
                                    padding: '4px',
                                }
                            }}
                            options={sortedPresetData}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => {
                                const isFirstItem = option === sortedPresetData[0];

                                return (
                                    <li style={{borderBottom: isFirstItem ? '1px solid rgba(0,0,0, .08)' : 'none'}} {...props}>
                                        {isFirstItem ? (
                                            <span className={styles.icon}>+</span>
                                        ) : null}
                                        <span className={classnames(isFirstItem && styles.firstText)}>
                      {option.name}
                    </span>
                                    </li>
                                )
                            }}

                            clearIcon={<Box style={{lineHeight: '0'}} onClick={onClearHandler}><CloseIcon/></Box>}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder="Start typing"
                                           label="Choose a preset"/>
                            )}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid
                container
                rowSpacing={4}
                item
                xs={9}
                md={10}
                component="form"
                onSubmit={handleSave}
                noValidate
                sx={{margin: 'auto'}}
                ref={formRef}
            >
                {(isSelectorChosen || modelId || preselectedModelId) &&
                  <CreateModelForm
                    modelDataIsChanged={modelDataIsChanged}
                    modelState={modelState}
                    setModelState={setModelState}
                    handleInputChange={handleInputChange}
                    setFileHandler={val => setFile(val)}
                    file={file}
                    deleteHintHandler={(id) => {
                      const hintsDataCopy = { ...hintsData };
                      delete hintsDataCopy[id];
                      setHintsData(hintsDataCopy);
                    }}
                    addHintHandler={() => {
                      setHintsData({ ...hintsData, [`hint${Math.floor(Math.random() * 1000000)}`]: '' });
                    }}
                    hintsData={hintsData}
                  />}
            </Grid>
            {/* Comfirmation dialog */}
            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {selectingNewModel ?
                            `Are you sure you want to change this model's data? This action cannot be undone.`
                            : `Are you sure you want to delete this model's data? This action cannot be undone.`
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={onConfirmHandler}>
                        {selectingNewModel ?
                            `Confirm Change`
                            : `Confirm Delete`
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
};

export default CreateModel;
