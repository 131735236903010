import Monetization from "pages/protected/monetization";
import InvoicesManager from "pages/protected/monetization/components/InvoicesManager";
import SubscriberManager from "pages/protected/monetization/components/SubscriberManager";


const monetizationRoutes = [
  // { path: '/monetization', element: <Monetization /> },
  {
    element: <Monetization />,
    path: "/monetization",
    children: [
      {
        path: "",
        element: <InvoicesManager />
      },
      {
        path: "/monetization/invoices",
        element: <InvoicesManager />
      },
      {
        path: "/monetization/subs",
        element: <SubscriberManager />
      },
      
    ]
  },
];

export default monetizationRoutes;