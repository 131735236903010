export const components = {

    MuiCssBaseline: {
        styleOverrides: {
            '*': {
                boxSizing: 'border-box',
            },
            html: {
                MozOsxFontSmoothing: 'grayscale',
                WebkitFontSmoothing: 'antialiased',
                height: '100%',
                width: '100%'
            },
            body: {
                height: '100%',
            },
            a: {
                textDecoration: 'none',
                color: 'inherit',
            },
            '#root': {
                //  minHeight: '100%',
                height: '100%',
            },
            //  svg: {
            //   width: '25px',
            //   fontSize: '25px',
            //   height: '25px'
            //  }
        },
    },
}
