import { useCallback, memo } from "react";
import { Collapse, Fade, Paper } from "@mui/material";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import SearchList from "./components/SearchList";

const FileSelectorPopup = ({ isOpen, setCommandPrompt, isFilesAllowed }) => {
  const { smallScreen } = useIsSmallScreen();

  const handleClickAway = useCallback((event) => {
    setCommandPrompt(false);
  }, [setCommandPrompt]);

  const handleSelectItem = (item) => {
    setCommandPrompt(item.hash);
  };

  if (smallScreen) {
    return (
      <Collapse in={isOpen} unmountOnExit sx={{ padding: '0 5%', boxShadow: 'rgba(0,0,0, .1) 0px -7px 12px -4px' }}>
        <SearchList smallScreen={smallScreen} handleSelectItem={handleSelectItem} isFilesAllowed={isFilesAllowed} />
      </Collapse>
    )
  }

  return (
    <Fade in={isOpen} unmountOnExit style={{ boxShadow: 'none', position: 'absolute', bottom: 0, right: 0, left: 0 }}>
      <Paper sx={{zIndex: 3}}><SearchList smallScreen={smallScreen} handleSelectItem={handleSelectItem} isFilesAllowed={isFilesAllowed} /></Paper>
    </Fade>
  );
};

export default memo(FileSelectorPopup, (prevProps, nextProps) => {
  return prevProps.modelId === nextProps.modelId && prevProps.isOpen === nextProps.isOpen;
});
