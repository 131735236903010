import {useLocation, useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useCallback} from "react";

const BackButton = ({levelUp = false}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBack = useCallback(() => {

        if (levelUp) {
            const pathArray = location.pathname.split('/');
            const newPath = pathArray.slice(0, -1).join('/') || '/';
            navigate(newPath);
            return
        }

        navigate(-1);
    }, [levelUp, location.pathname, navigate]);

    return (
        <IconButton onClick={handleBack} color="primary">
            <ArrowBackIcon/>
        </IconButton>
    );
};

export default BackButton;
