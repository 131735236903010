import { Box } from "@mui/material";
import InvoiceItem from "./InvoiceItem";
import InvoiceLoading from "./InvoiceLoading";
import styles from "../invoices-table.module.css";
import { memo } from "react";
import InvoiceSmallItem from "./InvoiceSmallItem";
import InvoiceSmallLoading from "./InvoiceSmallLoading";

const LOADING_PRESET_NUM = 5;


const Loading = () => {
  return (

    [...Array(LOADING_PRESET_NUM)].map((_, i) => (
      <InvoiceLoading key={`invoice-loading-${i}`} />
    ))
  )
};

const LoadingSmall = () => {
  return (

    [...Array(LOADING_PRESET_NUM)].map((_, i) => (
      <InvoiceSmallLoading key={`invoice-loading-small-${i}`} />
    ))
  )
};

const Invoices = ({ list }) => {
  return (
    list.map((item) => (
      <InvoiceItem key={`invoices-${item.id}`} item={item} />
    ))
  )
};

const InvoicesSmall = ({ list }) => {
  return (
    list.map((item) => (
      <InvoiceSmallItem key={`invoices-small-${item.id}`} item={item} />
    ))
  )
};


const NoInvoicesLabel = () => {
  return (
    <Box className={styles.wrapper} py={4}>
      <div className={styles.commingSoon}>No invoices yet</div>
    </Box>
  )
}

export const NoInvoices = memo(NoInvoicesLabel);
export const InvoiceItems = memo(Invoices);
export const InvoicesSmallItems = memo(InvoicesSmall);
export const LoadingItems = memo(Loading);
export const LoadingSmallItems = memo(LoadingSmall);

