export const defaultPalette = {
  background: {
    default: '#f9fafb',
    overlay: 'rgba(0, 0, 0, 0.1)',
    paper: '#fff'
  },
  primary: {
    main: '#3976EF',
    light: '#FFFAFF', 
    dark: '#3976EF',
    200: '#A85FF5',
    800: '#1565c0',
  },
  // #5e35b1
  secondary: {
    main: '#705DFE',
    light: '#FCFCFC', 
    dark: '#705DFE',
    200: '#99baf8',
    800: '#6200ea',
  },
  accent: {
    main: '#ff9800',
    light: '#ffd54f',
    dark: '#f57c00',
  },
  icons: {
    main: '#7b8191',
    accent: '#ffcc00', 
  },
  smoothSecondary: {
    main: '#99baf8',
    gradient: 'linear-gradient(to right, #99baf8, #b5d4f8)', 
  },
  deleteIcon: {
    main: 'red',
    hover: 'darkred', 
  },
  brighterText: {
    main: '#bdbdbd',
    subtle: '#ccc', 
  },
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
};
