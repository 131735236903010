import {Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const TrainStatus = ({status = ""}) => {
    if (!status) {
        return <></>
    }
    let variant = status + "_status";
    return (
        <Typography variant={variant}>
            {status === 'pending' &&
                <CircularProgress color="inherit" sx={{position: 'relative', marginRight: '3px', top: '3px'}}
                                  size={14}/>}
            {status}
        </Typography>
    )
}
export default TrainStatus;