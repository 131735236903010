import { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Skeleton,
  TableContainer,
  CircularProgress
} from "@mui/material";
// import CircularProgress from '@material-ui/core/CircularProgress';
import api from "_services/api";
import controlledAPI from "_services/controlledApi";
import { useDispatch } from "react-redux";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { interactWithUser } from "redux/status";
import { deleteInvitation } from "redux/invitations";
import CancelIcon from '@mui/icons-material/Cancel';

const Row = ({ row }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteChat = () => {
    setDeleteLoading(true);
    api
      .delete(`model/invite/delete/${row.id}`)
      .then((res) => {
        dispatch(deleteInvitation({id: row.id}));
        setDeleteLoading(false);
        dispatch(
          interactWithUser({
            message: "Invintation was revoked.",
          })
        );
      })
      .catch((error) => {
        setDeleteLoading(false);
      });
  };

  const resendInvitation = async () => {
    setResendLoading(true);
    const response = await controlledAPI({ ignoreUser: true }).post('model/invite/sent', {
      modelId: row.model,
      recipientEmails: [ row.recipient_email ],
    });

    if (response.code >= 400) {
      const message = response?.message || "Something went wrong";

      dispatch(
        interactWithUser({
          message,
          type: 'error',
        })
      );
      setResendLoading(false);
      return;
    }

    setResendLoading(false);
    dispatch(
      interactWithUser({
        message: "The invitation has been successfully resent.",
      })
    );
  }

  return (
    <>
      <TableRow>
        <TableCell sx={{ 
          padding: '4px' ,
          textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: { xs: '100px', md: '100%' },
            whiteSpace: 'nowrap',
        }}>
          <Typography
            sx={{ paddingLeft: "10px", 
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: { xs: '100px', md: '100%' },
            whiteSpace: 'nowrap',
          }}
            variant="div"
          >
            {row.recipient_email}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: '4px' }}>
          <Typography
            sx={{ paddingLeft: "10px" }}
            variant="div"
          >
            {row.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: '4px' }}>
          <Box sx={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'right' }}>
            {row.status === 'sent' && (
              <Tooltip title="Resend invitation">
                <IconButton onClick={resendInvitation}>
                  {resendLoading ? <CircularProgress size={20} sx={{ margin: '0 2px' }} /> : <ForwardToInboxIcon size={20} />}
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Cancel invitation">
              <IconButton size="sm" variant="contained" color="error" onClick={handleDeleteChat}>
                {deleteLoading ? <CircularProgress size={20} sx={{ margin: '0 2px' }} /> : <CancelIcon size={20} />}
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
