import MessageChunkSkeleton from "components/loadings/templates/MessagesChunkSkeleton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getByIds } from "redux/messages";


const RELOAD_TIMEOUT_MS = 60000;

const MessageProcessing = ({status, msgId = 0, chatId = 0}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        // Function to make the server request
        const fetchDataFromServer = async () => {
            dispatch(getByIds({messageIds: [msgId], chatId: chatId }));
        };
    
        if(status === "processing") {
    
            const intervalId = setInterval(() => {
              fetchDataFromServer();
            }, RELOAD_TIMEOUT_MS); 

            return () => clearInterval(intervalId);
        }
        
    }, [status]);


    return <MessageChunkSkeleton loading={true}/>
}


export default MessageProcessing;