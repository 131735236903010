import {
    Box,
    Divider, IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Skeleton,
    TextField,
    Typography
} from "@mui/material";
import {useCallback, useMemo, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import GeneralTooltip from "../../../../tooltip/GeneralTooltip";
import SupportIconTooltip from "../../../../tooltip/SupportIconTooltip";
import ActivateToggle from "./ActivateToggle";
import styles from '../tools.module.css'

const codeMapToText = {}

const desktopStyling = {
    borderRadius: '4px',
    boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, ' +
        'rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, ' +
        'rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
};


const ToolsList = ({modelId, smallScreen = '', tools = [], isLoading = false}) => {


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value.toLowerCase());
    }, []);

    const filteredItems = useMemo(() =>
            tools.filter(item => item.function_name.toLowerCase().includes(searchTerm)),
        [tools, searchTerm]
    );


    return (
        <List sx={smallScreen ? {} : desktopStyling}>
            <ListItem
                secondaryAction={<Typography sx={{
                    padding: "4px 10px",
                    backgroundColor: "#eee",
                    borderRadius: "14px",
                    opacity: 0.8,
                    position: "relative",
                    right: "-6px",
                }} variant="describe_text">/</Typography>}>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    size="small"
                    // margin="normal"
                    onChange={handleSearchChange}
                />
            </ListItem>
            <Box className={styles.wrapper}>

                {isLoading ? (
                    [...Array(2)].map((_, i) => (
                        <Skeleton key={i} variant="text" animation="wave" sx={{margin: '0 10px'}}/>
                    ))
                ) : filteredItems.length === 0 ? (
                    <ListItem>
                        <ListItemText primary={<Typography variant="describe_text">0 options found</Typography>}/>
                    </ListItem>
                ) : (
                    filteredItems.map((item) => {
                      return (
                        <ListItem
                            key={`cstm-tool-${item.id}`}
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                    <SupportIconTooltip title={item.function_description}/>
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton sx={{cursor: 'unset'}} dense>
                                {/*<ListItemIcon>*/}
                                    <ActivateToggle id={item.id} modelId={modelId} status={item.isActive}/>
                                {/*</ListItemIcon>*/}
                                <ListItemText
                                    primary={item.function_name.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
                                />
                            </ListItemButton>
                        </ListItem>
                    )})
                )}
                {!isLoading && (
                  <ListItem
                    sx={{ cursor: 'pointer', borderTop: '1px solid rgba(0,0,0, .1)' }}
                    disablePadding
                  >
                    <ListItemButton href={`/portal/custom-api`} sx={{textAlign: 'center'}}>
                      {/*<ListItemIcon>*/}
                          {/* <ActivateToggle id={item.id} modelId={modelId} status={item.isActive}/> */}
                      {/*</ListItemIcon>*/}
                      <ListItemText
                        primary="+ Create Custom Tool"
                      />
                    </ListItemButton>
                  </ListItem>
                )}
            </Box>
        </List>
    )
}

export default ToolsList;