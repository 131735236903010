import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api/index.js';
import controlledAPI from "_services/controlledApi";

export const getListThunk = createAsyncThunk('models/list', async (payload, thunkAPI) => {
  const modelId = payload?.modelId;
  try {
    const response = await api.get('/model/list');
    const models =  response.models ? response.models : [];
    
    // if(!user || response.code >= 400) {
    //     throw new Error(response);
    // }

    return { models, modelId: modelId || null };
  } catch(e) {
    const message = e?.response?.message || e?.message || 'Something went wrong';
    return { message };
    // return thunkAPI.rejectWithValue(message);
  }
});

export const getInvitationsListThunk = createAsyncThunk('models/invitationsList', async (_, thunkAPI) => {
  try {
    const response = await api.get('/model/invite/my/list');
    const models =  response.shared_model_invites ? response.shared_model_invites : [];

    return { models };
  } catch(e) {
    const message = e?.response?.message || e?.message || 'Something went wrong';
    return { message };
  }
});

export const updateModelSettingsThunk = createAsyncThunk('models/updateModelSettings', async (payload, thunkAPI) => {
  try {
    const response = await controlledAPI({ ignoreUser: true }).put('/model/settings/update', payload);

    if (response.code >= 400 || response.status >= 400) {
      throw new Error("Could not get server info");
    }

    return { settings: response.model_settings };
  } catch(e) {

    const message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const getSubscriptionsThunk = createAsyncThunk('models/getSubscriptions', async (payload, thunkAPI) => {
  try {
    const response = await api.get('/user-model/subscribe/list');

    if (response.code >= 400) {
      throw new Error("Could not get server info");
    }

    return { list: response.items };
  } catch(e) {
    const message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const unsubscribeThunk = createAsyncThunk('billing/unsubscribe', async (payload, thunkAPI) => {
  try {
    const response = await api.get(`/user-model/unsubscribe/${payload.modelId}`);

    if (response.code >= 400) {
      throw new Error(response);
    }

    return { modelId: payload.modelId };
    
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const generateHintsThunk = createAsyncThunk('models/hints/generate', async (payload, thunkAPI) => {
  try {
    const response = await api.post('/model/questions/generate', payload);
    const hints =  response.items ?? [];


    return { hints };
  } catch(e) {
    const message = e?.response?.message || e?.message || 'Something went wrong';
    return { message };
    // return thunkAPI.rejectWithValue(message);
  }
});

export const unfollowThunk = createAsyncThunk('models/unfollow', async (payload, thunkAPI) => {
  const { modelId } = payload;

  try {
    const response = await api.post('/model/share/unfollow', { modelId });

    if(response.code >= 400) {
      throw new Error(response);
    }

    return { modelId: payload.modelId };
  } catch(e) {
      let message = e?.response?.message || e?.message || 'We were not able to process the invitation';
      return thunkAPI.rejectWithValue({ message });
      // return thunkAPI.rejectWithValue(message);
  }
});
export const getHintsListThunk = createAsyncThunk('models/hints/list', async (payload, thunkAPI) => {
  try {

    const {modelId, signal} = payload;

    const response = await api.post('/model/questions/list',{modelId}, {signal: signal});
    const hints =  response.items ?? [];

    // if(!user || response.code >= 400) {
    //     throw new Error(response);
    // }

    return {modelId, hints };
  } catch(e) {
    const message = e?.response?.message || e?.message || 'Something went wrong';
    // return { hintsmessage };
    return thunkAPI.rejectWithValue(message);
  }
});
