import QrCode from "../QrCode";
import Grid from "@mui/material/Grid";
import ShareModel from "pages/ShareModel";
import {Typography} from "@mui/material";
import BrowserWindow from "pages/protected/ShareModel/components/BrowserWindow";

const Preview = ({ manualHash = '' }) => {
    const url = manualHash?.length ? `younet.ai/p/${manualHash}` : null;
    return (
        <Grid sx={{width: '100%', height: '100%', flexFlow: 'column'}} container spacing={2}>
            <Grid item xs={12} flex="auto !important">
                <Typography sx={{fontSize: '24px', padding: {xs: '0 0 20px 10px', md: 0}}}>
                    Preview
                </Typography>
            </Grid>
            <Grid item xs={12}>
              <BrowserWindow url={url}>
                <ShareModel manualHash={manualHash} hideSideBar={true} preview={true} />
              </BrowserWindow>
            </Grid>
        </Grid>

    )
}

export default Preview;