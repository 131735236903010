import { useState } from "react";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";

const EditingDialog = ({ isEditing, editingItem, handleEditorChange, saveChanges, setIsEditing }) => {
    const [editiingValue, setEditingValue] = useState(editingItem.text);

    const clickedSave = () => {
      handleEditorChange(editiingValue);
      saveChanges(editiingValue);
    };


    return (
      <Dialog open={isEditing} maxWidth='lg'>
        <DialogTitle sx={{ m: 0, p: 4 }}>Edit {editingItem.title}</DialogTitle>
        <DialogContent dividers>
          <CustomMdEditor 
            value={editingItem.text} 
            onChange={setEditingValue}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={clickedSave}
          >
            Save
          </Button>
          <Button
            autoFocus
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default EditingDialog;
  