import FlexWrapper from "components/wrappers/FlexWrapper";
import {Box} from "@mui/material";
import {TrainBtn} from "./TrainBtn";
import Typography from "@mui/material/Typography";
import {approveOrDisapproveById} from "redux/links/linksThunk";
import {useDispatch, useSelector} from "react-redux";
import {selectAll, unselectAll} from "redux/links";
import SimpleCheckbox from "components/inputs/checkboxes/SimpleCheckbox";

const SelectedTraining = ({selectedNum = 0, selectedIds = {}}) => {

    const dispatch = useDispatch();
    const isAllSelected = useSelector(state => state.links.content.sort.maps.isAllSelected);
    const sortId = useSelector(state => state.links.content.sort);

    const handleTrain = async (isApproved = true) => {

        if(sortId === 'trained') {


            return
        }

        await dispatch(approveOrDisapproveById({isApproved: isApproved, pageOrSite: Object.keys(selectedIds)}));
        dispatch(unselectAll());
    }

    const handleItemCheckboxClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const isChecked = e.target?.checked;

        if(isAllSelected) {
            dispatch(unselectAll());

        } else {
            dispatch(selectAll());
        }


    }
    return (
        <FlexWrapper position="space-between">
            <Box
                // sx={{paddingLeft: '4 px'}}
            >
                <FlexWrapper position="space-between" styles={{marginLeft: '12px'}}>
                    <SimpleCheckbox checked={isAllSelected} onClick={handleItemCheckboxClick} edge="start" size="small"/>
                    <Typography sx={{fontSize: '11px'}} color="primary">{selectedNum} selected</Typography>

                </FlexWrapper>

            </Box>
            <Box>
                <TrainBtn handleTrain={handleTrain}/>
            </Box>
        </FlexWrapper>
    )
}

export default SelectedTraining;