import Balance from "pages/protected/billing/Balance";
import Payment from "pages/protected/billing/Payment";
import AddPaymentForm from "pages/protected/billing/Payment/components/AddPaymentForm";
import UpgradePlan from "pages/protected/billing/UpgradePlan";
import AllPayments from "pages/protected/billing/Payment/components/AllPayments";
import Stripe from "_lib/Stripe";


const billingRoutes = [
  { path: '/billing/balance', element: <Balance /> },
  {
    element: <Payment />,
    path: "/billing/payment",
    children: [
      {
        path: "all",
        element: <AllPayments />,
      },
      // {
      //   path: "add",
      //   element: <Stripe isForm={true}><AddPaymentForm /></Stripe>,
      // }
      // {
      //   path: "checkout/:paymentId",
      //   element: <Stripe><CheckoutSession /></Stripe>,
      // }
    ]
  },
  {
    path: "/billing/subscribe",
    element: <UpgradePlan />,
  },
  {
    path: "/billing/subscribe/:potentialPlanId",
    element: <UpgradePlan />,
  },
  // { path: '/billing/subscribe', element: <Subscribe /> }
];

export default billingRoutes;