import {Checkbox, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import {memo, useCallback, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from '@mui/icons-material/Done';
import controlledAPI from "_services/controlledApi";
import {updateModel} from "redux/models";
import CircularProgress from "@mui/material/CircularProgress";
import WebsiteDomain from "./components/WebsiteDomain";
import StyleCustomizations from "./components/StyleCustomizations";
import EmbededCode from "./components/EmbeddedCode";


const ChatBot = ({hideFromPublic, isPublic}) => {

    const [previewData, setPreviewData] = useState({});

    const handleChanges = useCallback((data) => {
        setPreviewData(prev => ({...prev, ...data}));
    }, [setPreviewData])

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} >
                <Typography sx={{fontSize: '24px'}}>
                    {isPublic ? 'Edit' : 'Create'} Website Chatbot
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <WebsiteDomain/>
            </Grid>
            <Grid item xs={12}>
                <StyleCustomizations handleChanges={handleChanges}/>
            </Grid>

            {
                isPublic &&
                <Grid item xs={12}>
                    <EmbededCode previewData={previewData}/>
                </Grid>
            }

        </Grid>
    )
}

export default memo(ChatBot);