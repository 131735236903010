import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {
  Box,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Skeleton,
  Typography,
} from "@mui/material";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import SearchInput from './components/SearchInput';
import { getSharedCards } from 'redux/marketplace';
import Wrapper from './components/Wrapper';
import ListItem from './components/ListItem';
import {sendUIEvent} from "../../../events/analytics/google";

const SharedModels = ({ widesidebar, onSelect, leftBarMenu, leftOffset = false, defaultOppened = true, changeUrl = false }) => {
  const { smallScreen } = useIsSmallScreen();
  const [modelsMenuOpen, setModelsMenuOpen] = useState(!smallScreen && defaultOppened);
  const [searchValue, setSearchValue] = useState('');
  const sharedCards = useSelector((state) => state.marketplace.sharedCards, shallowEqual);
  const marketplaceLoading = useSelector((state) => state.marketplace.loading, shallowEqual);
  const dispatch = useDispatch();

  const onSubmitHandler = (value, loadMore = false) => {
    setSearchValue(value);
    // dispatch(getSharedCards({ query: value, page: 1, pages: sharedCards.pages || null, loadMore, count: pages: sharedCards. }));
    dispatch(getSharedCards({ query: value, loadMore, ...sharedCards }));
  }

  return (
    <>
      <ListItemButton
        sx={{ pl: 6 }}
        onClick={() => {
          setModelsMenuOpen(!modelsMenuOpen);
          const label = modelsMenuOpen ? 'close' : 'open'
            sendUIEvent({name: `explore_ai_nav_click_${label}`});
        }}
      >
        <ListItemIcon sx={{minWidth: '40px', paddingLeft: leftOffset ? '5px' : 0, color: 'rgba(0, 0, 0, 0.54)' }}>
          <DashboardCustomizeIcon width={20} height={20} />
        </ListItemIcon>
        {widesidebar && (
          <>
            <ListItemText primary={<Typography sx={{ fontSize: { xs: '1rem', md: '0.875rem' } }}>Explore AIs</Typography>} />
            {modelsMenuOpen ? (
              <ExpandLessIcon fontSize="small" />
            ) : (
              <ExpandMoreIcon fontSize="small"/>
            )}
          </>
        )}
      </ListItemButton>
      <Wrapper
        widesidebar={widesidebar}
        modelsMenuOpen={modelsMenuOpen}
        onCloseHandler={() => setModelsMenuOpen(false)}
        loadMoreHandler={() => {
          const { page, pages } = sharedCards;
          onSubmitHandler(searchValue, true);
        }}
        // loadMoreDisabled={sharedCards?.pages === limit / 10 && !Boolean(searchValue?.length)}
        loadMoreDisabled={sharedCards?.allPage === sharedCards?.allPages && Boolean(searchValue?.length)}
        leftBarMenu={leftBarMenu}
      >
        <SearchInput widesidebar={widesidebar} onSubmitHandler={onSubmitHandler} />
        {marketplaceLoading ? (
          <Box px={6}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
          </Box>
        ) : (
          <>
          {/* TODO: remove filtering */}
            {sharedCards?.pinnedItems?.filter(item => Boolean(item?.settings?.public_listed_on_marketplace) || Boolean(item?.settings?.listed_on_marketplace))?.map((item, i) => {
              return <ListItem
                      key={`pinnedItems-${item.id}`}
                      item={item}
                      widesidebar={widesidebar}
                      onSelect={onSelect}
                      leftBarMenu={leftBarMenu}
                      setModelsMenuOpen={setModelsMenuOpen}
                      changeUrl={changeUrl}
                    />
            })}
            {/* TODO: remove filtering */}
             {sharedCards?.allItems?.filter(item => Boolean(item?.settings?.public_listed_on_marketplace) || Boolean(item?.settings?.listed_on_marketplace))?.map((item, i) => {
              return <ListItem
                      key={`allItems-${item.id}`}
                      item={item}
                      widesidebar={widesidebar}
                      onSelect={onSelect}
                      leftBarMenu={leftBarMenu}
                      setModelsMenuOpen={setModelsMenuOpen}
                      changeUrl={changeUrl}
                    />
            })}
          </>
        )}
      </Wrapper>
    </>
  )
}

export default SharedModels;
