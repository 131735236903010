import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { interactWithUser } from "redux/status";
import api from "_services/api";
import ShadowSpinner from "components/loadings/ShadowSpinner";

const UnSubscribeNewsLetter = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = params.verificationToken || null;
  useEffect(() => {

    if (token) {
      api
        .get(`users/unsubscribe/token/${token}`)
        .then((r) => {
          if (r.code >= 400) {
            dispatch(interactWithUser({ message:"Oops! We encountered an issue while processing your unsubscription request. Please try again later, or contact our support team for assistance", type: "info" }));
            navigate("/login");
          } else {
           navigate("/playground");
           dispatch(interactWithUser({ message:"Your newsletter subscription has been canceled", type: "success" }));
          }
        })
        .catch((err) => {
          console.log(err)
          navigate("/login");
        });
    }

  }, []);

  return (
    <ShadowSpinner open={true}/>
  );
};

export default UnSubscribeNewsLetter;
