/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import ModelSettingForm from "./ModelSettingForm";
import {Grid,Typography}from '@mui/material';
import api from "_services/api";
import { interactWithUser } from "redux/status";
import { useDispatch, useSelector } from "react-redux";
import { updateModel } from "redux/models";
import AccessChecker from "components/security/AccessChecker";

const ModelSetting = () => {
        const models = useSelector(state => state.models);
        const dispatch = useDispatch();
        const model = useSelector(state => state.models.current);
        
        const [modelSetting, setModelSetting] = useState({
            gpt_model: model.settings && model.settings.gpt_model ? model.settings.gpt_model: null,
            contextWindowSizePercent: model.settings && model.settings.contextWindowSizePercent ?  model.settings.contextWindowSizePercent : null,
        });

    
        // if model settings are null, let's fetch global settings
        useEffect(() => {
            if ( modelSetting.gpt_model === null || modelSetting.contextWindowSizePercent === null) {
                api.get('/user/settings/current')
                    .then(response => {
                        if (response.code === 200) {
                            setModelSetting({
                                gpt_model: response.setting.gpt_model,
                                contextWindowSizePercent: response.setting.contextWindowSizePercent,
                            }
                            );
                        }
                    })
                    .catch(error => {
                        dispatch(
                            interactWithUser({
                                message: `Error fetching model settings: ${error}`,
                                type: "error",
                            })
                        );
                    });
            }
        }, []);
    
        const handleGeneralSave = (e) => {
            e.preventDefault();
            const form = new FormData(e.currentTarget);
            let gptModel = form.get("gpt_model");
            // Parse the value as an integer
            let contextWindowSize = parseInt(form.get("contextWindowSizePercent"));
            // If the value is not a number, set it to 80
            contextWindowSize = isNaN(contextWindowSize) ? 80 : contextWindowSize;
    
            api.put('/model/settings/update', {
                modelId: model.id,
                gptModel: gptModel,
                contextWindowSize: contextWindowSize,
            })
            .then(response => {
                if (response.code === 200) {
                    const newModel = {
                        ...model,
                        settings: {
                            ...model.settings,
                            gpt_model: gptModel,
                            contextWindowSizePercent: contextWindowSize,
                        }
                    };
    
                    dispatch(updateModel({model:newModel}));
    
                    dispatch(
                        interactWithUser({
                            message: "Settings saved",
                            type: "success",
                        })
                    );
                }
            }
            )
            .catch(error => {
                dispatch(
                    interactWithUser({
                        message: `Error saving settings: ${error}`,
                        type: "error",
                    })
                );
            }
            );
        }
    
        const handleModelChange = (event) => {
            const { name, value } = event.target;
            setModelSetting((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    return (
        <AccessChecker currentPermissions={models.current.access} requiredPermissions={["write"]} fallbackComponent="locked">
            <Grid container sx={{px:3,py:6,overflowY:"auto"}}>
                <Typography px={2} sx={{fontSize:24}}>
                    '{models?.current?.name? models?.current?.name: "Model" }' Settings
                </Typography>
                <ModelSettingForm handleGeneralSave={handleGeneralSave} handleModelChange={handleModelChange} modelSetting={modelSetting}/>
            </Grid>
        </AccessChecker>
    );
}

export default ModelSetting;