import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useState} from "react";
import api from "_services/api";
// import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: "50px",
  height: "24px",
  padding: "0px",
  margin: '0 10px 0 0',
  '& .MuiSwitch-switchBase': {
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#23bf58"
      }
    }
  },
  '& .MuiSwitch-thumb': {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px",
  },
  '& .MuiSwitch-track': {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "9px",
      position: "absolute",
      top: "5px"
    },
    "&:after": {
      content: "'On'",
      left: "8px"
    },
    "&:before": {
      content: "'Off'",
      right: "7px"
    }
  },
  ".MuiSwitch-switchBase.Mui-checked": {
    color: "#23bf58 !important",
    transform: "translateX(26px) !important"
  },
}));



const handleStatus = async (id, modelId, isActive = 0, callback) => {
    let params = {
        functionId: id,
        modelId: modelId,
        isActive: isActive
    }

    try {
        const response = await api.post('custom-functions/status', params);

        if (response.code !== 200) {
            throw new Error('Could not update custom function status');
        }

        callback();

    } catch (e) {
        console.error(e.message);
    }
}


const ActivateToggle = ({id = 0, modelId, status = 0}) => {
    const [checked, setChecked] = useState(Boolean(status));
    const [isLoading, setLoading] = useState(false);


    const onToggle = async (e) => {
        e.preventDefault();

        const handleCallback = () => {
            setChecked(prev => !prev);
            setLoading(false);
        }

        const isActiveInt = status ? 0 : 1;


        setLoading(true)
        await handleStatus(id, modelId, isActiveInt, handleCallback);
    }

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '60px' }}>
          <CircularProgress size={14}/>
        </Box>
      )
    }

    return (
      <AntSwitch checked={checked} onChange={onToggle} />
    )
}

export default ActivateToggle;