import { useState } from 'react';
import { useDispatch } from 'react-redux';
import api from "_services/api";
import { interactWithUser } from "redux/status";
import { updateModel } from "redux/models";

const useModelSettingsUpdate = () => {
  const [isSettingsUpdateLoading, setIsSettingsUpdateLoading] = useState(false);

  const dispatch = useDispatch();

  const handleModelSettingsUpdate = (model, gptModel, contextWindowSize) => {
    setIsSettingsUpdateLoading(true);
    api.put('/model/settings/update', {
      modelId: model.id,
      gptModel,
      contextWindowSize,
    })
    .then(response => {
      if (response.code === 200) {
        const newModel = {
          ...model,
          settings: {
            ...model.settings,
            gpt_model: gptModel,
            contextWindowSizePercent: contextWindowSize,
          }
        };
        dispatch(updateModel({ model: newModel }));

        setIsSettingsUpdateLoading(false);
        dispatch(
          interactWithUser({
            message: "Settings saved",
            type: "success",
          })
        );
      }
    })
    .catch(error => {
      setIsSettingsUpdateLoading(false);
      dispatch(
        interactWithUser({
          message: `Error saving settings: ${error}`,
          type: "error",
        })
      );
    });
  };

  return { handleModelSettingsUpdate, isSettingsUpdateLoading };
};

export default useModelSettingsUpdate;
