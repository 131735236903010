import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { memo, useMemo } from 'react';

const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));


const ProgressBar = ({ className = "", percentage = false, maxValue = 0, currentValue = 0 }) => {

    const value = useMemo(() => {

        if(percentage) {
            return percentage;
        }

        let number = currentValue / maxValue || 0;

        let percent = number > 1 ? 100 : number * 100;

        return percent;

    }, [maxValue, currentValue])


    return <StyledProgressBar className={className} variant="determinate" value={value} />;
}




export default memo(ProgressBar, (prevProps, nextProps) => {
  return prevProps.percentage === nextProps.percentage && prevProps.maxValue === nextProps.maxValue && prevProps.currentValue === nextProps.currentValue;
});

