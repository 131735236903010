import {Grid, Link, Typography} from "@mui/material";
import GoogleAuthBtn from "components/auth/GoogleAuthBtn";
import useViewportHeight from "hooks/useViewportHeight";
import OutlookAuthBtn from "components/auth/OutlookAuthBtn";
import {ThreeDotsLoading} from "components/loadings/BrandLoading";
import { useSelector, shallowEqual } from "react-redux";

const EmailSignIn = ({loading}) => {
    const models = useSelector((state) => state.models, shallowEqual);
    
    const redirectUrlOutlook = `${window.location.origin}/portal/email/gmail`;
    const redirectUrlGmail = `${window.location.origin}/portal/email/gmail`;
    const viewportHeight = useViewportHeight();

    const googleOptions = {
        method: 'get',
        url: 'google/auth/url'
    }

    if (loading) {
        return <ThreeDotsLoading topOffset={-170} isLoading={true}/>;
    }

    return (
        <Grid
            sx={{flexGrow: 1}}
            pt={10}
            container
            spacing={5}
            alignItems={"center"}
            justifyContent={"space-around"}
            flexDirection={"column"}
            minHeight={`calc(${viewportHeight}px - 220px)`}
        >

            <Grid item xs={12} md={12}
                  container
                  spacing={5}
                  alignItems={"center"}
                  flexDirection={"column"}
            >
                <Grid item xs={12} md={8}>
                    <Typography variant="header" component="p" sx={{textAlign: "center"}}>
                        Connect an existing email address
                        {/* Currently unavailable */}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>

                    {/* uncomment once google verification was completed */}
                    <Typography variant="center_text" component="p">
                        Once you've successfully signed in, you can choose emails from the
                        list containing responses to train your Al model to answer in a style
                        that matches your own writing
                    </Typography>
                    {/* <Typography>
        Due to an unexpected surge in user activity, we're currently experiencing some technical difficulties with Gmail conversations training.<br/><br/> We're working hard to get it back up and running as soon as possible. In the meantime, we encourage you to explore other methods of training with Files, Text or Q&A.<br/><br/> We appreciate your patience and understanding. Stay tuned for updates!
        </Typography> */}
                </Grid>
                <Grid item xs={12} md={8}>
                    <GoogleAuthBtn fullWidth width={200} googleOptions={googleOptions} redirectUrl={redirectUrlGmail}
                                   text={'Sign in with Google'}/>
                </Grid>
                <Grid item xs={12} md={8}>
                    <OutlookAuthBtn fullWidth width={200} redirectUrl={redirectUrlOutlook}
                                    text={'Sign in with Microsoft'}/>
                </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography variant="center_text" component="p" fontSize={13}>
                    Use and transfer of information received from Google APIs to Younet will adhere to <Link
                    href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">Google API
                    Services User Data Policy</Link>, including the Limited Use requirements.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EmailSignIn;
