import { createPixel } from "_helpers/apiHandlers";
import { getToken, setToken } from "_helpers/tokenManagement";

export const shouldSendGenaiPixel = () => {
    const pixelSent = getToken("genaiPixelSent") || false;
    return !pixelSent;
};

export const sendPixelGenai = async (params = { afprice: false, once: false }) => {
    const { afprice, once } = params;

    if(process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
      return;
    }
    let url = 'https://genai.g2afse.com/success.jpg?offer_id=23&afgoal=2&afstatus=1';

    if (afprice) {
      url += `&afprice=${afprice}`;
    }

    const onLoad = () => {
      if (once) {
        setToken('genaiPixelSent', 'true');
      }
    };

    await createPixel(url, onLoad, undefined, {allowError: true});
};