import Box from "@mui/material/Box";
import ChatMenu from "../menus/helperMenu/ChatMenu";
import ParentMenu from "../menus/ParentMenu";
import TopBar from "../menus/TopBar";
import Grid from "@mui/material/Grid";
import styles from "./layout.module.css";
import Content from "components/ContentContainer";
import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useViewportHeight from "../../hooks/useViewportHeight";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Layout({ model, children }) {

  const location = useLocation()
  const viewportHeight = useViewportHeight();

  const modelId = useMemo(() => {
    return model?.id;
  }, [model?.id]);
  const [widesidebar, setWidesidebar] = useState(!Boolean(localStorage.getItem('smallMenu')));
  const [mobilebar, setMobilebar] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (location.pathname !== "/settings")
      localStorage.removeItem("activeAccountSettingTab");

  });

  const handleWidesidebarChange = (s) => {
    setWidesidebar(s);
  };
  const handleMobilebarChange = (s) => {
    setMobilebar(s);
  };

  

  const containerStyle = {
    height: `${viewportHeight}px`,
    overflow: "hidden",
  };
  return (
    <Grid container style={containerStyle}>
      {/*<Grid container>*/}

      {/*  <Grid item xs={12} className={styles["topbars"]} >*/}
          <TopBar
            mobilebar={mobilebar}
            mobilebarChange={handleMobilebarChange}
            smallScreen={isMobile}
            widesidebar={widesidebar}
            widesidebarChange={handleWidesidebarChange}
          />
      {/*  </Grid>*/}
      {/*</Grid>*/}
      <Grid
        container
        sx={{
          height: `calc(${viewportHeight}px - 64px)`,
          backgroundColor: { xs: "white", md: "#f9fafb" },
        }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: 0,
              md: widesidebar ? "auto" : "73px",
              lg: widesidebar ? "auto" : "73px",
            },
          }}
          xs={0}
          md={widesidebar ? 3.5 : "auto"}
          lg={widesidebar ? 2.5 : "auto"}
        >
          <ParentMenu
            mobilebar={mobilebar}
            mobilebarChange={handleMobilebarChange}
            smallScreen={isMobile}
            widesidebar={widesidebar}
            widesidebarChange={handleWidesidebarChange}
          />
        </Grid>
        <Grid
          item
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            flexGrow: 1,
            width: {
              md: widesidebar ? "auto" : "calc(100vw - 73px)",
              lg: widesidebar ? "auto" : "calc(100vw - 73px)",
            },
          }}
          xs={12}
          md={widesidebar ? 8.5 : "auto"}
          lg={widesidebar ? 9.5 : "auto"}
          className={styles["main-content"]}
        >
          {modelId ? <Content>{children}</Content> : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Layout;
