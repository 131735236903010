import {useEffect, useRef} from "react";
import {Box, Typography} from "@mui/material";
import {shallowEqual, useSelector} from "react-redux";
import GalleryContent from '../../../../../../../ShareModelMarketplace/components/GalleryContent';
import OverviewContent from '../../../../../../../ShareModelMarketplace/components/OverviewContent';
import TopContent from '../../../../../../../ShareModelMarketplace/components/TopContent';
import Grid from "@mui/material/Grid";
import BrowserWindow from "pages/protected/ShareModel/components/BrowserWindow";

const Preview = ({ monetizationState, files, marketplaceHash }) => {
  const auth = useSelector((state) => state.auth, shallowEqual);
  const chats = useSelector((state) => state.chats, shallowEqual);

  const combinedTopContentData = {
    title: monetizationState.title.value,
    mini_description: monetizationState.miniDescription.value,
    user: {
      avatar: auth.user.avatar,
      name: auth.user.fullName,
    },
    chat_count: chats.chats.count || 0,
  }


  const isEmpty = 
    !monetizationState.title.value
    && !monetizationState.miniDescription.value
    && !monetizationState.description.value
    && !monetizationState.price.value
    && !files.length;

  const combinedOverviewDataData = {
    description: monetizationState.description.value,
  }

  return (
    <Grid sx={{height: '100%', flexFlow: 'column'}} container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={{fontSize: '24px', padding: {xs: '0 0 20px 10px', md: 0}}}>
          Preview
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ filter: isEmpty && 'blur(10px)' }}>
        <BrowserWindow url={marketplaceHash ? `younet.ai/m/${marketplaceHash}` : null}>
          <Box sx={{ padding: '10px' }}>
            <TopContent marketplaceData={combinedTopContentData} fullwidth />
            <GalleryContent gallery_items={files} />
            <OverviewContent marketplaceData={combinedOverviewDataData} showMoreEnabled={false} fullwidth />
          </Box>
        </BrowserWindow>
      </Grid>
    </Grid>
  );

}

export default Preview;