// <script type="text/javascript" src="https://engine.4dsply.com/Pixel/Script?cid=62444"></script>.
import {createPixel, createScript} from "_helpers/apiHandlers";
import {getToken, setToken} from "_helpers/tokenManagement";


const PIXEL_URL = 'https://engine.4dsply.com/Pixel/Script?cid=62444';
const SENT_FLAG = 'E4DPixelSent';


export const shouldSendE4DPixel = () => {
    const pixelSent = getToken(SENT_FLAG) || false;
    return !pixelSent;
};

export const sendPixelE4D = async (params = {once: false}) => {

    if(process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
        return;
    }

    const {once} = params;
    let url = PIXEL_URL;

    const onLoad = () => {
        if (once) {
            setToken(SENT_FLAG, "true");
        }
    };

    await createScript(url, onLoad, undefined, {allowError: true});
};