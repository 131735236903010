import { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Skeleton,
  Divider,
  Modal,
  Link,
} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useViewportHeight from "hooks/useViewportHeight";
import Slider from "react-slick";
import { decodeData } from "utils/formatContent";
import classnames from 'classnames';
import TopContent from './TopContent';
import GalleryContent from './GalleryContent';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from "react-router-dom";
import controlledAPI from "_services/controlledApi";
import { getInitials } from "utils/prepareNames";
import MarkdownComponent from "components/MarkdownComponent";
import { useTheme } from '@mui/material/styles';
import "slick-carousel/slick/slick.css";
import useGetMarketplaceProductCard from 'hooks/useGetMarketplaceProductCard';
import styles from '../shareModelMarketplace.module.css';

const OverviewContent = ({ marketplaceData, showMoreEnabled, fullwidth }) => {
  const [isShortDescription, setIsShortDescription] = useState(false);

  return (
    <Grid container>
      {!fullwidth && <Grid item xs={12} md={1.5} />}
      <Grid item xs={12} md={fullwidth ? 12 : 9}>
        <Box sx={{ marginBottom: {xs: '210px', md: '120px'} }}>
          <Typography variant="marketplace_content_title" mb="5px">
            Overview
          </Typography>
          {marketplaceData?.description ? (
            <Box mb="20px" className={classnames(isShortDescription && styles.showMore)} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
              <MarkdownComponent source={decodeData(marketplaceData.description)} />
            </Box>
          ) : <Skeleton width={150} variant="text" animation="wave"/>}
          {showMoreEnabled && (
            <Box>
              <Button
                size="small"
                onClick={() => setIsShortDescription(!isShortDescription)}
              >{isShortDescription ? 'Show more...' : 'Show less'}</Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default OverviewContent;
