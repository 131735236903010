import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Box, Grid, CardContent, Card } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ModelItem from './components/ModelItem';
import "./index.css";

const AddNewModelCard = () => {
  const navigate = useNavigate();
  const handleAddNewModel = () => {
    navigate("/models/create");
  };

  return (
    <Card className="custom-card new-model" onClick={handleAddNewModel}>
      <CardContent className="custom-card-content">
        <AddIcon className="new-model-icon" />
      </CardContent>
    </Card>
  );
};

const Model = () => {
  const models = useSelector((state) => state.models);
  const { list, invitationsList } = models;
  const filteredInvitations = invitationsList.filter(item => item.status === 'sent');

  return (
    <Box sx={{ height: "inherit", overflowY: "auto" }}>
      <Grid container mt={6} sx={{ padding: "0 10px 20px" }}>
        {filteredInvitations.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="tool_header">Invitations</Typography>
            </Grid>
            <Grid item xs={12} mb={4}>
              <Grid
                container
                mt={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item className="custom-grid">
                  {filteredInvitations.map((model, index) => (
                    <Grid m={2} key={`${model.id}-${index}`}>
                      <ModelItem model={model.unique_hash ? { ...model.model, unique_hash: model.unique_hash, status: model.status } : model} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="tool_header">All models</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            mt={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item className="custom-grid">
              {list.length > 0 &&
                list.map((model, index) => (
                  <Grid m={2} key={`${model.id}-${index}`}>
                    <ModelItem model={model} />
                  </Grid>
                  )
                )}
              <Grid m={2}>
                <AddNewModelCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Model;
