import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "_services/api";
import formatTime from "utils/formatTime";
import { decodeData } from "utils/formatContent";
import { useDispatch } from "react-redux";
import { interactWithUser } from "redux/status";
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import MarkdownComponent from "components/MarkdownComponent";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import formatFileSizes from "utils/formatFileSizes";
import BrainCell from "components/BrainCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ViewQAContent = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [isExpanded, setExpand] = useState(false);

  const handleDeleteRows = (id) => {
    const deleteData = async () => {
      await api
        .delete(`/learning/file/${id}`)
        .then((r) => {
          if (r.code < 400) {
            // const newRows = rows.filter((row) => id != row.id);
            // setRows(newRows);
            navigate("/training/brain");
          }
        })
        .catch((error) => {});
    };

    deleteData();
  };

  const handleDownloadRows = (id, fileName) => {
    const downloadData = async () => {
      await api
        .get(`/file/download/${id}`, { responseType: "blob" })
        .then(async (blob) => {
          const aElement = document.createElement("a");
          aElement.setAttribute("download", fileName);
          const href = URL.createObjectURL(blob);
          aElement.href = href;
          // aElement.setAttribute('href', href);
          aElement.setAttribute("target", "_blank");
          aElement.click();
          URL.revokeObjectURL(href);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    downloadData();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await api
        .get(`brain/get/${id}`)
        .then((r) => {
          if (r.code == 200) {
            setData(r.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
        });
      // setData(result.data);
    };

    fetchData();
  }, []);

  const handleExpansion = () => {
    setExpand(!isExpanded);
  };

  const handleChunkDelete = (vector_id) => {
    
    let chunksArr = data.chunks.filter(item => item.vector_id !== vector_id);
    setData((prevState) => ({
      ...prevState,
      chunks: chunksArr
    }));
  }

  const calculatedDate = data.created_at
    ? formatTime({ date: data.created_at.date })
    : "";
  const textData = data.data ? decodeData(data.data) : "";
  const titleData = data.title ? decodeData(data.title) : "";

  return (
    <>
      <Grid
        container
        sx={{ height: "inherit", overflowY: "auto", padding: "0 20px" }}
      >
        <Grid item md={11}>
          <Typography variant="view_data_title">Brain Cells</Typography>
        </Grid>
        <Grid
          item
          md={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="view_data_time">
            {loading ? (
              <Skeleton
                sx={{ maxWidth: "100px" }}
                variant="text"
                animation="wave"
              />
            ) : (
              calculatedDate
            )}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12} sx={{ p: 4 }}>
          {loading ? (
            <>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </>
          ) : (
            data.chunks &&
            data.chunks.map((item, index) => (
              <BrainCell key={`${index}-${item.vector_id}`} item={item} onDelete={(id)=>{
                if (data.chunks?.length > 1) {
                  handleChunkDelete(id);
                } else {
                  handleDeleteRows(data.id);
                }
              }}></BrainCell>
            ))
          )}
        </Grid>
        <Grid item md={12}>
          <Typography variant="view_data_content">
            Original Name: {data.relate_data && data.relate_data.original_name}
          </Typography>
          <br />
          <Typography variant="view_data_content">
            File type: {data.relate_data && data.relate_data.file_type}
          </Typography>
          <br />
          <Typography variant="view_data_content">
            File size:{" "}
            {data.relate_data && formatFileSizes(data.relate_data.file_size)}
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ mb: 5 }}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={11}>
        <Typography variant="view_data_content">Raw Data:</Typography>
      </Grid>
      <Grid item md={1} sx={{ textAlign: "right" }}>
        <IconButton onClick={() => handleExpansion()}>
          {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Grid>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={11}>
          <Typography variant="view_data_title">
            {loading ? <Skeleton variant="text" animation="wave" /> : titleData}
          </Typography>
        </Grid>
        <Grid item md={1}>
          <IconButton
            onClick={() =>
              handleDownloadRows(
                data.relate_data.id,
                data.relate_data.original_name
              )
            }
          >
            <DownloadIcon color="icons" />
            {/* </a> */}
          </IconButton>
          <IconButton onClick={() => handleDeleteRows(data.id)}>
            <DeleteIcon color="icons" />
          </IconButton>
        </Grid>
        {/* <Grid item md={1} sx={{ textAlign: "right" }}>
          {isEditMode ? (
            <>
              <IconButton onClick={handleEditClose}>
                <CloseIcon />
              </IconButton>
              <IconButton onClick={() => handleSave()}>
                <SaveIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={() => handleDeleteRows(data.id)}>
                <DeleteIcon color="icons" />
              </IconButton>
              <IconButton onClick={() => handleEditClick()}>
                <EditIcon color="icons" />
              </IconButton>
            </>
          )}
        </Grid> */}
        {/* <Typography variant="view_data_content">{textData}</Typography> */}

        <Grid item md={12} sx={{ p: 4 }}>
          {loading ? (
            <>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </>
          ) : (
            <MarkdownComponent source={textData} />
          )}
        </Grid>
      </Collapse>
    </>
  );
};

export default ViewQAContent;
