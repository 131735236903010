import ShareModel from "pages/ShareModel";
import Grid from "@mui/material/Grid";
import {Typography, Box} from "@mui/material";
import BrowserWindow from "pages/protected/ShareModel/components/BrowserWindow";
import { useEffect, useRef } from "react";


const Preview = ({manualHash = '', publicLink}) => {
    const url = manualHash?.length ? `younet.ai/${manualHash}` : null;
    const browserRef = useRef(null);
    console.log(browserRef?.current?.clientHeight)

    if (!Boolean(publicLink)) return null;

    return (
        <Grid sx={{width: '100%', height: '100%', flexFlow: 'column'}} container spacing={2}>
            <Grid item xs={12} flex="auto !important">
                <Typography sx={{fontSize: '24px', padding: {xs: '0 0 20px 10px', md: 0}}}>
                    Preview
                </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ paddingTop: '14px' }}>
                <BrowserWindow url={url}>
                  <Box sx={{ height: '100%' }}>
                    <ShareModel manualHash={manualHash} hideSideBar={true} preview={true} />
                  </Box>
                </BrowserWindow>
              </Box>
            </Grid>
        </Grid>

    )
}
export default Preview;
