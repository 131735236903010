//1. Activate loading state true with different action states
//2. activate save action
//3. get model. and send messages for generation
//4. after generated redirect to playground
// 5. End loading

import {DynamicLogo} from "components/loadings/BrandLoading";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./modelSaveWindow.module.css";
import useWriteAnimation from "hooks/useWriteAnimation";
import {useCallback, useEffect, useMemo, useState} from "react";
import modelLabels from "mappings/languages/en/modelLabels.json"
import {useDispatch, useSelector} from "react-redux";
import {setSession, generateHints, addModel, cleanSession, getHintsList} from "redux/models";
import {useNavigate} from "react-router-dom";
import {sendUIEvent} from "../../../../../events/analytics/google";


const ModelSaveWindow = ({isDataSending = false, stepBag = undefined, customHintsEnabled = false}) => {

    const dispatch = useDispatch();
    const step = useSelector(state => state.models.session.loading.step);
    const model = useSelector(state => state.models.session.model);
    const navigate = useNavigate();

    const sources = useMemo(() => {
        return Object.values(
            modelLabels.labels.saveModelLoadingSteps
        );
    }, []);

    const setStep = (step) => {
        dispatch(setSession({step: step}));
    }


    const {contentWriteAnimation, cleanContentWriteAnimation} = useWriteAnimation(sources, setStep, 5000, 2);

    const generateHintsCallback = useCallback(async (model) => {
        // const REDIRECT_DELAY = 1000;
        const handleNavigation = () => {

            if (stepBag) {
                stepBag.setNextStep();
            } else {
                // dispatch(addModel({model: model, choosen: true}));
                navigate('/playground');
            }
        };

        const handleFailedHints = async (attempts = 2) => {

            if (attempts === 0) {
                handleNavigation();
                return;
            }

            const response = await dispatch(getHintsList({modelId: model.id}));

            if (response.type === 'models/hints/list/fulfilled') {
                handleNavigation();
            } else {
                handleFailedHints(attempts - 1)
            }
        }

        if (!customHintsEnabled) {
          const response = await dispatch(generateHints({modelId: model.id}));

          dispatch(addModel({model: model, choosen: true}));

          if (response.type === 'models/hints/generate/fulfilled') {
              cleanContentWriteAnimation();
              handleNavigation();
          } else {
              handleFailedHints();
          }
        } else {
          cleanContentWriteAnimation();
          handleNavigation();
          dispatch(addModel({model: model, choosen: true}));
        }

    }, [dispatch, cleanContentWriteAnimation, stepBag, navigate]);

    useEffect(() => {
        if (model.id) {
          generateHintsCallback(model);
          contentWriteAnimation();
          return () => {
              dispatch(cleanSession());
              cleanContentWriteAnimation();
          }
        }


    }, [model, customHintsEnabled]);

    return (
        <Box className={styles['container']}>
            <Box className={styles['inner-container']}>
                <DynamicLogo size={"large"}
                    // tickNum={tickNum}
                             stage={3}
                             isLoading={true}/>
                <Typography sx={{paddingTop: '40px'}} variant="page_list" color="#7c8091">
                    <div className={styles["dots-loading"]}>{step}</div>
                </Typography>
                <Typography
                    variant="page_list"
                    sx={{
                        display: 'block',
                        textAlign: 'right',
                        fontSize: '11px',
                        fontWeight: 300,
                    }}
                >
                    Please, do not close this window
                </Typography>
            </Box>

        </Box>
    )
}

export default ModelSaveWindow;