import {memo} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {Tooltip, Typography} from "@mui/material";
import formatTime from "../../../../../../../../../../utils/formatTime";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {toggleRow} from "redux/email";
import {sendUIEvent} from "../../../../../../../../../../events/analytics/google";

const createData = (data) => {
    let briefInfo = (
        <Typography variant="inline">
            <Typography variant="gmailSubject">{data.subject}</Typography>{" "}
            <Typography variant="gmailContent">{"- " + data.snippet}</Typography>
        </Typography>
    );
    let gmailFrom = <Typography variant="gmailFrom">{data.from}</Typography>;

    let formattedData = {
        key: data.id,
        from: gmailFrom,
        briefInfo: briefInfo,
        date: formatTime(data.date),
        isApproved: data.isApproved,
    };

    return formattedData;
};

const EmailRow = ({row = {}, supportData, selected}) => {

    const nav = useNavigate();
    const dispatch = useDispatch();

    const isSelected = Boolean(selected);

    let formattedData = createData(row);
    let status_message = 'This data has not been approved yet';
    let status_name = 'not added';

    if (row.isApproved) {
        status_name = 'approved';
        status_message = 'This data has been approved and memorized';
    }

    if (row.task) {

        if (row.task.status != 'success') {
            status_message = row.task.error_message;
            status_name = row.task.status;

        }
    }

    const handleClick = (data) => {
        nav(`./${supportData.accountId}/${supportData.modelId}/${data.id}`);
        sendUIEvent({name: `email_tool_view_item`});
    };
    const handleRowToggle = (dataId) => {
        dispatch(toggleRow({dataId}));
    }

    return (

        <TableRow
            sx={{
                "&:last-child td, &:last-child th": {border: 0},
                "&:hover": {
                    backgroundColor: "#efefef",
                },
                cursor: "pointer",
            }}
        >
            <TableCell sx={{maxWidth: '40px'}} padding="none" component="th" scope="row">
                <Checkbox
                    checked={isSelected}
                    onChange={() => handleRowToggle(row.id)}
                />
            </TableCell>
            <TableCell sx={{maxWidth: '20%'}} padding="none" onClick={() => handleClick(row)}>
                {formattedData.from}
            </TableCell>
            <TableCell sx={{maxWidth: '40%'}} padding="none" onClick={() => handleClick(row)}>
                {formattedData.briefInfo}
            </TableCell>
            <TableCell/>
            <TableCell component="th" scope="row">
                <Tooltip title={status_message} placement="top">
                    <Typography variant={status_name + "_status"}>{status_name}</Typography>
                </Tooltip>
            </TableCell>

            <TableCell sx={{padding: "0 5px 0 0"}} align="right">
                {formattedData.date}
            </TableCell>
        </TableRow>

    );
};


export default memo(EmailRow, (prevProps, nextProps) => {
    return prevProps.selected === nextProps.selected && prevProps.supportData?.modelId === nextProps.supportData?.modelId;
});
