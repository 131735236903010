import {getTrainedList, getAllLinks, getNestedList, getPendingList} from "redux/links/linksThunk";

const getLinksListByFilterAction = {
    archive: (params) => getAllLinks(params),
    trained: (params) => getTrainedList(params),
    new: (params) => () => {
    },
    pending: (params) => getPendingList(params),
    nested: (params) => getNestedList(params)
};


export default getLinksListByFilterAction;