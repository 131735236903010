import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {reloadSearchFilter, setSearchFilter} from "redux/email";
import {useEffect} from "react";
import {getOutlookFolderList} from "redux/email/emailThunk";
import accountTypeActions from "../../actions/accountTypeActions";


const EmailSortSearch = () => {

    const dispatch = useDispatch();
    const sort = useSelector(state => state.email.content.sort, shallowEqual);
    const currentAcc = useSelector(state => state.email.account.current, shallowEqual);

    useEffect(() => {
        if (currentAcc) {
            const {type, id} = currentAcc;
            const action = accountTypeActions[type];
            if (action && !sort.current?.id) {
                action(dispatch, id);
            }
        }

        return () => {

        }
    }, [currentAcc, dispatch]);

    if (sort.loading || !sort.current?.id) {
        return <></>;
    }


    return <Autocomplete
        disableClearable
        fullWidth
        options={sort.filter}
        getOptionLabel={(option) => option.name}
        style={{width: 300}}
        defaultValue={sort.current}
        value={sort.current}
        onChange={(e, newValue) => {
            dispatch(setSearchFilter({current: newValue}))
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Change threads"
                InputProps={{
                    ...params.InputProps,
                    type: "search",
                }}
            />
        )}
    />
}


export default EmailSortSearch;