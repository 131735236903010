import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SmoothContent from "components/loadings/SmoothContent";
import { useSelector } from "react-redux";
import SubsTable from "./components/SubsTable";

const SubscriberManager = () => {

    return (
        <Grid container paddingTop="15px" spacing={4}>
            <Grid item xs={12} md={12} >
                <Typography variant="page_sub_title_bold">Subscriber Management</Typography>
            </Grid>
            <Grid item xs={12} md={12} >
                <SubsTable />
            </Grid>
        </Grid>
    )
}

export default SubscriberManager
