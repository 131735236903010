// str (string) - full string
// num (int) - amount of words
export const getWordsFromString = (str, num) => {
  let words = str.trim().split(/\s+/);
  let result = words.slice(0, num).join(" ");

  return result;
};

export const getInitials = (str, num = 2) => {

  const words = str.split(" ");

  const firstLetters = words
    .slice(0, num) 
    .map((word) => word.charAt(0))
    .join(""); 

  return firstLetters
};
