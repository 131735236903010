import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import {FormHelperText} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {isInputValid} from "utils/validateInputs";
import SupportIconTooltip from "components/tooltip/SupportIconTooltip";


const StyledSearchParse = styled(TextField)(({theme}) => ({
    // '& .MuiTextField-root': {
    //     backgroundColor: '#eef2fd',
    //     maxWidth: '600px',
    //     width: '100%'
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '0.5px solid #eee',

        },
    },
    '& .MuiInputBase-root': {
        height: '32px',
        color: '#7b8191',
        padding: 0,
        textAlign: 'center',
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px'
        },
        '&::after, &::before': {
            border: 'unset'
        },
        // '&::placeholder': {
        //     fontSize: '11px'
        // }

    },
    backgroundColor: '#eef2fd',
    // height: '32px',
    maxWidth: '600px',
    borderRadius: '4px',
    width: '100%',
}));

const ParseInput = ({
                        onChange
                    }) => {

    const preselectedWebsite = useSelector(state => state.models?.current?.data?.website);
    const [linkStatus, setStatus] = useState({isError: false, msg: ' ', content: preselectedWebsite || ''});

    const onChangeHandle = (e, manual = undefined) => {
        e?.preventDefault();

        const value = manual ?? e?.target?.value;
        const linkStatus = isInputValid(value, {type: 'url', isRequired: true});
        let errorStatus = {
            isError: false,
            status: ''
        }

        if (linkStatus.error) {
            errorStatus = {
                isError: true,
                status: linkStatus.error
            }
        }
        setStatus(prev => {
            return {...prev, isError: errorStatus.isError, msg: errorStatus.status, content: value}
        });
        onChange({link: value, isError: errorStatus.isError});
    }

    useEffect(() => {

        if(preselectedWebsite) {
            onChangeHandle(undefined,preselectedWebsite);
        }

    }, [preselectedWebsite]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StyledSearchParse
                    value={linkStatus.content}
                    onChange={onChangeHandle}
                    variant="outlined"
                    placeholder="Add your link (Ex: https://younet.ai/younet-content)"
                    // disabled={loading}
                    fullWidth
                    required
                    FormHelperTextProps={{style: {margin: '4px 0 0'}}}
                    InputProps={{
                        endAdornment: (
                            <SupportIconTooltip margin="0 5px 0 0"
                                                title='Make sure the URL is correct to ensure accurate parsing.'/>)
                    }}
                    // onKeyDown={(e) => {
                    //
                    //     if (e.key === "Enter") {
                    //         onSubmit(e)
                    //     }
                    // }}

                />
                <FormHelperText sx={{color: "#d32f2f", textAlign: 'center'}}>
                    {linkStatus.msg || ' '}
                </FormHelperText>
            </Grid>
        </Grid>
    )
}


export default ParseInput;