import axios from "axios";
import { getToken, removeToken } from "_helpers/tokenManagement.js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import store from "redux/status/statusStore";
import store from 'redux/store';
import { interactWithUser } from "redux/status";
import { refreshUserToken } from "redux/auth/authThunk";
import { checkIntention } from "_helpers/statusManagement";


const requestCallback = (config) => {
  if(!config.headers['Authorization']) {
    config.headers["Authorization"] = `Bearer ${getToken('token')}`;
  }

  if(!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json";
  }

  // config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
}

const controlledAPI = (params = {}) => {

 const api = axios.create({
   baseURL: params.base_url || process.env.REACT_APP_API_BASE_URL || `https://api.younet.ai/api`,
 });

 api.interceptors.request.use(
  params.request_callback ?? requestCallback,
   (error) => {
     store.dispatch(interactWithUser({template: 'DEFAULT_ERROR'}));
     return Promise.reject(error);
   }
 );
 
 api.interceptors.response.use(
   (res) => {
 
     let code = res?.data.code || 200;
     let serverCode = res.code || 200;
     let intention = res?.data.intention || '';
     let message = Array.isArray(res?.data?.message)
       ? res?.data?.message[0] || null
       : res?.data?.message || res?.data?.message || null;


     
     let statusInstance = params.statusInstance ?? (intention || message) ? checkIntention(serverCode,code,intention, message) : false;
 
     if(code == 401) {
       const nav = useNavigate();
       removeToken("token");
       removeToken("refreshToken");
       removeToken("userData");
       nav('/login');
       // store.dispatch(interactWithUser({template: 'JWT_TOKEN_EXPIRED'}));
       return;
     }
 
     // ignoreUser - don`t display message back to the user  
     if(params.ignoreUser == true && statusInstance?.status !== 'error') {
       return res.data;
     }
 
     if(statusInstance) {
       store.dispatch(interactWithUser({message: statusInstance.message, type: statusInstance.status, code: code}));
     }

 
     return res.data;
   },
   (err) => {
    if (err.code === 'ERR_BAD_REQUEST') {
      if (localStorage.getItem('refreshToken')) {
        store.dispatch(refreshUserToken());
      } else {
        window.location.href = '/portal/login';
      }
    }

    if (err.code === "ERR_CANCELED") {
      // aborted in useEffect cleanup
      return Promise.resolve({status: 499})
    }
     store.dispatch(interactWithUser({template: 'DEFAULT_ERROR'}));
 
     throw new Error(err.response.data.message);
   }
 );

 return api;
}


export default controlledAPI;