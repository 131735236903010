import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Paper, Typography,Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./content.module.css";
import useViewportHeight from "hooks/useViewportHeight";

function Content({ children, newUser = false }) {
  const model = useSelector((state) => state.models.current);
  // const navigate = useNavigate();
  const location = useLocation();
  // const pathnames = location.pathname.split("/").filter((x) => x);
  const viewportHeight = useViewportHeight();
  const is_default = useSelector(state => state.auth.user.tariff_plan?.is_default);
  const isTrial = Boolean(is_default);

  const trialOffset = isTrial ? '128px': '94px';

  return (
    <Grid container className={styles["container"]}>
      {/* <Grid item xs={12} sx={{height:'30px'}}>
      <Breadcrumbs aria-label="breadcrumb" separator="/">
        <Link underline="hover" color="inherit" href="/">
        <Typography variant="page_title" >
          {model.name}
        </Typography>
        </Link>

        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;


          console.log(routeTo)
          return isLast ? (
            <Typography
              key={name}
              // color="primary"
              sx={{ textTransform: "capitalize" }}
            >
              {name}
            </Typography>
          ) : (
            <Link
              key={name}
              color="inherit"
              to={routeTo}
              // onClick={() => {console.log('HER: ', routeTo); navigate(routeTo)}}
              // sx={{ textTransform: "capitalize" }}
            >
              <Typography  sx={{ textTransform: "capitalize" }}>{name}</Typography> 
            </Link>
          );
        })}
        <Link
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
          
        >
          Playground
        </Link>
        <Typography>Breadcrumbs</Typography>
      </Breadcrumbs>
      <Typography variant="page_title" className={styles["title"]}>
        {title}
      </Typography>
      </Grid> */}

      <Grid item xs={12} sx={{
        position:"relative",
        display: "flex",
        flexDirection: "column",
        height:"100%",
        boxSizing:"border-box",
      }}>
        <Paper 
          sx={{
            // height:{xs:`calc(${viewportHeight}px - 94px)`,md:`calc(${viewportHeight}px - 124px)`},
            // height: model.noModels ? {xs:`calc(${viewportHeight}px - 34px)`,md:`calc(${viewportHeight}px - 65px)`} : {xs:`calc(${viewportHeight}px - ${trialOffset})`, md:`calc(${viewportHeight}px - 124px)`},
            height: newUser ? {xs:`calc(${viewportHeight}px - 34px)`,md:`calc(${viewportHeight}px - 38px)`} : {xs:`calc(${viewportHeight}px - ${trialOffset})`, md:`calc(${viewportHeight}px - 100px)`},
            overflowY: 'auto',
            borderRadius: 0,
            boxShadow:{xs:'none',md:'0px 2px 1px -1px rgba(0,0,0,0.02), 0px 1px 1px 0px rgba(0,0,0,0.014), 0px 1px 3px 0px rgba(0,0,0,0.012)'}
          }}
        //square={true}
        >
          {children}
        </Paper>
        <Box className={styles["version-info"]} sx={{ right: '10px' }}>
          Younet.ai - <a style={{textDecoration:'underline'}} href="https://support.younet.ai/article/20-Younet-AI-Release-Notes">Release Notes</a>
          &nbsp;<a style={{textDecoration:'underline'}} href="https://younet.ai/terms-and-conditions#privacy-policy">Privacy Policy</a>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Content;
