import React from "react";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {solarizedlight} from "react-syntax-highlighter/dist/cjs/styles/prism";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button} from "@mui/material";
import {BlockMath, InlineMath} from "react-katex";
import CodeBlock from "./CodeBlock";


export const AdaptiveLanguageMarkdown = ({className, language, inline, props, children}) => {

    const stringValue = String(children);

    // TODO: better mapping later
    // if (language === 'latex') {
    //     return inline ? <InlineMath renderError={() => {
    //         return <span>{children}</span>;
    //     }}>{stringValue.trimEnd()}</InlineMath> : <BlockMath renderError={() => {
    //         return <span>{children}</span>;
    //     }}>{stringValue.trimEnd()}</BlockMath>;
    // } else
    if (!inline) {
        return <CodeBlock language={language} value={stringValue.replace(/\n$/, '')}/>
    }

    return <code className={className} {...props}>
        {children}
    </code>


}
