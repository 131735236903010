import PublicLink from "../../PublicLink";
import {Box, FormControlLabel, Switch, Typography} from "@mui/material";
import Hint from "../../Hint";
import React, {useEffect, useState} from "react";
import {interactWithUser} from "redux/status";
import {useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FlexWrapper from "../../../../../../components/wrappers/FlexWrapper";
import Button from "@mui/material/Button";
import downloadFromWeb from "utils/downloadFromWeb";
import api from "_services/api";

const CustomerSupport = ({
                             isPublic, hash = '', qr = {}, hideFromPublic
                         }) => {

    const dispatch = useDispatch();
    const [blobUrl, setBlobUrl] = useState('');
    const publicLink = 'https://younet.ai/p/' + hash;
    const copyPublicLink = async () => {
        const linkToCopy = publicLink;
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(linkToCopy);
        } else {
            document.execCommand("copy", true, linkToCopy);
        }
        return dispatch(
            interactWithUser({type: "info", message: "Copied URL!"})
        );
    };


    const handleOnDownload = async () => {
        await downloadFromWeb(blobUrl, qr.type, qr.file_name);
    }


    const handlePrint = () => {

        function setPrint() {
            const closePrint = () => {
                document.body.removeChild(this);
            };
            this.contentWindow.onbeforeunload = closePrint;
            this.contentWindow.onafterprint = closePrint;
            const imgHTML = document.getElementById('printable-qr-code').outerHTML;

            const htmlContent = `<html>
                    <head>
                        <title>Print QR Code</title>
                        <style>
                            body {
                                margin: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                            }
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        </style>
                    </head>
                    <body>
                        ${imgHTML}
                    </body>
                </html>`
            this.contentWindow.document.open();
            this.contentWindow.document.write(htmlContent);


            this.contentWindow.document.close();
            this.contentWindow.print();


        }

        const hideFrame = document.createElement("iframe");
        hideFrame.onload = setPrint;
        hideFrame.style.display = "none";
        document.body.appendChild(hideFrame);

    }


    useEffect(() => {


        if (qr?.public_url) {
            api.get(`/file/download/${qr.id}`, {responseType: 'blob'})
                .then((response) => {
                    const blobUrl = URL.createObjectURL(response);
                    setBlobUrl(blobUrl)

                    // setImgData({blob: response, url: blobUrl});

                })
                .catch((error) => {
                    console.warn(error);
                });
        }


    }, [qr?.public_url])


    if (!isPublic) {
        return
    }

    return (
        <Grid container py={4} spacing={4}>
            <Grid item xs={12} sm={12} md={12} py={2}>
                <Typography sx={{fontSize: '24px'}}>
                    Speak to Product link and QR code
                </Typography>
            </Grid>


            <Grid item xs={12} sm={12} md={12} py={2}>
                <PublicLink
                    publicLink={publicLink}
                    copyPublicLink={() => copyPublicLink()}
                    hideFromPublic={hideFromPublic}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <FlexWrapper>
                    <Paper id="printable-qr-code" variant="outlined">
                        <img width="320px" height="320px" src={qr?.public_url} alt={'Qr code image'}/>
                    </Paper>
                </FlexWrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <FlexWrapper>
                    <Button onClick={handleOnDownload} size="small" variant="contained">Download</Button>
                    <Button onClick={handlePrint} sx={{marginLeft: '45px'}} size="small" variant="text">Print</Button>
                </FlexWrapper>
            </Grid>


        </Grid>
    )
}


export default CustomerSupport;