import React, {memo, useCallback, useEffect, useState} from "react";
import api from "_services/api";
import {GENERATE_IMG_URL} from "constants";
import Spinner from "../../../loadings/Spinner";
import FailedImage from "./FailedImage";
import {Box, Dialog} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import downloadFromWeb from "../../../../utils/downloadFromWeb";
import {getToken} from "../../../../_helpers/tokenManagement";

const EXPIRATION_MSG = 'This image will expire in a few hours';
const IMG_ERROR_CODE = 'IMAGE_ERROR';

const ImageComponent = ({src, alt, ...props}) => {

    const [imgData, setImgData] = useState({blob: null, url: ''});
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [supportInfo, setSupportInfo] = useState(null);

    const handleCloseFullScreen = () => {
        setOpen(false);
    };

    const handleOnErrorImg = () => {

        setImgData(prev => ({...prev, error: IMG_ERROR_CODE}));
    }

    const downloadImage = useCallback((fileId) => {
        api.get(`/file/download/${fileId}`, {responseType: 'blob'})
            .then((response) => {
                const blobUrl = URL.createObjectURL(response);
                let parts = blobUrl.split('/');

                response.name = parts[parts.length - 1];
                setImgData({blob: response, url: blobUrl});
                setLoading(false);
            })
            .catch((error) => {
                console.warn(error);
                handleOnErrorImg();
                setLoading(false);
            });
    }, [])

    useEffect(() => {

        if (src.includes(GENERATE_IMG_URL)) {
            setImgData(prev => ({...prev, url: src}));
            setLoading(false);
            setSupportInfo(EXPIRATION_MSG)
            return;
        }

        if (src.startsWith('http')) {
            fetch(src)
                .then((response) => response.blob())
                .then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    setImgData(prev => ({...prev, url: blobUrl}));
                    setLoading(false);
                })
                .catch((error) => {
                    console.warn(error);
                    handleOnErrorImg();
                    setLoading(false);
                });
            return;
        }

        // Make sure that src is an integer
        const fileId = parseInt(src, 10);

        downloadImage(fileId)
    }, [src]);


    if (!imgData.url || loading) {
        return <Spinner/>; // return a loading indicator or a placeholder
    }

    if (imgData.error === IMG_ERROR_CODE) {
        return <FailedImage alt={alt}/>
    }

    const handleFullScreen = () => {
        setOpen(true);
    }

    const saveImageOnComputer = async () => {
        const blob = imgData.blob;
        await downloadFromWeb(imgData.url,blob.type, blob.name);
    }

    return (
        <>
            <Box sx={{display: 'inline-block', flexFlow: 'column', position: 'relative'}}>
                {imgData.blob &&
                <Box sx={{position: 'absolute', right: 0, top: 0, backgroundColor: '#0000004d'}}>
                    <IconButton size="small" sx={{
                        color: '#d3dbeae3', '&:hover': {
                            color: '#fff'
                        },
                    }} onClick={saveImageOnComputer}><DownloadIcon/></IconButton>
                    <IconButton sx={{
                        color: '#d3dbeae3', '&:hover': {
                            color: '#fff'
                        },
                    }} onClick={handleFullScreen}><FullscreenIcon/></IconButton>
                </Box>
                }
                <img src={imgData.url} alt={alt} {...props} className='preview-image'
                     onError={handleOnErrorImg}/>
                {supportInfo &&
                    <Typography component="p" sx={{fontSize: '10px', opacity: 0.6, fontStyle: 'italic', padding: '2px'}}
                                variant="describe_text">*{supportInfo}</Typography>}
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={handleCloseFullScreen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Toolbar sx={{backgroundColor: '#0000004d',position: 'absolute', right: 0}}>
                    <IconButton size="small" sx={{
                        color: '#d3dbeae3', '&:hover': {
                            color: '#fff'
                        },
                    }} onClick={saveImageOnComputer}><DownloadIcon/></IconButton>
                    <IconButton sx={{color: '#d3dbeae3',paddingLeft: '25px'}} edge="start" onClick={handleCloseFullScreen} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
                <img src={imgData.url} alt={alt} {...props} className='full-screen-image'/>
            </Dialog>
        </>
    );
};


export default memo(ImageComponent);