import { useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Typewriter from '../components/Typewriter';
import api from '_services/api';
import { getInitials } from "utils/prepareNames";
import SearchInput from 'components/menus/SharedModels/components/SearchInput';
import { getSharedCards } from 'redux/marketplace';
import ModelAccordion from '../components/ModelAccordion';
import { allowOpenPortal, setSelectedModelType } from 'redux/models';
import Wrapper from 'components/menus/SharedModels/components/Wrapper';
import ListItem from 'components/menus/SharedModels/components/ListItem';
import styles from "../onboarding.module.css";
import {sendUIEvent} from "../../../../events/analytics/google";

const Marketplace = ({ stepBag, isNewUser, finishedStepNames }) => {
  const [searchValue, setSearchValue] = useState('');
  const onboardingCards = useSelector((state) => state.marketplace.onboardingCards, shallowEqual);
  const marketplaceLoading = useSelector((state) => state.marketplace.loading, shallowEqual);
  const animationFinished = finishedStepNames.includes(stepBag.stepKey);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = (value, loadMore = false) => {
    setSearchValue(value);
    dispatch(getSharedCards({ query: value, loadMore, ...onboardingCards, isPublic: true, isOnboarding: true }));
  }

  const fetchSharedData = async (modelHash) => {
    let params = {
      modelHash,
    };
    dispatch(setSelectedModelType({ modelType: 'shared', data: null, loading: true }));

    try {
        const response = await api.post('/model/share/data', params);

        if (response.code >= 400) {
            // setDataLoading(false);
            // setChatNotExist(true);
            return;
        }

        const messages = response.messages || [];
        const model = response.chat?.model || {};
        const ownerInfo = response.owner || {};
        const ownerInnitial = ownerInfo.fullName || '';

        if (ownerInnitial !== '') {
            ownerInfo.initial = getInitials(ownerInnitial);
        }

        // dispatch(addMessages({ type: 'shared', messages }));
        // setModelInitialsData({ avatar: model.avatar, name: model.name });
        const data = {
            model: model,
            messages: messages,
            info: {
              // chat_id: null,
              chat_id: response.chat.id,
                guest_id: response.chat.guest_id,
                modelName: model.name,
                modelWebsite: model.modelWebsite,
                modelId: model.id,
            },
            ownerInfo: ownerInfo,
        };
        dispatch(setSelectedModelType({ modelType: 'shared', data: { ...data, messages: [] }, loading: false }));
        dispatch(allowOpenPortal({ model }));
    } catch (error) {
        // setDataLoading(false);
    }
  }

  const onSelect = (model) => {
    if (Boolean(model?.public_hash)) {
      fetchSharedData(model?.public_hash);
    }

    navigate('/playground');
  }

  const generateDescription = (model) => {
    if (model.mini_description) {
      return model.mini_description;
    }

    let description = model.model.role;
    if (Boolean(model.model.company?.length)) {
      description = `${description} at ${model.model.company}`;
    }

    return description;
  }


  return (
    <Box sx={{ maxWidth: '400px', width: '100%', margin: '0 auto 20px' }}>
      <Typography
        variant="h2"
        sx={{
          marginBottom: '40px',
          textAlign: 'center',
          width: '100%',
          fontSize: {xs: '22px', md: '32px'},
          fontWeight: 300,
          minHeight: '39px',
          position: 'relative',
        }}
      >
        {animationFinished || !isNewUser ? `Let's find the public AI model that suit your needs` : (
          <>
            <Box sx={{ opacity: 0 }}>Let's find the public AI model that suit your needs</Box>
            <Box sx={{ position: 'absolute', top: 0, textAlign: 'center', width: '100%' }}>
              <Typewriter text="Let's find the public AI model that suit your needs" animationSpeed={stepBag.step >= 2 ? 20 : 30} animationDelay={2400}  />
            </Box>
          </>
        )}
      </Typography>
      <Box 
        sx={{ animationDelay: '4900ms', boxShadow: 'none', borderRadius: '10px' }}
        className={classnames(!animationFinished && isNewUser && styles.button)}
      >
        <Wrapper
          createModelEnabled={false}
          widesidebar={true}
          modelsMenuOpen={true}
          onCloseHandler={() => {}}
          loadMoreHandler={() => {
            onSubmitHandler(searchValue, true);
          }}
          // loadMoreDisabled={sharedCards?.pages === limit / 10 && !Boolean(searchValue?.length)}
          loadMoreDisabled={onboardingCards?.allPage === onboardingCards?.allPages && Boolean(searchValue?.length)}
          leftBarMenu={true}
        >
          <SearchInput widesidebar={true} onSubmitHandler={onSubmitHandler} outlined={true} />
          {marketplaceLoading ? (
            <Box px={6}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
            </Box>
          ) : (
            <Box sx={{ paddingTop: '5px' }}>
              {onboardingCards?.pinnedItems?.map((item, i) => {
                return (
                  <ModelAccordion
                    key={`pinnedItems-${item.id}-${i}`}
                    expanded={true}
                    name={item.name}
                    description={generateDescription(item)}
                    onClickHandler={() => { onSelect(item); sendUIEvent({name: 'onboarding_marketplace_public_model_clicked', params: {modelId: item.id} }) }}
                  />
                )
              })}
              {onboardingCards?.allItems?.map((item, i) => {
                return (
                  <ModelAccordion
                    key={`allItems-${item.id}-${i}`}
                    expanded={true}
                    name={item.name}
                    description={generateDescription(item)}
                    onClickHandler={() => { onSelect(item); sendUIEvent({name: 'onboarding_marketplace_public_model_clicked', params: {modelId: item.id} }) }}
                  />
                )
              })}
            </Box>
          )}
        </Wrapper>
      </Box>
    </Box>
  );
}

export default Marketplace;
