import TablePagination from "@mui/material/TablePagination";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {tableNavigation} from "../../../../../../../../../../redux/email";
import {memo} from "react";

const EmailTablePagination = () => {

    const {maxRows, pagination} = useSelector(state => state.email.content, shallowEqual);
    const dispatch = useDispatch();
    const emailsAmount = pagination.amount;

    if (emailsAmount < maxRows) {
        return;
    }

    const handleChangePage = (e, resId) => {
        const pageId = resId + 1;
        dispatch(tableNavigation({pageId}))
    }

    const numRowsLabel = ({ from, to, count }) => {
        return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
    };

    // specifically for mui pagination
    const pageId = pagination.page.id - 1;
    return (
        <TablePagination
            labelDisplayedRows={numRowsLabel}
            rowsPerPageOptions={[]}
            component="div"
            count={-1}
            rowsPerPage={maxRows}
            page={pageId}
            onPageChange={handleChangePage}
        />
    )
}
export default memo(EmailTablePagination);
