import { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Grid, TextField, Select, Box, MenuItem, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import controlledAPI from '_services/controlledApi';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveDataButton from 'components/buttons/SaveDataButton';
import Title from '../Title';
import { checkFormForErrors } from "utils/validateInputs";
import { interactWithUser } from "redux/status";
import styles from './form.module.css';

const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    resize: none;
    padding-right: 25px;
  }
  @media only screen and (max-width: 899px) {
    & .MuiInputLabel-root {
      white-space: pre-wrap;
    }
    & .MuiInputLabel-shrink {
      white-space: nowrap;
    }
  }
`;

const initialState = {
  name: {
    id: 1,
    errMsg: '',
    isRequired: true,
    value: '',
  },
  description: {
    id: 2,
    errMsg: '',
    isRequired: true,
    value: '',
  },
  method: {
    id: 3,
    errMsg: '',
    isRequired: true,
    value: 'get',
  },
  url: {
    id: 4,
    errMsg: '',
    isRequired: true,
    value: '',
  },
  // body: {
  //   id: 5,
  //   errMsg: '',
  //   isRequired: false,
  //   value: '',
  // },
  security: {
    id: 6,
    errMsg: '',
    isRequired: false,
    value: 'noSecurity',
  },
};

const Form = ({ setName }) => {
  const [formState, setFormState] = useState(initialState);
  const [optionalParams, setOptionalParams] = useState([]);
  const [requiredParams, setRequiredParams] = useState([]);
  const [loading, setLoading] = useState(false);
  const model = useSelector((state) => state.models.current, shallowEqual);
  const dispatch = useDispatch();

  const generateInputPair = (
    id,
    keyName,
    valueName,
    keyLabel = null,
    valueLabel = null,
    keyPlaceholder = null,
    valuePlaceholder = null,
    keyDataKey = null,
    valueDataKey = null,
    isRequired = false
  ) => {
    return {
      [keyName]: {
        id,
        errMsg: "",
        isRequired,
        label: keyLabel,
        placeholder: keyPlaceholder,
        dataKey: keyDataKey,
      },
      [valueName]: {
        id,
        errMsg: "",
        isRequired,
        label: valueLabel,
        placeholder: valuePlaceholder,
        dataKey: valueDataKey,
      }
    }
  }

  const generateFormSet = (id, type, securityType) => {
    // securityType: header | requiredParams | optionalParams
    // type: basic | customHeader | bearer | oauth
    
    if (type === 'header') {
      return generateInputPair(`headerKey${id}`, `headerValue${id}`);
    }
    if (type === 'requiredParams' || type === 'optionalParams') {
      return {
        ...generateInputPair(id, `${type}Key${id}`, `${type}Type${id}`),
        [`${type}Description${id}`]: {
          id,
          dynamic: true,
          errMsg: "",
          isRequired: false,
          label: 'Description',
        },
      }
    }
    // Select the Security type
    if (type === 'security') {
      switch (securityType) {
        case 'basic':
          // return generateInputPair(id, `${type}${securityType}ClientId${id}`, `${type}${securityType}ClientSecret${id}`, 'e.g., user@example.com"', 'e.g., securePassword123!', 'client_id', 'client_secret');
          return generateInputPair(id, `${type}${securityType}ClientUsername${id}`, `${type}${securityType}ClientPassword${id}`, 'Username', 'Password', 'e.g., user@example.com"', 'e.g., securePassword123!', 'username', 'password', true);
          break;
        case 'header':
          return generateInputPair(id, `${type}${securityType}ClientId${id}`, `${type}${securityType}ClientSecret${id}`, 'Client Id', 'Client Secret', 'e.g., X-API-KEY', 'e.g., ABC123DEF456GHI789', 'custom_key', 'custom_value', true);
          break;
        case 'bearer':
          return {
            [`${type}${securityType}Value${id}`]: {
              id,
              errMsg: "",
              isRequired: true,
              dataKey: 'token',
            }
          }
          break;
        case 'oauth':
          return {
            [`${type}Oauth`]: {
              id,
              errMsg: "",
              isRequired: false,
              label: 'Oauth',
              dataKey: 'auth_method',
            },
            ...generateInputPair(id, `securityOauth2ClientId${id}`, `securityOauth2ClientSecret${id}`, 'Client Id', 'Client Secret',  'e.g., 1234567890abcdef', 'e.g., abcdef12345-67890fedcba', 'client_id', 'client_secret', true),
            ...generateInputPair(id, `securityOauth2RedirectUriValue${id}`, `securityOauth2AuthorizationUrlKey${id}`, 'Redirect Url', 'Authorization Url', 'e.g., https://example.com/oauth2/callback', 'e.g., https://accounts.example.com/o/oauth2/auth', 'redirect_uri', 'authorization_url', true),
            ...generateInputPair(id, `securityOauth2TokenUrlKey${id}`, `securityOauth2Scope${id}`, 'Token Url', 'Scope', 'e.g., https://accounts.example.com/o/oauth2/access-token', 'e.g., profile email', 'token_url', 'scope', true),
          }
          break;
        default:
          return {};
          break;
      }
    }

    // Select the Security OAuth type
    if (type === 'securityOauth') {
      switch (securityType) {
        case 'headers':
          return generateInputPair(id, `securityHeaderClientId${id}`, `securityHeaderClientSecret${id}`, 'Client Id', 'Client Secret', 'e.g., 1234567890abcdef', 'e.g., abcdef12345-67890fedcba', 'client_id', 'client_secret', true);
          break;
        case 'payload':
          return generateInputPair(id, `securityPayloadClientId${id}`, `securityPayloadClientSecret${id}`, 'Client Id', 'Client Secret', 'e.g., 1234567890abcdef', 'e.g., abcdef12345-67890fedcba', 'client_id', 'client_secret', true);
          break;
        case 'oauth2':
          return {
            ...generateInputPair(id, `securityOauth2ClientId${id}`, `securityOauth2ClientSecret${id}`, 'Client Id', 'Client Secret',  'e.g., 1234567890abcdef', 'e.g., abcdef12345-67890fedcba', 'client_id', 'client_secret', true),
            ...generateInputPair(id, `securityOauth2RedirectUriValue${id}`, `securityOauth2AuthorizationUrlKey${id}`, 'Redirect Url', 'Authorization Url', 'e.g., https://example.com/oauth2/callback', 'e.g., https://accounts.example.com/o/oauth2/auth', 'redirect_uri', 'authorization_url', true),
            ...generateInputPair(id, `securityOauth2TokenUrlKey${id}`, `securityOauth2Scope${id}`, 'Token Url', 'Scope', 'e.g., https://accounts.example.com/o/oauth2/access-token', 'e.g., profile email', 'token_url', 'scope', true),
          }
          break;
        default:
          return {};
          break;
      }
    }
  }

  const handleDelete = (id, type) => {
    const formStateCopy = {...formState};
    Object.keys(formStateCopy).forEach(key => {
      if (typeof formStateCopy[key] === 'object' && formStateCopy[key].id === id) {
        delete formStateCopy[key];
      }
    });
    setFormState(formStateCopy);

    if (type === 'requiredParams') {
      setRequiredParams(requiredParams.filter(item => item !== id));
    }
    if (type === 'optionalParams') {
      setOptionalParams(optionalParams.filter(item => item !== id));
    }
  }

  const addFieldsHandler = (type, securityType) => {
    // type: requiredParams | optionalParams | security
    const id = Math.floor(Math.random() * 1000000);
    const fields = generateFormSet(id, type, securityType);
    const formStateCopy = {...formState, security: {...formState.security, value: securityType ? securityType : ''}};

    if (type === 'requiredParams') {
      setRequiredParams([...requiredParams, id]);
    }
    if (type === 'optionalParams') {
      setOptionalParams([...optionalParams, id]);
    }
    if (type === 'security') {
      Object.keys(formStateCopy).forEach(key => {
        if (key.includes('security') && key.length > 8) {
          delete formStateCopy[key];
        }
      });
    }
    if (type === 'securityOauth') {
      Object.keys(formStateCopy).forEach(key => {
        if (key.includes('security') && key !== 'securityOauth' && key.length > 8) {
          delete formStateCopy[key];
        }
      });
    }

    setFormState({...formStateCopy, ...fields});
  }

  const handleChange = (event) => {
    const { target } = event;
    setFormState({...formState, [target.name]: {...formState[target.name], value: target.value}});

    if (target.name === 'security' || target.name === 'securityOauth') {
      addFieldsHandler(target.name, target.value);
    }

    if (target.name === 'name') {
      setName(target.value);
    }
  }

  const handleSave = (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    const status = checkFormForErrors(form, formState);

    if (status.hasErrors) {
      console.log('error', status)
      setFormState((prevState) => ({
        ...prevState,
        ...Object.fromEntries(
          Object.entries(status.updates).map(([key, update]) => [
            key,
            {
              ...prevState[key],
              errMsg: update.errMsg,
            },
          ])
        ),
      }));

      return;
    }
    setLoading(true);
    const formStateKeys = Object.keys(formState);
    const data = Object.fromEntries(formStateKeys.map(field => [field, form.get(field) || '']));

    const payload = {
      modelId: model?.id,
      type: 'function',
      functionName: formState.name.value,
      functionDescription: formState.description.value,
      apiEp: formState.url.value,
      apiType: formState.method.value.toUpperCase(),
    }

    if (data.security !== 'noSecurity') {
      payload.security = {
        auth_type: data.security
      }

      if (data.securityOauth) {
        payload.security.auth_method = data.securityOauth
      }
      Object.keys(formState).filter(item => item.includes('security') && item.length > 8).forEach(item => {
        payload.security[formState[item].dataKey] = data[item];
      });
    }

    if (requiredParams.length || optionalParams.length) {
      payload.parameters = {
        properties: {},
        required: [],
        type: 'object',
      }
    }

    if (requiredParams.length) {
      requiredParams.forEach(item => {
        const key = formState[`requiredParamsKey${item}`].value;
        const type = formState[`requiredParamsType${item}`].value;
        const description = formState[`requiredParamsDescription${item}`].value;
        payload.parameters.properties[key] = {
          description,
          type,
        }
        payload.parameters.required = [...payload.parameters.required, key];
      });
    }

    if (optionalParams.length) {
      optionalParams.forEach(item => {
        const key = formState[`optionalParamsKey${item}`].value;
        const type = formState[`optionalParamsType${item}`].value;
        const description = formState[`optionalParamsDescription${item}`].value;
        payload.parameters.properties[key] = {
          description,
          type,
        }
      });
    }

    controlledAPI({ignoreUser: true}).post('custom-functions/item', payload)
      .then(async (res) => {
        if (res.code < 400) {
          dispatch(
            interactWithUser({
              message: 'Settings saved',
              type: 'success',
            })
          );
        } else {
          dispatch(
            interactWithUser({
              message: 'Something went wrong. Please, try again later..',
              type: 'error',
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          interactWithUser({
            message: 'Something went wrong. Please, try again later..',
            type: 'error',
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const securityItems = Object.keys(formState).filter(item => (item.includes('securitybearer') || item.includes('securitybasic') || item.includes('securityheader')));
  const securityOauthEnabled = Boolean(Object.keys(formState).filter(item => item.includes('securityOauth')).length);

  // OAuth 2.0
  const securityPayloadItems = Object.keys(formState).filter(item => item.includes('securityPayload'));
  const securityHeaderItems = Object.keys(formState).filter(item => item.includes('securityHeader'));
  const securityOauth2Items = Object.keys(formState).filter(item => item.includes('securityOauth2'));

  return (
    <Grid container component="form" onSubmit={handleSave} noValidate>
      <Grid
        container
        rowSpacing={4}
        item
        xs={12}
        noValidate
        sx={{ margin: "auto" }}
        className="form_fields"
      >
        <Grid item px={2} xs={12}>
          <Box className={styles.row}>
            <Title text="Custom Tool Name" hintText="Name your custom tool for easy identification." />
            <Box className={styles.rowIn}>
              <Box className={styles.inputsRow}>
                <CustomTextField
                  // label="Custom Tool Name"
                  placeholder="Enter Custom Tool Name"
                  name="name"
                  error={!!formState.name.errMsg}
                  // value={modelState[name].value || ''}
                  required={true}
                  fullWidth
                  rows={1}
                  multiline={false}
                  onChange={handleChange}
                />
                {Boolean(formState.name.errMsg) && <div className={styles.error}>{formState.name.errMsg}</div>}
              </Box>
            </Box>
          </Box>
          <Box className={styles.row}>
            <Title text="Function Description" hintText="Provide a clear and concise description of what the API function does." />
            <Box className={styles.rowIn}>
              <Box className={styles.inputsRow}>
                <CustomTextField
                  // label="Function Description"
                  placeholder="e.g., Retrieves user profile data including name, email, and profile picture."
                  name="description"
                  error={!!formState.description.errMsg}
                  // value={modelState[name].value || ''}
                  required={true}
                  fullWidth
                  rows={4}
                  multiline
                  onChange={handleChange}
                />
                {Boolean(formState.description.errMsg) && <div className={styles.error}>{formState.description.errMsg}</div>}
              </Box>
            </Box>
          </Box>
          <Box className={styles.row}>
            <Title text="HTTP Method" hintText="Choose the HTTP method your API will use (GET, POST, etc.)." />
            <Box className={styles.rowIn}>
              <Box className={styles.inputsRow}>
                <Select
                  name="method"
                  defaultValue="get"
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="get">GET</MenuItem>
                  <MenuItem value="post">POST</MenuItem>
                  <MenuItem value="delete">DELETE</MenuItem>
                  <MenuItem value="put">PUT</MenuItem>
                  <MenuItem value="patch">PATCH</MenuItem>
                </Select>
                {/* {Boolean(formState.name.errMsg) && <div className={styles.error}>{formState.name.errMsg}</div>} */}
              </Box>
            </Box>
          </Box>
          <Box className={styles.row}>
            <Title text="API Endpoint URL" hintText="Specify the endpoint URL. Use curly braces for dynamic variables that reflects parameters configurations." />
            <Box className={styles.rowIn}>
              <Box className={styles.inputsRow}>
                <CustomTextField
                  // label="API Endpoint URL"
                  placeholder="https://api.example.com/data/{parameter}"
                  name="url"
                  error={!!formState.url.errMsg}
                  // value={modelState[name].value || ''}
                  required={true}
                  fullWidth
                  rows={1}
                  multiline={false}
                  onChange={handleChange}
                />
                {Boolean(formState.url.errMsg) && <div className={styles.error}>{formState.url.errMsg}</div>}
              </Box>
            </Box>
          </Box>
          <Box className={classnames(styles.row, styles.centerBtns, !optionalParams.length && !requiredParams.length && styles.centerBtnsInline)}>
            {/* <Title text="Body" hintText="test" />
            <Box className={styles.rowIn}>
              <Box className={styles.inputsRow}>
                <Select
                  name="body"
                  defaultValue="json"
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="json">JSON</MenuItem>
                  <MenuItem value="raw">RAW</MenuItem>
                </Select>
              </Box>
            </Box> */}
            {requiredParams.map((item, i) => {
              const keyName = `requiredParamsKey${item}`;
              const typeName = `requiredParamsType${item}`;
              const descriptionName = `requiredParamsDescription${item}`;

              return (
                <Box key={`${item}${i}`}>
                  <Box className={styles.row}>
                    <Title text="Required Parameters" hintText="Add all required parameter key-value pairs." />
                    <Box className={styles.rowIn}>
                      <Box className={styles.inputsRow}>
                        <CustomTextField
                          // label="Enter Key"
                          placeholder="Enter Key"
                          name={keyName}
                          value={formState[keyName].value || ''}
                          required={false}
                          fullWidth
                          rows={1}
                          multiline={false}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box className={styles.inputsRow}>
                        <CustomTextField
                          // label="Enter Type"
                          placeholder="Enter Type"
                          name={typeName}
                          // error={!!modelState[name].errMsg}
                          value={formState[typeName].value || ''}
                          required={false}
                          fullWidth
                          rows={1}
                          multiline={false}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <IconButton onClick={() => handleDelete(item, 'requiredParams')}>
                          <DeleteIcon color="icons" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowIn}>
                      <Box className={styles.inputsRow}>
                        <CustomTextField
                          // label="Enter Description"
                          placeholder="e.g., Unique identifier of the user for which profile data is being requested, e.g., 42"
                          name={descriptionName}
                          // error={!!modelState[name].errMsg}
                          value={formState[descriptionName].value || ''}
                          required={false}
                          fullWidth
                          rows={4}
                          multiline
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })}
            <Box sx={{ padding: '5px 7px', marginBottom: '10px' }}>
              <Button
                sx={{
                  fontSize: '0.75rem',
                  width: {xs: '90%', sm: 'auto'},
                }}
                // variant="outlined"
                onClick={() => addFieldsHandler('requiredParams')}
              >Add Required Parameters</Button>
            </Box>
            {optionalParams.map((item, i) => {
              const keyName = `optionalParamsKey${item}`;
              const typeName = `optionalParamsType${item}`;
              const descriptionName = `optionalParamsDescription${item}`;

              return (
                <Box key={`${item}${i}`}>
                  <Box className={styles.row}>
                    <Title text="Optional Parameters (optional)" hintText="Add any optional parameter key-value pairs for unique use cases." />
                    <Box className={styles.rowIn}>
                      <Box className={styles.inputsRow}>
                        <CustomTextField
                          // label="Enter Key"
                          placeholder="Enter Key"
                          name={keyName}
                          // error={!!modelState[name].errMsg}
                          value={formState[keyName].value || ''}
                          required={false}
                          fullWidth
                          rows={1}
                          multiline={false}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box className={styles.inputsRow}>
                        <CustomTextField
                          // label="Enter Tape"
                          placeholder="Enter Type"
                          name={typeName}
                          // error={!!modelState[name].errMsg}
                          value={formState[typeName].value || ''}
                          required={false}
                          fullWidth
                          rows={1}
                          multiline={false}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <IconButton onClick={() => handleDelete(item, 'optionalParams')}>
                          <DeleteIcon color="icons" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowIn}>
                      <Box className={styles.inputsRow}>
                        <CustomTextField
                          // label="Description"
                          placeholder="e.g., Flag indicating whether to include the user's email address in the response, e.g., true"
                          name={descriptionName}
                          // error={!!modelState[name].errMsg}
                          value={formState[descriptionName].value || ''}
                          required={false}
                          fullWidth
                          rows={4}
                          multiline
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })}
            <Box sx={{ padding: '5px 7px', marginBottom: '10px' }}>
              <Button
                sx={{
                  fontSize: '0.75rem',
                  width: {xs: '90%', sm: 'auto'},
                }}
                // variant="outlined"
                onClick={() => addFieldsHandler('optionalParams')}
              >Add Optional Parameters</Button>
            </Box>
          </Box>
          <Box className={styles.row}>
            <Title text="Security Type" hintText="Select the type of security mechanism your API uses." />
            <Box className={styles.rowIn}>
              <Box className={styles.inputsRow}>
                <Select
                  name="security"
                  defaultValue="noSecurity"
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="noSecurity">Security configuration is not specified</MenuItem>
                  <MenuItem value="header">Custom Header</MenuItem>
                  <MenuItem value="bearer">Bearer</MenuItem>
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="oauth">OAuth</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
          {securityOauthEnabled && (
            <Box className={styles.row}>
              <Title text="Oauth Type" hintText="Choose the OAuth version used for authorization. Common types include OAuth Payload, OAuth Headers and OAuth 2.0, etc." />
              <Box className={styles.rowIn}>
                <Box className={styles.inputsRow}>
                  <Select
                    name="securityOauth"
                    defaultValue="oauth2"
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItem value="headers">OAuth Header</MenuItem>
                    <MenuItem value="payload">OAuth Payload</MenuItem>
                    <MenuItem value="oauth2">OAuth2</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          )}
          {Boolean(securityItems.length) && (
            <Title text={
              securityItems[0].includes('securitybearer') ? 
                'Bearer Token' : securityItems[0].includes('securitybasic') ?
                'Username & Password' : 'Custom Header'
            } hintText={
              securityItems[0].includes('securitybearer') ? 
                'Enter the JWT bearer token for API authorization.' : securityItems[0].includes('securitybasic') ?
                'Provide your username and password for basic authentication.' : 'Enter the name and value of the custom header required for API access.'
            } />
          )}
          {securityItems?.map((item, i) => {
            return (
              <Box key={`${item}${i}`}>
                <Box className={styles.row}>
                  <Box className={styles.rowIn}>
                    <Box className={styles.inputsRow}>
                      <CustomTextField
                        // label={formState[item].label}
                        placeholder={formState[item].label}
                        name={item}
                        // error={!!modelState[name].errMsg}
                        // value={modelState[name].value || ''}
                        required={false}
                        fullWidth
                        rows={1}
                        multiline={false}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
          {Boolean(securityPayloadItems.length) && <Title text="OAuth Payload" hintText="Provide the client ID and secret as part of the OAuth payload." extraMargin />}
          {securityPayloadItems?.map((item, i) => {
            return (
              <Box key={`securityPayloadItems${item}${i}`}>
                <Box className={styles.row}>
                  <Box className={styles.rowIn}>
                    <Box className={styles.inputsRow}>
                      <CustomTextField
                        label={formState[item].label}
                        InputLabelProps={{ shrink: true }}
                        placeholder={formState[item].placeholder}
                        name={item}
                        error={!!formState[item].errMsg}
                        // value={modelState[name].value || ''}
                        required={true}
                        fullWidth
                        rows={1}
                        multiline={false}
                        onChange={handleChange}
                      />
                      {Boolean(formState[item].errMsg) && <div className={styles.error}>{formState[item].errMsg}</div>}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
          {Boolean(securityHeaderItems.length) && <Title text="OAuth Headers" hintText="Enter the client ID and secret to be sent as headers for OAuth." extraMargin />}
          {securityHeaderItems?.map((item, i) => {
            return (
              <Box key={`securityHeaderItems${item}${i}`}>
                <Box className={styles.row}>
                  <Box className={styles.rowIn}>
                    <Box className={styles.inputsRow}>
                      <CustomTextField
                        label={formState[item].label}
                        InputLabelProps={{ shrink: true }}
                        placeholder={formState[item].placeholder}
                        name={item}
                        error={!!formState[item].errMsg}
                        // value={modelState[name].value || ''}
                        required={true}
                        fullWidth
                        rows={1}
                        multiline={false}
                        onChange={handleChange}
                      />
                      {Boolean(formState[item].errMsg) && <div className={styles.error}>{formState[item].errMsg}</div>}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
          {Boolean(securityOauth2Items.length) && <Title text="OAuth2 Configuration" hintText="Enter the details required to initiate the OAuth2 authorization flow, including the client ID, secret, and the scopes your application requires. Recommended redirect url: https://younet.ai/portal." extraMargin />}
          {securityOauth2Items?.map((item, i) => {
            return (
              <Box key={`securityOauth2Items${item}${i}`}>
                <Box className={styles.row}>
                  <Box className={styles.rowIn}>
                    <Box className={styles.inputsRow}>
                      <CustomTextField
                        label={formState[item].label}
                        InputLabelProps={{ shrink: true }}
                        placeholder={formState[item].placeholder}
                        name={item}
                        error={!!formState[item].errMsg}
                        // value={modelState[name].value || ''}
                        required={true}
                        fullWidth
                        rows={1}
                        multiline={false}
                        onChange={handleChange}
                      />
                      {Boolean(formState[item].errMsg) && <div className={styles.error}>{formState[item].errMsg}</div>}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
          
        </Grid>
        
      </Grid>
      <Grid item xs={12} sx={{paddingLeft: '8px', paddingRight: '8px', textAlign: 'right'}}>
        <SaveDataButton type="submit" disabled={loading} />
      </Grid>
    </Grid>
  )
}

export default Form;
