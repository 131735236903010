import { Grid } from "@mui/material";
import styles from "./payment.module.css";
import { Outlet } from "react-router-dom";


const Payment = ({title}) => {

    return (
        <Grid container className={styles["container"]} >
            <Grid item xs={12} md={12}>
                {/* <Stripe> */}
                    <Outlet/>
                {/* </Stripe> */}
            </Grid>
        </Grid>
    )
}


export default Payment;