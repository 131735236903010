
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSource } from 'redux/chats';

 const SwapChatsTab = ({modelId, defaultValue = "chats"}) => {
  const dispatch = useDispatch(); 
  const nav = useNavigate();
  const source = useSelector(state => state.chats.source);

  const handleChange = (e, value) => {
    e.preventDefault()
    
    // if(value !== "shared") {
    //   dispatch(cleanChats({shared: true}));
    // }

    if (Boolean(value)) {
      dispatch(setSource({source:  value}));
    }
    nav("/playground");
  };

  return (
    <ToggleButtonGroup
      sx={{padding: "10px 20px" }}
      color="primary"
      value={source}
      exclusive
      onChange={handleChange}
      aria-label="Chat"
    >
      <ToggleButton value="default" color="primary" size="small" sx={{textTransform: "capitalize"}}>Chats</ToggleButton>
      <ToggleButton value="shared" sx={{textTransform: "capitalize"}}>Shared</ToggleButton>
    </ToggleButtonGroup>
  );
}


export default SwapChatsTab;