import Login from "pages/Login";
import SignUp from "pages/SignUp";
import ExternalLogin from "pages/ExternalLogin";
import SetToken from "components/auth/SetToken";
import ResetPassword from "pages/ResetPassword";
import SharedModel from "pages/SharedModel";
import VerifyPassword from "pages/ResetPassword/components/verifyPassword";
import UnSubscribeNewsLetter from "components/linkHooks/UnSubscribeNewsLetter";
import TrackingPixel from "pages/TrackingPixel";

const publicRoutes = [
  { path: '/verify-email/:verifyCode', element: <Login authType="verify" />},
  { path: '/facebook/login', element: <Login authType="facebook" />},
  { path: '/google/login', element: <Login authType="google"/>},
  { path: '/login', element: <Login />},
  { path: '/external/login', element: <ExternalLogin />},
  { path: '/external/login/google', element: <ExternalLogin manual="google"/>},
  { path: '/external/login/facebook', element: <ExternalLogin manual="facebook"/>},
  { path: '/external/set-token/:token', element: <SetToken/>},
  { path: '/sign-up', element: <SignUp />},
  { path: '/reset-password', element: <ResetPassword />},
  { path: '/verify-password/:hash', element: <VerifyPassword />},
  { path: '/portal/:hash', element: <VerifyPassword />},
  { path: '/unsubscribe/:verificationToken', element: <UnSubscribeNewsLetter />},
  { path: '/shared-model/accept/:hash', element: <SharedModel action="accept" />},
  { path: '/shared-model/reject/:hash', element: <SharedModel action="reject" />},
  { path: '/tracking', element: <TrackingPixel />},

  // /api/users/unsubscribe/token/{verificationToken}
  
  // { path: '/:modelHash', element: <ShareModel/>, exact: true }
];

export default publicRoutes;