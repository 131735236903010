export const encodeData = (content) => {

 // to UTF-8
 const encoder = new TextEncoder();
 const data = encoder.encode(content);

 // convert to Base64
 const base64 = btoa(String.fromCharCode(...new Uint8Array(data.buffer)));

 return base64;
}


export const decodeData = (content) => {
 // convert to normal
 // const normal = decodeURIComponent(escape(atob(content)));
 try {
  const normal = decodeURIComponent(escape(atob(content)));
  return normal;
 } catch (e) {
  console.warn(e)
  return content;
 }

}
