import { Skeleton } from "@mui/material";

const SmoothContent = ({ width = undefined, loading = true, children, height=15 }) => {

    if (loading) {
        return <Skeleton width={width} height={height} variant="text" animation="wave">{children}</Skeleton>
    }
    return children
};

export default SmoothContent;
