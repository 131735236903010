import React, {useEffect, useState} from 'react';
import {
    Typography,
    Box,
    Grid,
    Select,
    MenuItem,
    FormControl,
    Button,
    Divider,
    Avatar, Link
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import RocketIcon from '@mui/icons-material/Rocket';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import classnames from 'classnames';
import logo from 'assets/images/logo.png';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getPlansInfo, newCardStatus} from 'redux/billing';
import moment from 'moment';
import ComparePlans from '../ComparePlans';
import Subscriptions from './components/Subscriptions';
import styles from './subscribe.module.css';
import G2Logo from "components/logo/G2Logo";
import {sendUIEvent} from "../../../../../../events/analytics/google";

const defaultPrices = {
    "9": {
        price: 14.99,
        annual: 119.88,
    },
    "10": {
        price: 39,
        annual: 360,
    },
    "11": {
        price: 149,
        annual: 1428,
    },
    "12": {
        price: 369,
        annual: 3540,
    },
    "13": {
        price: 699,
        annual: 6708
    }
}

const unlimitedTemplate = ['Unlimited AI Model', 'Unlimited daily use', 'Unlimited data entry'];

// hardcoded for now. waiting for mykola to make api`s
// bullshit code
const support_info = {
    "1": {
        icon: <ConstructionIcon/>,
        content: ['200 credits', '1 AI Model', 'Limited daily use', 'Limited data entry'],
    },
    "2": {
        icon: <RocketIcon/>,
        content: ['2,000 credits', ...unlimitedTemplate]
    },
    "3": {
        icon: <WorkspacePremiumIcon/>,
        content: [...unlimitedTemplate],
    },
    "9": {
        icon: <RocketIcon/>,
        content: ['2,000 credits', ...unlimitedTemplate]
    },
    "10": {
        icon: <WorkspacePremiumIcon/>,
        content: [...unlimitedTemplate],
    }
};

const DEFAULT_PRO_PLAN_ID = 3;
const DEFAULT_PRO_PLAN_ID_YEARLY = 10;

const SubscribeTable = ({setSelectedPlan, currentPlanId = 0}) => {

    const {tariff_plans, loading, checkoutSession, planInfo} = useSelector((state) => state.billing, shallowEqual);


    // const proPlanOptions = [tariff_plans[2], tariff_plans[3], tariff_plans[4], tariff_plans[5]];

    const [isAnnual, setIsAnnual] = useState(false);
    const [proPlan, setProPlan] = useState(false);
    const [proPlanOptions, setProPlanOptions] = useState([]);

    const dispatch = useDispatch();

    const handlePlanChooseClick = (plan) => {
        setSelectedPlan(plan);
        sendUIEvent({name: `price_table_select_plan_${plan.id}`});
    };


    const handleChange = (event) => {
        const {value} = event.target;
        const selectedItem = proPlanOptions.find(item => item.id == value);

        setProPlan(selectedItem);
    };

    const changeAnualState = (state, track = true) => {

        let triggerNum = state ? DEFAULT_PRO_PLAN_ID_YEARLY : DEFAULT_PRO_PLAN_ID;

        const selectedItem = tariff_plans.find(item => item.id === triggerNum);

        setProPlan(selectedItem);
        setIsAnnual(state);

        if(track) {
            sendUIEvent({name: `price_table_annual_state_change_${state}`});
        }


    }

    useEffect(() => {
        if (tariff_plans.length == 0) {
            dispatch(getPlansInfo());
        }
        if (checkoutSession.isNewCard) {
            dispatch(newCardStatus({isNewCard: false}))
        }
    }, []);

    useEffect(() => {

        if (tariff_plans.length > 0 && currentPlanId != 0) {

            const currentProPlan = tariff_plans.find(item => item.id === currentPlanId);
            const isTrialPlan = currentPlanId === 1;

            if (currentProPlan && currentProPlan.is_pro == 1) {
                setProPlan(currentProPlan);
                setIsAnnual(currentProPlan.frequency == "yearly");
            } else {
                changeAnualState(currentProPlan.frequency == "yearly", false);
            }

            let proPlans = tariff_plans.filter((item) => item.is_pro === 1);

            setProPlanOptions(proPlans);
        }

    }, [tariff_plans, currentPlanId])


    if (loading || !proPlan) {
        return <></>;
    }

    const nextRenewalDate = planInfo.nextChargeDate;
    const nextRenewalDateLocalized = moment(nextRenewalDate?.date).local().format('YYYY-MM-DD');

    return (
        <Grid container className={styles["container"]}>
            {/* <Grid item xs={12} md={12} mt={4}>
        <Typography px={2} sx={{ fontSize: 20, textAlign: 'center', fontWeight: '600' }}> for your dayly use</Typography>
      </Grid> */}
            {/* <Grid item xs={12} md={12}>
                <Typography
                    sx={{ fontSize: 24 }}>{steps[activeStep]?.title || "Checkout"}</Typography>
            </Grid> */}
            <Subscriptions/>
            <Grid item xs={12} md={12} p={6} display="flex" justifyContent="center">
                <G2Logo/>
            </Grid>
            <Grid pt={4} mb={4} className={styles.annualSelect} py={6} justifyContent="center" display="flex">

                <Box sx={{backgroundColor: '#e0e0e0', borderRadius: '5px', padding: '3px'}}>
                    <Button className={classnames(styles.annualCta, isAnnual && styles.selected)} onClick={() => {
                        changeAnualState(true)
                    }}>Annual <span className={styles.annualBadge}>-20%</span></Button>
                    <Button className={classnames(styles.annualCta, !isAnnual && styles.selected)} onClick={() => {
                        changeAnualState(false)
                    }}>Monthly</Button>
                </Box>
                {/* <Box sx={{ backgroundColor: '#e0e0e0', borderRadius: '5px', padding: '3px' }}>
          <Button className={classnames(styles.annualCta, !isAnnual && styles.selected)} onClick={() => { setIsAnnual(false) }}>Monthly</Button>
        </Box> */}
            </Grid>
            <Grid item xs={12} md={12} className={styles.wrapper}>
                {tariff_plans.map((item, i) => {

                    if (item.id != 1 && (
                        (isAnnual && item.frequency === "monthly")
                        || (!isAnnual && item.frequency === "yearly")
                        || (item.is_pro == 1
                            && (item.id != 3 && item.id != 10)))) {
                        return;
                    }

                    const isProPlan = item.id == DEFAULT_PRO_PLAN_ID || item.id == DEFAULT_PRO_PLAN_ID_YEARLY;
                    const isAnnualPay = item.id == 9 || item.id == DEFAULT_PRO_PLAN_ID_YEARLY;

                    return (
                        <Box key={`plan-${i}`} className={classnames(styles.box, item.is_pro && styles.popular)}>
                            {Boolean(item.is_pro) && <div className={styles.badge}>Most Popular</div>}
                            <Box className={styles.headerBlock}>
                                <Box className={styles.avatarBlock}>
                                    <Avatar sx={{bgcolor: '#3976ef'}}>

                                        {support_info[item.id]?.icon}
                                    </Avatar>
                                </Box>
                                <Box>
                                    {item.is_pro != 1 ? (
                                        <div className={styles.title}>{item.name}</div>
                                    ) : (
                                        <Box mb={2}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={proPlan.id || DEFAULT_PRO_PLAN_ID}
                                                    onChange={handleChange}
                                                >
                                                    {proPlanOptions?.map((optionItem, optionI) => {


                                                        if ((isAnnual && optionItem.frequency === "monthly") || (!isAnnual && optionItem.frequency === "yearly")) {
                                                            return;
                                                        }

                                                        const lastOption = optionI + 1 === proPlanOptions.length;

                                                        const formattedPrice = optionItem.amount / 1000;

                                                        return (
                                                            <MenuItem key={`variant-${optionItem.id}`}
                                                                      value={optionItem.id} py={3}
                                                                      divider={!lastOption}>
                                                                <div className={styles.select}>
                                                                    <div className={styles.title}>Pro</div>
                                                                    <div
                                                                        className={styles.selectAmount}>{formattedPrice}k
                                                                    </div>
                                                                </div>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )}
                                    <div className={styles.text}>{item.description}</div>
                                </Box>
                            </Box>
                            <Typography className={styles.priceBlock} mb={8} sx={{
                                display: 'block',
                                fontSize: '20px',
                                lineHeight: '1.4',
                                position: "relative"
                            }} variant="p" mt={8}>
                                {isAnnual ? (
                                    <>
                                        {item.id != 1 &&
                                            <span className={styles.fullPrice}>
                        ${item.id == DEFAULT_PRO_PLAN_ID_YEARLY ? defaultPrices[proPlan.id].price : defaultPrices[item.id].price ?? item.price}
                      </span>
                                        }

                                        <span className={styles.finalPrice}>
                      {`$${(isProPlan ? proPlan.price : item.price) / 12}`}
                    </span>
                                        <span className={styles.month}>/mon</span>
                                        {isProPlan ? proPlan.id == currentPlanId : item.id == currentPlanId &&
                                            <span
                                                className={styles.renewal}>Next renewal: {nextRenewalDateLocalized} </span>
                                        }

                                    </>
                                ) : (
                                    <>
                    <span className={styles.finalPrice}>
                      {`$${isProPlan ? proPlan.price : item.price}`}
                    </span>
                                        <span className={styles.month}>/mon</span>
                                        {isProPlan ? proPlan.id == currentPlanId : item.id == currentPlanId &&
                                            <span
                                                className={styles.renewal}>Next renewal: {nextRenewalDateLocalized} </span>
                                        }


                                    </>
                                )}
                            </Typography>
                            <Divider className={styles.divider}/>
                            <Box className={styles.listBlock}>
                                {isProPlan && (
                                    <div className={styles.details}>
                                        <TaskAltIcon fontSize='small'/>
                                        <span>{proPlan.amount.toLocaleString()} credits</span>
                                    </div>
                                )}
                                {support_info[item.id]?.content?.map((contentItem, contentI) => {
                                    return (
                                        <div key={`content-${contentItem.id}-${contentI}`} className={styles.details}>
                                            <TaskAltIcon fontSize='small'/>
                                            <span>{contentItem}</span>
                                        </div>
                                    )
                                })}
                            </Box>
                            <Box className={classnames(styles.ctaBlock, isAnnual && isAnnualPay && styles.noBtmMargin)}>
                                {isProPlan ?
                                    <Button
                                        onClick={() => proPlan.id != currentPlanId && handlePlanChooseClick(proPlan)}
                                        fullWidth
                                        variant={proPlan.id == currentPlanId ? 'contained' : 'outlined'}>{proPlan.id == currentPlanId ? "Selected" : "Get Started"}</Button> :
                                    <Button onClick={() => item.id != currentPlanId && handlePlanChooseClick(item)}
                                            fullWidth
                                            variant={item.id == currentPlanId ? 'contained' : 'outlined'}>{item.id == currentPlanId ? "Selected" : "Get Started"}</Button>
                                }

                                {/*<div className={styles.supportInfoContainer}>*/}
                                {item.id !== 1 &&
                                    <div className={styles.annualPay}>
                                        <span>*cancel anytime</span>
                                    </div>}

                                {isAnnual && isAnnualPay &&
                                    <div className={styles.annualPay}>
                                        <span>**Billed annually ${item.id == DEFAULT_PRO_PLAN_ID_YEARLY ? defaultPrices[proPlan.id].annual : defaultPrices[item.id] ? defaultPrices[item.id].annual : ''}</span>
                                    </div>
                                }

                                {/*</div>*/}

                            </Box>
                            <Divider className={classnames(styles.divider,styles.bottomLine,item.id === 1 && (isAnnual ? styles.bottomLineTrialAnnual :  styles.bottomLineTrial) )}/>
                        </Box>
                    )
                })}
                <Box className={styles.box}>
                    <Box className={styles.customTitleBox}>
                        <Box className={styles.avatarBlock}>
                            <Avatar sx={{bgcolor: '#3976ef'}}>
                                <img src={logo} alt='younet-logo' width='40px'/>
                            </Avatar>
                        </Box>
                        <Box>
                            <div className={styles.title}>Managed Service</div>
                            <div className={styles.text}>Pro choice for unlimited AI tasks and business needs.</div>
                        </Box>
                    </Box>
                    {/* <Typography sx={{ display: 'block', fontSize: '10px', lineHeight: '1.2', color: '#707070' }} variant="p" mt={2}>
            Starting at
          </Typography>
          <Typography mb={2} sx={{ display: 'block', fontSize: '20px', lineHeight: '1.4' }} variant="p">
            <span className={styles.finalPrice}>
              $4,999
            </span>
            <span className={styles.month}>/mon</span>
          </Typography> */}
                    {/* <Divider className={styles.divider} /> */}
                    <Box mt={6} mb={6}>
                        <Button onClick={() => {sendUIEvent({name: `price_table_request_demo_click`}); window.href='https://support.younet.ai/submit-ticket/2-Sales'}} target="_blank" fullWidth
                                variant="outlined">Request a demo</Button>
                    </Box>
                    <Divider className={styles.divider}/>
                    <Box mt={6}>
                        <Typography sx={{display: 'block', fontWeight: '600', fontSize: '14px', lineHeight: '1.4'}}
                                    variant="p">
                            What's included
                        </Typography>
                        {['Custom Younet AI Integration', 'Consulting', 'Enterprise support', 'Big data integration', 'Professional training'].map((contentItem, contentI) => {
                            return (
                                <div key={`content-custom-${contentI}`} className={styles.details}>
                                    <TaskAltIcon fontSize='small'/>
                                    <span>{contentItem}</span>
                                </div>
                            )
                        })}
                    </Box>
                </Box>
                <ComparePlans/>
            </Grid>
        </Grid>
    )
}

export default SubscribeTable;
