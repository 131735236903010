import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ShadowSpinner from 'components/loadings/ShadowSpinner';

const TrackingPixel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('genai', 1);

    navigate('/login');
  }, []);

  return (
    <ShadowSpinner open={true}/>
  )
};

export default TrackingPixel;
