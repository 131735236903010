import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import StatsCard from 'components/showcase/cards/StatsCard';
import { shallowEqual, useSelector } from 'react-redux';
import SmoothContent from 'components/loadings/SmoothContent';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import GeneralTooltip from "components/tooltip/GeneralTooltip";


const CardWrapper = styled(StatsCard)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark,
    padding: '25px 15px',
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.secondary[800],
        borderRadius: '50%',
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.secondary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));



const TotalEarningsCard = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const isSubPage = location.pathname.includes('/subs');
    const btnOpacity = isSubPage ? 1 : 0.5;
    const btnSize = "large";
    const { total, current, loading } = useSelector((state) => state.monetization.earnings);
    const [isTotal, setTotal] = useState(false);

    const handleRedirect = (e, path = '') => {
      e.preventDefault();
      navigate(`/monetization/${path}`);
    }
    const toggleButtonHandler = (e, state) => {
      e.preventDefault();
      setTotal(state);
    }
    const labelValues = isTotal ? {
      label: 'Total',
      value: total
    } : {
      label: 'Current',
      value: current
    }

    return (
        <CardWrapper border={true} content={false} >
            <Box sx={{ p: 2.25 }}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <PaymentsOutlinedIcon />
                            </Grid>
                            <Grid item sx={{ zIndex: 1 }}>
                              <GeneralTooltip
                                placement="bottom"
                                title="Your earnings awaiting payment. This amount reflects what you've earned from leases since your last invoice."
                              >
                                <Button
                                    disableElevation
                                    variant={!isTotal ? 'contained' : 'text'}
                                    size="small"
                                    sx={{ color: 'inherit', padding: '4px 10px',fontSize: '12px', textTransform: "capitalize" }}
                                    onClick={(e) => toggleButtonHandler(e, false)}
                                >
                                    Current
                                </Button>
                              </GeneralTooltip>
                              <GeneralTooltip
                                placement="bottom"
                                title="The sum of all earnings from leasing your AI models, including both invoiced and pending amounts."
                              >
                                <Button
                                    disableElevation
                                    variant={isTotal ? 'contained' : 'text'}
                                    size="small"
                                    sx={{ color: 'inherit',fontSize: '12px', textTransform: "capitalize" }}
                                    onClick={(e) => toggleButtonHandler(e, true)}
                                >
                                    Total
                                </Button>
                              </GeneralTooltip>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item>
                        <SmoothContent loading={loading} height={55}>
                            <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                ${labelValues.value > 0 ? labelValues.value.toFixed(2) : 0}
                            </Typography>
                        </SmoothContent>
                    </Grid>

                    <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: -1.25 }}>
                        <Grid item >
                            <Typography
                              sx={{
                                fontSize: '1rem',
                                fontWeight: 500,
                                color: theme.palette.secondary[200]
                              }}
                              >
                              {labelValues.label} Earnings
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                disableElevation
                                variant="contained"
                                // color='primary'
                                // size="small"
                                sx={{ zIndex: 1, color: '#eee', padding: '8px 10px', backgroundColor: "rgba(0, 0, 0, .1)", display: "flex", alignItems: "center",  opacity: btnOpacity,textTransform: "capitalize" }}
                                // sx={{ color: theme.palette.secondary[200]}}
                                onClick={(e) => handleRedirect(e, '/invoices')}
                                >
                                <span>Invoices</span>
                                <ArrowCircleRightIcon fontSize={"medium"} sx={{ color: theme.palette.secondary[200], paddingLeft: "5px"}} />

                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </CardWrapper>
    );
};

export default TotalEarningsCard;
