import { Box} from '@mui/material';
import styles from '../subs-table.module.css';
import SmoothContent from 'components/loadings/SmoothContent';



const SubsLoading = () => {

    return (
        <Box className={styles.line}>

            <div className={styles.loadingCenter}>
                <SmoothContent width={100}></SmoothContent>
            </div>

            <div className={styles.loadingCenter}>
                <SmoothContent width={80} />
            </div>

            <div className={styles.actions}>
                <div className={styles.loadingIcons}>
                    <SmoothContent width={22} height={22} variant="circular" />
                </div>
            </div>
        </Box>
    );
};


export default SubsLoading;