import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api/index.js';

export const getListThunk = createAsyncThunk('invitations/list', async (modelId, thunkAPI) => {
    try {
      const response = await api.get(`model/invite/list/${modelId}`)

      let invitations =  response?.shared_model_invites || [];

      return { invitations }
    } catch(e){
      let message = e?.response?.message || e?.message || 'Something went wrong';
      return { message: message };
    }
});


export const deleteInvitation = createAsyncThunk('message/deleteInvitation', async (inviteId, thunkAPI) => {
  try{
    const response = await api.delete(`model/invite/delete/${inviteId}`);
    
    let {code, chat} = response || {};

    if(code >= 400 || !chat ) {
      throw new Error(response);
    }

    return {chat: chat}
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return {message: message}
  }
});

