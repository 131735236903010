import DeleteIcon from "@mui/icons-material/Delete";
import {IconButton} from "@mui/material";

const DeleteItem = ({onDelete = () => {}}) => {
    return (
       <IconButton size="small" color="icons" onClick={onDelete} ><DeleteIcon /></IconButton>

    )
}


export default DeleteItem;