import {Badge, Grid, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {accountLogout} from "../../../../../../../../../../redux/email";
import {useState} from "react";
import {styled} from '@mui/material/styles';

const StyledBadge = styled(Badge)(({theme}) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const AccountManageBtn = () => {


    const dispatch = useDispatch();
    const accountData = useSelector(state => state.email.account.current, shallowEqual);

    const [popup, setPopup] = useState(null);

    const handleGmailAccountMenu = (e) => {
        setPopup(e);

        const fetchAccounts = async () => {
            // await api
            //     .get("google/auth/account/list")
            //     .then((r) => {
            //         if (r.code >= 400) {
            //             return;
            //         }
            //         let accounts = r.account || "";
            //         setAccounts(accounts);
            //     })
            //
            //     .catch((error) => {
            //     });
            // setData(result.data);
        };

        // fetchAccounts();
    };
    const handleGmailLogout = () => {
        dispatch(accountLogout())
        // window.location.reload();
    };

    const handlePopupClose = () => {
        setPopup(null);
    }


    return (
        <>
            <Typography variant="email" sx={{paddingRight: "10px"}}>
                {accountData.email}
            </Typography>
            <IconButton
                onClick={(e) => {
                    handleGmailAccountMenu(e.currentTarget);
                }}
            >
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    variant="dot"
                >
                    <Avatar
                        sx={{width: "26px", height: "26px"}}
                        alt={"gmail user photo"}
                        src={accountData.photo || ""}
                    />
                </StyledBadge>
            </IconButton>
            <Menu
                anchorEl={popup}
                open={Boolean(popup)}
                onClose={handlePopupClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {/*not sure this feature is essential yet*/}
                {/*{accounts.length > 0 &&*/}
                {/*    accounts.map((acc, index) => (*/}
                {/*        <MenuItem key={acc.id}>*/}
                {/*            <Avatar*/}
                {/*                sx={{width: "26px", height: "26px"}}*/}
                {/*                alt={"gmail user photo"}*/}
                {/*                src={acc.photo || ""}*/}
                {/*            />*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                <MenuItem onClick={() => handleGmailLogout()}>Logout</MenuItem>
            </Menu>
        </>
    )
}


export default AccountManageBtn;
