import { checkLimitations, checkPermissions } from "_helpers/checkPermissions";
import { useSelector } from "react-redux";
import { LockedTemplate, UpgradeTemplate } from "./components/Templates";


const list = {
  ROLE_USER: {
    message: {
      write: true,
      read: true,
      edit: true,
      delete: true,
    },
  },
};



const templates = {
  locked: <LockedTemplate />,
  upgrade: <UpgradeTemplate/>
};


const AccessChecker = ({
  userRole = "ROLE_USER",
  currentPermissions = ["*"],
  requiredPermissions,
  children,
  fallbackComponent = null,
  source = ""
}) => {


  const hasPermission =
    currentPermissions === null || currentPermissions.includes("*")
      ? true
      : checkPermissions(currentPermissions, requiredPermissions);

  // console.log("Has permission: ", " ", hasPermission)
  // console.log(source);

  let denyHanlderComponent =
    typeof fallbackComponent == "string"
      ? templates[fallbackComponent]
      : fallbackComponent;

    return hasPermission ? <>{children}</> : denyHanlderComponent
};


export const PlanChecker = ({children, limitationAction = "", fallbackComponent = "", visible=true}) => {

  let { planLimitations } = useSelector((state) => state.permissions);

  const isAnyLimitations = Object.keys(planLimitations).length !== 0;

  const hasPermission = !isAnyLimitations || checkLimitations(planLimitations,limitationAction);


  if(hasPermission) {
    return <>{children}</>;
  }

  
  let DenyHanlderComponent =
  typeof fallbackComponent == "string"
    ? templates[fallbackComponent]
    : fallbackComponent;


  return <>{DenyHanlderComponent}{visible && children}</>
}

// export default memo(AccessChecker, (prevProps, nextProps) => {
//   return JSON.stringify(prevProps.currentPermissions) === JSON.stringify(nextProps.currentPermissions) 
//   // && prevProps.cachedData == nextProps.cachedData
//   ;
// });


export default AccessChecker;