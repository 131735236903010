import { createSlice } from '@reduxjs/toolkit';
import { getAllCardsThunk, addNewCardThunk, updateCardThunk, updateCardWithHashThunk, deleteCardThunk, deleteFileThunk, addFilesThunk, getSharedCardsThunk } from './marketplaceThunk';

const initialState = {
  list: [],
  sharedCards: {
    pinnedPages: null,
    pinnedPage: null,
    pinnedCount: null,
    pinnedLimit: null,
    allPages: null,
    allPage: null,
    allCount: null,
    allLimit: null,
    pinnedItems: [],
    allItems: [],
  },
  onboardingCards: {
    pinnedPages: null,
    pinnedPage: null,
    pinnedCount: null,
    pinnedLimit: null,
    allPages: null,
    allPage: null,
    allCount: null,
    allLimit: null,
    pinnedItems: [],
    allItems: [],
  },
  errors: [],
  loading: false,
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
  globalLoading: false,
};

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    addCards(state, action) {
      const { cards } = action.payload;

      if (cards) {
        state.list = cards;
      }
    },
    addCard(state, action) {
      const { card } = action.payload;

      if (card) {
        state.list.push(card);
      }
    },
    updateCard(state, action) {
      const { id, title, description, miniDescription } = action.payload;

      const updatedCards = state.list.map(item => {
        if (item.id === id) {
          return { ...item, title, description, miniDescription }
        }
        return item;
      });

      state.list = updatedCards;
    },
    deleteCard(state, action) {
      const { id } = action.payload;

      const updatedCards = state.list.filter(item => item.id !== id);
      state.list = updatedCards;
    },
    addFile(state, action) {
      const { cardId, file } = action.payload;

      const updatedCards = state.list.map(item => {
        if (item.id === cardId) {
          return { ...item, gallery_items: [ ...item.gallery_items, file ] };
        }
        return item;
      });

      state.list = updatedCards;
    },
    deleteGalleryItem(state, action) {
      const { id, cardId } = action.payload;

      const updatedCards = state.list.map(item => {
        if (item.id === cardId) {
          const updatedGallery = item.gallery_items.filter(item => item.id !== id);
          return { ...item, gallery_items: updatedGallery }
        }
        return item;
      });

      state.list = updatedCards;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getAllCards.pending, (state, { _ }) => {
      state.globalLoading = true;
    });
    builder.addCase(getAllCards.rejected, (state, { _ }) => {
      state.globalLoading = false;
    });
    builder.addCase(getAllCards.fulfilled, (state, { payload }) => {
      const { cardsList } = payload;
      state.list = cardsList;
      state.globalLoading = false;
    });
    // Get a list on all shared models
    builder.addCase(getSharedCardsThunk.pending, (state, { _ }) => {
      state.loading = true;
    });
    builder.addCase(getSharedCardsThunk.rejected, (state, { _ }) => {
      state.loading = false;
    });
    builder.addCase(getSharedCardsThunk.fulfilled, (state, { payload }) => {
      const { data, isOnboarding, initialSearch } = payload;

      if (initialSearch) {
        state.onboardingCards = data;
        state.sharedCards = data;
      } else {
        if (isOnboarding) {
          state.onboardingCards = data;
        } else {
          state.sharedCards = data;
        }
      }
      state.loading = false;
    });
    // Add new card
    builder.addCase(addNewCardThunk.pending, (state, { _ }) => {
      state.createLoading = true;
    });
    builder.addCase(addNewCardThunk.rejected, (state, { _ }) => {
      state.createLoading = false;
    });
    builder.addCase(addNewCardThunk.fulfilled, (state, { payload }) => {
      const { card } = payload;
      state.createLoading = false;

      if (!Boolean(card)) return;

      if (Boolean(state.list.filter(item => item.id === card?.id)?.[0])) {
        const updatedList = state.list.map(item => {
          if (item.id === card?.id) {
            return card;
          }
  
          return item;
        });

        state.list = updatedList;
      } else {
        state.list = [...state.list, card];
      }
    });

    // Update a card
    builder.addCase(updateCardThunk.pending, (state, { _ }) => {
      state.updateLoading = true;
    });
    builder.addCase(updateCardThunk.rejected, (state, { _ }) => {
      state.updateLoading = false;
    });
    builder.addCase(updateCardThunk.fulfilled, (state, { payload }) => {
      const { card } = payload;
      const { id, params } = card;

      const { title, description, miniDescription } = params;
      const updatedCards = state.list.map(item => {
        if (item.id === id) {
          return { ...item, title, description, mini_description: miniDescription }
        }

        return item;
      });

      state.list = updatedCards;
      state.updateLoading = false;
    });

    // Update a card with hash
    builder.addCase(updateCardWithHashThunk.pending, (state, { _ }) => {
      state.updateLoading = true;
    });
    builder.addCase(updateCardWithHashThunk.rejected, (state, { _ }) => {
      state.updateLoading = false;
    });
    builder.addCase(updateCardWithHashThunk.fulfilled, (state, { payload }) => {
      const { card } = payload;
      const { id, params } = card;
      if (!Boolean(id) && !Boolean(params)) return;

      const { title, description, miniDescription, urlHash } = params;
      const updatedCards = state.list.map(item => {
        if (item.id === id) {
          return { ...item, title, description, mini_description: miniDescription, url_hash: urlHash }
        }

        return item;
      });

      state.list = updatedCards;
      state.updateLoading = false;
    });
    // Delete a card
    builder.addCase(deleteCard.pending, (state, { _ }) => {
      state.deleteLoading = true;
    });
    builder.addCase(deleteCard.rejected, (state, { _ }) => {
      state.deleteLoading = false;
    });
    builder.addCase(deleteCard.fulfilled, (state, { payload }) => {
      const { id } = payload;
      const updatedCards = state.list.filter(item => item.id !== id);

      state.list = updatedCards;
      state.deleteLoading = false;
    });

    // Add files
    // builder.addCase(addFiles.pending, (state, { _ }) => {
    //   // state.deleteLoading = true;
    // });
    // builder.addCase(addFiles.rejected, (state, { _ }) => {
    //   // state.deleteLoading = false;
    // });
    builder.addCase(addFiles.fulfilled, (state, { payload }) => {
      const { files, cardId } = payload;
      const updatedCards = state.list.map(item => {
        if (item.id === cardId) {
          return { ...item, gallery_items: [ ...item.gallery_items, ...files ] };
        }
        return item;
      });

      state.list = updatedCards;
    });
  },
});

export const {
  addCards,
  addCard,
  addFile,
  deleteGalleryItem
} = marketplaceSlice.actions;

export const getAllCards = getAllCardsThunk;
export const getSharedCards = getSharedCardsThunk;
export const addNewCard = addNewCardThunk;
export const updateCard = updateCardThunk;
export const updateCardWithHash = updateCardWithHashThunk;
export const deleteCard = deleteCardThunk;
export const deleteFile = deleteFileThunk;
export const addFiles = addFilesThunk;

export default marketplaceSlice.reducer;
