import {useDispatch} from 'react-redux';
import {interactWithUser} from "redux/status";
import statuses from 'mappings/languages/en/interactiveStatus.json';


export const useStatusActions = () => {
    const dispatch = useDispatch();

    const dispatchStatus = ({type = 'success', code = '', msg = ''}) => {
        const displayMsg = statuses[code]?.[type] || msg;

        dispatch(
            interactWithUser({
                message: displayMsg,
                type: type,
            })
        );
    }

    const dispatchError = () => {
        dispatch(interactWithUser({template: "DEFAULT_ERROR"}));
    };

    return {dispatchError, dispatchStatus};
};

// export default useStatusActions;
