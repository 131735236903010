export const checkPermissions = (currentPermissions, requiredPermissions) => {
    currentPermissions = Array.isArray(currentPermissions)
        ? currentPermissions
        : ["*"];
    requiredPermissions = Array.isArray(requiredPermissions)
        ? requiredPermissions
        : ["*"];

    const currentSet = new Set(currentPermissions);
    const allowedSet = new Set(requiredPermissions);

    const hasPermission = [...allowedSet].some((permission) =>
        currentSet.has(permission)
    );

    return hasPermission;
};


export const checkLimitations = (planLimitations, routeLimitations) => {

    const limitation = planLimitations[routeLimitations] || null;

    if (limitation) {

        if (limitation.disabled == 1) {
            return false;
        }

    }

    return true;


    // currentPermissions = Array.isArray(currentPermissions)
    //   ? currentPermissions
    //   : ["*"];
    // requiredPermissions = Array.isArray(requiredPermissions)
    //   ? requiredPermissions
    //   : ["*"];

    // const currentSet = new Set(currentPermissions);
    // const allowedSet = new Set(requiredPermissions);

    // const hasPermission = [...allowedSet].some((permission) =>
    //   currentSet.has(permission)
    // );

};