import {changeModel} from "../redux/models";
import {cleanAccount} from "../redux/email";
import {cleanAllLinks} from "../redux/links";


export const changeModelAction = (dispatch, modelId) => {
    try {
        // Dispatch actions to change model and clean account
        dispatch(changeModel(modelId));
        dispatch(cleanAccount());
        dispatch(cleanAllLinks());
    } catch (error) {
        console.error('Error occurred while dispatching actions:', error);
    }
};


const modelActions = (dispatch) => {

    return {
        changeModel: (modelId) => changeModelAction(dispatch, modelId)
    };
};


export default modelActions;