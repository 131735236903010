import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const SaveEditBtns = ({
                          setEditMode, isEditMode, onSave = () => {
    }
                      }) => {

    const handleEditClose = () => {
        setEditMode(false)
    }
    const handleEditClick = () => {
        setEditMode(true)
    }

    const saveBtnClick = () => {

        onSave();
    }
    return (

        isEditMode ? (
            <>
                <IconButton
                    onClick={handleEditClose}
                    size="small"
                >
                    <CloseIcon fontSize="small"/>
                </IconButton>
                <IconButton
                    sx={{marginLeft: '5px'}}
                    size="small"
                    onClick={() => saveBtnClick()}
                >
                    <SaveIcon fontSize="small"/>
                </IconButton>
            </>
        ) : (
            <>
                {/*<IconButton*/}
                {/*    // onClick={() => handleDeleteRows(data.vector_id)}*/}
                {/*>*/}
                {/*    <DeleteIcon color="icons"/>*/}
                {/*</IconButton>*/}
                <IconButton
                    onClick={() => handleEditClick()}
                >
                    <EditIcon color="icons" fontSize="small"/>
                </IconButton>
            </>
        )

    )
}

export default SaveEditBtns;