import {Grid} from "@mui/material";
import EmailSignIn from "./components/EmailSignIn";
import {Outlet, useParams} from "react-router-dom";
import useEmailAuth from "./hooks/useEmailAuth";
import Description from "./components/EmailSignIn/Description";
import {shallowEqual, useSelector} from "react-redux";
import {useMemo} from "react";

const MailTraining = () => {

    const params = useParams();
    const emailType = params.type || "";
    const modelId = useSelector(state => state.models?.current?.id, shallowEqual);
    const {loading} = useEmailAuth(modelId, emailType);
    const accountId = useSelector(state => state.email.account.current?.id, shallowEqual);
    const isVerified = useMemo(() => Boolean(accountId), [accountId]);

    return (
        <Grid container rowSpacing={6} sx={{display: 'flex', height: '100%'}}>
            {/*<Grid item xs={12} sm={12} md={12} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
            {/*    <Description/>*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={12} md={12} sx={{height: 'calc(100% - 15px)'}}>
                {isVerified ? (
                    <Outlet/>
                ) : (
                    <EmailSignIn accounts={[]} loading={loading}/>
                )}
            </Grid>
        </Grid>

    );
};

export default MailTraining;
