import api from "_services/api";
import {
    Box,
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    Grid,
} from "@mui/material";
import {useState, useEffect, useRef, memo} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useOutletContext} from "react-router-dom";
import {decodeData, encodeData} from "utils/formatContent";
import {useDispatch, useSelector} from "react-redux";
import formatTime from "utils/formatTime";
import EastIcon from "@mui/icons-material/East";
import trainingTypes from "mappings/trainingTypes.json";
import {useNavigate} from "react-router-dom";
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {getBrainList, clearBrain} from "redux/tools";
import styles from '../../training.module.css';
import {sendUIEvent} from "../../../../../../events/analytics/google";

const BrainData = () => {

    const {modelId} = useOutletContext();
    const nav = useNavigate();
    const dispatch = useDispatch();
    const {brainList, brain, loading} = useSelector((state) => ({
        brainList: state.tools.brain.list,
        brain: state.tools.brain,
        loading: state.tools.loading
    }));

    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (brain.currentPage < newPage + 1) {
            dispatch(getBrainList({model_id: modelId, page: newPage + 1}));
        }
    };

    useEffect(() => {
        dispatch(clearBrain());
        dispatch(getBrainList({model_id: modelId, page: 1}));
    }, [modelId]);

    const handleRedirect = (id, path) => {
        nav(`${path}/${id}`);
        sendUIEvent({name: `brain_tool_view_item`});
    }

    const Row = memo((props) => {
        const {row} = props;
        const {date} = row.created_at;
        const calculatedDate = formatTime({date: date});

        const title = decodeData(row.title) || '...';
        const data = decodeData(row.data) || '...';
        const typeName = trainingTypes[row.data_type].name || 'Data';
        const typePath = trainingTypes[row.data_type].path;

        return (
            <TableRow sx={{"& > *": {borderBottom: "unset"}}}>
                <TableCell component="th" scope="row" className={styles.trim}>
                    {title}
                </TableCell>
                <TableCell component="th" scope="row" sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "250px"
                }}>
                    {typeName}
                </TableCell>
                <TableCell sx={{padding: "5px 10px 5px 0"}} align="right">
                    {typePath && <IconButton
                        onClick={() => handleRedirect(row.id, typePath)}
                    >
                        <VisibilityIcon/>
                    </IconButton>
                    }
                </TableCell>
            </TableRow>
        );
    });

    const startIndex = page * 10;
    const endIndex = startIndex + 10;
    const currentData = brainList.slice(startIndex, endIndex);

    return (
        <Grid
            container
            spacing={6}
        >

            <Grid item xs={12} md={12}>
                <TableContainer
                    component={Paper}
                    // sx={{maxHeight: '300px'}}
                >
                    <Table aria-label="collapsible table" fixedheader="true" fixedfooter="true">
                        <TableHead>
                            <TableRow
                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                            >

                                <TableCell>Title</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell align="right" sx={{paddingRight: "20px"}}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <>
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Skeleton animation="wave"/>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Skeleton animation="wave"/>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Skeleton animation="wave"/>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Skeleton animation="wave"/>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : currentData ? (
                                currentData.map((row) => <Row key={row.id} row={row}/>)
                            ) : null}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={brain.cellsAmount}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={handleChangePage}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default BrainData;
