import { Box } from "@mui/material";
import SubItem from "./SubItem";
import SubsLoading from "./SubsLoading";
import styles from '../subs-table.module.css';
import { memo } from "react";
import SubItemSmall from "./SubItemSmall";

const LOADING_PRESET_NUM = 5;

const Loading = () => {
  return (
    [...Array(LOADING_PRESET_NUM)].map((_, i) => (
      <SubsLoading key={`subs-loading-${i}`} />
    ))
  )
};

const LoadingSmall = () => {
  return (
    [...Array(LOADING_PRESET_NUM)].map((_, i) => (
      <SubsLoading key={`subs-loading-${i}`} />
    ))
  )
};

const Subs = ({ list }) => (
  list.map((item) => (
    <SubItem key={`subs-${item.id}`} item={item} />
  ))
);

const SubsSmall = ({list}) => (
  list.map((item) => (
    <SubItemSmall key={`subs-small-${item.id}`} item={item} />
  ))
);

const NoSubsLabel = () => (
  <Box className={styles.wrapper} py={4}>
    <div className={styles.commingSoon}>No Subscribers yet</div>
  </Box>
);

export const SubsItems = memo(Subs);
export const SubsSmallItems = memo(SubsSmall);
export const NoSubs = memo(NoSubsLabel);
export const LoadingItems = memo(Loading);
export const LoadingSmallItems = memo(LoadingSmall);