import {memo} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";


export const BtnSettings = memo(({
                              handleChanges = () => {
                              }
                          }) => {

    const onLabelChange = (e) => {
        const value = e.target.value;

        handleChanges({label: value});
    }

    const onImgChange = (e) => {
        const value = e.target.value;

        handleChanges({btnImg: value});
        // const urlStatus = useMemo(() => {
        //     return isInputValid(url || '', {type: 'website'});
        // }, [url])
    }


    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <TextField
                    variant="filled"
                    placeholder={'Add your label text for the button'}
                    sx={{opacity: 0.6}}
                    defaultValue="Hello There"
                    fullWidth
                    label="Button Label"
                    onChange={onLabelChange}
                    InputProps={{
                        style: {paddingRight: "5px"},
                    }}

                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="filled"
                    placeholder={'Add your icon url for the button (Ex: https://younet.ai/icon)'}
                    sx={{opacity: 0.6}}
                    defaultValue={''}
                    fullWidth
                    label="Button Image"
                    onChange={onImgChange}
                    InputProps={{
                        style: {paddingRight: "5px"},
                    }}

                />
            </Grid>
        </Grid>
    )
})