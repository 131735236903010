import { createSlice } from "@reduxjs/toolkit";
import { getInfo, setInfo, updateInfo, deleteInfo, unsubscribeThunk } from "./monetizationThunk";

const initialState = {
  earnings: {
    loading: true,
    total: 0,
    current: 0,
    pending: 0,
    list: []
  },
  subs: {
    loading: true,
    unsubscribeLoading: false,
    total: 0,
    list: [],
  },
  invoices: {
    loading: true,
    details: {},
    list: [],
  },
  payment: {
    loading: false,
    details: {},
    list: [],
  },
  globalLoading: true,
};


export const monetizationState = createSlice({
  name: "monetization",
  initialState,
  reducers: {
    deleteSubscriber(state, action) {
      let { id } = action.payload;
      state.subs.list = state.subs.list.filter((sub) => sub.id !== id);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMonetizationInfo.pending, (state, { _ }) => {
      state.globalLoading = true;
      state.subs.loading = true;
      state.earnings.loading = true;
      state.invoices.loading = true;
    });

    builder.addCase(getMonetizationInfo.rejected, (state, { _ }) => {
      state.globalLoading = false;
      state.subs.loading = false;
      state.earnings.loading = false;
      state.invoices.loading = false;
    });

    builder.addCase(getMonetizationInfo.fulfilled, (state, { payload }) => {
      const { subs, balance, invoices, paymentList } = payload;
      state.earnings = {
        loading: false,
        total: balance.total || 0,
        current: balance.balance || 0,
        pending: balance.pending || 0,
        invoices: balance.invoices || 0,
        list: paymentList,
      }
      state.payment.list = paymentList;

      state.subs =  {
        loading: false,
        total: subs?.length || 0,
        list: subs ?? []
      }

      const test =[
        {
          "id": 0,
          "user_id": 0,
          "amount": 0,
          "invoice_date": {
            "date": "2024-01-30T18:05:57.659Z",
            "timezone_type": 0,
            "timezone": "string"
          },
          "status": "string",
          "comments": "string"
        }
      ]
      state.invoices = {
        loading: false,
        list: invoices ?? []
      }

      state.globalLoading = false;
    });

    // Set the card's info
    builder.addCase(setMonetizationInfo.pending, (state, { _ }) => {
      state.payment.loading = true;
    });

    builder.addCase(setMonetizationInfo.rejected, (state, { _ }) => {
      state.payment.loading = false;
    });

    builder.addCase(setMonetizationInfo.fulfilled, (state, { payload }) => {
      const { paymentDetails } = payload;

      const updatedPaymentDetails = state.payment.list?.map(item => ({ ...item, is_default: 0}));
      state.payment.list = state.payment.list ? [paymentDetails, ...updatedPaymentDetails] : [paymentDetails];
      state.payment.loading = false;
    });

    // Update the card's info
    builder.addCase(updatePaymentInfo.pending, (state, { _ }) => {
      state.payment.loading = true;
    });

    builder.addCase(updatePaymentInfo.rejected, (state, { _ }) => {
      state.payment.loading = false;
    });

    builder.addCase(updatePaymentInfo.fulfilled, (state, { payload }) => {
      const { paymentDetails } = payload;
      const { data } = paymentDetails;

      const updatedPaymentDetails = state.payment.list.map(item => {
        if (Number(item.id) === Number(paymentDetails.id)) {
          return { 
            ...item,
            is_default: data.isDefault ? 1 : item.is_default,
            payment_data: data.paymentData ?  data.paymentData : item.payment_data
          };
        }

        if (Boolean(data.isDefault)) {
          return { ...item, is_default: 0 };
        }

        return item;
      });

      state.payment.list = updatedPaymentDetails;
      state.payment.loading = false;
    });

    // Delere the card's info
    builder.addCase(deletePaymentInfo.pending, (state, { _ }) => {
      state.payment.loading = true;
    });

    builder.addCase(deletePaymentInfo.rejected, (state, { _ }) => {
      state.payment.loading = false;
    });

    builder.addCase(deletePaymentInfo.fulfilled, (state, { payload }) => {
      const { id } = payload;

      const updatedPaymentDetails = state.payment.list.filter(item => Number(item.id) !== Number(id));

      state.payment.list = updatedPaymentDetails;
      state.payment.loading = false;
    });

    // Unsubscribe
    builder.addCase(unsubscribeThunk.pending, (state, { _ }) => {
      state.subs.unsubscribeLoading = true;
    });

    builder.addCase(unsubscribeThunk.rejected, (state, { _ }) => {
      state.subs.unsubscribeLoading = false;
    });

    builder.addCase(unsubscribeThunk.fulfilled, (state, { payload }) => {
      const { id } = payload;

      const updatedSubs = state.subs.list.filter((sub) => sub.id !== id);;

      state.subs.list = updatedSubs;
      state.subs.unsubscribeLoading = false;
    });
  }
});

export const { deleteSubscriber } = monetizationState.actions;

export const getMonetizationInfo = getInfo;
export const setMonetizationInfo = setInfo;
export const updatePaymentInfo = updateInfo;
export const deletePaymentInfo = deleteInfo;
export const unsubscribe = unsubscribeThunk;

export default monetizationState.reducer;
