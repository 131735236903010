import { useRef, useState, useEffect } from "react";
import { Typography, Box, Button, TextareaAutosize, TextField, ListItemButton } from "@mui/material";
import Typewriter from '../components/Typewriter';
import Logo from '../components/Logo';
import classnames from 'classnames';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import { styled } from '@mui/material/styles';
import styles from "../onboarding.module.css";

const BaseTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    resize: none;
    padding-right: 25px;
    height: 20px;
    overflow: hidden;
    max-width: 85%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Mui-focused .MuiOutlinedInput-input {
    overflow: inherit;
    max-width: 100%;
    height: 100%;
    white-space: inherit;
  }

  &.MuiFormControl-root {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 102%;
    left: -1%;
    background: #fff;
    height: 38px;
    top: ${(props) => `${props.top}px` || '134px'};
    transition: 0.3s ease all;
    animation-delay: 4300ms;
  }

  &.MuiFormControl-root:has(> .Mui-focused) fieldset,
  &.MuiFormControl-root:has(> .Mui-focused) .MuiOutlinedInput-root:hover fieldset {
    box-shadow: 0 0 3px 3px rgba(216, 211, 250, 0.2);
  }

  .MuiInputBase-root {
    position: absolute;
    padding: 8px 10px 8px 14px;
    border-radius: 12px;
    font-size: 14px;
    color: #000;
    height: 100%;
    bottom: 0;
    width: 98%;
    left: 1%;
    transition: 0.3s ease top;
  }

  .MuiInputLabel-root {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    transform: translate(14px, 9px) scale(1);
  }

  .MuiInputLabel-root.Mui-focused {
    transform: translate(10px, -9px) scale(0.75) !important;
    padding: 0 5px;
    background: #fff;
  }

  .MuiOutlinedInput-root fieldset,
  .MuiOutlinedInput-root:hover fieldset {
    border-color: rgba(216, 211, 250, 0.4);
    box-shadow: 0 0 2px 2px rgba(216, 211, 250, 0.25);
  }

  .MuiOutlinedInput-root:hover fieldset {
    background: rgba(57, 118, 239, 0.04);
  }

  @media only screen and (max-width: 899px) {
    & .MuiInputLabel-root {
      white-space: pre-wrap;
    }
    & .MuiInputLabel-shrink {
      white-space: nowrap;
    }
  }
`;

const CustomTextField = styled(BaseTextField)`
  &.MuiFormControl-root:has(> .Mui-focused) {
    top: ${(props) => (props.expanded ? '0' : '-1%')};
    height: ${(props) => (props.expanded ? '0' : '101%')};
  }

  .MuiInputBase-root.Mui-focused {
    top: 0;
    bottom: 0;
    height: 100%;
    padding-top: 10px;
  }

  .MuiInputBase-root.Mui-focused .MuiInputBase-inputMultiline {
    height: 100% !important;
  }
`;

const data = [
  {
    name: 'role',
    title: 'What best describes your current role?',
    options: ['Student', 'Working professional', 'Job seeker'],
  },
  {
    name: 'industry',
    title: 'Which industry or field are you most involved with or interested in?',
    options: ['Education & research', 'Arts & entertainment', 'Technology & software'],
  },
  {
    name: 'text',
    title: 'What type of text data do you work with most often?',
    options: ['Social media and blog content', 'Emails and correspondence', 'Reports and documents'],
  },
  {
    name: 'routine',
    title: 'Which of the following routine tasks do you perform that you wish could be automated or assisted by AI?',
    options: ['Summarizing long texts or reports', 'Translating text between languages', 'Not decided yet'],
  },
];

const Quiz = ({ item, isNewUser, steps, quizLength, setAnimatedSteps, animatedSteps, setAnswers, answers, stepBag, getModels, isFocused, setIsFocused, setTextInputText, textInputText, setFinishedStepNames, finishedStepNames }) => {
  const onFocus = () => {
    window.gtag('event', 'Onboarding_focus_text_field', {
      'event_category': 'Onboarding',
      'event_label': 'Onboarding',
      'value': 'focus text field',
      'step': stepBag.step,
      'width': window.innerWidth,
    });
    setIsFocused(true);
  }
  const onBlur = () => setIsFocused(false);
  const animationFinished = finishedStepNames.includes(item.name);
  const inputRef = useRef(null);
  const isFieldDisabled = item.isOptional ? false : !Boolean(textInputText.length);
  const currentAnswer = answers.filter(obj => obj.question === item.name)[0]?.answer || '';

  useEffect(() => {
    const stepKey = steps[stepBag.step - 1];
    
    setAnimatedSteps({...animatedSteps, [stepKey]: true});
  }, []);


  const updatedAnswerByKey = (array, questionKey, newAnswer) => {
    const existingObjectIndex = array.findIndex(obj => obj.question === questionKey);
  
    if (existingObjectIndex !== -1) {
      array[existingObjectIndex].answer = newAnswer;
    } else {
      const newObj = {
        question: questionKey,
        answer: newAnswer
      };
      array.push(newObj);
    }
  
    return array;
  }

  return (
    <>      
      <Typography
        variant="h2"
        sx={{
          marginBottom: '40px',
          textAlign: 'center',
          width: '100%',
          fontSize: {xs: '22px', md: '32px'},
          fontWeight: 300,
          minHeight: '39px',
          position: 'relative',
        }}
      >
        {animationFinished || !isNewUser ? item.title : (
          <>
            <Box sx={{ opacity: 0 }}>{item.title}</Box>
            <Box sx={{ position: 'absolute', top: 0, textAlign: 'center', width: '100%' }}>
              <Typewriter text={item.title} animationSpeed={stepBag.step >= 2 ? 20 : 30} animationDelay={2400}  />
            </Box>
          </>
        )}
      </Typography>
      <Box sx={{ maxWidth: '340px', width: '100%', margin: 'auto', position: 'relative', paddingBottom: item.expanded ? '200px' : '38px' }}>
        {item.options.map((option, i) => (
          <Button
            key={`btn-${i}`}
            onClick={() => {
              const updatedAnswers = updatedAnswerByKey(answers, item.title, option);
              setAnswers(updatedAnswers);
              setFinishedStepNames([ ...finishedStepNames, item.name ]);
              window.gtag('event', 'Onboarding_select_answer', {
                'event_category': 'Onboarding',
                'event_label': 'Onboarding',
                'value': 'select answer',
                'step': stepBag.step,
                'width': window.innerWidth,
              });
              stepBag.setNextStep();
              if (stepBag.step === quizLength) {
                getModels(updatedAnswers);
              }
            }}
            sx={{
              border: '1px solid rgba(216,211,250, .1)',
              borderRadius: '12px',
              padding: '4px 10px 4px 14px',
              width: '100%',
              marginBottom: '10px',
              justifyContent: 'left',
              color: '#000',
              fontSize: '14px',
              textTransform: 'none',
              boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
              animationDelay: `${(item.options.length - i - 1) * 500 + 4600}ms`,
              height: '35px'
            }}
            className={classnames(!animationFinished && isNewUser && styles.button)}
          >
            {animationFinished || !isNewUser ? option : <Typewriter text={option} animationSpeed={50} animationDelay={(item.options.length - i - 1) * 500 + 4400} />}
          </Button>
        ))}
        {item.expanded ? (
          <BaseTextField
            top={45 * item.options.length}
            inputHeight={item.expanded}
            inputRef={inputRef}
            placeholder={isFocused || item.expanded ? item.other : Boolean(item.customOther) ? item.customOther : 'Other (Please specify)' }
            name='text'
            fullWidth
            defaultValue={item.options.includes(currentAnswer) ? '' : currentAnswer}
            rows={4}
            multiline={true}
            className={classnames(!animationFinished && isNewUser && styles.button, item.expanded && styles.expanded)}
            onFocus={onFocus} onBlur={onBlur}
            onChange={(event) => {
              setTextInputText(event.target.value);
            }}
            InputProps={{
              inputComponent: TextareaAutosize,
              endAdornment: (
                <ListItemButton
                  onClick={(event) => {
                    event.stopPropagation();
                    const updatedAnswers = updatedAnswerByKey(answers, item.title, inputRef.current.value);
                    setAnswers(updatedAnswers);
                    setFinishedStepNames([ ...finishedStepNames, item.name ]);
                    stepBag.setNextStep();
                    window.gtag('event', 'Onboarding_select_custom_answer', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'select custom answer',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    if (stepBag.step === quizLength) {
                      getModels(updatedAnswers.filter(item => item.answer.length));
                    }
                  }}
                  disableGutters={true}
                  disabled={isFieldDisabled}
                  // disabled={true}
                  edge="end"
                  aria-label="edit"
                  sx={{ position: 'absolute', right: '7px', bottom: '4px', padding: '5px', borderRadius: '50%' }}
                >
                  <EastOutlinedIcon sx={{ color: '#3976EF' }} fontSize="small" />
                </ListItemButton>
              )
            }}
          />
        ) : (
          <CustomTextField
            top={45 * item.options.length}
            inputHeight={item.expanded}
            inputRef={inputRef}
            placeholder={isFocused || item.expanded ? item.other : Boolean(item.customOther) ? item.customOther : 'Other (Please specify)' }
            name='text'
            fullWidth
            defaultValue={item.options.includes(currentAnswer) ? '' : currentAnswer}
            rows={4}
            multiline={true}
            className={classnames(!animationFinished && isNewUser && styles.button, item.expanded && styles.expanded)}
            onFocus={onFocus} onBlur={onBlur}
            onChange={(event) => {
              setTextInputText(event.target.value);
            }}
            InputProps={{
              inputComponent: TextareaAutosize,
              endAdornment: (
                <ListItemButton
                  onClick={(event) => {
                    event.stopPropagation();
                    const updatedAnswers = updatedAnswerByKey(answers, item.title, inputRef.current.value);
                    setAnswers(updatedAnswers);
                    setFinishedStepNames([ ...finishedStepNames, item.name ]);
                    stepBag.setNextStep();
                    window.gtag('event', 'Onboarding_select_custom_answer', {
                      'event_category': 'Onboarding',
                      'event_label': 'Onboarding',
                      'value': 'select custom answer',
                      'step': stepBag.step,
                      'width': window.innerWidth,
                    });
                    if (stepBag.step === quizLength) {
                      getModels(updatedAnswers.filter(item => item.answer.length));
                    }
                  }}
                  disableGutters={true}
                  disabled={isFieldDisabled}
                  // disabled={true}
                  edge="end"
                  aria-label="edit"
                  sx={{ position: 'absolute', right: '7px', bottom: '4px', padding: '5px', borderRadius: '50%' }}
                >
                  <EastOutlinedIcon sx={{ color: '#3976EF' }} fontSize="small" />
                </ListItemButton>
              )
            }}
          />
        )}
      </Box>
      <Button
        onClick={() => {
          const newStep = stepBag.step - 1;
          window.gtag('event', 'Onboarding_click_back', {
            'event_category': 'Onboarding',
            'event_label': 'Onboarding',
            'value': 'click back',
            'step': stepBag.step,
            'width': window.innerWidth,
          });
          setAnimatedSteps({...animatedSteps, [stepBag.stepKey]: true});
          setFinishedStepNames([ ...finishedStepNames, item.name ]);
          stepBag.setStep(Boolean(newStep) ? data[newStep - 1]?.name : 'initial');
        }}
        startIcon={<WestOutlinedIcon sx={{ position: 'relative', margin: {xs: '5px', md: 0}, left: { xs: '6px',  md: '0'} }} />}
        sx={{ 
          position: 'absolute',
          bottom: '10px',
          left: '20px',
          color: '#7e7e7e',
          background: '#fff',
          minWidth: {xs: '32px', md: 'auto'}, 
          height: {xs: '40px', md: 'auto'},
        }}
      >
        <Box sx={{ display: {xs: 'none', md: 'block'} }}>
          Back
        </Box>
      </Button>
    </>
  )
};

export default Quiz;
