import React, {useEffect, useRef, useState} from 'react';
import {Grid, Typography, Button, Container} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Stripe from '_lib/Stripe';
import CheckoutSession from './components/CheckoutSession';
import PaymentOptions from '../Payment/components/PaymentOptions';
import SubscribeTable from './components/SubscribeTable';
import PaymentReview from './components/PaymentReview';
import styles from './upgrade.module.css';
import {getBillingInfo, newCardStatus} from 'redux/billing';
import ShadowSpinner from 'components/loadings/ShadowSpinner';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


const UpgradePlanForm = ({noHeader = false}) => {
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();
    const [plan, setPlan] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [monetizationModel, setMonetizationModel] = useState(null);
    const navigate = useNavigate();
    const params = useParams();
    const potentialPlanId = params.potentialPlanId || null;

  const { currentPlanId, checkoutSession, tariff_plans } = useSelector((state) => state.billing, shallowEqual);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (tariff_plans.length == 0) {
      dispatch(getBillingInfo());
    }
    const { jsonData } = auth.user;
    const monetizationSubscription = jsonData?.monetizationSubscription;
    if (monetizationSubscription?.isTrial === 0) {
      setActiveStep(1);
      setMonetizationModel(monetizationSubscription);
      if (localStorage.getItem('monetizationSubscription')) {
        localStorage.removeItem('monetizationSubscription');
      }
    }
  }, [auth]);

  const formRef = useRef(null);
  const finishCheckoutBtnLabel = currentPlanId < (plan?.id || 1) ? "Subscribe" : "Downgrade";

  const handleAddCardSubmit = () => {
    formRef?.current?.requestSubmit();
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      let nextStep = prevActiveStep + 1;
      return nextStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      let prevStep = prevActiveStep - 1;

      return prevStep;
    });
  };

  const handlePlanSelection = (plan) => {
    setPlan(plan);
    handleNext();
  }

  const steps = [
    {
        noHeader: noHeader,
      title: 'Choose your plan',
      component: (
        <SubscribeTable
          currentPlanId={currentPlanId}
          setSelectedPlan={handlePlanSelection}
        />
      ),
    },
    {
      title: 'Checkout',
      component: (
        <PaymentReview
          formRef={formRef}
          planInformation={plan}
          setPaymentMethod={setPaymentMethod}
          paymentInformation={paymentMethod}
          handleBack={handleBack}
          setSuccess={
            (status) => {
              if (status == 'success') {
                handleNext();
              }
            }
          }
        />
      ),
    },
  ];

  const isSubmit = activeStep === steps.length - 1;
  const nextDisabled = (plan == null || checkoutSession.loading) && !Boolean(monetizationModel);


  useEffect(() => {
    const tariffLoaded = Boolean(tariff_plans.length);
    if (potentialPlanId != 0 && tariffLoaded) {
      if (potentialPlanId == currentPlanId) {
        navigate('/billing/subscribe');

        return;
      }
      const selectedItem = tariff_plans.find(item => item.id == potentialPlanId);
      selectedItem && handlePlanSelection(selectedItem);
    }
  }, [potentialPlanId != 0 && tariff_plans.length]);

  // new state for the confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const subscribeOnClickEvent = () => {
    checkoutSession.isNewCard ? handleAddCardSubmit() : handleNext(plan?.id);
  };

  return (
    <Grid container className={styles.container} >
        {!noHeader &&
            <Grid item xs={12} md={12} p={6} textAlign={{xs: "center", sm: "center", md: "left"}}>
                <Typography sx={{fontSize: 24}}>{steps[activeStep]?.title || "Subscribe"}</Typography>
            </Grid>}

      {activeStep === steps.length ? (
        <Stripe loadingComponent={<ShadowSpinner open={true} />}>
          <CheckoutSession
            plan={plan}
            paymentMethod={paymentMethod}
            monetizationModel={monetizationModel}
            handleError={() => {
              setActiveStep(0);
            }}
          />
        </Stripe>
      ) : (
        <Container>
          <Grid item xs={12} md={12}>
            {steps[activeStep].component}
            {(plan || Boolean(monetizationModel)) && (
              <Grid py={5} item xs={12} md={12} display="flex" justifyContent="flex-end">
                {!Boolean(auth.user?.jsonData?.monetizationSubscription) && activeStep === 1 && (
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Change a plan
                  </Button>
                )}
                {/* {Boolean(monetizationModel) ? (
                  <Button
                    sx={{ marginLeft: "15px" }}
                    type={isSubmit ? "submit" : "button"}
                    variant="contained" color="primary" disabled={nextDisabled}
                    onClick={handleModelSubscribe}
                  >
                    Subscribe
                  </Button>
                ) : (
                  <Button
                    sx={{ marginLeft: "15px" }}
                    type={isSubmit ? "submit" : "button"}
                    variant="contained" color="primary" disabled={nextDisabled}
                    onClick={() => checkoutSession.isNewCard ? handleAddCardSubmit() : handleNext(plan?.id)}
                  >
                    {isSubmit ? finishCheckoutBtnLabel : "Continue"}
                  </Button>
                )} */}
                {activeStep === 1 && (
                  <>
                    <Button
                      sx={{ marginLeft: "15px" }}
                      type={isSubmit ? "submit" : "button"}
                      variant="contained" color="primary"
                      disabled={nextDisabled}
                      // onClick={() => checkoutSession.isNewCard ? handleAddCardSubmit() : handleNext(plan?.id)}
                      onClick={() => setOpenConfirmDialog(true)}
                    >
                      {Boolean(monetizationModel) ? 'Subscribe' : isSubmit ? finishCheckoutBtnLabel : "Continue"}
                    </Button>

                    {/* Confirmation Dialog */}
                    <Dialog
                        open={openConfirmDialog}
                        onClose={() => setOpenConfirmDialog(false)}
                        aria-labelledby="confirm-dialog-title"
                        aria-describedby="confirm-dialog-description"
                        maxWidth="sm"
                        fullWidth
                    >
                      <DialogTitle id="confirm-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                          Do you want to change your subscription?
                        </Typography>
                        <IconButton onClick={() => setOpenConfirmDialog(false)}>
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>

                      <DialogContent dividers>
                        <Typography sx={{ mb: 2, fontSize: '0.9rem' }}>
                          If you switch to a different plan:
                        </Typography>
                        <ul style={{ paddingLeft: '20px', margin: 0 }}>
                          <li><Typography sx={{ fontSize: '0.9rem' }}>Any leftover credits will not be transferred to your new plan</Typography></li>
                          <li><Typography sx={{ fontSize: '0.9rem' }}>Your billing will start automatically from today's date</Typography></li>
                        </ul>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={() => setOpenConfirmDialog(false)} variant="outlined">
                          Cancel
                        </Button>
                        <Button
                            onClick={() => {
                              setOpenConfirmDialog(false);
                              subscribeOnClickEvent();
                            }}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 1 }}
                        >
                          Continue
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </Grid>
  );
};

export default UpgradePlanForm;
