import styled from "@emotion/styled";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React, {memo} from "react";
import SupportIconTooltip from "components/tooltip/SupportIconTooltip";
import FlexWrapper from "components/wrappers/FlexWrapper";
import {sendUIEvent} from "../../../../../../../../../events/analytics/google";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    '& .MuiToggleButton-root': {
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            // margin: '2px',
            // borderRadius: '10px'
        },
        '&.Mui-selected:hover': {
            backgroundColor: '#6090f0',
        },
        color: theme.palette.primary.main,
        // backgroundColor: '#fefefe',
        // fontWeight: 600,
        textTransform: 'Capitalize',
        // border: 'unset',
        fontSize: '12px',
        borderColor: '#82a8f5',
        // padding: '5px 10px',
        // fontFamily: 'Inter'
    },
    height: '32px',
    opacity: 0.8,

    backgroundColor: '#f6f8f8',

    // padding: '2px',
    borderRadius: '10px',
    // backgroundColor: '#f4f4f5'

}));


const ParseOptionsBtn = ({value = 'page', onChange}) => {


    const handleLocalChange = (e, value) => {
        e.preventDefault();
        if (!value) {
            return
        }
        onChange({parseSource: value});
        sendUIEvent({name: `website_tool_parse_option_changed_to_${value}`});
    }

    return (

        <StyledToggleButtonGroup
            value={value}
            exclusive
            onChange={handleLocalChange}
            aria-label="Website parse control"
        >
            <ToggleButton value="page">Single page</ToggleButton>
            <ToggleButton value="website">Website</ToggleButton>
        </StyledToggleButtonGroup>


    )
}

export default memo(ParseOptionsBtn);