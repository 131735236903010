import api from "_services/api";
import { useState, useMemo, useRef, useEffect } from "react";

const useStatefulQuery = (url, method, payload, trigger) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState("");
  // const [loading, setLoading] = useState(false);
  const instance = useMemo(() => {
    return api;
  }, [api]);

  const cachedUrl = useMemo(() => {
    return url;
  }, [url]);

  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  useEffect(() => {
    (async () => {
      if (!trigger) {
        return;
      }

      // setLoading(true);
      setStatus("loading");
      try {
        const response = await instance.request({
          signal: controllerRef.current.signal,
          data: payload,
          method,
          cachedUrl,
        });

        setData(response.data);
        setStatus("fetched");
      } catch (error) {
        setError(error.message);
        setStatus("error");
      } finally {
        // setLoading(false);
        setStatus("");
      }
    })();
    
  }, [trigger]);

  return { cancel, data, error, status };
};

export default useStatefulQuery;
