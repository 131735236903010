import FlexWrapper from "components/wrappers/FlexWrapper";
import {Box} from "@mui/material";
import FilterMenu from "./FilterMenu";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {changeFilter} from "redux/links";
import {useDispatch} from "react-redux";
import BackButton from "components/buttons/BackButton";
import SearchSort from "./SearchSort";


const Filter = () => {

    const params = useParams();
    const site_id = params.site_id || false;
    const dispatch = useDispatch();

    useEffect(() => {

        if (site_id) {
            const prefix = site_id === 'trained' ? site_id : 'nested_' + site_id;
            dispatch(changeFilter(prefix));
        } else {
            dispatch(changeFilter());

        }
    }, [site_id]);

    if (site_id) {
        return <BackButton levelUp={true}/>;
    }

    return (
        <FlexWrapper position="space-between">
            <Box
                // sx={{paddingLeft: '4 px'}}
            >
                <FilterMenu/>
            </Box>
            <Box>
                <SearchSort/>
            </Box>
        </FlexWrapper>
    )
}

export default Filter;