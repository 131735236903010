import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api/index.js';

export const getBrainListThunk = createAsyncThunk('brain/list', async (payload, thunkAPI) => {  
  try{
    const apiUrl = Boolean(payload.page) ? `/brain/list/${payload.model_id}?page=${payload.page || 1}` : `/brain/list/${payload.model_id}`;
    const response = await api.get(apiUrl);
    const brain_items = response.brain_items ? response.brain_items : [];

    if(response.code >= 400) {
      throw new Error(response);
    }

    return { brain_items, count: response.count, page: payload.page }
  }catch(e){
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const getEmailsThunk = createAsyncThunk('brain/list', async (payload, thunkAPI) => {
    try{
        const response = await api.get(`/brain/list/${payload.model_id}?page=${payload.page}`);
        const brain_items = response.brain_items ? response.brain_items : [];

        if(response.code >= 400) {
            throw new Error(response);
        }

        return { brain_items, count: response.count, page: payload.page }
    }catch(e){
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return thunkAPI.rejectWithValue({ message });
        // return thunkAPI.rejectWithValue(message);
    }
});
