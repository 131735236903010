import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progressItems: {},
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setProgressItem(state, action) {
      const progress = action.payload.progress;
      const modelId = action.payload.modelId;
      state.progressItems = {...state.progressItems, [modelId]: {...state.progressItems[modelId], ...progress} };
    },
    setProgress(state, action) {
      const progress = action.payload.progress;
      const modelId = action.payload.modelId;
      state.progressItems = {...state.progressItems, [modelId]: progress};
    },
  },
  extraReducers: () => {},
});

export const { setProgressItem, setProgress } = progressSlice.actions;

export default progressSlice.reducer;
