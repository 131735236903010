import Typography from "@mui/material/Typography";
import {memo} from "react";
import {StyledTooltip} from "components/tooltip/StyledTooltip";
import SupportIconTooltip from "components/tooltip/SupportIconTooltip";
import {Box} from "@mui/material";
import FlexWrapper from "components/wrappers/FlexWrapper";


const PagesCounterLabel = () => {
    return (
        <FlexWrapper>
            <Typography variant="describe_text" sx={{paddingRight: '4px'}}>Pages</Typography>
            <SupportIconTooltip size="small"
                                title="Enter how many levels deep you want us to explore the site. For example, entering '3' means we'll look at the homepage and two additional layers of linked pages."/>
        </FlexWrapper>

    )
}

export default memo(PagesCounterLabel);