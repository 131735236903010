// chat hints for playground to help user with fast prompts 

export default [
    // "What are the things you can do for me?",
    // "What are the questions I can ask you?",
    // "How extensive is your knowledge base?",
    // "What are the sources of your knowledge to conduct research?",
    // "Can you generate some ideas for me?",
    // "How can you assist me in my day-to-day tasks?"
];

export const randomWelcomeMessage = (name = '') => {


    const data =[
        {
            "firstLine": `Hi ${name}!`,
            "secondLine": "How can I help you?"
        },
        {
            "firstLine": `Welcome ${name}!`,
            "secondLine": "What can I do for you?"
        },
        {
            "firstLine": `Hi ${name}!`,
            "secondLine": "Looking for assistance?"
        },
        {
            "firstLine": `Welcome  ${name}!`,
            "secondLine": "What's on your mind?"
        },
        {
            "firstLine": `Hi  ${name}!`,
            "secondLine": "How can I support you?"
        },
        {
            "firstLine": `Hi  ${name}!`,
            "secondLine": "What project can I assist with?"
        },
        {
            "firstLine": `Hello  ${name}!`,
            "secondLine": "What's your focus for today?"
        },
        {
            "firstLine": `Welcome  ${name}!`,
            "secondLine": "What's on your agenda?"
        },
        {
            "firstLine": `Hi  ${name}!`,
            "secondLine": "What are you seeking today?"
        }
    ]

    const randomIndex = Math.floor(Math.random() * data.length);
    return data[randomIndex];
}