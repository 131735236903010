import {useState} from "react";
import {ToggleButton, Tooltip, Box} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import api from "_services/api";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import transparentLogoSrc from "assets/images/transp-logo.png";
import filledLogoSrc from "assets/images/logo.png";
import {useDispatch, useSelector} from "react-redux";
import {interactWithUser} from "redux/status";
import {setStatus} from "redux/tools";
import FileIcons from "components/icons/fileIcons";
import {cleanEmails, reloadContent} from "redux/email";

const toggleApproveActions = {
    outlookDis: 'microsoft/outlook/disapprove/',
    outlookApp: 'microsoft/outlook/approve/email/message/',
    gmailDis: 'google/disapprove/',
    gmailApp: 'google/approve/email/thread/'
}

const ApproveEmail = ({type = 'google', mailId, accountId, modelId, checked, sizes = 24}) => {

    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [isApproved, setApprove] = useState(checked);
    const getMapping = (type) => {

        let emails = {
            google: 'googleGmail',
            outlook: 'microsoftOutlook'
        }

        return  emails[type];
    }
    const maxAllowedEmails = useSelector(state => state.permissions?.planLimitations[getMapping(type)]?.maxLimit);
    const approvedEmailsList = useSelector(state => state.tools?.email?.list);

    const approvedEmailsAmount = approvedEmailsList?.length || 0


    const postActionSync = () => {
        setLoading(false);
        // TODO: make later update that doesnt require reload of the whole table
        dispatch(cleanEmails());
    }

    const checkRestrictions = () => {

        if(maxAllowedEmails <= approvedEmailsAmount) {
            dispatch(interactWithUser({
                message: `You used maximum training capability for emails. Upgrade your plan to get more.`,
                type: "info",
            }))

            return true
        } else {
            return false
        }



    }
    const approveCall = async () => {

        const hasRestrictions = checkRestrictions();
        if(hasRestrictions) return


        let params = {
            modelId: modelId,
        };

        if (type === 'outlook') {
            params.messageId = mailId;
        } else {
            params.messageIds = [mailId];
        }


        const url = toggleApproveActions[`${type}App`]
        await api
            .post(`${url}${accountId}`, params)
            .then((r) => {
                let code = r.code || 500;

                if (code >= 400) {
                    // dispatch(
                    //   interactWithUser({
                    //     message: `Email was not approved. Try again later.`,
                    //     type: "error",
                    //   })
                    // );
                    return;
                }

                dispatch(setStatus({type: 'emails', status: 'pending'}));
                setApprove(true);
                
                // dispatch(
                //   interactWithUser({
                //     message: `Email was approved successfully`,
                //     type: "success",
                //   })
                // );
            })
            .catch((error) => {
                // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
            }).finally(() => {
                postActionSync();
            });
    };

    const disapproveCall = () => {

        const url = toggleApproveActions[`${type}Dis`];
        let postUrlParams = {
            gmail: `${accountId}/${modelId}/${mailId}`, outlook: `${modelId}/${mailId}`
        }

        api
            .get(`${url}${postUrlParams[type]}`)
            .then((r) => {
                let code = r.code || 500;

                if (code >= 400) {
                    return;
                }
                setApprove(false);

            })
            .catch((error) => {

                // dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));

            }).finally(() => {
            postActionSync();
        });
    };

    const handleChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);

        // if approved before than do the opposite
        if (isApproved) {
            disapproveCall();
        } else {
            approveCall();
        }
    };

    return (<Tooltip title="Use this response to train your model" placement="top">
            <Box>
                {isLoading ? (<CircularProgress size={sizes}/>) : (<Checkbox
                        size={"small"}
                        sx={{padding: 0}}
                        checked={isApproved}
                        color="success"
                        onClick={handleChange}
                        checkedIcon={// <Avatar
                            //   sx={{ width: sizes, height: sizes }}
                            //   src={filledLogoSrc}
                            //   alt="logo"
                            // />
                            // <ThumbUp />
                            <Box sx={{width: {xs: 20}, height: {xs: 20}, display: 'flex', alignItems: 'center'}}>
                                <FileIcons icon='brianSuccess'/>
                            </Box>}
                        icon={// <Avatar
                            //   sx={{ width: sizes, height: sizes }}
                            //   src={transparentLogoSrc}
                            //   alt="logo"
                            // />
                            <Box
                                sx={{width: {xs: 20}, height: {xs: 20}, display: 'flex', alignItems: 'center'}}
                                display='flex'>
                                <FileIcons icon='brainAdd'/>
                            </Box>}
                    />)}
            </Box>
        </Tooltip>);
};

export default ApproveEmail;
