import Grid from "@mui/material/Grid";
import QrCode from "../QrCode";
import {Typography} from "@mui/material";
import PublicLink from "../../../../PublicLink";
import React from "react";

const Settings = ({qr, publicLink, hideFromPublic, copyPublicLink, smallScreen}) => {
    return (
        <>
          {!smallScreen && (
            <Grid item xs={12} sm={12} md={12} >
              <Typography sx={{fontSize: '24px'}}>
                Speak to Product link and QR code
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} py={2}>
            <PublicLink
              publicLink={publicLink}
              copyPublicLink={() => copyPublicLink()}
              hideFromPublic={hideFromPublic}
            />
          </Grid>
          {Boolean(publicLink?.length) && (
            <Grid item xs={12} sm={12} md={12} py={2}>
              <QrCode qr={qr} />
            </Grid>
          )}
        </>
    )
}

export default Settings;
