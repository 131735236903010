import { useMemo } from 'react';
import { Typography, Box, Button, Card, CardContent, CardActions, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import classnames from 'classnames';
import Typewriter from '../components/Typewriter';
import { styled } from '@mui/material/styles';
import { getInitials } from "utils/prepareNames";
import monetizationLogo from "assets/images/onboarding-monetization.png";
import assistantLogo from "assets/images/onboarding-assistant.png";
import chatBotLogo from "assets/images/onboarding-chat-bot.png";
import customerSupportLogo from "assets/images/onboarding-customer-support.png";
import styles from '../onboarding.module.css';
import {sendUIEvent} from "../../../../events/analytics/google";

const SelectFlow = ({ stepBag, isNewUser, setIsCustomModel, finishedSteps, setIsFormShowed, isFormShowed, setFlow, finishedStepNames }) => {
  const animationFinished = finishedStepNames.includes(stepBag.stepKey);
  const models = useSelector((state) => state.models.list);
  const subtitle = models.length < 2 ? 'Let me help you to create your first AI model for:' : 'Let me help you to create a new AI model for:';

  const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    color: '#000',
    padding: '3px 10px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#f1f1f1',
    borderColor: '#f1f1f1',
    '&:hover': {
      backgroundColor: '#d7d5d5',
      borderColor: '#d7d5d5',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#d7d5d5',
      borderColor: '#d7d5d5',
    },
    '&:focus': {
      boxShadow: '#d7d5d5',
    },
  });

  const onNextHandler = (val) => {
    setFlow(val);
    // setIsCustomModel(false);
    stepBag.setNextStep();
    sendUIEvent({name: `onboarding_select_flow_${val}`});
  }

  return (
    <Box sx={{ paddingBottom: '30px', width: '100%' }}>
      <Typography
        variant="h2"
        sx={{
          marginBottom: '40px',
          textAlign: 'center',
          width: '100%',
          fontSize: {xs: '22px', md: '32px'},
          fontWeight: 300,
          position: 'relative',
        }}
      >
        {animationFinished || !isNewUser ? (
          <>
            <div>Let me help you to create your first AI model</div>
          </>
        )  : (
          <>
            <Box sx={{ opacity: 0 }}>
              <div>Let me help you to create your first AI model</div>
            </Box>
            <Box sx={{ position: 'absolute', top: 0, textAlign: 'center', width: '100%' }}>
              <Box sx={{ minHeight: {xs: '40px', md: '60px' } }}>
                <Typewriter text={subtitle} animationSpeed={30} animationDelay={2900} cursorDelay={3100} />
              </Box>
            </Box>
          </>
        )}
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', md: 'row' }, gap: '20px', maxWidth: '1040px', margin: '0 auto' }}>
        <Card onClick={() => onNextHandler('assistant')} sx={{ animationDelay: '4800ms', boxShadow: 'none', borderRadius: '10px' }} className={classnames(!animationFinished && isNewUser && styles.button, styles.card)}>
          <CardContent sx={{ padding: 0 }} className={styles.cardContent}>
            <Box>
              <img src={assistantLogo} width="100%" />
            </Box>
            <Box sx={{ padding: '16px' }}>
              <Box mb={2}>
                <Typography variant='onboarding_title'>AI Assistant</Typography>
              </Box>
              <Typography variant='onboarding_content'>
                Your AI assistant ready to expedite your work and collaborate with entire team by centralizing knowledge of your business.
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid rgba(0,0,0, .1)' }}>
            <CustomButton variant="contained" size="small" className={styles.cta}>
              Get started
            </CustomButton>
            <Typography variant='onboarding_content'>
              4 steps
            </Typography>
          </CardActions>
        </Card>
        <Card onClick={() => onNextHandler('chat-bot')} sx={{ animationDelay: '5200ms', boxShadow: 'none', borderRadius: '10px' }} className={classnames(!animationFinished && isNewUser && styles.button, styles.card)}>
          <CardContent sx={{ padding: 0 }} className={styles.cardContent}>
            <Box>
              <img src={chatBotLogo} width="100%" />
            </Box>
            <Box sx={{ padding: '16px' }}>
              <Box mb={2}>
                <Typography variant='onboarding_title'>Website Chatbot</Typography>
              </Box>
              <Typography variant='onboarding_content'>
                Instant answers to customer questions on your website with our AI-powered assistant, trained with your knowledge.
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid rgba(0,0,0, .1)' }}>
            <CustomButton variant="contained" size="small" className={styles.cta}>
              Get started
            </CustomButton>
            <Typography variant='onboarding_content'>
              4 steps
            </Typography>
          </CardActions>
        </Card>
        <Card onClick={() => onNextHandler('monetization')} sx={{ animationDelay: '5600ms', boxShadow: 'none', borderRadius: '10px' }} className={classnames(!animationFinished && isNewUser && styles.button, styles.card)}>
          <CardContent sx={{ padding: 0 }} className={styles.cardContent}>
            <Box>
              <img src={monetizationLogo} width="100%" />
            </Box>
            <Box sx={{ padding: '16px' }}>
              <Box mb={2}>
                <Typography variant='onboarding_title'>Monetize AI</Typography>
              </Box>
              <Typography variant='onboarding_content'>
                Unlock the potential of your knowledge and earn money from your AI assistant. Start making the most of your expertise!
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid rgba(0,0,0, .1)' }}>
            <CustomButton variant="contained" size="small" className={styles.cta}>
              Get started
            </CustomButton>
            <Typography variant='onboarding_content'>
              3 steps
            </Typography>
          </CardActions>
        </Card>
        <Card onClick={() => onNextHandler('customer-support')} sx={{ animationDelay: '6000ms', boxShadow: 'none', borderRadius: '10px' }} className={classnames(!animationFinished && isNewUser && styles.button, styles.card)}>
          <CardContent sx={{ padding: 0 }} className={styles.cardContent}>
            <Box>
              <img src={customerSupportLogo} width="100%" />
            </Box>
            <Box sx={{ padding: '16px' }}>
              <Box mb={2}>
                <Typography variant='onboarding_title'>Speak to Product</Typography>
              </Box>
              <Typography variant='onboarding_content'>
                Provide customers with expert product support through an AI assistant, helping with setup, troubleshooting, and returns.
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid rgba(0,0,0, .1)' }}>
            <CustomButton variant="contained" size="small" className={styles.cta}>
              Get started
            </CustomButton>
            <Typography variant='onboarding_content'>
              3 steps
            </Typography>
          </CardActions>
        </Card>
      </Box>
      {models.length > 0 && (
        <Box sx={{
          padding: { xs: '10px', md: 0 },
          position: { xs: 'fixed', md: 'absolute' },
          bottom: { xs: '25px', md: '5px'},
          right: { xs: '0', md: '5px' }, 
          left: { xs: '0', md: 'inherit' },
          background: { xs: '#fff', md: 'none' },
          textAlign: 'center'
        }}>
          <Tooltip title="I am experienced user" placement="bottom">
            <Button
              onClick={() => {
                window.gtag('event', 'Onb_click_skip', {
                  'event_category': 'Onboarding',
                  'event_label': 'Onboarding',
                  'value': 'click skip',
                  'step': stepBag.step,
                  'width': window.innerWidth,
                });
                setIsFormShowed(true);
                stepBag.setStep('modelForm');
                setIsCustomModel(true);
              }}
              size="small"
              sx={{ color: '#7e7e7e', fontSize: '12px' }}
            >Skip</Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
};

export default SelectFlow;
