import { createContext, useContext } from 'react';

const initialStepperValue = {
  steps: [],
  currentStep: 0,
  maxStep: 0,
  currentStepKey: null,
  prevStepKey: null,
  onFinish: () => {},
  setStep: () => {},
  setPreviousStep: () => {},
  setNextStep: () => {},
};

export const StepperContext = createContext(initialStepperValue);

export const useStepperContext = () => useContext(StepperContext);