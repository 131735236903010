import { Box, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import formatTime from 'utils/formatTime';
import { unsubscribe } from 'redux/monetization';
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import moment from "moment";
import styles from '../subs-table.module.css';

const SubItem = ({ item }) => {
  const dispatch = useDispatch();
  const { unsubscribeLoading } = useSelector((state) => state.monetization.subs);
  const orderId = item.id;
  let rawDate = item.start_at?.date || {};
  const endDate = moment(item?.end_at?.date).local().add(1, 'months').format('YYYY-MM-DD');

  const dateJoined = formatTime({ date: rawDate });
  const fullName = item.user?.name || 'Unknown'

  return (
    <Box className={styles.line} key={`history-item-${orderId}`}>
      <div className={styles.textOverflow}>{dateJoined}</div>
      <div className={styles.textOverflow} style={{ flexGrow: 1 }}>{fullName}</div>
      <div className={styles.textOverflow} style={{ flexGrow: 1 }}>{item.model.name}</div>
      <div className={styles.actions}>
        {unsubscribeLoading ? <CircularProgress size={20} /> : (
          item.status === 'canceled' ? (
            <GeneralTooltip
              placement='bottom'
              title={`The user's subscription will be automatically canceled on ${endDate}`}
            >
              <DeleteIcon sx={{ color: 'rgba(0, 0, 0, .3)' }} fontSize={"small"} />
            </GeneralTooltip>
          ) : (
            <GeneralTooltip
              placement='bottom'
              title='Cancel user`s subscription'
            >
              <IconButton
                disabled={item.status === 'canceled'}
                onClick={() => {
                  dispatch(unsubscribe({id: item.id}));
                }}
              ><DeleteIcon fontSize={"small"} /></IconButton>
            </GeneralTooltip>
          )
        )}
      </div>
    </Box>
  );
};


export default SubItem;