import {
    Box,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Skeleton,
    Typography,
    Chip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "pages/protected/billing/Payment/payment.module.css";
import {useNavigate} from "react-router-dom";
import AddCardIcon from '@mui/icons-material/AddCard';
import {useEffect, useState} from "react";
import PoweredByStripe from './logos/PoweredByStripe';
import Guarantee from './logos/Guarantee';
import api from "_services/api";
import Stripe from "_lib/Stripe";
import CheckoutPaymentForm
    from "pages/protected/billing/UpgradePlan/components/PaymentReview/components/CheckoutPaymentForm";
import {ArrowBack} from "@mui/icons-material";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {newCardStatus, checkoutLoadingStatus} from "redux/billing";
import StripePaymentSkeleton from "components/loadings/templates/StripePaymentSkeleton";

export const PaymentBoxInfo = ({item, handleDeleteRows = false}) => {

    const fullAddress = [item.address_line_1, item.city, item.state, item.postal_code]
        .filter(Boolean)
        .join(', ');

    return (
        <Grid container className={styles["payment-box-info"]}>
            <Grid item md="auto">
                <FormControlLabel sx={{paddingLeft: "5px"}} value={item.id} control={<Radio
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: 16
                        },
                    }}/>}/>

            </Grid>
            <Grid item xs>
                <Typography variant="describe_text">{fullAddress}</Typography>
            </Grid>
            <Grid item md={5} textAlign="right">
                <Typography variant="describe_text">{item.name}</Typography>
            </Grid>
            {handleDeleteRows &&
                <Grid item md={1} textAlign="right">
                    <IconButton onClick={() => handleDeleteRows(item.id)}>
                        <DeleteIcon color="icons"/>
                    </IconButton>

                </Grid>
            }

        </Grid>
    );
}


export const AddPaymentMethodBox = ({setNewCardMode}) => {

    const handleNewCard = () => {
        setNewCardMode(true)
    }

    return (
        <Grid container className={styles["payment-box-info"]} mb={6} onClick={handleNewCard}>
            <Grid item xs={10} md={6}>
                <Typography variant="describe_text">Add Payment Method</Typography>
            </Grid>
            <Grid item xs={2} md={1} textAlign="right">
                <IconButton>
                    <AddCardIcon color="icons"/>
                </IconButton>
            </Grid>
        </Grid>
    );
}


const PaymentOptions = ({
                            setPaymentMethod = false, formRef, setSuccess = false, setTempAddress = () => {
    }
                        }) => {

    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [defaultCard, setDefaultCard] = useState("");
    const checkoutSession = useSelector((state) => state.billing.checkoutSession, shallowEqual);


    const dispatch = useDispatch();

    const handlePrimaryChange = async (e, id = null) => {

        dispatch(checkoutLoadingStatus(true));
        const paymentId = id || e.target.value;

        const {code} = await api.get(`stripe/payment_method/set-default/${paymentId}`);


        if (code == 200) {
            const newDefaultCard = payments.find(item => item.id == paymentId);
            setDefaultCard(paymentId);
            setPaymentMethod && setPaymentMethod(newDefaultCard);
        }
        dispatch(checkoutLoadingStatus(false))

    }

    const handleDeleteRows = async (id) => {

        setLoading(true);
        const {code} = await api.delete(`stripe/payment_method/${id}`);

        if (code == 200) {
            let newArray = payments.filter(item => item.id !== id);
            setPayments(newArray);
        }
        setLoading(false);
    }


    const handleNewCard = (state) => {

        dispatch(newCardStatus({isNewCard: state}))

        const newDefaultCard = payments.find(item => item.id == defaultCard);
        setPaymentMethod(state ? null : newDefaultCard);
        setTempAddress(null);
    }

    useEffect(() => {

        const getPayments = async () => {

            // add to thunk later
            dispatch(checkoutLoadingStatus(true));
            let {payment_methods, code} = await api.get("stripe/payment_method/list");

            if (payment_methods == null) {
                dispatch(newCardStatus({isNewCard: true}))
                setPaymentMethod("empty")
            } else {
                const defaultPayment = payment_methods.find(item => item.is_default === 1);
                setDefaultCard(defaultPayment.id);
                setPaymentMethod && setPaymentMethod(defaultPayment);
                setPayments(payment_methods);
            }
            dispatch(checkoutLoadingStatus(false));
        }

        if (payments.length == 0) {
            getPayments();
        }


    }, []);


    // if(checkoutSession.loading) {
    //     return <></>
    // }


    if (checkoutSession.isNewCard || (payments.length == 0 && !loading)) {
        return (
            <Box display="flex" alignItems="flex-start">
                {payments.length != 0 &&
                    <IconButton onClick={() => {
                        handleNewCard(false)
                    }} color="primary">
                        <ArrowBack/>
                    </IconButton>
                }


                <Stripe loadingComponent={<StripePaymentSkeleton/>}><CheckoutPaymentForm
                    setPaymentMethod={setPaymentMethod} formRef={formRef} setSuccess={setSuccess}
                    setTempAddress={setTempAddress}/></Stripe>
            </Box>

        )
    }


    return (

        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Typography variant="page_sub_title">Choose Payment Method</Typography>
            </Grid>
            <Grid item xs={12}>
                {checkoutSession.loading ? (<>
                            <Box className={styles["payment-box-info"]}>
                                <Skeleton variant="text" animation="wave" width="100%"/>
                            </Box>
                            <Box className={styles["payment-box-info"]}>
                                <Skeleton variant="text" animation="wave" width="100%"/>
                            </Box>
                            <Box className={styles["payment-box-info"]}>
                                <Skeleton variant="text" animation="wave" width="100%"/>
                            </Box>
                        </>
                    ) :
                    <RadioGroup
                        value={defaultCard}
                        onChange={handlePrimaryChange}
                        sx={{width: "100%"}}
                    >
                        {payments.map((item, index) =>
                            <PaymentBoxInfo key={index} item={item}
                                            handleDeleteRows={!setPaymentMethod ? handleDeleteRows : false}/>
                        )}
                    </RadioGroup>
                }

            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                {/* <Stripe><CheckoutPaymentForm setPaymentMethod={setPaymentMethod} formRef={formRef} /></Stripe> */}
                <AddPaymentMethodBox setPaymentMethod={setPaymentMethod} setNewCardMode={(isNewCard) => {
                    dispatch(newCardStatus({isNewCard: isNewCard}))
                }}/>
                <Box ml={1} sx={{fontWeight: 600, display: 'flex', alignItems: 'center'}}>
                    <Guarantee/>&nbsp;&nbsp;&nbsp;
                    <Typography variant="describe_text" sx={{fontWeight: 600, color: '#2a2f32'}}>Guaranteed safe
                        checkout</Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Box display="flex" alignItems="center">
                        <Typography variant="describe_text" sx={{fontSize: '11px'}}>powered by</Typography>
                        <PoweredByStripe/>
                    </Box>

                </Box>
            </Grid>
        </Grid>

    );


}

export default PaymentOptions;