import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import React from "react";

export const StyledTooltip =  styled(({ className, ...props }) => {
    return (
        <Tooltip
            {...props}
            classes={{ popper: className }}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            leaveDelay={100}
        />
    )
})
(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding:'20px',
        backgroundColor: 'white',
        color: '#757575',
        fontSize: theme.typography.pxToRem(14),
        fontWeight:'300',
        lineHeight: '1.4em',
        filter: 'drop-shadow(0px 1px 5px rgba(0,0,0,0.12))',
    },
}));