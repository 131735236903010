import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {useMemo} from "react";

const StyledComp = styled(Typography)(({theme}) => ({
    letterSpacing: '1px',
    WebkitMaskImage: 'linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%)',
    WebkitMaskSize: '200%',
    animation: 'shine 2s linear infinite',

    '@keyframes shine': {
        from: {WebkitMaskPosition: '150%'},
        to: {WebkitMaskPosition: '-50%'},
    },


}));

const SmartLoading = ({text}) => {

    const formattedText = useMemo(() => {
        return text.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    }, [text])

    return <StyledComp variant="describe_text">{formattedText}..</StyledComp>
}

export default SmartLoading;