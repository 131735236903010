import { Avatar, Divider, Grid, Button, Box, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import RocketIcon from '@mui/icons-material/Rocket';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PaymentOptions from "pages/protected/billing/Payment/components/PaymentOptions";
import AddPaymentForm from "pages/protected/billing/Payment/components/AddPaymentForm";
import Stripe from "_lib/Stripe";
import api from "_services/api";
import { removeJsonData } from "redux/auth";
import controlledAPI from "_services/controlledApi";
import PaymentPlanInfo from './components/PaymentPlanInfo';
import PaymentMonetizationInfo from './components/PaymentMonetizationInfo';
import CheckoutPaymentOptions from "./components/CheckoutPaymentOptions";
import { changeModel } from "redux/models";
import { unfollow } from 'redux/models';
import { interactWithUser } from "redux/status";
import styled from "@emotion/styled";
import SmoothContent from "components/loadings/SmoothContent";
import { sendPixelFB } from "events/analytics/facebook";

const PaymentReview = ({ paymentInformation = false, planInformation = {}, formRef = null, setPaymentMethod = null, setSuccess = {}, handleBack }) => {
  const auth = useSelector((state) => state.auth);
  const { models } = useSelector((state) => ({
    models: state.models.list
  }));
  const [tempAddress, setTempAddress] = useState(null);
  const [declineLoading, setDeclineLoading] = useState(false);
  const dispatch = useDispatch();
  const monetizationModel = auth.user?.jsonData?.monetizationSubscription;
  // const PaymentDetails = () => {
  //     const fullAddress = [paymentInformation?.address_line_1, paymentInformation?.city, paymentInformation?.state, paymentInformation?.postal_code, paymentInformation?.country]
  //         .filter(Boolean)
  //         .join(', ');


  //     const cardType = paymentInformation?.name.split("••••")[0] || "....";
  //     const cardNumber = paymentInformation?.name.split("••••")[1] || "....";

  //     const payments = [
  //         { id: 0, name: 'Card type: ', detail: cardType },
  //         // { name: 'Card holder', detail: paymentInformation.fullName },
  //         { id: 1, name: 'Card number: ', detail: `xxxx-xxxx-xxxx-${cardNumber}` },
  //         // { name: 'Expiry date', detail: paymentInformation. },
  //     ];


  //     return (
  //         <>
  //             <Grid item container direction="column" xs={12} sm={12}>
  //                 <Typography variant="page_sub_title_bold" gutterBottom sx={{ mt: 2 }}>
  //                     Billing Address
  //                 </Typography>
  //                 {/* <Typography variant="page_content" gutterBottom>John Smith</Typography> */}
  //                 <Typography variant="page_content" gutterBottom>{fullAddress}</Typography>
  //             </Grid>
  //             <Grid item container direction="column" xs={12} sm={12}>
  //                 <Typography variant="page_sub_title_bold" gutterBottom sx={{ mt: 2 }}>
  //                     Payment details
  //                 </Typography>
  //                 <Grid container>
  //                     {payments.map((payment) => (
  //                         <Fragment key={payment.id}>
  //                             <Grid item xs={12}>
  //                                 <Typography variant="page_content" gutterBottom>{payment.name}</Typography>
  //                                 <Typography variant="page_content" gutterBottom>{payment.detail}</Typography>
  //                             </Grid>

  //                         </Fragment>
  //                     ))}
  //                 </Grid>
  //             </Grid>

  //             <Grid item xs={12}>
  //                 <Divider />
  //             </Grid>
  //         </>
  //     )
  // }


  useEffect(()=> {
    const sendEventFB = async () => {
      await sendPixelFB({ event: 'InitiateCheckout', content_name: planInformation.name});
    };
    if (planInformation) {
      sendEventFB();
    }
  }, []);
  // TODO: move this code to a separate helper
  const selectedPlanInformation = Boolean(planInformation) ? planInformation : auth.user.tariff_plan;
  const shouldUpdate = Boolean(planInformation) && auth.user.tariff_plan.id != planInformation?.id;
  const country = paymentInformation?.country || "";
  const TAXES = {
    "CA": 0.13
  }
  const taxRate = TAXES[tempAddress ?? country];
  const taxPrice = taxRate ? (selectedPlanInformation?.price * taxRate) : 0;
  const fullPlanPrice = shouldUpdate ? selectedPlanInformation?.price + taxPrice : 0;
  const monetizationTaxPrice = taxRate ? (monetizationModel?.price * taxRate) : 0;
  // const monetizationTaxPrice = monetizationModel?.price * taxRate;
  const fullMonetizationPrice = monetizationModel?.price + monetizationTaxPrice;

  return (
    <>
      <Grid container spacing={6} sx={{
        justifyContent: "center",
        margin: { lg: "0 auto" },
      }}>
        {planInformation?.id != 1 && (
          <>
            <Grid item xs={12} sm={12} md={8} sx={{ order: { xs: 2, sm: 2, md: 1 } }}>
              <CheckoutPaymentOptions setPaymentMethod={setPaymentMethod} formRef={formRef} setSuccess={setSuccess} setTempAddress={setTempAddress} />
            </Grid>
            <Grid sx={{ order: { sm: 0, md: 2 } }} item sm md={1} >
              <Divider orientation="vertical" sx={{ width: '1px' }} />
            </Grid>
          </>
        )}
        <Grid item container direction="column" xs={12} sm={12} md={3} sx={{ order: { xs: 1, sm: 1, md: 3 }, alignItems: {sm: 'center', md: 'flex-start'}, paddingTop: "24px", paddingBottom: '24px', paddingLeft: { md: '0 !important' } }}>
          {Boolean(monetizationModel?.price) && (
            <>
              <PaymentMonetizationInfo monetizationModel={monetizationModel} paymentInformation={paymentInformation} tempAddress={tempAddress} />
              <Button disabled={declineLoading} onClick={() => {
                if (monetizationModel?.model_id) {
                  const updatedJsonData = auth.user?.jsonData ? { ...auth.user.jsonData } : null;
                  if (Boolean(updatedJsonData) && updatedJsonData.hasOwnProperty('monetizationSubscription')) {
                    delete updatedJsonData.monetizationSubscription;
                  }
                  dispatch(unfollow({ modelId: monetizationModel.model_id })).then((res) => {
                    if (res.payload) {
                      dispatch(
                        interactWithUser({
                          message: 'The invitation has been rejected',
                        })
                      );
                      controlledAPI({ ignoreUser: true })
                        .put(`users/${auth.user.id}`, { jsonData: updatedJsonData })
                        .then(() => {
                          dispatch(removeJsonData({ dataKey: 'monetizationSubscription' }));
                          // if (models.length === 1) {
                          //   dispatch(changeModel(78));
                          // } else {
                            dispatch(changeModel(models[0].id));
                          // }
                        })
                        .finally(() => {
                          handleBack();
                          setDeclineLoading(false);
                        });
                    }
                  }).catch(() => {
                    dispatch(
                      interactWithUser({
                        message: 'We were not able to process the invitation',
                        type: 'error',
                      })
                    );
                  });
                  setDeclineLoading(true);
                } else {
                  // if (models.length === 1) {
                  //   dispatch(changeModel(78));
                  // }
                  handleBack();
                }
              }}>
                Decline
              </Button>
            </>
          )}
          <PaymentPlanInfo planInformation={selectedPlanInformation} paymentInformation={paymentInformation} tempAddress={tempAddress} />
          {Boolean(monetizationModel?.price) && (
            <Button onClick={handleBack}>
              Change a plan
            </Button>
          )}
          {Boolean(monetizationModel?.price) && (
            <Box sx={{ width: '100%', maxWidth: '300px', display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
              <Typography variant="page_sub_title_bold"  pr={4}>Total</Typography>
              <Typography variant="page_sub_title_bold"  pr={4}>${(fullPlanPrice + fullMonetizationPrice).toFixed(2)}</Typography>
            </Box>
          )}
        </Grid>
        {/* <PaymentPlanInfo planInformation={planInformation} monetizationModel={monetizationModel} paymentInformation={paymentInformation} tempAddress={tempAddress} /> */}
        {/* <PaymentMonetizationInfo monetizationModel={monetizationModel} paymentInformation={paymentInformation} tempAddress={tempAddress} /> */}
      </Grid>
    </>
  )
}

export default PaymentReview;