import {useSelector} from "react-redux";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {useMemo} from "react";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import styles from "../../topbar.module.css";
import {MAX_ALLOWED_DAYS_TRIAL} from "constants";
import {sendUIEvent} from "../../../../../events/analytics/google";

// popup restrict
// gmail and outlook
//
const TrialCountdown = () => {

    const navigate = useNavigate();
    const is_default = useSelector(state => state.auth.user.tariff_plan?.is_default);
    const created_date = useSelector(state => state.auth.user?.created_at?.date);
    // const created_date = '2024-04-03 00:06:52.000000'
    const availableCredits = useSelector(state => state.billing.planInfo.availableCredits);

    const daysLeft = useMemo(()=> {
        let currentDate = moment();
        const dateToCheck = moment(created_date);
        // console.log(dateToCheck.isAfter(currentDate, 'day'))
        const differenceInDays = currentDate.diff(dateToCheck, 'days');

        if(differenceInDays > MAX_ALLOWED_DAYS_TRIAL) {
            return 'no';
        }

        return MAX_ALLOWED_DAYS_TRIAL - differenceInDays;

    },[created_date])

    const isTrial = Boolean(is_default);


    if (!isTrial) {
        return;
    }


    const redirectToUpgrade = (e) => {
        e.preventDefault();
        navigate('/billing/subscribe');
        sendUIEvent({name: 'upgrade_trial_btn_click'});
    }

    return (
        <>
            <Typography variant="count_down_text">You have <span className={styles['bolded']}>{`${daysLeft} days / ${availableCredits}`} credits </span> left in your
                trial</Typography> <Button className={styles["gradient-btn"]} size="small"  onClick={redirectToUpgrade} variant="contained">Upgrade</Button>
        </>
    )
}


export default TrialCountdown;
