import {shallowEqual, useSelector} from "react-redux";
import ToolCallsLoadings from "../../../loadings/templates/ToolCallsLoadings";
import {memo} from "react";

const getSelector = type => {

    if (type === 'shared') {
        return state => state.sharedMessages.session.tools.stack;
    }
    return state => state.messages.session.tools.stack;
}

const ToolCalls = ({type = "default"}) => {

    const toolStack = useSelector(getSelector(type), shallowEqual);

    return Object.keys(toolStack)?.map((key, id) => (
            Array.from({length: toolStack[key]}, (_, subId) => (
                <ToolCallsLoadings key={'tool-call-' + id + '-' + subId} template={key}/>
            ))
        )
    );

}

export default memo(ToolCalls);
