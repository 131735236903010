import React, {useState, useRef, useEffect} from "react";
import PublicLink from "../../PublicLink";
import {Divider, Typography, Box} from "@mui/material";
import {interactWithUser} from "redux/status";
import {useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import {useOutletContext} from "react-router-dom";
import SmallScreenToggleBtn from "../../SmallScreenToggleBtn";
import Preview from "../SpeakToProduct/components/Preview";
import Settings from "./components/Settings";
import ActionBtns from "../../ActionBtns";

const CustomerSupport = () => {
    const viewRef = useRef(null,)
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setIsInView(entry.isIntersecting);
        }, {
            root: null,
            threshold: 1.0
        });

        if (viewRef.current) {
            observer.observe(viewRef.current);
        }

        return () => {
            if (viewRef.current) {
                observer.unobserve(viewRef.current);
            }
        };
    }, []);
    const {isPublic, selectedModel, hideFromPublic, smallScreen} = useOutletContext();
    const dispatch = useDispatch();
    // const hash = selectedModel.public_hash || '';
    const qr = selectedModel?.qr || {};
    const context = useOutletContext();

    // const publicLink = 'https://younet.ai/p/' + hash;
    const publicLink = isPublic
        ? 'https://younet.ai/p/' + selectedModel?.public_hash
        : '';
    const copyPublicLink = async () => {
        const linkToCopy = publicLink;
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(linkToCopy);
        } else {
            document.execCommand("copy", true, linkToCopy);
        }
        return dispatch(
            interactWithUser({type: "info", message: "Copied URL!"})
        );
    };

    if (smallScreen) {

        return (
          <>
            <Grid container py={4} spacing={4} sx={{ paddingBottom: '100px' }}>
                <Grid item xs={12} sm={12} textAlign="center">
                    <SmallScreenToggleBtn
                        position={2}
                        isInView={isInView}
                        hidePreview={!publicLink}
                        settingsComp={<Settings qr={qr} smallScreen={smallScreen} publicLink={publicLink} copyPublicLink={copyPublicLink} hideFromPublic={hideFromPublic}/>}
                        previewComp={<Preview qr={qr} manualHash={selectedModel.public_hash}/>}
                    />
                    {isInView && (
                      <ActionBtns
                        isPublic={context.isPublic}
                        hideFromPublic={context.hideFromPublic}
                        savePublic={context.savePublic}
                        loading={context.loading}
                        websiteStatus={context.websiteStatus}
                        shareType={context.shareType}
                        handleSendInvitation={context.handleSendInvitation}
                      />
                    )}
                </Grid>
            </Grid>
            <Box ref={viewRef} />
          </>
        )
    }

    return (
        <Grid container py={4} spacing={4}>
            <Grid item xs={12} sm={12} md={3.8} lg={4.9} >
                <Settings qr={qr} publicLink={publicLink} copyPublicLink={copyPublicLink} hideFromPublic={hideFromPublic} smallScreen={smallScreen}/>
                <ActionBtns
                  isPublic={context.isPublic}
                  hideFromPublic={context.hideFromPublic}
                  savePublic={context.savePublic}
                  loading={context.loading}
                  websiteStatus={context.websiteStatus}
                  shareType={context.shareType}
                  handleSendInvitation={context.handleSendInvitation}
                />
            </Grid>
            <Grid item auto>
                <Divider orientation="vertical"/>
            </Grid>
            <Grid item xs={12} sm={12} md={7.8} lg={6.9}>
                <Preview manualHash={selectedModel.public_hash}/>
            </Grid>
        </Grid>
    )
}


export default CustomerSupport;