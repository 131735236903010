import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import api from "_services/api";

const GoogleAuthBtnExternal = ({
  redirectUrl,
  text = "",
  fullWidth = false,
  googleOptions = {},
  manual
}) => {
  const [url, setUrl] = useState('');

  const Icon = styled.i`
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 0;
    width: 25px;
    height: 20px;
  `;

  const Btn = styled.a`
    ${fullWidth ? "justify-content: center;width: 100%;" : "max-width: 240px;"}
    display: flex;
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 8px 10px !important;
    border: none;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px -1px 0px, rgba(0, 0, 0, 0.25) 0px 1px 3px;
    color: #757575;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.08) 0px -1px 0px, rgba(0, 0, 0, 0.25) 0px 2px 5px;
    }

    &:active {
      background-color: #eeeeee;
    }

    &:focus {
      outline: none;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
        0 0 0 3px #c8dafc;
    }
  `;

  useEffect(() => {
    
    const fetchData = async () => {

      let params = {
        redirectUrl: redirectUrl,
      };


      try {
        let response;
        if (googleOptions.method == "get") {
          response = await api.get(googleOptions.url);
        } else if (googleOptions.method == "post") {
          response = await api.post(googleOptions.url, params);
        } else {
          return;
        }

        if (response.code >= 400) {
          throw Error("Something went wrong..");
        }

        let authUrl = response.authUrl || "";


        if(manual == 'google') {
         window.location.href = authUrl
        }

        // waiting till Mykola make it Dynamic -_-
        // if (googleOptions.method == "get") {
          // authUrl = authUrl.replace(
          //   /redirect_uri=.*?&/g,
          //   "redirect_uri=" +
          //     encodeURIComponent(window.location.origin + "/training/email") +
          //     "&"
          // );
        // }
        setUrl(authUrl);
      } catch (error) {
        console.error(error); // Handle any errors
      }
    };

    if(window.location.origin == 'https://younet.ai') { 
      fetchData();
    } 

  }, []);


  const handleClick = () => {
    window?.parent?.postMessage({isAuthentificated: true, redirectUrl: 'https://younet.ai/portal/external/login/google'}, 'https://younet.ai');
  }
  return (
    <Btn variant="contained" color="primary" onClick={() => window.location.href == 'https://younet.ai/portal/external/login' ? handleClick() : window.location.href = url}>
      <Icon /> {text}
    </Btn>
  );
};

export default GoogleAuthBtnExternal;
