import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import api from "_services/api";
import {Box, Collapse, Grid, IconButton, Typography,} from "@mui/material";
import ApproveEmail from "components/approve/ApproveEmailToggleBtn";
import formatTime from "utils/formatTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useDispatch} from "react-redux";
import {accountLogout} from "redux/email";
import SmoothContent from "components/loadings/SmoothContent";
import EmailLoadingTemplate from "./EmailLoadingTemplate";
import parse from "html-react-parser";

const actionTypes = {
    gmail: 'google',
    outlook: 'microsoft/outlook'
}

const preprocessHtmlString = (html = "") => {

    // return html
    //html = `<html><head>\r\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\"><style type=\"text/css\">\r\np\r\n\t{margin-top:0;\r\n\tmargin-bottom:0}\r\n</style></head><body dir=\"ltr\"><div class=\"elementToProof\" style=\"font-family:Aptos,Aptos_EmbeddedFont,Aptos_MSFontService,Calibri,Helvetica,sans-serif; font-size:12pt; color:rgb(0,0,0)\">this is an email test</div></body></html>`;
    try {

        const cleanedHtmlString = html.replace(/\\r\\n/g, '');

        return cleanedHtmlString
        // return parse(cleanedHtmlString);


    } catch (e) {

        return "";
    }

}

const GoogleEmailTemplate = () => {
    const nav = useNavigate();
    const {type, mailId, accountId, modelId} = useParams();
    const [emailLoading, setEmailLoading] = useState(false);

    const dispatch = useDispatch();

    const modelIdInt = parseInt(modelId);

    const [data, setData] = useState({});


    useEffect(() => {
        const fetchData = async () => {
            setEmailLoading(true);

            await api
                .get(`${actionTypes[type]}/get/emailThread/${accountId}/${modelId}/${mailId}`)
                .then((r) => {

                    if (r.code >= 400) {
                        dispatch(accountLogout());
                        nav("/training/email");
                        return;
                    }

                    let data = r.data;


                    let message = data.message;

                    const separator = " <";

                    const index = message.from.indexOf(separator);
                    const name = message.from.substring(0, index); // Extracts everything before the name of the user.
                    const email = message.from.substring(index); // Extracts email of the user


                    const messageContent = preprocessHtmlString(message.messageContent);

                    let historyData = data.history;
                    const isArr = Array.isArray(historyData)

                    if (!isArr) {
                        historyData = Object.entries(historyData).map(([key, value]) => (value));
                    }

                    let emailsLen = historyData?.length


                    let info = {
                        id: message.id,
                        name: name,
                        email: email,
                        subject: message.subject,
                        textContent: messageContent,
                        date: formatTime(message.date),
                        content: historyData,
                        isApproved: message.isApproved,
                        amount: emailsLen
                    };


                    setData(info);
                    setEmailLoading(false);
                })

                .catch((error) => {
                    console.log(error)
                    setEmailLoading(false);
                });
            // setData(result.data);
        };

        fetchData();
    }, []);

    const DisplayAllEmails = ({data}) => {

        return (
            <>
                {data?.map((email, id) => (
                    <IframeHtmlParser
                        key={id}
                        id={id}
                        htmlString={email}
                    ></IframeHtmlParser>
                ))}
            </>
        );
    }


    const IframeHtmlParser = ({id, htmlString}) => {

        const iframeRef = useRef(null);
        const [isExpanded, setExpand] = useState(false);
        const [iframeHeight, setIframeHeight] = useState(0);
        var isLoaded = false;
        const handleResize = () => {
            // plus margin height
            let height = iframeRef.current.contentDocument.body.scrollHeight + 16;


            setIframeHeight(height);
        };

        const handleExpansion = () => {
            setExpand(!isExpanded);
            handleLoad();
        };
        // useEffect(() => {
        //     if (iframeRef.current) {
        //         iframeRef.current.contentWindow.addEventListener(
        //             "resize",
        //             handleResize
        //         );
        //     }
        //
        //     return () => {
        //         iframeRef.current &&
        //         iframeRef.current.contentWindow.removeEventListener(
        //             "resize",
        //             handleResize
        //         );
        //     };
        // }, []);

        function handleLoad() {

            if (isLoaded) {
                return;
            }
            isLoaded = true;
            const iframe = iframeRef.current;
            const iframeDoc = iframe.contentDocument;
            iframeDoc.body.innerHTML = preprocessHtmlString(htmlString);
            handleResize();
        }

        return (
            <>
                {id === 0 ? (
                    <iframe
                        id={"email-iframe-id-" + id}
                        ref={iframeRef}
                        title="HTML Parser Iframe"
                        onLoad={handleResize}
                        srcDoc={preprocessHtmlString(htmlString)}
                        height={iframeHeight}
                        style={{
                            paddingLeft: "10px",
                            width: "100%",
                            // height: iframeHeight,
                            border: "none",
                            overflow: "auto"
                        }}
                    />
                ) : (
                    <>
                        <IconButton onClick={() => handleExpansion()}>
                            {isExpanded ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                        <Collapse in={isExpanded}>
                            <iframe
                                id={"email-iframe-id-" + id}
                                ref={iframeRef}
                                title="HTML Parser Iframe"
                                srcDoc={preprocessHtmlString(htmlString)}
                                onLoad={handleResize}
                                height={iframeHeight}

                                style={{
                                    paddingLeft: "10px",
                                    width: "100%",
                                    // height: iframeHeight,
                                    border: "none",
                                    overflow: "auto"
                                }}

                            />
                        </Collapse>
                    </>
                )}
            </>
        );
    };


    return (
        <Grid container rowSpacing={6} padding={"10px 25px"}>
            <Grid
                item
                xs={12}
                md={12}
                justifyContent="space-between"
                flexDirection="column"
                display="flex"
            >
                <Grid item xs={1}>
                    <IconButton onClick={() => nav(`/training/email/${type}`)}
                                sx={{position: "relative", left: "-10px"}}>
                        <ArrowBackIcon color="primary"/>
                    </IconButton>
                </Grid>
                <Grid container>
                    <Grid item xs={11} sx={{display: "flex", alignItems: "center"}}>
                        <SmoothContent width={200} height={20} loading={emailLoading}>
                            <Typography variant="emailTemplateSubject" width="100%">
                                {data.subject}
                            </Typography>
                        </SmoothContent>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <Box display={"flex"} alignItems={"center"}>
                            {!emailLoading && (
                                <ApproveEmail
                                    type={type}
                                    mailId={data.id}
                                    modelId={modelIdInt}
                                    accountId={accountId}
                                    checked={data.isApproved}
                                    sizes={35}
                                />
                            )}
                            
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <SmoothContent width={300} height={18} loading={emailLoading}>
                    <Box>
                        <Typography variant="emailTemplateName">{data.name}</Typography>
                        <Typography variant="emailTemplateEmail">{data.email}</Typography>
                    </Box>
                </SmoothContent>
                <SmoothContent width={70} height={18} loading={emailLoading}>
                    <Typography variant="emailTemplateTime">{data.date}</Typography>
                </SmoothContent>
            </Grid>

            <Grid item xs={12} md={12}>
                {emailLoading ? <EmailLoadingTemplate/> : data.amount > 0 && <DisplayAllEmails data={data.content}/>}
            </Grid>
        </Grid>
    );
};

export default GoogleEmailTemplate;
