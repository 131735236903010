import { Avatar, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import RocketIcon from '@mui/icons-material/Rocket';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PaymentOptions from "pages/protected/billing/Payment/components/PaymentOptions";
import AddPaymentForm from "pages/protected/billing/Payment/components/AddPaymentForm";
import Stripe from "_lib/Stripe";
import styled from "@emotion/styled";
import SmoothContent from "components/loadings/SmoothContent";
import { sendPixelFB } from "events/analytics/facebook";

const TAXES = {
  "CA": 0.13
}

const unlimitedTemplate = ['Unlimited AI Model', 'Unlimited daily use', 'Unlimited data entry'];

// hardcoded for now. waiting for mykola to make api`s
const support_info = {
  "1": {
    icon: <ConstructionIcon />,
    content: ['200 credits', '1 AI Model', 'Limited daily use', 'Limited data entry'],
  },
  "2": {
    icon: <RocketIcon fontSize="10" />,
    content: ['2,000 credits', ...unlimitedTemplate]
  },
  "3": {
    icon: <WorkspacePremiumIcon />,
    content: [...unlimitedTemplate],
  },
  "4": {
    icon: <WorkspacePremiumIcon />,
    content: ['20,000 credits', ...unlimitedTemplate],
  },
  "5": {
    icon: <WorkspacePremiumIcon />,
    content: ['50,000 credits', ...unlimitedTemplate],
  },
  "6": {
    icon: <WorkspacePremiumIcon />,
    content: ['100,000 credits', ...unlimitedTemplate],
  },
  "9": {
    icon: <RocketIcon />,
    content: ['2,000 credits', ...unlimitedTemplate]
  },
  "10": {
    icon: <WorkspacePremiumIcon />,
    content: [...unlimitedTemplate],
  },
  "11": {
    icon: <WorkspacePremiumIcon />,
    content: ['20,000 credits', ...unlimitedTemplate],
  },
  "12": {
    icon: <WorkspacePremiumIcon />,
    content: ['50,000 credits', ...unlimitedTemplate],
  },
  "13": {
    icon: <WorkspacePremiumIcon />,
    content: ['100,000 credits', ...unlimitedTemplate],
  },
};

const FeeContainer = styled(Typography)({
  maxWidth: 500,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const PaymentPlanInfo = ({ paymentInformation = false, planInformation = null, tempAddress = null }) => {
  const auth = useSelector((state) => state.auth);
  const country = paymentInformation?.country || '';
  const selectedPlanInformation = Boolean(planInformation) ? planInformation : auth.user.tariff_plan;
  const shouldUpdate = auth.user.tariff_plan.id != planInformation?.id;
  const isCurrentPlan = auth.user.tariff_plan.id === planInformation?.id;

  useEffect(()=> {
    const sendEventFB = async () => {
      await sendPixelFB({ event: 'InitiateCheckout', content_name: selectedPlanInformation.name});
    };

    sendEventFB();

  }, []);

  // if (!planInformation) return null;

  const taxRate = TAXES[tempAddress ?? country];
  const taxPrice = taxRate ? (selectedPlanInformation?.price * taxRate) : 0;
  const fullPrice = selectedPlanInformation?.price + taxPrice;
  const priceLoading = selectedPlanInformation?.price != 0 && ( (paymentInformation == "empty" && !tempAddress) || paymentInformation == null ); 

  return (
    // <Grid item container direction="column" xs={12} sm={12} md={3} sx={{ order: { xs: 1, sm: 1, md: 3 }, alignItems: selectedPlanInformation?.id == 1 ? "flex-start" : "center", padding: "24px 0 !important" }}>
      <List>
        <ListItem sx={{ padding: 0 }} >
          <ListItemIcon>
            <Avatar sx={{ bgcolor: '#3976ef', width: 30, height: 30 }} >
              {support_info[selectedPlanInformation?.id]?.icon}
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="page_sub_title_bold" sx={{ mt: 2 }}>
            {selectedPlanInformation?.name || "Trial"}
          </Typography>} />
        </ListItem>
        {/* secondaryAction={<Typography variant="describe_text" >{`$${planInformation.price}/${planInformation.frequency}`}</Typography> */}
        {isCurrentPlan ? (
          <Typography variant="page_content" sx={{ display: 'block', textTransform: "capitalize", fontWeight: 500, fontSize: '1rem' }} pr={4} pt={4}>Current plan</Typography>
        ) : (
          <>
            <ListItem sx={{ padding: 0 }} >
              <ListItemText primary={<Typography variant="list_header">
                Subscription
              </Typography>}
                secondary={<FeeContainer>
                  <Typography variant="page_content" sx={{ textTransform: "capitalize", fontWeight: 500 }} pr={4}>Billed {selectedPlanInformation?.frequency} </Typography>
                  <Typography variant="page_content" >USD ${selectedPlanInformation?.price}/{selectedPlanInformation?.frequency} </Typography>
                </FeeContainer>} />
            </ListItem>
            <ListItem sx={{ padding: 0 }} >
              <ListItemText
                secondary={<FeeContainer>
                    <Typography variant="page_content" pr={4} sx={{ textTransform: "capitalize", fontWeight: 500 }}>Tax</Typography>
                    <SmoothContent loading={priceLoading}>
                      <Typography variant="page_content" >+${taxPrice.toFixed(2)}</Typography>
                    </SmoothContent>
                </FeeContainer>} />
            </ListItem>
            <ListItem sx={{ padding: 0 }} >
              <ListItemText primary={<Typography variant="page_sub_title_bold">
              </Typography>}
                secondary={<FeeContainer>
                  <Typography variant="page_sub_title_bold" pr={4}>Total</Typography>
                  <SmoothContent loading={priceLoading}>
                    <Typography variant="page_content" >{shouldUpdate ? `$${fullPrice.toFixed(2)}` : '$0'}</Typography>
                  </SmoothContent>
                </FeeContainer>} />
            </ListItem>
          </>
        )}
        {/* {paymentInformation && <PaymentDetails />} */}
        {/* {support_info[planInformation.id].content.map((item, id) =>
            (
                <ListItem key={id} dense disablePadding >
                    <ListItemIcon>
                        <TaskAltIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="describe_text" >
                        {item}
                    </Typography>} />

                </ListItem>
            )
            )} */}
      </List>
    // </Grid>
  )
}


export default PaymentPlanInfo;