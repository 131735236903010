import moment from "moment";

export default (time) => {
 let date = moment.utc(time?.date ?? "");
 const localMomentObj = date.local();

 const formattedDate = localMomentObj.calendar(null, {
   lastHour: "mm [ ago]",
   lastDay: "[Yesterday]",
   sameDay: function (now) {
     if (this.get("hour") == now.get("hour")) {
       return "[" + this.fromNow() + "]";
     } else {
       return "HH:mm";
     }
   },
   nextDay: "[Tomorrow]",
   lastWeek: "dddd",
   nextWeek: "dddd",
   sameElse: "DD/MM/YYYY",
 });

 return formattedDate;
};