import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { components } from 'themes/components';
import { defaultPalette } from 'themes/pallete';
import { defaultTypography } from 'themes/typography';

const defaultSpacing = 4;

const defaultTheme = createTheme({
  palette: defaultPalette,
  typography: defaultTypography,
  spacing: defaultSpacing,
  components: components
});

export default responsiveFontSizes(defaultTheme);
