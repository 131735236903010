import {getToken, setToken} from "_helpers/tokenManagement";

const GOOGLE_PIXEL_SENT = 'GooglePixelSent';

export const shouldSendGooglePixel = () => {
    const pixelSent = getToken(GOOGLE_PIXEL_SENT) || false;
    return !pixelSent;
};

export const sendPixelGoogle = async (params = {event: 'conversion', custom: null, once: false}) => {


    if (typeof window.gtag === 'undefined' || process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
        return;
    }

    const {event, custom, once} = params;

    window.gtag('event', event, {'send_to': 'AW-11481494731/Mgg3CLLqnpkZEMvp5uIq'});

    if (once) {
        setToken(GOOGLE_PIXEL_SENT, "true");
    }
};


export const sendUIEvent = async (eventProps = {name: 'click', category: 'click', value: 'click', params: {}}) => {

    if(eventProps?.params?.once) {
        const label = `${eventProps.name}_sent`;
        if(getToken(label)) {
            if(eventProps?.params?.event_callback) {
                eventProps.params.event_callback();
            }
            return
        }
        setToken(`${eventProps.name}_sent`, "true");
    }

    if (typeof window.gtag === 'undefined' || process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
        return;
    }


    if(eventProps?.params?.once) {
        const label = `${eventProps.name}_sent`;

        if(getToken(label)) {

            if(eventProps?.params?.event_callback) {
                eventProps.params.event_callback();
            }
            return
        }

        setToken(`${eventProps.name}_sent`, "true");
    }

    const isMobile = localStorage.getItem('isMobileDevice') || 'false';
    const eventLabel = `${eventProps.name}${isMobile === 'true' ? '_mobile' : ''}`;

    console.log('sent event: ', eventLabel)
    window.gtag('event', eventLabel, eventProps.params);
};

export const eventMiddleware = (eventProps = {name: 'click', category: 'click', value: 'click'}, middleware) => {

    return (event) => {
        middleware(event);

        if (typeof window.gtag === 'undefined' || process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
            return;
        }

        sendUIEvent(eventProps)
    };
};

