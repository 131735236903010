import React, {useRef, useState, useEffect, useMemo} from "react";
import {
    Grid,
    Box,
    Typography,
    Divider,
} from "@mui/material";
import {decodeData} from "utils/formatContent";
import {useDispatch, useSelector} from "react-redux";
import controlledAPI from "_services/controlledApi";
import {LoadingButton} from "@mui/lab";
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import {interactWithUser} from 'redux/status';
import {updateModel} from 'redux/models';
import {addInvitations} from 'redux/invitations';
import {STANDART_GPT_MODEL} from 'constants';
import {isInputValid} from "utils/validateInputs";
import ShareTypeSelector from "./components/ShareTypeSelector";
import {Outlet, useLocation} from "react-router-dom";
import ShareModelSelector from "./components/ShareModelSelector";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";

const ShareModel = () => {


    const {
        user,
        cards,
        settings,
        deleteLoading,
        updateCardLoading,
        createeCardLoading,
        invitationsList,
        initialModel,
        updateModelSettingsLoading,
        models,
        savedWebsite
    } = useSelector((state) => ({
        user: state.auth.user,
        cards: state.marketplace.list,
        settings: state.auth.user.settings,

        invitationsList: state.invitations.list,
        updateModelSettingsLoading: state.models.updateLoading,
        updateCardLoading: state.marketplace.updateLoading,
        createeCardLoading: state.marketplace.createLoading,
        deleteLoading: state.marketplace.deleteLoading,
        models: state.models.list,
        initialModel: state.models.current,
        savedWebsite: state.models.current?.data?.website || ''
    }));

    const [selectedModel, setSelectedModel] = useState(initialModel);
    const {smallScreen} = useIsSmallScreen();


    const websiteStatus = useMemo(() => {
        return isInputValid(savedWebsite || '', {type: 'website', isRequired: true});
    }, [savedWebsite]);
    const isPublic =
        selectedModel?.public_hash &&
        selectedModel?.public_hash != '' &&
        selectedModel?.settings &&
        selectedModel?.settings.is_public == 1;

    const currentCard = cards?.filter(card => card?.model_id === selectedModel?.id)[0];
    const currentCardDescription = currentCard?.description?.length > 0 ? decodeData(currentCard?.description) : '';
    const currentCardMiniDescription = currentCard?.mini_description?.length > 0 ? decodeData(currentCard?.mini_description) : '';
    const currentCardTitle = currentCard?.title?.length ? decodeData(currentCard?.title) : '';

    const initialMonetizationState = {
        price: {
            value: '',
            errMsg: '',
        },
        title: {
            errMsg: '',
            value: '',
            countErr: false,
        },
        description: {
            errMsg: '',
            value: '',
            countErr: false,
        },
        miniDescription: {
            errMsg: '',
            value: '',
            countErr: false,
        },
        files: {
            errMsg: '',
            countErr: false,
        },
    };

    const [monetizationState, setMonetizationState] = useState(initialMonetizationState);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const location = useLocation();
    const [isListedOnMarketplace, setIsListedOnMarketplace] = useState(Boolean(selectedModel?.settings?.listed_on_marketplace));
    const [isPublicListedOnMarketplace, setIsPublicListedOnMarketplace] = useState(!isPublic || Boolean(selectedModel?.settings?.public_listed_on_marketplace));
    const [isMonetizationEnabled, setIsMonetizationEnabled] = useState(Boolean(selectedModel?.settings?.enable_monetization));
    const [marketPlacePublicLink, setMarketPlacePublicLink] = useState('');
    const [shareType, setShareType] = useState(Boolean(isMonetizationEnabled) ? 'monetization' : invitationsList.length ? 'restricted' : 'public');
    const dispatch = useDispatch();
    const tagRef = useRef();


    useEffect(() => {
        const type = location.pathname.split('/').pop() || 'public';
        setShareType(type)
    }, [location.pathname]);

    useEffect(() => {
        setTags([]);
        setIsMonetizationEnabled(Boolean(selectedModel?.settings?.enable_monetization));
        setIsListedOnMarketplace(Boolean(selectedModel?.settings?.listed_on_marketplace));
    }, [selectedModel]);


    useEffect(() => {
        const currentModelSettings = models.filter(item => item.id === selectedModel?.id)[0]?.settings;
        if (Boolean(currentModelSettings) && shareType === 'monetization') {
            setSelectedModel({...selectedModel, settings: currentModelSettings});
        }
    }, [models]);


    const publicLink = isPublic
        ? 'https://younet.ai/' + selectedModel?.public_hash
        : '';

    const copyPublicLink = async (link = null) => {
        const linkToCopy = shareType === 'monetization' ? marketPlacePublicLink : publicLink;
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(link || linkToCopy);
        } else {
            document.execCommand("copy", true, link || linkToCopy);
        }
        return dispatch(
            interactWithUser({type: "info", message: "Copied URL!"})
        );
    };

    const hideFromPublic = async () => {
        setLoading(true);
        let status = await changePublicStatus();

        if (status.code == 200) {
            const updatedModel = {...selectedModel, settings: status.model_settings};

            setSelectedModel(updatedModel);
            dispatch(updateModel({model: updatedModel}));

            dispatch(
                interactWithUser({
                    type: "success",
                    message: "Stopped sharing",
                })
            );
        }

        setLoading(false);
    };

    const isValidEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    };

    // const handleDeleteTag = (value) => {
    //     const newtags = tags.filter((val) => val !== value);
    //     setTags(newtags);
    // }

    const handleKeyPress = (e) => {
        if (tagRef.current.value.trim() === '') return;

        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            const enteredEmail = tagRef.current.value.trim();
            if (enteredEmail !== '' && isValidEmail(enteredEmail)) {
                if (tags.includes(enteredEmail)) {
                    setEmailError('Duplicate email address');
                    return;
                }
                if (enteredEmail === user.email) {
                    setEmailError('Self-invitations are not permitted');
                    return;
                }
                if (invitationsList?.map(item => item.recipient_email).includes(enteredEmail)) {
                    setEmailError('One invitation per user allowed');
                    return;
                }
                setTags([...tags, enteredEmail]);
                tagRef.current.value = '';
            } else {
                setEmailError('Invalid email format');
            }
        }
    };

    const savePublic = async (publicListedOnMarketplace = 1) => {
        setLoading(true);

        let status = await changePublicStatus(1, publicListedOnMarketplace);

        if (!status.model_settings || !status.model_settings.public_hash) {
            setLoading(false);
            dispatch(interactWithUser({template: "DEFAULT_ERROR"}));
            return;
        }

        const updatedModel = {
            ...selectedModel,
            settings: status.model_settings,
            public_hash: status.model_settings.public_hash,
            qr: status.model_settings.qr
        };

        setSelectedModel(updatedModel);
        dispatch(updateModel({model: updatedModel}));

        dispatch(
            interactWithUser({
                type: "success",
                message: "Shared to public",
            })
        );

        setLoading(false);
    };

    const handleSendInvitation = async () => {
      const enteredEmail = tagRef.current.value.trim();
      if (!isValidEmail(enteredEmail) && !tags.length) {
          setEmailError('Invalid email format');
          return;
      }
      ;

      if (enteredEmail === user.email) {
          setEmailError('Self-invitations are not permitted');
          return;
      }

      if (invitationsList?.map(item => item.recipient_email).includes(enteredEmail)) {
          setEmailError('One invitation per user allowed');
          return;
      }

      setLoading(true);
      const recipientEmails = Boolean(enteredEmail.length) ? [...tags, enteredEmail] : tags;

      let params = {
          modelId: selectedModel.id,
          recipientEmails,
      }

      let response = await controlledAPI({ignoreUser: true}).post('model/invite/sent', params);

      if (response.code >= 400) {
          let message = response?.message || "Something went wrong";
          setLoading(false);

          dispatch(
              interactWithUser({
                  message: message ?? "Something went wrong..",
                  type: 'error',
              })
          );
          tagRef.current.value = '';
          return;
      }

      setTags([]);
      setLoading(false);
      tagRef.current.value = '';
      dispatch(addInvitations({list: response.shared_model_invites}));
  }

    // TODO: move to the separate helper
    const changePublicStatus = async (isPublic = 0, publicListedOnMarketplace = 0) => {
        let payload = {
            isPublic: isPublic,
            contextWindowSize: selectedModel.settings
                ? selectedModel.settings.contextWindowSizePercent
                : settings
                    ? settings.contextWindowSizePercent
                    : 80,
            gptModel: selectedModel.settings
                ? selectedModel.settings.gpt_model
                : settings
                    ? settings.gpt_model
                    : STANDART_GPT_MODEL,
            modelId: selectedModel.id,
            enableMonetization: isMonetizationEnabled ? 1 : 0,
            monetizationPrice: Number(monetizationState.price.value),
            monetizationDescription: monetizationState.description.value,
            monetizationMiniDescription: monetizationState.miniDescription.value,
            publicListedOnMarketplace: publicListedOnMarketplace,
            listedOnMarketplace: isListedOnMarketplace ? 1 : 0
        };

        return await controlledAPI({ignoreUser: true})
            .put("model/settings/update", payload)
            .then((r) => {
                if (r.code >= 400) {
                    return {};
                } else {
                    return r;
                }
            })
            .catch((error) => {
                dispatch(interactWithUser({template: "DEFAULT_ERROR"}));
                return {};
            });
    };



    // const validateHash = (hash) => {
    //     const pattern = /[^a-zA-Z0-9\-_]/g;
    //     return hash.replace(pattern, '');
    // }


    // TODO: separate steps to components
    return (
        <Grid container sx={{px: 3, py: 6, height: 'inherit', overflowY: "auto", flexFlow: 'column nowrap'}}>

            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} sx={{ textAlign: 'left' }}>
                    <ShareModelSelector selectedModel={selectedModel} setSelectedModel={setSelectedModel}
                                        smallScreen={smallScreen}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} textAlign={smallScreen ? 'center' : 'left'}
                      sx={{paddingTop: '0 !important'}}>
                    <Typography sx={{ fontWeight: 400, color: '#7b8191', margin: 0, fontSize: {xs: '0.75rem', md: '0.875rem'} }}>
                        Your private conversations with Al model will not be shared with others.
                    </Typography>
                </Grid>
                {smallScreen && (
                  <Grid item sx={{ width: '100%' }}>
                    <Divider orientation="horisontal" sx={{ borderTop: '1px solid rgba(0,0,0, .1)', width: '100%' }} />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                    <ShareTypeSelector shareType={shareType} setShareType={setShareType} smallScreen={smallScreen}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} py={4} sx={{ paddingTop: {xs: '0 !important', md: '16px !important'} }}>
                    <Outlet
                        context={{
                            copyPublicLink: copyPublicLink,
                            selectedModel: selectedModel,
                            isPublic: isPublic,
                            hideFromPublic: hideFromPublic,
                            setIsPublicListedOnMarketplace: setIsPublicListedOnMarketplace,
                            savePublic: savePublic,
                            setTags: setTags,
                            tags: tags,
                            emailError: emailError,
                            setEmailError: setEmailError,
                            tagRef: tagRef,
                            handleKeyPress: handleKeyPress,
                            invitationsList: invitationsList,
                            cards: cards,
                            settings: settings,
                            monetizationState: monetizationState,
                            setMonetizationState: setMonetizationState,
                            loading: loading,
                            smallScreen: smallScreen,
                            websiteStatus: websiteStatus,
                            shareType: shareType,
                            handleSendInvitation: handleSendInvitation,
                        }}/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ShareModel;