import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import TrainStatus from "../../../showcase/TrainStatus";
import SimpleCheckbox from "../../../inputs/checkboxes/SimpleCheckbox";
import Typography from "@mui/material/Typography";
import taskActionsLabels from "mappings/languages/en/taskActionsLabels.json";
import {memo} from "react";

const TaskItem = ({item}) => {

    const paramsAction = item?.queue?.params?.action || 'default';
    const actionLabel = taskActionsLabels[paramsAction];

    const supportData = item.queue?.data || {};

    const dataKeys = Object.keys(supportData);
    const firstKey = dataKeys[0];

    const supportLabel = supportData[firstKey] || '';

    const siteTaskName = `${item.name} (${supportLabel})`;

    return (
        <ListItem xs={12}
                  secondaryAction={<TrainStatus status={item.status}/>}
                  disablePadding
                  divider
                  dense
        >
            <ListItemButton dense
                // onClick={handleRowSelect}
            >
                {/*{checkboxAllowed &&*/}
                {/*    <ListItemIcon>*/}
                {/*        <SimpleCheckbox edge="start"/>*/}
                {/*    </ListItemIcon>*/}
                {/*}*/}
                {/*    <ListItemIcon>*/}
                {/*        /!*<AccountTreeIcon fontSize="small" edge="start"/>*!/*/}
                {/*    </ListItemIcon>*/}

                {/*}*/}

                <ListItemText
                    sx={{color: "#3976EF"}}
                    // color="primary"
                    primary={siteTaskName}
                    secondary={
                        <Typography
                            // sx={{display: 'inline'}}
                            variant="count_down_text"
                            // color="secondary"
                            maxWidth="80%"
                        >
                            {actionLabel}
                        </Typography>

                    }


                />
            </ListItemButton>

            {/*{item.id}*/}


            {/*<ListItemAvatar>*/}
            {/*    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />*/}
            {/*</ListItemAvatar>*/}


        </ListItem>
    )
}

export default memo(TaskItem);