import { createSlice } from "@reduxjs/toolkit";
import { getInfo, getCredits, getPlans, unsubscribeThunk, getSubscriptionsThunk } from "./billingThunk";

const initialState = {
  currentPlanId: 0,
  planInfo: {
    availableCredits: 0,
    maximumAllowedCredits: 0,
    currentPlan: {
      planId: 1,
      name: "Trial",
      startDate: "",
      endDate: "",
      chargePerMonth: 0,
      description: "Ideal to try Younet to build 1 AI model.",
      frequency: "monthly"
    },
    nextChargeDate: "",
  },
	billingInfo: {
    autoRenewal: false,
		lastFourDigits: "0000",
		billingHistory: [],
    marketplaceInvoices: [],
	},
  tariff_plans: [],
  loading: false,
  loadingPlans: false,
  loadingBilling: false,
  checkoutSession: {
    loading: false,
    isNewCard: false,
    paymentInfo: null
  },
  limitations: [],
};


export const billingState = createSlice({
  name: "billing",
  initialState,
  reducers: {
    updateCredits(state, action) {
      const { newBalance } = action.payload;
      state.planInfo.availableCredits = newBalance;
    },
    checkoutLoadingStatus(state, action) {
      const loading  = action.payload;
      state.checkoutSession.loading = loading;
    },
    newCardStatus(state, action) {
      const { isNewCard } = action.payload;
      state.checkoutSession.isNewCard = isNewCard;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBillingInfo.pending, (state, { _ }) => {
      state.loadingBilling = true;
    });

    builder.addCase(getBillingInfo.rejected, (state, { payload }) => {
      state.loadingBilling = false;
    });

    builder.addCase(getBillingInfo.fulfilled, (state, { payload }) => {
      const { data, invoices } = payload;
      state.planInfo = {
        maximumAllowedCredits: data.maximumAllowedCredits,
        availableCredits: data.availableCredits,
        nextChargeDate: data.nextChargeDate,
        currentPlan: data.currentPlan
      }
      
      state.billingInfo = {
        autoRenewal: data.autoRenewal,
        lastFourDigits: data.billingInfo.lastFourDigits,
        billingHistory: data.billingInfo.billingHistory,
        marketplaceInvoices: invoices,
      }

      state.currentPlanId = data.currentPlan.planId;
      state.loadingBilling = false;
    });

    builder.addCase(getCredits.pending, (state, { _ }) => {
      state.loading = true;
    });
    builder.addCase(getCredits.fulfilled, (state, { payload }) => {
      const data = payload.data;
      state.currentPlanId = data.planId;
      state.planInfo.currentPlan.name = data.name;
      state.planInfo.currentPlan.planId = data.planId;
      state.planInfo.maximumAllowedCredits = data.maximumAllowedCredits;
      state.planInfo.availableCredits = data.availableCredits;
      state.planInfo.nextChargeDate = data.nextChargeDate;
      state.limitations = data.limitations;
      state.loading = false;
    });

    builder.addCase(getPlans.pending, (state, { _ }) => {
      state.loadingPlans = true;
    });
    builder.addCase(getPlans.fulfilled, (state, { payload }) => {
      const tariff_plans = payload.tariff_plans;
      state.tariff_plans = tariff_plans;
      state.loadingPlans = false;
    });
  },
});

export const { updateCredits,newCardStatus,checkoutLoadingStatus } = billingState.actions;

export const getBillingInfo = getInfo;
export const getCreditsInfo = getCredits;
export const getPlansInfo = getPlans;

export default billingState.reducer;
