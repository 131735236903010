import {memo} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React, {useState} from "react";
import languages from "mappings/chat-bot-languages.json";


export const LanguageSettings = memo(({
                                          handleChanges = () => {
                                          }
                                      }) => {

    const [language, setLanguage] = useState('en');

    const handleChange = (event) => {
        const value = event.target.value;
        setLanguage(value);
        handleChanges({language: value})
    };

    return (

        <FormControl fullWidth>
            <InputLabel id="chat-bot-language-label">Language</InputLabel>
            <Select
                labelId="chat-bot-language-label"
                sx={{maxHeight: '48.5px'}}
                defaultValue={"en"}
                value={language}
                label="Language"
                onChange={handleChange}
            >
                {languages.map((option) => (
                    <MenuItem
                        key={option.code}

                        value={option.code}
                        onClick={() => () => {
                        }}
                    >
                        {option.name}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>

    );
})
