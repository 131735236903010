import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {Typography, Box} from "@mui/material";
import UserForm from "./components/UserForm";
import Copyright from "components/Copyrights";
import styles from "./sign-up.module.css";
import {useSelector} from "react-redux";
import VerificationLetter from "components/userInteractions/Memos/Verification";
import TopAppBar from "components/TopAppBar";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {setToken} from "_helpers/tokenManagement";
import {sendPixelFB} from "events/analytics/facebook";
import {sendPixelGenai, shouldSendGenaiPixel} from 'events/analytics/genai';
import {sendPixelE4D} from "../../events/analytics/engine4display";

const SignUp = () => {
    const userState = useSelector((state) => state.auth.signup);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get("redirect");
    const isGenai = localStorage.getItem('genai');

    useEffect(() => {

        if (userState.isUserSaved) {

            if (redirectUrl) {
                setToken('loginRedirectUrlSession', redirectUrl)
            }

            const triggerSendFB = async () => {
                await sendPixelFB({event: 'CompleteRegistration', content_name: 'Platform Registration', once: true});
            };
            triggerSendFB();

            const triggerSendE4D = async () => {
                await sendPixelE4D({event: 'CompleteRegistration', once: true});
            };
            triggerSendE4D();

            if (shouldSendGenaiPixel() && Boolean(isGenai)) {
              const triggerSend = async () => {
                  await sendPixelGenai({
                    once: true
                  });
              };
              triggerSend();
            }

        }


    }, [userState.isUserSaved])


    return (
        <>
            <TopAppBar/>
            <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                {userState.isUserSaved ? (
                    <VerificationLetter email={userState.savedEmail}/>
                ) : (
                    <Paper
                        className={styles["paper-container"]}
                        variant="outlined"
                        sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
                    >
                        <Typography sx={{pb: 5}} component="h1" variant="h1" align="center">
                            Create your account
                        </Typography>
                        <Typography sx={{pb: 5}} align="center" variant="h3">Start using personalized AI in your daily
                            life to advance.</Typography>
                        <UserForm/>
                    </Paper>
                )}
                <Box pt={4}>
                    <Copyright/>
                </Box>
            </Container>
        </>
    );
};

export default SignUp;
