import EmailRow from "./EmailRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {memo, useEffect, useMemo} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import EmailsLoadingList from "./EmailsLoadingList";
import getEmailsActions from "../../actions/getEmailsActions";
import getEmailRequestParamsActions from "../../actions/getEmailRequestParamsActions";

const AllRows = memo(({supportData, list}) => {
    const selected = useSelector(state => state.email.content.selected, shallowEqual);

    return (list.map((row) => (
        <EmailRow selected={selected[row.id]} supportData={supportData} key={`email-row-${row.id}`} row={row}/>)))
});

const EmailsList = () => {

    const dispatch = useDispatch();

    const content = useSelector(state => state.email.content, shallowEqual);
    const syncCounter = useSelector(state => state.email.content.syncCounter);
    const account = useSelector(state => state.email.account, shallowEqual);
    const modelId = useSelector(state => state.models?.current?.id);
    const currentAcc = useMemo(() => account.current, [account.current]);
    const currentPageId = content.pagination.page.id;
    const currentSort = content.sort?.current?.id;
    const emailCategory = content.emails[currentSort];
    const cachedEmails = emailCategory ? emailCategory[currentPageId] : undefined;

    useEffect(() => {

        if (!currentAcc || !currentSort || typeof cachedEmails !== 'undefined') {
            return;
        }

        // default params + specific for each type of email
        const params = {
            modelId,
            accountId: currentAcc.id,
            ...getEmailRequestParamsActions[content.type](content)
        };


        dispatch(getEmailsActions[content.type](params));
    }, [content.sort, currentPageId, syncCounter]);


    return (<Table aria-label="collapsible table" size="small">
        <TableBody>
            {content.loading ? <EmailsLoadingList/> :
                <AllRows supportData={{accountId: currentAcc.id, modelId}} list={cachedEmails || []}/>}
        </TableBody>
    </Table>)
}

export default memo(EmailsList);