import { createPixel } from "_helpers/apiHandlers";
import { getToken, setToken } from "_helpers/tokenManagement";


export const shouldSendShareSalePixel = () => {
    const pixelSent = getToken("shareSalePixelSent") || false;
    return !pixelSent;
};

export const sendPixelShareSale = async (userId) => {

    if(process.env.REACT_APP_ANALYTICS_ENABLED !== 'true') {
        return;
    }

    const url = `https://www.shareasale.com/sale.cfm?tracking=${userId}&amount=0.00&merchantID=149700&transtype=lead`;
    const onLoad = () => {
        setToken("shareSalePixelSent", "true");
    };

    await createPixel(url, onLoad);
};



