import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { useState, forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { close } from "redux/status";


const InteractWithUser = ({ msg = "", display = false, type, autoHideDuration = 6000 }) => {
  const dispatch = useDispatch();

  let boolean = Boolean(display);
  const [open, setOpen] = useState(boolean);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(close());
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right", zIndex: 1500 }}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={handleClose}
    >
      <MuiAlert
        onClose={handleClose}
        severity={type}
        sx={{ width: "100%", maxWidth: "300px" }}
        elevation={6}
        variant="filled"
      >
        <div dangerouslySetInnerHTML={{ __html: msg }}></div>
        {/* {msg} */}
      </MuiAlert>
    </Snackbar>
  );
};

export default InteractWithUser;
