const getEmailRequestParamsActions = {
    outlook: (content) => ({
        pageId: content.pagination.page.id,
        folderId: content.sort.current.id,
        limits: content.maxRows
    }),
    gmail: (content) => ({
        query: content.sort.current.id,
        pageToken: content.pagination.tokens[content.pagination.page.id],
        maxResults: content.maxRows
    }),
    // Add functions for other email service providers if needed
};


export default getEmailRequestParamsActions;