import React, { useState, useEffect, useCallback, useRef, useMemo} from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import Form from './components/Form';

const CustomApi = () => {
  const [name, setName] = useState();

  return (
    <>
      <Grid
        container
        rowSpacing={4}
        item
        xs={12}
        md={12}
        padding={5}
        sx={{margin: "auto"}}
      >
        <Grid item xs={12} md={12}>
          <Typography variant="tool_subheader">
            {name}
          </Typography>
        </Grid>
        <Divider sx={{margin: '20px 0'}}/>
        <Form setName={setName} />
      </Grid>
    </>
  );
};

export default CustomApi;
