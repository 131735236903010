import moment from "moment";

const TRAINED_LABEL = 'trained';
const NEW_LABEL = 'new';
const PENDING_LABEL = 'pending';
const NEW_LABEL_DAYS_MAX = 1;


const getLinkType = (sort, item, isTrained = false) => {

    if (isTrained || (sort === 'trained' && item.data_type === 'link_resource_type')) {
        return TRAINED_LABEL;
    }

    // if (sort === 'all') {
        const date = item?.created_at?.date;
        const currentDate = new Date();
        const differenceInDays = moment(currentDate).diff(moment(date), 'days');

        if (differenceInDays <= NEW_LABEL_DAYS_MAX) {
            return NEW_LABEL;
        } else {
            return;
        }
    // }


    if (item.queue) {
        return PENDING_LABEL;
    }


    return "";

}

export default getLinkType;
