import React from "react";
import NavigationRoutes from "_navigation/NavigationRoutes";
import {Provider} from "react-redux";
import store from "redux/store";
import globalTheme from "themes/instances/default.js";
import {ThemeProvider} from "@mui/material/styles";
import {getToken} from "_helpers/tokenManagement";
import {validateUser} from "redux/auth/authThunk";
import {noToken} from "./redux/auth";

import CssBaseline from "@mui/material/CssBaseline";
import {LicenseInfo} from "@mui/x-license-pro";

import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import Events from "components/userInteractions/Events";

LicenseInfo.setLicenseKey(
    "a9c89fe5753a8865d35ed47698c14248Tz03MTQ5NSxFPTE3MjIwMDQwOTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

if (getToken("token")) {
    store.dispatch(validateUser());
} else {
    store.dispatch(noToken());
}


function App() {
    return (
        <ThemeProvider theme={globalTheme}>
            <CssBaseline/>
            <Provider store={store}>
                <NavigationRoutes/>
                {/*<Events/>*/}
            </Provider>
        </ThemeProvider>
    );
}

export default App;
