import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { login, thirdPartyLogin } from "redux/auth/authThunk";
import { useParams, useLocation, Link } from "react-router-dom";
import LinkStyle from "@mui/material/Link";
import api from "_services/api";
import InteractWithUser from "components/userInteractions/InteractWithUser";
import { encodeData } from "utils/formatContent";
import { Divider } from "@mui/material";
import GoogleAuthBtn from "components/auth/GoogleAuthBtn";
import FacebookAuthBtn from "components/auth/FacebookAuthBtn";
import transparentLogoSrc from "assets/images/transp-logo.png";
import { isInputValid } from "utils/validateInputs";
import { getToken, setToken, removeToken } from "_helpers/tokenManagement";
import useDeviceDetect from "hooks/useDetectDevice";

const LoginForm = ({ authType = "default", redirectAllowed = true }) => {
  const dispatch = useDispatch();
  const { verifyCode } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const authStateParam = searchParams.get("state");
  const authCodeParam = searchParams.get("code");
  const loginRedirectUrlSession = searchParams.get("redirect") || getToken("loginRedirectUrlSession") || '/';
  const isNewPlan = searchParams.get("newPlan");
  const userToken = getToken('token');
  const guestId = getToken("guestId") || null;
  const { isInAppView } = useDeviceDetect();
  const referrer = getToken('referrer');
  const userAgent = getToken('userAgent');
  if (!referrer?.length) {
    setToken('referrer', document.referrer);
  }
  if (!userAgent) {
    setToken('userAgent', navigator?.userAgent);
  }
  const [userState, setUserState] = useState({
    email: {
      errMsg: "",
      isRequired: true,
    },
    password: {
      errMsg: "",
      isRequired: true,
    },
  });

  const googleOptions = {
    method: "post",
    url: "google/oauth2/url",
  };

  const [open, setOpen] = useState(false);

  const redirectUser = () => {
    navigate(loginRedirectUrlSession);
    removeToken("loginRedirectUrlSession");
  }

  useEffect(() => {
    if (userToken && redirectAllowed) {
      redirectUser();
    }
  }, []);

  useEffect(() => {
    if (verifyCode) {
      api
        .get(`users/verify/${verifyCode}`)
        .then((r) => {
          if (r.code >= 400) {
            return;
          }
          setOpen(true);
        })
        .catch(() => { });
    }
  }, [verifyCode != ""]);

  useEffect(() => {
    if (authCodeParam && authType != "default") {
      let params = {
        code: encodeData(authCodeParam),
        state: authStateParam,
        redirectUrl:
          window.location.origin +
          `/portal/${authType}/login` +
          (authType == "facebook" ? "/" : ""),
        type: authType,
        // redirectUser: loginRedirectUrlSession ?? '/portal'
      };

      if (guestId) {
        params.guestId = guestId;
      }

      const referrer = getToken('referrer');
      const userAgent = getToken('userAgent');

      params.referrer = referrer;
      params.userAgent = userAgent;

      if (!referrer?.length) {
        setToken('referrer', document.referrer);
      }
      if (!userAgent) {
        setToken('userAgent', navigator?.userAgent);
      }

      const loginThirdParty = async () => {
        const response = await dispatch(thirdPartyLogin(params));
        if(response.type == "auth/thirdPartyLogin/fulfilled") {
          redirectUser();
        }
      }

      loginThirdParty();

    }
  }, [authCodeParam != ""]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");
    let password = data.get("password");

    let updates = {};
    let hasErrors = false;

    for (const field of data.entries()) {
      let keyData = userState[field[0]];
      // if(keyData.isRequired) {
      let state = isInputValid(field[1], {
        type: field[0],
        isRequired: keyData.isRequired,
      });

      updates[field[0]] = {
        isRequired: keyData.isRequired,
        errMsg: state.success ? "" : state.error || "Something wrong..",
      };

      if (state.error && !hasErrors) {
        hasErrors = true;
      }
    }

    setUserState((prevState) => ({
      ...prevState,
      ...updates,
    }));

    if (!hasErrors) {
      let prepareValues = {
        username: email,
        password: password,
        redirectUrl: loginRedirectUrlSession ?? "/portal/",
      };
      prepareValues.referrer = referrer;
      prepareValues.userAgent = userAgent;

      const response = await dispatch(login(prepareValues));

      if(response.type == "auth/login/fulfilled") {
        redirectUser();
      }
    }
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {verifyCode && (
        <InteractWithUser
          msg={"The email was verified successfully!"}
          display={open}
          type={"success"}
        />
      )}
      <Avatar
        // sx={{ width: 24, height: 24 }}
        src={transparentLogoSrc}
        alt="logo"
      />
      <Typography component="h1" variant="h5">
        Sign in to Younet
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          data-testid="email-field"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={userState.email.errMsg != ""}
          helperText={userState.email.errMsg ? userState.email.errMsg : ""}
        />
        <TextField

          data-testid="password-field"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={userState.password.errMsg != ""}
          helperText={
            userState.password.errMsg ? userState.password.errMsg : ""
          }
        />

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <LinkStyle>
            <Link to="/reset-password">
              {"Forgot password"}
            </Link>
          </LinkStyle>

          <LinkStyle>
            <Link to={`/sign-up${isNewPlan ? `?redirect=${loginRedirectUrlSession}` : ''}`} >
              {"Sign Up"}
            </Link>
          </LinkStyle>
        </Grid>
        <Button
          data-testid="submit-btn"
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            {/* <Link href="#" variant="body2">
              Forgot password?
            </Link> */}
          </Grid>
          <Grid item xs={12}>
            <Divider>or</Divider>
          </Grid>
          {!isInAppView && (
            <Grid sx={{ mt: 2, mb: 0, padding: 0 }} item xs={12}>
              <GoogleAuthBtn
                googleOptions={googleOptions}
                redirectUser={loginRedirectUrlSession}
                redirectUrl={window.location.origin + "/portal/google/login"}
                text={"Sign in with Google"}
                fullWidth
              />
            </Grid>
          )}
          <Grid sx={{ mt: 2, mb: 2, padding: 0 }} item xs={12}>
            <FacebookAuthBtn
              redirectUser={loginRedirectUrlSession}
              redirectUrl={
                window.location.origin + "/portal/facebook/login/"
              }
              text={"Sign in with Facebook"}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginForm;
