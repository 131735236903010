import { useCallback, memo } from "react";
import { Collapse, Fade, Paper } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import SearchList from "./components/SearchList";

const MAX_FILES_ALLOWED = 1;

const FileSelector = ({ modelId, isOpen, closeDialog, fileSources = [], files, setFileSources, isFilesAllowed, isLoading, setInputFile,setInputImage}) => {

  const { smallScreen } = useIsSmallScreen();
  // const files = useSelector((state) => state.tools.files.list, shallowEqual);
  const prices = useSelector((state) => state.tools.files.prices, shallowEqual);

  const handleSelectItem = useCallback((item) => {
    const formattedItem = { id: Number(item.file_id), name: item.relate_data?.original_name || "" };
    const alreadySelected = fileSources.some(selectedItem => selectedItem.id === formattedItem.id);

    setFileSources([formattedItem]);

    if (alreadySelected) {
      setFileSources(prevSelectedItems => prevSelectedItems.filter(selectedItem => selectedItem.id !== formattedItem.id));
      return;
    }

    setInputFile(null)
    setInputImage(null)

    // TODO: use this logic to select multiple files
    // if (fileSources.length < MAX_FILES_ALLOWED) {
    //   setFileSources(prevSelectedItems => [...prevSelectedItems, formattedItem]);
    // }

    closeDialog();
  }, [fileSources]);
  

  if (smallScreen) {
    return (
      <Collapse in={isOpen} unmountOnExit sx={{ padding: '0 5%', boxShadow: 'rgba(0,0,0, .1) 0px -7px 12px -4px' }}>
        <SearchList {...{ files, fileSources, handleSelectItem, modelId, prices, smallScreen, isFilesAllowed, closeDialog, isLoading }} />
      </Collapse>
    )
  }

  return (
    <Fade in={isOpen} unmountOnExit style={{ boxShadow: 'none', position: 'absolute', bottom: 0, right: 0, left: 0 }}>
      <Paper sx={{zIndex: 3}}>
        <SearchList {...{ files, fileSources, handleSelectItem, modelId, prices, smallScreen, isFilesAllowed, closeDialog, isLoading }} />
      </Paper>
    </Fade>
  );
};

export default memo(FileSelector, (prevProps, nextProps) => {
  return prevProps.modelId === nextProps.modelId && prevProps.isOpen === nextProps.isOpen && prevProps.fileSources?.length === nextProps.fileSources?.length;
});
