import { useEffect, useState, useRef,useMemo} from "react";
import {
    Box,
    Grid

} from "@mui/material";
import useViewportHeight from "hooks/useViewportHeight";
import api from "_services/api";
import controlledAPI from "_services/controlledApi";
import { getInitials } from "utils/prepareNames";
import { getToken } from "_helpers/tokenManagement";
import { useSelector, useDispatch } from "react-redux";
import { addMessages } from "redux/sharedMessages";
import Chat from "../../ShareModel/components/Chat";
import ShadowSpinner from "components/loadings/ShadowSpinner";

const Rabbit = (props) => {
    const viewportHeight = useViewportHeight();
    const [dataLoading, setDataLoading] = useState(true);
    // TODO: move data to redux
    const [data, setData] = useState({});
    const [topContainerHeight, setTopContainerHeight] = useState(115);
    const topContainerRef = useRef(null);
    const dispatch = useDispatch();

    // const auth = useSelector((state) => state.auth);
    const { auth, settings } = useSelector((state) => ({
      auth: state.auth,
      settings: state.auth.user.settings
    }));

    const userInfo = useMemo(()=>{
        let userInfo = {fullname:'',avatar:'',initial:''}
        if(auth.user){
          userInfo.fullname = auth.user.fullName;
          userInfo.avatar = auth.user.avatar;
          userInfo.initial = getInitials(auth.user.fullName);
        }
        return userInfo;
      },[auth.user])

    useEffect(() => {
        let params = {
          modelHash: "N85LNs"
        };

        params.guestId = getToken("guestId") || undefined;

        api
          .post("/model/share/data", params)
          .then((r) => {                
              if (r.code >= 400) {
                setDataLoading(false);
                return;
              }

              let messages = r.messages || [];
              let model = r.chat?.model || {};
              
              // dispatch(addMessages({type: 'rabbit', messages}));
              dispatch(addMessages({type: 'rabbit', messages: []}));
              setData({
                model: model,
                messages: messages,
                info: { chat_id: r.chat.id, guest_id: r.chat.guest_id, modelName: model.name }, 
              });
              setDataLoading(false);
              
              // if (chatId) {
              //   setData({
              //     model: model,
              //     messages: messages,
              //     info: { chat_id: chatId, guest_id: r.chat.guest_id, modelName: model.name }, 
              //   });
              // }
          })
          .catch((error) => {
              setDataLoading(false);
          });

          const resizeObserver = new ResizeObserver((entries) => {
              for (const entry of entries) {
                if (entry.target === topContainerRef.current) {
                  const newHeight = entry.target.clientHeight;
                  setTopContainerHeight(newHeight);
                }
              }
            });
        
            if (topContainerRef.current) {
              resizeObserver.observe(topContainerRef.current);
            }
        
            return () => {
              resizeObserver.disconnect();
            };
          }, []);

    if (dataLoading) {
      return <ShadowSpinner open={true}/>;
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            width="100%"
            height={"100%"}
            overflow="hidden"
            ref={topContainerRef}
            sx={{ backgroundColor: { xs: "white" } }}
        >
            <Grid item md={12} sx={{height: "100%"}} width="100%">
                {/* {data.model && ( */}
                <Chat
                  inputSizes={{lg: 6, md: 8, sm: 11, xs: 11.9}}
                  viewportHeight={viewportHeight}
                  topContainerHeight={topContainerHeight}
                  info={data.info}
                  modelName={data.model && getInitials(data.model.name)}
                  chatLoading={dataLoading}
                  topOffset = {{md: 110, xs: 70}}
                  userInfo={userInfo}
                />
                {/* )}  */}
            </Grid>
        </Box>

    );
};

export default Rabbit;
