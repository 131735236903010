import { Box } from '@mui/material';
import classnames from 'classnames';
import styles from '../invoices-table.module.css';

const InvoiceHeader = () => {
  return (
    <Box className={classnames(styles.line, styles.headerLine)}>
      <div>Date</div>
      <div>Invoice ID</div>
      <div>Status</div>
      <div>Amount</div>
      {/* <div>Actions</div> */}
    </Box>
  );
};

export default InvoiceHeader;

