import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import GeneralTooltip from "./GeneralTooltip";

const SupportIconTooltip = ({title = '', size="medium", margin= '0'}) => {
    return (
        <GeneralTooltip placement='bottom'
                        title={title}>
            <HelpOutlineIcon fontSize={size} sx={{color: '#B5B5B5', margin: margin}}/>
        </GeneralTooltip>
    )
}

export default SupportIconTooltip;