import styles from "../LinksTable/links-table.module.css";
import Grid from "@mui/material/Grid";
import LinksToolBar from "../LinksTable/components/LinksToolBar";
import {useNavigate, useParams} from "react-router-dom";
import {memo, useEffect, useRef, useState} from "react";
import api from "_services/api";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {deleteBrainItem, getFullDataById, getTrainedDataById, updateLinkContent} from "redux/links/linksThunk";
import SmoothContent from "components/loadings/SmoothContent";
import {Box, IconButton, ListItemText, Skeleton, Typography} from "@mui/material";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SaveIcon from '@mui/icons-material/Save';
import FlexWrapper from "components/wrappers/FlexWrapper";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import MarkdownComponent from "components/MarkdownComponent";
import ApproveLinksToggleBtn from "./components/ApproveLinksToggleBtn";
import SaveEditBtnsForBrainCells from "./components/SaveEditBtns";
import BrainCell from "components/BrainCell";
import {deleteItemChunk} from "redux/links";
import {encodeData} from "utils/formatContent";
import ShowMore from "components/userInteractions/ShowMore";
import DeleteItem from "./components/DeleteItem";


// didn't have time to work through this file, so here could be some fast solutions
const LinkContentView = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const [isEditMode, setEditMode] = useState(false);
    const contentRef = useRef('');
    const navigate = useNavigate();
    // const [isLoading, setLoading] = useState(false);
    const fullDataItems = useSelector(state => state.links.content.fullDataItems, shallowEqual);

    const {site_id, contentId} = params;

    const isBrainCell = site_id === 'trained';
    // const isTasks = site_id === 'tasks';


    const cachedItem = fullDataItems[contentId] || false;
    const isLoading = fullDataItems.loading || !fullDataItems[contentId];

    const handleSave = async () => {
        // console.log(savedData)

        setEditMode(false);

        // if (isBrainCell) {
        //
        // } else {

        const result = await dispatch(updateLinkContent({
            id: cachedItem.id,
            title: cachedItem.title,
            content: contentRef.current
        }));

        // }


    };

    const deleteBrain = async () => {
        await dispatch(deleteBrainItem({id: cachedItem.brainId}));
        navigate('/training/website')
    }

    const handleChunkDelete = async (vector_id) => {

        const chunksLen = cachedItem?.chunks?.length || 0;

        dispatch(deleteItemChunk({itemId: cachedItem.brainId, chunkId: vector_id}))

        if (chunksLen === 1) {
            await deleteBrain();
        }

    }


    useEffect(() => {
        if (cachedItem) {
            return;
        }

        if (isBrainCell) {
            dispatch(getTrainedDataById({id: contentId}));
        } else {
            dispatch(getFullDataById({id: contentId}));
        }


    }, [contentId]);

    // if (fullDataItems.loading) {
    //     return <>load</>
    // }


    const handleEditorChange = (val) => {
        contentRef.current = val;
    }

    return (
        <Grid container className={styles['container']}>
            <Grid item xs={12} sm={12} md={12} className={styles['border-bottom']} px={4}>
                {/*<LinksToolBar/>*/}
                <Grid container>
                    <Grid item xs={10} sx={{display: "flex", alignItems: "center"}}>
                        {/* TODO: improve loading states*/}
                        <SmoothContent width={200} height={20} loading={isLoading}>
                            <ListItemText
                                primary={cachedItem.title}
                                secondary={
                                    <SmoothContent width={200} height={20} loading={isLoading}>
                                        <Typography
                                            // sx={{display: 'inline'}}
                                            variant="truncate_text_150w"
                                            color="text.primary"
                                            maxWidth="80%"
                                        >
                                            {cachedItem.domain}
                                        </Typography>
                                    </SmoothContent>
                                }

                            />
                        </SmoothContent>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        {!isLoading &&
                            <FlexWrapper>
                                {isBrainCell ?
                                    <DeleteItem onDelete={deleteBrain}/>
                                    :
                                    <>
                                        <SaveEditBtnsForBrainCells onSave={handleSave} isEditMode={isEditMode}
                                                                   setEditMode={setEditMode}/>
                                        {!isEditMode && <ApproveLinksToggleBtn siteId={cachedItem.siteId}
                                                                               pageId={cachedItem.id}/>}
                                    </>
                                }

                            </FlexWrapper>}

                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item xs={12} sm={12} md={12}>*/}
            {/*    /!*{cachedItem.content}*!/*/}
            {/*</Grid>*/}
            {!isBrainCell &&
                <Grid item md={12} xs={12} sx={{padding: "10px 20px 0"}}>
                    {isLoading ? (
                        <>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                        </>
                    ) : isEditMode ? (
                        <CustomMdEditor
                            value={cachedItem.content}
                            onChange={handleEditorChange}
                            customHeight="100%"
                        />
                    ) : (
                        <ShowMore><MarkdownComponent source={cachedItem.content}/></ShowMore>
                    )}
                </Grid>
            }
            <Grid item xs={12} sm={12} md={12}>
                {isBrainCell && (
                    isLoading ? (
                        <Box px={4}>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                            <Skeleton variant="text" animation="wave"/>
                        </Box>
                    ) : (
                        cachedItem?.chunks?.map((item, index) => (
                            <BrainCell
                                key={`${index}-${item.vector_id}`}
                                item={item}
                                onDelete={(id) => {
                                    handleChunkDelete(id);
                                }}
                            />
                        ))
                    )
                )}


            </Grid>
        </Grid>
    )
}

export default memo(LinkContentView);