import TableContainer from "@mui/material/TableContainer";
import EmailsList from "./EmailsList";
import EmailTablePagination from "./EmailsPagination";

const EmailTable = () => {

    return (
        <TableContainer size="small">
            <EmailsList/>
            <EmailTablePagination/>
        </TableContainer>
    )
}


export default EmailTable;
