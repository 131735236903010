import axios from "axios";
import { getToken, removeToken } from "_helpers/tokenManagement.js";
import store from "redux/store";
import { interactWithUser } from "redux/status";
import {handleResponse} from "_helpers/apiHandlers.js";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || `https://api.younet.ai/api`,
});

api.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${getToken("token")}`;
    }

    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error) => {
    store.dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {

    let status = handleResponse(res);

    return status ?? res.data;
  },
  (err) => {
    let response = err.response || {};

    let status = handleResponse(response);

    return status ?? response.data;

    // handleResponse()
    // store.dispatch(interactWithUser({template: 'DEFAULT_ERROR'}));

    // throw new Error(err.response.data.message);
  }
);



export default api;
