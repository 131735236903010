import {useState, memo} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import api from "_services/api";
import {removeToken} from "_helpers/tokenManagement";
import {interactWithUser} from "redux/status";
import {alpha, Box, Button, IconButton, TextField, Toolbar, Typography} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {DateRangeCalendar, LocalizationProvider, MobileDateRangePicker} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {accountLogout, cleanEmails, reloadContent, toggleAllRows} from "redux/email";
import {styled} from "@mui/material/styles";
import {getBrainList, setStatus} from "redux/tools";
import approveEmailActions from "../../actions/approveEmailActions";
import disapproveEmails from "../../actions/disapproveEmails";
import CachedIcon from '@mui/icons-material/Cached';
import styles from '../../emaiil-view.module.css';
import classnames from "classnames";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        {...props}
    />
))(({theme}) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

// TODO: refactor this component when available
const EmailToolBar = () => {

    const modelId = useSelector(state => state.models?.current?.id);
    const account = useSelector(state => state.email.account.current, shallowEqual);
    const content = useSelector(state => state.email.content, shallowEqual);
    // const content = useSelector(state => state.email.content, shallowEqual);

    const getMapping = (type) => {

        let emails = {
            gmail: 'googleGmail',
            outlook: 'microsoftOutlook'
        }

        return  emails[type];
    }
    const maxAllowedEmails = useSelector(state => state.permissions?.planLimitations[getMapping(account.type)]?.maxLimit);
    const approvedEmailsList = useSelector(state => state.tools?.email?.list);


    const approvedEmailsAmount = approvedEmailsList?.length || 0;

    const numSelected = Object.keys(content.selected)?.length || 0;
    const allEmailsAmount = content.pagination.amount;
    const isAllEmailsSelectedChecked = allEmailsAmount !== 0 && allEmailsAmount === numSelected;

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [dateMenuAnchorEl, setDateMenuAnchorEl] = useState(null);
    const isDateMenuOpen = Boolean(dateMenuAnchorEl);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [dateRange, setDateRange] = useState([null, null]);

    const handleTableRefresh = (e) => {
        e.preventDefault();

        if(content.loading) {
            return;
        }

        refreshTable();
    }


    const handleDateMenuOpen = (event) => {
        setDateMenuAnchorEl(event.currentTarget);
    };

    const handleDateMenuClose = () => {
        setDateMenuAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const refreshTable = () => {
        dispatch(cleanEmails());
        dispatch(reloadContent());
    }

    const handleError = () => {
        dispatch(accountLogout());
    }


    const checkRestrictions = () => {

            if(maxAllowedEmails <= approvedEmailsAmount) {
                dispatch(interactWithUser({
                    message: `You used maximum training capability for emails. Upgrade your plan to get more.`,
                    type: "info",
                }))

                return true
            } else {
                return false
            }



    }

    const handleApproveByDate = async () => {

        const hasRestrictions = checkRestrictions();
        if(hasRestrictions) return

        let params = {
            "modelId": modelId,
            "dataStart": dateRange[0]?.format("YYYY-MM-DD HH:MM:ss"),
            "dataEnd": dateRange[1]?.format("YYYY-MM-DD HH:MM:ss")
        }

        if (account.type === 'outlook') {
            params.folderId = content.sort.current.id;
        }

        const url = approveEmailActions[`${account.type}Date`];
        await api
            .post(`${url}${account.id}`, params)
            .then((r) => {
                if (r.code >= 400) {
                    handleError();
                    return;
                }


                dispatch(
                    interactWithUser({
                        message: `Emails approve requested from ${dateRange[0]?.format("YYYY-MM-DD")} to ${dateRange[1]?.format("YYYY-MM-DD")}`,
                        type: "success",
                    })
                );
                // TODO: use it for progress
                // dispatch(setProgressItem({ modelId, progress: {email_message_type: {passed: true, skipped: false}} }));
                // manageProgressState(modelId, 'email_message_type', true);
                dispatch(setStatus({type: 'emails', status: 'done'}));
                refreshTable();
            })

            .catch((error) => {
                handleError();
            }).finally(()=>{
                handleDateMenuClose();
            });
    }

    const handleMultipleDisapprove = async () => {

        let selectedIds = Object.keys(content.selected);

        let params = {
            modelId: modelId,
            messageIds: selectedIds
        };

        if (account.type === 'outlook') {
            params.folderId = content.sort.current.id;
        }

        const url = disapproveEmails[`${account.type}Dis`];

        await api
            .post(`${url}${account.id}`, params)
            .then((r) => {
                if (r.code >= 400) {
                    handleError();
                    return;
                }


                dispatch(getBrainList({model_id: modelId}));

                // dispatch(
                //   interactWithUser({
                //     message: "Emails disapprove requested successfully",
                //     type: "success",
                //   })
                // );
                refreshTable();
            })

            .catch((error) => {
                handleError();
            });
    };

    const handleMultipleTraining = async () => {

        const hasRestrictions = checkRestrictions();
        if(hasRestrictions) return


        let selectedIds = Object.keys(content.selected);

        let params = {
            modelId: modelId,
        };

        if (account.type === 'outlook') {
            params.threadIds = selectedIds;
            params.folderId = content.sort.current.id;
        } else {
            params.messageIds = selectedIds;
        }


        const url = approveEmailActions[`${account.type}Ids`];

        await api
            .post(`${url}${account.id}`, params)
            .then((r) => {
                if (r.code >= 400) {
                    handleError();
                    return;
                }

                dispatch(setStatus({type: 'emails', status: 'pending'}));
                refreshTable();
            })

            .catch((error) => {
                handleError();
            });
    };

    const handleToggleSelect = (event) => {
        let isChecked = event.target.checked;

        dispatch(toggleAllRows({isChecked}))
    };

    return (
        <Toolbar
            sx={{
                padding: "0 !important",
                justifyContent: "space-between",
                display: "flex",
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            <Box display="flex" alignItems="center" >
                {!content.loading && allEmailsAmount > 1 && <Checkbox
                    checked={isAllEmailsSelectedChecked}
                    onChange={(event) => handleToggleSelect(event)}
                />}

                {numSelected > 0 ? (
                    <>
                        <Box>
                            <Typography
                                sx={{flex: '1 1 100%', pl: '10px'}}
                                color="inherit"
                                variant="subtitle1"
                                component="div"

                            >
                                {numSelected} selected
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                size="medium"
                                aria-label="display more EmailMenuBar"
                                variant="outlined"
                                sx={{ml: '15px'}}
                                onClick={handleMenuOpen}
                            >
                                Action
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    "aria-labelledby": "demo-customized-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleMultipleTraining();
                                        handleClose();
                                    }}
                                    disableRipple
                                >
                                    Add to Brain
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleMultipleDisapprove();
                                        handleClose();
                                    }}
                                    disableRipple
                                >
                                    Remove from Brain
                                </MenuItem>
                            </StyledMenu>
                        </Box>
                    </>
                ) : (
                    <Box>
                        {content.sort.current.sentFolder && (
                            <Button
                                aria-label="approve by date"
                                onClick={handleDateMenuOpen}
                            >
                                Add by date
                            </Button>
                        )
                        }
                        <Menu
                            id="date-menu"
                            anchorEl={dateMenuAnchorEl}
                            open={isDateMenuOpen}
                            onClose={handleDateMenuClose}
                        >
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    {isMobile ? (
                                        <MobileDateRangePicker
                                            startText="Start Date"
                                            endText="End Date"
                                            value={dateRange}
                                            onChange={(newValue) => setDateRange(newValue)}
                                            renderInput={(startProps, endProps) => (
                                                <>
                                                    <TextField {...startProps} />
                                                    <Box sx={{mx: 2}}> to </Box>
                                                    <TextField {...endProps} />
                                                </>
                                            )}
                                        />
                                    ) : (
                                        <DateRangeCalendar
                                            value={dateRange}
                                            onChange={(newValue) => setDateRange(newValue)}
                                        />
                                    )}
                                </LocalizationProvider>
                            </MenuItem>

                            <MenuItem display="flex"
                                      sx={isMobile ? {justifyContent: 'center'} : {justifyContent: 'flex-end'}}>
                                <Button onClick={handleApproveByDate}>
                                    ADD TO BRAIN
                                </Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            </Box>
           <IconButton className={classnames( styles.reloadContentIcon, content.loading && styles.reloadIconAnimation)} onClick={handleTableRefresh} sx={{marginRight: "8px"}}> <CachedIcon color="icons"/> </IconButton>
        </Toolbar>
    )
}

export default memo(EmailToolBar);