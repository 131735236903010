import React, {Suspense, lazy, useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Layout from "components/Layout";
import Spinner from "components/loadings/Spinner";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {getList, getInvitationsList} from "redux/models";
import { getSharedCards } from 'redux/marketplace';
import controlledAPI from "_services/controlledApi";
import {getAllCards} from "redux/marketplace";
import {useDispatch} from "react-redux";
import {changeModel} from "redux/models";
// import { getAllCards } from "redux/marketplace";
import Content from "components/ContentContainer";
import CreateModel from "pages/protected/Model/components/CreateModel";
import Subscription from 'components/userInteractions/Events/components/Subscription';
import Onboarding from "pages/protected/Onboarding";
import {getCreditsInfo} from "redux/billing";
import {setJsonData} from "redux/auth";

import UpgradePlanWrapper from "components/wrappers/UpgradePlanWrapper";
import moment from "moment";
import Playground from "../pages/protected/tools/Playground";
import {MAX_ALLOWED_DAYS_TRIAL} from "constants";

const VerificationMemo = lazy(() => import('components/userInteractions/Memos/Verification'));

// optimize it when Alex will allow us )))
const PrivateRoute = () => {
        const dispatch = useDispatch();
        const auth = useSelector((state) => state.auth, shallowEqual);
        const model = useSelector((state) => state.models.current, shallowEqual);
        const models = useSelector((state) => state.models, shallowEqual);
        const isNewModel = useSelector((state) => state.models.isNewModel, shallowEqual);
        const planInfo = useSelector((state) => state.billing.planInfo, shallowEqual);
        const [authApproved, setAuthApproved] = useState(false);
        const [globalLoading, setGlobalLoading] = useState(true);
        const [importantDataLoading, setImportantDataLoading] = useState(true);
        const isBrainOnboardingEnabled = localStorage.getItem('brainOnboarding');
        const isOnboardingBrainStatus = localStorage.getItem('onboardingBrainStatus');
        const cachedModel = localStorage.getItem('cachedModel');

        const location = useLocation();

        const navigate = useNavigate();
        const showOnboarding = model?.noModels && !Boolean(auth.user?.jsonData) && !Boolean(localStorage.getItem('monetizationSubscription')) && !Boolean(auth?.jsonData) || isNewModel || isBrainOnboardingEnabled;

        useEffect(() => {

            if (auth.token === null) {
                navigate('/login')
                return
            } else {
                setAuthApproved(true);
            }

            return () => {
                setGlobalLoading(false)
            }
        }, []);

        useEffect(() => {


            if (!authApproved) {
                return;
            }

            const fetchData = async () => {


                if (auth.user?.is_verified && auth.token) {
                    // Dispatch all async actions and wait for them to complete

                    await Promise.all([
                        dispatch(getList()),
                        dispatch(getInvitationsList()),
                        dispatch(getCreditsInfo()),
                        dispatch(getAllCards()),
                        dispatch(getSharedCards({ query: null, loadMore: false, initialSearch: true }))
                    ]);


                    setImportantDataLoading(false);
                }
            };
            fetchData();
            // dispatch(getAllCards());

        }, [authApproved, auth.token, dispatch]);

        useEffect(() => {
            if (!importantDataLoading) {
                const fetchData = async () => {
                    const userId = auth.user?.id;
                    const localMonetizationSubscription = localStorage.getItem('monetizationSubscription');
                    const localGenai = localStorage.getItem('genai');
                    let modelId = null;
                    let isTrial = null;
                    let isSubscription = null;

                    if (Boolean(localMonetizationSubscription) || Boolean(localGenai)) {
                        let jsonData = {};
                        if (Boolean(localMonetizationSubscription)) {
                          const monetizationSubscription = JSON.parse(localMonetizationSubscription);
                          modelId = monetizationSubscription.model_id;
                          isTrial = monetizationSubscription?.isTrial === 1;
                          isSubscription = monetizationSubscription?.isTrial === 0;

                          jsonData = { ...jsonData ,monetizationSubscription };
                        }

                        if (Boolean(localGenai)) {
                          jsonData = { ...jsonData, genai: 1 };
                        }

                        await controlledAPI({ignoreUser: true})
                            .put(`users/${userId}`, { jsonData })
                            .then(async (res) => {
                                if (res.code === 200) {
                                    dispatch(setJsonData({ jsonData }));
                                    if (isTrial) {
                                        await controlledAPI({ignoreUser: true})
                                            .get(`model/preview/${modelId}`)
                                            .then(async (res) => {
                                                if (res.code === 200) {
                                                    await dispatch(getList()).then((res) => {

                                                        if (res?.type === 'models/list/fulfilled') {
                                                            dispatch(changeModel(modelId));
                                                        }
                                                    })
                                                    // localStorage.removeItem('model/preview/${modelId}');

                                                }
                                            });
                                    } else if (isSubscription) {

                                        //  localStorage.removeItem('monetizationSubscription');
                                        // dispatch(getList()).then((res) => {
                                        // if (models.length === 1) {
                                        //     dispatch(changeModel(78));
                                        // }
                                        navigate('/billing/subscribe');

                                        // });
                                    }
                                }
                            })
                            .finally(() => {
                              localStorage.removeItem('genai');
                            });

                    } else {
                        const jsonData = auth.user?.jsonData;
                        if (Boolean(jsonData)) {
                            const modelId = jsonData?.monetizationSubscription?.model_id;
                            const monetizationSubscription = jsonData?.monetizationSubscription;
                            // if (model?.noModels && !showOnboarding) {
                            //     dispatch(changeModel(78));
                            // } else
                            if (modelId && !monetizationSubscription.isRedirected) {
                                dispatch(changeModel(modelId));

                                const updatedData = { ...monetizationSubscription, isRedirected: true };
                                await controlledAPI({ ignoreUser: true })
                                    .put(`users/${auth.user.id}`, {jsonData: { ...auth.user.jsonData, monetizationSubscription: updatedData }})
                            }
                        }

                        if (auth.user.jsonData?.monetizationSubscription?.isTrial === 0) {
                            navigate(`/billing/subscribe`);
                        }
                    }

                    // if (location.pathname === '/rabbit-ai') {
                    //     dispatch(changeModel(78));
                    // }


                    setGlobalLoading(false);
                }


                fetchData()
            }


        }, [importantDataLoading]);


        if (auth.loading) return <Spinner/>;

        if (auth.user?.is_verified === 0) {
            return <Suspense fallback={<Spinner/>}><VerificationMemo email={auth.user.email}/></Suspense>;
        }

        if (!auth.user || !auth.token) return <Navigate to="/login" replace/>;

        if (globalLoading) return <Spinner/>;

        // trial ended
        if (Boolean(auth.user.tariff_plan?.is_default)) {

            const isTrialRestricted = (availableCredits, created_date) => {

                const dateToCheck = moment(created_date);
                const currentDate = moment();
                const differenceInDays = currentDate.diff(dateToCheck, 'days');

                return availableCredits <= 0 || differenceInDays > MAX_ALLOWED_DAYS_TRIAL;
            }

            if (isTrialRestricted(planInfo.availableCredits, auth.user?.created_at?.date) || auth.user.tariff_plan.manualUpgradeLock) {
                return <UpgradePlanWrapper><Layout model={model}><Playground/></Layout></UpgradePlanWrapper>;
            }

        }

        if (showOnboarding || model?.noModels && !showOnboarding) {
            // return <Content title="Creating Model"><CreateModel/></Content>;
            return <Content title="Creating Model" newUser={true}><Onboarding initialStep={(isNewModel || isBrainOnboardingEnabled) ? 'modelBrain' : 'initial'} /></Content>;
        }

        return <Layout model={model}><Outlet/></Layout>;
    }
;

export default PrivateRoute;

