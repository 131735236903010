import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Skeleton, Typography, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../../payment.module.css";
import AddCardIcon from '@mui/icons-material/AddCard';
import { useEffect, useState } from "react";
import api from "_services/api";
import AddPaymentForm from "../AddPaymentForm";
import Stripe from "_lib/Stripe";
import { ArrowBack } from "@mui/icons-material";
import controlledAPI from "_services/controlledApi";
import PoweredByStripe from '../logos/PoweredByStripe';
import Guarantee from '../logos/Guarantee';
import DeletePaymentDialog from "components/dialogs/DeletePaymentDialog";
import StripePaymentSkeleton from "components/loadings/templates/StripePaymentSkeleton";

export const PaymentBoxInfo = ({ item, handleDeleteRows = false }) => {

    const fullAddress = [item.address_line_1, item.city, item.state, item.postal_code]
        .filter(Boolean)
        .join(', ');

    return (
        <Grid container className={styles["payment-box-info"]}>
            <Grid item md="auto">
                <FormControlLabel sx={{ paddingLeft: "5px" }} value={item.id} control={<Radio
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: 16
                        },
                    }} />} />

            </Grid>
            <Grid item xs>
                <Typography variant="describe_text">{fullAddress}</Typography>
            </Grid>
            <Grid item md={5} textAlign="right">
                <Typography variant="describe_text">{item.name}</Typography>
            </Grid>
            <Grid item md={1} textAlign="right">
                <IconButton onClick={() => handleDeleteRows(item.id)}>
                    <DeleteIcon color="icons" />
                </IconButton>
            </Grid>

        </Grid>
    );
}

export const AddPaymentMethodBox = ({ handleNewCard }) => {

    const addNewCardClick = () => {
        handleNewCard(true)
    }

    return (
      <Grid container className={styles["payment-box-info"]} onClick={addNewCardClick}>
        <Grid item xs={10} md={6}>
          <Typography variant="describe_text">Add Payment Method</Typography>
        </Grid>
        <Grid item xs={2} md={1} textAlign="right">
          <IconButton >
            <AddCardIcon color="icons" />
          </IconButton>
        </Grid>
      </Grid>
    );
}


const PaymentOptions = () => {

    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [defaultCard, setDefaultCard] = useState("");
    const [newCard, setNewCard] = useState({ replace: false, isNewCard: false });
    const [isOpen, setOpenDeleteDialog] = useState(false);

    const handlePrimaryChange = async (e = {}, id = null) => {

        setLoading(true);
        const paymentId = id || e.target.value;

        const { code } = await api.get(`stripe/payment_method/set-default/${paymentId}`);


        if (code == 200) {
            setDefaultCard(paymentId);
        }
        setLoading(false);

    }

    const addPaymentMethod = (payment_method) => {

        if (payment_method) {
            setPayments(prevItems => [...prevItems, payment_method]);
            setDefaultCard(payment_method.id);
        }

    }

    const handleDeleteRows = async (id) => {

        setLoading(true);

        const { code } = await api.delete(`stripe/payment_method/${id}`);

        if (code == 200) {
            let newArray = payments.filter(item => item.id !== id);

            if (defaultCard == id) {
                let newFirstItem = newArray[0] || null;

                if (newFirstItem) {
                    const { code } = await controlledAPI({ ignoreUser: true }).get(`stripe/payment_method/set-default/${newFirstItem.id}`);
                    if (code == 200) {
                        setDefaultCard(newFirstItem.id);
                    }

                }


            }
            setPayments(newArray);
        }
        setLoading(false);
    }

    const handleLastPayment = () => {
        setOpenDeleteDialog(true);
    }

    const setPaymentMethod = async (payment_method) => {

        if (newCard.replace) {
            let lastObjectId = payments[0]?.id;
            await handleDeleteRows(lastObjectId);
        }

        if (payment_method) {
            addPaymentMethod(payment_method);
        }

        setNewCard({ replace: false, isNewCard: false })
    }

    const handleNewCard = (state, replace = false) => {

        setNewCard({ replace: replace, isNewCard: state });

        if (isOpen) {
            setOpenDeleteDialog(false);
        }

    }

    const handleBack = () => {
        handleNewCard(false);

        if (isOpen) {
            setOpenDeleteDialog(false);
        }

    }

    useEffect(() => {

        const getPayments = async () => {
            setLoading(true);
            // add to thunk later
            // dispatch(checkoutLoadingStatus(true));
            let { payment_methods, code } = await api.get("stripe/payment_method/list");

            if (payment_methods == null) {
                handleNewCard(true)
                // dispatch(newCardStatus({isNewCard: true}))
            } else {
                const defaultPayment = payment_methods.find(item => item.is_default === 1);
                setDefaultCard(defaultPayment?.id);
                setPayments(payment_methods);
            }
            setLoading(false);

        }

        if (payments.length == 0) {
            getPayments();
        }


    }, []);




    if (newCard.isNewCard
        // || (payments.length == 0 && !loading)
    ) {
        return (
            <Box display="flex" alignItems="flex-start">
                {payments.length != 0 &&
                    <IconButton onClick={handleBack} color="primary">
                        <ArrowBack />
                    </IconButton>
                }


                <Stripe loadingComponent={<StripePaymentSkeleton />}><AddPaymentForm setPaymentMethod={setPaymentMethod} /></Stripe>
            </Box>

        )
    }



    const methodsLen = payments.length || 0;

    return (

        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Typography variant="page_sub_title">Choose Payment Method</Typography>
            </Grid>
            <Grid item xs={12}>

                {loading ? (<>
                    <Box className={styles["payment-box-info"]}>
                        <Skeleton variant="text" animation="wave" width="100%" />
                    </Box>
                    <Box className={styles["payment-box-info"]}>
                        <Skeleton variant="text" animation="wave" width="100%" />
                    </Box>
                    <Box className={styles["payment-box-info"]}>
                        <Skeleton variant="text" animation="wave" width="100%" />
                    </Box>
                </>
                ) :
                    <RadioGroup
                        value={defaultCard}
                        onChange={handlePrimaryChange}
                        sx={{ width: "100%" }}
                    >
                        {payments.map((item, index) =>
                            <PaymentBoxInfo key={index} item={item} handleDeleteRows={methodsLen == 1 ? handleLastPayment : handleDeleteRows} />
                        )}
                    </RadioGroup>
                }

            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <AddPaymentMethodBox handleNewCard={handleNewCard} />
              <Box mt={6} sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <Guarantee />&nbsp;&nbsp;&nbsp;
                <Typography variant="describe_text" sx={{ fontWeight: 600, color: '#2a2f32' }}>Guaranteed safe checkout</Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;<Chip sx={{ padding: '5px 0 0', height: '20px', lineHeight: '20px', borderRadius: '4px', background: '#6a7177' }} label={<PoweredByStripe />}></Chip>
              </Box>
            </Grid>
            <DeletePaymentDialog isOpen={isOpen} setOpen={setOpenDeleteDialog} handleNewCard={handleNewCard} />
        </Grid>

    );


}

export default PaymentOptions;