import React, {useRef, useState, useEffect, useMemo} from "react";
import {
    Grid,
    Select,
    MenuItem,
    TextField,
    Box,
    Chip,
    Typography,
    Table,
    TableBody,
    Skeleton,
    Switch,
    FormControlLabel,
    FormHelperText,
    DialogContent,
    DialogActions,
    Button,
    InputAdornment,
} from "@mui/material";
import {getAllCards} from 'redux/marketplace';
import classnames from 'classnames';
import SaveIcon from '@mui/icons-material/Save';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import PublicIcon from '@mui/icons-material/Public';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {encodeData, decodeData} from "utils/formatContent";
import {useDispatch, useSelector} from "react-redux";
import controlledAPI from "_services/controlledApi";
import {LoadingButton} from "@mui/lab";
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import {interactWithUser} from 'redux/status';
import {updateModel, updateModelSettings} from 'redux/models';
import {addNewCard, updateCard, updateCardWithHash, deleteCard, addFiles} from 'redux/marketplace';
import CustomMdEditor from "components/inputs/CustomMdEditor";
import {addInvitations} from 'redux/invitations';
import { STANDART_GPT_MODEL, ENHANCED_GPT_MODEL } from 'constants';
import DeleteIcon from '@mui/icons-material/Delete';
import Row from './components/Row';
import AddIcon from '@mui/icons-material/Add';
import FileInput from 'components/inputs/FileInput';
import Hint from './components/Hint';
import PublicLink from './components/PublicLink';
import ImagesList from './components/ImagesList';
import styles from './shareModelConfig.module.css';
import ChatBot from "./components/screens/ChatBot";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {isInputValid} from "utils/validateInputs";
import CustomerSupport from "./components/screens/CustomerSupport";
import QrCodeIcon from '@mui/icons-material/QrCode';

const ShareModelConfig = ({selectedModel, setSelectedModel, handleClose, preselectedFlow = 'anyone'}) => {
    const {
        user,
        cards,
        settings,
        listLoading,
        invitationsList,
        deleteLoading,
        updateCardLoading,
        createeCardLoading,
        updateModelSettingsLoading,
        models,
        savedWebsite
    } = useSelector((state) => ({
        user: state.auth.user,
        cards: state.marketplace.list,
        settings: state.auth.user.settings,
        listLoading: state.invitations.loading,
        invitationsList: state.invitations.list,
        updateModelSettingsLoading: state.models.updateLoading,
        updateCardLoading: state.marketplace.updateLoading,
        createeCardLoading: state.marketplace.createLoading,
        deleteLoading: state.marketplace.deleteLoading,
        models: state.models.list,
        savedWebsite: state.models.current?.data?.website || ''
    }));

    const websiteStatus = useMemo(() => {
        return isInputValid(savedWebsite || '', {type: 'website',  isRequired: true});
    }, [savedWebsite]);
    const isPublic =
        selectedModel?.public_hash &&
        selectedModel?.public_hash != '' &&
        selectedModel?.settings &&
        selectedModel?.settings.is_public == 1;

    const currentCard = cards?.filter(card => card?.model_id === selectedModel?.id)[0];
    const currentCardDescription = currentCard?.description?.length > 0 ? decodeData(currentCard?.description) : '';
    const currentCardMiniDescription = currentCard?.mini_description?.length > 0 ? decodeData(currentCard?.mini_description) : '';
    const currentCardTitle = currentCard?.title?.length ? decodeData(currentCard?.title) : '';

    const initialMonetizationState = {
        price: {
            value: '',
            errMsg: '',
        },
        title: {
            errMsg: '',
            value: '',
            countErr: false,
        },
        description: {
            errMsg: '',
            value: '',
            countErr: false,
        },
        miniDescription: {
            errMsg: '',
            value: '',
            countErr: false,
        },
        files: {
            errMsg: '',
            countErr: false,
        },
    };

    const [monetizationState, setMonetizationState] = useState(initialMonetizationState);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [selectFileVisible, setSelectFileVisible] = useState(false);
    // const [currentCard, setCurrentCard] = useState(null);
    const [isMonetizationFormActive, setIsMonetizationFormActive] = useState(!Boolean(currentCard?.url_hash));
    const [file, setFile] = useState(null);
    const [isListedOnMarketplace, setIsListedOnMarketplace] = useState(Boolean(selectedModel?.settings?.listed_on_marketplace));
    const [isPublicListedOnMarketplace, setIsPublicListedOnMarketplace] = useState(!isPublic || Boolean(selectedModel?.settings?.public_listed_on_marketplace));
    const [isMonetizationEnabled, setIsMonetizationEnabled] = useState(Boolean(selectedModel?.settings?.enable_monetization));
    const [clearFile, setClearFile] = useState(false);
    const [marketPlacePublicLink, setMarketPlacePublicLink] = useState('');
    const [shareType, setShareType] = useState(Boolean(isMonetizationEnabled) ? 'monetization' : invitationsList.length ? 'restricted' : 'anyone');
    const dispatch = useDispatch();
    const tagRef = useRef();
    const amountRef = useRef();
    const filesRef = useRef();
    const switcherRef = useRef();
    const switcherMonetizationRef = useRef();
    const isRestrictionType = shareType === 'restricted' || shareType === 'monetization';
    const currentLinkArray = marketPlacePublicLink.split('/');
    const currentHash = currentLinkArray[currentLinkArray.length - 1];

    useEffect(() => {
        setShareType(preselectedFlow);
    }, [preselectedFlow]);

    useEffect(() => {
        setTags([]);
        setIsMonetizationEnabled(Boolean(selectedModel?.settings?.enable_monetization));
        setIsListedOnMarketplace(Boolean(selectedModel?.settings?.listed_on_marketplace));
    }, [selectedModel]);

    const countLimits = {
        title: 50,
        miniDescription: 150,
    }

    useEffect(() => {
        const card = cards?.filter(card => card?.model_id === selectedModel?.id)[0];
        if (cards?.length && card) {
            // setCurrentCard(card);
            if (Boolean(file?.length)) {
                dispatch(addFiles({upload: file, cardId: card.id}));
                setFile(null);
            }
            setIsMonetizationFormActive(!Boolean(card?.url_hash));
            // setShareType('monetization');
            setMonetizationState({
                price: {
                    value: selectedModel?.settings?.monetization_price,
                    errMsg: '',
                },
                title: {
                    value: decodeData(card.title),
                    errMsg: '',
                    countErr: false,
                },
                description: {
                    value: decodeData(card.description),
                    errMsg: '',
                    countErr: false,
                },
                miniDescription: {
                    value: decodeData(card.mini_description),
                    errMsg: '',
                    countErr: false,
                },
                files: {
                    errMsg: '',
                    countErr: false,
                }
            });
        } else {
            setMonetizationState(initialMonetizationState);
            // setCurrentCard(null);
            // setShareType('anyone');
            setIsMonetizationFormActive(true);
        }
    }, [cards, selectedModel]);

    useEffect(() => {
        if (Boolean(currentCard?.gallery_items?.length)) {
            setSelectFileVisible(false);
        }
        if (currentCard?.url_hash) {
            setMarketPlacePublicLink(`https://younet.ai/m/${currentCard?.url_hash}`);
        } else {
            setMarketPlacePublicLink('');
        }
    }, [currentCard]);

    useEffect(() => {
        const currentModelSettings = models.filter(item => item.id === selectedModel?.id)[0]?.settings;
        if (Boolean(currentModelSettings) && shareType === 'monetization') {
            setSelectedModel({...selectedModel, settings: currentModelSettings});
        }
    }, [models]);

    const supportedFormats = [
        {
            formats: ['.png', '.jpg', '.jpeg'],
            title: 'Picture'
        }
    ];

    useEffect(() => {
        if (Object.values(monetizationState).some((element) => element.countErr)) {
            setTimeout(() => {
                const updatedState = {...monetizationState};
                Object.keys(updatedState).forEach((key) => {
                    updatedState[key] = {...updatedState[key], countErr: false};
                });
                setMonetizationState(updatedState);
            }, 300);
        }
    }, [monetizationState]);

    const transformText = text => {
        text = text.toLowerCase();
        text = text.replace(/[^a-zA-Z0-9\-_]/g, '').replace(/\s/g, '-');

        return text;
    }

    const onChangeHandler = (event, isDescription = false) => {
        const fieldName = isDescription ? 'description' : event.target.name;
        const value = isDescription ? event : event.target.value;
        const maxCount = countLimits[fieldName];
        if (value.length <= maxCount || !maxCount) {
            setMonetizationState({...monetizationState, [fieldName]: {errMsg: '', value, countErr: false}});
        }
        if (value.length > maxCount) {
            setMonetizationState({
                ...monetizationState,
                [fieldName]: {...monetizationState[fieldName], countErr: true}
            });
        }
    }
    const publicLink = isPublic
        ? 'https://younet.ai/' + selectedModel?.public_hash
        : '';

    const copyPublicLink = async () => {
        const linkToCopy = shareType === 'monetization' ? marketPlacePublicLink : publicLink;
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(linkToCopy);
        } else {
            document.execCommand("copy", true, linkToCopy);
        }
        return dispatch(
            interactWithUser({type: "info", message: "Copied URL!"})
        );
    };

    const hideFromPublic = async () => {
        setLoading(true);
        let status = await changePublicStatus();

        if (status.code == 200) {
            const updatedModel = {...selectedModel, settings: status.model_settings};

            setSelectedModel(updatedModel);
            dispatch(updateModel({model: updatedModel}));

            dispatch(
                interactWithUser({
                    type: "success",
                    message: "Stopped sharing",
                })
            );
        }

        setLoading(false);
    };

    const isValidEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    };

    const handleDeleteTag = (value) => {
        const newtags = tags.filter((val) => val !== value);
        setTags(newtags);
    }

    const handleKeyPress = (e) => {
        if (tagRef.current.value.trim() === '') return;

        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            const enteredEmail = tagRef.current.value.trim();
            if (enteredEmail !== '' && isValidEmail(enteredEmail)) {
                if (tags.includes(enteredEmail)) {
                    setEmailError('Duplicate email address');
                    return;
                }
                if (enteredEmail === user.email) {
                    setEmailError('Self-invitations are not permitted');
                    return;
                }
                if (invitationsList?.map(item => item.recipient_email).includes(enteredEmail)) {
                    setEmailError('One invitation per user allowed');
                    return;
                }
                setTags([...tags, enteredEmail]);
                tagRef.current.value = '';
            } else {
                setEmailError('Invalid email format');
            }
        }
    };

    const savePublic = async (publicListedOnMarketplace = 1) => {
        setLoading(true);

        let status = await changePublicStatus(1, publicListedOnMarketplace);

        if (!status.model_settings || !status.model_settings.public_hash) {
            setLoading(false);
            dispatch(interactWithUser({template: "DEFAULT_ERROR"}));
            return;
        }

        const updatedModel = {
            ...selectedModel,
            settings: status.model_settings,
            public_hash: status.model_settings.public_hash,
            qr: status.model_settings.qr
        };

        setSelectedModel(updatedModel);
        dispatch(updateModel({model: updatedModel}));

        dispatch(
            interactWithUser({
                type: "success",
                message: "Shared to public",
            })
        );

        setLoading(false);
    };

    // TODO: move to the separate helper
    const changePublicStatus = async (isPublic = 0, publicListedOnMarketplace = 0) => {
        let payload = {
            isPublic: isPublic,
            contextWindowSize: selectedModel.settings
                ? selectedModel.settings.contextWindowSizePercent
                : settings
                    ? settings.contextWindowSizePercent
                    : 80,
            gptModel: selectedModel.settings
                ? selectedModel.settings.gpt_model
                : settings
                    ? settings.gpt_model
                    : STANDART_GPT_MODEL,
            modelId: selectedModel.id,
            enableMonetization: isMonetizationEnabled ? 1 : 0,
            monetizationPrice: Number(monetizationState.price.value),
            monetizationDescription: monetizationState.description.value,
            monetizationMiniDescription: monetizationState.miniDescription.value,
            publicListedOnMarketplace: publicListedOnMarketplace,
            listedOnMarketplace: isListedOnMarketplace ? 1 : 0
        };

        return await controlledAPI({ignoreUser: true})
            .put("model/settings/update", payload)
            .then((r) => {
                if (r.code >= 400) {
                    return {};
                } else {
                    return r;
                }
            })
            .catch((error) => {
                dispatch(interactWithUser({template: "DEFAULT_ERROR"}));
                return {};
            });
    };

    const handleSendInvitation = async () => {
        const enteredEmail = tagRef.current.value.trim();
        if (!isValidEmail(enteredEmail) && !tags.length) {
            setEmailError('Invalid email format');
            return;
        }
        ;

        if (enteredEmail === user.email) {
            setEmailError('Self-invitations are not permitted');
            return;
        }

        if (invitationsList?.map(item => item.recipient_email).includes(enteredEmail)) {
            setEmailError('One invitation per user allowed');
            return;
        }

        setLoading(true);
        const recipientEmails = Boolean(enteredEmail.length) ? [...tags, enteredEmail] : tags;

        let params = {
            modelId: selectedModel.id,
            recipientEmails,
        }

        let response = await controlledAPI({ignoreUser: true}).post('model/invite/sent', params);

        if (response.code >= 400) {
            let message = response?.message || "Something went wrong";
            setLoading(false);

            dispatch(
                interactWithUser({
                    message: message ?? "Something went wrong..",
                    type: 'error',
                })
            );
            tagRef.current.value = '';
            return;
        }

        setTags([]);
        setLoading(false);
        tagRef.current.value = '';
        dispatch(addInvitations({list: response.shared_model_invites}));
    }

    const validateHash = (hash) => {
        const pattern = /[^a-zA-Z0-9\-_]/g;
        return hash.replace(pattern, '');
    }

    const generateParams = (listedOnMarketplace, deleteCard = false) => {
        return {
            isPublic: Boolean(isPublic) ? 1 : 0,
            contextWindowSize: selectedModel.settings
                ? selectedModel.settings.contextWindowSizePercent
                : settings
                    ? settings.contextWindowSizePercent
                    : 80,
            gptModel: selectedModel.settings
                ? selectedModel.settings.gpt_model
                : settings
                    ? settings.gpt_model
                    : STANDART_GPT_MODEL,
            modelId: selectedModel.id,
            enableMonetization: deleteCard ? 0 : isMonetizationEnabled ? 1 : 0,
            monetizationPrice: deleteCard ? 0 : Number(monetizationState.price.value),
            monetizationDescription: deleteCard ? '' : monetizationState.description.value,
            monetizationMiniDescription: deleteCard ? '' : monetizationState.miniDescription.value,
            listedOnMarketplace,
        }
    };

    const validateForm = () => {
        let valid = true;
        const newMonetizationState = {
            price: {...monetizationState.price, errMsg: ""},
            title: {...monetizationState.title, errMsg: ""},
            description: {...monetizationState.description, errMsg: ""},
            miniDescription: {...monetizationState.miniDescription, errMsg: ""},
            files: {...monetizationState.files, errMsg: ""}
        };

        // Validate price
        if (shareType === 'monetization' && !amountRef?.current.value.trim()) {
            newMonetizationState.price.errMsg = "Price is required";
            amountRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }
        // Validate price's amount
        if (shareType === 'monetization' && amountRef?.current.value < 1) {
            newMonetizationState.price.errMsg = "The price must exceed $1";
            amountRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        // Validate title
        if (!monetizationState.title.value.trim()) {
            newMonetizationState.title.errMsg = "Title is required";
            amountRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        // Validate description
        if (!monetizationState.description.value.trim()) {
            newMonetizationState.description.errMsg = "Description is required";
            valid = false;
        }

        // Validate short description
        if (!monetizationState.miniDescription?.value?.trim()) {
            newMonetizationState.miniDescription.errMsg = "Short description is required";
            filesRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        // Validate files length
        if (!Boolean(file?.length) && !Boolean(currentCard?.gallery_items.length)) {
            newMonetizationState.files.errMsg = "Images are required";
            filesRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        setMonetizationState(newMonetizationState);
        return valid;
    };

    const icon = () => {
        if (shareType === 'monetization') {
            return <AttachMoneyIcon/>;
        } else if (shareType === 'restricted') {
            return <HttpsOutlinedIcon/>;
        } else if (shareType === 'chat-bot') {
            return <SmartToyIcon/>
        } else if(shareType === 'customer-support') {
            return <QrCodeIcon/>
        } else {
            return <PublicIcon/>;
        }
    }

    // TODO: separate steps to components
    return (
        <>
            <DialogContent dividers>
                <Grid container rowSpacing={6}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="describe_text">
                            Your private conversations with Al model will not be shared with others.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <Chip
                                sx={{
                                    padding: '5px',
                                    ".MuiChip-avatar": {margin: 0, padding: '1px'},
                                    ".MuiChip-label": {padding: 0}
                                }}
                                avatar={icon()}
                            />
                            <Box>
                                <Select
                                    value={shareType}
                                    onChange={event => {
                                        setShareType(event.target.value);
                                    }}
                                    sx={{
                                        boxShadow: "none",
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        ".MuiOutlinedInput-notchedOutline": {border: 0, padding: 0},
                                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        ".MuiSelect-select": {
                                            padding: 0
                                        }
                                    }}
                                >
                                    <MenuItem value="anyone">Anyone with the link</MenuItem>
                                    <MenuItem value="restricted">Restricted by email</MenuItem>
                                    <MenuItem value="monetization">Monetization</MenuItem>
                                    <MenuItem value="chat-bot">Website Chatbot</MenuItem>
                                    <MenuItem value="customer-support">Speak to Product</MenuItem>
                                </Select>
                                <Typography sx={{fontSize: '12px', color: '#444746'}}>
                                    {shareType === 'restricted' && 'Only invited people will have the ability to communicate with the model'}
                                    {shareType === 'anyone' && 'Anyone on the Internet will have the ability to communicate with the model'}
                                    {shareType === 'monetization' && 'Only people with a subscription will have the ability to communicate with the model'}
                                    {shareType === 'chat-bot' && 'People on your website will be able to use this chat '}
                                    {shareType === 'customer-support' && 'People with the customer link or QR code will be able to use this chat'}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {shareType === 'chat-bot' && (<ChatBot isPublic={isPublic} hideFromPublic={hideFromPublic}/>)}
                    {shareType === 'customer-support' && (<CustomerSupport qr={selectedModel?.qr} hash={selectedModel.public_hash} isPublic={isPublic} hideFromPublic={hideFromPublic}/>)}
                    {shareType === 'monetization' && (
                        <>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '24px'}}>
                                    Manage subscription page
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} component="div">
                                <TextField
                                    label="Price/Month"
                                    variant="outlined"
                                    error={Boolean(monetizationState.price.errMsg.length)}
                                    sx={{
                                        width: '100%',
                                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                            display: 'none',
                                        },
                                        '& input[type=number]': {
                                            MozAppearance: 'textfield',
                                        },
                                    }}
                                    type="number"
                                    name="price"
                                    value={monetizationState.price.value}
                                    min={0}
                                    inputRef={amountRef}
                                    onChange={event => onChangeHandler(event)}
                                    InputProps={{
                                        min: 0,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Hint text="Specify subscription price per month in USD"/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {Boolean(monetizationState.price.errMsg.length) && (
                                    <FormHelperText error>{monetizationState.price.errMsg}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sx={{display: {sx: 'block', md: 'flex'}, gap: 6}}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FormControlLabel
                                        control={<Switch checked={isMonetizationEnabled}/>}
                                        label="Activate monetization"
                                        inputRef={switcherMonetizationRef}
                                        onChange={event => {
                                            setIsMonetizationEnabled(event.target.checked);
                                            if (!event.target.checked) {
                                                setIsListedOnMarketplace(false);
                                            }
                                        }}
                                        sx={{marginRight: '5px'}}
                                    />
                                    <Hint
                                        text="Once the subscription is activated, users will be able to subscribe and communicate with the Al model. They will have the ability to interact with it, but not to train it."/>
                                </Box>
                                {isMonetizationEnabled && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FormControlLabel
                                            control={<Switch checked={isListedOnMarketplace}/>}
                                            label="List on the Marketplace"
                                            inputRef={switcherRef}
                                            onChange={event => setIsListedOnMarketplace(event.target.checked)}
                                            sx={{marginRight: '5px'}}
                                        />
                                        {/* <Hint text="List your Al model in the Marketplace so that others can find it." /> */}
                                        <Hint text="Marketplace is coming soon."/>
                                    </Box>
                                )}
                            </Grid>
                            {currentCard?.url_hash && (
                                <>
                                    <PublicLink
                                        publicLink={marketPlacePublicLink}
                                        copyPublicLink={() => copyPublicLink(true)}
                                        hideFromPublic={hideFromPublic}
                                        hideStopCta={true}
                                        readOnly={false}
                                        onChangeHandler={(event) => {
                                            const {value} = event.target;
                                            if (value === 'https://younet.ai/m') return;
                                            setMarketPlacePublicLink(value);
                                        }}
                                    />
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Button startIcon={<AddIcon/>}
                                                    onClick={() => setIsMonetizationFormActive(!isMonetizationFormActive)}>
                                                Change model info
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                            {isMonetizationFormActive && (
                                <>
                                    <Grid item xs={12} component="div">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Subscription title"
                                            name="title"
                                            multiline
                                            helperText={
                                                <div
                                                    className={classnames(styles.symbols, monetizationState.title.countErr && styles.countError)}>
                                                    {`${monetizationState.title.value.length}/50`}
                                                </div>
                                            }
                                            error={Boolean(monetizationState.title.errMsg.length)}
                                            value={monetizationState.title.value}
                                            onChange={event => onChangeHandler(event)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Hint
                                                            text="Enter a title for your subscription that clearly reflects its purpose or content."/>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {Boolean(monetizationState.title.errMsg.length) && (
                                            <FormHelperText error>{monetizationState.title.errMsg}</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Button startIcon={<AddIcon/>}
                                                    onClick={() => setSelectFileVisible(!selectFileVisible)}>
                                                Upload images
                                            </Button>
                                            <Hint text="Add an image for the marketplace"/>
                                        </Box>
                                        {selectFileVisible && (
                                            <Box mt={4}>
                                                <Typography>Image(s)</Typography>
                                                <FileInput
                                                    ref={filesRef}
                                                    fileMaxSize={5}
                                                    supportedFormats={supportedFormats}
                                                    selectedTab={0}
                                                    isShort={false}
                                                    isArray={true}
                                                    onChange={(data) => {
                                                        setFile(data);
                                                    }}
                                                    clearFile={clearFile}
                                                />
                                            </Box>
                                        )}
                                        {Boolean(monetizationState.files.errMsg.length) && (
                                            <FormHelperText error>{monetizationState.files.errMsg}</FormHelperText>
                                        )}
                                        <ImagesList cardId={currentCard?.id} gallery={currentCard?.gallery_items}/>
                                    </Grid>
                                    <Grid item xs={12} component="div">
                                        <TextField
                                            label="Short description"
                                            variant="outlined"
                                            error={Boolean(monetizationState.miniDescription.errMsg.length)}
                                            sx={{
                                                width: '100%',
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                    display: 'none',
                                                },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield',
                                                },
                                            }}
                                            helperText={
                                                <div
                                                    className={classnames(styles.symbols, monetizationState.miniDescription.countErr && styles.countError)}>
                                                    {`${monetizationState.miniDescription.value?.length}/150`}
                                                </div>
                                            }
                                            type="text"
                                            name="miniDescription"
                                            value={monetizationState.miniDescription.value}
                                            onChange={event => onChangeHandler(event)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Hint
                                                            text="Write a short description for your subscription that reflects its purpose or content."/>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {Boolean(monetizationState.miniDescription.errMsg.length) && (
                                            <FormHelperText
                                                error>{monetizationState.miniDescription.errMsg}</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} component="div">
                                        <CustomMdEditor
                                            isToolbarVisible={true}
                                            value={monetizationState.description.value}
                                            onChange={value => onChangeHandler(value, true)}
                                            placeholder="Description"
                                            // resetSignal={resetTextValueSignal}
                                            // setResetSignal={setResetTextValueSignal}
                                            customHeight="150px"
                                        />
                                        {/* <TextField
                      fullWidth
                      variant="outlined"
                      name="description"
                      label="Description"
                      multiline
                      rows={4}
                      helperText={
                        <div className={classnames(styles.symbols, monetizationState.description.countErr && styles.countError)}>
                          {`${monetizationState.description.value?.length}/1000`}
                        </div>
                      }
                      error={Boolean(monetizationState.description.errMsg.length)}
                      value={monetizationState.description.value}
                      onChange={event => onChangeHandler(event)}
                      inputRef={descriptionRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ position: 'absolute', top: '18px', right: '10px' }}>
                            <Hint text="Create a description for your model." />
                          </InputAdornment>
                        )
                      }}
                    /> */}
                                        {Boolean(monetizationState.description.errMsg.length) && (
                                            <FormHelperText
                                                error>{monetizationState.description.errMsg}</FormHelperText>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {shareType === 'restricted' && (
                        <>
                            <Grid item xs={12} sm={12} md={12} mb={2}>
                                {Boolean(tags.length) && !listLoading && (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap'}} mb={4}>
                                        {tags.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={tag}
                                                onDelete={() => handleDeleteTag(tag)}
                                                sx={{margin: '0.2rem'}}
                                            />
                                        ))}
                                    </Box>
                                )}
                                <Box sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        error={Boolean(emailError)}
                                        variant="outlined"
                                        label="Type email and press Enter"
                                        margin="none"
                                        placeholder='Email Address'
                                        inputRef={tagRef}
                                        onChange={() => {
                                            setEmailError(false);
                                        }}
                                        onKeyDown={handleKeyPress}
                                    />
                                    {emailError && (
                                        <div
                                            className={classnames(styles.error, emailError && styles.errorShowed)}>{emailError}</div>
                                    )}
                                </Box>
                            </Grid>
                            <Grid sx={{width: '100%'}}>
                                {listLoading && (
                                    <Skeleton animation="wave"/>
                                )}
                                {!listLoading && isRestrictionType && Boolean(invitationsList.length) && (
                                    <Box mb={2}>
                                        <Typography>People with access</Typography>
                                        <Box sx={{maxHeight: '144px', overflow: 'hidden', overflowY: 'scroll'}}>
                                            <Table aria-label="collapsible table" fixedheader="true" fixedfooter="true"
                                                   px={2} pb={4}>
                                                <TableBody px={2}>
                                                    {Boolean(invitationsList.length) && !listLoading && (
                                                        <>
                                                            {invitationsList.map((row => (
                                                                <Row key={row.id} row={row}/>
                                                            )))}
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                )}
                            </Grid>
                        </>
                    )}
                    {isPublic && shareType === 'anyone' && (
                      <>
                        <PublicLink
                          publicLink={publicLink}
                          copyPublicLink={() => copyPublicLink()}
                          hideFromPublic={hideFromPublic}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          mt={6}
                        >
                          <FormControlLabel
                            control={<Switch defaultChecked={Boolean(selectedModel?.settings?.public_listed_on_marketplace)} />}
                            label="List on the Marketplace"
                            onChange={event => {
                              setIsPublicListedOnMarketplace(event.target.checked);
                              const isChecked = event.target.checked ? 1 : 0;
                              savePublic(isChecked);
                            }}
                            sx={{marginRight: '5px'}}
                          />
                          <Hint text="Marketplace is coming soon."/>
                        </Box>
                      </>
                    )}
                </Grid>
            </DialogContent>
            
            <DialogActions>
                <Box sx={{display: 'flex', padding: '0 10px', justifyContent: 'space-between', width: '100%'}}>
                    {shareType === 'restricted' && (
                        // <Box sx={{ display: 'flex', gap: '10px' }}>
                        <LoadingButton
                          loading={loading}
                          loadingIndicator="Loading…"
                          variant="contained"
                          onClick={handleSendInvitation}
                          size="small"
                          startIcon={<SendIcon/>}
                        >
                          Send Invitation
                        </LoadingButton>
                        // </Box>
                    )}

                    {shareType === 'monetization' && (
                        <Box sx={{display: 'flex', gap: 2}}>
                            <LoadingButton
                                loading={loading}
                                loadingIndicator="Loading…"
                                variant="contained"
                                onClick={async () => {
                                    const params = generateParams(isListedOnMarketplace ? 1 : 0);

                                    const validation = validateForm();
                                    if (!validation) {
                                        return;
                                    }

                                    if (isMonetizationEnabled !== Boolean(selectedModel?.settings?.enable_monetization)
                                        || isListedOnMarketplace !== Boolean(selectedModel?.settings?.listed_on_marketplace)
                                        || Number(params.monetizationPrice) !== Number(selectedModel?.settings?.monetization_price)
                                    ) {
                                        dispatch(updateModelSettings(params)).then((res) => {
                                            dispatch(getAllCards());
                                        });
                                    }

                                    // Update a current card
                                    if (Boolean(currentCard?.id) && (monetizationState?.description.value !== currentCard?.description || monetizationState?.title.value !== currentCard?.title || monetizationState?.miniDescription.value !== currentCard?.miniDescription || Boolean(file))) {
                                        const linkToArr = marketPlacePublicLink.split('/');
                                        const isSameHash = linkToArr[linkToArr.length - 1] === currentCard?.url_hash;
                                        if (isSameHash) {
                                            dispatch(updateCard({
                                                id: currentCard.id, params: {
                                                    title: encodeData(monetizationState.title.value),
                                                    description: encodeData(monetizationState.description.value),
                                                    miniDescription: encodeData(monetizationState.miniDescription.value),
                                                }
                                            }));
                                        } else {
                                            // updateCardWithHash
                                            dispatch(updateCardWithHash({
                                                id: currentCard.id, params: {
                                                    title: encodeData(monetizationState.title.value),
                                                    description: encodeData(monetizationState.description.value),
                                                    miniDescription: encodeData(monetizationState.miniDescription.value),
                                                    urlHash: currentHash,
                                                }
                                            })).then(res => {
                                                if (res?.type === 'marketplace/updateCardWithHash/rejected' && Boolean(res?.payload?.message)) {
                                                    dispatch(interactWithUser({
                                                        message: res?.payload?.message,
                                                        type: 'error',
                                                    }));
                                                }

                                            });
                                        }
                                    }

                                    // Create a new card
                                    if (!Boolean(currentCard)) {
                                        dispatch(
                                            addNewCard(
                                                {
                                                    modelId: selectedModel.id,
                                                    title: encodeData(monetizationState.title.value),
                                                    description: encodeData(monetizationState.description.value),
                                                    miniDescription: encodeData(monetizationState.miniDescription.value),
                                                    urlHash: transformText(selectedModel.name),
                                                })
                                        );
                                    }
                                }}
                                size="small"
                                startIcon={<SaveIcon/>}
                                disabled={
                                    updateCardLoading
                                    || updateModelSettingsLoading
                                    || (isMonetizationEnabled === Boolean(selectedModel?.settings?.enable_monetization)
                                        && isListedOnMarketplace === Boolean(selectedModel?.settings?.listed_on_marketplace)
                                        && Number(monetizationState.price.value) === Number(selectedModel?.settings?.monetization_price)
                                        && monetizationState?.description.value === currentCardDescription
                                        && monetizationState?.miniDescription.value === currentCardMiniDescription
                                        && monetizationState?.title.value === currentCardTitle)
                                    && currentHash === currentCard?.url_hash
                                    && !Boolean(file)
                                }
                            >
                                Save
                            </LoadingButton>
                            {Boolean(currentCard?.id) && (
                                <LoadingButton
                                    loading={loading}
                                    loadingIndicator="Loading…"
                                    variant="contained"
                                    onClick={() => {
                                        const params = generateParams(0, true, 0);
                                        dispatch(deleteCard({id: currentCard.id})).then(() => {
                                            dispatch(updateModelSettings(params)).then(() => {
                                                dispatch(getAllCards());
                                            });
                                            setIsMonetizationFormActive(true);
                                            setMonetizationState(initialMonetizationState);
                                            setIsMonetizationEnabled(false);
                                            setIsListedOnMarketplace(false);
                                            // setCurrentCard(null);
                                            setFile(null);
                                        });
                                    }}
                                    size="small"
                                    color="error"
                                    startIcon={<DeleteIcon/>}
                                    disabled={deleteLoading}
                                >
                                    Delete Page
                                </LoadingButton>
                            )}
                        </Box>
                    )}

                    {(!isPublic && (shareType === 'anyone' || (shareType === 'chat-bot' && websiteStatus.success))) && (
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="Loading…"
                            variant="contained"
                            onClick={() => savePublic(1)}
                            size="small"
                            startIcon={<LinkIcon/>}
                        >
                            Share
                        </LoadingButton>
                    )}

                    {(!isPublic && (shareType === 'customer-support')) && (
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="Loading…"
                            variant="contained"
                            onClick={() => savePublic(1)}
                            size="small"
                            startIcon={<LinkIcon/>}
                        >
                            Generate QR Code
                        </LoadingButton>
                    )}

                    {isPublic && shareType === 'chat-bot' && (
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="…"
                            variant="contained"
                            onClick={hideFromPublic}
                            size="small"
                            sx={{backgroundColor: 'red'}}
                        >
                            STOP SHARING
                        </LoadingButton>
                    )}

                    {isPublic && shareType === 'customer-support' && (
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="…"
                            variant="contained"
                            onClick={hideFromPublic}
                            size="small"
                            sx={{backgroundColor: 'red'}}
                        >
                            STOP SHARING
                        </LoadingButton>
                    )}

                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </Box>
            </DialogActions>
        </>
    );
};

export default ShareModelConfig;