import { Button } from "@mui/base";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import api from "_services/api";
import FacebookIcon from '@mui/icons-material/Facebook';

const FacebookAuthBtnExternal = ({
  redirectUrl,
  text = "",
  manual,
  fullWidth = false,
  facebookOptions = {},
}) => {
  const [url, setUrl] = useState();

  const Btn = styled.a`
    background-color: #3b5998;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    padding: 8px 10px !important;

    & > svg {
      margin-right: 5px;

    }
  `;

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        redirectUrl: redirectUrl,
      };

      try {

        let response = await api.post('facebook/oauth2/url', params);
        if (response.code >= 400) {
          throw Error("Something went wrong..");
        }

        let authUrl = response.authUrl || "";

        if(manual == 'facebook') {
         window.location.href = authUrl
        }

        setUrl(authUrl);
      } catch (error) {
        console.error(error); // Handle any errors
      }
    };

    if(window.location.origin == 'https://younet.ai') { 
      fetchData();
    } 

  }, []);
  const handleClick = () => {
    window?.parent?.postMessage({isAuthentificated: true, redirectUrl: 'https://younet.ai/portal/external/login/facebook'}, 'https://younet.ai');
  }

  return (
    <Btn
      variant="contained"
      color="primary"
      onClick={() => window.location.href == 'https://younet.ai/portal/external/login' ? handleClick() : window.location.href = url }
    >
      <FacebookIcon /> {text}
    </Btn>
  );
};

export default FacebookAuthBtnExternal;
