import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, ListItemButton, ListItemIcon, Avatar, List, Divider } from "@mui/material";

const ListItem = ({ item, widesidebar, onSelect, leftBarMenu, setModelsMenuOpen = false, changeUrl = false }) => {
  const navigate = useNavigate();

  const convertToKPlus = (number) => {
    if (number >= 1000) {
        // Convert the number to thousands
        let thousands = Math.floor(number / 1000);
        return `${thousands}K+`;
    }
    return number.toString();
}

  if (!Boolean(item)) {
    return null;
  }

  return (
    <List component="div" disablePadding>
      <ListItemButton
        sx={{ pl: 0, pr: 0, display: 'flex' }}
        onClick={() => {
          if (changeUrl) {
            if (Boolean(item.marketplace_card?.url_hash)) {
              window.history.pushState({}, null, `/m/${item.marketplace_card?.url_hash}`);
            } else if (Boolean(item.public_hash)) {
              navigate(`/${item.public_hash}`, { replace: true });
            }
          }
          onSelect(item);
          if (!leftBarMenu && setModelsMenuOpen) {
            setModelsMenuOpen(false);
          }
        }}
      >
        <ListItemIcon sx={{ minWidth: "40px", position: 'relative', ml: 6 }}>
          <Avatar src={item.avatar ?? ''} sx={{ width: '30px',height: '30px' }}>{item.name?.substring(0, 1)}</Avatar>
          {Boolean(item.marketplace_card?.price) && (
            <Typography sx={{ fontSize: '0.675rem', position: 'absolute', left: '18px', top: '-6px', padding: '0 5px', background: '#fff', borderRadius: '4px', border: '1px solid rgba(0,0,0, .1)' }}>$</Typography>
          )}
        </ListItemIcon>
        {widesidebar && (
          <Box width="100%">
            <Box sx={{ position: 'relative', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 500, fontSize: '0.875rem', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '65%', lineHeight: '1.2' }}>{item.name}</Typography>
              {Boolean(item.chat_count) && (
                <Typography sx={{ fontSize: '0.75rem', color: '#5c5c5c', whiteSpace: 'nowrap', position: 'absolute', top: '3px', right: '24px' }}><QuestionAnswerOutlinedIcon sx={{ position: 'relative', top: '2px', fontSize: '0.75rem' }} />&nbsp;{convertToKPlus(item.chat_count)}</Typography>
              )}
            </Box>
            <Typography sx={{ fontSize: {xs: '0.75rem', md: '0.75rem'}, color: '#b2b2b2', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>By: {item.user?.name}</Typography>
          </Box>
        )}
      </ListItemButton>
      <Divider sx={{ color: '1px solid rgba(0,0,0, .1)', borderWidth: '100%', height: '1px', margin: '0 24px' }} />
    </List>
  )
}

export default ListItem;
