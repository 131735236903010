import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {sendExtensionAction} from '_services/extensionApi';
import {sendPixelFB, shouldSendFBPixel} from 'events/analytics/facebook';
import {sendPixelGenai, shouldSendGenaiPixel} from 'events/analytics/genai';
import {sendPixelShareSale, shouldSendShareSalePixel} from 'events/analytics/shareSale';
import {sendPixelGoogle, shouldSendGooglePixel} from "events/analytics/google";
import {sendPixelE4D, shouldSendE4DPixel} from "../../../../../events/analytics/engine4display";


const UserTracking = () => {
    const userId = useSelector(state => state?.auth?.user?.id);
    const token = useSelector(state => state?.auth?.token);
    const jsonData = useSelector((state) => state?.auth?.user?.jsonData);
    // const isGenai = localStorage.getItem('genai');
    const authCount = useSelector(state => state?.auth?.authCount);
    const firstLogin = authCount === 1;


    useEffect(() => {

        if (userId && token && process.env.REACT_APP_ANALYTICS_ENABLED === 'true') {
            sendExtensionAction({action: 'giveToken', data: token})
            if (firstLogin) {
                if (shouldSendShareSalePixel()) {
                    const triggerSend = async () => {
                        await sendPixelShareSale(userId);
                    };
                    triggerSend();
                }

                if (shouldSendFBPixel()) {
                    const triggerSend = async () => {
                        await sendPixelFB({
                            event: 'CompleteRegistration',
                            content_name: 'Socials Registration',
                            once: true
                        });
                    };
                    triggerSend();
                }

                if (shouldSendE4DPixel()) {
                    const triggerSendE4D = async () => {
                        await sendPixelE4D({event: 'CompleteRegistration', once: true});
                    };
                    triggerSendE4D();
                }


                if (shouldSendGooglePixel()) {
                    const triggerSend = async () => {
                        await sendPixelGoogle({event: 'conversion', once: true});
                    };

                    triggerSend();
                }

                // if ((shouldSendGenaiPixel() && Boolean(jsonData?.genai)) || (shouldSendGenaiPixel() && Boolean(isGenai))) {
                //   const triggerSend = async () => {
                //       await sendPixelGenai({
                //         once: true
                //       });
                //   };
                //   triggerSend();
                // }

            }




        }
        return () => {
            if (userId) {
                sendExtensionAction({action: 'logout'});
            }
        }

    }, [userId]);

    return <></>;
};

export default UserTracking;