import {memo, useEffect, useState} from "react";
import {Box, IconButton} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import {useOutletContext} from "react-router-dom";


const minPagesNum = 1;
const maxPagesNum = 100;

const StyledPagesInput = styled(TextField)(({theme}) => ({
    '& .MuiInputBase-root': {
        width: '30px',
        height: '32px',

        // color: '#7b8191',
        // padding: '2px',
        // backgroundColor: 'red',
        '& .MuiInputBase-input': {
            padding: '0px',
            textAlign: 'center',
        }

    },

    // '& .MuiFilledInput-input': {
    //     padding: '3px',
    //
    //     '& .MuiInputBase-input': {
    //         padding: '3px'
    //     }
    // }

}));

const Counter = ({
                     onChange = () => {
                     }
                 }) => {

    const [count, setCount] = useState(10);
    const handleOnChange = (e, manual = undefined) => {
        e.preventDefault();

        let value = parseInt(manual || e.target.value, 10);
        if (!Number.isInteger(value)) {
            value = 0
        }

        if (value > maxPagesNum) value = maxPagesNum;
        // if (value < minPagesNum) value = minPagesNum;
        setCount(value);
    }

    const onMinusClick = (e) => {
        e.preventDefault();

        setCount(prev => {
            const newValue = prev - 1;
            if (newValue < minPagesNum) {
                return minPagesNum;
            }
            return newValue;
        })

    }

    const onPlusClick = (e) => {
        e.preventDefault();

        setCount(prev => {
            const newValue = prev + 1;
            if (newValue >= maxPagesNum) {
                return maxPagesNum;
            }
            return newValue;
        })
    }

    useEffect(() => {
        // countRef.current = count;
        onChange({count: count})
    }, [count])

    return (
        <Box display="flex" sx={{borderRadius: '12px', height: '26px', margin: '0 5px'}}>
            <IconButton size="small" onClick={onMinusClick}><RemoveIcon fontSize="small"/></IconButton>
            <StyledPagesInput
                size="small"
                value={count}
                InputProps={{inputProps: {min: 1, max: 3}}}
                variant="standard"
                onChange={handleOnChange}
            />
            <IconButton size="small" onClick={onPlusClick}><AddIcon fontSize="small"/></IconButton>
        </Box>
    )
}

export default memo(Counter);