import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from "_services/api";
import { deleteChat } from 'redux/chats';

const useDeleteChat = () => {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteChat = (chatId, finallyCallback = false) => {
    setIsDeleteLoading(true);
    api
      .delete(`/chat/${chatId}`)
      .then(() => {
        dispatch(deleteChat(chatId));
        setIsDeleteLoading(false);
        // if (selectedChat === chatId) {
        //   navigate('/playground');
        // }
      })
      .catch((error) => {
        setIsDeleteLoading(false);
      })
      .finally(() => {
        if (finallyCallback) {
          finallyCallback();
        }
      });
  };

  return { handleDeleteChat, isDeleteLoading };
};

export default useDeleteChat;
