import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import {
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  Avatar,
  Divider
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import api from "_services/api";
import { interactWithUser } from "redux/status";
import { useDispatch } from "react-redux";
import { changeInvitationStatus } from "redux/models";
import TopAppBar from "components/TopAppBar";
import { getToken } from "_helpers/tokenManagement";
import LoginForm from 'pages/Login/components/LoginForm';

const SharedModel = ({ action }) => {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const params = useParams();
  const hash = params.hash || '';
  const userToken = getToken('token');
  const userData = getToken('userData');
  const navigate = useNavigate();
  const parsedUserData = userData ? JSON.parse(userData) : false;
  const dispatch = useDispatch();

  useEffect(() => {
    const handleInvitation = async () => {
      setLoading(true);
      await api
        .post('model/invite/action', { action, hash })
        .then((r) => {
          if (r.code >= 400) {
            dispatch(
              interactWithUser({
                message: 'We were not able to process the invitation',
                type: "error",
              })
            );
            setLoading(false);
            return;
          }
          dispatch(
            interactWithUser({
              message: action === 'accept' ? 'Invitation was successfully accepted' : 'Invitation was rejected',
            })
          );
          dispatch(changeInvitationStatus({ hash, status: action }));
          setIsSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
      };

      handleInvitation();
  }, []);

  const getInitials = (str, num = 2) => {
    const words = str.split(" ");

    const firstLetters = words
      .slice(0, num) 
      .map((word) => word.charAt(0))
      .join(""); 
  
    return firstLetters;
  };

  const onLogOutHandler = () => {
    logout();
    navigate('/login');
  }

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('cachedModel');
    localStorage.removeItem('guestId');
    localStorage.removeItem('activePage');
    localStorage.removeItem('guestId');
    localStorage.removeItem('userData');
  }

  return (
    <>
      <TopAppBar/>
      <Paper
        variant="outlined"
        sx={{
          my: { xs: 3, md: 6 },
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '600px',
          minHeight: '200px',
          margin: '150px auto',
          padding: '30px 0 30px',
        }}
      >
        {loading && <CircularProgress color="inherit" sx={{ margin: 'auto' }} />} 
        {!loading && isSuccess && (
          <>
            <Box sx={{ textAlign: 'center' }} mb={2}>
              {action === 'accept' ? (
                <>
                  <CheckCircleOutlineIcon sx={{ fontSize: 80, color: 'green' }} />
                  <Typography>{`Invitation was successfully accepted`}</Typography>
                </>
              ) : (
                <>
                  <CancelIcon sx={{ fontSize: 80, color: 'green' }} />
                  <Typography>The invitation has been rejected</Typography>
                </>
              )}
            </Box>
            <Divider sx={{ margin: '20px 0' }} />
          </>
        )}
        {!loading && !userToken && <LoginForm redirectAllowed={false} />}
        {!loading && userToken && (
          <Box
            component="div"
            noValidate
            sx={{ maxWidth: '500px', width: '100%', margin: '0 auto', padding: '0 10px' }}
          >
            <Grid sx={{ mb: 0, padding: 0 }} item xs={12}>
              <Grid container sx={{ mb: 3, gap: '14px', pl: '5px' }}>
                <Grid item sx={{ display: 'flex' }}>
                  <Avatar src={parsedUserData?.avatar ? parsedUserData.avatar : ''} sx={{ fontSize: 15, border: '1px solid #00000011' }}>{parsedUserData?.fullName && getInitials(parsedUserData.fullName)}</Avatar>
                </Grid>
                <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography sx={{ width: '100%', fontWeight: "bold" }} variant="div">
                    {parsedUserData?.fullName}
                  </Typography>
                  <Typography sx={{ width: '100%', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} variant="span">
                    {parsedUserData?.email}
                  </Typography>
                </Grid>
              </Grid>
              <Button href="/portal/" sx={{ mb: 3 }} variant="contained" fullWidth>
                Go to My Account
              </Button>
              <Button variant="outlined" startIcon={<LogoutIcon />} className="auth-cta" onClick={onLogOutHandler} fullWidth>
                Log Out
              </Button>
            </Grid>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default SharedModel;
