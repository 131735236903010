import React, {useState, useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    Box,
    Tooltip,
    Drawer,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import classnames from 'classnames';
import useViewportHeight from "hooks/useViewportHeight";
import {getList} from "redux/invitations";
import {setPermissionsAndLimitations} from "redux/permissions";
import ListMenu from "./components/ListMenu";
import SimpleListMenu from "./components/SimpleListMenu";
import ShareModelPopup from "./components/ShareModelPopup";
import ExtensionCta from './components/ExtensionCta';
import checkProgressIsCompleted from '_helpers/checkProgressIsCompleted'
import SharedModels from 'components/menus/SharedModels';
import styles from "./parent-menu.module.css";
import {getBrainList} from "redux/tools";
import LocalChatBotHelper from "components/userInteractions/LocalChatBotHelper";
import styled from "@emotion/styled";


const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiPaper-root': {
        overflow: 'visible',
    },
}));

const apps = [
    // { link: "/app/mail", exp_text: "Your Mail App", icon: <MailOutlineIcon /> },
    {
        link: "/app/add",
        exp_text: "Add App",
        icon: (
            <Tooltip title=" APP (Coming soon)" placement="right">
                <AddCircleOutlineIcon/>
            </Tooltip>
        ),
    },
];

function ParentMenu({
                        mobilebar,
                        mobilebarChange,
                        widesidebar,
                        widesidebarChange,
                        smallScreen,
                    }) {
    const RABBIT_AI_MODEL_ID = 78;

    // const progressData = localStorage.getItem('progress');
    // const progressParsedData = JSON.parse(progressData) || null;
    const viewportHeight = useViewportHeight();

    const dispatch = useDispatch();
    const {models, user} = useSelector((state) => ({
        models: state.models,
        user: state.auth?.user,
    }), shallowEqual);

    // We use localstorage here in cate when we used emails tool (redirects)
    const [shareModelConfig, setShareModelConfig] = useState({
        // isExpanded: Boolean(localStorage.getItem('newModelFlow')),
        isExpanded: false,
        model: models.current,
        // shareType: localStorage.getItem('newModelFlow') ?? 'public',
        shareType: 'public',
    });

    const userId = user.id;

    useEffect(() => {
        if (smallScreen) {
            widesidebarChange(false);
        }

    }, [widesidebar, smallScreen, widesidebarChange]);

    useEffect(() => {
        if (models.current) {
            dispatch(setPermissionsAndLimitations({
                permissions: models.current.access,
                limitations: user.tariff_plan.limitation
            }))
            if (models.current.id) {
                dispatch(getBrainList({model_id: models.current.id}));
                dispatch(getList(models.current.id));

                setShareModelConfig(prev => ({...prev, model: models.current}));
            }
        }
    }, [models.current])

    if (models.loading || (!Boolean(models.selectedModel.data) && models.list.length === 0)) {
        return
            // <Skeleton variant="h2" />
            ;
    }
    const containerStyle = {
        height: `calc(${viewportHeight}px - 64px)`,
    };

    const mobilebarHandler = () => {
        mobilebarChange(false)
    }

    return (
        <>
            {smallScreen ? (
                <StyledDrawer
                    anchor='left'
                    open={mobilebar}
                    onClose={mobilebarHandler}
                >
                    <Box className={styles.menuBlock} pt={3} sx={{width: '260px'}}>
                        <ListMenu userId={userId} mobilebarChange={mobilebarHandler}
                            // rabbitId={RABBIT_AI_MODEL_ID}
                                  smallScreen={smallScreen} widesidebar={widesidebar}
                                  widesidebarChange={widesidebarChange}
                                  setShareModelConfig={setShareModelConfig}/>
                        {/* {!checkProgressIsCompleted(progressParsedData) && <ProgressBar widesidebar={true}  userId={userId} setShareModelConfig={setShareModelConfig} shareModelConfig={shareModelConfig} />} */}
                        {smallScreen ? <LocalChatBotHelper/> : <ExtensionCta widesidebar/>}
                    </Box>
                </StyledDrawer>
            ) : (
                <Box
                    className={classnames(styles.menu, styles.menuBlock)}
                    style={containerStyle}
                    sx={{
                        width: widesidebar ? "100%" : "72px",
                    }}
                >

                    <Box mb={2} sx={{width: '100%', height: '80%', overflow: 'hidden', overflowY: 'scroll'}}
                         className={styles.menuScroll}>
                        {
                            widesidebar ? (
                                <ListMenu
                                    userId={userId}
                                    smallScreen={smallScreen}
                                    widesidebar={widesidebar}
                                    widesidebarChange={widesidebarChange}
                                    setShareModelConfig={setShareModelConfig}
                                />
                            ) : (
                                <SimpleListMenu
                                    userId={userId}
                                    smallScreen={smallScreen}
                                    widesidebar={widesidebar}
                                    widesidebarChange={widesidebarChange}
                                    setShareModelConfig={setShareModelConfig}
                                />
                            )
                        }
                        {/* {!checkProgressIsCompleted(progressParsedData) && <ProgressBar userId={userId} widesidebar={widesidebar} setShareModelConfig={setShareModelConfig} shareModelConfig={shareModelConfig} />} */}
                    </Box>

                    {smallScreen ? <LocalChatBotHelper/> : <ExtensionCta widesidebar={widesidebar}/>}
                </Box>)}

            {shareModelConfig.isExpanded && shareModelConfig.model && (
                <ShareModelPopup
                    data={shareModelConfig}
                    onClose={() => {
                        setShareModelConfig({...shareModelConfig, isExpanded: false});
                    }}
                    fullScreen={smallScreen}
                    preselectedFlow={shareModelConfig.shareType}
                />
            )}
        </>
    );
}

export default ParentMenu;
