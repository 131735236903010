import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import styles from '../invoices-table.module.css';
import StripeStatus from 'components/showcase/StripeStatus';
import formatTime from 'utils/formatTime';


const InvoiceItem = ({ item }) => {
  const { id, status, amount, invoice_date } = item;
  const formattedDate = formatTime({ date: invoice_date.date });
  const orderId = id;

  return (
    <Box className={styles.line} key={`history-item-${orderId}`}>
      <div className={styles.textOverflow}>{formattedDate}</div>
      <div className={styles.textOverflow}>{orderId}</div>
      <StripeStatus status={status} />
      <div>{`$${amount}`}</div>
      {/* <div className={styles.actions}> */}
        {/* <IconButton variant="text"
          //  href={hosted_invoice_url} 
          target="_blank">
          <RemoveRedEyeIcon />
        </IconButton>
        <IconButton variant="text"
        // href={invoice_pdf}
        >
          <DownloadIcon />
        </IconButton> */}
      {/* </div> */}
    </Box>
  );
};

export default InvoiceItem;