import { Skeleton, Typography, Grid, Box } from "@mui/material";

const StripePaymentSkeleton = () => {


    return (
        <Grid container

            spacing={12}
            padding={0}
        >
            <Grid item xs={12} md={12}>
                <Typography variant="page_sub_title_bold">
                    Payment Info
                </Typography>
                <Grid container item sx={{ padding: "16px 5px" }} spacing={2} xs={12} sm={12} md={12}>
                    <Grid item xs={4} sm={4} md={4}>
                        <Skeleton variant="text" height={100} animation="wave" />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <Skeleton variant="text" height={100} animation="wave" />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <Skeleton variant="text" height={100} animation="wave" />
                    </Grid>


                    <Grid item xs={12} sm={12} md={6}>
                        <Skeleton variant="text" height={15} width={100} animation="wave" />
                        <Skeleton variant="text" height={45} animation="wave" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Skeleton variant="text" height={15} width={100} animation="wave" />
                        <Skeleton variant="text" height={45} animation="wave" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Skeleton variant="text" height={15} width={100} animation="wave" />
                        <Skeleton variant="text" height={45} animation="wave" />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                <Typography variant="page_sub_title_bold">
                    Billing Address
                </Typography>
                <Grid container item sx={{ padding: "16px 5px" }} spacing={4}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Skeleton variant="text" height={15} width={60} animation="wave" />
                        <Skeleton variant="text" height={45} animation="wave" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Skeleton variant="text" height={15} width={100} animation="wave" />
                        <Skeleton variant="text" height={45} animation="wave" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Skeleton variant="text" height={15} width={100} animation="wave" />
                        <Skeleton variant="text" height={45} animation="wave" />
                    </Grid>
                </Grid>
            </Grid>


            </Grid>
        </Grid>
    )
}

export default StripePaymentSkeleton;