import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import api from "_services/api";
import stateData from './verify.json';
import {getToken, removeToken, setToken} from "_helpers/tokenManagement";
import {REDIRECT_AUTH_DATA_PREFIX} from "../../../../../constants";
import {useStatusActions} from "_actions/statusActions";


const ParamsChecker = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const state = searchParams.get("state");
    const code = searchParams.get("code");
    const authUser = searchParams.get("authuser");
    const {dispatchStatus} = useStatusActions();

    useEffect(() => {

        const sendData = async () => {

            const saveLabel = `${REDIRECT_AUTH_DATA_PREFIX}${state}`
            try {

                const data = stateData[state] || stateData['default'];

                const cachedStateData = getToken(saveLabel, 'json');


                // if (!data || !cachedStateData) {
                //     navigate(location.pathname);
                //     return;
                // }

                let params =
                    {
                        state: state,
                        code: code,
                        modelId: cachedStateData.modelId
                    }

                let response = await api.post(data.url, params);
                if (response.code >= 400) {
                    throw Error("Something went wrong..");
                } else if (response.code === 200) {
                    dispatchStatus({type: 'success', code: 'tool_verify'})
                }


                if (cachedStateData) {
                    removeToken(saveLabel);

                    if (cachedStateData.status === 'pending' && cachedStateData.timeStamp < cachedStateData.expData && cachedStateData.redirectUrl) {
                        navigate(cachedStateData.redirectUrl);
                        return
                    }

                }

                navigate(location.pathname);

            } catch (error) {
                console.error(error);
                removeToken(saveLabel);
                dispatchStatus({type: 'error', code: 'tool_verify'})
            }


        }

        if (state && code && !authUser) {
            sendData();
        }


    }, [state, code]);

    return (
        <></>
    )
}

export default ParamsChecker;