import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "_services/api/index.js";

export const getListThunk = createAsyncThunk(
  "chats/list",
  async (payload, thunkAPI) => {
    try {
      const response = await api.get("/chat/list", { params: payload });

      let chats = response?.chats || [];
      let shared = response?.shared || [];
      const limit = payload.limit;

      return { chats, shared, limit };
    } catch (e) {
      let message =
        e?.response?.message || e?.message || "Something went wrong";
      return { message: message };
    }
  }
);


export const getSharedChatList = createAsyncThunk(
  "chats/sharedList",
  async (payload, thunkAPI) => {
    try {
      const response = await api.get(`/model/share/chat/model/${payload.modelId}`);
      const chats = response?.chats || [];

      return { chats: chats };
    } catch (e) {
      let message =
        e?.response?.message || e?.message || "Something went wrong";
      return { message: message };
    }
  }
);

export const addChat = createAsyncThunk(
  "chats/addChat",
  async (payload, thunkAPI) => {
    try {
      const response = await api.post("/chat", payload);
      let { code, chat } = response || {};

      if (code >= 400 || !chat) {
        throw new Error(response);
      }

      return { chat: chat };
    } catch (e) {
      let message =
        e?.response?.message || e?.message || "Something went wrong";
      // console.log(message)
      return { message: message };
    }
  }
);
