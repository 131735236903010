import { useEffect, useState, useRef } from "react";
import { Box, Button, Skeleton } from "@mui/material";
import TopContent from './TopContent';
import GalleryContent from './GalleryContent';
import OverviewContent from './OverviewContent';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from "react-router-dom";
import controlledAPI from "_services/controlledApi";
import { useTheme } from '@mui/material/styles';
import "slick-carousel/slick/slick.css";
import useGetMarketplaceProductCard from 'hooks/useGetMarketplaceProductCard';
import styles from '../shareModelMarketplace.module.css';

const Content = ({ modelHash = null, setModelinitials = false, offsetLeft = 0, isSticky = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const topContentRef = useRef(null);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [tryLoader, setTryLoader] = useState(false);
  const auth = useSelector((state) => state.auth);
  const params = useParams();
  const hash = modelHash || params.modelHash;

  const { handleGetMarketplaceProductCard, dataLoading, data, marketplaceData, showMoreEnabled } = useGetMarketplaceProductCard();

  useEffect(() => {
    handleGetMarketplaceProductCard(hash, () => {
      if (!Boolean(setModelinitials)) {
        window.location.href = '/portal';
      }
    }, (data) => {
      if (Boolean(setModelinitials)) {
        setModelinitials({ avatar: data.model?.avatar, name: data.title });
      }
    })
  }, [modelHash]);

  const setSubscriptionData = async (data, successCallback) => {
    // setUpdateLoading(true);
    await controlledAPI({ ignoreUser: true })
      .put(`users/${auth.user.id}`, {jsonData: { ...auth.user.jsonData, monetizationSubscription: data }})
      .then((res) => {
        setUpdateLoading(false);
        if (res.code >= 400) {
          return;
        }
        successCallback();
        // setMarketplaceData(res.data);
      })
      .catch(() => {
        setUpdateLoading(false);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  }

  const handleTryFree = async () => {
    if (Boolean(auth?.user?.id)) {
      setTryLoader(true);
      controlledAPI({ ignoreUser: true })
        .get(`model/preview/${data.model_id}`)
        .then((res) => {
          if (res.code === 200) {
            setSubscriptionData({ ...data, isTrial: 1 }, () => {
              window.location.href = '/portal';
            });
          }     
        }).finally(() => {
          setTryLoader(false);
        });
    } else {
      localStorage.setItem('monetizationSubscription', JSON.stringify({ ...data, isTrial: 1 }));
      window.location.href = '/portal';
    }
  }

  const handleSubscribe = async () => {
    setSubscribeLoading(true);
    if (Boolean(auth?.user?.id) && Boolean(data)) {
      await controlledAPI({ ignoreUser: true })
        .put(`users/${auth.user.id}`, {jsonData: { ...auth.user.jsonData, monetizationSubscription: data }})
        .then((res) => {
          setSubscribeLoading(false);
          if (res.code >= 400) {
            return;
          }
          
          setSubscriptionData({ ...data, isTrial: 0 }, () => {
            window.location.href = '/portal/billing/subscribe';
          });
          // setMarketplaceData(res.data);
        })
        .catch(() => {
          setSubscribeLoading(false);
        })
        .finally(() => {
          setSubscribeLoading(false);
        });
    } else {
      localStorage.setItem('monetizationSubscription', JSON.stringify({ ...data, isTrial: 0 }));
      window.location.href = '/portal';
    }
  }

  return (
    <>
      <Box>
        {dataLoading || !marketplaceData ? (
          <Box sx={{ padding: '10px' }}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: "100%" }}
            />
          </Box>
        ) : (
          <>
            <Box ref={topContentRef}>
              <TopContent marketplaceData={marketplaceData} />
              <GalleryContent gallery_items={marketplaceData?.gallery_items} />
            </Box>
            <OverviewContent marketplaceData={marketplaceData} showMoreEnabled={showMoreEnabled} />
          </>
        )}
      </Box>
      {!dataLoading && (
        <Box 
          className={styles.ctaBlock}
          sx={{ left: isMobile ? 0 : `${offsetLeft}px`, right: isSticky ? '-15px' : 0, position: isSticky ? 'sticky' : 'flex' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              onClick={handleSubscribe}
              disabled={isMobile ? false : subscribeLoading}
              variant="contained"
              sx={{ margin: '0 auto', minWidth: {xs: '100%', md: '320px'}, display: 'flex', justifyContent: 'space-between' }}
            >
              <span>Subscribe</span>
              <span style={{ textTransform: 'lowercase' }}>{`$${marketplaceData?.price} per month`}</span>
            </Button>
          </Box>
          <Box sx={{ fontSize: '12px', textAlign: { xs: 'center', md: 'center' } }}> or
            <Button onClick={handleTryFree} disabled={tryLoader} sx={{ fontSize: '12px', textAlign: { xs: 'center', md: 'right' } }}>
              Try for free
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Content;
