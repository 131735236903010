import { useEffect, useRef } from 'react';

const usePrevious = (value) => {
  const ref = useRef();

  const forceSetPrevious = (nextValue) => {
    ref.current = nextValue;
  };

  useEffect(() => {
    if (value !== ref.current) forceSetPrevious(value);
  }, [value]);

  return {
    previous: ref.current,
    forceSetPrevious,
  };
};

export default usePrevious;
