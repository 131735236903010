import React from 'react';

const LogoSvg = ({hasLogo = true}) => {

    const width = hasLogo ? '119.678' : '90';
    const transformValue = hasLogo ? '-919' : '-953';

    return (
        <svg width={width} height="29.312" viewBox={`0 0 ${width} 29.312`}>
            {hasLogo && <defs>
                <linearGradient id="linear-gradient" x1="0.09" y1="0.293" x2="0.894" y2="0.743"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#a061f3"/>
                    <stop offset="0.471" stopColor="#6074ee"/>
                    <stop offset="1" stopColor="#1fa6f4"/>
                </linearGradient>
            </defs>}

            <g id="Group_8767" data-name="Group 8767" transform={`translate(${transformValue} -2450.442)`}>
              {hasLogo && (
                <g id="Group_8766" data-name="Group 8766" transform="translate(919 2450.442)">
                  <path id="Union_1" data-name="Union 1"
                      d="M13.6,28.016a1.3,1.3,0,1,1,1.3,1.3A1.3,1.3,0,0,1,13.6,28.016Zm6.015-4.13H10.989a1.3,1.3,0,1,1-.042-.648H19.66a1.3,1.3,0,1,1-.042.648Zm-7.589-5.425H6.769a1.619,1.619,0,1,1,0-.648h5.219a2.591,2.591,0,0,1,5.172,0h5.867a1.62,1.62,0,0,1,3.174,0h5.247a1.619,1.619,0,1,1,0,.648H26.2a1.62,1.62,0,0,1-3.174,0H17.121a2.592,2.592,0,0,1-5.091,0ZM7.8,12.226H2.527a1.3,1.3,0,1,1,.042-.648H7.783a2.915,2.915,0,0,1,5.81,0h5.218a2.025,2.025,0,0,1,4,0h3.773a1.3,1.3,0,0,1,2.546,0h8.3a1.3,1.3,0,1,1,.042.648H29.085a1.3,1.3,0,0,1-2.462,0H22.808a2.025,2.025,0,0,1-4,0H13.575a2.915,2.915,0,0,1-5.774,0ZM30.982,6.882H18.411a1.62,1.62,0,0,1-3.137,0H7.722a1.62,1.62,0,1,1,.033-.648h7.486a1.62,1.62,0,0,1,3.2,0H30.949a1.619,1.619,0,1,1,.033.648ZM24.666,2.024H15.5a1.62,1.62,0,1,1,.033-.648h9.105a1.619,1.619,0,1,1,.033.648Z"
                      transform="translate(0 0)" fill="url(#linear-gradient)"/>
                </g>
              )}
                <path id="Path_5940" data-name="Path 5940"
                      d="M30.841,6.5H28.7l-3.381,8.4L25,15.953l-.276-1.035L21.365,6.5H19.249l4.738,11.454L21.848,22.83h2.139Zm6.946-.391a6.164,6.164,0,1,0,6.164,6.164A6.15,6.15,0,0,0,37.787,6.109Zm0,1.794a4.371,4.371,0,1,1-4.3,4.347A4.339,4.339,0,0,1,37.787,7.9ZM55.543,6.5v7.36a5.356,5.356,0,0,1-4.209,2.714c-2.047,0-3.1-1.472-3.1-3.5V6.5h-1.84v6.808c0,2.875,1.656,5.014,4.531,5.014a5.755,5.755,0,0,0,4.669-2.507V18h1.794V6.5Zm11.684-.322a5.755,5.755,0,0,0-4.669,2.507V6.5H60.764V18h1.863V10.64a5.284,5.284,0,0,1,4.209-2.714c2.024,0,3.082,1.472,3.082,3.5V18h1.863V11.192C71.781,8.317,70.1,6.178,67.227,6.178Zm19.182,5.888a5.913,5.913,0,0,0-6.072-5.957,6.167,6.167,0,1,0,.253,12.328,7.635,7.635,0,0,0,4.531-1.449l-.391-1.863a7.026,7.026,0,0,1-4.14,1.564,4.3,4.3,0,0,1-4.485-3.611H86.317A6.093,6.093,0,0,0,86.409,12.066ZM80.337,7.834a4.018,4.018,0,0,1,4.163,3.7H76.082A4.237,4.237,0,0,1,80.337,7.834Zm15,8.1a4.864,4.864,0,0,1-2.254.529A1.734,1.734,0,0,1,91.193,14.6V8.225h4.278V6.5H91.193V3.188h-1.84V6.5H87.26V8.225h2.093v6.348a3.33,3.33,0,0,0,3.634,3.68,7.135,7.135,0,0,0,2.691-.575Z"
                      transform="translate(943 2451)" fill="#454545"/>
            </g>
        </svg>
    );
}

export default LogoSvg;