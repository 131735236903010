import { Typography } from '@mui/material';
import GeneralTooltip from 'components/tooltip/GeneralTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Title = ({ text, hintText, extraMargin = false }) => {
  return (
    <Typography sx={{ marginBottom: extraMargin ? '15px' : '5px', fontSize: '0.875rem', display: 'flex', alignItems: 'center' }}>
      {text}&nbsp;
      <GeneralTooltip
        placement='bottom'
        title={hintText}
      >
        <HelpOutlineIcon fontSize='small' sx={{color:'#B5B5B5'}}/>
      </GeneralTooltip>
    </Typography>
  )
}

export default Title;
