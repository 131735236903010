import {Box, FormControlLabel, Grid, Switch, Typography} from "@mui/material";
import React from "react";
import PublicLink from "../../../../PublicLink";
import Hint from "../../../../Hint";
import ActionBtns from "pages/protected/ShareModel/components/ActionBtns";

const Settings = ({publicLink, copyPublicLink, hideFromPublic, selectedModel, savePublic, setIsPublicListedOnMarketplace, smallScreen, isInView }) => {


    return (
        <>
            {!smallScreen && (
                <Grid item xs={12} flex="0 !important" sx={{padding: '0 0 22px !important'}}>
                    <Typography sx={{fontSize: '24px'}}>
                        Share Model to Public
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12} flex="0 !important" sx={{paddingTop: '0 !important', paddingBottom: '20px'}}>
                <PublicLink
                    publicLink={publicLink}
                    copyPublicLink={() => copyPublicLink()}
                    hideFromPublic={hideFromPublic}
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    mt={6}
                >
                    <FormControlLabel
                        control={<Switch
                            defaultChecked={Boolean(selectedModel?.settings?.public_listed_on_marketplace)}/>}
                        label="List on the Marketplace"
                        onChange={event => {
                            setIsPublicListedOnMarketplace(event.target.checked);
                            const isChecked = event.target.checked ? 1 : 0;
                            savePublic(isChecked);
                        }}
                        sx={{marginRight: '5px'}}
                    />
                    <Hint text="Marketplace is coming soon."/>
                </Box>
            </Grid>
        </>
    )
}

export default Settings;