import { Box } from '@mui/material';
import styles from "../invoices-table.module.css";
import classnames from 'classnames';
import SmoothContent from 'components/loadings/SmoothContent';

const InvoiceSmallLoading = () => {


    return (
        <Box className={classnames(styles.wrapper, styles.mobileWrapper)}>
            <Box className={classnames(styles.verticalLine, styles.bold)}>
                <div>Date</div>
                <div>Invoice ID</div>
                <div>Status</div>
                <div>Amount</div>
            </Box>
            <Box className={styles.verticalLine}>

                <div><SmoothContent width={100}/></div>
                <div><SmoothContent width={100}/></div>
                <div><SmoothContent width={100}/></div>
                <div><SmoothContent width={100}/></div>
            </Box>
            <Box className={styles.actionsWrapper}>
                <div className={styles.actions}>
                    <div className={styles.loadingIcons}>
                        <SmoothContent width={22} height={22} variant="circular" />
                    </div>
                    <div className={styles.loadingIcons}>
                        <SmoothContent width={22} height={22} variant="circular" />
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default InvoiceSmallLoading;