import ImageGenerating from "../ImageGenerating";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import {useMemo} from "react";
import styled from "@emotion/styled";
import SmartLoading from "./SmartLoading";



const templates = {
    generate_image: <ImageGenerating/>
}
const ToolCallsLoadings = ({template = null}) => {


    const {smallScreen} = useIsSmallScreen();

    const LoadingItem = templates[template] || <SmartLoading text={template}/>;
    if (!template) {
        return
    }


    const paddingSize = smallScreen ? '8px' : '75px';


    return (
        <Box sx={{padding:`8px 0 8px calc(${paddingSize} + 8.33%)` }}>{LoadingItem}</Box>
    )
}
export default ToolCallsLoadings;
