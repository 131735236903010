import {Grid} from "@mui/material";
import {Outlet} from "react-router-dom";
import ParseWebsiteContent from "./components/ParseWebsiteContent";

const WebsiteTraining = () => {

    return (
        <Grid container display="flex" flexflow="column" justifyContent="center" height="100%" alignItems="center">
            <Grid item xs={12}>
                <ParseWebsiteContent/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Outlet/>
            </Grid>
        </Grid>

    );
};

export default WebsiteTraining;
