import {Box, Chip, Grid, Skeleton, Table, TableBody, TextField, Typography} from "@mui/material";
import classnames from "classnames";
import styles from "../../../shareModelConfig.module.css";
import Row from "../../Row";
import React, {useState, useRef, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSelector} from "react-redux";
import Settings from "./components/Settings";
import Preview from "./components/Preview";
import SmallScreenToggleBtn from "../../SmallScreenToggleBtn";
import ActionBtns from "../../ActionBtns";

const Restricted = () => {
    const viewRef = useRef(null,)
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setIsInView(entry.isIntersecting);
        }, {
            root: null,
            threshold: 1.0
        });

        if (viewRef.current) {
            observer.observe(viewRef.current);
        }

        return () => {
            if (viewRef.current) {
                observer.unobserve(viewRef.current);
            }
        };
    }, []);

    const {
        tags,
        setTags,
        emailError,
        setEmailError,
        tagRef,
        isPublic,
        invitationsList,
        handleKeyPress,
        selectedModel,
        smallScreen,
        hideFromPublic,
        savePublic,
        loading,
        websiteStatus,
        shareType,
        handleSendInvitation,
        publicLink,
    } = useOutletContext();



    const listLoading = useSelector(state => state.invitations.loading);
    const list = useSelector(state => state.invitations.list);


    const handleDeleteTag = (value) => {
        const newtags = tags.filter((val) => val !== value);
        setTags(newtags);
    }

    // const showPreview = invitationsList?.length;
    const showPreview = false;

    if (smallScreen) {

        return (
          <>
            <Grid container py={4} spacing={4} sx={{ paddingBottom: '100px' }}>
                <Grid item xs={12} sm={12} textAlign="center">
                    <SmallScreenToggleBtn
                        position={2}
                        isInView={isInView}
                        // hidePreview={!publicLink}
                        hidePreview={!showPreview}
                        settingsComp={<Settings isInView={isInView} smallScreen={smallScreen} tags={tags} handleDeleteTag={handleDeleteTag} listLoading={listLoading} list={list} emailError={emailError} setEmailError={setEmailError} tagRef={tagRef} invitationsList={invitationsList} handleKeyPress={handleKeyPress}  />}
                        previewComp={<Preview invitationsList={invitationsList} manualHash={selectedModel.public_hash} />}
                    />
                    {isInView && (
                      <ActionBtns
                        isPublic={isPublic}
                        hideFromPublic={hideFromPublic}
                        savePublic={savePublic}
                        loading={loading}
                        websiteStatus={websiteStatus}
                        shareType={shareType}
                        handleSendInvitation={handleSendInvitation}
                      />
                    )}
                </Grid>
            </Grid>
            <Box ref={viewRef} />
          </> 
        )
    }

    return (
        <Grid container py={4} spacing={4}>
            <Grid item xs={12} sm={12} md={showPreview ? 3.8 : 12} lg={showPreview ? 4.9 : 12}>
                <Settings isInView={true} tags={tags} handleDeleteTag={handleDeleteTag} listLoading={listLoading} list={list} emailError={emailError} setEmailError={setEmailError} tagRef={tagRef} invitationsList={invitationsList} handleKeyPress={handleKeyPress} smallScreen={smallScreen}  />
                <ActionBtns
                  isPublic={isPublic}
                  hideFromPublic={hideFromPublic}
                  savePublic={savePublic}
                  loading={loading}
                  websiteStatus={websiteStatus}
                  shareType={shareType}
                  handleSendInvitation={handleSendInvitation}
                />
            </Grid>
            {showPreview && (
              <Grid item xs={12} sm={12} md={7.8} lg={6.9} sx={{maxHeight: 'calc(100vh - 247px)',}}>
                  <Preview invitationsList={invitationsList} manualHash={selectedModel.public_hash} />
              </Grid>
            )}
            <Box ref={viewRef} />
        </Grid>
    )
}

export default Restricted;