import { createSlice } from "@reduxjs/toolkit";
import {
    getListThunk,
    getInvitationsListThunk,
    changeInvitationStatusThunk,
    getSubscriptionsThunk,
    unsubscribeThunk,
    unfollowThunk,
    generateHintsThunk,
    updateModelSettingsThunk,
    getHintsListThunk
} from "./modelsThunk";
import {getToken, setToken, removeToken} from "_helpers/tokenManagement";

const CACHE_CURRENT_MODEL = "cachedModel";
const RABBIT_AI_MODEL_ID = 78;
const cachedModel = getToken(CACHE_CURRENT_MODEL, "json");
const currentModel = cachedModel ? cachedModel : {};

const initialState = {
    current: {},
    hints: {
        loading: false,
        list: {
            current: [],
        }

    },
    isNewModel: false,
    // newModelFlow: null,
    selectedModel: { type: 'default', data: null, loading: false }, // 'default' | 'shared' | 'monetization'
    list: [],
    invitationsList: [],
    subscriptions: [],
    loading: false,
    invitationsLoading: false,
    updateLoading: false,
    loadingSubscription: false,
    loadingUnfollow: false,
    errors: [],
    session: {
        loading: {
            isDataSending: false,
            step: 'Thinking...'
        },
        model: {}

    }
};

const cacheModel = (model) => {
    try {
        const modelStr = JSON.stringify(model);
        setToken(CACHE_CURRENT_MODEL, modelStr);
    } catch {
    }

}

export const modelsSlice = createSlice({

    name: "models",
    initialState,
    reducers: {
        cleanSession(state) {
            state.session = initialState.session;
        },
        setLoading(state, action) {
            const {isDataSending} = action.payload;
            state.session.loading.isDataSending = isDataSending;
        },
        allowOpenPortal(state, action) {
          const { model } = action.payload;
          if (!Boolean(state.current.id)) {
            state.current = model;
          }
        },
        setSession(state, action) {
            const {step, model} = action.payload;
            state.session.loading.step = step;
            if(model) {
                state.session.model = model;
            }
        },
        setSelectedModelType(state, action) {
            const { modelType, data, loading } = action.payload;

            state.selectedModel = { type: modelType, data, loading };
        },
        removeHints(state, _) {
          state.hints = {
            loading: false,
            list: {
              current: [],
            },
          };
        },
        setHints(state, action) {
          const { modelId, hints } = action.payload;

          if (modelId === state.current.id) {
            state.hints = {
              loading: false,
              list: {
                ...state.hints.list,
                [modelId]: hints,
                current: hints,
              },
            };
          } else {
            state.hints = {
              loading: false,
              list: {
                ...state.hints.list,
                [modelId]: hints,
              },
            };
          }
        },
        setIsNewModel(state, action) {
          const { isNewModel } = action.payload;

          state.isNewModel = isNewModel;
        },
        // setNewModelFlow(state, action) {
        //   const { flow } = action.payload;

        //   state.newModelFlow = flow;
        // },
        changeModel(state, action) {
            let modelId = action.payload;
            const model = state.list.find((model) => model.id === modelId) || state.invitationsList.find((model) => model.model.id === modelId);

            if(!model) {
                return;
            }
            state.current = model;
            state.hints.list.current = state.hints.list[modelId] || [];
            cacheModel(model);
        },
        addModel(state, action) {
            const {model, choosen} = action.payload;

            const alreadyExist = state.list.some(item => item.id === model.id);
            if(alreadyExist) {
                return
            }

            if (model) {
                state.list.push(model);
            }

            if (choosen) {
                state.current = model;
                cacheModel(model);
            }
        },
        updateModel(state, action) {
            const updatedModel = action.payload;
            const model = state.list.find(
                (model) => model.id === updatedModel.model.id
            );
            Object.assign(model, updatedModel.model);
            if (state.current.id === updatedModel.model.id) {
                state.current = model;
                cacheModel(model);
            }
        },
        deleteModel(state, action) {
          let { id } = action.payload;
          const hintsList = { ...state.hints.list };
          if (Object.keys(hintsList).length < 3) {
            hintsList.current = [];
          }
          delete hintsList[id];
          state.list = state.list.filter((model) => model.id !== id);
          state.hints = {
            loading: false,
            list: hintsList,
          }
          state.current = state.list[0] ? state.list[0] : {noModels: true};
          removeToken(CACHE_CURRENT_MODEL);
        },
        updateModelSettings(state, action) {
            const updatedModels = state.list.map(item => {
                if (item.id === action.payload.model_id) {
                    return {...item, settings: action.payload}
                }
                return item;
            });

            state.list = updatedModels;

            const model = updatedModels.find(model => model.id === action.payload.model_id);
            cacheModel(model);
            // state.current = state.list[0] ? state.list[0] : { noModels: true };
            // removeToken(CACHE_CURRENT_MODEL);
        },
        deleteInvitation(state, action) {
            let {id} = action.payload;
            state.invitationsList = state.invitationsList.filter((model) => model.model.id !== id);
        },
        changeInvitationStatus(state, action) {
            const {hash, status} = action.payload;
            const updatedList = state.invitationsList.map(item => {
                if (item.unique_hash === hash) {
                    item.status = status;
                }

                return item;
            });

            state.invitationsList = updatedList;
        },
        acceptInvitation(state, action) {
            const {hash, id} = action.payload;
            const selectedInvitation = state.invitationsList.filter(item => item.unique_hash === hash)[0];
            selectedInvitation.model.access = ['read'];
            state.invitationsList = state.invitationsList.filter((model) => model.model.id !== id);
            state.list.push(selectedInvitation.model);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getListThunk.pending, (state, {_}) => {
            state.loading = true;
        });
        builder.addCase(getListThunk.fulfilled, (state, {payload}) => {
            state.loading = false;
            const { models, modelId } = payload;
            state.list = models;
            const modelExist = currentModel.id && models.filter(item => item.id === currentModel.id)[0];
            if (modelExist) {
              state.current = modelExist;
            } else {
              const selectedModel = Boolean(modelId) ? models.filter(item => item.id === modelId)[0] : models[0];
              const model = models.length > 0 ? selectedModel : { noModels: true };
              state.current = model;
              cacheModel(model);
            }
            state.errors = [];
        });
        builder.addCase(getInvitationsListThunk.pending, (state, {_}) => {
            state.invitationsLoading = true;
        });
        builder.addCase(getInvitationsListThunk.fulfilled, (state, {payload}) => {
            state.invitationsLoading = false;
            state.invitationsList = payload.models;
        });

// Update model's settings
        builder.addCase(updateModelSettingsThunk.pending, (state, {_}) => {
            state.updateLoading = true;
        });
        builder.addCase(updateModelSettingsThunk.rejected, (state, {_}) => {
            state.updateLoading = false;
        });
        builder.addCase(updateModelSettingsThunk.fulfilled, (state, {payload}) => {
            const {settings} = payload;
            state.updateLoading = false;

            const updatedModels = state.list.map(item => {
                if (item.id === settings.model_id) {
                    return {...item, settings}
                }
                return item;
            });

            state.list = updatedModels;
            const model = updatedModels.find(model => model.id === settings.model_id);
            cacheModel(model);
        });

        builder.addCase(generateHintsThunk.pending, (state, {_}) => {

        });
        builder.addCase(generateHintsThunk.rejected, (state, {_}) => {

        });
        builder.addCase(generateHintsThunk.fulfilled, (state, {payload}) => {
            const {hints} = payload;
            const modelId = state.current.id;

            state.hints.list.current = hints;
            state.hints.list[modelId] = hints;
            state.hints.loading = false;

        });

        builder.addCase(getHintsListThunk.pending, (state, {_}) => {
            state.hints.loading = true;
        });
        builder.addCase(getHintsListThunk.rejected, (state, {_}) => {
            const modelId = state.current.id;
            state.hints.list.current = [];
            state.hints.list[modelId] = [];
            state.hints.loading = false;
        });
        builder.addCase(getHintsListThunk.fulfilled, (state, {payload}) => {
            const { modelId, hints} = payload;

            state.hints.list.current = hints;
            state.hints.list[modelId] = hints;
            state.hints.loading = false;
        });
        // Get subscriptions
        builder.addCase(getSubscriptionsThunk.pending, (state, { _ }) => {
          state.loadingSubscription = true;
        });
        builder.addCase(getSubscriptionsThunk.rejected, (state, { _ }) => {
          state.loadingSubscription = false;
        });
        builder.addCase(getSubscriptionsThunk.fulfilled, (state, { payload }) => {
          const { list } = payload;
          state.loadingSubscription = false;
          state.subscriptions = list;
        });
        // Remove subscriptions
        builder.addCase(unsubscribeThunk.pending, (state, { _ }) => {
          state.loadingSubscription = true;
        });
        builder.addCase(unsubscribeThunk.rejected, (state, { _ }) => {
          state.loadingSubscription = false;
        });
        builder.addCase(unsubscribeThunk.fulfilled, (state, { payload }) => {
          const { modelId } = payload;
          // const updatedSubscriptions = state.subscriptions.filter(item => item.model.id !== modelId);
          const updatedSubscriptions = state.subscriptions.map(item => {
            if (item.model.id === modelId) {
              return { ...item, status: 'canceled' };
            }
            return item;
          });
          state.subscriptions = updatedSubscriptions;
          state.loadingSubscription = false;
        });
        // Get subscriptions
        builder.addCase(unfollowThunk.pending, (state, { _ }) => {
          state.loadingUnfollow = true;
        });
        builder.addCase(unfollowThunk.rejected, (state, { _ }) => {
          state.loadingUnfollow = false;
        });
        builder.addCase(unfollowThunk.fulfilled, (state, { payload }) => {
          const { modelId } = payload;
          state.list = state.list.filter((model) => model.id !== modelId);
          state.current = state.list[0] ? state.list[0] : { noModels: true };
          removeToken(CACHE_CURRENT_MODEL);
          state.loadingUnfollow = false;
        });
    },
});

export const {
    changeModel,
    addModel,
    updateModel,
    allowOpenPortal,
    removeHints,
    setHints,
    // updateModelSettings,
    setSelectedModelType,
    deleteModel,
    deleteInvitation,
    changeInvitationStatus,
    acceptInvitation,
    setSession,
    setLoading,
    cleanSession,
    setIsNewModel,
    // setNewModelFlow,
} =
    modelsSlice.actions;

export const getList = getListThunk;
export const getInvitationsList = getInvitationsListThunk;
export const updateModelSettings = updateModelSettingsThunk;
export const generateHints = generateHintsThunk;
export const getSubscriptions = getSubscriptionsThunk;
export const unsubscribe = unsubscribeThunk;
export const unfollow = unfollowThunk;

export const getHintsList = getHintsListThunk;

export default modelsSlice.reducer;