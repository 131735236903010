import { useState} from "react";
import {
  Grid,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import Logout from '@mui/icons-material/Logout';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {logout} from "redux/auth";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from "react-redux";

const UserMenuButton=({userInfo})=> {
    const dispatch = useDispatch();
    const [userMenuOpen,setUserMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleModelsClose = () => {
      setAnchorEl(null);
    };
    const handleLogout = async () => {
        const result = await dispatch(logout({redirect: {hard: true}}));
        if(result.type === "auth/logout") {
            window.location.href = "/portal/login";
        }
      };

    return (
        <IconButton 
        sx={{width:'30px',height:'30px'}}
        onClick={(e)=>{setUserMenuOpen(!userMenuOpen);setAnchorEl(e.currentTarget);}}><ExpandMoreIcon fontSize="small"/>
            {userMenuOpen && 
            <Menu 
            id="user-menu"
            width="100%"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleModelsClose}
            PaperProps={{
            elevation: 3,
            sx: {
                width:'240px',
                "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        >
            <MenuItem sx={{py:4,width:'100%',justifyContent:'center',pointerEvents:'none'}} divider>
              <Grid container>
                <Grid item xs={3} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Avatar src={userInfo.avatar ?? ''} sx={{ fontSize: 15}}>{userInfo.initial}</Avatar>
                </Grid>
                <Grid item xs={9} sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    <Typography sx={{ width:'100%',fontWeight: "bold"}} variant="h3">
                        {userInfo.fullName ?? ''}
                    </Typography>
                    <Typography sx={{ width:'100%',whiteSpace:'pre-wrap',overflowWrap:'break-word'}} variant="span">
                        {userInfo.email ?? ''}
                    </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem sx={{py:3,px:5,width:'100%'}} onClick={()=>{window.location.href="/portal/settings"}}>
                Account Settings
            </MenuItem>
            {/* Return later */}
            {/* <MenuItem sx={{py:3,px:5,width:'100%'}}>
                Plans and Billing
            </MenuItem> */}
            <a href='https://support.younet.ai' target='_blank'rel="noopener noreferrer"> 
            <MenuItem sx={{py:3,px:5,width:'100%'}}>
                Help Center<OpenInNewIcon fontSize="10" sx={{margin:'0 3px',color:'#7b8191'}}/>
            </MenuItem>
            </a>
            <MenuItem sx={{py:3,width:'100%'}} onClick={handleLogout}>
                <ListItemIcon>
                <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
            </Menu>
            }
        </IconButton>
    );
}

export default UserMenuButton;