import TrainStatus from "../../../../../../../../../../../../../components/showcase/TrainStatus";
import {ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton} from "@mui/material";
import SimpleCheckbox from "../../../../../../../../../../../../../components/inputs/checkboxes/SimpleCheckbox";
import Typography from "@mui/material/Typography";
import SmoothContent from "components/loadings/SmoothContent";

const LoadingLinksList = () => {

    return Array.from({length: 10}).map((_, index) => (

        <ListItem key={'link-loading-item'+index} xs={12}
                  secondaryAction={<SmoothContent loading={true} height={24} width={50}/>}
                  disablePadding
                  divider
                  dense
        >
            <ListItemButton dense>

                <ListItemText
                    primary={<SmoothContent width={300} height={24} isLoading={true}/>}
                    secondary={
                        <SmoothContent loading={true} width={100}>
                            <Typography
                                // sx={{display: 'inline'}}
                                variant="truncate_text_150w"
                                // color="text.primary"
                                maxWidth="80%"
                            >

                            </Typography>
                        </SmoothContent>

                    }


                />
            </ListItemButton>
        </ListItem>
        )
    )

}


export default LoadingLinksList;