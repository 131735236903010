import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {monetizationState} from "redux/monetization";
import Preview from './components/Preview';
import TextField from "@mui/material/TextField";
import React, {useEffect, useRef, useState} from "react";
import {Box, FormHelperText, InputAdornment, Switch, Divider} from "@mui/material";
import Hint from "../../Hint";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useOutletContext} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {decodeData, encodeData} from "../../../../../../utils/formatContent";
import PublicLink from "../../PublicLink";
import AddIcon from "@mui/icons-material/Add";
import classnames from "classnames";
import styles from '../../../shareModelConfig.module.css';
import FileInput from "../../../../../../components/inputs/FileInput";
import ImagesList from "../../ImagesList";
import CustomMdEditor from "../../../../../../components/inputs/CustomMdEditor";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import {updateModelSettings} from "../../../../../../redux/models";
import {
    addFiles,
    addNewCard,
    deleteCard,
    getAllCards,
    updateCard,
    updateCardWithHash
} from "../../../../../../redux/marketplace";
import {interactWithUser} from "../../../../../../redux/status";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {STANDART_GPT_MODEL} from "constants";
import SmallScreenToggleBtn from "../../SmallScreenToggleBtn";

const countLimits = {
    title: 50,
    miniDescription: 150,
};

const supportedFormats = [
    {
        formats: ['.png', '.jpg', '.jpeg'],
        title: 'Picture'
    }
];

const transformText = text => {
    text = text.toLowerCase();
    text = text.replace(/[^a-zA-Z0-9\-_]/g, '').replace(/\s/g, '-');

    return text;
}

const initialMonetizationState = {
    price: {
        value: '',
        errMsg: '',
    },
    title: {
        errMsg: '',
        value: '',
        countErr: false,
    },
    description: {
        errMsg: '',
        value: '',
        countErr: false,
    },
    miniDescription: {
        errMsg: '',
        value: '',
        countErr: false,
    },
    files: {
        errMsg: '',
        countErr: false,
    },
};

const Monetization = () => {
    const {
        selectedModel,
        isPublic,
        invitationsList,
        cards,
        copyPublicLink,
        hideFromPublic,
        settings,
        monetizationState,
        setMonetizationState,
        loading
    } = useOutletContext();

    const currentCard = cards?.filter(card => card?.model_id === selectedModel?.id)[0];
    const [isMonetizationFormActive, setIsMonetizationFormActive] = useState(!Boolean(currentCard?.url_hash));
    const updateCardLoading = useSelector(state => state.marketplace.updateLoading);
    const updateModelSettingsLoading = useSelector(state => state.marketplace.updateLoading);
    const deleteLoading = useSelector(state => state.marketplace.deleteLoading);
    const [file, setFile] = useState(null);
    const [filesPreview, setFilesPreview] = useState(null);
    const [isListedOnMarketplace, setIsListedOnMarketplace] = useState(Boolean(selectedModel?.settings?.listed_on_marketplace));
    const [isPublicListedOnMarketplace, setIsPublicListedOnMarketplace] = useState(!isPublic || Boolean(selectedModel?.settings?.public_listed_on_marketplace));
    const [isMonetizationEnabled, setIsMonetizationEnabled] = useState(Boolean(selectedModel?.settings?.enable_monetization));
    const [clearFile, setClearFile] = useState(false);
    const [marketPlacePublicLink, setMarketPlacePublicLink] = useState('');
    const [selectFileVisible, setSelectFileVisible] = useState(false);
    const [shareType, setShareType] = useState(Boolean(isMonetizationEnabled) ? 'monetization' : invitationsList.length ? 'restricted' : 'public');
    const viewRef = useRef(null);
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            // Check if the current entry is intersecting
            const entry = entries[0];
            setIsInView(entry.isIntersecting);
        }, {
            root: null,
            threshold: 1.0
        });

        if (viewRef.current) {
            observer.observe(viewRef.current);
        }

        return () => {
            if (viewRef.current) {
                observer.unobserve(viewRef.current);
            }
        };
    }, []);

    const dispatch = useDispatch();

    const tagRef = useRef();
    const amountRef = useRef();
    const filesRef = useRef();
    const switcherRef = useRef();
    const switcherMonetizationRef = useRef();

    const currentLinkArray = marketPlacePublicLink.split('/');
    const currentHash = currentLinkArray[currentLinkArray.length - 1];
    const { smallScreen } = useIsSmallScreen();

    const currentCardDescription = currentCard?.description?.length > 0 ? decodeData(currentCard?.description) : '';
    const currentCardMiniDescription = currentCard?.mini_description?.length > 0 ? decodeData(currentCard?.mini_description) : '';
    const currentCardTitle = currentCard?.title?.length ? decodeData(currentCard?.title) : '';

    // const files = file.map()

    const generateFilePreviews = () => {
      let files = [];

      if (currentCard?.gallery_items?.length) {
        // const filteredFiles = currentCard.gallery_items.map(item => item.file_url);
        // files = filteredFiles;
        files = currentCard.gallery_items;
      }

      if (filesPreview?.length) {
        const updatedFiles = filesPreview.map(item => ({ file_url: item }));
        files = [...files, ...updatedFiles];
      }

      return files;
    }

    const validateForm = () => {
        let valid = true;
        const newMonetizationState = {
            price: {...monetizationState.price, errMsg: ""},
            title: {...monetizationState.title, errMsg: ""},
            description: {...monetizationState.description, errMsg: ""},
            miniDescription: {...monetizationState.miniDescription, errMsg: ""},
            files: {...monetizationState.files, errMsg: ""}
        };

        // Validate price
        if (shareType === 'monetization' && !amountRef?.current.value.trim()) {
            newMonetizationState.price.errMsg = "Price is required";
            amountRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }
        // Validate price's amount
        if (shareType === 'monetization' && amountRef?.current.value < 1) {
            newMonetizationState.price.errMsg = "The price must exceed $1";
            amountRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        // Validate title
        if (!monetizationState.title.value.trim()) {
            newMonetizationState.title.errMsg = "Title is required";
            amountRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        // Validate description
        if (!monetizationState.description.value.trim()) {
            newMonetizationState.description.errMsg = "Description is required";
            valid = false;
        }

        // Validate short description
        if (!monetizationState.miniDescription?.value?.trim()) {
            newMonetizationState.miniDescription.errMsg = "Short description is required";
            filesRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        // Validate files length
        if (!Boolean(file?.length) && !Boolean(currentCard?.gallery_items.length)) {
            newMonetizationState.files.errMsg = "Images are required";
            filesRef.current?.scrollIntoView({behavior: 'smooth'});
            valid = false;
        }

        setMonetizationState(newMonetizationState);
        return valid;
    };


    const generateParams = (listedOnMarketplace, deleteCard = false) => {
        return {
            isPublic: Boolean(isPublic) ? 1 : 0,
            contextWindowSize: selectedModel.settings
                ? selectedModel.settings.contextWindowSizePercent
                : settings
                    ? settings.contextWindowSizePercent
                    : 80,
            gptModel: selectedModel.settings
                ? selectedModel.settings.gpt_model
                : settings
                    ? settings.gpt_model
                    : STANDART_GPT_MODEL,
            modelId: selectedModel.id,
            enableMonetization: deleteCard ? 0 : isMonetizationEnabled ? 1 : 0,
            monetizationPrice: deleteCard ? 0 : Number(monetizationState.price.value),
            monetizationDescription: deleteCard ? '' : monetizationState.description.value,
            monetizationMiniDescription: deleteCard ? '' : monetizationState.miniDescription.value,
            listedOnMarketplace,
        }
    };


    const onChangeHandler = (event, isDescription = false) => {
        const fieldName = isDescription ? 'description' : event.target.name;
        const value = isDescription ? event : event.target.value;
        const maxCount = countLimits[fieldName];
        if (value.length <= maxCount || !maxCount) {
            setMonetizationState({...monetizationState, [fieldName]: {errMsg: '', value, countErr: false}});
        }
        if (value.length > maxCount) {
            setMonetizationState({
                ...monetizationState,
                [fieldName]: {...monetizationState[fieldName], countErr: true}
            });
        }
    }

    useEffect(() => {
        const card = cards?.filter(card => card?.model_id === selectedModel?.id)[0];
        if (cards?.length && card) {
            // setCurrentCard(card);
            if (Boolean(file?.length)) {
                dispatch(addFiles({upload: file, cardId: card.id}));
                setFile(null);
            }
            setIsMonetizationFormActive(!Boolean(card?.url_hash));
            // setShareType('monetization');
            setMonetizationState({
                price: {
                    value: selectedModel?.settings?.monetization_price,
                    errMsg: '',
                },
                title: {
                    value: decodeData(card.title),
                    errMsg: '',
                    countErr: false,
                },
                description: {
                    value: decodeData(card.description),
                    errMsg: '',
                    countErr: false,
                },
                miniDescription: {
                    value: decodeData(card.mini_description),
                    errMsg: '',
                    countErr: false,
                },
                files: {
                    errMsg: '',
                    countErr: false,
                }
            });
        } else {
            setMonetizationState(initialMonetizationState);
            // setCurrentCard(null);
            // setShareType('anyone');
            setIsMonetizationFormActive(true);
        }
    }, [cards, selectedModel]);

    useEffect(() => {
        if (Boolean(currentCard?.gallery_items?.length)) {
            setSelectFileVisible(false);
        }
        if (currentCard?.url_hash) {
            setMarketPlacePublicLink(`https://younet.ai/m/${currentCard?.url_hash}`);
        } else {
            setMarketPlacePublicLink('');
        }
    }, [currentCard]);

    useEffect(() => {
        if (Object.values(monetizationState).some((element) => element.countErr)) {
            setTimeout(() => {
                const updatedState = {...monetizationState};
                Object.keys(updatedState).forEach((key) => {
                    updatedState[key] = {...updatedState[key], countErr: false};
                });
                setMonetizationState(updatedState);
            }, 300);
        }
    }, [monetizationState]);

    return (
      <Grid container py={4} spacing={4}>
            <Grid item container py={4} spacing={4} xs={12} sm={12} md={3.8} lg={4.9} pb={smallScreen ? 26 : 4} sx={{ alignContent: 'flex-start' }}>
              {!smallScreen && (
                <Grid item xs={12}>
                    <Typography sx={{fontSize: '24px'}}>
                        Manage subscription page
                    </Typography>
                </Grid>
              )}
              <Grid item xs={12} component="div">
                  <TextField
                      label="Price/Month"
                      variant="outlined"
                      error={Boolean(monetizationState.price.errMsg.length)}
                      sx={{
                          width: '100%',
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                              display: 'none',
                          },
                          '& input[type=number]': {
                              MozAppearance: 'textfield',
                          },
                      }}
                      type="number"
                      name="price"
                      value={monetizationState.price.value}
                      min={0}
                      inputRef={amountRef}
                      onChange={event => onChangeHandler(event)}
                      InputProps={{
                          min: 0,
                          endAdornment: (
                              <InputAdornment position="end">
                                  <Hint text="Specify subscription price per month in USD"/>
                              </InputAdornment>
                          )
                      }}
                  />
                  {Boolean(monetizationState.price.errMsg.length) && (
                      <FormHelperText error>{monetizationState.price.errMsg}</FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12} sx={{display: {xs: 'block', lg: 'flex'}, gap: 6}}>
                  <Box
                      sx={{
                          display: {xs: 'block', lg: 'flex'},
                          alignItems: 'center'
                      }}
                  >
                      <FormControlLabel
                          control={<Switch checked={isMonetizationEnabled}/>}
                          label="Activate monetization"
                          inputRef={switcherMonetizationRef}
                          onChange={event => {
                              setIsMonetizationEnabled(event.target.checked);
                              if (!event.target.checked) {
                                  setIsListedOnMarketplace(false);
                              }
                          }}
                          sx={{marginRight: '5px'}}
                      />
                      <Hint
                          text="Once the subscription is activated, users will be able to subscribe and communicate with the Al model. They will have the ability to interact with it, but not to train it."/>
                  </Box>
                  {isMonetizationEnabled && (
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center'
                          }}
                      >
                          <FormControlLabel
                              control={<Switch checked={isListedOnMarketplace}/>}
                              label="List on the Marketplace"
                              inputRef={switcherRef}
                              onChange={event => setIsListedOnMarketplace(event.target.checked)}
                              sx={{marginRight: '5px'}}
                          />
                          {/* <Hint text="List your Al model in the Marketplace so that others can find it." /> */}
                          <Hint text="Marketplace is coming soon."/>
                      </Box>
                  )}
              </Grid>
              {currentCard?.url_hash && (
                  <>
                      <PublicLink
                          publicLink={marketPlacePublicLink}
                          copyPublicLink={() => copyPublicLink(marketPlacePublicLink)}
                          hideFromPublic={hideFromPublic}
                          hideStopCta={true}
                          readOnly={false}
                          onChangeHandler={(event) => {
                              const {value} = event.target;
                              if (value === 'https://younet.ai/m') return;
                              setMarketPlacePublicLink(value);
                          }}
                      />
                      <Grid item xs={12}>
                          <Box
                              sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                              }}
                          >
                              <Button startIcon={<AddIcon/>}
                                      onClick={() => setIsMonetizationFormActive(!isMonetizationFormActive)}>
                                  Change model info
                              </Button>
                          </Box>
                      </Grid>
                  </>
              )}
              {isMonetizationFormActive && (
                  <>
                      <Grid item xs={12} component="div">
                          <TextField
                              fullWidth
                              variant="outlined"
                              label="Subscription title"
                              name="title"
                              multiline
                              helperText={
                                  <div
                                      className={classnames(styles.symbols, monetizationState.title.countErr && styles.countError)}>
                                      {`${monetizationState.title.value.length}/50`}
                                  </div>
                              }
                              error={Boolean(monetizationState.title.errMsg.length)}
                              value={monetizationState.title.value}
                              onChange={event => onChangeHandler(event)}
                              InputProps={{
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <Hint
                                              text="Enter a title for your subscription that clearly reflects its purpose or content."/>
                                      </InputAdornment>
                                  )
                              }}
                          />
                          {Boolean(monetizationState.title.errMsg.length) && (
                              <FormHelperText error>{monetizationState.title.errMsg}</FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                          <Box
                              sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                              }}
                          >
                              <Button startIcon={<AddIcon/>}
                                      onClick={() => setSelectFileVisible(!selectFileVisible)}>
                                  Upload images
                              </Button>
                              <Hint text="Add an image for the marketplace"/>
                          </Box>
                          {selectFileVisible && (
                              <Box mt={4}>
                                  <Typography>Image(s)</Typography>
                                  <FileInput
                                      ref={filesRef}
                                      fileMaxSize={5}
                                      supportedFormats={supportedFormats}
                                      selectedTab={0}
                                      isShort={false}
                                      isArray={true}
                                      onChange={(data) => {
                                          setFile(data);
                                      }}
                                      setPreviews={setFilesPreview}
                                      clearFile={clearFile}
                                  />
                              </Box>
                          )}
                          {Boolean(monetizationState.files.errMsg.length) && (
                              <FormHelperText error>{monetizationState.files.errMsg}</FormHelperText>
                          )}
                          <ImagesList cardId={currentCard?.id} gallery={currentCard?.gallery_items}/>
                      </Grid>
                      <Grid item xs={12} component="div">
                          <TextField
                              label="Short description"
                              variant="outlined"
                              error={Boolean(monetizationState.miniDescription.errMsg.length)}
                              sx={{
                                  width: '100%',
                                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                      display: 'none',
                                  },
                                  '& input[type=number]': {
                                      MozAppearance: 'textfield',
                                  },
                              }}
                              helperText={
                                  <div
                                      className={classnames(styles.symbols, monetizationState.miniDescription.countErr && styles.countError)}>
                                      {`${monetizationState.miniDescription.value?.length}/150`}
                                  </div>
                              }
                              type="text"
                              name="miniDescription"
                              value={monetizationState.miniDescription.value}
                              onChange={event => onChangeHandler(event)}
                              InputProps={{
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <Hint
                                              text="Write a short description for your subscription that reflects its purpose or content."/>
                                      </InputAdornment>
                                  )
                              }}
                          />
                          {Boolean(monetizationState.miniDescription.errMsg.length) && (
                              <FormHelperText
                                  error>{monetizationState.miniDescription.errMsg}</FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12} component="div">
                          <CustomMdEditor
                              isToolbarVisible={true}
                              value={monetizationState.description.value}
                              onChange={value => onChangeHandler(value, true)}
                              placeholder="Description"
                              // resetSignal={resetTextValueSignal}
                              // setResetSignal={setResetTextValueSignal}
                              customHeight="150px"
                          />
                          {Boolean(monetizationState.description.errMsg.length) && (
                              <FormHelperText
                                  error>{monetizationState.description.errMsg}</FormHelperText>
                          )}
                      </Grid>
                  </>
              )}
              {isInView && (
                <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="Loading…"
                            variant="contained"
                            sx={{
                              position: { xs: 'fixed', md: 'relative' },
                              bottom: { xs: '50px', md: 'inherit' },
                              width: { xs: '90%', md: 'auto' },
                              left: { xs: '5%', md: 'inherit' },
                              padding: { xs: '6px 10px', md: '4px 10px' }
                            }}
                            onClick={async () => {
                                const params = generateParams(isListedOnMarketplace ? 1 : 0);

                                const validation = validateForm();
                                if (!validation) {
                                    return;
                                }

                                if (isMonetizationEnabled !== Boolean(selectedModel?.settings?.enable_monetization)
                                    || isListedOnMarketplace !== Boolean(selectedModel?.settings?.listed_on_marketplace)
                                    || Number(params.monetizationPrice) !== Number(selectedModel?.settings?.monetization_price)
                                ) {
                                    dispatch(updateModelSettings(params)).then((res) => {
                                        dispatch(getAllCards());
                                    });
                                }

                                // Update a current card
                                if (Boolean(currentCard?.id) && (monetizationState?.description.value !== currentCard?.description || monetizationState?.title.value !== currentCard?.title || monetizationState?.miniDescription.value !== currentCard?.miniDescription || Boolean(file))) {
                                    const linkToArr = marketPlacePublicLink.split('/');
                                    const isSameHash = linkToArr[linkToArr.length - 1] === currentCard?.url_hash;
                                    if (isSameHash) {
                                        dispatch(updateCard({
                                            id: currentCard.id, params: {
                                                title: encodeData(monetizationState.title.value),
                                                description: encodeData(monetizationState.description.value),
                                                miniDescription: encodeData(monetizationState.miniDescription.value),
                                            }
                                        }));
                                    } else {
                                        // updateCardWithHash
                                        dispatch(updateCardWithHash({
                                            id: currentCard.id, params: {
                                                title: encodeData(monetizationState.title.value),
                                                description: encodeData(monetizationState.description.value),
                                                miniDescription: encodeData(monetizationState.miniDescription.value),
                                                urlHash: currentHash,
                                            }
                                        })).then(res => {
                                            if (res?.type === 'marketplace/updateCardWithHash/rejected' && Boolean(res?.payload?.message)) {
                                                dispatch(interactWithUser({
                                                    message: res?.payload?.message,
                                                    type: 'error',
                                                }));
                                            }

                                        });
                                    }
                                }

                                // Create a new card
                                if (!Boolean(currentCard)) {
                                    dispatch(
                                        addNewCard(
                                            {
                                                modelId: selectedModel.id,
                                                title: encodeData(monetizationState.title.value),
                                                description: encodeData(monetizationState.description.value),
                                                miniDescription: encodeData(monetizationState.miniDescription.value),
                                                urlHash: transformText(selectedModel.name),
                                            })
                                    );
                                }
                            }}
                            size="small"
                            startIcon={<SaveIcon/>}
                            disabled={
                                updateCardLoading
                                || updateModelSettingsLoading
                                || (isMonetizationEnabled === Boolean(selectedModel?.settings?.enable_monetization)
                                    && isListedOnMarketplace === Boolean(selectedModel?.settings?.listed_on_marketplace)
                                    && Number(monetizationState.price.value) === Number(selectedModel?.settings?.monetization_price)
                                    && monetizationState?.description.value === currentCardDescription
                                    && monetizationState?.miniDescription.value === currentCardMiniDescription
                                    && monetizationState?.title.value === currentCardTitle)
                                && currentHash === currentCard?.url_hash
                                && !Boolean(file)
                            }
                        >
                            Save
                        </LoadingButton>
                        {Boolean(currentCard?.id) && (
                            <LoadingButton
                                loading={loading}
                                loadingIndicator="Loading…"
                                variant="contained"
                                onClick={() => {
                                    const params = generateParams(0, true, 0);
                                    dispatch(deleteCard({id: currentCard.id})).then(() => {
                                        dispatch(updateModelSettings(params)).then(() => {
                                            dispatch(getAllCards());
                                        });
                                        setIsMonetizationFormActive(true);
                                        setMonetizationState(initialMonetizationState);
                                        setIsMonetizationEnabled(false);
                                        setIsListedOnMarketplace(false);
                                        // setCurrentCard(null);
                                        setFile(null);
                                    });
                                }}
                                sx={{
                                  position: { xs: 'fixed', md: 'relative' },
                                  bottom: { xs: '100px', md: 'inherit' },
                                  width: { xs: '90%', md: 'auto' },
                                  left: { xs: '5%', md: 'inherit' },
                                  padding: { xs: '6px 10px', md: '4px 10px' }
                                }}
                                size="small"
                                color="error"
                                startIcon={<DeleteIcon/>}
                                disabled={deleteLoading}
                            >
                                Delete Page
                            </LoadingButton>
                        )}
                        {smallScreen && (
                          <Box sx={{ position: 'relative', zIndex: 10 }}>
                            <SmallScreenToggleBtn
                              position={Boolean(currentCard?.id) ? 3 : 2}
                              previewComp={<Preview monetizationState={monetizationState} files={generateFilePreviews()}/>}
                            />
                          </Box>
                        )}

                    </Box>
                </Grid>
              )}
            </Grid>
            <Grid item auto>
              <Divider orientation="vertical"/>
            </Grid>
            {!smallScreen && (
              <Grid item xs={12} sm={12} md={7.9} lg={6.9}>
                <Preview monetizationState={monetizationState} files={generateFilePreviews()} marketplaceHash={currentCard?.url_hash} />
              </Grid>
            )}
            <Box ref={viewRef} />
        </Grid>
    )
}
export default Monetization;