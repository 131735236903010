import { Avatar, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Box } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import RocketIcon from '@mui/icons-material/Rocket';
import { decodeData } from "utils/formatContent";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PaymentOptions from "pages/protected/billing/Payment/components/PaymentOptions";
import AddPaymentForm from "pages/protected/billing/Payment/components/AddPaymentForm";
import Stripe from "_lib/Stripe";
import styled from "@emotion/styled";
import SmoothContent from "components/loadings/SmoothContent";
import { sendPixelFB } from "events/analytics/facebook";

const TAXES = {
  "CA": 0.13
}

const FeeContainer = styled(Typography)({
  maxWidth: 500,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const PaymentMonetizationInfo = ({ monetizationModel = false, planInformation = {}, paymentInformation = {}, tempAddress = null }) => {
  const country = paymentInformation?.country || '';

  // useEffect(()=> {
  //   const sendEventFB = async () => {
  //     await sendPixelFB({ event: 'InitiateCheckout', content_name: planInformation.name});
  //   };

  //   sendEventFB();
  // }, []);

  const taxRate = TAXES[tempAddress ?? country];
  const taxPrice = taxRate ? (monetizationModel?.price * taxRate) : 0;
  const fullPrice = monetizationModel?.price + taxPrice;
  const priceLoading = monetizationModel?.price != 0 && ( (paymentInformation == "empty" && !tempAddress) || paymentInformation == null ); 

  return (
    // <Grid item container direction="column" xs={12} sm={12} md={3} sx={{ order: { xs: 1, sm: 1, md: 3 }, alignItems: 'center', padding: '24px 0 !important' }}>
      <List>
        <ListItem sx={{ padding: 0 }} >
          {Boolean(monetizationModel?.title) && (
            <ListItemText primary={<Typography variant="page_sub_title_bold" sx={{ mt: 2 }}>
              {decodeData(monetizationModel?.title)}
            </Typography>} />
          )}
        </ListItem>
        <ListItem sx={{ padding: 0 }} >
          <ListItemText primary={<Typography variant="list_header">
            Subscription
          </Typography>}
            secondary={<FeeContainer>
              <Typography variant="page_content" sx={{ textTransform: "capitalize", fontWeight: 500 }} pr={4}>Billed {monetizationModel?.frequency} </Typography>
              <Typography variant="page_content" >USD ${monetizationModel?.price}/{monetizationModel?.frequency} </Typography>
            </FeeContainer>} />
        </ListItem>
        <ListItem sx={{ padding: 0 }} >
          <ListItemText
            secondary={<FeeContainer>
              <Typography variant="page_content" pr={4} sx={{ textTransform: "capitalize", fontWeight: 500 }}>Tax</Typography>
              <SmoothContent loading={priceLoading}>
                <Typography variant="page_content">+${taxPrice.toFixed(2)}</Typography>
              </SmoothContent>
            </FeeContainer>} />
        </ListItem>
        <ListItem sx={{ padding: 0 }} >
          <ListItemText primary={<Typography variant="page_sub_title_bold">
          </Typography>}
            secondary={<FeeContainer>
              <Typography variant="page_sub_title_bold" pr={4}>Total</Typography>
              <SmoothContent loading={priceLoading}>
                <Typography variant="page_content" >${fullPrice.toFixed(2)}</Typography>
              </SmoothContent>
            </FeeContainer>} />
        </ListItem>
      </List>
    // </Grid>
  )
}


export default PaymentMonetizationInfo;