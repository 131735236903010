import api from "_services/api";
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Skeleton from "@mui/material/Skeleton";
import Collapse from "@mui/material/Collapse";
import VisibilityIcon from "@mui/icons-material/Visibility";

import IconButton from "@mui/material/IconButton";
import { useOutletContext } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { encodeData } from "utils/formatContent";
import QAEditors from "components/inputs/QAEditors";
import EastIcon from "@mui/icons-material/East";
import trainingTypes from "mappings/trainingTypes.json";
import { useNavigate } from "react-router-dom";
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { updateCredits } from "redux/billing";
import {setStatus, updateCells} from "redux/tools";
import styles from '../../training.module.css';
import {sendUIEvent} from "../../../../../../events/analytics/google";

const QuestionAndAnswerTraining = ({ id }) => {
  // const { modelId } = useOutletContext();
  const [rows, setRows] = useState([]);
  const [isListLoading, setListLoading] = useState(false);
  const [isDataSending, setDataSending] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const model = useSelector((state) => state.models?.current, shallowEqual);
  const modelId = Boolean(model.id) ? model.id : id;
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Brain clicked item
  const [clickedBrainButton, setClickedBrainButton] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setListLoading(true);
      if (modelId) {
        await api
          .get(`/learning/faq/list/${modelId}`)
          .then((r) => {
            let parsedRows = r.code >= 400 || !r.faq_memory_items ? [] : r.faq_memory_items;
            setRows(parsedRows);
            setListLoading(false);
            if (Boolean(parsedRows.length)) {
              dispatch(setStatus({type: 'qa', status: 'done'}));
            }
          })

          .catch((error) => {
            setListLoading(false);
          });
      }
      // setData(result.data);
    };

    fetchData();
  }, [modelId]);


  const handleDeleteRows = (id) => {
    const newRows = rows.filter((row) => id != row.id);

    const deletedata = async () => {
      setListLoading(true);
      await api
        .delete(`/learning/faq/${id}`)
        .then((r) => {
          setRows(newRows);
          // TODO: use it for progress
          // if (!newRows.length) {
          //   dispatch(setProgressItem({ modelId, progress: {faq_resource_type: {passed: false, skipped: false}} }));
          //   manageProgressState(modelId, 'faq_resource_type', false);
          // }
          dispatch(updateCells({increase: false}));
          setListLoading(false);
        })

        .catch((error) => {
          setListLoading(false);
        });
    };

    deletedata();
  };


  const addToBrain = async (answer, question) => {

    let params = {
      answer: encodeData(answer),
      question: encodeData(question),
      modelId: modelId,
    };

    setDataSending(true);
    setListLoading(true);
    if (modelId) {
      await api
        .post("/learning/faq", params)
        .then((r) => {
          const { id, question, answer, balance } = r.model_data;
          handleAddRow(id, question, answer);
          // setRows(r.text_memory_items);
          
          // TODO: use it for progress
          // dispatch(setProgressItem({ modelId, progress: {faq_resource_type: {passed: true, skipped: false}} }));
          // manageProgressState(modelId, 'faq_resource_type', true);
          setListLoading(false);
          setDataSending(false);
          dispatch(updateCells({increase: true}));
          dispatch(setStatus({type: 'qa', status: 'done'}));
          dispatch(updateCells({increase: true}));
          if(balance) {
            dispatch(updateCredits({newBalance: balance}));
          }
        })

        .catch((error) => {
          setListLoading(false);
          setDataSending(false);
        });
    }
  };


  const handleAddRow = (id, question, answer) => {
    const newRow = { id: id, question: question, answer: answer };
    setRows([newRow, ...rows]);
  };

  const handleRedirect = (id,path) => {
    nav(path+'/'+id);
    sendUIEvent({name: `q&a_tool_view_item`});
  }

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);

    const typePath = '/training/q&a' || '/training';

    // return(
    //   <>
    //   <Box sx={{ display: 'flex', "& > *": { borderBottom: "unset" } }}>
    //     <div sx={{ width: "40px", padding: "0 0 0 10px" }}>
    //       <IconButton
    //         aria-label="expand row"
    //         size="small"
    //         onClick={() => setOpen(!open)}
    //       >
    //         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    //       </IconButton>
    //     </div>
    //     <div sx={{
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           padding: "5px",
    //           // maxWidth: "200px",
    //         }}>
    //       <Box sx={{ margin: 1, display: "flex", alignItems: "center", whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis", }}>
    //         <Typography variant="h2">Q:</Typography>
    //         <Typography
    //           style={{ paddingLeft: "10px",
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis", }}
    //           // variant="h3"
    //           // component="i"
    //         >
    //           {row.question}
    //         </Typography>
    //       </Box>
    //     </div>
    //     {/* <div>
    //       <IconButton onClick={() => handleDeleteRows(row.id)}>
    //         <DeleteIcon />
    //       </IconButton>
    //       <IconButton 
    //       onClick={() => handleRedirect(row.id,typePath)}
    //       >
    //         <EastIcon color="primary" />
    //       </IconButton>
    //     </div> */}
    //     <div></div>
    //   </Box>
    //     <Collapse in={open} timeout="auto" unmountOnExit>
    //       <Box sx={{ margin: 1, display: "flex", alignItems: "center" }}>
    //         <Typography variant="h2">A:</Typography>
    //         <Typography
    //           sx={{ paddingLeft: "10px" }}
    //           variant="h3"
    //           component="i"
    //         >
    //           {row.answer}
    //         </Typography>
    //         <div>
    //           {row.answer}
    //         </div>
    //       </Box>
    //     </Collapse>
    //   </>
    // )

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell sx={{ width: "40px", padding: "0 0 0 10px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              // maxWidth: "200px",
            }}
          >
            {/* <Collapse in={isExpanded} timeout="auto" unmountOnExit> */}

            <Box sx={{ margin: 1, display: "flex", alignItems: "center" }}>
              <Typography variant="h2">Q:</Typography>
              <Typography
                sx={{ paddingLeft: "10px", whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis", }}
                variant="h3"
                component="i"
                className={styles.trim}
              >
                {row.question}
              </Typography>
            </Box>
            {/* </Collapse> */}
          </TableCell>
          <TableCell sx={{ padding: "5px" }} align="right">
            {/* <IconButton onClick={() => handleViewContent(row)}>
              <VisibilityIcon />
            </IconButton>
            <IconButton disabled>
              <EditIcon />
            </IconButton> */}
            <IconButton onClick={() => handleDeleteRows(row.id)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => handleRedirect(row.id, typePath)}>
              <VisibilityIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{padding: '0 48px' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, display: "flex", alignItems: "center" }}>
                <Typography variant="h2">A:</Typography>
                <Typography
                  sx={{ paddingLeft: "10px" }}
                  variant="h3"
                  component="i"
                  className={styles.trim}
                >
                  {row.answer}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };


  const startIndex = page * 10;
  const endIndex = startIndex + 10;
  const currentData = rows.slice(startIndex, endIndex);

  return (
    <Grid
      container
      // className={styles["container"]}
      spacing={6}
    >
      <QAEditors
        fetchSignal={clickedBrainButton}
        fetchCallback={function (question, answer) {
          addToBrain(answer, question);
        }}
      />

      <Grid display="flex" justifyContent="center" item md={12} xs={12}>
        <LoadingButton
          sx={{ borderRadius: "5px", textAlign: "right" }}
          color="primary"
          loading={isDataSending}
          loadingPosition="end"
          endIcon={<SendIcon />}
          onClick={function () {
              setClickedBrainButton(clickedBrainButton + 1);
            }
          }
          disabled={isListLoading}
        >
          Add to Brain
        </LoadingButton>
      </Grid>

      <Grid item xs={12} md={12}>
        {/* <Paper>
          {isListLoading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : rows ? (
            currentData.map((row) => <EmailRow.js key={row.id} row={row} />)
          ) : null}
        </Paper> */}
        <TableContainer
          component={Paper}
          // sx={{maxHeight: '300px'}}
        >
          <Table aria-label="collapsible table" fixedheader="true" fixedfooter="true">
            {/* <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Question</TableCell>
                <TableCell />
                <TableCell align="right" sx={{ paddingRight: "40px" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead> */}
            <TableBody>
              {isListLoading ? (
                <>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                </>
              ) : rows ? (
                currentData.map((row) => <Row key={row.id} row={row} />)
              ) : null}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex' }}>
            <div>
              <IconButton
                aria-label="expand row"
                size="small"
                // onClick={() => setOpen(!open)}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </div>
            {/* <div style={{display: 'flex', padding: '0 40px 0 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              dddd d d d d  dd  d d dddddddd d d d d  dd  d d dddddddd d d d d  dd  d d dddddddd d d d d  dd  d d dddddddd d d d d  dd  d d dddddddd d d d d  dd  d d dddddddd d d d d  dd  d d dddd
              <div>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
                <IconButton 
                >
                  <EastIcon color="primary" />
                </IconButton>
              </div>
            </div> */}
          </Box>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rows.length}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default QuestionAndAnswerTraining;

