import {useState, useEffect} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {setToken, getToken, removeToken} from "_helpers/tokenManagement";
import {encodeData} from 'utils/formatContent';
import api from '_services/api';
import {useDispatch, useSelector} from "react-redux";
import {setAccount} from "redux/email";
import {GET_EMAIL_CACHED_DATA_NAME} from "mappings/constants";

const useEmailAuth = (modelId = 0, emailType = "", newUser = true) => {

    const nav = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const params = useParams();
    const models = useSelector((state) => state.models.list);

    const code = urlParams.get('code');
    const state = urlParams.get('state');

    const getUserData = () => {
        const account = getToken(STORAGE_ID, 'json');
        if (!account) return null;
        return account;
    };

    const STORAGE_ID = GET_EMAIL_CACHED_DATA_NAME(modelId);
    const account = getUserData();

    const needsLoading = Boolean(account);
    const [loading, setLoading] = useState(needsLoading);


    const setAccountRedux = (data) => {
        dispatch(setAccount({modelId: modelId, account: data}));
    }

    const refreshToken = async (accountId) => {
        return await api.get(`google/auth/token/regenerate/${accountId}`);
    };

    const handleRefreshTokenSuccess = (account) => {

        if(!params.mailId) {
          if (!Boolean(localStorage.getItem('onboardingBrainStatus')) || (Boolean(localStorage.getItem('onboardingBrainStatus')) && Boolean(localStorage.getItem('brainOnboarding')))) {
            nav(`./${account.type}`);
          }
        }

        setAccountRedux(account);
    };

    const handleRefreshTokenError = () => {
      console.log('handleRefreshTokenError');
      setAccountRedux({});
      removeToken(STORAGE_ID);
    };

    const authenticateUser = async (code) => {

        const url = {
            gmail: 'google/auth/user',
            outlook: 'microsoft/oauth2/user'
        }

        return await api.post(url[emailType || 'gmail'], {code: encodeData(code), state});
    };

    const handleAuthenticationSuccess = async (info) => {

        try {

            const account = info.data ?? info.userData.account;
            account.type = emailType;
            const stringData = JSON.stringify(account);
            // TODO: refactor - if there is a auth from the onboarding
            setToken(STORAGE_ID, stringData);
            if (Boolean(localStorage.getItem('onboardingBrainStatus'))) {
              // TODO: improve this logic
              setToken('emailLogin', { 'storageId': STORAGE_ID, 'stringData': account });
              if (models.length < 3) {
                nav(`/training/email/${emailType}`);
              } else {
                nav({
                  pathname: '/models/create',
                  search: '?continueOnboarding=1',
                })
              }
            } else {
              nav(`./${emailType}`);
            }
            setAccountRedux(account);

        } catch {
            nav(`./`);
        }

    };

    const handleAuthenticationError = () => {
      console.log('handleAuthenticationError');
      setAccountRedux({});
      removeToken(STORAGE_ID);
    };

    useEffect(() => {


        const fetchUserData = async () => {

            try {

                if (account) {

                    if(account.type === "outlook") {
                        handleRefreshTokenSuccess(account);
                        return;
                    }

                    setLoading(true);
                    const response = await refreshToken(account.id);
                    const {code} = response;
                    if (code >= 400) {
                        handleRefreshTokenError();
                    } else {
                        handleRefreshTokenSuccess(account);
                    }
                }

            } catch (error) {
                handleRefreshTokenError();
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {

        const fetchUser = async () => {

            if (code) {

                setLoading(true);
                try {
                    const userData = await authenticateUser(code);
                    if (userData.code >= 400) {
                        handleAuthenticationError();
                    } else {
                        handleAuthenticationSuccess(userData);
                    }
                } catch (error) {
                    handleAuthenticationError();
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchUser();
    }, [code]);


    return {loading};
};

export default useEmailAuth;
  