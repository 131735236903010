import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api/index.js';
// import { constant } from 'cypress/types/lodash';

export const getMsgListThunk = createAsyncThunk('message/list', async (chatId, thunkAPI) => {
    try {
        
        const response = await api.get("/message/list", { params: { chat_id: chatId, limit: 10, page: 1 } })


        // console.log(response)
        let messages = response?.messages || [];
        // console.warn(user)

        // if(!user || response.code >= 400) {
        //     throw new Error(response);
        // }

        return { messages: messages, count: response.count, limit: response.limit }
    } catch (e) {
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return { message: message };
        // return thunkAPI.rejectWithValue(message);
    }
});

export const loadMoreThunk = createAsyncThunk('message/loadMore', async (payload, thunkAPI) => {
  try {
      const response = await api.get("/message/list", { params: { chat_id: payload.chatId, limit: payload.limit, page: 1 } })
      const messages = response?.messages || [];

      return { messages: messages, count: response.count, limit: response.limit }
  } catch (e) {
      const message = e?.response?.message || e?.message || 'Something went wrong';
      return { message: message };
  }
});

export const getMsgThunkByIds = createAsyncThunk('message/retrieve', async (payload, thunkAPI) => {
    
    try {
        const response = await api.post("/message/retrieve", { messageIds: payload.messageIds, chatId: payload.chatId  })

        let messages = response?.messages || [];

        if (response.code >= 400) {
            throw new Error(response);
        }

        return { messages: messages }
    } catch (e) {
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return { message: message };
        // return thunkAPI.rejectWithValue(message);
    }
});


export const sendMessage = createAsyncThunk('message/sendMessage', async (payload, thunkAPI) => {

    try {
        const response = await api.post('/message', payload);

        let { code, chat } = response || {};

        if (code >= 400 || !chat) {
            throw new Error(response);
        }

        return { chat: chat }
    } catch (e) {

        let message = e?.response?.message || e?.message || 'Something went wrong';
        return { message: message }
    }
});