import {
    Box,
    Chip,
    FormControlLabel,
    Grid,
    Skeleton,
    Switch,
    Table,
    TableBody,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import PublicLink from "../../../../PublicLink";
import Hint from "../../../../Hint";
import classnames from "classnames";
import styles from "../../../../../shareModelConfig.module.css";
import Row from "../../../../Row";
import ActionBtns from "pages/protected/ShareModel/components/ActionBtns";

const Settings = ({
                      tags = [],
                      listLoading,
                      handleDeleteTag,
                      emailError,
                      setEmailError,
                      tagRef,
                      invitationsList,
                      handleKeyPress,
                      smallScreen,
                      isInView,
                  }) => {
    return (
        <>
            {!smallScreen && (
                <Grid item xs={12} flex="0 !important" sx={{padding: '0 0 22px !important'}}>
                    <Typography sx={{fontSize: '24px'}}>
                        Share Model by Email
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} mb={2}>
                {Boolean(tags.length) && !listLoading && (
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}} mb={4}>
                        {tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                onDelete={() => handleDeleteTag(tag)}
                                sx={{margin: '0.2rem'}}
                            />
                        ))}
                    </Box>
                )}
                <Box sx={{position: 'relative'}}>
                    <TextField
                        fullWidth
                        error={Boolean(emailError)}
                        variant="outlined"
                        label="Type email and press Enter"
                        margin="none"
                        placeholder='Email Address'
                        inputRef={tagRef}
                        onChange={() => {
                            setEmailError(false);
                        }}
                        onKeyDown={handleKeyPress}
                    />
                    {emailError && (
                        <div
                            className={classnames(styles.error, emailError && styles.errorShowed)}>{emailError}</div>
                    )}
                </Box>
            </Grid>
            <Grid sx={{width: '100%'}}>
                {listLoading && (
                    <Skeleton animation="wave"/>
                )}
                {!listLoading && Boolean(invitationsList.length) && (
                    <Box mb={2}>
                        <Typography>People with access</Typography>
                        <Box sx={{maxHeight: '144px', overflow: 'hidden', overflowY: 'scroll'}}>
                            <Table aria-label="collapsible table" fixedheader="true" fixedfooter="true"
                                   px={2} pb={4}>
                                <TableBody px={2}>
                                    {Boolean(invitationsList.length) && !listLoading && (
                                        <>
                                            {invitationsList.map((row => (
                                                <Row key={row.id} row={row}/>
                                            )))}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                )}
            </Grid>
            {isInView && (
              <ActionBtns />
            )}
        </>
    )
}

export default Settings;