import {styled} from "@mui/material/styles";
import {Accordion} from "@mui/material";

const rootStyle = ({animationDelay = '3300ms'}) => `
  &.MuiAccordion-root {
    border: 1px solid rgba(216,211,250, .1);
    border-radius: 12px !important;
    padding: 4px 10px 4px 14px;
    width: 100%;
    justify-content: left;
    margin-bottom: 10px;
    color: #000;
    font-size: 14px;
    text-transform: none;
    box-shadow: 0 0 2px 2px rgba(216,211,250, .5);
    animation-delay: ${animationDelay};
    min-height: 34px;
  }
`;

const accordionBaseStyles = `
  &.MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
  .MuiAccordionSummary-content:before {
    display: none;
  }
  .MuiAccordionSummary-root {
    min-height: auto !important;
    padding: 0;
  }
  .MuiAccordionDetails-root {
    padding: 5px 0 0;
  }
`;


// Create a custom accordion component with dynamic animation delay
const CustomAccordion = styled(Accordion)((styles = {}) => `
  ${accordionBaseStyles}
  ${rootStyle(styles)}  // Include additional styles
`);

export default CustomAccordion;