import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setToken } from "_helpers/tokenManagement";
import api from "_services/api";
import ShadowSpinner from "components/loadings/ShadowSpinner";

const SetToken = () => {
  const params = useParams();
  const navigate = useNavigate();

  const token = params.token || null;

  useEffect(() => {
    if (token) {
      api
        .get(`users/autologin/${token}`)
        .then((r) => {
          if (r.code >= 400) {
            navigate("/login");
          } else {
            setToken("token", r.token);
            window.location.href="/portal/playground";
          }
        })
        .catch((err) => {
          console.log(err)

          navigate("/login");
        });
    }

  }, [navigate]);

  return (
    <ShadowSpinner open={true}/>
  );
};

export default SetToken;
