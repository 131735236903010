import { createSlice } from "@reduxjs/toolkit";
import { getBrainListThunk } from "./toolsThunk";


const initialState = {
  loading: false,
  isBrainOnboardingEnabled: false,
  status: {
    text: null,
    qa: null,
    files: null,
    emails: null,
    links: null
  },
  brain: {
    loading: false,
    cellsAmount: 0,
    currentPage: 0,
    list: []
  },
  files: {
    list: [],
    amount: 0,
    prices: {}
  },
  emails: {
    list: [],
    amount: 0,
  },
  links: {
    list: [],
    amount: 0,
  }

  // later, add here other tools for platform optimization and cache systems
};

export const toolsState = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setStatus(state, action) {
      const { type, status } = action.payload;
      const updatedData = { ...state.status, [type]: status };

      state.status = updatedData;
    },
    setEveryToolsStatus(state, action) {
      const { status } = action.payload;

      state.status = status;
    },
    setBrainOnboarding(state, action) {
      const { isEnabled } = action.payload;
      state.isBrainOnboardingEnabled = isEnabled;
    },
    clearStatus(state) {
      state.status = {
        text: null,
        qa: null,
        files: null,
        emails: null,
      };
    },
    clearBrain(state, action) {
      state.brain.currentPage = 0;
      state.brain.list = [];
      state.brain.cellsAmount = 0
    },
    updateCells(state, action) {
      const { increase } = action.payload;
      const cellsAmount = state.brain.cellsAmount;

      if(cellsAmount === 0 && !increase) {
        state.brain.cellsAmount = 0;
      }

      state.brain.cellsAmount = increase ? cellsAmount + 1 : cellsAmount - 1;
    },
    deleteBrainItem(state, action) {
      const updatedList = state.brain.list.filter(item => {
        return item.id !== action.payload.id}
      );
      state.brain.cellsAmount = updatedList.length;
      state.brain.list = updatedList;
    },
    changeFilePrices(state, action) {
      const {fileId, price, refresh} = action.payload;

      if(refresh) {
        state.files.prices = {};
        return;
      }
      state.files.prices[fileId] = price;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrainListThunk.pending, (state, { _ }) => {
      state.loading = true;
    });

    builder.addCase(getBrainListThunk.rejected, (state, { payload }) => {
      state.loading = false;
      state.brain.cellsAmount = -1;
    });

    builder.addCase(getBrainListThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.brain.cellsAmount = payload.count;
      if (state.brain.currentPage < payload.page) {
        state.brain.list = [...state.brain.list, ...payload.brain_items];
        state.brain.currentPage = payload.page;
      }
      let fileItems = payload.brain_items.filter(item => item.data_type === 'file_resource_type');
      state.files.list = fileItems;

      let emailList = payload.brain_items.filter(item => item.data_type === 'email_message_type');
      state.emails.list = emailList;

      let linksList = payload.brain_items.filter(item => item.data_type === 'link_resource_type');
      state.links.list = linksList
    });
  },
});

export const { deleteBrainItem, changeFilePrices, clearBrain, setStatus, clearStatus, setBrainOnboarding, setEveryToolsStatus, updateCells } = toolsState.actions;
export const getBrainList = getBrainListThunk;

export default toolsState.reducer;
