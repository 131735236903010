import { Box, Grid, Typography, Avatar, Skeleton } from "@mui/material";
import { decodeData } from "utils/formatContent";
import { getInitials } from "utils/prepareNames";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

const TopContent = ({ marketplaceData, fullwidth = false }) => {
  return (
    <Grid container>
      {!fullwidth && <Grid item xs={12} md={1.5} />}
      <Grid item xs={12} md={fullwidth ? 12 : 9}>
        <Box sx={{ margin: {xs: '8px 0 8px', md: '25px 0 12px'} }}>
          <Typography variant="marketplace_title">
            {marketplaceData?.title ? decodeData(marketplaceData.title) : <Skeleton width={150} variant="text" animation="wave"/>}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="marketplace_subtitle">
            {Boolean(marketplaceData?.mini_description) && decodeData(marketplaceData.mini_description)}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' alignItems='center' sx={{ paddingBottom: {xs: '2px', md: '8px'} }}>
          <Typography sx={{ color: '#b2b2b2', fontSize: {xs: '0.75rem', md: '0.875rem'}, fontWeight: 400, marginRight: '8px' }}>
            Created by:
          </Typography>
          {marketplaceData?.user ? <Avatar src={marketplaceData.user?.avatar ?? ''} sx={{width:{xs:'26px', md:'30px'},height:{xs:'26px', md:'30px'},border:'1px solid #00000011', fontSize: "14px"}}>{getInitials(marketplaceData?.user?.name)}</Avatar> : <Skeleton width={30} height={30} variant="circular" animation="wave">  <Avatar /></Skeleton>}
          {marketplaceData?.user?.name ? <Typography variant="p" pl={2} sx={{wordBreak: 'break-word', fontSize: {xs: '0.75rem', md: '0.875rem'}}}>{marketplaceData.user?.name ?? ''}</Typography> : <Skeleton sx={{margin: "0 10px"}} width={80} variant="text" animation="wave"/> }
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ flexWrap: 'nowrap', marginBottom: {xs: '20px', md: '40px'} }}>
          <QuestionAnswerOutlinedIcon sx={{ fontSize: '0.87rem', position: 'relative', top: '1px', marginRight: '5px' }} />
          <Typography sx={{ fontSize: {xs: '0.75rem', md: '0.875rem'}, marginleft: '8px' }}>
            {marketplaceData?.chat_count < 1000 ? `${marketplaceData?.chat_count} chats` : `${marketplaceData?.chat_count / 1000}k chats`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TopContent;
