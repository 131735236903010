import React, { useEffect, useState, useRef, memo } from "react";
import {
  Dialog,
  Divider,
  TextField,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Button,
  Box,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from "@mui/icons-material/ChatBubbleOutline";
import useDeleteChat from 'hooks/useDeleteChat';
import useRenameChat from 'hooks/useRenameChat';
import { cleanMessages } from 'redux/messages';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setCurrentChat, getList, setSource } from "redux/chats";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { Skeleton } from "@mui/material";
import useViewportHeight from "hooks/useViewportHeight";
import AccessChecker from "components/security/AccessChecker";
import ChatSettings from "./components/ChatSettings";
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { getBrainList } from "redux/tools";
import ChatList from './components/ChatList';
import styles from "./chat-menu.module.css";
import {sendUIEvent} from "../../../../events/analytics/google";

const ChatMenu = ({onChatChange, onInnerClick=() => {}}) => {

  const dispatch = useDispatch();
  const { chatId, model, loading, loadingGetMore, list, chats, sharedChats, userId, isSharedChatSelected } = useSelector((state) => ({
    loading: state.chats.loading,
    loadingGetMore: state.chats.loadingGetMore,
    list:
      state.chats.source == "default"
        ? state.chats.chats.items
        : state.chats.sharedChats.items,
    chats: state.chats.source == "default" ? state.chats.chats : state.chats.sharedChats,
    chatId: state.chats.chatId,
    model: state.models.current,
    userId: state.auth.user.id,
    sharedChats: state.chats.sharedChats.items,
    isSharedChatSelected: state.chats.source !== "default",
  }),shallowEqual);
  const { smallScreen } = useIsSmallScreen();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedChat, setSelectedChat] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const viewportHeight = useViewportHeight();
  const { handleDeleteChat, isDeleteLoading } = useDeleteChat();
  const { handleRenameChat, isRenamingLoading } = useRenameChat();

  let { chat_id } = useParams();

  // const mobileChatOpenHandler = () => {
  //   if (smallScreen) {
  //     mobileHelperChange(false);
  //   }
  // };

  // const mobileChatCloseHandler = () => {
  //   if (smallScreen) {
  //     mobileHelperChange(true);
  //   }
  // };

  useEffect(() => {
    const isOwnersModel = model.user_id === userId;
    // if (model.id && isOwnersModel) {
      dispatch(getList({ model_id: model.id, limit: 10 }));
      dispatch(getBrainList({ model_id: model.id }));
    // }
  }, [model]);


  useEffect(() => {
    if (list?.length && chatId != chat_id) {
      chat_id = chat_id || null;
      dispatch(setCurrentChat(chat_id));
    }
  }, [chat_id, list?.length]);

  // useEffect(() => {
  //   const chatOpenedMatch = pathName.startsWith('/playground/chat');
  //
  //   if (!chatOpenedMatch) {
  //     if (list?.length) {
  //       mobileChatCloseHandler();
  //     } else {
  //       mobileChatOpenHandler();
  //     }
  //   } else {
  //     mobileChatOpenHandler();
  //   }
  // }, [list, pathName]);

  return (
    <>
      <Box
        sx={{
          // maxWidth: "calc(100% - 50px)",
          width: "100%",
          // maxWidth: smallScreen ? "100%" : "250px",
          bgcolor: "background.paper",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          // borderRight: "1px solid #eee",
          height: "100%",
        }}
      >
        {/* <Divider /> */}
        <Box
          className={
            smallScreen ? styles["center-btns-sm"] : styles["center-btns"]
          }
        >
          <Button
            className={styles["new-chat-btn"]}
            onClick={() => {
              dispatch(cleanMessages());
              navigate("/playground/chat/new");
              if (isSharedChatSelected) {
                dispatch(setSource({source: 'default'}));
              }
                onInnerClick();
                sendUIEvent({name: `new_chat_btn_click`});
              // mobileChatOpenHandler();
            }}
            variant="outlined"
          >
            New chat
          </Button>
          <AccessChecker
            currentPermissions={model.access}
            requiredPermissions={["write"]}
          >
            {Boolean(sharedChats?.length) && <ChatSettings modelId={model.id} />}
          </AccessChecker>
        </Box>
        <List
          className={styles["chat-list-container"]}
          sx={{
            maxHeight: {
              xs: `calc(${viewportHeight}px - 358px)`,
              md: `calc(${viewportHeight}px - 200px)`,
            },
          }}
        >
          {loading ? (
            <>
              {[...Array(4)].map((_, i) => (
                <ListItem disablePadding key={`loading-${i}`}>
                  <ListItemButton>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <ChatIcon fontSize={"small"} />
                    </ListItemIcon>

                    <ListItemText
                      className={styles["one-line"]}
                      primaryTypographyProps={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Skeleton variant="text" animation="wave" />
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </>
          ) : (
            <>
              <ChatList
                setIsDialogOpen={val => setIsDialogOpen(val)}
                onChatChange={val => onChatChange(val)}
                // mobileChatOpenHandler={mobileChatOpenHandler}
                setSelectedChat={val => {
                  setTitle(val.name);
                  setSelectedChat(val);
                }}
              />
              {chats.pages > chats.page && (
                <Button
                  type="button"
                  sx={{
                    fontSize: '0.75rem',
                    color: '#7e7e7e',
                  }}
                  disabled={loadingGetMore}
                  onClick={() => {
                    dispatch(getList({ model_id: model.id, limit: chats.limit + 10 }));
                  }}
                >Load more...</Button>
              )}
            </>
          )}
          
        </List>
        <Divider />
      </Box>
      <AccessChecker
        currentPermissions={model.access}
        requiredPermissions={["write"]}
      >
        {smallScreen && (
          <Dialog
            open={isDialogOpen}
            sx={{
              '.MuiPaper-root': {
                padding: 2,
              },
            }}
            onClose={() => setIsDialogOpen(false)}
          >
            <DialogContent sx={{ userSelect: 'none' }}>
              <TextField
                autoFocus
                type="text"
                label="Edit chat title"
                value={title}
                fullWidth
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //     <IconButton
                //       aria-label="toggle password visibility"
                //       onClick={() => handleRenameChat(selectedChat.id, title, () => setIsDialogOpen(false))}
                //       edge="end"
                //     >
                //       {isRenamingLoading ? <CircularProgress size={20} /> : <EastOutlinedIcon sx={{ color: '#3976EF' }} fontSize="small" />}
                //     </IconButton>
                //   </InputAdornment>
                //   ),
                // }}
              />
              <Divider sx={{ margin: '20px 0 0' }} />
            </DialogContent>
            <DialogActions sx={{ padding: '0 24px 10px', flexWrap: 'wrap' }}>
              <Box sx={{ width: '100%' }}>
                {title !== selectedChat.name ? (
                  <LoadingButton
                    variant="contained"
                    sx={{ background: '#3976EF' }}
                    fullWidth
                    loading={isRenamingLoading}
                    onClick={() => handleRenameChat(selectedChat.id, title, () => setIsDialogOpen(false))}
                  >
                    Update chat
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    color="error"
                    fullWidth
                    loading={isDeleteLoading}
                    sx={{ marginLeft: 0 }}
                    onClick={() => {
                      handleDeleteChat(selectedChat.id, () => setIsDialogOpen(false));
                    }}
                  >
                    Delete chat
                  </LoadingButton>
                )}
                <Button
                  variant="outlined"
                  fullWidth
                  loading={isDeleteLoading}
                  sx={{ marginTop: '10px' }}
                  onClick={() => setIsDialogOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </AccessChecker>      
    </>
  );
};

export default memo(ChatMenu);
