import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    model: null,
    sharedMessages: [],
    rabbitMessages: [],
    info: {
        chat_id: null,
        guest_id: null,
        modelName: null,
        modelWebsite: null,
    },
    ownerInfo: null,
    lastResponseSources: {
        messageId: 0,
        list: {}
    },
    loading: false,
    errors: [],
    session: {
        loading: {
            stage: 1,
            isMsgSending: false,
            tick: 0
        },
        tools: {
            call: '',
            stack: {}

        },
        shared: false,
        rabbit: false,
    },
};

export const sharedMessagesSlice = createSlice({
    name: "sharedMessages",
    initialState,
    reducers: {

        addToolCallToStack(state, action) {
            let tool = action.payload;
            const count = state.session.tools.stack[tool] || 0;
            state.session.tools.stack[tool] = count + 1;
        },
        popFromToolCallStack(state, action) {
            let tool = action.payload;
            const count = state.session.tools.stack[tool];

            if (!count) {
                return;
            }

            if(count - 1 === 0) {
                delete state.session.tools.stack[tool]
                return
            }

            state.session.tools.stack[tool] = count - 1;
        },
        cleanStack(state) {
            state.session.tools.stack = {}
        },

        updateToolCallStatus(state, action) {
            let tool = action.payload;
            state.session.tools.call = tool;
        },
        brainTickUpdate(state) {
            state.session.loading.tick += 1
        },

        setMessageLoading(state, action) {
            let {loading, stage} = action.payload;

            if (typeof loading !== 'undefined') {
                state.session.loading.isMsgSending = loading;
            }

            if (typeof stage !== 'undefined') {
                state.session.loading.stage = stage;
            }

        },
        addMessages(state, action) {
            const {messages, type} = action.payload;
            state[`${type}Messages`] = messages;
        },
        addTempMessage(state, action) {
            const {type, refresh} = action.payload;

            if (refresh) {
                state.session[type] = false;
                return;
            }

            const {text} = action.payload;

            if (state.session[type]) {
                state.session[type] += text;
            } else {
                state.session[type] = text;
            }
        },
        addMessage(state, action) {
            const {message, type} = action.payload;
            state[`${type}Messages`].push(message);
        },

        deleteAllShared(state) {
            state.sharedMessages = [];
        },

        editMessage(state, action) {
            const updatedArray = state.list.map(msg => {
                if (msg.id === action.payload.id) {

                    return {
                        ...msg,
                        message: action.payload.message
                    }
                }
                return msg;
            })

            state.list = updatedArray;
        },
        setLastResponseSources(state, action) {
            const {messageId, sources} = action.payload;
            state.lastResponseSources = {messageId: messageId, list: sources};
        },
    },
    extraReducers: (builder) => {
    }
});

export const {
    addMessages,
    addMessage,
    deleteAllShared,
    addTempMessage,
    setLastResponseSources,
    brainTickUpdate,
    setMessageLoading,
    updateToolCallStatus,
    addToolCallToStack,
    popFromToolCallStack,
    cleanStack
} = sharedMessagesSlice.actions;

export default sharedMessagesSlice.reducer;
