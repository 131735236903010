import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {memo, useEffect, useMemo, useRef, useState} from "react";
import {updateModel} from "redux/models";
import controlledAPI from "_services/controlledApi";
import {IconButton, TextField, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import {isInputValid} from "utils/validateInputs";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

const WebsiteDomain = () => {
    const model = useSelector(state => state.models?.current, shallowEqual);
    const savedWebsite = useSelector(state => state.models?.current?.data?.website);
    const websiteDomainRef = useRef(null);
    const dispatch = useDispatch();

    const [websiteEditMode, setWebsiteEditMode] = useState({
        on: false,
        loading: false,
        isError: false,
        status: '',
        text: savedWebsite,
        savedText: savedWebsite
    });

    const errorHandleApi = () => {
        setWebsiteEditMode({on: false, loading: false});
    }

    const handleSuccessWebsiteDomainSave = (res) => {

        if (res.code === 200) {

            const updatedModel = {
                ...model,
                data: {
                    ...model.data,
                    website: websiteEditMode.text,
                },
            };

            dispatch(updateModel({model: updatedModel}));

        }

        setWebsiteEditMode({on: false, loading: false});
    }


    const onDomainChange = (e) => {
        e.preventDefault();

        const value = e.target.value;
        const domainStatus = isInputValid(value, {type: 'website', isRequired: true});


        setWebsiteEditMode(prev => {

            let editMode = true;
            if (value === websiteEditMode.savedText) {
                editMode = false;
            }

            let errorStatus = {
                isError: false,
                status: ''
            }
            if (domainStatus.error) {
                errorStatus = {
                    isError: true,
                    status: domainStatus.error
                }
            } else if(editMode) {
                errorStatus.status = 'ready to save';
            }

            return {...prev, on: editMode, isError: errorStatus.isError, status: errorStatus.status, text: value}
        });

    }

    const saveWebsiteDomain = async (e) => {
        e.preventDefault();

        const value = websiteEditMode.text

        setWebsiteEditMode({on: false, loading: true});

        const data = {
            name: model.name,
            data: {...model.data, website: value},
        };

        await controlledAPI({ignoreUser: true}).put(`/model/${model.id}`, data).then(handleSuccessWebsiteDomainSave).catch(errorHandleApi);
    }

    // const websiteStatus = useMemo(() => {
    //     return isInputValid(savedWebsite || '', {type: 'website', isRequired: true});
    // }, [savedWebsite])


    // initial domain check
    useEffect(() => {
        const domainStatus = isInputValid(savedWebsite, {type: 'website', isRequired: true});

        setWebsiteEditMode(prev => {

            let errorStatus = {
                isError: false,
                status: ''
            }
            if (domainStatus.error) {
                errorStatus = {
                    isError: true,
                    status: domainStatus.error
                }
            }

            return {...prev, isError: errorStatus.isError, status: errorStatus.status}
        });

    }, []);


    return (
        <TextField
            inputRef={websiteDomainRef}
            variant="filled"
            placeholder={'Add your website domain (Ex: https://younet.ai)'}
            sx={{opacity: websiteEditMode.on ? 1 : 0.6}}
            defaultValue={savedWebsite || ''}
            onChange={onDomainChange}
            fullWidth
            label="Website Domain"
            helperText={websiteEditMode.status || ''}
            error={websiteEditMode.isError || false}
            required
            FormHelperTextProps={{ style: { margin: '4px 0 0' } }}
            InputProps={{

                style: {paddingRight: "5px"},
                readOnly: !websiteEditMode.on && false,
                endAdornment: (
                    websiteEditMode.loading ?
                        <CircularProgress size={20} sx={{marginRight: '5px'}}/>
                        :
                        websiteEditMode.on && !websiteEditMode.isError &&
                        <Tooltip title="Save website domain">
                            <IconButton
                                size="small"
                                onClick={saveWebsiteDomain}
                            >
                                <EastOutlinedIcon sx={{color: '#3976EF'}} fontSize="small"/>
                            </IconButton>
                        </Tooltip>

                )
            }}

        />
    )
}


export default memo(WebsiteDomain);