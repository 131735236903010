import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {alpha, Box} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {styled} from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import {useState} from "react";


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        {...props}
    />
))(({theme}) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export const TrainBtn = ({handleTrain}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleTrainClick = (e) => {
        e.preventDefault();
        handleTrain(true);
    }

    const handleDisapproveClick = (e) => {
        e.preventDefault();
        handleTrain(false);
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        e.preventDefault();
        setAnchorEl(null);
    };


    return (
        <Box>
            <Button
                size="small"
                aria-label="display more links menu bar"
                variant="outlined"
                sx={{ml: '15px'}}
                onClick={handleMenuOpen}
            >
                Action
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={(e) => {
                        handleTrainClick(e);
                        handleClose(e);
                    }}
                    disableRipple
                >
                    Add to Brain
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        handleDisapproveClick(e);
                        handleClose(e);
                    }}
                    disableRipple
                >
                    Remove from Brain
                </MenuItem>
            </StyledMenu>
        </Box>
    )
    return (
        <Button onClick={handleTrainClick} size="small" variant="outlined"
                sx={{fontSize: '11px', textTransform: 'unset', padding: '6px 9px'}}>Add selected pages to brain</Button>
    )
}
