import { useState } from 'react';
import api from '_services/api';
import { useDispatch } from 'react-redux';
import { deleteGalleryItem } from 'redux/marketplace';

const useDeleteGalleryItem = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteGalleryItem = (id, cardId) => {
    setIsLoading(true);
    
    api
      .delete(`/marketplace/card/gallery/${id}`)
      .then((res) => {
        if (res.code >= 400 || res.status >= 400) {
          setIsLoading(false);
        }

        if (res?.code === 200) {
          dispatch(deleteGalleryItem({ id, cardId }));
          
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return { handleDeleteGalleryItem, isLoading };
};

export default useDeleteGalleryItem;
