import { useRef, useMemo, useState } from "react";
import { Box, Grid, Avatar, Link } from "@mui/material";
import LogoSvgIcon from "components/logo/LogoSvgIcon";
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import useViewportHeight from "hooks/useViewportHeight";
import { getInitials } from "utils/prepareNames";
import { useTheme } from '@mui/material/styles';
import UserMenuButton from "./components/UserMenuButton";
import Content from './components/Content';
import "slick-carousel/slick/slick.css";

const ShareModelMarketplace = () => {
  const [chatNotExist, setChatNotExist] = useState(false);
  const viewportHeight = useViewportHeight();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const topContainerRef = useRef(null);

  const auth = useSelector((state) => state.auth);
  const userInfo = useMemo(()=>{
    let userInfo = {fullName:'',email:'',avatar:'',initial:''}
    if(auth?.user?.id){
      userInfo.fullName = auth.user.fullName;
      userInfo.email = auth.user.email;
      userInfo.avatar = auth.user.avatar;
      userInfo.initial = getInitials(auth.user.fullName);
    }
    return userInfo;
  },[auth.user]);

  // if (chatNotExist) {
  //   return (
  //     <h3>Chat Not Exist</h3>
  //   )
  // }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        height={`calc(${viewportHeight}px)`}
        overflow="hidden"
        sx={{ backgroundColor: { xs: "white" } }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            height="100%"
            sx={{
              position: 'relative',
              overflowY: "auto",
              borderRight: {
                xs: "none",
                md: "0.5px solid rgba(0, 0, 0, 0.12)",
              },
              display: 'flex',
              height: '100vh',
              flexDirection: 'column',
            }}
          >
            {/* Top Nav */}
            <Grid
              sx={{ paddingTop: { xs: '5px', md: '20px' }, background: '#fff', position: 'relative', zIndex: 1 }}
              ref={topContainerRef}
            >
              <Box display="flex"
                justifyContent="center"
                mb={2}
                sx={{ borderBottom: "0.5px solid rgba(0, 0, 0, 0.12)", paddingBottom: { xs: '5px', md: '20px' } }}>
                  <Grid container px='15px'>
                    <Grid item xs={6} display='flex' justifyContent='start' alignItems='center'>
                      <Link href="/" sx={{ lineHeight: 0 }}>
                        <LogoSvgIcon width={isMobile ? 30 : 40}/>
                      </Link>
                    </Grid>
                    <Grid item xs={6} display='flex' justifyContent='end' alignItems='center'>
                    {auth.user ? (
                      <Box display='flex' alignItems='center'>
                        <Avatar  sx={{width:{xs: '24px', md: '40px'}, fontSize: {xs: '0.875rem', md: '1.25rem'},height:{xs: '24px', md: '40px'},border:'1px solid #0000 0011'}} src={userInfo.avatar ?? ''}>{userInfo.initial}</Avatar>
                        <UserMenuButton userInfo={userInfo}/>
                      </Box>
                    ) : (
                      <Box display='flex' alignItems='center' sx={{ fontSize: {xs: '0.875rem', md: '1rem'} }}>
                        <Link href="/portal/login">Login</Link>
                      </Box>
                    )}
                    </Grid>
                  </Grid>
              </Box>
            </Grid>

            {/* Content Block */}
            <Grid container px={4} sx={{ maxWidth: '2200px', margin: '0 auto' }}>
              <Grid xs={12} md={1} lg={2} item />
              <Grid xs={12} md={10} lg={8} item>
                <Content />
              </Grid>
            </Grid> 
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ShareModelMarketplace;
