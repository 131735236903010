import {useEffect, useState, useRef} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Typography, Button, Box, Paper, Chip, IconButton} from "@mui/material";
import formatFileSizes from "utils/formatFileSizes";
import {useDispatch} from "react-redux";
import {interactWithUser} from "redux/status";
import classnames from 'classnames';
import styles from "./file-input.module.css";
import {sendUIEvent} from "../../../events/analytics/google";

const FileInput = ({
  resetCounter = 0,
  supportedFormats,
  selectedTab,
  inputRef,
  onChange = () => {},
  setSpreadsheetsTab,
  isShort = true,
  clearFile = false,
  fileMaxSize = 100,
  isArray = false,
  error = false,
  setPreviews,
}) => {
    const [file, setFile] = useState(null);
    const [filesPreview, setFilesPreview] = useState([]);
    const dispatch = useDispatch();
    const formatsText = supportedFormats[selectedTab].formats.join(', ');
    const allowedFormats = supportedFormats[selectedTab].formats;
    const fileRef = useRef(null);

    useEffect(() => {
        setFile(null);
        onChange(null);
        fileRef.current.value = '';
    }, [selectedTab]);

    // Custom setFile function
    const getSavedFile = (newFile) => {
        if (newFile) {
            const fileExtension = `.${newFile.name.split(".").pop()}`;
            // TODO: delete this "if" part when chrome is fixed (txt and cvs issue)
            if (selectedTab === 0 && fileExtension === '.csv') {
                setSpreadsheetsTab();
                setTimeout(() => {
                    setFile(newFile);
                    sendUIEvent({name: `file_tool_uploaded_file`});
                    onChange(newFile);
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(newFile);
                    fileRef.current.files = dataTransfer.files;
                }, 100);

            } else if (supportedFormats[selectedTab].formats.includes(fileExtension)) {
                setFile(newFile);
                onChange(newFile);
                sendUIEvent({name: `file_tool_uploaded_file`});
            } else {
                dispatch(interactWithUser({message: "File format not supported", type: "error"}));
            }
        }
    }

    // const isAllowedFormat = (filesExtensions) => {
    //   allowedFormats.some(item1 => filesExtensions.some(item2 => item1?.includes(item2)))
    // }

    function isAllowedFormat(array1, array2) {
        return array2.every(item1 => array1.some(item2 => item1?.includes(item2)));
    }

    const getSavedFiles = (newFiles) => {
        if (Boolean(newFiles?.length)) {
            const filesExtensions = newFiles.map(item => `.${item.name.split(".").pop()}`);
            if (isAllowedFormat(allowedFormats, filesExtensions)) {
                setFile(file ? [...file, ...newFiles] : newFiles);
                onChange(file ? [...file, ...newFiles] : newFiles);
            } else {
                dispatch(interactWithUser({message: "File format not supported", type: "error"}));
            }
        }
    }

    let savedCounter = 0;

    const handleChange = (e) => {
        const fileObject = e.target.files[0];
        const files = e.target.files;
        const filesArray = Array.from(files);

        if (filesArray.length && isArray) {
            getSavedFiles(filesArray);
            if (isAllowedFormat(allowedFormats, filesArray.map(item => `.${item.name.split(".").pop()}`))) {
                filesArray.forEach(fileObj => {
                    const reader = new FileReader();

                    reader.onload = () => {
                        if (reader.result) {
                            setFilesPreview(prevFilesPreview => [...prevFilesPreview, reader.result]);
                        }
                    };
                    reader.readAsDataURL(fileObj);
                });
            }
        }
        if (fileObject && !isArray) {
            getSavedFile(fileObject);
        }
    };

    useEffect(() => {
      if (Boolean(setPreviews)) {
        setPreviews(filesPreview)
      }
    }, [filesPreview]);

    const handleClose = (e, index) => {
        e.preventDefault();

        if (Array.isArray(file)) {
            const filteredFiles = file.filter((_, i) => i !== index);
            const filteredPreviews = filesPreview.filter((_, i) => i !== index);
            const updatedFiles = filteredFiles.length ? filteredFiles : null;
            const updatedPreviews = filteredPreviews.length ? filteredPreviews : null;
            setFile(updatedFiles);
            setFilesPreview(updatedPreviews);
            onChange(updatedFiles);
        } else {
            setFile(null);
            onChange(null);
            setFilesPreview(null);
        }
    };

    useEffect(() => {
        if (resetCounter != savedCounter) {
            savedCounter = resetCounter;
            setFile(null);
        }
    }, [resetCounter]);

    useEffect(() => {
        if (clearFile) {
            savedCounter = 0;
            setFile(null);
        }
    }, [clearFile]);

    return (
        <>
            <label className={classnames(styles["file-label"], isShort && styles.short, error && styles.error)}>
                {file ? (
                    Array.isArray(file) ? (
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                            {
                                file.map((item, i) => {
                                    return (
                                        <Paper key={`file-chip-${i}`} elevation={3} sx={{
                                            position: 'relative',
                                            maxWidth: '200px',
                                            padding: '10px',
                                            margin: '5px'
                                        }}>
                                            <IconButton onClick={event => handleClose(event, i)}
                                                        sx={{zIndex: 1, left: '2px', top: '2px', position: 'absolute'}}>
                                                <CloseIcon/>
                                            </IconButton>
                                            <Box sx={{
                                                height: '100px',
                                                position: 'relative',
                                                background: `url(${filesPreview[i]}) no-repeat`,
                                                backgroundSize: 'cover'
                                            }}></Box>
                                            <Typography
                                                variant="span">Size: {formatFileSizes(item.size)}</Typography><br/>
                                            <Typography variant="span">Name: {item.name}</Typography><br/>

                                            {/* <Chip
                        key={`file-chip-${i}`}
                        label={item?.name}
                        className={styles['file-name']}
                        onDelete={event => handleClose(event, i)}
                        deleteIcon={<CloseIcon />}
                        variant="outlined"
                      /> */}
                                        </Paper>
                                    )
                                })
                            }
                        </Box>
                    ) : (
                        <>
                            <Typography variant="span">Type: {file.type}</Typography><br/>
                            <Typography variant="span">Size: {formatFileSizes(file.size)}</Typography><br/>
                            <Chip
                                label={file.name}
                                className={styles['file-name']}
                                onDelete={handleClose}
                                deleteIcon={<CloseIcon/>}
                                variant="outlined"
                            />
                        </>
                    )
                ) : (
                    <Typography variant="span"><Typography variant="span">
                        {isArray ? 'Drop file(s)' : 'Drop a file'} </Typography><br/>or <br/>
                        <Button size="small" variant="text">Click Upload</Button>
                        <hr></hr>
                        Allowed formats<br/>
                        {formatsText}
                        <br/>{`Max size: ${fileMaxSize}MB`}
                        {isArray && (
                            <>
                                <br/><Typography variant="span">Recommended size ratio: 2x1 (Example:
                                1200/600)</Typography>
                            </>
                        )}
                    </Typography>
                )}
                <input
                    name="file"
                    multiple={isArray}
                    className={styles["file-input"]}
                    ref={fileRef}
                    accept={formatsText}
                    type="file"
                    onChange={handleChange}
                />
            </label>
        </>
    );
};

export default FileInput;
