import classnames from 'classnames';
import styles from './stripe-status.module.css';
import { memo } from 'react';


const StripeStatus = ({ status }) => {
    const getStatusStyle = () => {
        if (status === 'draft' || status === 'draft') {
            return styles.statusWarning;
        } else if (status === 'refunded') {
            return styles.statusGrey;
        } else {
            return styles.statusGreen;
        }
    };

    return <span className={classnames(getStatusStyle(), styles.status)}>{status}</span>;
};


export default memo(StripeStatus);