import { memo } from 'react';
import { Box } from '@mui/material';
import classnames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import styles from './invoices-table.module.css';
import InvoiceHeader from './components/InvoiceHeader';
import { InvoiceItems, InvoicesSmallItems, LoadingItems, LoadingSmallItems, NoInvoices } from './components/InvoiceViews';


const InvoicesTable = () => {

  const { smallScreen } = useIsSmallScreen();
  const { list, loading } = useSelector((state) => state.monetization.invoices, shallowEqual);

  if (!list.length && !loading) {
    return <NoInvoices />;
  }

  if (smallScreen) {
    return loading ? <LoadingSmallItems /> : <InvoicesSmallItems list={list} />
  }

  return (
    <Box className={classnames(styles.wrapper, smallScreen && styles.mobileWrapper)}>
      <InvoiceHeader />
      { loading ? <LoadingItems /> : <InvoiceItems list={list} />}
    </Box>
  );
};

export default memo(InvoicesTable);


