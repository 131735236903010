import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { checkFormForErrors, isInputValid } from "utils/validateInputs";
import Box from "@mui/material/Box";
import api from "_services/api";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getToken, setToken } from "_helpers/tokenManagement";
import { signup } from "redux/auth";
import GoogleAuthBtn from "components/auth/GoogleAuthBtn";
import FacebookAuthBtn from "components/auth/FacebookAuthBtn";
import countries from "mappings/countries.json";
import dropdowns from "mappings/signInDropDowns.json";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useDeviceDetect from "hooks/useDetectDevice";
import { useLocation } from "react-router-dom";

const UserForm = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.signup.loading);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const guestId = searchParams.get("guestId");

  const googleOptions = {
    method: "post",
    url: "google/oauth2/url",
  };

  const [country, setCountry] = useState('');
  const [profession, setProfession] = useState('');
  const [intention, setIntension] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isInAppView } = useDeviceDetect();

  const [userState, setUserState] = useState({
    firstName: {
      errMsg: "",
      isRequired: true,
    },
    lastName: {
      errMsg: "",
      isRequired: true,
    },
    email: {
      errMsg: "",
      isRequired: true,
    },
    // phone: {
    //   errMsg: "",
    //   isRequired: false,
    // },
    country: {
      errMsg: "",
      isRequired: true,
    },
    profession: {
      errMsg: "",
      isRequired: true,
    },
    intention: {
      errMsg: "",
      isRequired: true,
    },
    birthday: {
      errMsg: "",
      isRequired: true,
      additionals: {
        minAllowedAge: 18
      }
    },
    // address: {
    //   errMsg: "",
    //   isRequired: false,
    // },
    referenceSource: {
      errMsg: "",
      isRequired: true,
    },
    waitingListJoinReason: {
      errMsg: "",
      isRequired: true,
    },
    password: {
      errMsg: "",
      isRequired: true,
    },
    passwordCopy: {
      errMsg: "",
      isRequired: true,
    },
  });

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  }
  const handleProfessionChange = (e) => {
    setProfession(e.target.value);
  }
  const handleIntentionChange = (e) => {
    setIntension(e.target.value);
  }

  const saveUser = (data) => {
    dispatch(signup(data));
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);

    let status = checkFormForErrors(form, userState);

    setUserState((prevState) => ({
      ...prevState,
      ...status.updates,
    }));

    if (status.hasErrors) {
      return;
    }

    let passwordCopy = form.get("passwordCopy");
    let password = form.get("password");

    if (passwordCopy != password) {
      status.updates.passwordCopy = {
        isRequired: true,
        errMsg: "Passwords must be identical..",
      };
      status.hasErrors = true;
    }

    setUserState((prevState) => ({
      ...prevState,
      ...status.updates,
    }));

    if (!status.hasErrors) {
      let firstName = form.get("firstName");
      let lastName = form.get("lastName");
      let fullName = firstName + " " + lastName;
      let email = form.get("email");
      let profession = form.get("profession");
      const professionItem = dropdowns.profession.find(item => item.value === profession);
      let intention = form.get("intention");
      const intentionItem = dropdowns.intention.find(item => item.value === intention);
      let country = form.get("country");

      let referrer = getToken('referrer');
      let userAgent = getToken('userAgent');

      if (!referrer?.length) {
        setToken('referrer', document.referrer);
        referrer = document.referrer;
      }
      if (!userAgent) {
        setToken('userAgent', navigator?.userAgent);
        userAgent = navigator?.userAgent;
      }

      let prepareValues = {
        fullName: fullName,
        email: email,
        country: country,
        password: password,
        passwordCopy: passwordCopy,
        referenceSource: referrer,
        waitingListJoinReason: userAgent,
      };

      if (guestId) {
        prepareValues.guestId = guestId
      }

      saveUser(prepareValues);
    }
  };

  return (
    <>
      <Grid
        component="form"
        noValidate
        onSubmit={handleUserSubmit}
        container
        spacing={6}
      >
        {!isInAppView && (
          <Grid item xs={12}>
            <GoogleAuthBtn
              googleOptions={googleOptions}
              redirectUrl={window.location.origin + "/portal/google/login"}
              text={"Sign up with Google"}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FacebookAuthBtn
            redirectUrl={window.location.origin + "/portal/facebook/login/"}
            text={"Sign up with Facebook"}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider>or</Divider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="first-name"
            name="firstName"
            label="First Name"
            fullWidth
            error={userState.firstName.errMsg != ""}
            helperText={
              userState.firstName.errMsg ? userState.firstName.errMsg : ""
            }
            autoComplete="first-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            fullWidth
            autoComplete="family-name"
            error={userState.lastName.errMsg != ""}
            helperText={
              userState.lastName.errMsg ? userState.lastName.errMsg : ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            error={userState.email.errMsg != ""}
            helperText={userState.email.errMsg ? userState.email.errMsg : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="password"
            name="password"
            label="Password"
            type="password"
            fullWidth
            error={userState.password.errMsg != ""}
            helperText={
              userState.password.errMsg ? userState.password.errMsg : ""
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            id="passwordCopy"
            name="passwordCopy"
            label="Confirm Password"
            type="password"
            fullWidth
            error={userState.passwordCopy.errMsg != ""}
            helperText={
              userState.passwordCopy.errMsg ? userState.passwordCopy.errMsg : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
          >
            <InputLabel>Country*</InputLabel>
            <Select
              data-testid="country-sign-up-selector"
              fullWidth
              name="country"
              value={country}
              label="Country"
              onChange={handleCountryChange}
              error={userState.country.errMsg != ""}
            >
              <MenuItem value="">Select a country</MenuItem>
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {userState.country.errMsg ? userState.country.errMsg : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            fullWidth
            autoComplete="phone"
            variant="outlined"
            error={userState.phone.errMsg != ""}
            helperText={userState.phone.errMsg ? userState.phone.errMsg : ""}
          />
        </Grid> */}

        {/* <Grid item xs={12}>
          <TextField
            id="address"
            name="address"
            label="Address"
            fullWidth
            autoComplete="shipping address"
            variant="outlined"
            error={userState.address.errMsg != ""}
            helperText={
              userState.address.errMsg ? userState.address.errMsg : ""
            }
          />
        </Grid> */}
        {/* <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12}> */}
          {/* <TextField
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="country"
            variant="outlined"
            // error={userState.country.errMsg != ""}
            // helperText={
            //   userState.country.errMsg ? userState.country.errMsg : ""
            // }
          /> */}

          {/* <FormControl
            fullWidth
          >
            <InputLabel>Profession*</InputLabel>
            <Select
              fullWidth
              name="profession"
              value={profession}
              label="Profession"
              onChange={handleProfessionChange}
              error={userState.profession.errMsg != ""}
            >
              <MenuItem value="">Select a profession</MenuItem>
              {dropdowns.profession.map((profession, index) => (
                <MenuItem key={index} value={profession.value}>
                  {profession.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {userState.profession.errMsg ? userState.profession.errMsg : ""}
            </FormHelperText>
          </FormControl>
        </Grid> */}

        {/* <Grid item xs={12} md={12}>
          <FormControl
            fullWidth
          >
            <InputLabel >Why I want to use Younet AI:</InputLabel>
            <Select
              fullWidth
              name="intention"
              value={intention}
              label="Why I want to use Younet AI?*"
              onChange={handleIntentionChange}
              error={userState.intention.errMsg != ""}
            >
              <MenuItem value="">Select a intention</MenuItem>
              {dropdowns.intention.map((intention, index) => (
                <MenuItem key={index} value={intention.value}>
                  {intention.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {userState.intention.errMsg ? userState.intention.errMsg : ""}
            </FormHelperText>
          </FormControl>
        </Grid> */}

        {/* 
        <Grid item xs={12}>
          <TextField
            id="referenceSource"
            name="referenceSource"
            label="Where are you from?*"
            fullWidth
            variant="outlined"
            error={userState.referenceSource.errMsg != ""}
            helperText={
              userState.referenceSource.errMsg
                ? userState.referenceSource.errMsg
                : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="waitingListJoinReason"
            name="waitingListJoinReason"
            label="Why do you want joint to waitlist?*"
            fullWidth
            variant="outlined"
            error={userState.waitingListJoinReason.errMsg != ""}
            helperText={
              userState.waitingListJoinReason.errMsg
                ? userState.waitingListJoinReason.errMsg
                : ""
            }
          />
        </Grid> */}
        <Grid item display="flex" justifyContent="flex-end" xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ mt: 3, ml: 1, borderRadius: "5px", width: "100%" }}
            loading={isLoading}
          >
            Sign Up
          </LoadingButton>
        </Grid>

      </Grid>
    </>
  );
};

export default UserForm;