import React, { useState } from 'react';
import { Grid, Typography, FormControl, TextField, Button, FormHelperText, Divider } from '@mui/material';
import api from "_services/api";
import { useSelector } from "react-redux";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid sx={{ p: 3 }}>
          {children}
        </Grid>
      )}
    </div>
  );
};

const SecuritySetting = ({ selectedTag }) => {
  const { auth } = useSelector((state) => ({
      auth: state.auth,
  }));
  const user = auth.user;

  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const handleSecuritySave = async (e) => {
    e.preventDefault();

    let isValid = true;
    const updatedErrors = { ...passwordError };

    if (passwords.newPassword !== passwords.confirmPassword) {
      updatedErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    } else {
      updatedErrors.confirmPassword = '';
    }

    setPasswordError(updatedErrors);

    if (isValid) {
      try {
        const response = await api.put(`/users/${user.id}`, {
          email: user.email,
          password: passwords.newPassword,
        });

        if (response.status === 200) {
          setPasswords({ newPassword: '', confirmPassword: '' });
        }
      } catch (err) {
        // Handle error updating password
        console.error('Error updating password:', err);
      }
    }
  };

  const handlePasswordChange = (e) => {
    setPasswords((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <CustomTabPanel value={selectedTag} index={2}>
      <Grid container px={6} py={2} component="form" noValidate onSubmit={handleSecuritySave}>
        <Grid item xs={12} md={12} px={4} py={2}>
          <Typography variant="h6">Change Your Password:</Typography>
          <Typography variant="body1">New password needs to be different from the old password.</Typography>
        </Grid>
        <Grid item xs={12} md={2} px={4} py={2} sx={{ display: 'flex', alignItems: 'center' }}>
          New Password
        </Grid>
        <Grid item xs={12} md={10} px={4} py={2}>
          <FormControl  error={passwordError.newPassword !== ''}>
            <TextField
              id="newPassword"
              name="newPassword"
              variant="outlined"
              type="password"
              required
              value={passwords.newPassword}
              onChange={handlePasswordChange}
              fullWidth
            />
            {passwordError.newPassword && <FormHelperText>{passwordError.newPassword}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} px={4} py={2} sx={{ display: 'flex', alignItems: 'center' }}>
          Confirm Password
        </Grid>
        <Grid item xs={12} md={10} px={4} py={2}>
          <FormControl  error={passwordError.confirmPassword !== ''}>
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              variant="outlined"
              type="password"
              required
              value={passwords.confirmPassword}
              onChange={handlePasswordChange}
              fullWidth
            />
            {passwordError.confirmPassword && <FormHelperText>{passwordError.confirmPassword}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} px={2}>
          <Button type="submit" variant="contained" sx={{ width: '150px', margin: '30px 0px' }}>
            Save
          </Button>
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

export default SecuritySetting;
