const brainToolsLabels = {
    "description": "For multilingual purposes, add more standardized labels here",
    "labels": {
        "navigationMenuItems": [
            {
                label: 'All',
                value: 'brain',
                path: 'training/brain'
            },
            {
              label: 'Website',
              value: 'website',
              path: 'training/website',
              // badge: 'beta'
            },
            {
                label: 'Text',
                value: 'text',
                path: 'training/text'
            },
            {
                label: 'Q&A',
                value: 'q&a',
                path: 'training/q&a'
            },
            {
                label: 'Email',
                value: 'email',
                path: 'training/email'
            },
            {
                label: 'Files',
                value: 'files',
                path: 'training/files'
            },
        ],
        "brain": {
            "title": "All brain Knowledge",
            "description": "The Brain Knowledge feature on the Younet AI platform is designed to show all data knowledge inputs for the selected model."
        },
        "text": {
            "title": "Text Knowledge",
            "description": <>
                Text data for AI models is the textual content used to train AI algorithms. It includes documents,
                social
                media posts, reviews, emails, chat logs, and more. This data helps AI models understand, analyze, and
                generate human-like responses, automate tasks, and make informed decisions.
                &nbsp;
                <a href='https://support.younet.ai/article/25-How-to-train-AI-model-on-the-Text-Data'
                   style={{color: '#3976ef', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
                    Learn More
                </a>
            </>
        },
        "q&a": {
            "title": "Q&A Knowledge",
            "description": <>
                Curated question and answer pairs used to train AI models, enabling accurate responses, personalized
                interactions, and improved user experiences. All questions and responses that are being selected to be
                trained are stored here.
                &nbsp;
                <a href='https://support.younet.ai/article/23-How-to-train-AI-model-with-Questions-and-Answers-QA'
                   style={{color: '#3976ef', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
                    Learn More
                </a>
            </>
        },
        "email": {
            "title": "Email Knowledge",
            "description": <>
                Email conversations are utilized to train AI models in understanding context, extracting information,
                and providing accurate responses. Through analyzing email conversations, the AI model learns the writing
                style and gains insights into your interactions with others. This enables the AI model to better
                understand your communication patterns and deliver more personalized and effective responses.
                &nbsp;
                <a href='https://support.younet.ai/article/21-How-to-connect-Gmail-to-Younet-and-train-AI-model-with-email-conversations'
                   style={{color: '#3976ef', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
                    Learn More
                </a>
            </>

        },
        "files": {
            "title": "Files Knowledge",
            "description": <>
                The Files data knowledge for AI model on Younet allows you to upload and manage files that can be used
                to train and enhance your AI models. Easily organize and access your data to improve the accuracy and
                performance of your AI models.
                &nbsp;
                <a href='https://support.younet.ai/article/22-How-to-train-an-AI-model-using-text-document'
                   style={{color: '#3976ef', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
                    Learn More
                </a>
            </>
        },
        "website": {
            "title": "Links Knowledge",
            "description": <>
                Select 'Single Page' to extract content from a specific page or 'Website' to crawl and parse multiple pages from a website.
            </>
        }
    }
}


export default brainToolsLabels;