import { useState, useEffect, memo, useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Divider, Skeleton, Typography } from "@mui/material";

const PopupMenu = ({
  title = "",
  menuItems = [],
  children,
  onOpen = (e) => {},
  loading = false
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    onOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box onClick={handleClick}>{children}</Box>
      
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} >
        
        {title && (
          <Typography px={5} py={2} textAlign="center">
            {title}
          </Typography>
        )}
        <Divider  sx={{ width: "100%", margin: "0 auto 20px" }}/>
        {loading ? (
          <Box>
            <Typography variant="">
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "90%", margin: "auto", padding: "4px 16px" }}
              />
            </Typography>
            <Typography variant="">
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "90%", margin: "auto", padding: "4px 16px" }}
              />
            </Typography>
            <Typography variant="">
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "90%", margin: "auto", padding: "4px 16px" }}
              />
            </Typography>
            <Typography variant="">
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "90%", margin: "auto", padding: "4px 16px" }}
              />
            </Typography>
          </Box>
        ) : (
          menuItems.map((item, index) => (
            item.element ? (
             <Box key={index}>{item.element}</Box>
            ) : (
              <MenuItem
              sx={{ fontSize: 16, padding: "10px 20px" }}
              key={index}
              onClick={(e) => {
                e.preventDefault();
                if (typeof item == "function") {
                  item.onClick();
                }
                handleClose();
              }}
            >
              {item.name && (
                <Typography variant="list_header">{item.name}</Typography>
              )}
              {/* {item.element && (
                <Typography variant="list_header"> {item.element}</Typography>
              )} */}
            </MenuItem>
            )

          ))
        )}
      </Menu>
    </>
  );
};

export default memo(PopupMenu, (prevProps, nextProps) => {
  return prevProps?.menuItems?.length === nextProps?.menuItems?.length && prevProps.modelId === nextProps.modelId;
});

// export default memo(PopupMenu);
