import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from "_services/api";
import { renameChat } from 'redux/chats';

const useRenameChat = () => {
  const [isRenamingLoading, setIsRenamingLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRenameChat = (chatId, name, finallyCallback = false) => {
    setIsRenamingLoading(true);
    api
      .put(`/chat/${chatId}`, { name })
      .then(() => {
        dispatch(renameChat({ chatId, name }));
        setIsRenamingLoading(false);
      })
      .catch((error) => {
        setIsRenamingLoading(false);
      })
      .finally(() => {
        if (finallyCallback) finallyCallback();
      });
  };

  return { handleRenameChat, isRenamingLoading };
};

export default useRenameChat;
