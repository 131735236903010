import {Autocomplete, InputAdornment, InputBase, ListItem, ListItemButton} from "@mui/material";
import {shallowEqual, useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {styled} from "@mui/material/styles";
import FlexWrapper from "components/wrappers/FlexWrapper";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {useNavigate} from "react-router-dom";

const StyledAutocomplete = styled(Autocomplete)`
    .MuiOutlinedInput-input {
    }
`
const StyledInputBase = styled(TextField)(({theme, focused}) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-input': {
        backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        fontSize: '13px',
    },

    color: 'inherit',
    width: focused ? '200px' : '0',
    maxWidth: focused ? '200px' : '0',
    transition: theme.transitions.create('width'),
    '& .MuiTextField-root': {

        // padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        // '&:focus': {
        //     width: '20ch',
        // },
        // [theme.breakpoints.up('sm')]: {
        //     // width: '12ch',
        //     '&:focus': {
        //         width: '20ch',
        //     },
        // },
    },
}));

const SearchSort = ({}) => {

    const sortId = useSelector(state => state.links.content.sort.current.id)
    const sortCategory = useSelector(state => state.links.content.list[sortId], shallowEqual);
    const [focused, setFocused] = useState(false);

    const navigate = useNavigate();
    const currentPageId = sortCategory.pagination.page.id || 1;
    const searchItems = sortCategory[currentPageId] || [];

    if (searchItems.length === 0) {
        return <></>;
    }

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const toggleSearch = (e) => {
        e.preventDefault();
        setFocused(prev => !prev);
    }

    // const optionMapper = searchItems[0].option ? ''

    let optionMapper = '';

    if (searchItems[0].title) {
        optionMapper = 'title';
    } else if (searchItems[0].domain) {
        optionMapper = 'domain'

    } else if (searchItems[0].name) {
        optionMapper = 'name'
    }
    const getOptionLabel = (option) => {
        return option.title ?? option.domain ?? option.name ?? '';
    };


    const handleItemSearchClick = (e, item) => {
        e.preventDefault();


        const isBrainCell = item.data_type === 'link_resource_type';
        let path = '';
        if (isBrainCell) {
            // const splitData = item?.relate_data_id.split('_')
            // const site_id = splitData[0];
            // const contentId = splitData[1];

            path = `/training/website/trained/${item.id}`;
            // path = `/training/website/${site_id}/${contentId}`;
        } else if (sortId !== 'archive') {
            path = `/training/website/${item.site_id}/${item.id}`;
        } else {
            path = `/training/website/${item.id}`;
        }

        navigate(path);

    }

    const filterOptions = (options, {inputValue}) => {
        return options.filter((option) =>
            option[optionMapper].toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    return (
        <FlexWrapper styles={{border: '1px solid #eee', borderRadius: '4px', padding: '2px', height: '30px'}}>
            <InputAdornment position="start" onClick={toggleSearch} sx={{cursor: 'pointer', margin: 0}}>
                <SearchIcon/>
            </InputAdornment>
            <Autocomplete
                // freeSolo
                size="small"
                disableClearable
                filterOptions={filterOptions}
                options={searchItems}
                clearOnBlur
                forcePopupIcon={false}
                getOptionLabel={getOptionLabel}
                // renderInput={(params) => (
                //     <TextField
                //         {...params}
                //         placeholder=""
                //         inputProps={{ 'aria-label': 'search' }}
                //         focused={focused}
                //         onFocus={() => console.log(params)}
                //         onBlur={() => setFocused(false)}
                //     />
                // )}
                renderOption={(props, item, state) => {

                    return <ListItemButton

                        {...props}
                        sx={{margin: 0}}
                        disablePadding
                        divider
                        dense onClick={(e) => handleItemSearchClick(e, item)}>
                        <Typography variant="truncate_text_150w">{getOptionLabel(item)}</Typography>
                        {/*<ListItemText primary={<Typography {...props} variant="truncate_text_150w">*/}

                        {/*</Typography>}/>*/}
                    </ListItemButton>
                }}
                renderInput={(params) => (
                    <StyledInputBase
                        {...params}
                        focused={focused}
                        // label="Search input"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                )}
            />
            {/*<StyledInputBase*/}
            {/*    // size="small"*/}
            {/*    // {...params}*/}
            {/*    placeholder=""*/}
            {/*    inputProps={{'aria-label': 'search'}}*/}
            {/*    focused={focused}*/}
            {/*    onFocus={() => setFocused(true)}*/}
            {/*    onBlur={() => setFocused(false)}*/}
            {/*    // sx={{*/}
            {/*    //     // width: 1,*/}
            {/*    //     '&:focus-within':{*/}
            {/*    //         width:'100%',*/}
            {/*    //     },*/}
            {/*    //     transition: 'width 1s',*/}
            {/*    // }}*/}
            {/*    // /!*{...params}*!/*/}
            {/*    // label="Search input"*/}
            {/*    // InputProps={{*/}
            {/*    //     ...params.InputProps,*/}
            {/*    //     type: 'search',*/}
            {/*    //     startAdornment: (*/}
            {/*    //         <InputAdornment position="start">*/}
            {/*    //             <SearchIcon/>*/}
            {/*    //         </InputAdornment>*/}
            {/*    //     )*/}
            {/*    // }}*/}

            {/*/>*/}
        </FlexWrapper>

    )
}

export default SearchSort;