import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../_services/api";

export const getGmailEmailList = createAsyncThunk('gmail/list', async (payload, thunkAPI) => {
    try {
        const response = await api.post(`google/get/emails/threads/${payload.accountId}`, payload)

        let emails = response?.data?.emails || [];
        let nextPageToken = response.data.nextPageToken || "";

        return {emails, nextPageToken}
    } catch (e) {
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return {message: message};
    }
});

export const getOutlookEmailsList = createAsyncThunk('outlook/list', async (payload, thunkAPI) => {
    try {
        const {pageId, accountId} = payload;
        const response = await api.post(`microsoft/outlook/get/emails/${accountId}?page=${pageId}`, payload)


        let emails = response?.data?.emails || [];

        return {emails}
    } catch (e) {
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return {message: message};
    }
});


export const getOutlookFolderList = createAsyncThunk('outlook/folder/list', async (payload, thunkAPI) => {
    try {
        const response = await api.get(`microsoft/outlook/folder/list/${payload.accountId}`);

        let items = response?.items || [];

        return {items}
    } catch (e) {
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return {message: message};
    }
});