import React from 'react';

const ChromeExtension = ({ className }) => {
  return (
    <svg className={className} version="1.1" id="Livello_1"  x="0px" y="0px" viewBox="0 0 2225.3 1921.9"
      enableBackground="new 0 0 2225.3 1921.9" width="50" height="50">
    <metadata>
      <sfw  xmlns="&ns_sfw;">
        <slices></slices>
        <sliceSourceBounds  bottomleftorigin="true" height="46" width="46" x="0" y="0"></sliceSourceBounds>
      </sfw>
    </metadata>
    <g>
      <path fill="#EEEEEE" d="M1365.5,404.7H859.8c-55.9,0-101.2-45.3-101.2-101.2c0-55.8,45.3-101.1,101.2-101.1h505.7
        c55.9,0,101.2,45.3,101.2,101.1C1466.7,359.4,1421.4,404.7,1365.5,404.7z M0,0v1770.2c0,83.4,68.3,151.7,151.7,151.7h1921.9
        c83.4,0,151.7-68.3,151.7-151.7V0H0z"/>
      <path fill="#DB4437" d="M1112.7,809.2c-339.2,0-637.3,175.7-808.3,441.1v671.5h468.5l339.8-588.6h856.1
        C1809.7,1022.2,1486,809.2,1112.7,809.2z"/>
      <path fill="#0F9D58" d="M304.1,1250.7c-96.4,149.8-152.4,328.1-152.4,519.5c0,51.6,4.1,102.3,12,151.7h531.8L304.1,1250.7z"/>
      <path fill="#FFCD40" d="M2073.6,1770.2c0-157.3-37.8-305.8-104.8-436.9h-856.1l339.8,588.6h609.1
        C2069.5,1872.5,2073.6,1821.8,2073.6,1770.2z"/>
      <path fill="#F1F1F1" d="M1112.7,1333.3c-241.3,0-436.8,195.6-436.8,436.9c0,53.4,9.6,104.5,27.1,151.7h94.8
        c-22.2-45.9-34.6-97.4-34.6-151.7c0-193.1,156.5-349.5,349.5-349.5s349.5,156.4,349.5,349.5c0,54.4-12.4,105.8-34.6,151.7h94.8
        c17.5-47.3,27.1-98.4,27.1-151.7C1549.5,1528.9,1353.9,1333.3,1112.7,1333.3z"/>
      <path fill="#4285F4" d="M1112.7,1420.7c-193,0-349.5,156.4-349.5,349.5c0,54.4,12.4,105.8,34.6,151.7h629.8
        c22.2-45.9,34.6-97.4,34.6-151.7C1462.1,1577.1,1305.7,1420.7,1112.7,1420.7z"/>
      <path opacity="0.05" fill="#212121" enableBackground="new    " d="M0,0v961h2225.3V0H0z M1365.5,404.7H859.8
        c-55.9,0-101.2-45.3-101.2-101.2c0-55.8,45.3-101.1,101.2-101.1h505.8c55.9,0,101.2,45.3,101.2,101.1
        C1466.7,359.4,1421.4,404.7,1365.5,404.7z"/>
      <rect y="948.3" opacity="0.02" fill="#212121" enableBackground="new    " width="2225.3" height="12.7"/>
      <rect y="961" opacity="0.05" fill="#FFFFFF" enableBackground="new    " width="2225.3" height="12.7"/>
      <path opacity="0.02" fill="#212121" enableBackground="new    " d="M0,0v12.7h2225.3V0H0z M1365.5,404.7H859.8
        c-53.7,0-97.6-42-100.8-94.8c-0.3,2.1-0.4,4.2-0.4,6.3c0,55.9,45.3,101.1,101.2,101.1h505.7c55.9,0,101.2-45.2,101.2-101.1
        c0-2.1-0.1-4.2-0.4-6.3C1463.1,362.6,1419.3,404.7,1365.5,404.7z"/>
      <path opacity="0.1" fill="#231F20" enableBackground="new    " d="M2073.6,1909.2H151.7C68.3,1909.2,0,1841,0,1757.5v12.7
        c0,83.4,68.3,151.7,151.7,151.7h1921.9c83.4,0,151.7-68.3,151.7-151.7v-12.7C2225.3,1841,2157,1909.2,2073.6,1909.2z M859.8,202.3
        h505.7c53.7,0,97.6,42,100.8,94.8c0.1-2.1,0.4-4.2,0.4-6.3c0-55.9-45.3-101.2-101.2-101.2H859.8c-55.9,0-101.2,45.3-101.2,101.2
        c0,2.1,0.1,4.2,0.4,6.3C762.2,244.3,806,202.3,859.8,202.3z"/>
    </g>
    </svg>
  );
}

export default ChromeExtension;