import {useRef, useEffect, useState, memo} from "react";
import {Grid, Box} from "@mui/material";
import {Divider, Tooltip, Typography, Button} from "@mui/material";
import styles from "components/ChatConversation/chat-conversation.module.css";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import ApproveMessage from "components/approve/ApproveMessageToggleBtn";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import CloseIcon from "@mui/icons-material/Close";
import controlledAPI from "_services/controlledApi";
import {useDispatch} from "react-redux";
import {editMessage} from "redux/messages";
import {interactWithUser} from "redux/status";
import {encodeData} from "utils/formatContent";
import MarkdownComponent from "components/MarkdownComponent";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import AccessChecker from "components/security/AccessChecker";
import {useNavigate} from "react-router-dom";
import AttachmentLinks from "components/showcase/AttachmentsLinks";
import {getInitials} from "utils/prepareNames";
import MessageProcessing from "../MessageProcessing";
import {sendUIEvent} from "../../../../events/analytics/google";

const Message = ({msg, userInfo, modelName, isSharedChatSelected = false, sharedChat = false}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isEditing, setEditing] = useState(false);
    const [isEditLoading, setEditLoading] = useState(false);
    const [editValue, setEditValue] = useState(msg.message);

    // Save message clicked item
    const [clickedBrainButton, setClickedBrainButton] = useState(1);

    const attachments = msg?.attachments || [];

    const handleRedirect = (id) => {

        navigate(`/training/files/${id}`)
    }


    const [status, setStatus] = useState(msg.is_approved === 1);
    const handleEditMsg = () => {
        setEditing(!isEditing);
        setEditValue(msg.message);
        if (!isEditing) {
            sendUIEvent({name: `edit_message_chat_click`});
        }

    };

    const saveMessage = (editedValue) => {
        setEditValue(editedValue);
        let editedMsg = editedValue;

        // add validations later
        if (msg.message == editedMsg) {
            return;
        }
        setEditLoading(true);

        let params = {
            message: encodeData(editedMsg),
            isApproved: 0,
        };

        controlledAPI({ignoreUser: true})
            .put(`/message/update-approve/${msg.chat_id}/${msg.id}`, params)
            .then((response) => {
                let code = response.code || [];

                if (code < 400) {
                    dispatch(editMessage({id: msg.id, message: editedMsg}));
                    setStatus(true);
                }

                dispatch(
                    interactWithUser({
                        message: 'Edited and Trained',
                        type: 'success',
                    })
                );
                setEditing(false);
                setEditLoading(false);
            })
            .catch((error) => {
                setEditLoading(false);
                console.error(error)
            });
    };

    const ApproveBtns = () => {
        return <>
            {msg.type === "response" && (
                <Grid container pt={{xs: 2, md: 0}}
                      sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: "center",
                          justifyContent: {xs: 'end', md: 'start'}
                      }}>
                    {isEditLoading ? (
                        <CircularProgress size={20} sx={{margin: "0px 9px"}}/>
                    ) : status ? (
                        <ApproveMessage
                            chatId={msg.chat_id}
                            msgId={msg.id}
                            checked={status}
                            onStatusChange={(status) => {
                                setStatus(status);
                            }}
                        />
                    ) : (
                        <Checkbox
                            size={"small"}
                            checked={isEditing}
                            color="error"
                            onClick={handleEditMsg}
                            checkedIcon={<CloseIcon color="icons"/>}
                            icon={<EditIcon/>}
                        />
                    )}
                    {/* </Box> */}

                    {isEditing && (
                        <Tooltip
                            title="Press button to edit AI response"
                            placement="top"
                        >
                            <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={function () {
                                    setClickedBrainButton(clickedBrainButton + 1);
                                    // sendUIEvent({name: `save_message_chat_click`});
                                }
                                }
                            >
                                <EastOutlinedIcon sx={{fontSize: 22}}/>
                            </IconButton>
                        </Tooltip>
                    )}

                </Grid>
            )}
        </>
    }

    const AvatarLogo = () => {
        if (msg.type === 'prompt') {
            return (
                <Avatar
                    className={styles.avatar}
                    src={isSharedChatSelected ? sharedChat.avatar ? sharedChat.avatar : null : userInfo.avatar}
                    sx={{fontSize: 15}}
                >
                    {sharedChat && sharedChat.fullName?.length ? getInitials(sharedChat.fullName) : userInfo.initials}
                </Avatar>
            )
        }

        // if (msg.type === 'prompt' && isSharedChatSelected && sharedChat.length && Boolean(sharedChat.avatar)) {
        //   return (
        //     <Avatar
        //       className={styles.avatar}
        //       src={sharedChat[0].avatar}
        //       sx={{ fontSize: 15 }}
        //     />
        //   )
        // }

        // if (msg.type === 'prompt') {
        //   return (
        //     <Avatar
        //       className={styles.avatar}
        //       src={!Boolean(sharedChat?.avatar) ? !Boolean(sharedChat.avatar) : userInfo.avatar}
        //       sx={{ fontSize: 15 }}
        //     >{sharedChat.fullName.length ? sharedChat.fullName?.length ? getInitials(sharedChat.fullName) : null : userInfo.initials}</Avatar>
        //   )
        // }

        if (msg.type !== 'prompt') {
            return <Avatar className={styles["avatar"]} sx={{backgroundColor: 'rebeccapurple'}}>{modelName}</Avatar>
        }

    }

    return (
        <Grid container sx={{padding: {md: '20px 50px 20px 60px', xs: '20px 0'}}}
            // msg_id={msg.id}
              className={
                  styles["msg-box"] +
                  " " +
                  (msg.type === "prompt" ? styles["user"] : styles["ai"])
              }
        >
            <Grid item xs={1} sx={{display: 'flex', justifyContent: 'end'}}>
                {/* {msg.type === "prompt" ? (

          <Avatar className={styles["avatar"]} 
          src={userInfo.avatar ? userInfo.avatar : ''}
           sx={{ fontSize: 15}}>{userInfo.initials}</Avatar>
        ) : (
          <Avatar className={styles["avatar"]} sx={{backgroundColor: 'rebeccapurple'}}>{modelName}</Avatar>
        )} */}
                <AvatarLogo/>
            </Grid>


            <Grid item xs={10} md={9.5} px={{xs: 2, md: 6}} sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography className={styles["inner-msg"]} variant="messages">
                    {isEditing ? (
                        <CustomMdEditor
                            customHeight={"auto"}
                            value={editValue}
                            onChange={saveMessage}
                            onChangeRequest={clickedBrainButton}
                        />
                    ) : (
                        <><MarkdownComponent source={msg.message} msgId={msg.id} chatId={msg.chat_id}/> {msg.messageStatus &&
                            <MessageProcessing status={msg.messageStatus} msgId={msg.id} chatId={msg.chat_id}/>}</>
                    )}
                    {attachments.length > 0 && <AttachmentLinks content={attachments} onClick={handleRedirect}/>}
                </Typography>

                <AccessChecker currentPermissions={userInfo.permissions} requiredPermissions={["write"]}>
                    <Grid sx={{display: {xs: 'flex', md: 'none'}}}>
                        <ApproveBtns/>
                    </Grid>
                </AccessChecker>

            </Grid>

            <AccessChecker currentPermissions={userInfo.permissions} requiredPermissions={["write"]}>
                <Grid item xs={0} md={1.5} sx={{display: {xs: 'none', md: 'flex'}, alignItems: 'start'}}>
                    <ApproveBtns/>
                </Grid>
            </AccessChecker>
        </Grid>
    );
};

export default memo(Message, (prevProps, nextProps) => {
    return prevProps.msg === nextProps.msg;
});


// export default Message;
