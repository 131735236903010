import {useEffect, useState, useRef, useMemo} from "react";
import {
    Box,
    Grid,
    Skeleton,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Button,
    Drawer,
    Avatar,
    Typography,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {decodeData} from "utils/formatContent";
import UserMenuButton from "./components/UserMenuButton";
import SharedModels from 'components/menus/SharedModels';
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import useViewportHeight from "hooks/useViewportHeight";
import {useParams} from "react-router-dom";
import api from "_services/api";
import {getInitials} from "utils/prepareNames";
import {getToken, setToken} from "_helpers/tokenManagement";
import {addMessages} from "redux/sharedMessages";
import {getSharedCards} from 'redux/marketplace';
import Chat from "./components/Chat";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import TopBarSharing from '../../components/menus/TopBarSharing';
import Content from 'pages/ShareModelMarketplace/components/Content';


const ShareModel = ({hideSideBar = false, manualHash, preview = false}) => {
    const viewportHeight = useViewportHeight();
    const [widesidebar, setWidesidebar] = useState(!Boolean(localStorage.getItem('smallMenu')));
    const [mobilebar, setMobilebar] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [topContainerHeight, setTopContainerHeight] = useState(100);
    const topContainerRef = useRef(null);
    const leftContainerRef = useRef(null);
    const [selectedTag, setSelectedTag] = useState(0);
    const [marketplaceHash, setMarketplaceHash] = useState(null);
    const [chatNotExist, setChatNotExist] = useState(false);
    const [offsetLeft, setOffsetLeft] = useState(false);
    const {smallScreen} = useIsSmallScreen();
    const navigate = useNavigate();
    // TODO: Move data to redux
    const [data, setData] = useState({});
    const [modelInitialsData, setModelInitialsData] = useState({avatar: null, name: null});
    const params = useParams();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const userInfo = useMemo(() => {
        let userInfo = {fullName: '', email: '', avatar: '', initial: ''}
        if (auth?.user?.id) {
            userInfo.fullName = auth.user.fullName;
            userInfo.email = auth.user.email;
            userInfo.avatar = auth.user.avatar;
            userInfo.initial = getInitials(auth.user.fullName);
        }
        return userInfo;
    }, [auth.user]);

    const modelHash = manualHash || params.modelHash || null;
    const modelWebsite = useMemo(() => {
        let website = ''
        if (data.model && data.model.data.website) {
            website = data.model.data.website
            if (!/^https?:\/\//i.test(website)) {
                website = 'http://' + website;
            }
        }
        return website;
    }, [data.model?.data?.website]);

    useEffect(() => {
        dispatch(getSharedCards({query: null, loadMore: false}));
    }, []);

    useEffect(() => {
        setOffsetLeft(leftContainerRef?.current?.clientWidth + 1);
    }, [widesidebar]);

    const fetchData = async (modelHash) => {
        setDataLoading(true);
        let params = {
            modelHash,
        };

        params.guestId = getToken("guestId") || undefined;

        await api
            .post("/model/share/data", params)
            .then((r) => {

                if (r.code >= 400) {
                    setDataLoading(false);
                    setChatNotExist(true);
                    return;
                }

                let messages = r.messages || [];
                let model = r.chat?.model || {};
                let ownerInfo = r.owner || {};
                let ownerInnitial = ownerInfo.fullName || '';
                if (ownerInnitial !== '') {
                    ownerInfo.initial = getInitials(ownerInnitial);
                }

                if (Boolean(model.marketplace_card?.enable_monetization) && !manualHash) {
                    navigate(`/m/${model.marketplace_card?.url_hash}`)
                }

                dispatch(addMessages({type: 'shared', messages}));
                setModelInitialsData({avatar: model.avatar, name: model.name});
                setData({
                    model: model,
                    messages: messages,
                    info: {
                        chat_id: r.chat.id, guest_id: r.chat.guest_id, modelName: model.name,
                        modelWebsite: modelWebsite, modelId: model.id
                    },
                    ownerInfo: ownerInfo,
                });

                setDataLoading(false);
                setToken("guestId", r.chat.guest_id);

            })
            .catch((error) => {
                setDataLoading(false);
            });
    }

    useEffect(() => {
        if (modelHash) {

            if (auth.loading == false) {

                fetchData(modelHash);
            }
        }

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === topContainerRef.current) {
                    const newHeight = entry.target.clientHeight;
                    setTopContainerHeight(newHeight);
                }
            }
        });

        if (topContainerRef.current) {
            resizeObserver.observe(topContainerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [auth.loading]);

    if (chatNotExist) {
        return (
           <Box sx={{ filter: preview && 'blur(10px)' }}>
            <h3>Chat Not Exist</h3>
          </Box>
        )
    }

    const handleMobilebarChange = (s) => {
        setMobilebar(s);
    };

    const handleWidesidebarChange = (s) => {
        setWidesidebar(s);
    };

    const onSelectHandler = (model) => {
        // If shared model
        if (!Boolean(model?.settings?.monetization_price) && Boolean(model?.public_hash)) {
            fetchData(model.public_hash);
            setMarketplaceHash(null);
        }

        // Id monetization page
        if (Boolean(model?.settings?.monetization_price) && Boolean(model?.marketplace_card?.url_hash)) {
            setMarketplaceHash(model?.marketplace_card?.url_hash);
        }
    }

    return (
        <>
            {!hideSideBar &&
                <TopBarSharing
                    userInfo={userInfo}
                    mobilebar={mobilebar}
                    mobilebarChange={handleMobilebarChange}
                    smallScreen={smallScreen}
                    widesidebar={widesidebar}
                    widesidebarChange={handleWidesidebarChange}
                    onSelect={model => {
                        onSelectHandler(model);
                    }}
                />}
            <Box
                display="flex"
                justifyContent="center"
                width="100%"
                height={hideSideBar ? '100%' : manualHash ? 'calc(100vh - 347px)' : `calc(${viewportHeight - 64}px)`}
                sx={{backgroundColor: {xs: "white"}}}
            >
                <Grid container overflow="hidden">
                    {!hideSideBar && (
                        <Grid
                            item
                            ref={leftContainerRef}
                            sx={{
                                width: {
                                    xs: 0,
                                    md: widesidebar ? "auto" : "73px",
                                    lg: widesidebar ? "auto" : "73px",
                                },
                            }}
                            xs={0}
                            md={widesidebar ? 4 : "auto"}
                            lg={widesidebar ? 2 : "auto"}
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="column"
                        >
                            {/* <Box py={2} sx={{display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid #ededf5' }}>
              {smallScreen ?
                <Box sx={{
                  width: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <ListIcon
                    color={'icons'}
                    sx={{ marginLeft: '6px', width: 28, height: 28, position: 'relative', zIndex: 10 }}
                    onClick={() => {
                      setMobilebar(!mobilebar);
                    }}
                  />
                </Box> : <Box sx={{ display: 'flex', paddingLeft: '16px' }}>
                  <IconButton
                    aria-label="sideMenu"
                    onClick={() => setWidesidebar(!widesidebar)}
                  >
                    {widesidebar ? <MenuOpenIcon color={'icons'}/> : <ListIcon color={'icons'}/>}
                  </IconButton>
                  {widesidebar && (
                    <Box sx={{display: 'flex', alignItems: 'center'}}><LogoSvg hasLogo={false}/></Box>
                  )}
                </Box>
              }
            </Box> */}
                            {/* TODO: move a menu into a separate component */}
                            {smallScreen ? (
                                <Drawer
                                    anchor='left'
                                    open={mobilebar}
                                    onClose={() => setMobilebar(false)}
                                >
                                    <Box
                                        pt={3}
                                        sx={{
                                            width: '260px',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            flexDirection: 'row',
                                            height: '100%',
                                        }}>
                                        <Box
                                            // style={containerStyle}
                                            sx={{
                                                width: widesidebar ? "100%" : "72px",
                                            }}
                                        >
                                            <Box mb={2} sx={{width: '100%'}}>
                                                <ListItemButton
                                                    id="model"
                                                    sx={{pl: 6, borderBottom: '1px solid #ededf5'}}
                                                >
                                                    <ListItemIcon sx={{minWidth: "40px"}}>
                                                        <Avatar src={modelInitialsData?.avatar ?? ''} sx={{
                                                            width: {xs: '26px', md: '30px'},
                                                            height: {xs: '26px', md: '30px'},
                                                            border: '1px solid #00000011',
                                                            fontSize: "14px"
                                                        }}>{modelInitialsData?.name?.substring(0, 1)}</Avatar>
                                                    </ListItemIcon>
                                                    {widesidebar && (
                                                        dataLoading ?
                                                            <Skeleton
                                                                variant="text"
                                                                animation="wave"
                                                                sx={{width: "100%", margin: '4px 0'}}
                                                            /> : <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        sx={{fontSize: {xs: '1rem', md: '0.875rem'}}}>
                                                                        {Boolean(modelInitialsData?.name) ? modelInitialsData?.name : data?.model?.name}
                                                                    </Typography>
                                                                }
                                                            />
                                                    )}
                                                </ListItemButton>
                                                <Box sx={{
                                                    height: `calc(${viewportHeight - 215}px)`,
                                                    overflow: 'scroll'
                                                }}>
                                                    <SharedModels
                                                        widesidebar={widesidebar}
                                                        smallScreen={smallScreen}
                                                        onSelect={model => {
                                                            onSelectHandler(model);
                                                            setMobilebar(false);
                                                        }}
                                                        leftBarMenu={true}
                                                        leftOffset={true}
                                                        changeUrl={true}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Drawer>
                            ) : (
                                <>
                                    <Box
                                        // style={containerStyle}
                                        sx={{
                                            width: widesidebar ? "100%" : "72px",
                                        }}
                                    >
                                        <Box mb={2} sx={{width: '100%',}}>
                                            <ListItemButton
                                                id="model"
                                                sx={{pl: 6, borderBottom: '1px solid #ededf5'}}
                                            >
                                                <ListItemIcon sx={{minWidth: "40px"}}>
                                                    {Boolean(modelInitialsData.name) ?
                                                        <Avatar src={modelInitialsData?.avatar ?? ''} sx={{
                                                            width: {xs: '26px', md: '30px'},
                                                            height: {xs: '26px', md: '30px'},
                                                            border: '1px solid #00000011',
                                                            fontSize: "14px"
                                                        }}>{modelInitialsData?.name?.substring(0, 1)}</Avatar> :
                                                        <Skeleton width={30} height={30} variant="circular"
                                                                  animation="wave"> <Avatar/></Skeleton>}
                                                </ListItemIcon>
                                                {widesidebar && (
                                                    dataLoading ?
                                                        <Skeleton
                                                            variant="text"
                                                            animation="wave"
                                                            sx={{width: '100%', margin: '4px 0'}}
                                                        /> : <ListItemText
                                                            sx={{
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden'
                                                            }}
                                                            primary={<Box sx={{
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                fontSize: '0.875rem'
                                                            }}>{Boolean(modelInitialsData?.name) ? decodeData(modelInitialsData?.name) : decodeData(data?.model?.name)}</Box>}/>
                                                )}
                                            </ListItemButton>
                                            <Box sx={{
                                                height: `calc(${viewportHeight - 215}px)`,
                                                overflow: 'scroll'
                                            }}>
                                                <SharedModels
                                                    widesidebar={widesidebar}
                                                    smallScreen={smallScreen}
                                                    leftBarMenu={true}
                                                    leftOffset={true}
                                                    onSelect={model => onSelectHandler(model)}
                                                    changeUrl={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    {!auth.user && widesidebar && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '10px',
                                                justifyContent: 'center',
                                                width: widesidebar ? "100%" : "72px",
                                                borderTop: '1px solid #ededf5'
                                            }}
                                        >
                                            <Button href="/portal/login" variant="contained" sx={{
                                                paddingLeft: '30px',
                                                paddingRight: '30px',
                                                margin: '30px 5px'
                                            }}>Sign&nbsp;in</Button>
                                            <Button
                                                href={
                                                    "/portal/sign-up?guestId=" +
                                                    (data.info ? data.info.guest_id : "")
                                                }
                                                sx={{padding: '0 20px', margin: '30px 5px'}}
                                            >
                                                Sign&nbsp;up
                                            </Button>
                                        </Box>
                                    )}
                                    {auth.user && widesidebar && (
                                        <Grid container justifyContent='end' px={4} py={2}
                                              sx={{borderTop: '1px solid #ededf5'}}>
                                            <Grid item xs={3.5} px={4} display='flex' alignItems='center'
                                                  justifyContent='end'>
                                                <Avatar src={userInfo.avatar ?? ''}
                                                        sx={{border: '1px solid #00000011'}}>{userInfo.initial}</Avatar>
                                            </Grid>
                                            <Grid item xs={6} display='flex' flexDirection='column'
                                                  justifyContent='center'>
                                                <span style={{fontWeight: 700}}>{userInfo.fullName}</span>
                                            </Grid>
                                            <Grid item xs={2.5} display='flex' alignItems='center'>
                                                <UserMenuButton userInfo={userInfo}/>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        md={hideSideBar ? 12 : widesidebar ? 8 : "auto"}
                        lg={hideSideBar ? 12 : widesidebar ? 10 : "auto"}
                        height="100%"
                        sx={{
                            display: "flex",
                            flexFlow: "column",
                            overflowY: Boolean(marketplaceHash) ? "scroll" : "hidden",
                            flexGrow: 1,
                            paddingTop: Boolean(marketplaceHash) ? '15px' : 0,
                            width: {
                                md: hideSideBar ? "auto" : widesidebar ? "auto" : "calc(100vw - 73px)",
                                lg: hideSideBar ? "auto" : widesidebar ? "auto" : "calc(100vw - 73px)",
                            },
                            borderLeft: {xs: "none", md: "0.5px solid rgba(0, 0, 0, 0.12)"},
                            borderRight: {
                                xs: "none",
                                md: "0.5px solid rgba(0, 0, 0, 0.12)",
                            },
                        }}
                    >
                        {Boolean(marketplaceHash) ? (
                            <Box sx={{padding: '0 15px'}}>
                                {/* Paid model */}
                                <Content
                                    modelHash={marketplaceHash}
                                    setModelinitials={(modelInitials) => setModelInitialsData(modelInitials)}
                                    offsetLeft={offsetLeft}
                                />
                            </Box>
                        ) : (
                            <>
                                <Grid container sx={{
                                    // padding: "15px 15px 0px",
                                    flex: 1, display: 'flex', flexFlow: 'column'
                                }}>
                                    <Grid item xs={12} display="flex" flexDirection="column" sx={{flex: 1}}>
                                        <Grid
                                            container
                                            display="flex"
                                            flexDirection="column"
                                            sx={{position: 'relative', minHeight: smallScreen && preview ? 'calc(100vh - 206px)' : preview ? `500px` : '100%', scrollY: preview ? 'scroll' : 'inherit'}}
                                        >
                                            <Grid item xs={12}>
                                                {/* {data.model && ( */}
                                                <Chat
                                                    islogin={true && auth.user}
                                                    viewportHeight={viewportHeight}
                                                    topContainerHeight={topContainerHeight}
                                                    info={data.info}
                                                    modelName={data?.model?.name && getInitials(data.model.name)}
                                                    chatLoading={dataLoading}
                                                    type="shared"
                                                    hideSideBar={hideSideBar}
                                                    topOffset={{
                                                        md: hideSideBar ? (manualHash ? 447 : 102) : 166,
                                                        xs: hideSideBar ? (manualHash ? 447 : 102) : 166
                                                    }}
                                                    preview={preview}
                                                    userInfo={userInfo}
                                                    modelData={data}
                                                />
                                                {/* )} */}
                                            </Grid>
                                        </Grid>
                                        {/*</CustomTabPanel>*/}
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {/* terms & privacy mobile only*/}
                        <Grid
                            display={{xs: "flex", md: "none"}}
                            flexDirection="row"
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            {/* <Typography variant="body2" color="text.secondary" align="center">
                {"© " + new Date().getFullYear()}{" "}
                <a href="https:/younet.ai">Younet</a>.
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                <a
                  style={{ color: "#3976ef" }}
                  href="https://younet.ai/terms-and-conditions#terms-and-conditions"
                >
                  Terms of use
                </a>
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                <a
                  style={{ color: "#3976ef" }}
                  href="https://younet.ai/terms-and-conditions#privacy-policy"
                >
                  Privacy policy
                </a>
                <br />
              </Typography> */}

                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ShareModel;
