import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "_services/api/index.js";
import {encodeData} from "../../utils/formatContent";

export const parseWebsite = createAsyncThunk(
    "links/parse",
    async (payload = {}, thunkAPI) => {
        try {

            // payload.approve = 1;
            // payload.modelId = 243

            let newParams = {
                ...payload,
                // approve: 1,
                // modelId: 243,
            }


            const response = await api.post("/learning/crawler/link", newParams);

            if (response.code >= 400) {
                throw new Error('No data')
            }

            const data = response.data;
            let pages = data?.pages || [];
            let task = data?.task || [];
            let errors = data?.errors || [];

            return {pages, task, errors};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);


export const getDomainsList = createAsyncThunk(
    "links/domains/list",
    async (payload, thunkAPI) => {
        try {

            const response = await api.get(`/learning/crawler/list/${payload.modelId}}`);

            if (response.code >= 400) {
                throw new Error('No data')
            }
            const chats = response?.chats || [];

            return {chats: chats};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return {message: message};
        }
    }
);

export const getAllLinks = createAsyncThunk(
    "links/all/list",
    async (payload, thunkAPI) => {
        try {
            const response = await api.get(`/learning/crawler/list?page=${payload.page}`);
            const items = response?.items || [];

            return {all: items, totalAmount: response?.count || -1};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);

export const getTrainedList = createAsyncThunk(
    "links/trained/list",
    async (payload, thunkAPI) => {
        try {

            const response = await api.get(`/learning/crawler/list/${payload.modelId}`);
            const trained = response?.link_memory_items || [];

            return {trained: trained, totalAmount: trained?.length || -1};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);


export const getNestedList = createAsyncThunk(
    "links/nested/list",
    async (payload, thunkAPI) => {
        try {
            const response = await api.get(`/learning/crawler/${payload.id}?page=${payload.page}`);
            const items = response?.items || [];

            return {all: items, totalAmount: response?.count || -1};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);

export const getFullDataById = createAsyncThunk(
    "links/full/content",
    async (payload, thunkAPI) => {
        try {
            const response = await api.get(`/learning/crawler/content/${payload.id}`);
            const data = response?.item || [];

            return {data: data};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);


export const getTrainedDataById = createAsyncThunk(
    "links/trained/content",
    async (payload, thunkAPI) => {
        try {
            const response = await api.get(`/brain/get/${payload.id}`);
            const data = response?.data || [];

            return {data: data};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);


export const approveOrDisapproveById = createAsyncThunk(
    "links/approve-disapprove/content",
    async (payload, thunkAPI) => {
        try {

            const state = thunkAPI.getState();
            const modelId = state?.models.current?.id;
            const sortId = state.links.content.sort.current.id;


            if (!modelId) {
                throw new Error('no model id')
            }

            // if (payload.isApproved) {

            let params = {
                modelId: modelId,
            }

            if (payload.pageOrSite) {
                params[sortId.startsWith('nested') ? 'pageIds' : 'siteIds'] = payload.pageOrSite;
            } else {
                params = {
                    ...params,
                    pageIds: payload.pageIds,
                    siteIds: payload.siteIds
                };
            }

            const route = payload.isApproved ? '/approve' : '/disapprove/bulk';
            const response = await api.post(`/learning/crawler${route}`, params);
            const data = (payload.pageIds && payload.pageIds.length > 0) ? payload.pageIds :
                (payload.siteIds && payload.siteIds.length > 0) ? payload.siteIds :
                    (payload.pageOrSite && payload.pageOrSite.length > 0) ? payload.pageOrSite :
                        [];

            return {isApproved: payload.isApproved, data: data};
            // } else {
            //     const response = await api.get(`/learning/crawler/disapprove/${modelId}/${payload.siteId}/${payload.pageIds[0]}`);
            //     const data = response?.data || [];
            //
            //     return {data: data};
            // }


        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);

export const deleteBrainItem = createAsyncThunk(
    "links/content/delete",
    async (payload, thunkAPI) => {
        try {


            const response = await api.delete(`/learning/crawler/brain/${payload.id}`);
            const code = response?.code || [];

            if (code >= 400) {
                throw new Error('Could not delete')
            }

            return {brainId: payload.id};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);

export const updateLinkContent = createAsyncThunk(
    "links/content/update",
    async (payload, thunkAPI) => {
        try {


            const params = {
                title: payload.title,
                content: payload.content,
            };

            const response = await api.put(`/learning/crawler/content/${payload.id}`, params);

            if (response.code >= 400) {
                throw new Error('Could not update')
            }

            const data = response?.data || [];

            return {data: payload};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);


export const getPendingList = createAsyncThunk(
    "links/pending/list",
    async (payload, thunkAPI) => {
        try {

            const params = {
                type: 'site'
            };


            const response = await api.get('/task/list', {params});

            if (response.code >= 400) {
                throw new Error('Could not get list ')
            }

            const tasks = response?.tasks || [];
            const totalAmount = tasks.length;

            return {tasks: tasks, totalAmount: totalAmount};
        } catch (e) {
            let message =
                e?.response?.message || e?.message || "Something went wrong";
            return thunkAPI.rejectWithValue({message});
        }
    }
);






