import { useEffect, useState, useCallback } from "react";

const useIsSmallScreen = () => {
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 900);

    const handleCheckWidth = useCallback(() => {
        const isSmall = window.innerWidth < 900;
        if (isSmall !== smallScreen) {
            setSmallScreen(isSmall);
        }
    }, [smallScreen]);

    useEffect(() => {
        window.addEventListener("resize", handleCheckWidth);

        return () => {
            window.removeEventListener("resize", handleCheckWidth);
        };
    }, [handleCheckWidth]);

    return { smallScreen };
};

export default useIsSmallScreen;
