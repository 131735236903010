import {useState, useEffect} from 'react';
import classnames from 'classnames';
import styles from './logo.module.css';


const TICK_ANIMATION_TIME = 40;
const TEXT_INPUT_OFFSET = 50;
export const DynamicLogo = ({tickNum = 0, stage, isLoading, textInputText = '', size = 'medium'}) => {

    const [tick, setTick] = useState(false);

    // offset symbols ratio num
    const textInputOffset = TEXT_INPUT_OFFSET;
    const textLen = textInputText.length || 0;
    const textRationNum = Math.round(textLen / textInputOffset)

    useEffect(() => {

        if (tickNum !== 0 || textInputText.length) {
            setTick(true);
            setTimeout(() => {
                setTick(false);
            }, TICK_ANIMATION_TIME);
        }
    }, [tickNum, textRationNum]);


    return (
        <div
            data-testid="dynamic-brand-logo"

            className={
                classnames(
                    styles.wrapper,
                    stage === 1 && styles.firstStage,
                    stage === 2 && styles.secondStage,
                    (stage === 3) && styles.thirdStage,
                    size && styles[size]
                )
            }
        >
            <div className={classnames(styles.line, styles.line1)}/>
            <div className={classnames(styles.line, styles.line2)}/>
            <div className={classnames(styles.line, styles.line3)}/>
            <div className={classnames(styles.line, styles.line4)}/>
            <div className={classnames(styles.line, styles.line5)}/>
            <div className={classnames(styles.smallDot, styles.dot1)}/>
            <div className={classnames(styles.smallDot, styles.dot2)}/>
            <div className={classnames(styles.smallDot, styles.dot3)}/>
            <div className={classnames(styles.smallDot, styles.dot4)}/>
            <div className={classnames(styles.smallDot, styles.dot5)}/>
            <div className={classnames(styles.smallDot, styles.dot6)}/>
            <div className={classnames(styles.smallDot, styles.dot7)}/>
            <div className={classnames(styles.smallDot, styles.dot8)}/>
            <div className={classnames(styles.smallDot, styles.dot9)}/>
            <div className={classnames(styles.smallDot, styles.dot10)}/>
            <div className={classnames(styles.smallDot, styles.dot11)}/>
            <div className={classnames(styles.smallDot, styles.dot11)}/>
            <div className={classnames(styles.smallDot, styles.dot12)}/>
            <div className={classnames(styles.smallDot, styles.dot13)}/>
            <div className={classnames(styles.smallDot, styles.dot14)}/>
            <div className={classnames(styles.blue, isLoading && styles.loading)}/>
            <div className={classnames(styles.purple, isLoading && styles.loading)}/>
            <div className={classnames(
                styles.bigDot,
                // isLoading && styles.loading,
                isLoading && stage === 2 && styles.infiniteTick,
                tick && styles.tick,

                // bouncing && !tick && !isLoading && styles.bouncing,
            )}/>
        </div>
    )
}


export const ThreeDotsLoading = ({center = true, isLoading = false, topOffset = 0}) => {

    return (
        <div className={center && styles.centerLogo}>
            <div className={classnames(styles.wrapper, styles.thirdStage)} style={{marginTop: topOffset}}>
                <span className={classnames(styles.blue, isLoading && styles.loading)}/>
                <span className={classnames(styles.purple, isLoading && styles.loading)}/>
                <span className={classnames(
                    styles.bigDot,
                    isLoading && styles.loading,
                )}/>
            </div>
        </div>
    )
}

export const FullAnimationLoading = ({stage, isLoading, textInputText = '' }) => {


    return (
        <div
            className={
                classnames(
                    styles.wrapper,
                    stage === 1 && styles.firstStage,
                    stage === 2 && !isLoading && styles.secondStage,
                    (stage === 3 || isLoading) && styles.thirdStage,
                )
            }
        >
            <div className={classnames(styles.line, styles.line1)}/>
            <div className={classnames(styles.line, styles.line2)}/>
            <div className={classnames(styles.line, styles.line3)}/>
            <div className={classnames(styles.line, styles.line4)}/>
            <div className={classnames(styles.line, styles.line5)}/>
            <div className={classnames(styles.smallDot, styles.dot1)}/>
            <div className={classnames(styles.smallDot, styles.dot2)}/>
            <div className={classnames(styles.smallDot, styles.dot3)}/>
            <div className={classnames(styles.smallDot, styles.dot4)}/>
            <div className={classnames(styles.smallDot, styles.dot5)}/>
            <div className={classnames(styles.smallDot, styles.dot6)}/>
            <div className={classnames(styles.smallDot, styles.dot7)}/>
            <div className={classnames(styles.smallDot, styles.dot8)}/>
            <div className={classnames(styles.smallDot, styles.dot9)}/>
            <div className={classnames(styles.smallDot, styles.dot10)}/>
            <div className={classnames(styles.smallDot, styles.dot11)}/>
            <div className={classnames(styles.smallDot, styles.dot11)}/>
            <div className={classnames(styles.smallDot, styles.dot12)}/>
            <div className={classnames(styles.smallDot, styles.dot13)}/>
            <div className={classnames(styles.smallDot, styles.dot14)}/>
            <div className={classnames(styles.blue, isLoading && styles.loading)}/>
            <div className={classnames(styles.purple, isLoading && styles.loading)}/>
            <div className={classnames(
                styles.bigDot,
                isLoading && styles.loading,
                // tick && styles.tick,
                // bouncing && !tick && !isLoading && styles.bouncing,
            )}/>
        </div>
    )
}


