import { Typography, Box, Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const accordionStyles = `
  &.MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
  .MuiAccordionSummary-content:before {
    display: none;
  }
  .MuiAccordionSummary-root {
    min-height: auto !important;
    padding: 0;
  }
  .MuiAccordionDetails-root {
    padding: 5px 0 0;
  }
`;
const CustomAccordion = styled(Accordion)`${accordionStyles}`;

const ModelAccordion = ({ expanded, name, description, isLoading = false, onClickHandler, classNames, handleAccordionClick = false }) => {
  return (
    <CustomAccordion
      defaultExpanded={true}
      sx={{
        marginBottom: '10px',
        border: '1px solid rgba(216,211,250, .1)',
        borderRadius: '12px !important',
        padding: '4px 10px 4px 14px',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'left',
        color: '#000',
        fontSize: '14px',
        textTransform: 'none',
        boxShadow: '0 0 2px 2px rgba(216,211,250, .5)',
        minHeight: '34px'
      }}
      className={classNames}
    >
      <AccordionSummary
        aria-controls="panel-content"
        expandIcon={<ExpandMoreIcon />}
        onClick={handleAccordionClick}
      >
        {name}
      </AccordionSummary>
      <AccordionDetails>
        {description && (
          <Typography
            variant="div"
            sx={{
              marginBottom: '5px',
              width: '100%',
              fontSize: '14px',
              color: 'rgba(0,0,0, .6)'
            }}
          >
            {description}
          </Typography>
        )}
        {!isLoading && (
          <Box sx={{ textAlign: 'right', margin: '20px 0 10px' }}>
            <Button
              fullWidth
              variant="contained"
              onClick={onClickHandler}>Next</Button>
          </Box>
        )}
      </AccordionDetails>
    </CustomAccordion>
  )
}

export default ModelAccordion;
