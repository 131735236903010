import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  Divider,
  IconButton,
  Skeleton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { getSubscriptions } from 'redux/models';
import classnames from 'classnames';
import { unsubscribe } from 'redux/models';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from './subscriptions.module.css';

const Subscriptions = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const dispatch = useDispatch();
  const { planInfo } = useSelector((state) => state.billing, shallowEqual);
  const { loadingSubscription, subscriptions } = useSelector((state) => state.models, shallowEqual);

  const handleUnsubscribe = () => {
    dispatch(unsubscribe({ modelId: selectedSubscription.model.id })).then(() => {
      setIsDialogOpen(false);
      setSelectedSubscription(null);
    })
  }

  useEffect(() => {
    if (!subscriptions?.length) {
      dispatch(getSubscriptions());
    }
  }, [subscriptions]);

  return (
    <>
      {loadingSubscription ? (
        <Grid item xs={12} md={12}>
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ width: "100%" }}
          />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ width: "100%" }}
          />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ width: "100%" }}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={12}>
          <Box mb={6}>
            {Boolean(planInfo) && (
              <Box className={styles.block}>
                <Typography className={styles.blockTitle} variant="div">{planInfo.currentPlan?.name}</Typography>
                <Typography className={classnames(styles.blockText, styles.grow)} variant="div">{planInfo.currentPlan?.description}</Typography>
                <Typography className={styles.blockText} variant="div">{`$${planInfo.currentPlan?.chargePerMonth}/${planInfo.currentPlan?.frequency}`}</Typography>
                {/* <IconButton aria-label="delete" disabled={loadingSubscription} onClick={handleBillingUnsubscribe}>
                  <DeleteIcon fontSize="small" />
                </IconButton> */}
              </Box>
            )}
            {Boolean(subscriptions?.length) && subscriptions.map((item, i) => {
              const price = item.model?.settings?.monetization_price;
              const frequency = item.model?.settings?.monetization_charge_frequency;
              const isCanceled = item.status === 'canceled';
              const nextRenewalDateLocalized = moment(item.end_at?.date).local().format('YYYY-MM-DD');

              return (
                <Box key={`${item.id}-${i}`} className={styles.block}>
                  <Typography className={classnames(styles.blockTitle, !isCanceled && styles.grow)} variant="div">{item.model?.name}</Typography>
                  {isCanceled && <Typography className={classnames(styles.blockText, styles.grow)} variant="div">{`The subscription expires on ${nextRenewalDateLocalized}`}</Typography>}
                  <Typography className={styles.blockText} variant="div">{`$${price}/${frequency}`}</Typography>
                  {isCanceled ? (
                    <GeneralTooltip
                      placement="bottom"
                      title={`The subscription expires on ${nextRenewalDateLocalized}`}
                    >
                      <DeleteIcon fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.26)', margin: '8px' }} />
                    </GeneralTooltip>
                  ) : (
                    <IconButton aria-label="delete" disabled={loadingSubscription || isCanceled} onClick={() => {
                      setIsDialogOpen(true);
                      setSelectedSubscription(item);
                    }}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              )
            })}
          </Box>
          <Divider sx={{ width: '100%' }} />
        </Grid>
      )}
      <Dialog
        open={isDialogOpen}
        sx={{
          '.MuiPaper-root': {
            padding: 2,
          },
        }}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogContent sx={{ display: 'flex', alignItems: 'top', gap: '10px' }}>
          <ReportProblemRoundedIcon sx={{ width: 50, height: 50, color: '#ff3d00' }} />
          <DialogContentText>
            {`Are you sure you want to unsubscribe from ${selectedSubscription?.model?.name} model? This action cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.ctaActions}>
          <Button
            variant="outlined"
            className={styles.cta}
            onClick={() => {
              setIsDialogOpen(false);
            }}
          >Cancel</Button>
          <LoadingButton
            variant="contained"
            color="error"
            className={styles.cta}
            loading={loadingSubscription}
            onClick={handleUnsubscribe}
          >
            Unsubscribe
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Subscriptions;
