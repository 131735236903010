import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api/index.js';

const DEFAULT_PATH = '/owner-model';

export const getInfo = createAsyncThunk('monetization/info', async (payload, thunkAPI) => {
  try {
    const balanceResponse = await api.get(`${DEFAULT_PATH}/subscribe/balance`);
    const subsResponse = await api.get(`${DEFAULT_PATH}/subscribe/list`);
    const invoicesResponse = await api.get(`${DEFAULT_PATH}/payment/invoices/list`);
    const paymentListResponse = await api.get('user/payment-details/list');

    if (balanceResponse.code >= 400 || subsResponse.code >= 400 || invoicesResponse.code >= 400 || paymentListResponse.code >= 400) {
      throw new Error("Could not get server info");
    }

    const balance = balanceResponse.data;
    const subs = subsResponse.items;
    const invoices = invoicesResponse.invoices;
    const paymentList = paymentListResponse.items;

    return { balance, subs, invoices, paymentList };
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
    // return thunkAPI.rejectWithValue(message);
  }
});


export const unsubscribeThunk = createAsyncThunk('monetization/unsubscribe', async (payload, thunkAPI) => {
  const { id } = payload;
  try {
    const response = await api.get(`${DEFAULT_PATH}/unsubscribe/${id}`);

    if (response.status >= 400) {
      throw new Error("Could not get server info");
    }

    return { id };
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
    // return thunkAPI.rejectWithValue(message);
  }
});

export const setInfo = createAsyncThunk('monetization/setInfo', async (payload, thunkAPI) => {
  try {
    const paymentDetailsResponse = await api.post('/user/payment-details', payload);

    if (paymentDetailsResponse.code >= 400) {
      throw new Error("Could not get server info");
    }

    const paymentDetails = paymentDetailsResponse.user_payment_details;

    return { paymentDetails };
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const updateInfo = createAsyncThunk('monetization/updateInfo', async (payload, thunkAPI) => {
  try {
    const { id, data } = payload;
    const paymentDetailsResponse = await api.put(`/user/payment-details/${id}`, data);
    if (paymentDetailsResponse.code >= 400) {
      throw new Error("Could not get server info");
    }

    return { paymentDetails: payload }
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});

export const deleteInfo = createAsyncThunk('monetization/deleteInfo', async (payload, thunkAPI) => {
  try {
    const { id } = payload;
    const paymentDetailsResponse = await api.delete(`/user/payment-details/${id}`);
    if (paymentDetailsResponse.code >= 400) {
      throw new Error("Could not get server info");
    }

    return { id }
  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
  }
});
