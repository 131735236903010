import {Skeleton} from "@mui/material";
import React from "react";
import ImageGenerating from "../../loadings/ImageGenerating";

const InlineLoad = () => {


    return <ImageGenerating/>
    return (
         <Skeleton sx={{display: 'inline-block'}} width={100}/>
    )
}

export default InlineLoad;