import { useEffect } from 'react';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import classnames from 'classnames';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getPlansInfo } from 'redux/billing';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import styles from './history.module.css';

const History = ({ billingHistory, isLoading, subscriptions }) => {
  const dispatch = useDispatch();
  const { smallScreen } = useIsSmallScreen();

  const formatDate = dateString => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }

  useEffect(() => {
    dispatch(getPlansInfo());
}, []);

  const { tariff_plans, loadingPlans } = useSelector((state) => state.billing);

  if (isLoading || loadingPlans) {
    return (
      <Box mt={4}>
        {[...Array(4)].map((_, i) => (
          <Typography variant="" key={`history-loading-${i}`}>
            <Skeleton
              variant="text"
              animation="wave"
            />
          </Typography>
        ))}
      </Box>
    )
  }

  if (!billingHistory.length) {
    return (
      <Box className={styles.wrapper} py={4}>
        <div className={styles.commingSoon}>No invoices yet</div>
      </Box>
    );
  }

  if (smallScreen) {
    return (
      <>
        {billingHistory.map((item, i) => {
          const date = new Date(item.created * 1000);
          const orderId = item.id;
          const subscriptionName = subscriptions?.filter(sub => sub.stripe_sub_id === item.subscription)[0]?.model?.name;
          const plan = tariff_plans.filter(plan => plan.stripe_id === item.lines.data[0].plan.product)[0]?.name;
          const status = item.status;
          const price = item.total / 100;
          const pdf = item.invoice_pdf;
          const url = item.hosted_invoice_url;

          return (
            <Box key={`history-mobile-${i}`} className={classnames(styles.wrapper, styles.mobileWrapper)}>
              <Box className={classnames(styles.verticalLine, styles.bold)}>
                <div>Date</div>
                <div>OrderID</div>
                <div>Plan</div>
                <div>Status</div>
                <div>Amount</div>
              </Box>
              <Box className={styles.verticalLine}>
                <div>{formatDate(date)}</div>
                <div>{orderId}</div>
                <div>{plan || subscriptionName || '-'}</div>
                <div className={styles.status}>
                  <span className={classnames((status === 'draft' || status === 'draft') && styles.statusWarning, status === 'refunded' && styles.statusGrey, styles.statusGreen)}>{status}</span>
                </div>
                <div>{`$${price}`}</div>
              </Box>
              <Box className={styles.actionsWrapper}>
                <div className={styles.actions}>
                  <IconButton variant="text" href={url} target="_blank">
                    <RemoveRedEyeIcon />
                  </IconButton>
                  <IconButton variant="text" href={pdf}>
                    <DownloadIcon  />
                  </IconButton>
                </div>
              </Box>
            </Box>
          )
        })}
      </>
    )
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={classnames(styles.line, styles.headerLine)}>
        <div>Date</div>
        <div>OrderID</div>
        <div>Plan</div>
        <div>Status</div>
        <div>Amount</div>
        <div>Actions</div>
      </Box>
      {billingHistory.map((item, i) => {
        const date = new Date(item.created * 1000);
        const orderId = item.id;
        const plan = tariff_plans.filter(plan => plan.stripe_id === item.lines?.data[0]?.plan?.product)[0]?.name;
        const subscriptionName = subscriptions?.filter(sub => sub.stripe_sub_id === item.subscription)[0]?.model?.name;

        if (!plan && !subscriptionName) {
          return;
        }
        const status = item.status;
        const price = item.total / 100;
        const pdf = item.invoice_pdf;
        const url = item.hosted_invoice_url;

        return (
          <Box key={`history-item-${i}`} className={classnames(styles.line)}>
            <div className={styles.textOverflow}>{formatDate(date)}</div>
            <div className={styles.textOverflow}>{orderId}</div>
            <div className={styles.textOverflow}>{plan || subscriptionName}</div>
            <div className={styles.status}>
              <span className={classnames((status === 'draft' || status === 'draft') && styles.statusWarning, status === 'refunded' && styles.statusGrey, styles.statusGreen)}>{status}</span>
            </div>
            <div>{`$${price}`}</div>
            <div className={styles.actions}>
              <IconButton variant="text" href={url} target="_blank">
                <RemoveRedEyeIcon />
              </IconButton>
              <IconButton variant="text" href={pdf}>
                <DownloadIcon  />
              </IconButton>
            </div>
          </Box>
        )
      })}
    </Box>
  )
}

export default History;
