import actionStatuses from "mappings/actionStatuses";
import statusMessages from "mappings/languages/en/statusMessages.json";
const ACTION_KEYWORDS = ["create", "update", "delete", "disapprove", "approve"];

function splitCamelCaseToWords(input) {
  const wordsArray = input.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");
  return wordsArray.join(" ");
}

const generateSmartStatus = (intention) => {
  const regex = new RegExp(`(${ACTION_KEYWORDS.join("|")})`, "i");
  const intentionArr = intention.split(regex);

  // const keyword = ACTION_KEYWORDS.find(keyword =>
  //   intention.toLowerCase().includes(keyword)
  // );

  if (intentionArr.length <= 1) {
    return;
  }

  let len = intentionArr.length,
    i = len - 1,
    action = "",
    noun = "";

  for (i; i >= 0; i--) {
    let item = intentionArr[i];

    if (item == "") {
      continue;
    }

    if (action == "") {
      action = item.toLocaleLowerCase() + "d";
      continue;
    }

    if (noun == "") {
      let splittedWords = splitCamelCaseToWords(item);
      noun = splittedWords.toLocaleLowerCase();
      break;
    }
  }

  if (noun == "" && action == "") {
    return false;
  } else {
    return {
      action: action,
      userAction: true,
      name: noun,
    };
  }
};



const detailedError = (errorCode = null) => {

  const messageLabel = statusMessages.messages[errorCode] || null
  if(!messageLabel) {
    return;
  }



  return {
    status: "error",
    message: messageLabel
  }
  
}

const generateMessage = (params) => {
  let template = "";

  if (params.status.custom) {
    template = params.status.custom;
    // template = params.isServerError || params.code >= 400 ? params.status.custom.bad : params.status.custom.good;
  } else if (params.status) {
    
    template =
      params.isServerError || params.code >= 400
        ? `The ${params.status.name} could not be ${params.status.action}`
        : `The ${params.status.name} has been ${params.status.action}`;
  }

  let status = "success";

  if (params.isServerError) {
    status = "error";
  } else if (params.code >= 400) {
    status = "info";
  }

  let messageInstance = {
    status: status,
    message: template,
  };


  return messageInstance;
};

export const checkIntention = (serverCode, code, intention, message = null) => {

  // console.log(message)

  let isServerError = false;

  if (serverCode >= 400) {
    isServerError = true;
  }

  if(message) {
    const getCodeError = detailedError(message);
    if(getCodeError) {
      return getCodeError;
    }
  }


  // check if the status was registered before or generate a custom one
  let actionStatus =
    actionStatuses[intention] || generateSmartStatus(intention) || {custom: message ?? "Something went wrong.."};

  if (isServerError || actionStatus.userAction) {
    let params = {
      isServerError: isServerError,
      code: code,
      status: actionStatus,
    };

    return generateMessage(params);
  } else {
    return;
  }
};
