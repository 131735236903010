import { useState, forwardRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ShadowSpinner from "components/loadings/ShadowSpinner";
import { useSelector, useDispatch } from "react-redux";
import { login, thirdPartyLogin } from "redux/auth/authThunk";
import { useParams, useLocation } from "react-router-dom";
import api from "_services/api";
import InteractWithUser from "components/userInteractions/InteractWithUser";
import Copyright from "components/Copyrights";
import { encodeData } from "utils/formatContent";
import { Divider } from "@mui/material";
import GoogleAuthBtn from "components/auth/GoogleAuthBtn/external";
import FacebookAuthBtn from "components/auth/FacebookAuthBtn/external";
import transparentLogoSrc from "assets/images/transp-logo.png";
import filledLogoSrc from "assets/images/logo.png";
import { isInputValid } from "utils/validateInputs";
import { getToken, setToken } from "_helpers/tokenManagement";
import { displayMessage } from "redux/status";

const theme = createTheme();

const ExternalLogin = ({ authType = "default", manual = false }) => {
  const dispatch = useDispatch();
  const { verifyCode } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authCodeParam = searchParams.get("code");
  const { loading, errors } = useSelector((state) => ({
    loading: state.auth.loading,
    errors: state.auth.errors,
  }));

  const [userState, setUserState] = useState({
    email: {
      errMsg: "",
      isRequired: true,
    },
    password: {
      errMsg: "",
      isRequired: true,
    },
  });

  const googleOptions = {
    method: "post",
    url: "google/oauth2/url",
  };
  const [open, setOpen] = useState(false);
  const referrer = getToken('referrer');
  const userAgent = getToken('userAgent');
  if (!referrer?.length) {
    setToken('referrer', document.referrer);
  }
  if (!userAgent) {
    setToken('userAgent', navigator?.userAgent);
  }

  useEffect(() => {
    if (verifyCode) {
      api
        .get(`users/verify/${verifyCode}`)
        .then((r) => {
          if (r.code >= 400) {
            return;
          }
          setOpen(true);
        })
        .catch(() => {});
    }
  }, [verifyCode != ""]);

  useEffect(() => {
    if (authCodeParam && authType != "default") {
      let params = {
        code: encodeData(authCodeParam),
        redirectUrl:
          window.location.origin +
          `/portal/${authType}/login` +
          (authType == "facebook" ? "/" : ""),
        type: authType,
      };

      params.referrer = referrer;
      params.userAgent = userAgent;

      dispatch(thirdPartyLogin(params));
    }
  }, [authCodeParam != ""]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");
    let password = data.get("password");

    let updates = {};
    let hasErrors = false;

    for (const field of data.entries()) {
      let keyData = userState[field[0]];
      // if(keyData.isRequired) {
      let state = isInputValid(field[1], {
        type: field[0],
        isRequired: keyData.isRequired,
      });

      updates[field[0]] = {
        isRequired: keyData.isRequired,
        errMsg: state.success ? "" : state.error || "Something wrong..",
      };

      if (state.error && !hasErrors) {
        hasErrors = true;
      }
    }

    setUserState((prevState) => ({
      ...prevState,
      ...updates,
    }));

    if (!hasErrors) {
      let prepareValues = {
        username: email,
        password: password,
        redirectTop: true,
        redirectUrl: 'https://younet.ai/portal/'
      };
      prepareValues.referrer = referrer;
      prepareValues.userAgent = userAgent;

      dispatch(login(prepareValues));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{ padding: 0 }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {verifyCode && (
            <InteractWithUser
              msg={"The email has been verified!"}
              display={open}
              type={"success"}
            />
          )}
          {errors.length > 0 && (
            <InteractWithUser
              msg={errors[0] || errors}
              display={errors.length > 0}
              type={"error"}
            />
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={userState.email.errMsg != ""}
              helperText={userState.email.errMsg ? userState.email.errMsg : ""}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={userState.password.errMsg != ""}
              helperText={
                userState.password.errMsg ? userState.password.errMsg : ""
              }
            />

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
            <Button
              onClick={()=> {
                window?.parent?.postMessage({isAuthentificated: true, redirectUrl: 'https://younet.ai/portal/sign-up'}, 'https://younet.ai');
              }}
              // fullWidth
              variant="text"
              sx={{ justifyContent:"flex-end"}}
            >
              Sign Up
            </Button>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item xs={12}>
                <Divider>or</Divider>
              </Grid>
              <Grid sx={{ mt: 2, mb: 0, padding: 0 }} item xs={12}>
                <GoogleAuthBtn
                  googleOptions={googleOptions}
                  redirectUrl={window.location.origin + "/portal/google/login"}
                  text={"Sign in with Google"}
                  fullWidth
                  manual={manual}
                />
              </Grid>
              <Grid sx={{ mt: 2, mb: 2, padding: 0 }} item xs={12}>
                <FacebookAuthBtn
                  redirectUrl={window.location.origin + "/portal/facebook/login/"}
                  text={"Sign in with Facebook"}
                  manual={manual}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ShadowSpinner open={manual != false}/>
      </Container>
    </ThemeProvider>
  );
};

export default ExternalLogin;
