import api from "_services/api";
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  FormHelperText,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Skeleton from "@mui/material/Skeleton";
import Collapse from "@mui/material/Collapse";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { useOutletContext } from "react-router-dom";
import { encodeData } from "utils/formatContent";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import EditingDialog from "components/dialogs/EditingDialog";
import MarkdownComponent from "components/MarkdownComponent";
import useStatefulQuery from "hooks/useStatefulQuery";
import EastIcon from "@mui/icons-material/East";
import trainingTypes from "mappings/trainingTypes.json";
import { useNavigate } from "react-router-dom";
import { checkFormForErrors } from "utils/validateInputs";
import { interactWithUser } from "redux/status";
import { useDispatch, useSelector, shallowEqual} from "react-redux";
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { updateCredits } from "redux/billing";
import {
  setStatus, updateCells
} from "redux/tools";
import styles from '../../training.module.css';
import {sendUIEvent} from "events/analytics/google";

const TextTraining = ({ id }) => {
  // const { modelId } = useOutletContext();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [isListLoading, setListLoading] = useState(false);
  const [isDataSending, setDataSending] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    title: "",
    text: "",
  });
  const model = useSelector((state) => state.models?.current, shallowEqual);
  const modelId = Boolean(model.id) ? model.id : id;

  const DEFAULT_MAX_WORDS = 1500;
  const [textState, setTextState] = useState({
    title: {
      errMsg: "",
      isRequired: true,
      additionals: {
        maxWordsAllowed: DEFAULT_MAX_WORDS,
      },
      // chained: {
      //   to: 'text',
      //   maxWordsAllowed: DEFAULT_MAX_WORDS
      // }
    },
    text: {
      errMsg: "",
      isRequired: true,
      additionals: {
        maxWordsAllowed: DEFAULT_MAX_WORDS,
      },
    },
  });

  const { data } = useStatefulQuery();

  const [page, setPage] = useState(0);
  const [textValue, setTextValue] = useState("");
  const [resetTextValueSignal, setResetTextValueSignal] = useState(false);
  // Brain clicked item
  const [clickedBrainButton, setClickedBrainButton] = useState(1);

  const [editingItem, setEditingItem] = useState({
    id: 0,
    title: "",
    text: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRedirect = (id, path) => {
    nav(path + "/" + id);
    sendUIEvent({name: `text_tool_view_item`});
  };

  const titleInputEl = useRef(null);
  const formEl = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setListLoading(true);
      if (modelId) {
        await api
          .get(`/learning/text/list/${modelId}`)
          .then((r) => {
            let parsedRows =
              r.code >= 400 || !r.text_memory_items ? [] : r.text_memory_items;
            setRows(parsedRows);
            setListLoading(false);
            if (Boolean(parsedRows.length)) {
              dispatch(setStatus({type: 'text', status: 'done'}));
            }
          })

          .catch((error) => {
            setListLoading(false);
          });
        }
      // setData(result.data);
    };

    fetchData();
  }, [modelId]);

  const handleViewContent = (item) => {
    setSelectedItem({
      id: item.id,
      title: item.title,
      text: item.data,
    });

    setExpanded(true);
  };

  const handleEditorChange = (newText) => {
    setEditingItem((prevState) => ({
      ...prevState,
      text: newText,
    }));
  };

  const handleEditClick = (item) => {
    setEditingItem({
      id: item.id,
      title: item.title,
      text: item.data,
    });
    setIsEditing(true);
  };

  const saveChanges = async (editorValue) => {
    let params = {
      text: editorValue,
      title: editingItem.title,
      dataId: editingItem.id,
      modelId: modelId,
    };

    setIsEditing(false);
    setDataSending(true);

    await api
      .put("/learning/text", params)
      .then((r) => {
        const { id, title, data } = r.model_data;
        handleAddRow(id, title, data);

        setDataSending(false);
      })
      .catch((error) => {
        setDataSending(false);
      });
  };

  const handleDeleteRows = (id) => {
    const newRows = rows.filter((row) => id != row.id);

    const deletedata = async () => {
      setListLoading(true);
      await api
        .delete(`/learning/text/${id}`)
        .then((r) => {
          setRows(newRows);
          setListLoading(false);
          // TODO: use it for progress
          // if (!newRows.length) {
          //   dispatch(setProgressItem({ modelId, progress: { text_resource_type: {passed: false, skipped: false} } }));
          //   manageProgressState(modelId, 'text_resource_type', false);
          // }
          dispatch(updateCells({increase: false}));
        })

        .catch((error) => {
          setListLoading(false);
        });
      // setData(result.data);
    };

    deletedata();
  };

  const addToBrain = async (editorValue) => {
    const form = new FormData(formEl.current);
    form.append("text", editorValue);

    let text = form.get("text");
    let title = form.get("title");

    let status = checkFormForErrors(form, textState);

    setTextState((prevState) => ({
      ...prevState,
      ...status.updates,
    }));

    let titleWords = title.split(' ');
    let textWords = text.split(' ');

    // quick token validation
    let totalWords = titleWords.length + textWords.length;
    if (totalWords > DEFAULT_MAX_WORDS) {
      dispatch(
        interactWithUser({
          message: `The total amount of words (${totalWords}) is more than maximum ${DEFAULT_MAX_WORDS}`,
          type: "info",
        })
      );

      return;
    }

    if (status.hasErrors) {
      return;
    }

    setTextValue(editorValue);

    let params = {
      text: encodeData(text),
      title: encodeData(title),
      modelId: modelId,
    };

    titleInputEl.current.value = "";
    setDataSending(true);
    setListLoading(true);
    if (modelId) {
      await api
        .post("/learning/text", params)
        .then((r) => {
          const { id, title, data, balance } = r.model_data;
          handleAddRow(id, title, data);
          setListLoading(false);
          setDataSending(false);
          setResetTextValueSignal(true);

          dispatch(updateCells({increase: true}));
          dispatch(setStatus({type: 'text', status: 'done'}));
          if(balance) {
            dispatch(updateCredits({newBalance: balance}));
          }
        })

        .catch((error) => {
          setListLoading(false);
          setDataSending(false);
        });
    }
  };

  const handleClose = () => {
    setExpanded(false);
    setSelectedItem({
      id: 0,
      title: "",
      text: "",
    });
  };

  const ContentShowcase = ({ isExpanded, selectedItem }) => {
    return (
      <Dialog open={isExpanded}>
        <DialogTitle sx={{ m: 0, p: 4 }}>{selectedItem.title}</DialogTitle>
        <DialogContent dividers>
          <MarkdownComponent source={selectedItem.text} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleAddRow = (id, title, data) => {
    const newRow = { id: id, title: title, data: data };
    setRows([newRow, ...rows]);
    // TODO: use it for progress
    // dispatch(setProgressItem({ modelId, progress: {text_resource_type: {passed: true, skipped: false}} }));
    // manageProgressState(modelId, 'text_resource_type', true);
  };

  // EmailRow.js Structure of the table
  function Row(props) {
    const { row } = props;

    const typePath = "/training/text" || "/training";

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row" className={styles.trim}>
            
            {row.title}
          </TableCell>
          {/* <TableCell
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            }}
          >
            {row.data}
          </TableCell> */}
          <TableCell />
          <TableCell align="right">
            {/* <IconButton onClick={() => handleViewContent(row)}>
              <VisibilityIcon />
            </IconButton> */}
            {/* <IconButton onClick={() => handleEditClick(row)}>
              <EditIcon />
            </IconButton> */}
            <IconButton onClick={() => handleDeleteRows(row.id)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => handleRedirect(row.id, typePath)}>
              <VisibilityIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      data: PropTypes.string.isRequired,
    }).isRequired,
  };

  const startIndex = page * 10;
  const endIndex = startIndex + 10;
  const currentData = rows.slice(startIndex, endIndex);

  return (
    <Grid
      container
      component="form"
      noValidate
      ref={formEl}
      // onSubmit={handleTextSubmit}
      spacing={6}
    >
      <Grid item xs={12} md={12}>
        <TextField
          inputRef={titleInputEl}
          id="title"
          label="Title"
          type="text"
          name="title"
          fullWidth
          error={textState.title.errMsg != ""}
          helperText={textState.title.errMsg ? textState.title.errMsg : ""}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomMdEditor
          value={textValue}
          onChange={function (value) {
            addToBrain(value);
          }}
          onChangeRequest={clickedBrainButton}
          placeholder="Add any contextual knowledge that you would like your AI model to memorize..."
          resetSignal={resetTextValueSignal}
          setResetSignal={setResetTextValueSignal}
          customHeight="150px"
        />
        <FormHelperText sx={{ color: "#d32f2f" }}>
          {textState.text.errMsg ? textState.text.errMsg : ""}
        </FormHelperText>
        {/* </Button> */}
      </Grid>

      <Grid display="flex" justifyContent="center" item md={12} xs={12}>
      <LoadingButton
          sx={{ borderRadius: "5px", textAlign: "right" }}
          color="primary"
          loading={isDataSending}
          loadingPosition="end"
          endIcon={<SendIcon />}
          onClick={(e) => {
            e.preventDefault();
            setClickedBrainButton(clickedBrainButton + 1);
          }}
          disabled={isListLoading}
        >
          Add to Brain
        </LoadingButton>

      </Grid>

      <Grid item xs={12} md={12}>
        <TableContainer
          component={Paper}
          // sx={{maxHeight: '300px'}}
        >
          <Table
            aria-label="collapsible table"
            fixedheader="true"
            fixedfooter="true"
          >
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Title</TableCell>
                <TableCell />
                <TableCell align="right" sx={{ paddingRight: "40px" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isListLoading ? (
                <>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                </>
              ) : rows ? (
                currentData.map((row) => <Row key={row.id} row={row} />)
              ) : null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rows.length}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
        <ContentShowcase isExpanded={isExpanded} selectedItem={selectedItem} />
        <EditingDialog
          isEditing={isEditing}
          editingItem={editingItem}
          handleEditorChange={handleEditorChange}
          saveChanges={saveChanges}
          setIsEditing={setIsEditing}
        />
      </Grid>
    </Grid>
  );
};

export default TextTraining;
