import { useState, useEffect } from 'react';
import api from '_services/api';
import useGetModelInstructions from './useGetModelInstructions';

const useGenerateModelInstructions = () => {
  const [modelInstructions, setModelInstructions] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const { handleGetModelInstructions, data } = useGetModelInstructions();

  const handleGenerateModelInstructions = (qa, type) => {
    setIsGenerating(true);
    setModelInstructions(null);
    setIsError(false);
    setRetryCount(0);

    api
      .post(`/model/instruction/generate`, { qa, type })
      .then((res) => {
        if (res.code >= 400 || res.status >= 400) {
          setIsError(true);
        }

        if (res?.items) {
          const modelsWithId = res?.items.map((item, i) => ({...item, id: 20+i}));
          setModelInstructions(modelsWithId);
          setIsGenerating(false);
        }
      })
      .catch(() => {
        setIsError(true);
      });
  };

  useEffect(() => {
    if (!isError || Boolean(modelInstructions?.length)) return;
    if (data?.length) {
      setModelInstructions(data);
      setIsGenerating(false);
    }
    if (!data && retryCount === 3) {
      setModelInstructions([]);
      setIsGenerating(false);
    }

    const intervalId = setInterval(() => {
      if (retryCount < 3 && Boolean(!modelInstructions?.length)) {
        setRetryCount((prevRetryCount) => prevRetryCount + 1);
        handleGetModelInstructions();
      } else {
        clearInterval(intervalId);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [retryCount, isError, modelInstructions, data]);

  return { handleGenerateModelInstructions, isGenerating, modelInstructions };
};

export default useGenerateModelInstructions;
