import styled from "@emotion/styled";

export const FacebookBtnStyle = styled.a`
    background-color: #3b5998;
    color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 8px 10px !important;
    ${({width}) => width ? `
    width: ${width}px;
  ` : ''}
    
    & > svg {
        margin-right: 5px;

    }

    ${props => props.disabled ? "opacity: 0.5; cursor: not-allowed; pointer-events: none;" : ""}
`;