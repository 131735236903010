import Grid from "@mui/material/Grid";
import LinksList from "./components/LinksList";
import styles from "./links-table.module.css";
import LinksToolBar from "./components/LinksToolBar";
import LinksPagination from "./components/LinksPagination";
import {memo} from "react";

const LinksTable = () => {

    // const list
    // console.log('links table')

    return (
        <Grid container className={styles['container']} flexDirection="column">
            <Grid item xs={12} className={styles['header-tools']}>
                <LinksToolBar/>
            </Grid>
            <Grid item xs={12} >
                <LinksList/>
            </Grid>
            <Grid item xs={12} className={styles['pagination']}>
                <LinksPagination/>
            </Grid>

        </Grid>
    )
}

export default memo(LinksTable);