import {memo, useMemo} from "react";
import {Checkbox, IconButton, TextField, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CodeBlock from "components/MarkdownComponent/components/CodeBlock";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";

const btnComment = '<!-- Place this code block at the desired position to render the button in the user interface. -->';
const fullCodeComment = '<!-- Add this block to the <head> or within the <body> at the desired location. -->';

const EmbeddedBtn = ({label = ""}) => {

    const btnText = label || 'Hello there';
    const btnCode = `${btnComment}\n<younet-chat-button>${btnText}</younet-chat-button>`;

    return (
        <CodeBlock language="html" value={btnCode}/>
    )
}

const EmbeddedCode = ({previewData}) => {

    const modelHash = useSelector(state => state.models.current.public_hash)
    const hash = modelHash || ''
    const type = previewData.type || 'bubble';
    const isBtnNeeded = type !== 'bubble';


    const code = useMemo(() => {
        let paramUrl = `https://widget.younet.ai/index.js?modelHash=${hash}&type=${type}`;
        const btnImg = previewData.btnImg ? encodeURIComponent(previewData.btnImg) : '';

        if (isBtnNeeded && btnImg !== '') {
            paramUrl += `&imgUrl=${btnImg}`
        }

        if (previewData.width) {
            paramUrl += `&width=${previewData.width}`;
        }

        if (previewData.height) {
            paramUrl += `&height=${previewData.height}`;
        }

        if (previewData.right) {
            paramUrl += `&right=${previewData.right}`;
        }

        if (previewData.bottom) {
            paramUrl += `&bottom=${previewData.bottom}`;
        }

        if (previewData.language) {
            paramUrl += `&language=${previewData.language}`;
        }
        return `${fullCodeComment}\n<script \n\ttype="module" \n\tsrc="${paramUrl}">\n</script>`;
    }, [previewData.type, previewData.width, previewData.height, previewData.right, previewData.bottom, previewData.language, previewData.btnImg]);


    return (
        <Grid container py={0} spacing={2}>
            <Grid item xs={12}>
                <Typography variant="describe_text" sx={{fontWeight: 500}}>Embedded HTML Code:</Typography>
            </Grid>
            {isBtnNeeded &&
                <Grid item xs={12}>
                    <EmbeddedBtn label={previewData.label}/>
                </Grid>
            }

            <Grid item xs={12}>
                <CodeBlock language="html" value={code}/>
            </Grid>
        </Grid>
    )
}

export default memo(EmbeddedCode);
