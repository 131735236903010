import {useEffect, useRef, useState} from "react";
import {Box, Typography} from "@mui/material";
import WebsiteWireframe from "./components/WebsiteWireframe";
import Widget from "./components/Widget";
import Grid from "@mui/material/Grid";
import BrowserWindow from "pages/protected/ShareModel/components/BrowserWindow";

const Preview = ({code = '', previewData = {}, isPublic}) => {
  const [parentHeight, setParentHeight] = useState(0);
    const wrapperRef = useRef(null);

    useEffect(() => {
      if (wrapperRef?.current) {
        setParentHeight(wrapperRef?.current?.offsetHeight);
      }
    }, [wrapperRef]);

    if (!isPublic) return null;

    return (
        <Grid sx={{width: '100%', height: '100%', flexFlow: 'column'}} container spacing={2}>
            <Grid item xs={12} flex="auto !important">
                <Typography sx={{fontSize: '24px', padding: {xs: '0 0 20px 10px', md: 0}}}>
                    Preview
                </Typography>
            </Grid>
            <Grid item xs={12} ref={wrapperRef}>
              <BrowserWindow>
                <WebsiteWireframe code={code} previewData={previewData} wrapperHeight={parentHeight} />
              </BrowserWindow>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <Widget previewData={previewData}/>*/}
            {/*</Grid>*/}


        </Grid>
    );

}

export default Preview;