import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

const SendDataButton = ({ isDataSending = false, onClickFunc = () => {}, fixed=false, type="button", isListLoading = false }) => {
  return (
    <LoadingButton
      sx={{ borderRadius: "5px", textAlign: "right",
       position: fixed ? 'absolute' : 'relative',
       top: fixed ? '50px' : 'unset',
       right: fixed ? '15px' : 'unset',
      }}

      type={type}
      color="primary"
      loading={isDataSending}
      loadingPosition="end"
      endIcon={<SendIcon />}
      onClick={onClickFunc}
      // variant="contained"
      disabled={isListLoading}
      variant="text"
    >
      Add to Brain
    </LoadingButton>
  );
};

export default SendDataButton;
