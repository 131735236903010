import ShareModel from "pages/ShareModel";
import Grid from "@mui/material/Grid";
import {Typography, Box} from "@mui/material";
import BrowserWindow from "pages/protected/ShareModel/components/BrowserWindow";


const Preview = ({manualHash = '', invitationsList}) => {
    if (!Boolean(invitationsList?.length)) return null;
    
    return (
        <Grid sx={{width: '100%', height: '100%', flexFlow: 'column'}} container spacing={2}>
            <Grid item xs={12} flex="auto !important">
                <Typography sx={{fontSize: '24px', padding: {xs: '0 0 20px 10px', md: 0}}}>
                    Preview
                </Typography>
            </Grid>
            <Grid item xs={12}>
              <BrowserWindow>
                <ShareModel manualHash={manualHash} hideSideBar={true} preview={true} />
              </BrowserWindow>
              
            </Grid>
        </Grid>

    )
}
export default Preview;
