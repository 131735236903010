import Grid from "@mui/material/Grid";
import LinksTable from "./components/LinksTable";
import LinkContentView from "./components/LinkContentView";

const ViewWebsite = ({content = undefined}) => {

    const isContentView = content === 'view';
    const layout = isContentView ? {xs: 12, sm: 5, md: 5} : {xs: 12, sm: 12, md: 12};

    return (
        <Grid container spacing={6}>
            <Grid item xs={layout.xs} sm={layout.sm} md={layout.md}>
                <LinksTable/>
            </Grid>
            {isContentView && <Grid item xs={12} sm={7} md={7}>
                <LinkContentView/>
            </Grid>
            }
        </Grid>
    )
}

export default ViewWebsite;