import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import api from "_services/api";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import useDeviceDetect from "hooks/useDetectDevice";
import useCopyToDevice from "hooks/useCopyToDevice";
import { setToken } from "_helpers/tokenManagement";
import { setBrainOnboarding } from "redux/tools";
import { useDispatch } from "react-redux";

const GoogleAuthBtn = ({
  width = null,
  redirectUrl,
  redirectUser = "",
  text = "",
  fullWidth = false,
  googleOptions = {},
}) => {
  const [url, setUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { copy } = useCopyToDevice();
  const { isInAppView } = useDeviceDetect();
  const dispatch = useDispatch();

  const Icon = styled.i`
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 0;
    width: 25px;
    height: 20px;
  `;

  const Btn = styled.a`
    ${fullWidth ? "justify-content: center;width: 100%;" : "max-width: 240px;"}
    ${width ? `width: ${width}px;` :  ''}
    display: flex;
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 8px 10px !important;
    border: none;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px -1px 0px, rgba(0, 0, 0, 0.25) 0px 1px 3px;
    color: #757575;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.08) 0px -1px 0px, rgba(0, 0, 0, 0.25) 0px 2px 5px;
    }

    &:active {
      background-color: #eeeeee;
    }

    &:focus {
      outline: none;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
        0 0 0 3px #c8dafc;
    }

    ${props => props.disabled ? "opacity: 0.5; cursor: not-allowed; pointer-events: none;" :""}
  `;

  useEffect(() => {

    const fetchData = async () => {

      let params = {
        redirectUrl: redirectUrl,
      };

      try {
        let response;
        if (googleOptions.method == "get") {
          response = await api.get(googleOptions.url);
        } else if (googleOptions.method == "post") {
          response = await api.post(googleOptions.url, params);
        } else {
          return;
        }

        if (response.code >= 400) {
          throw Error("Something went wrong..");
        }

        let authUrl = response.authUrl || "";

        setUrl(authUrl);
      } catch (error) {
        console.error(error); // Handle any errors
      }
    };

    fetchData();
  }, []);

  const handleButtonWarning = (e) => {
    // Prevent default behaviour if inAppBrowser is true
    if (isInAppView) {
      e.preventDefault();
      // Show the user the alert message: "Unable to open, please copy link to phone browser to proceed." with the option to copy the link to the clipboard
      setShowModal(true);
    }
  };

  const handleCopyClick = () => {
    copy(window.location.href);
    setShowModal(false);
  };

  const handleRedirect = (e) => {
    e.preventDefault();
    redirectUser && setToken("loginRedirectUrlSession",redirectUser)
    if(isInAppView) {
      handleButtonWarning();
    } else {
      window.location.href = url;
    }    
  }

  return (
    <>
      <Btn
        variant="contained"
        color="primary"
        onClick={handleRedirect}
        disabled={!Boolean(url)}
      >
        <Icon /> {text}
      </Btn>

      {isInAppView &&
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <DialogTitle>Unable to open</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please copy the link to the phone browser to proceed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCopyClick} color="primary">
              Copy Link
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

export default GoogleAuthBtn;
