import {Avatar, Box, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../../topbar.module.css";
import ProgressBar from "components/showcase/ProgressBar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Logout from "@mui/icons-material/Logout";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import React, {useMemo, useState} from "react";
import {getInitials} from "utils/prepareNames";
import {useNavigate} from "react-router-dom";
import {logout} from "redux/auth";
import LocalChatBotHelper from "components/userInteractions/LocalChatBotHelper";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import {sendUIEvent} from "../../../../../events/analytics/google";

const AccountMenu = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [avatarDropdown, setAvatarDropdown] = useState(null);
    const {smallScreen} = useIsSmallScreen();

    const avatarOpen = Boolean(avatarDropdown);


    const avatarHandleClick = (event) => {
        setAvatarDropdown(event.currentTarget);
        sendUIEvent({name: `tmb_avatar_click`});
    };
    const avatarHandleClose = () => {
        setAvatarDropdown(null);
    };

    const handleLogout = () => {
        sendUIEvent({name: `tmb_avatar_dropdown_logout_click`});
        dispatch(logout({redirect: {hard: true}}));
    };


    let {user, planInfo} = useSelector((state) => ({
            user: state.auth.user,
            planInfo: state.billing.planInfo,
        }),
        shallowEqual
    );

    let fullNameInitials = useMemo(() => {
        return getInitials(user.fullName)
    }, [user.fullName])

    return (
        <>
            <Tooltip title="Account settings">
                <Box display="flex" alignContent="center" alignItems="center">
                    {/*<Grid>*/}
                    {!smallScreen && <LocalChatBotHelper/>}
                    <Avatar src={user.avatar ? user.avatar : ''} sx={{
                        width: 32,
                        height: 32,
                        fontSize: 15,
                        border: '1px solid #00000011'
                    }}
                            onClick={avatarHandleClick}
                    >{fullNameInitials}</Avatar>
                    {/*</Grid>*/}
                    {/*<Grid>*/}
                    <IconButton
                        size="small"
                        sx={{width: '18px', height: '18px', margin: '5px 5px 0 5px'}}
                        aria-controls={avatarOpen ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={avatarOpen ? 'true' : undefined}
                        onClick={avatarHandleClick}
                    >
                        <ExpandMoreIcon fontSize='10px'/>
                    </IconButton>
                    {/*</Grid>*/}
                </Box>
            </Tooltip>
            <Menu
                anchorEl={avatarDropdown}
                open={avatarOpen}
                onClose={avatarHandleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 3,
                        width: '240px',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem sx={{py: 4, width: '100%', justifyContent: 'center', pointerEvents: 'none'}} divider>
                    <Grid container>
                        <Grid item xs={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Avatar src={user.avatar ? user.avatar : ''}
                                    sx={{fontSize: 15, border: '1px solid #00000011'}}>{fullNameInitials}</Avatar>
                        </Grid>
                        <Grid item xs={9} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography sx={{width: '100%', fontWeight: "bold"}} variant="h3">
                                {user.fullName}
                            </Typography>
                            <Typography sx={{width: '100%', whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}
                                        variant="span">
                                {user.email}
                            </Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
                <Box sx={{
                    py: 4,
                    width: '100%',
                    justifyContent: 'center',
                    borderBottom: '1px solid #e0e0e0',
                    position: 'relative'
                }}>
                    <Typography ml={5} sx={{fontSize: '10px', position: 'absolute', top: '6px', color: '#898787'}}
                                variant="span">
                        {planInfo.currentPlan.name}
                    </Typography>
                    <Tooltip
                        title={`You have ${planInfo.availableCredits} out of ${planInfo.maximumAllowedCredits} credits left`}>
                        <Box className={styles["flex-space-around"]}>
                            <ProgressBar className={styles["progress-bar"]}
                                         maxValue={planInfo.maximumAllowedCredits}
                                         currentValue={planInfo.availableCredits}/><Typography onClick={() => {
                            navigate("/billing/balance")
                        }} className={styles["progress-bar-credits"]}
                                                                                               variant="page_content">{`${planInfo.availableCredits}/${planInfo.maximumAllowedCredits}`}</Typography>
                            {planInfo.currentPlan.name?.toLowerCase() !== 'pro 100k' && (
                                <Link href="/portal/billing/subscribe"
                                      style={{fontSize: '11px', color: '#40b2f2', marginRight: '10px'}}
                                      underline="none">
                                    Upgrade
                                </Link>
                            )}
                        </Box>
                    </Tooltip>
                </Box>
                <MenuItem sx={{py: 3, px: 5, width: '100%'}} onClick={() => {
                    navigate('/settings');
                    avatarHandleClose()
                    sendUIEvent({name: `tmb_avatar_dropdown_account_settings_click`});
                }}>
                    Account Settings
                </MenuItem>
                {/* Return later */}
                {/* <MenuItem sx={{py:3,px:5,width:'100%'}}>
          Plans and Billing
        </MenuItem> */}
                <MenuItem sx={{py: 3, px: 5, width: '100%'}} onClick={() => {
                    navigate('/monetization');
                    avatarHandleClose()
                    sendUIEvent({name: `tmb_avatar_dropdown_monetization_click`});
                }}>
                    Monetization Center
                </MenuItem>
                <MenuItem sx={{py: 3, px: 5, width: '100%'}} onClick={() => {
                    navigate('/billing/balance');
                    avatarHandleClose()
                    sendUIEvent({name: `tmb_avatar_dropdown_balance_click`});
                }}>
                    Billing
                </MenuItem>
                <a href='https://support.younet.ai' target='_blank' rel="noopener noreferrer">
                    <MenuItem sx={{py: 3, px: 5, width: '100%'}}>
                        Help Center<OpenInNewIcon fontSize="10" sx={{margin: '0 3px', color: '#7b8191'}}/>
                    </MenuItem>
                </a>
                <a href='https://status.younet.ai/status/younet' target='_blank' rel="noopener noreferrer">
                    <MenuItem sx={{py: 3, px: 5, width: '100%'}}>
                        System Status<OpenInNewIcon fontSize="10" sx={{margin: '0 3px', color: '#7b8191'}}/>
                    </MenuItem>
                </a>
                <MenuItem sx={{py: 3, width: '100%'}} onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default AccountMenu;
