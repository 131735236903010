import React, { memo, useEffect, useRef, useState } from 'react';

const ScrollDown = ({ triggerArray = [], disableOnScrollUp = false, isMsgSending, scrollContainer, isLoadMore }) => {
  
  const lastElement = useRef(null);
  const observerRef = useRef(null);
  const [isVisible, setLastElementVisible] = useState(true);
  
    const scrollInto = () => {
        if (!lastElement.current || isLoadMore) return;
        lastElement.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    const scrollCheckerCallback = (entries) => {
        const isTargetVisible = entries[0].isIntersecting;
        setLastElementVisible(isTargetVisible);

    }

    const unobserveScroll = () => {
        if (observerRef.current) {
            if(lastElement.current) {
                observerRef.current.unobserve(lastElement.current);
            } else {
                observerRef.current.disconnect()
            }
            
        }
    };

    const observeScroll = () => {
        if (observerRef.current && lastElement.current) {
            observerRef.current.observe(lastElement.current);
        }
    };

    // setting observer to detect visibility changes
    useEffect(() => {
        if (!disableOnScrollUp) {
            return;
        }

        const options = {
            root: scrollContainer.current,
            rootMargin: '150px 0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver(scrollCheckerCallback, options);
        observerRef.current = observer;


        // Start observing the last element
        // observeScroll();

        // Cleanup the observer on component unmount
        return () => {
            unobserveScroll();
        };
    }, []);

    useEffect(() => {
        if (isMsgSending && !isVisible) return;
        scrollInto();

    }, triggerArray);

    useEffect(() => {

        if (isMsgSending) {
            observeScroll();
        } else {
            setLastElementVisible(true);
            unobserveScroll();
        }
        scrollInto();
    }, [isMsgSending]);

    return <div style={{ width: '100%', height: 1 }} ref={lastElement}></div>;
};

export default memo(ScrollDown);