import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import api from "_services/api";
import { STANDART_GPT_MODEL } from 'constants';
import { interactWithUser } from "redux/status";
import { useDispatch } from "react-redux";
import ModelSettingForm from "./modelsetting/ModelSettingForm";

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid sx={{ p: 3 }}>
                    {children}
                </Grid>
            )}
        </div>
    );
}

const GeneralSetting = ({ selectedTag }) => {
    const dispatch = useDispatch();
    const [modelSetting, setModelSetting] = useState({
        gpt_model: STANDART_GPT_MODEL,
        contextWindowSizePercent: 80,
    });

    useEffect(() => {
        api.get('/user/settings/current')
            .then(response => {
                if (response.code === 200) {
                    setModelSetting(response.setting);
                }
            })
            .catch(error => {
                dispatch(
                    interactWithUser({
                        message: `Error fetching model settings: ${error}`,
                        type: "error",
                    })
                );
            });
    }, []);

    const handleGeneralSave = (e) => {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        let gptModel = form.get("gpt_model");
        // Parse the value as an integer
        let contextWindowSize = parseInt(form.get("contextWindowSizePercent"));
        // If the value is not a number, set it to 80
        contextWindowSize = isNaN(contextWindowSize) ? 80 : contextWindowSize;

        const formData = new FormData();
        formData.append("gptModel", gptModel);
        formData.append("contextWindowSize", contextWindowSize);
        api.post('/user/settings/update', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
                if (response.code === 200) {
                    dispatch(
                        interactWithUser({
                          message: `General settings were updated`,
                          type: "success",
                        })
                    );
                }
            }
            )
            .catch(error => {
                console.error("Error updating model settings: ", error);
            }
            );
    }

    const handleModelChange = (event) => {
        const { name, value } = event.target;
        setModelSetting((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <CustomTabPanel value={selectedTag} index={0}>
            <ModelSettingForm handleGeneralSave={handleGeneralSave} handleModelChange={handleModelChange} modelSetting={modelSetting}/>
        </CustomTabPanel>
    );
}

export default GeneralSetting;
