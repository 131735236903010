import { Box } from '@mui/material';
import classnames from 'classnames';
import styles from '../subs-table.module.css';

const SubsHeader = () => {
  return (
    <Box className={classnames(styles.line, styles.headerLine)}>
      <div>Joined Date</div>
      <div>Customer Name</div>
      <div>Model Name</div>
      <div>Actions</div>
    </Box>
  );
};

export default SubsHeader;