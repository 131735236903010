import { CircularProgress, Grid, IconButton } from "@mui/material";
import MarkdownComponent from "components/MarkdownComponent";
import { useState } from "react";
import { decodeData, encodeData } from "utils/formatContent";
import api from "_services/api";
import { useDispatch } from "react-redux";
import { interactWithUser } from "redux/status";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import CustomMdEditor from "components/inputs/CustomMdEditor";
import ShowMore from "../userInteractions/ShowMore";

const BrainCell = ({ item, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [data, setData] = useState(item);

  const dispatch = useDispatch();

  var savedData = "";

  const handleEditorChange = (data) => {
    savedData = data;
  };

  const handleEditClick = () => {
    setEditMode(true);
    savedData = "";
  };

  const handleEditClose = () => {
    setEditMode(false);
    savedData = "";
  };

  const handleDeleteRows = (vector_id) => {
    const deleteData = async () => {
      setLoading(true);
      await api
        .delete(`/learning/chunk/${vector_id}`)
        .then((r) => {
          setLoading(false);
          if (r.code == 200) {

            onDelete(vector_id)

            // navigate("/training/brain");
            return;
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    deleteData();
  };

  const handleSave = async () => {

    let params = {
      data: savedData,
    };

    setLoading(true);
    await api
      .put(`/learning/chunk/${data.vector_id}`, params)
      .then((r) => {
        setLoading(false);

        setData((prevState) => ({
          ...prevState,
          ...{ data: encodeData(savedData) },
        }));

        // if (r.code >= 400) {
        //   dispatch(
        //     interactWithUser({
        //       message: "Could not update this brain knowledge",
        //       type: "error",
        //     })
        //   );
        // } else {
        //   dispatch(
        //     interactWithUser({
        //       message: "Brain Cell was updated successfully",
        //       type: "success",
        //     })
        //   );
        // }

        setEditMode(false);
      })
      .catch((error) => {
        dispatch(interactWithUser({ template: "DEFAULT_ERROR" }));
        setLoading(false);
        setEditMode(false);
      });
  };

  const textData = data.data ? decodeData(data.data) : "";
  const titleData = data.title ? decodeData(data.title) : "";

  return (
    <Grid
      container
      sx={{
        height: "inherit",
        overflowY: "auto",
        padding: "10px 20px 0",
        border: "1px solid #eee",
      }}
    >
      <Grid item md={11}>
        {isEditMode ? (
          <CustomMdEditor
            value={textData}
            onChange={handleEditorChange}
            customHeight="100%"
          />
        ) : (
          <ShowMore><MarkdownComponent source={textData} /></ShowMore>
        )}
      </Grid>
      {loading ? (
        <Grid item md={1} sx={{ textAlign: "right" }}><CircularProgress size={20} /></Grid>
      ) : (
        <>
          <Grid item md={1} sx={{ textAlign: "right" }}>
            {isEditMode ? (
              <>
                <IconButton onClick={handleEditClose}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={() => handleSave()}>
                  <SaveIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton onClick={() => handleDeleteRows(data.vector_id)}>
                  <DeleteIcon color="icons" />
                </IconButton>
                <IconButton onClick={() => handleEditClick()}>
                  <EditIcon color="icons" />
                </IconButton>
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default BrainCell;
