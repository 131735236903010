import { useRef } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import {sendUIEvent} from "../../../../../events/analytics/google";

const SearchInput = ({ widesidebar, onSubmitHandler, outlined = false }) => {
  const inputRef = useRef(null);

  if (!widesidebar) {
    return null;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    onSubmitHandler(inputRef?.current?.value);
    if(inputRef?.current?.value !== '') {
        sendUIEvent({name: `explore_ai_nav_search`});
    }

  }

  const debouncedOnChange = debounce(submitHandler, 1000);

  return (
    <Box
      pl={outlined ? 2 : 6}
      pr={outlined ? 2 : 6}
      mb={outlined ? 4 : 0}
      width="100%"
      component="form"
      onSubmit={submitHandler}
      sx={{
        border: outlined ? '1px solid rgba(0,0,0, .1)' : 'none',
        borderRadius: '8px',
      }}
    >
      <TextField
        variant="standard"
        rows={1}
        inputRef={inputRef}
        sx={{
          width: '100%',
          "& input::placeholder": { fontSize: { xs: '1.125rem', md: '0.875rem' }},
          "& input": { padding: { xs: '12px 0', md: '5px 0' }},
        }}
        placeholder="Search for AI"
        onChange={debouncedOnChange}
        InputProps={{
          startAdornment: (
            <IconButton sx={{ p: '5px', marginRight: '5px' }} aria-label="search">
              <SearchIcon />
            </IconButton>  
          ),
          disableUnderline: true, // <== added this
        }}
      />
    </Box>
  )
};

export default SearchInput;
