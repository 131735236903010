/* eslint-disable no-param-reassign */
import React, { useState, useMemo, useEffect, useRef, cloneElement, Children, memo } from 'react';
import { StepperContext } from './StepperContext';
import usePrevious from 'hooks/usePrevious';

const getStepsKeysFromChildren = (children) => {
  const elements = Children.toArray(children);
  const steps = elements.map((element) => (element.props.stepKey || ''));

  return steps;
};

const Stepper = ({
  children,
  initialStep = '',
  innerRef,
  onFinish = () => {},
  onChangeStep,
}) => {
  const steps = useMemo(() => getStepsKeysFromChildren(children), [children]);

  const initialStepIndex = Math.max(steps.indexOf(initialStep), 0);
  const [currentStep, setCurrentStep] = useState(initialStepIndex);

  const maxStep = steps.length - 1;
  const currentStepKey = steps[currentStep];

  const { previous, forceSetPrevious } = usePrevious(currentStepKey);

  const setNextStep = () => setCurrentStep((step) => (step === maxStep ? maxStep : step + 1));

  const setStep = (step) => {
    const nextStep = Math.max(steps.indexOf(step), 0);

    setCurrentStep(nextStep);
  };

  const providerValue = {
    steps,
    setNextStep,
    currentStep,
    currentStepKey,
    prevStepKey: previous,
    maxStep,
    setStep,
    onFinish,
    setPreviousStep: forceSetPrevious,
  };

  useEffect(() => {
    setCurrentStep(initialStepIndex);
  }, [initialStepIndex]);

  useEffect(() => {
    if (!innerRef) return;

    innerRef.current = providerValue;
  }, [providerValue]);

  useEffect(() => {
    onChangeStep?.(currentStepKey);
  }, [currentStepKey]);

  const childrenList = Children.toArray(children).map((element, index) => {
    return cloneElement(element, { ...element.props, step: index });
  });

  return <StepperContext.Provider value={providerValue}>{childrenList}</StepperContext.Provider>;
};

export default memo(Stepper, (prevProps, nextProps) => {
  const prevSteps = getStepsKeysFromChildren(prevProps.children);
  const nextSteps = getStepsKeysFromChildren(nextProps.children);

  if (nextProps.updateAnyway || prevSteps.length !== nextSteps.length) return false;

  const isEqual = prevSteps.every((step, index) => step === nextSteps[index]);

  return isEqual;
});
