import { useState, useEffect } from "react";

const useViewportHeight = () => {
    const [height, setHeight] = useState(window.innerHeight);
  
    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };
  
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return height;
  };

export default useViewportHeight