import { useSelector } from 'react-redux';
import InteractWithUser from 'components/userInteractions/InteractWithUser';

const StatusMessageState = () => {

 const status = useSelector(state => {return state.status});
 let shouldBeOpen = status.message != '';
 let type = status.code ? (status.code >= 400 ? "error" : "success") : status.type;

 if(!shouldBeOpen) {
  return;
 }


 return (
    <InteractWithUser display={shouldBeOpen} type={type} msg={status.message}/>
 )

}


export default StatusMessageState;