import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";
import {memo} from "react";
import Checkbox from "@mui/material/Checkbox";
import {Tooltip, Typography} from "@mui/material";
import SmoothContent from "components/loadings/SmoothContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";


const LOADING_PRESET_NUM = 20;


const LoadingRow = memo(() => {

    return (
        <TableRow
            sx={{
                "&:last-child td, &:last-child th": {border: 0},
                "&:hover": {
                    backgroundColor: "#efefef",
                },
                cursor: "pointer",
            }}
        >

            <TableCell sx={{maxWidth: '40px'}} padding="none" component="th" scope="row">
                <Checkbox
                    sx={{opacity: 0.6}}
                    checked={false}
                    disabled={false}
                />
            </TableCell>
            <TableCell sx={{maxWidth: '20%'}} padding="none">
                <SmoothContent height={18} width={160}/>
            </TableCell>
            <TableCell sx={{maxWidth: '40%'}} padding="none">
                <SmoothContent height={18} width={650}/>
            </TableCell>
            {/*<TableCell component="th" scope="row"/>*/}

            <TableCell/>
            <TableCell component="th" scope="row">
                <SmoothContent height={18} width={70}/>
            </TableCell>
            <TableCell sx={{padding: "0 5px 0 0"}} align="right">
                <SmoothContent height={18} width={70}/>
            </TableCell>
        </TableRow>
    )
});

const MobileLoadingRow = memo(() => {
    return (
        <TableRow>
            <TableCell colSpan={5} align="center">
                <Skeleton animation="wave"/>
            </TableCell>
        </TableRow>
    )
});


const EmailsLoadingList = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (isMobile) {
        return ([...Array(LOADING_PRESET_NUM)].map((_, i) => (
            <MobileLoadingRow key={`loading-row-email-${i}`}/>
        )))
    }

    return ([...Array(LOADING_PRESET_NUM)].map((_, i) => (
        <LoadingRow key={`loading-row-email-${i}`}/>
    )))
}


export default memo(EmailsLoadingList);
