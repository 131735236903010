import React from 'react';

const LogoSvgIcon = ({width = 40, height = 29}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 29.312" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="linear-gradient" x1="0.09" y1="0.293" x2="0.894" y2="0.743"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#a061f3"/>
                    <stop offset="0.471" stopColor="#6074ee"/>
                    <stop offset="1" stopColor="#1fa6f4"/>
                </linearGradient>
            </defs>
            <path
                d="M13.6,28.016a1.3,1.3,0,1,1,1.3,1.3A1.3,1.3,0,0,1,13.6,28.016Zm6.015-4.13H10.989a1.3,1.3,0,1,1-.042-.648H19.66a1.3,1.3,0,1,1-.042.648Zm-7.589-5.425H6.769a1.619,1.619,0,1,1,0-.648h5.219a2.591,2.591,0,0,1,5.172,0h5.867a1.62,1.62,0,0,1,3.174,0h5.247a1.619,1.619,0,1,1,0,.648H26.2a1.62,1.62,0,0,1-3.174,0H17.121a2.592,2.592,0,0,1-5.091,0ZM7.8,12.226H2.527a1.3,1.3,0,1,1,.042-.648H7.783a2.915,2.915,0,0,1,5.81,0h5.218a2.025,2.025,0,0,1,4,0h3.773a1.3,1.3,0,0,1,2.546,0h8.3a1.3,1.3,0,1,1,.042.648H29.085a1.3,1.3,0,0,1-2.462,0H22.808a2.025,2.025,0,0,1-4,0H13.575a2.915,2.915,0,0,1-5.774,0ZM30.982,6.882H18.411a1.62,1.62,0,0,1-3.137,0H7.722a1.62,1.62,0,1,1,.033-.648h7.486a1.62,1.62,0,0,1,3.2,0H30.949a1.619,1.619,0,1,1,.033.648ZM24.666,2.024H15.5a1.62,1.62,0,1,1,.033-.648h9.105a1.619,1.619,0,1,1,.033.648Z"
                transform="translate(0 0)" fill="url(#linear-gradient)"/>
        </svg>
    );
}

export default LogoSvgIcon;