import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GeneralTooltip from "components/tooltip/GeneralTooltip";

const Hint = ({ text }) => {
  return (
    <GeneralTooltip
      placement='bottom'
      title={text}
    >
      <HelpOutlineIcon fontSize='small' sx={{color:'#B5B5B5'}}/>
    </GeneralTooltip>
  )
}

export default Hint;
