import { createSlice } from "@reduxjs/toolkit";

const messages = {
  DEFAULT_ERROR: {
    type: 'error',
    content: 'Something went wrong. Please, try again later..'}
    ,
  JWT_TOKEN_EXPIRED: {
    type: 'info',
    content: 'Session Timeout. Please, re-login again..'
  }

}


const initialState = {
  message: "",
  type: "",
  code: 0,
  maintenance: {
    active: false,
    message: ""
  },
};

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setMaintenance(state,action) {
      let {active, message} = action.payload;
      state.maintenance = {active,message};
    },
    interactWithUser(state, action) {

      let data = action.payload;

      let template = messages[data.template];
      if(data.template && template) {
        state.message = template.content;
        state.type = template.type;
        return;
      }

      const { message, type, code } = action.payload;
      state.message = message;
      state.type = type;
      if (code) {
        state.code = code;
      }
    },
    // showTemplateMsg(state,temp) {
    //   state.message = messages['default_error'];
    //   state.type = 'error';
    // },
    // defaultError(state) {
    //   state.message = messages['default_error'];
    //   state.type = 'error';
    // },
    close(state) {
      return initialState;
    },

  },
  extraReducers: (builder) => {},
});

export const { interactWithUser, close, setMaintenance } = statusSlice.actions;

export default statusSlice.reducer;
