import Grid from "@mui/material/Grid";
import FlexWrapper from "components/wrappers/FlexWrapper";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import api from "_services/api";
import downloadFromWeb from "utils/downloadFromWeb";

const handlePrint = () => {

    function setPrint() {
        const closePrint = () => {
            document.body.removeChild(this);
        };
        this.contentWindow.onbeforeunload = closePrint;
        this.contentWindow.onafterprint = closePrint;
        const imgHTML = document.getElementById('printable-qr-code').outerHTML;

        const htmlContent = `<html>
                    <head>
                        <title>Print QR Code</title>
                        <style>
                            body {
                                margin: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                            }
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        </style>
                    </head>
                    <body>
                        ${imgHTML}
                    </body>
                </html>`
        this.contentWindow.document.open();
        this.contentWindow.document.write(htmlContent);


        this.contentWindow.document.close();
        this.contentWindow.print();


    }

    const hideFrame = document.createElement("iframe");
    hideFrame.onload = setPrint;
    hideFrame.style.display = "none";
    document.body.appendChild(hideFrame);

}

const QrCode = ({qr = {}}) => {

    const [blobUrl, setBlobUrl] = useState('');


    useEffect(() => {

        if (qr?.public_url) {
            api.get(`/file/download/${qr.id}`, {responseType: 'blob'})
                .then((response) => {
                    const blobUrl = URL.createObjectURL(response);
                    setBlobUrl(blobUrl)
                    // setImgData({blob: response, url: blobUrl});

                })
                .catch((error) => {
                    console.warn(error);
                });
        }

    }, [qr?.public_url]);

    const handleOnDownload = useCallback(async () => {
        await downloadFromWeb(blobUrl, qr.type, qr.file_name);
    }, [blobUrl, qr.type, qr.file_name]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <FlexWrapper>
                    <Paper id="printable-qr-code" variant="outlined">
                        <img width="320px" height="320px" src={qr?.public_url} alt={'Qr code image'}/>
                    </Paper>
                </FlexWrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <FlexWrapper>
                    <Button onClick={handleOnDownload} size="small" variant="contained">Download</Button>
                    <Button onClick={handlePrint} sx={{marginLeft: '45px'}} size="small" variant="text">Print</Button>
                </FlexWrapper>
            </Grid>
        </Grid>
    )
}

export default QrCode;
