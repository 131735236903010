import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userPermissions: "*",
  planLimitations: {}
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissionsAndLimitations(state, action) {
      const { limitations, permissions } = action.payload;
      
      // if(permissions) {
      state.userPermissions = permissions == null ? "*" : permissions;
      // }

      if(limitations) {
        let associatedLimitations = {};
        limitations.forEach(item => {
          associatedLimitations[item.name] = item;
        });
  
        state.planLimitations = associatedLimitations;
      }

    },

  },
  extraReducers: (builder) => {

  },
});

export const { setPermissionsAndLimitations } = permissionsSlice.actions;

export default permissionsSlice.reducer;
