/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {Grid,Select,FormControl,MenuItem,Slider,Button, Typography}from '@mui/material';
import { STANDART_GPT_MODEL, ENHANCED_GPT_MODEL } from 'constants';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GeneralTooltip from "components/tooltip/GeneralTooltip";
import { styled } from '@mui/material/styles';

// const SidebarTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
//   ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//       padding:'20px',
//       backgroundColor: 'white',
//       color: '#757575',
//       fontSize: theme.typography.pxToRem(14),
//       filter: 'drop-shadow(0px 1px 5px rgba(0,0,0,0.12))',
//     },
//   }));

const ModelSettingForm = ({handleGeneralSave,handleModelChange,modelSetting}) => {
    return (
        <Grid container pt={6} mx={{ md: 6 }}>
            <Grid container px={6} py={2} component="form" noValidate onSubmit={handleGeneralSave}>
                <Grid container>
                    <Grid item xs={12} md={6} py={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Version of processing AI&nbsp;
                            <GeneralTooltip placement='bottom'
                                title={'The Version Selection feature in Processing AI allows you to choose the specific version of the AI model that you want to use for your processing tasks. This feature enables you to select the most suitable version based on your requirements, ensuring compatibility and optimal performance. Easily switch between different versions to experiment, compare results, or utilize specific improvements or features introduced in newer versions.'}
                            ><HelpOutlineIcon fontSize='small' sx={{color:'#B5B5B5'}}/>
                            </GeneralTooltip>
                    </Grid>
                    <Grid item xs={12} md={6} py={2}>
                        <FormControl sx={{ width: { md: '300px', xs: '100%' } }}>
                            <Select
                                labelId="gpt_model-label"
                                id="gpt_model"
                                name="gpt_model"
                                value={modelSetting.gpt_model ?? ''}
                                onChange={handleModelChange}
                            >
                                <MenuItem value={STANDART_GPT_MODEL}>Standard <Typography marginLeft={1} variant="describe_text"> (2 credits / request)</Typography></MenuItem>
                                <MenuItem value={ENHANCED_GPT_MODEL}>Enhanced <Typography  marginLeft={1} variant="describe_text"> (10 credits / request)</Typography></MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} py={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        Data Visibility&nbsp;
                            <GeneralTooltip placement='bottom'
                                title={'Adjust the Data Visibility feature to increase or decrease the level of context for AI model responses. Increase for more comprehensive answers, decrease for more concise ones. Find the right balance for your needs.'}
                            ><HelpOutlineIcon fontSize='small' sx={{color:'#B5B5B5'}}/>
                            </GeneralTooltip>
                    </Grid>
                    <Grid item xs={12} md={6} py={10}>
                        <FormControl sx={{ width: { md: '300px', xs: '100%' } }}>
                            <Slider name="contextWindowSizePercent" step={10}
                                marks
                                min={10}
                                max={100} valueLabelDisplay="on"
                                valueLabelFormat={modelSetting.contextWindowSizePercent+'%'}
                                value={modelSetting.contextWindowSizePercent || 50}
                                onChange={handleModelChange} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Button type="submit" variant="contained" sx={{ width: '150px', margin: '30px 0' }}>Save</Button>
            </Grid>
        </Grid>
    );
}

export default ModelSettingForm ;