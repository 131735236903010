import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const SaveDataButton = ({ disabled, isDataSending = false, onClickFunc = () => {}, fixed=false, type="text"}) => {
  return (
    <LoadingButton
      // sx={{ borderRadius: "5px", textAlign: "right",
      //  position: fixed ? 'absolute' : 'relative',
      //  top: fixed ? '50px' : 'unset',
      //  right: fixed ? '15px' : 'unset',
      // }}
      sx={{marginBottom: '16px'}}
      type={type}
      color="primary"
      loading={isDataSending}
      loadingPosition="end"
      endIcon={<SaveIcon />}
      onClick={onClickFunc}
      // variant="contained"
      variant="text"
      disabled={disabled}
    >
      Save
    </LoadingButton>
  );
};

export default SaveDataButton;
