const plans = [
    {
        name:'Trial',
        credits:'200',
        models:'1',
        dailyUse:'50 req/day',
        training:{title:'Training',list:[{name:'Text',value:true},{name:'Q&A',value:true},{name:'Gmail Conversations',value:10},{name:'Outlook Conversations',value:10},{name:'Files',value:1}]},
        security:{title:'Security',list:[{name:'Data Privacy',value:true},{name:'Security',value:true},{name:'Reliability',value:true}]},
        communication:{title:'Communication',list:[{name:'Playground',value:true},{name:'Browser Extension',value:true},{name:'AI Model Sharing',value:true},{name:'50+ Languages',value:true},{name:'Speak to File',value:true}]},
        support:{title:'Support',list:[{name:'Technical Support',value:true},{name:'Dedicated Manager',value:false}]},
    },
    {
        name:'Starter',
        credits:'2,000',
        models:'Unlimited',
        dailyUse:'Unlimited',
        training:{title:'Training',list:[{name:'Text',value:true},{name:'Q&A',value:true},{name:'Gmail Conversations',value:'Unlimited'},{name:'Outlook Conversations',value:'Unlimited'},{name:'Files',value:'Unlimited'}]},
        security:{title:'Security',list:[{name:'Data Privacy',value:true},{name:'Security',value:true},{name:'Reliability',value:true}]},
        communication:{title:'Communication',list:[{name:'Playground',value:true},{name:'Browser Extension',value:true},{name:'AI Model Sharing',value:true},{name:'50+ Languages',value:true},{name:'Speak to File',value:true}]},
        support:{title:'Support',list:[{name:'Technical Support',value:true},{name:'Dedicated Manager',value:false}]},
    },
    {
        name:'Pro5K',
        credits:'5,000',
        models:'Unlimited',
        dailyUse:'Unlimited',
        training:{title:'Training',list:[{name:'Text',value:true},{name:'Q&A',value:true},{name:'Gmail Conversations',value:'Unlimited'},{name:'Outlook Conversations',value:'Unlimited'},{name:'Files',value:'Unlimited'}]},
        security:{title:'Security',list:[{name:'Data Privacy',value:true},{name:'Security',value:true},{name:'Reliability',value:true}]},
        communication:{title:'Communication',list:[{name:'Playground',value:true},{name:'Browser Extension',value:true},{name:'AI Model Sharing',value:true},{name:'50+ Languages',value:true},{name:'Speak to File',value:true}]},
        support:{title:'Support',list:[{name:'Technical Support',value:true},{name:'Dedicated Manager',value:false}]},
    },
    {
        name:'Pro20K',
        credits:'20,000',
        models:'Unlimited',
        dailyUse:'Unlimited',
        training:{title:'Training',list:[{name:'Text',value:true},{name:'Q&A',value:true},{name:'Gmail Conversations',value:'Unlimited'},{name:'Outlook Conversations',value:'Unlimited'},{name:'Files',value:'Unlimited'}]},
        security:{title:'Security',list:[{name:'Data Privacy',value:true},{name:'Security',value:true},{name:'Reliability',value:true}]},
        communication:{title:'Communication',list:[{name:'Playground',value:true},{name:'Browser Extension',value:true},{name:'AI Model Sharing',value:true},{name:'50+ Languages',value:true},{name:'Speak to File',value:true}]},
        support:{title:'Support',list:[{name:'Technical Support',value:true},{name:'Dedicated Manager',value:false}]},
    },
    {
        name:'Pro50K',
        credits:'50,000',
        models:'Unlimited',
        dailyUse:'Unlimited',
        training:{title:'Training',list:[{name:'Text',value:true},{name:'Q&A',value:true},{name:'Gmail Conversations',value:'Unlimited'},{name:'Outlook Conversations',value:'Unlimited'},{name:'Files',value:'Unlimited'}]},
        security:{title:'Security',list:[{name:'Data Privacy',value:true},{name:'Security',value:true},{name:'Reliability',value:true}]},
        communication:{title:'Communication',list:[{name:'Playground',value:true},{name:'Browser Extension',value:true},{name:'AI Model Sharing',value:true},{name:'50+ Languages',value:true},{name:'Speak to File',value:true}]},
        support:{title:'Support',list:[{name:'Technical Support',value:true},{name:'Dedicated Manager',value:false}]},
    },
    {
        name:'Pro100K',
        credits:'100,000',
        models:'Unlimited',
        dailyUse:'Unlimited',
        training:{title:'Training',list:[{name:'Text',value:true},{name:'Q&A',value:true},{name:'Gmail Conversations',value:'Unlimited'},{name:'Outlook Conversations',value:'Unlimited'},{name:'Files',value:'Unlimited'}]},
        security:{title:'Security',list:[{name:'Data Privacy',value:true},{name:'Security',value:true},{name:'Reliability',value:true}]},
        communication:{title:'Communication',list:[{name:'Playground',value:true},{name:'Browser Extension',value:true},{name:'AI Model Sharing',value:true},{name:'50+ Languages',value:true},{name:'Speak to File',value:true}]},
        support:{title:'Support',list:[{name:'Technical Support',value:true},{name:'Dedicated Manager',value:true}]},
    },
]

export default plans