import React, { useState } from "react";
import {
  Typography,
  IconButton,
  ListItemAvatar,
  List,
  ListItemText,
  ListItem
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import useDeleteGalleryItem from 'hooks/useDeleteGalleryItem';

const ImagesList = ({ cardId, gallery }) => {
  const { handleDeleteGalleryItem, isLoading } = useDeleteGalleryItem();
  const [deletingItem, setDeletingItem] = useState(null);

  if (!gallery?.length) return null;

  return (
    <List>
      {gallery.map((item, i) => (
        <ListItem
          key={`gallery-${item.id}-${i}`}
          sx={{ paddingLeft: 0 }}
          secondaryAction={
            <IconButton edge="end" aria-label="delete" disabled={isLoading && deletingItem === item.id} onClick={() => {
              setDeletingItem(item.id);
              handleDeleteGalleryItem(item.id, cardId);
            }}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          }
        >
          <ListItemAvatar sx={{ paddingBottom: 0, marginRight: '10px' }}>
            <img height={30} src={item.file_url} />
          </ListItemAvatar>
          <ListItemText sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: '300px' }}
            primary={<Typography variant="one_line_ellipsis">{item.file_url.split('/').pop()}</Typography>}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default ImagesList;
