import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const DeletePaymentDialog = ({ isOpen = false, setOpen, handleNewCard = false }) => {

  const handleClose = () => {
    setOpen(false);
  };

  const addPaymentRedirect = () => {
    handleNewCard(true, true);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>
        Delete Payment Method
      </DialogTitle>
      <DialogContent>
        <DialogContentText>You need to add a new payment method before the last one can be removed</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={addPaymentRedirect} autoFocus>
          Add Payment
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeletePaymentDialog;
