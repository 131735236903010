import FlexWrapper from "components/wrappers/FlexWrapper";
import {Box, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterMenu from "./components/Filter/FilterMenu";
import SelectedTraining from "./components/SelectedTraining";
import Filter from "./components/Filter";
import {useSelector} from "react-redux";

const LinksToolBar = () => {

    const selectedIds = useSelector(state => state.links.content.sort.maps.selectedIds);
    const selectedNum = Object.keys(selectedIds).length || 0;
    if (selectedNum) {
        return <SelectedTraining selectedNum={selectedNum} selectedIds={selectedIds}/>;
    }

    return <Filter/>
}

export default LinksToolBar;