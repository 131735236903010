import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import CssBaseline from '@mui/material/CssBaseline';
// <CssBaseline />
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
     <App />
  // </React.StrictMode>
  // <App/>
);

// reportWebVitals();



// TODO
// create hooks later for redux useSelectors and others
