import React, {memo, useRef, useState, useEffect} from "react";
import PublicLink from "../../PublicLink";
import {Box, Divider, FormControlLabel, Grid, Switch, Typography} from "@mui/material";
import Hint from "../../Hint";
import {useOutletContext} from "react-router-dom";
import SmallScreenToggleBtn from "../../SmallScreenToggleBtn";
import Preview from "./components/Preview";
import Settings from "./components/Settings";
import ActionBtns from "../../ActionBtns";

const Public = () => {
    const context = useOutletContext();
    const selectedModel = context.selectedModel;
    const publicLink = context.isPublic
        ? 'https://younet.ai/' + selectedModel?.public_hash
        : '';

    if (context.smallScreen) {

        return (
            <Grid container py={4} spacing={4} sx={{ paddingBottom: '100px' }}>
                <Grid item xs={12} sm={12} textAlign="center">
                    <SmallScreenToggleBtn
                      position={1}
                      hidePreview={!publicLink}
                      settingsComp={<Settings publicLink={publicLink}
                                              setIsPublicListedOnMarketplace={context.setIsPublicListedOnMarketplace}
                                              savePublic={context.savePublic} copyPublicLink={context.copyPublicLink}
                                              hideFromPublic={context.hideFromPublic}
                                              selectedModel={selectedModel}
                                              smallScreen={context.smallScreen}
                      />}
                      previewComp={<Preview manualHash={selectedModel?.public_hash} publicLink={publicLink}/>}
                    />
                    <ActionBtns
                      isPublic={context.isPublic}
                      hideFromPublic={context.hideFromPublic}
                      savePublic={context.savePublic}
                      loading={context.loading}
                      websiteStatus={context.websiteStatus}
                      shareType={context.shareType}
                      handleSendInvitation={context.handleSendInvitation}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container py={4} spacing={4}>
            <Grid item xs={12} sm={12} md={publicLink ? 3.8 : 12} lg={publicLink ? 4.9 : 12}>
                <Settings
                  publicLink={publicLink}
                  setIsPublicListedOnMarketplace={context.setIsPublicListedOnMarketplace}
                  savePublic={context.savePublic} copyPublicLink={context.copyPublicLink}
                  hideFromPublic={context.hideFromPublic}
                  selectedModel={selectedModel}
                  smallScreen={context.smallScreen}
                />
                <ActionBtns
                  isPublic={context.isPublic}
                  hideFromPublic={context.hideFromPublic}
                  savePublic={context.savePublic}
                  loading={context.loading}
                  websiteStatus={context.websiteStatus}
                  shareType={context.shareType}
                  handleSendInvitation={context.handleSendInvitation}
                />
            </Grid>
            {!context.smallScreen && (
              <Grid item auto>
                <Divider orientation="vertical"/>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={7.8} lg={6.9} sx={{maxHeight: 'calc(100vh - 247px)',}}>
                <Preview manualHash={selectedModel?.public_hash} publicLink={publicLink}/>
            </Grid>
            
        </Grid>
    )
}

export default memo(Public);
