import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, Link, Stack, Typography } from "@mui/material";

const AttachmentLinks = ({ content = [], onDelete = false, onClick = false, maxWidth = "100%" }) => {


    return (
        <Box style={{ flexFlow: "row wrap", maxWidth: maxWidth }} pt={2}>
            {content.map((item, index) => (
                <Link
                    key={index}
                    {...(onClick && { onClick: () => onClick(item.data_id) })}
                    sx={{
                        fontWeight: "500",
                        margin: "12px 12px 0 0",
                        cursor: "pointer"
                    }}
                >
                    <Typography variant="truncate_text_150w" maxWidth={150}> <Typography variant="plain_link">{item.name}</Typography></Typography>
                    {onDelete && (
                        <ClearIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(item.id);
                            }}
                            sx={{ marginLeft: 0.8, cursor: 'pointer', fontSize: "14px" }}
                        />
                    )}
                </Link>
            ))}
        </Box>

    )

}


export default AttachmentLinks;