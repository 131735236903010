import { Navigate, useRoutes } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import NotFound from "pages/NotFound";
import dashboardRoutes from "./dashboard";
import billingRoutes from "./billing";
import publicRoutes from "./public";
import { useSelector, shallowEqual } from "react-redux";
import { checkPermissions } from "_helpers/checkPermissions";
import { memo, useMemo } from "react";
import monetizationRoutes from "./monetization";

const RoutesStructure = () => {
  const userPermissions = useSelector((state) => state.permissions.userPermissions,shallowEqual);
  // const planLimitations = useSelector((state) => state.permissions.planLimitations, shallowEqual);


  const AllowedComponent = ({ route }) => {
    const isAllowed =
      (typeof route.permission === 'undefined' ||
        userPermissions.includes('*') ||
        checkPermissions(userPermissions, route.permission));

    return isAllowed ? route.element : <Navigate to="/" />;
  };


  const dashboardChildren = useMemo(() => {
    const processRoute = (route) => {
      const newRoute = { ...route, element: <AllowedComponent route={route}/> };
  
      if (route.children) {
        newRoute.children = route.children.map(childRoute => processRoute(childRoute));
      }
  
      return newRoute;
    };
  
    return dashboardRoutes.map(route => processRoute(route));
  }, [userPermissions]);

  let routes = useRoutes([
    ...publicRoutes,
    {
      element: <PrivateRoute />,
      children: [
        ...dashboardChildren,
        ...billingRoutes,
        ...monetizationRoutes
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);

  return routes;
};

export default memo(RoutesStructure);
