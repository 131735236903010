import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import api from '_services/api';
import StripePaymentSkeleton from 'components/loadings/templates/StripePaymentSkeleton';
import { Box } from '@mui/material';


// loadStripe.setLoadParameters({advancedFraudSignals: false});
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);



const Stripe = ({ children, loadingComponent = false }) => {

  const [secretKey, setSecretKey] = useState("");

  useEffect(() => {
    const getSecretKey = async () => {
      const response = await api.get("billing/stripe/secret");

      if (response.code == 200 && response.client.secret) {
        setSecretKey(response.client.secret);
      } else {
          
      }

    }

    getSecretKey();
  }, []);

  const options = {
    clientSecret: secretKey,
  };


  if (!secretKey) {
    if (loadingComponent) {
      return <Box padding={2} width="100%">{loadingComponent}</Box>
    }
    return <></>;
  }


  return (
    <Elements stripe={stripePromise}
      options={options}
    >
      {children}
    </Elements>
  );
};


export default Stripe;
