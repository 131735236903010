import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  tasks: {},
};

// TODO: combine all events that needs to be seen for different use cases like pending heavy message, pending training statuses or future notifications
export const workerSlice = createSlice({
  name: "worker",
  initialState,
  reducers: {
    setTasks(state, action) {
    },
  },
  extraReducers: () => {},
});

export const { setTasks } = workerSlice.actions;

export default workerSlice.reducer;
