import Playground from "pages/protected/tools/Playground";
import Training from "pages/protected/tools/Training";
import Settings from "pages/protected/Settings";
import TextTraining from "pages/protected/tools/Training/components/TextTraining";
import EmailView from "pages/protected/tools/Training/components/EmailTraining/components/EmailView/index";
import EmailTraining from "pages/protected/tools/Training/components/EmailTraining";
import QuestionAndAnswerTraining from "pages/protected/tools/Training/components/QuestionAndAnswerTraining";
import GoogleEmailTemplate
    from "pages/protected/tools/Training/components/EmailTraining/components/GoogleEmailTemplate";
import Model from "pages/protected/Model";
import FilesTraining from "pages/protected/tools/Training/components/FilesTraining";
import BrainData from "pages/protected/tools/Training/components/BrainData";
import ViewFileContent from "pages/protected/tools/Training/components/FilesTraining/components/ViewFileContent";
import ViewQAContent
    from "pages/protected/tools/Training/components/QuestionAndAnswerTraining/components/ViewQAContent";
import ViewTextContent from "pages/protected/tools/Training/components/TextTraining/components/ViewTextContent";
import CreateModel from "pages/protected/Model/components/CreateModel";
import ViewEmailContent from "pages/protected/tools/Training/components/EmailTraining/components/ViewEmailContent";
import ModelSetting from "pages/protected/Settings/modelsetting/ModelSetting";
import ShareModelConfig from "pages/protected/ShareModelConfig";
import Rabbit from "pages/protected/Rabbit";
import Invitations from "pages/protected/Invitations";
import {PlanChecker} from "components/security/AccessChecker";
import Monetization from "../../pages/protected/monetization";
import InvoicesManager from "../../pages/protected/monetization/components/InvoicesManager";
import SubscriberManager from "../../pages/protected/monetization/components/SubscriberManager";
import WebsiteTraining from "pages/protected/tools/Training/components/WebsiteTraining";
import ViewWebsite from "pages/protected/tools/Training/components/WebsiteTraining/components/ViewWebsite";
import Onboarding from "pages/protected/Onboarding";
import ShareModel from "pages/ShareModel";
import ShareModelOptions from "pages/protected/ShareModel";
import Public from "pages/protected/ShareModel/components/views/Public";
import ChatBot from "pages/protected/ShareModel/components/views/ChatBot";
import Restricted from "pages/protected/ShareModel/components/views/Restricted";
import MonetizationShare from "pages/protected/ShareModel/components/views/Monetization";
import SpeakToProduct from "pages/protected/ShareModel/components/views/SpeakToProduct";
import CustomApi from "pages/protected/CustomApi";


// import AppMenu from "components/menus/AppMenu";
// finish lazy loading later
// import { lazy } from 'react';
// const Playground = lazy(() => import('pages/protected/tools/Playground'));


const dashboardRoutes = [
    {path: "/", element: <Playground/>},
    {path: "/playground", element: <Playground/>},
    {path: "/playground/chat", element: <Playground/>},
    {path: "/playground/chat/new", element: <Playground/>},
    {path: "/playground/chat/:chat_id", element: <Playground/>},
    {path: "/invitations", element: <Invitations/>},
    {
        element: <Training/>,
        path: "/training",
        permission: ["write"],
        children: [
            {
                path: "brain",
                element: <BrainData/>,
            },
            {
                path: "text",
                element: <TextTraining/>,
            },
            {
                path: "text/:id",
                element: <ViewTextContent/>,
            },
            {
                element:
                // <PlanChecker limitationAction="googleGmail" fallbackComponent="upgrade">
                    <EmailTraining/>,
                // </PlanChecker>,
                path: "email",
                limitationAction: "googleGmail",
                permission: ["write"],
                children: [
                    {
                        path: "",
                        element: <EmailView/>,
                        limitationAction: "googleGmail"
                    },
                    {
                        path: ":type",
                        element: <EmailView/>,
                        limitationAction: "googleGmail"
                    },
                    {
                        path: ":type/:accountId/:modelId/:mailId",
                        element: <GoogleEmailTemplate/>,
                        limitationAction: "googleGmail"
                    },
                    {
                        path: "content/:id",
                        element: <ViewEmailContent/>,
                        limitationAction: "googleGmail"
                    },
                ]
            },
            {
                path: "q&a",
                element: <QuestionAndAnswerTraining/>,
            },
            {
                path: "q&a/:id",
                element: <ViewQAContent/>,
            },
            {
                path: "files",
                element: <FilesTraining/>,
                // <PlanChecker limitationAction="learningFile" fallbackComponent="upgrade">

                // </PlanChecker>,
                limitationAction: "learningFile"
            },
            {
                path: "files/:id",
                element:
                // <PlanChecker limitationAction="learningFile" fallbackComponent="upgrade">
                    <ViewFileContent/>,
                // </PlanChecker>,
                limitationAction: "learningFile"
            },
            {
                path: "",
                element: <BrainData/>,
            },
            {
                element: <WebsiteTraining/>,
                path: "website",
                children: [
                    {
                        path: "",
                        element: <ViewWebsite/>
                    },
                    {
                        path: ":site_id",
                        element: <ViewWebsite/>
                    },
                    {
                        path: ":site_id/:contentId",
                        element: <ViewWebsite content="view"/>
                    },

                ]
            },
        ],
    },
    {
        element: <ShareModelOptions/>,
        path: "/share",
        permission: ["write"],
        children: [
            {
                path: "public",
                element: <Public/>,
            },
            {
                path: "restricted",
                element: <Restricted/>,
            },
            {
                path: "monetization",
                element: <MonetizationShare/>,
            },
            {
                path: "chat-bot",
                element: <ChatBot/>,
            },
            {
                path: "speak-to-product",
                element: <SpeakToProduct/>,
            },
        ]
    },
    {path: "/settings", element: <Settings/>},
    {path: "/custom-api", element: <CustomApi/>},
    {path: "/model/settings", element: <ModelSetting/>, permission: ["edit"]},
    {path: "/models", element: <Model/>},
    // {path: "/models/create", element: <CreateModel/>},
    {path: "/models/create", element: <Onboarding/>},
    // TODO: Check if ShareModelConfig is using now or not
    // { path: "/models/share-config", element: <ShareModelConfig /> },
    {path: "/models/:id", element: <CreateModel/>, permission: ["write"]},
    {path: "/rabbit-ai", element: <Rabbit/>},
    // { path: "/rabbit-ai", element: <ShareModel />},
];

export default dashboardRoutes;
