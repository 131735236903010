import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '_services/api/index.js';

export const getInfo = createAsyncThunk('billing/info', async (payload, thunkAPI) => {
  try{
    const response = await api.get('/billing/user/data');
    const invoices = await api.get('/user-model/invoices/list');

    if(response.code >= 400 || invoices.code >= 400) {
      throw new Error(response);
    }

    return { data: response.data, invoices: invoices.invoices }

    // {brain_items: brain_items}

  } catch(e) {
    let message = e?.response?.message || e?.message || 'Something went wrong';
    return thunkAPI.rejectWithValue({ message });
    // return thunkAPI.rejectWithValue(message);
  }
});


export const getCredits = createAsyncThunk('billing/credits', async (payload, thunkAPI) => {
    try{

        const response = await api.get('/billing/user/balance');


        if(response.code >= 400) {
            throw new Error(response);
        }


        return { data: response.balance }
        
        // {brain_items: brain_items}

    }catch(e){
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return thunkAPI.rejectWithValue({ message });
        // return thunkAPI.rejectWithValue(message);
    }
});

export const getPlans = createAsyncThunk('billing/plans', async (payload, thunkAPI) => {
    try{

        const response = await api.get('billing/tariff-plans/list');


        if(response.code >= 400) {
            throw new Error(response);
        }


        return { tariff_plans: response.tariff_plans }
        
        // {brain_items: brain_items}

    }catch(e){
        let message = e?.response?.message || e?.message || 'Something went wrong';
        return thunkAPI.rejectWithValue({ message });
        // return thunkAPI.rejectWithValue(message);
    }
});

export const getSubscriptionsThunk = createAsyncThunk('billing/subscribe', async (payload, thunkAPI) => {
  try{
    const response = await api.get('/user-model/subscribe/list');

    if (response.code >= 400) {
      throw new Error(response);
    }

    return { subscriptions: response.items }
    
  } catch(e) {
      const message = e?.response?.message || e?.message || 'Something went wrong';
      return thunkAPI.rejectWithValue({ message });
      // return thunkAPI.rejectWithValue(message);
  }
});

export const unsubscribeThunk = createAsyncThunk('billing/unsubscribe', async (payload, thunkAPI) => {
  try{
    const response = await api.get(`/user-model/unsubscribe/${payload.modelId}`);

    if(response.code >= 400) {
      throw new Error(response);
    }

    return { modelId: payload.modelId };
    
  } catch(e) {
      let message = e?.response?.message || e?.message || 'Something went wrong';
      return thunkAPI.rejectWithValue({ message });
      // return thunkAPI.rejectWithValue(message);
  }
});
