import authReducer from './auth';
import modelsReducer from './models';
import toolsReducer from './tools';
import chatsReducer from './chats';
import messagesReducer from './messages';
import sharedMessagesReducer from './sharedMessages';
import statusReducer from './status';
import billingReducer from './billing';
import permissionsReducer from './permissions';
import progressReducer from './progress';
import marketplaceReducer from './marketplace';
import monetizationReducer from './monetization';
import invitationsReducer from './invitations';
import workerReducer from './worker';
import emailReducer from "./email";
import linksReducer from "./links";
// import requestCancellationMiddleware from "_middleware/requestCancellationMiddleware";
import { combineReducers, configureStore } from '@reduxjs/toolkit';



const allReducers = combineReducers({
 auth: authReducer,
 models: modelsReducer,
 chats: chatsReducer,
 messages: messagesReducer,
 sharedMessages: sharedMessagesReducer,
 marketplace: marketplaceReducer,
 monetization: monetizationReducer,
 status: statusReducer,
 permissions: permissionsReducer,
 tools: toolsReducer,
 billing: billingReducer,
 progress: progressReducer,
 invitations: invitationsReducer,
 worker: workerReducer,
 email: emailReducer,
 links: linksReducer
});

const store = configureStore({
 reducer: allReducers,
 middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
})
     // .concat(requestCancellationMiddleware),
// devTools: false,
//  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export default store;