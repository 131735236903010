import ShareModel from "pages/ShareModel";
import Grid from "@mui/material/Grid";
import {Typography, Box} from "@mui/material";
import styles from './browserWindow.module.css';
import classnames from "classnames";


const BrowserWindow = ({children, url}) => {
    return (
      <Box sx={{ border: '3px solid #f1f1f1', minHeight: '500px', borderRadius: '4px' }}>
        <Box className={styles.row}>
          <Box className={classnames(styles.column, styles.left)}>
            <Box className={styles.dot} sx={{background:'#ED594A'}}></Box>
            <Box className={styles.dot} sx={{background:'#FDD800'}}></Box>
            <Box className={styles.dot} sx={{background:'#5AC05A'}}></Box>
          </Box>
          <Box className={classnames(styles.column, styles.middle)}>
            <input type="text" value={url || 'younet.ai/portal'} />
          </Box>
          <Box className={classnames(styles.column, styles.right)}>
            <Box sx={{ float: 'right' }}>
              <Box className={styles.bar}></Box>
              <Box className={styles.bar}></Box>
              <Box className={styles.bar}></Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: 0 }}>
          {children}
        </Box>
      </Box>
            

    )
}
export default BrowserWindow;
