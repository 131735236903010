import {Box, Skeleton} from "@mui/material";
import FlexWrapper from "../../wrappers/FlexWrapper";
import PhotoIcon from '@mui/icons-material/Photo';
import GeneralTooltip from "../../tooltip/GeneralTooltip";
import {memo} from "react";


const TOOLTIP_TITLE = 'Generating Image at the moment. Please wait.';
const MAX_IMAGE_SIZE = '285px';
const ImageGenerating = () => {

    return (
        // <GeneralTooltip placement='bottom' title={TOOLTIP_TITLE}>
            <FlexWrapper styles={{position: 'relative', justifyContent: 'flex-start',maxWidth: MAX_IMAGE_SIZE, maxHeight: MAX_IMAGE_SIZE}}>
                <Skeleton variant="rectangular"
                          width={MAX_IMAGE_SIZE} height={MAX_IMAGE_SIZE}>
                    {/*<IconButton>*/}

                    {/*<FlexWrapper>*/}
                    {/*    icon*/}
                    {/*</FlexWrapper>*/}
                    {/*</IconButton>*/}
                </Skeleton>
                {/*<FlexWrapper>*/}
                <PhotoIcon sx={{position: 'absolute', left: 'calc(50% - 18px)'}} fontSize="large" color="icons"/>
                {/*</FlexWrapper>*/}
            </FlexWrapper>
        // </GeneralTooltip>
        // <Box sx={{width: '285px', height: '285px', backgroundColor: 'red'}}>generationg image</Box>
    )
}

export default memo(ImageGenerating);