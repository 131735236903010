import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken, removeToken, setToken } from "_helpers/tokenManagement";
import api from "_services/api/index.js";
import controlledApi from "_services/controlledApi/index.js";

export const validateUser = createAsyncThunk(
  "auth/validateUser",
  async (_, thunkAPI) => {
    try {
      const token = getToken("token");
      // api.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await controlledApi({ ignoreUser: true }).get("/users/current");

      let { user, authCount } = response || {};

      if (!user || response.code >= 400) {
        throw new Error(response);
      }

      let referrer = getToken('referrer');
      let userAgent = getToken('userAgent');

      if (!user.waitingListJoinReason && referrer && userAgent) {
        api.put(`users/${user.id}`, { referenceSource: referrer, waitingListJoinReason: userAgent });
      }
      setToken("refreshToken", user?.refresh_token);
      let cachedModel = getToken("cachedModel", "json")
      if (cachedModel &&  typeof cachedModel.user_id == 'number') {
        if(cachedModel.user_id != user.id) {
          removeToken("cachedModel");
        }
      }

      return { token: token, user: user, authCount: authCount };
    } catch (e) {
      let message = e?.response?.message || e?.message || "Something went wrong";
      
      removeToken("token");
      removeToken("refreshToken");
      removeToken("userData");
      return thunkAPI.rejectWithValue({ message });
      
      // return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    try {
      const response = await api.post("/login_check", {username: payload.username, password: payload.password});

      let { token, user } = response || {};

      if (!token || !user || response.code >= 400) {
        throw new Error(response);
      }

      if (!user.waitingListJoinReason && payload.referrer && payload.userAgent) {
        api.put(`users/${user.id}`, { referenceSource: payload.referrer, waitingListJoinReason: payload.userAgent });
      }
      setToken("token", token);
      setToken("refreshToken", user?.refresh_token);
      setToken("userData", { fullName: user.fullName, email: user.email, avatar: user.avatar, planId: user.tariff_plan?.id || 1});
      // console.log(token)
      return { token: token, user: user, message: "Login Success!", redirectUrl: payload.redirectUrl, redirectTop: payload.redirectTop };
    } catch (e) {
      let message = e.message || "Something went wrong";
      removeToken("token");
      removeToken("refreshToken");
      removeToken("userData");
      return thunkAPI.rejectWithValue({ message: message });
      // thunkAPI.rejectWithValue(
      // {message: message}
      // );
    }
  }
);

export const thirdPartyLogin = createAsyncThunk(
  "auth/thirdPartyLogin",
  async (payload, thunkAPI) => {

    let guestId = payload.guestId || null;

    let params = {
      code: payload.code,
      state: payload.state,
      redirectUrl: payload.redirectUrl,
    }

    if(guestId) {
      params.guestId = guestId;
    }
    try {
      const response = await api.post(`${payload.type}/oauth2/user`, params);

      let { token, user } = response || {};

      if (!token || !user || response.code >= 400) {
        throw new Error(response.message);
      }
      if (!user.waitingListJoinReason && payload.referrer && payload.userAgent) {
        api.put(`users/${user.id}`, { referenceSource: payload.referrer, waitingListJoinReason: payload.userAgent });
      }
      setToken("token", token);
      setToken("refreshToken", user?.refresh_token);
      setToken("userData", { fullName: user.fullName, email: user.email, avatar: user.avatar, planId: user.tariff_plan?.id || 1});
      return { token: token, user: user, redirectUrl: payload.redirectUser };
    } catch (e) {
      let message = e?.message || "Something went wrong";
      removeToken("token");
      removeToken("refreshToken");
      removeToken("userData");
      return thunkAPI.rejectWithValue({ message: message });
      // thunkAPI.rejectWithValue(
      // {message: message}
      // );
    }
  }
);

export const updateUserState = createAsyncThunk(
  "auth/update",
  async (payload, thunkAPI) => {
    try {
      const response = await api.put(`users/${payload.id}`, payload.preparedValues);

      let { token, user } = response || {};

      if (response.code >= 400) {
        throw new Error(response.message);
      }
      

      return { code: response.code, updatedUserValues: payload.preparedValues };
    } catch (e) {
      let message = e?.message || "Something went wrong";
      return thunkAPI.rejectWithValue({ message: message });
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (payload, thunkAPI) => {
    try {
      const response = await api.post("/users", payload);

      if (response.code >= 400 || !response.user) {
        let message = response?.message || "Something went wrong";
        throw new Error(message);
      }

      // setToken('token',token);
      // console.log(token)
      return { id: response?.user?.id, email: response?.user.email };
    } catch (msg) {
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

// Refresh the user data
export const refreshUserState = createAsyncThunk(
  "auth/refreshUser",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/users/current");

      let { user } = response || {};

      if (!user || response.code >= 400) {
        throw new Error(response);
      }

      return { user: user };
    } catch (e) {
      let message = e?.response?.message || e?.message || "Something went wrong";
      return thunkAPI.rejectWithValue({ message: message });
    }
  }
);

export const refreshUserToken = createAsyncThunk(
  "auth/refreshUserToken",
  async (_, thunkAPI) => {
    try {
      const refreshToken = getToken("refreshToken");
      const response = await controlledApi({ ignoreUser: true, request_callback: false }).get(`/users/auth/refresh/${refreshToken}`);

      if (response.code >= 400 || !response.user) {
        const message = response?.message || "Something went wrong";
        throw new Error(message);
      }
      const { token, user } = response;

      setToken("token", token);
      setToken("refreshToken", user.refresh_token);
      return { token: token, user: user };
    } catch (e) {
      let message =
        e?.response?.message || e?.message || "Something went wrong";
      
      removeToken("token");
      removeToken("refreshToken");
      removeToken("userData");
      return thunkAPI.rejectWithValue({ message });
      
      // return thunkAPI.rejectWithValue(message);
    }
  }
);
