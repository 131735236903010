import React from 'react';
import { useStepperContext } from './StepperContext';

const Step = ({ stepKey, step = 0, children }) => {
  const { prevStepKey, currentStep, setNextStep, setStep, setPreviousStep, maxStep, onFinish } = useStepperContext();
  const isActiveStep = step === currentStep;

  if (!isActiveStep) return null;

  const stepBag = {
    setNextStep,
    setPreviousStep,
    stepKey,
    prevStepKey,
    step,
    setStep,
    maxStep,
    onFinish,
  };

  if (typeof children === 'function') {
    return <>{children(stepBag)}</>;
  }

  const child = React.Children.only(children);

  return <>{React.cloneElement(child, stepBag)}</>;
};

export default Step;
