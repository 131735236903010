import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import PopupMenu from "components/menus/PopupMenu";
import { memo, useMemo } from "react";
import SwapChatsTab from "./SwapChatsTab";

const ChatSettings = ({ modelId }) => {
  const items = useMemo(
    () => [
      {
        element: <SwapChatsTab key={modelId} modelId={modelId} />,
      },
    ],
    [modelId]
  );

  return (
    <PopupMenu
      modelId={modelId}
      menuItems={items}
      title="Chat Settings"
      // onOpen={handleOpen}
      // loading={loading}
    >
      <IconButton edge="end">
        <MoreVertIcon color="icons" />
      </IconButton>
    </PopupMenu>
  );
};

export default memo(ChatSettings);
