import React, {useEffect, useState} from "react";
import FlexWrapper from "../../wrappers/FlexWrapper";
import {sendUIEvent} from "../../../events/analytics/google";

const LocalChatBotHelper = () => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        if (!scriptLoaded) {
            const script = document.createElement('script');
            script.type = 'module';

            // it`s for flushing cache
            // later I would suggest to adjust the extension script to have reload functions
            const timestamp = new Date().getTime();
            script.src = `https://widget.younet.ai/index.js?modelHash=N85LNs&type=button&width=30&height=30&ts=${timestamp}`;
            document.body.appendChild(script);
            setScriptLoaded(true);

            return () => {
                document.body.removeChild(script);
                setScriptLoaded(false);
            };
        }
    }, []);

    if(!scriptLoaded) {
        return
    }

    return (
        <FlexWrapper styles={{width: '100%', margin: '15px', alignItems: 'flex-end', zIndex: 1}} onClick={() => {sendUIEvent({name: 'need_help_click'})}}>
            <younet-chat-button
                style={{fontFamily: 'Inter'}}
                // style={{transform: 'scale(0.7)'}}
            >NEED HELP?
            </younet-chat-button>
        </FlexWrapper>
    )
};

export default LocalChatBotHelper;
