import { Box, Button, Divider, Grid, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styles from "./monetization.module.css";
import ProgressBar from "components/showcase/ProgressBar";
import { useNavigate } from "react-router-dom";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import moment from "moment";
import SmoothContent from "components/loadings/SmoothContent";
import { Outlet } from 'react-router-dom';
import TotalSubscribersCard from "./components/cards/TotalSubscribersCard";
import TotalEarningsCard from "./components/cards/TotalEarningsCard";
import { getMonetizationInfo } from "redux/monetization";

const Monetization = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const billing = useSelector((state) => state.billing, shallowEqual);
    // const isLoading = billing.loadingBilling;
    // const maximumCredits = billing.planInfo?.maximumAllowedCredits || 0;
    // const availableCredits = billing.planInfo?.availableCredits || 0;
    // const currentPlan = billing.planInfo?.currentPlan || {};
    // const billingInfo = billing.billingInfo || {};
    // const lastFourDigits = billingInfo.lastFourDigits || null;
    // const startDate = moment(currentPlan?.startDate?.date).local().format('YYYY-MM-DD');
    // const endDate = moment(currentPlan?.endDate?.date).local().format('YYYY-MM-DD');


    useEffect(() => {
      dispatch(getMonetizationInfo());
    }, []);


    const handleRedirect = (path = "/") => {
      const defaultPath = "/billing";
      navigate(defaultPath + path);
    }


    return (
      <Grid container className={styles["main-container"]} spacing={12}>
        <Grid item xs={12} md={12}>
            <Typography sx={{ fontSize: 24 }}>Monetization</Typography>
        </Grid>
        <Grid container item xs={12} md={4} spacing={4}>
          <Grid item xs={12} sm={12} md={12} >
            <TotalEarningsCard />
          </Grid>
          <Grid item xs={12} sm={12} md={12} >
            <TotalSubscribersCard />
          </Grid>
        </Grid>
        <Grid item xs="auto" >
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} md={7} paddingTop="15px">
          <Outlet />
        </Grid>
      </Grid>
    );
}

export default Monetization;
