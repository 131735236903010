import { getToken } from "_helpers/tokenManagement.js";

const apiUrl =
  process.env.REACT_APP_API_BASE_URL || "https://api.younet.ai/api";

const GLOBAL_TIMEOUT_PERIOD_MS = 60000;
const RESPONSE_TIMEOUT_MESSAGE_CODE = "message.timeout";

export default async function createStream(
  url,
  callback,
  params = {},
  signal = {},
  errorCallback = () => { },
  streamSettings = { TIMEOUT_PERIOD_MS: 15000, hasAttachments: false }

) {

  let abortTimeoutId,
    errorSaveAction = true;
  const restartAbortTimer = (timeout = streamSettings.TIMEOUT_PERIOD_MS) => {
    clearTimeout(abortTimeoutId);
    abortTimeoutId = setTimeout(() => { errorCallback({ message: [RESPONSE_TIMEOUT_MESSAGE_CODE], params: { saveMessage: errorSaveAction } }) }, timeout);
  }

  try {
    restartAbortTimer(GLOBAL_TIMEOUT_PERIOD_MS);

    const response = await fetch(apiUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken("token")}`,
        // "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(params),
      signal,
    });

    if (
      response.status === 200 &&
      response.headers.get("Content-Type").startsWith("text/event-stream")
    ) {
      const reader = response.body.getReader();

      const decoder = new TextDecoder("utf-8");
      let dataBuffer = "";


      // later change logic a bit here for chunks error
      const handleReadError = (err) => {

        if (signal?.reason?.userAction) {
          errorCallback({ params: { hideErrorMsg: true } });
        } else {
          errorCallback({ message: [RESPONSE_TIMEOUT_MESSAGE_CODE], params: { saveMessage: errorSaveAction } });
        }
        console.warn(err)
        clearTimeout(abortTimeoutId);
      }


      function processChunk({ done, value }) {
        if (done) {
          console.log("SSE stream has ended.");
          clearTimeout(abortTimeoutId);
          return;
        }

        restartAbortTimer();

        let chunkData = { data: "", response: null, message: null };
        const chunkText = decoder.decode(value);



        const eventDataList = chunkText.split("\n");

        let parsedData = "";

        for (let eventData of eventDataList) {

          if (eventData.trim() === "") {
            continue;
          }

          if (eventData.startsWith("data: {")) {
            dataBuffer = eventData.slice(6);
          } else {
            dataBuffer += eventData;
          }

          try {
            let data = JSON.parse(dataBuffer);
            parsedData = data;
          } catch (error) {
            console.log(error)
            continue;
          }


          if (parsedData.response) {
            chunkData.response = parsedData.response;

            if (parsedData.balance) {
              chunkData.balance = parsedData.balance;
            }
            clearTimeout(abortTimeoutId);
          } else if (parsedData.message) {
            errorSaveAction = false;
            chunkData.message = parsedData.message;
            if (parsedData.attachments?.length > 0) {
              chunkData.message.attachments = parsedData.attachments;
            }

            chunkData.sources = parsedData.sources;
          } else if (parsedData.data) {
            chunkData.data += parsedData.data;
          } else if (streamSettings.hasAttachments && parsedData.status == "processing") {
            chunkData.progressStatus = { status: parsedData.status, msg: parsedData.processing_message }
          } else if(parsedData.tool_call) {
            chunkData.tool_call = parsedData.tool_call;

            if(parsedData.tool_data) {
              chunkData.tool_data = parsedData.tool_data;
            }

          } else if (parsedData.error) {
            chunkData.error = parsedData.error;
            clearTimeout(abortTimeoutId);
            callback(chunkData);
            return;
          }
        }

        
        if (chunkData.data !== "" || chunkData.response || chunkData.message || chunkData.progressStatus || chunkData.tool_call) {
          callback(chunkData);
        }

        reader.read().then(processChunk).catch(handleReadError);
      }
      reader.read().then(processChunk).catch(handleReadError);
    } else {
      const responseData = await response.json();
      clearTimeout(abortTimeoutId);
      errorCallback(responseData);
      console.error("Failed to establish SSE connection.");
    }
  } catch (e) {
    console.warn(e.message);
    clearTimeout(abortTimeoutId);

    if (signal?.reason?.userAction) {
      errorCallback({ params: { hideErrorMsg: true, saveMessage: errorSaveAction } });
    } else {
      errorCallback();
    }

  }
}
