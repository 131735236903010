import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";
import {memo} from "react";

const LOADING_PRESET_NUM = 20;
const EmailLoadingTemplate = () => {

    return ([...Array(LOADING_PRESET_NUM)].map((_, i) => (
        <Skeleton key={`loading-template-email-${i}`} animation="wave"/>
    )))
}


export default memo(EmailLoadingTemplate);
