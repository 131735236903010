import React, { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Grid,Select,FormControl,InputLabel,MenuItem,Divider,Avatar,TextField,Checkbox,Button,FormHelperText}from '@mui/material';
import { updateUser, refreshUser} from "redux/auth";
import countries from "mappings/countries.json";
import { STANDART_GPT_MODEL } from 'constants';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { checkFormForErrors } from "utils/validateInputs";
import api from "_services/api";
import { interactWithUser } from "redux/status";
import dayjs from "dayjs";

const CustomTabPanel =(props)=>{
    const { children, value, index, ...other } = props;
    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Grid sx={{ p: 3 }}>
              {children}
            </Grid>
          )}
        </div>
      );
}

const ProfileSetting = ({selectedTag})=> {
    const dispatch = useDispatch();
    const { auth } = useSelector((state) => ({
        auth: state.auth,
    }));

    const defaultProfileSetting = ({
        avatar:auth.user.avatar,
        firstName:auth.user.fullName.split(" ")[0],
        lastName:auth.user.fullName.split(" ")[1],
        email:auth.user.email,
        birthday: dayjs(auth.user.birthday),
        country:auth.user.country || "US", 
        is_subscribed:auth.user.is_subscribed == 1 ? true : false,
    });
    
    const [profileSetting,setProfileSetting] = useState(defaultProfileSetting)
    const handleProfileChange = (e)=>{
      setProfileSetting((prevState)=>({
          ...prevState,
          [e.target.name]:e.target.value
      }));

    }
    const handleProfileCheckboxChange = (e)=>{
        setProfileSetting((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.checked
        }));
    }
    
    const [userState, setUserState] = useState({
        firstName: {
          errMsg: "",
          isRequired: true,
        },
        lastName: {
          errMsg: "",
          isRequired: true,
        },
        country: {
          errMsg: "",
          isRequired: false,
        },
        birthday: {
          errMsg: "",
          isRequired: false,
        },
        email: {
          errMsg: "",
          isRequired: true,
        },
        is_subscribed: {
          errMsg: "",
          isRequired: false,
        },
      });

    const saveUser = async (data) => {
      dispatch(updateUser({id: auth.user.id, preparedValues: data}));
      dispatch(
          interactWithUser({
            message: "Your profile has been updated",
            type: "success",
          })
      );
    };

    const updateAvatar = async () => {
      const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async function() {
          const file = input.files[0];
          if (!file) return;
          try {
            const gptModel = auth.user.settings?.gpt_model || STANDART_GPT_MODEL;
            let formData = new FormData();
            formData.append("avatar", file);
            formData.append("gptModel", auth.user.settings?.gpt_model || STANDART_GPT_MODEL);
            if (Boolean(auth.user.settings)) formData.append("contextWindowSize", auth.user.settings.contextWindowSizePercent);
            const response = await api.post("user/settings/update", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            // Handle response here
            if (response.code === 200) {
              dispatch(
                interactWithUser({
                  message: "Your profile photo has been updated",
                  type: "success",
                })
              );
              dispatch(refreshUser());
            }
          } catch (error) {
            dispatch(
              interactWithUser({
                message: "Something went wrong, please try again later",
                type: "error",
              })
            );
          }
        };
        input.click();
    };
    const handleProfileSave=(e)=>{
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        const status = checkFormForErrors(form, userState);
    
        setUserState((prevState) => ({
            ...prevState,
            ...status.updates,
        }));
    
        if (status.hasErrors) {
            return;
        }
    
        let firstName = form.get("firstName");
        let lastName = form.get("lastName");
        let fullName = firstName + " " + lastName;
        let country = form.get("country");
        let is_subscribed = profileSetting.is_subscribed ? 1 : 0;
        
        let prepareValues = {
          fullName: fullName,
          country: country,
          is_subscribed: is_subscribed,
        };
    
        saveUser(prepareValues);
        
    }
    return (
        <CustomTabPanel value={selectedTag} index={1}>
                    <Grid container px={10} py={6}>
                        <Grid item xs={12} md={'auto'} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <Avatar src={profileSetting.avatar} sx={{width:'60px',height:'60px'}}/>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display:'flex',justifyContent:'center',flexDirection:'column'}} pl={4}>
                            <span style={{color:'#3976EF',cursor: 'pointer'}} onClick={updateAvatar}>Upload profile photo</span>
                            <span>Max size 2MB. Formats: JPG/JPEG, PNG</span>
                        </Grid>
                    </Grid>
                    <Grid container px={6} py={2}
                            component="form"
                            noValidate
                            onSubmit={handleProfileSave}>
                        <Grid container>
                          <Grid item xs={12} md={6} px={4} py={2}>
                          <FormControl fullWidth>
                          <TextField id="firstName" name="firstName" label="First Name" variant="outlined" required
                            error={userState.firstName.errMsg !== ""}
                            helperText={userState.firstName.errMsg ? userState.firstName.errMsg : ""}
                            value={profileSetting.firstName}
                            onChange={handleProfileChange}/>
                          </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} px={4} py={2}>
                          <FormControl fullWidth>
                          <TextField id="lastName" name="lastName" label="Last Name" variant="outlined" required
                          error={userState.lastName.errMsg !== ""}
                          helperText={userState.lastName.errMsg ? userState.lastName.errMsg : ""}
                          value={profileSetting.lastName}
                          onChange={handleProfileChange}/>
                          </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} px={4} py={2}>
                          <FormControl fullWidth>
                          <TextField variant="outlined"
                            value={profileSetting.email}
                            disabled/>
                          </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} px={4} py={2}>
                          <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                              type="date"
                              name="birthday"
                              format="MM.DD.YYYY"
                              defaultValue={profileSetting.birthday}
                              disabled
                              />
                          </LocalizationProvider>
                          </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} px={4} py={2}>
                          <FormControl fullWidth>
                          <InputLabel>Country</InputLabel>
                          <Select
                          fullWidth
                          name="country"
                          value={profileSetting.country}
                          label="Country"
                          error={userState.country.errMsg !== ""}
                          onChange={handleProfileChange}
                          >
                          <MenuItem value="">Select a country</MenuItem>
                          {countries.map((country, index) => (
                              <MenuItem key={index} value={country.code}>
                              {country.name}
                              </MenuItem>
                          ))}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                          {userState.country.errMsg ? userState.country.errMsg : ""}
                          </FormHelperText>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} px={2}>
                            <Checkbox 
                            name="is_subscribed" 
                            checked={profileSetting.is_subscribed} onChange={handleProfileCheckboxChange}/>I want to receive important updates and news from Younet.ai
                        </Grid>
                      </Grid>
                    <Button type="submit" variant="contained" sx={{width:'150px',margin:'30px 16px' }}>Save</Button>
                    </Grid>
            </CustomTabPanel>
    );
}

export default ProfileSetting;