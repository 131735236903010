import { useMemo } from 'react';
import { Typography, Box, Button, Card, CardContent, CardActions, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import classnames from 'classnames';
import Typewriter from '../components/Typewriter';
import { styled } from '@mui/material/styles';
import { getInitials } from "utils/prepareNames";
import customModelLogo from "assets/images/custom-model.png";
import preparedModelLogo from "assets/images/prepared-model.png";
import styles from '../onboarding.module.css';
import {sendUIEvent} from "../../../../events/analytics/google";

const Initial = ({ stepBag, isNewUser, finishedSteps, setIsInitialStepShowed, isInitialStepShowed, setFinishedStepNames, finishedStepNames }) => {
  const auth = useSelector((state) => state.auth);
  const animationFinished = finishedStepNames.includes(stepBag.stepKey);
  const subtitle = 'How will you start your AI journey today?';
  const userInfo = useMemo(()=>{
    let userInfo = {fullName:'',email:'',avatar:'',initial:''}
    if (auth.user) {
      userInfo.fullName = auth.user.fullName;
      userInfo.email = auth.user.email;
      userInfo.avatar = auth.user.avatar;
      userInfo.initial = getInitials(auth.user.fullName);
    }
    
    return userInfo;
  }, [auth.user]);

  const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    color: '#000',
    padding: '3px 10px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#f1f1f1',
    borderColor: '#f1f1f1',
    '&:hover': {
      backgroundColor: '#d7d5d5',
      borderColor: '#d7d5d5',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#d7d5d5',
      borderColor: '#d7d5d5',
    },
    '&:focus': {
      boxShadow: '#d7d5d5',
    },
  });

  const onNextHandler = (step) => {
    setIsInitialStepShowed(true);
    // stepBag.setNextStep(); 
    // stepBag.setStep('');
    setFinishedStepNames([ ...finishedStepNames, 'initial' ]) 
    stepBag.setStep(step);
  }

  return (
    <Box sx={{ paddingBottom: '30px', width: '100%' }}>
      <Typography
        variant="h2"
        sx={{
          marginBottom: '40px',
          textAlign: 'center',
          width: '100%',
          fontSize: {xs: '22px', md: '32px'},
          fontWeight: 300,
          position: 'relative',
        }}
      >
        {animationFinished || !isNewUser ? (
          <>
            <div>{`Hi, ${userInfo.fullName}!`}</div>
           <div>How will you start your AI journey today?</div>
          </>
        )  : (
          <>
            <Box sx={{ opacity: 0 }}>
              <div>{`Hi, ${userInfo.fullName}!`}</div>
              <div>How will you start your AI journey today?</div>
            </Box>
            <Box sx={{ position: 'absolute', top: 0, textAlign: 'center', width: '100%' }}>
              <Typewriter text={`Hi, ${userInfo.fullName}!`} animationSpeed={30} animationDelay={2000} /> <br />
              <Box sx={{ minHeight: {xs: '40px', md: '60px' } }}>
                <Typewriter text={subtitle} animationSpeed={30} animationDelay={3200} cursorDelay={3400} />
              </Box>
            </Box>
          </>
        )}
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', md: 'row' }, gap: '20px', maxWidth: '600px', margin: '0 auto' }}>
        <Card onClick={() => { onNextHandler('selectFlow'); sendUIEvent({name: 'onboarding_create_custom_model'}); }} sx={{ animationDelay: '4800ms', boxShadow: 'none', borderRadius: '10px' }} className={classnames(!animationFinished && isNewUser && styles.button, styles.card)}>
          <CardContent sx={{ padding: 0 }} className={styles.cardContent}>
            <Box>
              <img src={customModelLogo} width="100%" />
            </Box>
            <Box sx={{ padding: '16px' }}>
              <Box mb={2}>
                <Typography variant='onboarding_title'>Create a Custom Model</Typography>
              </Box>
              <Typography variant='onboarding_content'>
                Build a unique AI tailored to your specific business goals. Customize every detail to meet your exact needs.
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid rgba(0,0,0, .1)' }}>
            <CustomButton variant="contained" size="small" className={styles.cta}>
              Get started
            </CustomButton>
            {/* <Typography variant='onboarding_content'>
              4 steps
            </Typography> */}
          </CardActions>
        </Card>
        <Card onClick={() => {onNextHandler('marketplace'); sendUIEvent({name: 'onboarding_use_public_model'})}} sx={{ animationDelay: '5200ms', boxShadow: 'none', borderRadius: '10px' }} className={classnames(!animationFinished && isNewUser && styles.button, styles.card)}>
          <CardContent sx={{ padding: 0 }} className={styles.cardContent}>
            <Box>
              <img src={preparedModelLogo} width="100%" />
            </Box>
            <Box sx={{ padding: '16px' }}>
              <Box mb={2}>
                <Typography variant='onboarding_title'>Use a Public Model</Typography>
              </Box>
              <Typography variant='onboarding_content'>
                Search our marketplace to find and use existing AI models. Choose from a variety of options created by the community, ready for immediate use.
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid rgba(0,0,0, .1)' }}>
            <CustomButton variant="contained" size="small" className={styles.cta}>
              Get started
            </CustomButton>
            {/* <Typography variant='onboarding_content'>
              4 steps
            </Typography> */}
          </CardActions>
        </Card>
      </Box>
    </Box>
  )
};

export default Initial;
