import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import {Grid,Link} from "@mui/material";
import styles from "./top-app-bar.module.css";
import LogoSvg from "components/logo/LogoSvg";

const TopAppBar = ()=> {
    return (
        <AppBar
            position="absolute"
            color="default"
            elevation={0}
            sx={{
            position: "relative",
            }}
        >
        <Grid container>
            <Grid item xs={12} className={styles["topbar"]}>
            <Box pl={8} sx={{display:'flex',alignItems:'center'}}><Link href="/"><LogoSvg/></Link></Box>
            </Grid>
        </Grid>
        </AppBar>
    );
}

export default TopAppBar;