import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StatsCard from 'components/showcase/cards/StatsCard';
import { shallowEqual, useSelector } from 'react-redux';
import { PeopleAltOutlined } from '@mui/icons-material';
import SmoothContent from 'components/loadings/SmoothContent';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import GeneralTooltip from "components/tooltip/GeneralTooltip";

const CardWrapper = styled(StatsCard)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    padding: '25px 15px',
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));


const TotalSubscribersCard = () => {
    const theme = useTheme();
    const subs = useSelector((state) => state.monetization.subs, shallowEqual);
    const navigate = useNavigate();
    const location = useLocation();
    const isSubPage = location.pathname.includes('/subs');
    const btnOpacity = isSubPage ? 0.5 : 1;
    const btnSize = "large";

    const handleRedirect = (e, path = '') => {
        e.preventDefault();
        navigate(`/monetization/${path}`);
    }

    return (

        <CardWrapper border={false} content={false}>
            <Box sx={{ p: 2.25 }}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>

                                {/* <Avatar
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.largeAvatar,
                                                backgroundColor: theme.palette.secondary[800],
                                                mt: 1
                                            }}
                                        > */}
                                <PeopleAltOutlined />
                                {/* </Avatar> */}
                            </Grid>
                            {/* <Grid item>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.mediumAvatar,
                                        backgroundColor: theme.palette.secondary.dark,
                                        color: theme.palette.secondary[200],
                                        zIndex: 1
                                    }}
                                    aria-controls="menu-subs-card"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <ArrowCircleRightIcon fontSize="inherit"/>
                                </Avatar>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SmoothContent loading={subs.loading} height={55}>
                            <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75, ml: 1 }}>
                                {subs.total}
                            </Typography>
                        </SmoothContent>
                    </Grid>

                    <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: -1.25 }}>
                        <Grid item>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    color: theme.palette.secondary[200]
                                }}
                            >
                                Total subscribers
                            </Typography>
                        </Grid>

                        <Grid item>
                        <GeneralTooltip
                          placement="bottom"
                          title="View and manage your subscribers. Click to see who's subscribed to your AI models and manage these subscriptions."
                        >
                          <Button
                            disableElevation
                            variant="contained"
                            // color='primary' 
                            size="small"
                            sx={{ zIndex: 1, color: '#eee', padding: '8px 10px', backgroundColor: "rgba(0, 0, 0, .1)", display: "flex", alignItems: "center",  opacity: btnOpacity,textTransform: "capitalize" }}
                            // sx={{ color: theme.palette.secondary[200]}}
                            onClick={(e) => handleRedirect(e, '/subs')}
                            >
                            Subscribers
                            <ArrowCircleRightIcon fontSize={"medium"} sx={{ color: theme.palette.secondary[200], paddingLeft: "5px"}} />
                          </Button>
                        </GeneralTooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </CardWrapper>
    );
};


export default TotalSubscribersCard;
