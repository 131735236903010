/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState,useEffect} from "react";
import styled from "@emotion/styled";
import {Grid,Typography,Tabs,Tab}from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import './index.css';
import ProfileSetting from "./ProfileSetting";
import SecuritySetting from "./SecuritySetting";
import GeneralSetting from "./GeneralSetting";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useBeforeUnload } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';

const CustomAccordionDetails = styled(AccordionDetails)`
    padding: 0;
`
const tabProps=(index)=> {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const setting = () => {
    // Fetch the active page from local storage
    const [selectedTag, setSelectedTag] = useState(localStorage.activeAccountSettingTab? parseInt(localStorage.activeAccountSettingTab):0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleTagChange = (e,newValue) => {
      localStorage.setItem("activeAccountSettingTab",newValue);
      setSelectedTag(newValue);
    };
    
    useBeforeUnload(
        React.useCallback(() => {
            localStorage.removeItem("activeAccountSettingTab")
          }, [])
    );
    const renderTabsOrAccordion = () => {
      const accordions = [
        { id: 0, icon: <SettingsIcon />, title: <div className="title-wrapper">General Settings</div>, content: <GeneralSetting /> },
        { id: 1, icon: <PersonIcon />, title: <div className="title-wrapper">Profile Details</div>, content: <ProfileSetting /> },
        { id: 2, icon: <LockOpenIcon />, title: <div className="title-wrapper">Security Setting</div>, content: <SecuritySetting /> },
    ];
    
  
      if (isMobile) {
          return (
              <div>
                  {accordions.map(({ id, icon, title, content }) => (
                      <Accordion key={id} expanded={selectedTag === id} onChange={() => handleTagChange(null, id)}>
                          <AccordionSummary
                              style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center !important',
                                  backgroundColor: selectedTag === id ? '#f0f0f0' : 'transparent',
                              }}
                              aria-controls={`panel${id + 1}a-content`}
                              id={`panel${id + 1}a-header`}
                          >
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              {icon}
                              <Box style={{marginLeft: '8px'}}>{title}</Box>
                            </div>
                          </AccordionSummary>
                          <CustomAccordionDetails>
                              {content}
                          </CustomAccordionDetails>
                      </Accordion>
                  ))}
              </div>
          );
      } else {
            return (
                <Tabs value={selectedTag} onChange={handleTagChange}>
                    <Tab icon={<SettingsIcon/>} iconPosition="start" label="General Settings" {...tabProps(0)} />
                    <Tab icon={<PersonIcon/>} iconPosition="start" label="Profile Details" {...tabProps(1)} />
                    <Tab icon={<LockOpenIcon/>} iconPosition="start" label="Security Setting" {...tabProps(2)} />
                </Tabs>
            );
        }
    }

    return (

        <Grid container sx={{px:3,py:6,overflowY:"auto"}}>


            <Typography px={2} sx={{fontSize:24}}>Account Settings</Typography>
            <Grid container pt={6} mx={{md:6}}>
                <Grid item xs={12}>
                {renderTabsOrAccordion()}
                </Grid>
                <Grid item xs={12}>
                <GeneralSetting selectedTag={selectedTag}/>
                <ProfileSetting selectedTag={selectedTag}/>
                <SecuritySetting selectedTag={selectedTag}/>
                </Grid>

            </Grid>

        </Grid>
    );
}

export default setting;