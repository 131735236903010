import {Box, Avatar, Grid} from "@mui/material";
import styles from "./topbar.module.css";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListIcon from '@mui/icons-material/List';
import {Fragment} from "react";
import api from "_services/api";
import { getInitials } from "utils/prepareNames";
import {IconButton} from '@mui/material';
import SharedModels from 'components/menus/SharedModels';
import {useDispatch} from "react-redux";
import { deleteAllShared } from "redux/sharedMessages";
import LogoSvg from "components/logo/LogoSvg";
import { useNavigate } from "react-router-dom";
import {setSelectedModelType} from "redux/models";
import TrialCountdown from "./components/TrialCountdown";
import AccountMenu from "./components/AccountMenu";
import classnames from "classnames";
import {useSelector} from "react-redux";
import {sendUIEvent} from "../../../events/analytics/google";


function TopBar({widesidebar, mobilebar, mobilebarChange, widesidebarChange, smallScreen}) {
    const is_default = useSelector(state => state.auth?.user?.tariff_plan?.is_default);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isTrial = is_default ? is_default === 1 : false;

    const widesidebarHandler = () => {
      if (widesidebar) {
        localStorage.setItem('smallMenu', 1);
      } else {
        localStorage.removeItem('smallMenu');
      }
      widesidebarChange(!widesidebar);
    }

    const mobilebarHandler = () => {
        mobilebarChange(!mobilebar);
        sendUIEvent({name: `mobile_nav_expand_${!mobilebar}`});
    }

    // TODO: move to a helper
    const fetchSharedData = async (modelHash) => {
      let params = {
        modelHash,
      };
      dispatch(setSelectedModelType({ modelType: 'shared', data: null, loading: true }));
    
      try {
        const response = await api.post('/model/share/data', params);

        if (response.code >= 400) {
          // setDataLoading(false);
          // setChatNotExist(true);
          return;
        }

        const messages = response.messages || [];
        const model = response.chat?.model || {};
        const ownerInfo = response.owner || {};
        const ownerInnitial = ownerInfo.fullName || '';

        if (ownerInnitial !== '') {
          ownerInfo.initial = getInitials(ownerInnitial);
        }

        // dispatch(addMessages({ type: 'shared', messages }));
        // setModelInitialsData({ avatar: model.avatar, name: model.name });
        const data = {
          model: model,
          messages: messages,
          info: {
            chat_id: response.chat.id,
            guest_id: response.chat.guest_id,
            modelName: model.name,
            modelWebsite: model.modelWebsite,
            modelId: model.id,
          },
          ownerInfo: ownerInfo,
        };
        dispatch(setSelectedModelType({ modelType: 'shared', data, loading: false }));
      } catch (error) {
        // setDataLoading(false);
      }
    }

    return (
        <>

            {isTrial && smallScreen && <Box className={classnames(styles["flex-countdown-align"],styles["countdown-small"])}>
                <TrialCountdown/>
            </Box>}
            <Box className={styles["topbar"]}>

                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {
                        smallScreen ?
                            <>
                                <Box sx={{
                                    width: '60px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}><ListIcon color={'icons'} sx={{marginLeft: '6px', width: 28, height: 28}}
                                             onClick={mobilebarHandler}/></Box>
                                <Box sx={{
                                    width: 'calc(100% - 60px)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}>
                                    {/*<LogoSvgIcon/>*/}
                                </Box>
                            </> :
                            <>

                                <IconButton aria-label="sideMenu" onClick={widesidebarHandler}>
                                    {widesidebar ? <MenuOpenIcon color={'icons'}/> : <ListIcon color={'icons'}/>}
                                </IconButton>
                                <Box sx={{display: 'flex', alignItems: 'center'}}><LogoSvg hasLogo={false}/></Box>
                            </>
                    }
                </Box>
                {!smallScreen && <Box className={styles["flex-countdown-align"]}>
                    <TrialCountdown/>
                </Box>}
                <>
                  {smallScreen && (
                    <Box>
                      {/* <SharedModels
                        changeUrl={true}
                        widesidebar={true}
                        onSelect={(model) => {
                          if (!Boolean(model?.settings?.monetization_price) && Boolean(model?.public_hash)) {
                            fetchSharedData(model?.public_hash);
                            dispatch(deleteAllShared());
                          }
        
                          if (Boolean(model?.settings?.monetization_price) && Boolean(model?.marketplace_card?.url_hash)) {
                            dispatch(setSelectedModelType({ modelType: 'monetization', data: model, loading: false }));
                          }
                          navigate('/playground');
                        }}
                        defaultOppened={false}
                      /> */}
                    </Box>
                  )}

                  <Box className={styles["flex-space-around"]}>
                    <AccountMenu/>
                  </Box>
                </>
            </Box>
        </>
    );
}

export default TopBar;
